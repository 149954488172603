import React, {useEffect, useState, useContext, useRef} from 'react'

//=========3RD LIBRARY  ========//
import _ from 'lodash'  
import {StyleSelect} from "../../../helpers/select/Select"
import Select from 'react-select' 

//=========ICON LIST========// 
import HighlightOffIcon from '@material-ui/icons/HighlightOff' 

//=========BOOTSTARP INITIAL========//
import {Col, Row} from 'react-bootstrap' 

//=========CONTEXT========//
import {DashboardContext} from "../../../context/DashboardContext"  
import {filterDataTableForFilter, stripHtmlAnd } from '../../../helpers/utils/Utils'
 
const Filter = (props) =>{
    const {tableSearch,setValueFilter,valueFilter} = useContext(DashboardContext)  
    const [filterList, setFilterList] = useState([])     
    const [selectedFilter, setSelectedFilter] = useState({value: 'Column', label:'Column'})  
    const [condition, setCondition] = useState([
        {value: 'is any of', label: 'is any of'},
        {value: 'is not any of', label: 'is not any of'},
        {value: 'is empty', label: 'is empty'},
        {value: 'is not empty', label: 'is not empty'},
        {value: 'is', label: 'is'},
        {value: 'is not', label: 'is not'},
    ])  
    const [selectedCondition, setSelectedCondition] = useState({value: 'Condition', label:'Condition'})  
    const [selectedValue, setSelectedValue] = useState([])  
    const [value, setValue] = useState({value: 'Value', label:'Value'})  
    const [isDisabledValue, setDisabledValue] = useState(false)   

    const clearSelected = (index) =>{ 
        if(index === 0){
            setSelectedFilter({value: 'Column', label:'Column'})
            setSelectedCondition({value: 'Condition', label:'Condition'})  
            setSelectedValue({value: 'Value', label:'Value'})    
        }else {  
            props.deleteFitlter(index)
        }
     setValueFilter({...valueFilter, isReset: true, index:props.elementIndex})
    }

    useEffect(()=>{  
        let typeList = [{value:"Group",label:"Group"},{value:"Name", label:"Name", type: "Name"}] 
        tableSearch.map(tab =>{
            //===== GET HEADER EACH TABLE ======//
            tab.rows[0].map((row, key) =>{  
                if(key > 1){
                    let id =  row.id
                    let type =  row.type
                    let name =  row.name 
                    if((typeList.indexOf(type) === -1) && row.type !== 'option' && row.type !== 'add' 
                    && row.type !== 'subitemscontroll' && row.type !== 'file' 
                    && type.trim() !== 'Last Update' && type.trim() !== 'Creation Log'){  
                        let tmp = {value:id, label: stripHtmlAnd(name), type: type}
                        let isExist = _.some(typeList, function(i) {
                            return i.value == tmp.value
                        })
                        if(!isExist)
                        typeList.push(tmp) 
                    }
                }
                  
            })
        })
 
        setFilterList(typeList)  
        let data = {column: selectedFilter.value}
        let dataFilter = filterDataTableForFilter(tableSearch, data) 
        setValue(dataFilter) 
    },[tableSearch])
  

    const handleSelectedColumn = (selected) =>{
        setSelectedFilter(selected);
        setSelectedCondition(condition[0])
        setSelectedValue([])
        let data = {column: selected.value}
        let dataFilter = filterDataTableForFilter(tableSearch, data)  
        setValue(dataFilter) 
        if(selectedValue.value)
        setValueFilter({...valueFilter, isReset: true})
    }
    
    const handleChangeValue = (selected) => {
        setSelectedValue(selected)
        setValueFilter({value: selected.value, type: selectedFilter.type, isReset: false, index:props.elementIndex, condition: selectedCondition.value, column: selectedFilter.value})
      
    }

    const handleChangeOption = (selected) =>{
        let value = selectedValue.value
        setSelectedCondition(selected)
        setDisabledValue(false)
        if(selected.value === "is empty" || selected.value === "is not empty"){
            setDisabledValue(true) 
            if(!selectedValue.value) value = "empty"
         } 
        if(value)
        setValueFilter({value: value,  type: selectedFilter.type,isReset: false,index:props.elementIndex, condition: selected.value, column: selectedFilter.value})
    }

    return (
        <> 
            <Row className="mt-3 mb-3">
                  <Col className="d-flex align-items-center">
                    {props.elementIndex === 0 ?
                        <span className="font-14">Where</span>
                        : <span className="font-14 mr-3"> And</span>
                    }
                      </Col>
                  <Col>
                  <Select focus styles={StyleSelect({menuListHeight: 200, width: 200})} 
                        maxMenuHeight={150}
                        placeholder=""     
                        className='m-0 p-0' 
                        options={filterList}
                        value={selectedFilter}
                        onChange={(selected)=>{handleSelectedColumn(selected)}} 
                         ></Select>
                </Col>
                <Col>
                  <Select focus styles={StyleSelect({menuListHeight: 200, width: 200})} 
                        maxMenuHeight={150}
                        placeholder=""     
                        value={selectedCondition}
                        options={selectedFilter.value === "Column" ? []: condition}
                        onChange={(selected)=>{handleChangeOption(selected)}}
                        className='m-0 p-0'  
                         ></Select>
                </Col>
                <Col>
                  <Select focus styles={StyleSelect({menuListHeight: 200, width: 200})}  
                        inputId='select-filter'
                        maxMenuHeight={150}
                        placeholder=""       
                        options={selectedFilter.value === "Column" ? []: value}
                        className='m-0 p-0' 
                        value={selectedValue} 
                        isDisabled={isDisabledValue}
                        isMulti = {false}
                        isClearable={false}
                        onChange={(selected)=>{handleChangeValue(selected)}}
                         ></Select>
                </Col> 
                <Col className="d-flex align-items-center m-0 p-0 pr-3">
                    
                    <HighlightOffIcon style={{color: 'black', fontSize: 20 }} className="align-text-top cursor-pointer" onClick={()=>clearSelected(props.elementIndex)}/>
                       
                </Col> 
            </Row> 
        </>
    )
}

export default Filter