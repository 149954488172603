import React, {useContext, useState, useRef, useEffect} from 'react'
import {Modal, Form, Row, Col,Spinner, Button } from 'react-bootstrap'  
import {DashboardContext} from "../../context/DashboardContext"  
import {NotificationManager} from 'react-notifications' 
import Select from 'react-select'  
import ReactQuill from "react-quill";
import "quill-mention";
import "quill-mention/dist/quill.mention.css" 
import _ from 'lodash'
import { stripHtml,stripHtmlAnd} from '../../helpers/utils/Utils' 

const ModalArticle = (props) =>{  
    const {params,postArticleCrete, postMenuArticle,modalArticle, 
        setNewArticle, showModalArticle } = useContext(DashboardContext)  
    const [data, setData] = useState([]) 
    const [menu, setMenu] = useState([]) 
    const [isLoading, setLoading] = useState(false) 
    const [selectedMenu, setSelectedMenu] = useState({label: "Menu"}) 
    const [selectedSubMenu, setSelectedSubMenu] = useState({label: "Sub Menu"}) 
    let refTitle = useRef()  
    const [subMenu, setSubMenu] = useState([]) 
    const [value, setValue] = useState("") 
    let refQuill = useRef()

    const modules = {
        toolbar: [
          [{ 'header': [1, 2, false] }],
          [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' },'bold', 'italic', 'underline','strike', 'blockquote',{ 'color': [] }],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image','video'], 
        ] ,
        
    }

    const formats = [
        'header',
        'align','bold', 'italic', 'underline', 'strike', 'blockquote','color',
        'list', 'bullet', 'indent',
        'link', 'image',  'video'
    ] 

    useEffect(()=>{
        if(modalArticle)
        getMenu()

    },[modalArticle])

    const handleApply = async() => {
        // setLoading(true)
        let title = refTitle.current.value.trim()  
        if(title === "") {
            NotificationManager.error("Title is required.")
            return
        }

        if(!selectedMenu.value) {
            NotificationManager.error("Menu is required.")
            return
        }

        if(!selectedSubMenu.value) {
            NotificationManager.error("Sub menu is required.")
            return
        }

        let value = stripHtml(refQuill.current.state.value.trim()) 
        let valueInsert = refQuill.current.state.value.trim()
        if(value === "") {
            NotificationManager.error("Content is required.")
            return
        }

        let param = {}
        param.title = title
        param.menu = selectedMenu.value
        param.sub_menu = selectedSubMenu.value
        if(modalArticle.data) param.id = modalArticle.data.id
        param.content = valueInsert
        param.created_by = params.id
        setLoading(true)
        let data = await postArticleCrete(param)
        if(data.status === 200){
           showModalArticle({isShow : false, data : false})
           setNewArticle(new Date())
           setSelectedSubMenu({label: 'Sub Menu'})
           setSelectedMenu({label: 'Menu'})
        }
        setLoading(false)
       
    }

    const getMenu = async ()=>{
        setValue("")
        let data = await postMenuArticle()
        if(data.status === 200){
            let menu = [] 
            setData(data.data)
            data.data.map(item => {
                let tmp = {}
                tmp.value = item.id
                tmp.label = item.name
                if(item.parent === null){
                    menu.push(tmp)  
                }
                if(modalArticle.data && modalArticle.data.menu.id === item.id){
                    setSelectedMenu(tmp)   
                    let dataSubmenu = _.filter(data.data, (item)=> {return item.parent === modalArticle.data.menu.id})
                    let tmpArr = []
                    dataSubmenu.map(item => {
                        let tmp = {}
                        tmp.value = item.id
                        tmp.label = item.name
                        if(modalArticle.data.subMenu.id === item.id){
                            setSelectedSubMenu(tmp)
                            setValue(modalArticle.data.content)
                        }
                        tmpArr.push(tmp)
                    })
                    setSubMenu(tmpArr)
                }
            })
            setMenu(menu) 
        }
    }

 
    const handleAddMenu = (selected)=>{
        setSelectedSubMenu({}) 
        setSelectedMenu(selected)
        let dataSubmenu = _.filter(data, (item)=> {return item.parent === selected.value})
        let dataArr = []
        dataSubmenu.map(item => {
            let tmp = {}
            tmp.value = item.id
            tmp.label = item.name
            dataArr.push(tmp)  
        })
        setSubMenu(dataArr)
    }


    const handleAddSubMenu = (selected)=>{
        setSelectedSubMenu(selected) 
    }

    return (
        <Modal
        {...props}
        size=""
        id="modal-price-list"
        className="modal full fade" 
        centered>
            <Form> 
                <Modal.Header> 
                    <div className="w-100">
                        <span className="modal-title text-center">
                           Article
                        </span>
                    </div>
                    <Button 
                        disabled={isLoading}
                        className="btn-yes text-white float-right mr-2"
                        type="button"
                        onClick={handleApply}
                        >
                            {isLoading ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    />
                                : modalArticle.data ? "Update" : "Save"
                            }
                    </Button>  
                    <Button className="btn-cancel float-right" onClick={()=>showModalArticle({isShow: false, data: false})}>Cancel</Button>   
                </Modal.Header>
                <Modal.Body className="m-2"> 
                    <div>
                        <Row>
                            <Col xs="12"> 
                            <div className="pb-3">
                                 <input ref = {refTitle} 
                                 type="text" 
                                 className="form-control font-13" 
                                 placeholder="Title"
                                 defaultValue = {modalArticle.data ? modalArticle.data.title : ""}
                                 ></input>
                            </div>
                            </Col>
                            <Col xs="6"> 
                              <div> 
                                    <Select  
                                        placeholder ="Menu" 
                                        maxMenuHeight={150} 
                                        options={menu} 
                                        value={selectedMenu}
                                        onChange={(selected)=>{handleAddMenu(selected)}}
                                        isClearable={false} 
                                    />
                                </div>
                            </Col>
                            <Col xs="6"> 
                                <div >
                                    <Select  
                                        placeholder ="Sub Menu" 
                                        maxMenuHeight={150}  
                                        options={subMenu}
                                        value={selectedSubMenu}
                                        onChange={(selected)=>{handleAddSubMenu(selected)}}
                                        
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                         
                            <Col>
                                <ReactQuill className="mt-4" theme="snow" 
                                theme="snow"
                                ref = {refQuill}  
                                modules={modules}   
                                value= {value}
                                placeholder= 'Write an update...'
                                formats={formats} 
                                />  
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
               
            </Form>
        </Modal>
    )
}

export default ModalArticle