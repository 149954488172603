import React, {useState, useContext, useEffect} from 'react' 
import { MakeRequest } from "../../api/Api"   
import { useParams, useHistory } from "react-router-dom"   

const Register = () =>{ 
    const {token} = useParams()   
    const history = useHistory() 
    
    useEffect(()=>{ 
        const verification = async ()=> {   
            const result = await MakeRequest('post','verification', {token: token})   
            if(result.status === 200) {
                history.push("/login") 
            }
        }
        verification()
    },[])

    return(
        <>

        </>
    )
}

export default Register