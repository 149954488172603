import React, { Component } from 'react'; 
import './css/home.css'; 
import {useHistory} from 'react-router-dom'  
import Article from './Article'; 
import Footer from './Footer';  

const Home = () => {  

  const history = useHistory()  

  const login = async e => { 
    localStorage.removeItem('saatkerja')
    history.push('/login') 
  }

  const register = async e => { 
    localStorage.removeItem('saatkerja')
    history.push('/register') 
  }

    return (
      <div>
        <Article/> 
        <Footer/> 
      </div>
       
      
    );
  } 

export default Home