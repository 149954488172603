import React, {useEffect, useState, useContext} from 'react'
import {Modal, Button, Col, Spinner, Form, Row} from 'react-bootstrap' 
import _ from 'lodash' 
import {DashboardContext} from "../../context/DashboardContext"  
import {stylesSelectMulti} from "../../helpers/select/Select"
import Select from 'react-select' 
 
const ModalChooseBoard = (props) =>{ 
    const {params, boardActive, board, updateBoard, selectedBoard, dispatchChart} = useContext(DashboardContext)  
    const [boardList, setBoardList] = useState([]) 
    const [boardListTmp, setBoardListTmp] = useState([]) 
    const [selectedBoardTmp, setSelectedBoard] = useState([])  
    const [isLoading, setLoading] = useState(false) 

    useEffect(()=>{ 
        
    },[props])

    useEffect(()=>{  
        let arrTmp = []
        board.map(item =>{  
            let tmp = {value: item.id, label: item.title}
            let isExist = _.some(selectedBoard, (data)=> {return item.id === data.id})
            if(isExist) return
            if(item.type === 1)
                arrTmp.push(tmp)
        }) 
        setBoardList(arrTmp)
        setBoardListTmp(arrTmp)

        arrTmp = []
        selectedBoard.map(item =>{  
            let tmp = {value: item.id, label: item.title}
            if(item.type === 1)
                arrTmp.push(tmp)
        }) 
        setSelectedBoard(arrTmp)
       
    },[board, selectedBoard])
  

    const handleSelected = async(selected) =>{  
        if(selected === null) selected = []
        let dataSelected = _.clone(selectedBoardTmp, true)
        dataSelected.push(selected[0]) 
        setSelectedBoard(dataSelected)   

        let newSelected = _.filter(boardList,(item)=>{ 
            return item.value !== selected[0].value
        }) 

        setBoardList(newSelected)
    }

    const handleApply = async()=>{
        let selected = _.map(selectedBoardTmp,"value")
        if(selected.length === 0) return
        setLoading(true) 
        let param = {}
        param.id = boardActive.id
        param.title = boardActive.title
        param.description = boardActive.description
        param.permission_id = boardActive.permissionId 
        param.is_favorites = boardActive.is_favorites 
        param.updated_by = params.id
        param.selected_board = selected
        await updateBoard(param)
        setLoading(false) 
        props.onHide() 
    }

    const handleDelete = async (item) =>{ 
        let tmpData = _.cloneDeep(selectedBoardTmp, true)
        //== REMOVE LIST ==///
        let newSelected = _.filter(tmpData,(selected)=>{ 
            return item.value !== selected.value
        }) 
         //== ADD TO LIST ==///
         let tmpBoardList = _.cloneDeep(selectedBoardTmp, true)
         let newSelectedList = _.filter(tmpBoardList,(selected)=>{ 
            return item.value === selected.value
        }) 
        let newList = _.cloneDeep(boardList)
        newList = newList.concat(newSelectedList)
        setSelectedBoard(newSelected)
        setBoardList(newList)
      
    }

    const handleClearAll = () => {
        setSelectedBoard([])
        setBoardList(boardListTmp)
    }
  
    return (
        <Modal
        {...props}
        size=""  
        centered 
        style={{ background: 'rgba(0, 0, 0, 0.5)'}}
        aria-labelledby="contained-modal-title-vcenter" > 
            <Modal.Header>
                <span className="modal-title">
                    Choose boards
                </span> 
            </Modal.Header>
            <Modal.Body className="m-2"> 
            
             <Col>
                <Select styles={stylesSelectMulti} 
                    placeholder ="Search board name" maxMenuHeight={160}
                    isMulti
                    value={[]}
                    onChange={(selected)=>handleSelected(selected)}
                    options={boardList}
                />
                
             </Col>

             <Col>
                <div className="mt-3"> 
                    <Row>
                        <Col md="12" className="mt-3"> 
                            <span className="font-14 font-w-400 color-grey">Selected Boards</span> 
                             <span onClick={()=>handleClearAll()} className="cursor-pointer font-14 font-w-400 text-primary float-right align-middle" >Clear All</span>
                        </Col>  
                    </Row> 
                </div>
                <div>
                    {selectedBoardTmp.map((item, key) => { 
                        return( 
                            <Row key={key}>
                                <Col md="12" className="mt-3"> 
                                    <span className="font-14 font-w-400 color-grey text-dark">{item.label}</span>
                                    <Form.Check type="checkbox" onClick={()=>handleDelete(item)} 
                                    defaultChecked={true} className="float-right align-middle" label=""/> 
                                </Col>  
                            </Row> 
                        )
                    })}
                </div>
                
             </Col>
             
            </Modal.Body>
            <Modal.Footer>  
                <Button className="btn-cancel" onClick={props.onHide}>Cancel</Button>  
                <Button 
                    disabled={isLoading}
                    className="btn-yes text-white"
                    type="submit"
                    onClick={()=>{handleApply()}}
                    >
                        {isLoading ?
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                            : "Apply" 
                        }
                </Button> 
            </Modal.Footer> 
        </Modal>
    )
}

export default ModalChooseBoard