import React, { useContext, useEffect } from "react";

//=========BOOTSTARP INITIAL========//
import { Col, Image, Row, Table } from "react-bootstrap";
import { DashboardContext } from "../../context/DashboardContext";
import { BasicChart } from "../../helpers/highcharts/Charts";
import { useState } from "react";
import _ from "lodash";
import {
  getDataTimeLineReport,
  getDataTimeLineReportWorkspace,
} from "../../helpers/utils/Utils";
import { Gantt } from "gantt-task-react";
import { StyleSelect } from "../../helpers/select/Select";
import Select from "react-select";
import ImgKominfo from "../../img/kominfo.png";

const DashboardPMUWorkspace = () => {
  const {
    width,
    selectedTeams,
    selectedWorkspace,
    getDashboardBoard,
    dashboardBoard,
    getDashboardWorkspaceCurve,
    dashboardWorkspaceCurve,
    viewTimelineSelected,
    getCurvaS,
    curvaS,
  } = useContext(DashboardContext);

  const [charts, setChart] = useState({});
  const [chartsCurva, setChartCurva] = useState({});
  const [dataIssue, setDataIssue] = useState([]);
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    if (!selectedWorkspace.value) return;
    getData();
  }, [selectedWorkspace]);

  const getData = async () => {
    await getDashboardBoard();
    await getDashboardWorkspaceCurve();
    // await getCurvaS(1);
  };

  useEffect(() => {
    if (!dashboardWorkspaceCurve.dataCurve) return;
    if (dashboardWorkspaceCurve.dataIssue)
      setDataIssue(dashboardWorkspaceCurve.dataIssue);

    setChart({});
    let categories = _.map(dashboardWorkspaceCurve.dataCurve, "week");
    let categoriesCurva = _.map(curvaS, "week");
    let data = _.map(dashboardWorkspaceCurve.dataCurve, "plan_cumulative");
    let dataPlan = _.map(curvaS, "plan");
    let dataActualCurve = _.map(curvaS, "actual");
    let dataActual = _.map(
      dashboardWorkspaceCurve.dataActual,
      "actual"
    );

    let chart = {
      type: "spline",
      borderColor: "#F0F1F3",
      borderWidth: 1,
      legend: {
        align: "center",
        verticalAlign: "bottom",
        layout: "horizontal",
        enabled: true,
        symbol: "circle",
      },
      xAxis: {
        categories: categories,
        lineColor: "#F0F1F3",
        lineWidth: 1,
        labels: {
          enabled: true,
          formatter() {
            return `<span style="color: black;font-size: 10px; font-weight: 400">W${this.value}</span>`;
          },
        },
      },
      yAxis: {
        title: {
          // text: stateChart.yAxis,
          text: "",
        },
        labels: {
          enabled: false,
          symbol: "circle",

          style: {
            color: "black",
          },
        },
        gridLineColor: "#F0F1F3",
      },
      plotOptions: {
        series: {
          marker: {
            symbol: "circle",
          },
          animation: false,
          dataLabels: {
            format: "{y}",
            enabled: false,
          },
        },
      },
      series: [
        {
          marker: {
            symbol: "circle",
          },
          name: "Persentase Akumulatif Rencana",
          color: "rgb(244, 67, 54)",
          data,
        },
        {
          marker: {
            symbol: "circle",
          },
          name: "Persentase Akumulatif Actual",
          color: "rgb(3, 169, 244)",
          data: dataActual,
        },
      ],
    };

    let chartCurva = {
      type: "spline",
      borderColor: "#F0F1F3",
      borderWidth: 1,
      legend: {
        align: "center",
        verticalAlign: "bottom",
        layout: "horizontal",
        enabled: true,
        symbol: "circle",
      },
      xAxis: {
        categories: categoriesCurva,
        lineColor: "#F0F1F3",
        lineWidth: 1,
        labels: {
          enabled: true,
          formatter() {
            return `<span style="color: black;font-size: 10px; font-weight: 400">W${this.value}</span>`;
          },
        },
      },
      yAxis: {
        title: {
          // text: stateChart.yAxis,
          text: "",
        },
        labels: {
          enabled: false,
          symbol: "circle",

          style: {
            color: "black",
          },
        },
        gridLineColor: "#F0F1F3",
      },
      plotOptions: {
        series: {
          marker: {
            symbol: "circle",
          },
          animation: false,
          dataLabels: {
            format: "{y}",
            enabled: false,
          },
        },
      },
      series: [
        {
          marker: {
            symbol: "circle",
          },
          name: "Plan Weight",
          color: "rgb(244, 67, 54)",
          data : dataPlan,
        },
        {
          marker: {
            symbol: "circle",
          },
          name: "Actual Weight",
          color: "rgb(3, 169, 244)",
          data: dataActualCurve,
        },
      ],
    };

    setChart(chart);
    setChartCurva(chartCurva);
  }, [dashboardWorkspaceCurve, width]);

  useEffect(() => {
    if (!dashboardBoard.data.length) return;
    let data = getDataTimeLineReportWorkspace(
      dashboardBoard.data,
      selectedWorkspace
    );
    setTasks(data);
  }, [dashboardBoard]);

  return (
    <div className="content-board bg-grey" style={{ marginLeft: width.board }}>
      <div className="p-3">
        <Row className="no-gutters">
          <Col className="w-100 bg-white rounded shadow-sm p-3 m-1">
            <Row>
              <Col md="2">
                <Image width={50} src={ImgKominfo}></Image>
              </Col>
              <Col md="7">
                <div
                  className="text-center font-16 font-weight-bold"
                  style={{ color: "#003398" }}
                >
                  <span>DASHBOARD PMU</span>
                  <div>
                    <span>PEMASANGAN PERANGKAT ALAT UKUR TELEKOMUNIKASI</span>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="no-gutters">
          {dashboardBoard.data &&
            dashboardBoard.data.map((item, key) => {
              return (
                <Col key={key} md="3" className="mt-1 p-1">
                  <div className="bg-white rounded shadow-sm p-3">
                    <span
                      className="font-14 font-weight-bold"
                      style={{ color: "#003398" }}
                    >
                      {item.board}
                    </span>
                    <div>
                      <span className="font-18 font-weight-bold">
                        {item.avg} %
                      </span>
                    </div>
                  </div>
                </Col>
              );
            })}
        </Row>
        <Row className="no-gutters">
          <Col md="9" className="p-1 mt-1">
            <div
              className="bg-white rounded shadow-sm p-4"
              style={{ height: "350px" }}
            >
              <span
                className="font-14 font-weight-bold"
                style={{ color: "#003398" }}
              >
                Summary Progress
              </span>
              {charts.type ? <BasicChart charts={charts} /> : null}
            </div>
          </Col>

          <Col md="3" className="mt-1 h-100 p-1">
            <div
              className="bg-white rounded shadow-sm p-4"
              style={{ height: "350px" }}
            >
              <span
                className="font-14 font-weight-bold"
                style={{ color: "#003398" }}
              >
                Progress sampai minggu ini
              </span>
              <div>
                {dashboardBoard.progress.progress_weekly.map((item, key) => {
                  return (
                    <div className="d-flex justify-content-between">
                      <div className="mt-2">
                        <span className="font-14">{item.name}</span>
                      </div>
                      <div className="mt-2">
                        <span className="font-14 font-weight-bold">
                          {item.pct}%
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="no-gutters" hidden>
          <Col md="12" className="p-1 mt-1">
            <div
              className="bg-white rounded shadow-sm p-4"
              style={{ height: "350px" }}
            >
              <span
                className="font-14 font-weight-bold"
                style={{ color: "#003398" }}
              >
                Curva S
              </span>
              {chartsCurva.type ? <BasicChart charts={chartsCurva} /> : null}
            </div>
          </Col>
        </Row>
        <Row className="mt-1 no-gutters">
          <Col md="4" className="p-1">
            <div
              className="bg-white rounded shadow-sm p-4"
              style={{ height: "550px" }}
            >
              <span
                className="font-14 font-weight-bold"
                style={{ color: "#003398" }}
              >
                Hilight Issue
              </span>
              <div className="font-14 mt-3">
                <Table className="table-hover">
                  <tbody>
                    {dataIssue.map((item, key) => {
                      return (
                        <tr key={key}>
                          <td>{item.name}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>

          <Col md="8" className="w-100 p-1">
            <div
              className="bg-white rounded shadow-sm p-4 w-100"
              style={{ height: "550px" }}
            >
              {/* <div>
                <Select
                  styles={StyleSelect({ width: 200 })}
                  placeholder=""
                  options={viewTimeline}
                  value={viewTimelineSelected}
                  className="m-0 p-0"
                  onChange={(selected) => {
                    setViewTimelineSelected(selected);
                  }}
                ></Select>
              </div> */}
              <div>
                <span
                  className="font-14 font-weight-bold"
                  style={{ color: "#003398" }}
                >
                  Timeline
                </span>
                {tasks.length ? (
                  <div className="mt-3">
                    <Gantt
                      locale="id"
                      tasks={tasks}
                      viewMode={viewTimelineSelected.value}
                      listCellWidth=""
                      barProgressColor="rgb(76, 175, 80)"
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DashboardPMUWorkspace;
