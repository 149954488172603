import React, {useContext, useRef, useState, useEffect} from 'react'
import {Col, Nav,Image, Row } from 'react-bootstrap'    
import './Style.css' 
import {Style} from "../../helpers/style/Style"  
import {DashboardContext} from "../../context/DashboardContext"  
import CheckIcon from "../../img/check.svg"   
import PlusIcon from "../../img/plus.svg"      
import {initialString, asyncRequest, stripHtml } from '../../helpers/utils/Utils' 
import _ from 'lodash'  


const Menu = () =>{ 
    const {selectedWorkspace, teams, selectedTeamsSetting, 
        setSelectedTeamsSetting, createTeams, setModalSpinner,
        params, getTeams, showModalPricing} = useContext(DashboardContext)  
    let ref = useRef(null)    
    let refIdAddTeam = useRef("")    
    const [teamsData, setTeamsData] = useState([])

    const handleselectedTeamsSetting = (item) => {
        setSelectedTeamsSetting(item)
    }


    useEffect(()=>{ 
        if(!teams.length) return  
        let alreadySelected = false
        //=========Asign value teams========//
        let arrTmp = []  
        teams.map((item) => {   
            let objTmp = {}
            objTmp.created_by = item.team.user_id
            objTmp.id = item.team.id
            objTmp.name = stripHtml(item.team.name) 
            objTmp.is_default = item.team.is_default 
            objTmp.permission_id = item.permission_id 
            if(item.team.is_active  === true){
                arrTmp.push(objTmp)    
            } 
            if(item.team.id === selectedTeamsSetting.id){
                setSelectedTeamsSetting(objTmp)
                alreadySelected = true
            }
        }) 
        if(!alreadySelected)
        setSelectedTeamsSetting(arrTmp[0]) 
        setTeamsData(arrTmp)
    },[teams])
    
    const getDataTeams = async ()=>{
        await getTeams(params.id)
    }

    const handleClickAdd =  async()=>{ 
        let param = {}
        param.team = "New Team"
        param.id = params.id
        setModalSpinner(true)  
        let create =  await createTeams(param)   
        if(create.status === 200){
            setSelectedTeamsSetting(create.data) 
            await getDataTeams() 
        }
        asyncRequest(500).then(()=>{
            if(create.message === "limit") showModalPricing(true)
            setModalSpinner(false)  
        })
     }

    return ( 
        <React.Fragment>
            {selectedTeamsSetting ? 
                <Col className="position-absolute bg-white menu-teams m-0 p-0 h-100" ref={r => (ref = r)}>  
                    <div className="w-100 mt-3">
                        <div className="d-inline"> 
                            <span className="workspace-text d-inline pl-3">My Teams</span>
                        </div>    
                    </div>

                    <div className="spacer mt-3 mb-2" ></div> 
                    <div style={{overflow:'auto', maxHeight: '78vh'}}>
                        {teamsData.map(item => { 
                                return (
                                    <div className="pt-1 pb-1" key={item.id}> 
                                        <Nav.Link className={"nav-menu nav-menu-teams"} onClick={()=>{handleselectedTeamsSetting(item)}}> 
                                            <Row>
                                                <Col md="2">
                                                    <span style={Style.circleTextTeams}>
                                                        <span style={Style.circleTextTeamsSpan}>{selectedWorkspace? initialString(item.name): "NA"}</span>
                                                    </span>
                                                </Col>
                                                <Col className="col pr-1 pl-1">
                                                    <div className="d-inline">
                                                        <span className="pl-2 font-14 font-w-400 text-dark lable-no-wrap-teams">{item.name}</span>
                                                    </div> 
                                                </Col>
                                                <Col>
                                                    {selectedTeamsSetting && item.id === selectedTeamsSetting.id ? 
                                                        <div className="float-right">
                                                            <Image className="float-right mt-2" src={CheckIcon} ></Image>
                                                        </div>
                                                        :null
                                                    } 
                                                </Col>

                                            </Row> 
                                            
                                        </Nav.Link>  
                                    </div>  
                                )
                            })
                        }  
                    </div>
                    
                    <div className="create-workspace cursor-pointer" onClick={()=>handleClickAdd()}>
                        <Image src={PlusIcon} className="mr-2 mb-2"></Image>
                        <span className="font-16 pt-1 font-w-400">Create New Team</span>
                    </div>

                </Col> 
            : null }

           
        </React.Fragment>
    )
}

export default Menu