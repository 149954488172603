import React, {useEffect, useState, useContext, useRef} from 'react'

//=========BOOTSTARP INITIAL========//
import {Col, Row, Image, Table} from 'react-bootstrap' 
import {Style} from "../../helpers/style/Style"
import {initialString, stripHtml, stripHtmlAnd} from '../../helpers/utils/Utils'  
import DeleteIcon from "../../img/delete.svg"  
import AddIcon from "../../img/add-pa.svg"  
import AvatarIcon from "../../img/avatar.svg"    
import EditIcon from "../../img/edit.svg"    
import Cover from "../../img/cover.jpg"   
import Cover1 from "../../img/cover1.jpg"   
import Cover2 from "../../img/cover2.jpg"   
import Cover3 from "../../img/cover3.jpg"   
import Cover4 from "../../img/cover4.jpg"   
import Cover5 from "../../img/cover5.jpg"   
import Cover6 from "../../img/cover6.jpg"   

//=========CONTEXT========//
import {DashboardContext} from "../../context/DashboardContext"  
 

//=========3RD LIBRARY========//
import _ from 'lodash' 
import moment from "moment" 
import ContentEditable from 'react-contenteditable'    

const Workspace = () => { 
    const isGetMemberRef = useRef(false)  
    const isFirstRunPermission = useRef(true)
    const refCurrentSelected = useRef(true)
    const [data, setData] = useState([]) 
    const [name, setName] = useState("") 
    const [listType, setListType] = useState([])
    const {params, getMember, member, permission, 
        flagNewMember, selectedTeams, updateTeams, 
        getTeams, setShowMemberTeam, teams,modalSpinner, setModalSpinner, selectedTeamsSetting, setSelectedTeamsSetting,
        setEditTeamMember, setShowDeleteTeams, showDeleteTeams, setDeleteTeamMember} = useContext(DashboardContext)   
    const refTitle = useRef("")
    const [coverImg, setCoverImg] = useState()
    
    useEffect(()=>{ 
        if(selectedTeamsSetting && refCurrentSelected.current !== selectedTeamsSetting.id){ 
            getTeamsMember() 
            refCurrentSelected.current = selectedTeamsSetting.id 
        }
    },[selectedTeamsSetting])

    useState(()=>{  
        if(!teams.length) return 
        if(!selectedTeamsSetting.see_all_member){
            setSelectedTeamsSetting(teams[0].team)
        }
    },[teams]) 

    useEffect(()=>{
        const coverList = [Cover, Cover1, Cover2, Cover2, Cover3, Cover4, Cover5, Cover6]
        var randomInt = Math.floor(Math.random() * coverList.length)
        var coverImg = coverList[randomInt]
        setCoverImg(coverImg)
    
    },[])

    const getTeamsMember = async () => {
        isGetMemberRef.current = true
        await getMember(selectedTeamsSetting.id) 
    }
     

    useEffect(()=>{
        if(isFirstRunPermission.current){
            isFirstRunPermission.current = false
            return
        }  
       
       let type = []
       permission.map(item =>{
            if(item.id !== 1) {
                let tmp =    {value:item.id, label: item.name} 
                type.push(tmp)
            }
       }) 

        setListType(type)
        
    },[permission])

    useEffect(()=>{
        if(selectedTeams && selectedTeams.value) 
        getDataMember()
        
    },[flagNewMember])

    const getDataMember = async() =>{
        if(!selectedTeamsSetting.see_all_member){
            await getMember(selectedTeams.value)
        }else{
            await getMember(selectedTeamsSetting.id)
        }
    }
  

    const updateTeam = async (isActive, is_default = false) => {
        if(refTitle.current.el.current.outerText === "") return 
        let param = {}
        param.id = selectedTeamsSetting.id
        param.name = refTitle.current.el.current.outerText
        param.is_active = isActive
        if(is_default){
            let id = _.map(teams, "team.id") 
            param.is_default = is_default
            param.id = id
        } 
 
        setModalSpinner(true)  
        let result = await updateTeams(param) 
        if(result.status === 200 ) {
          let data =  await getTeams(params.id)  
          if(data.data.length && !isActive) setSelectedTeamsSetting(data.data[0].team)
          if(!data.data.length) setSelectedTeamsSetting(false)
        }
        let debouncer = setTimeout(() => { 
            setModalSpinner(false)  
        }, 500);
        return () => {
            clearTimeout(debouncer);
        } 
    }
   
    useEffect(()=>{
        if(showDeleteTeams.actionDelete)
        updateTeam(false)
    },[showDeleteTeams.actionDelete])

    return (
        <React.Fragment>
            {selectedTeamsSetting && selectedTeamsSetting.id && !modalSpinner? 
                <Row className="vh-100 vw-100"> 
                    {/* <MenuTeams/> */}
                    <Col md={12}>
                        <div className="content-workspace vh-100 overflow-scroll overflow-x-hidden">
                            {/* <div className="worspace-cover">
                                <Image src={coverImg} className="worspace-cover"></Image>
                            </div> */}
                            <Col className="m-4">
                                <div className="p-3 cursor-pointer bg-grey">
                                    <span style={Style.circleTextWorkspace}>
                                        <span style={Style.circleTextWorkspaceSpan}>{selectedTeamsSetting.name? initialString(selectedTeamsSetting.name): "NA"}</span>
                                    </span>   
                                    <ContentEditable 
                                        html={selectedTeamsSetting.name}  
                                        className="font-24 font-weight-bold ml-3 d-inline content-editable single-line" 
                                        disabled={selectedTeamsSetting.created_by === params.id  ? false : true}   
                                        onBlur={()=>{updateTeam(true)}}
                                        ref ={(r)=> refTitle.current = r}  
                                    />  
                                </div>
                                <div className="mt-3 p-3">
                                    <span className="font-14 font-w-600">{member.length}</span> 
                                    <span className="font-14"> member on this team</span> 
                                    {selectedTeamsSetting.created_by === params.id || selectedTeamsSetting.permission_id < 3 ?
                                        <span className="invite-workspace-pa" onClick={()=>setShowMemberTeam(true)}>
                                            <Image src={AddIcon}></Image>
                                            <span className="invite-workspace-pa-span">Add Member</span>
                                        </span>
                                    :null}
                                     {selectedTeamsSetting.created_by === params.id  ?
                                        <span className="delete-workspace"  onClick={()=>setShowDeleteTeams({isShow: true, name: selectedTeamsSetting.name})}>
                                            <Image src={DeleteIcon}></Image>
                                            <span className="delete-workspace-span">Delete Team</span>
                                        </span>
                                        :null
                                     }
                                </div>

                                <Row className="pt-3 pr-4">
                                    <Table bordered hover responsive className="mt-3">
                                        <thead>
                                            <tr> 
                                            <th className="font-14 font-w-500" >Name</th>
                                            <th className="font-14 font-w-500">Email</th>
                                            <th className="font-14 font-w-500" >User Type</th>
                                            <th className="font-14 font-w-500" >Joined</th>
                                            <th className="font-14 font-w-500" >Status</th>
                                            <th className="font-14 font-w-500" >Action</th> 
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {member.map((item, key)=>{
                                                item.team_id = selectedTeamsSetting.id
                                                item.team = selectedTeamsSetting.name
                                                return (
                                                    <tr key={key}> 
                                                        <td className="font-14 font-w-400"> 
                                                            <div className="w-100">
                                                                <div className="d-inline-block">
                                                                    <Image src={AvatarIcon} width="24"></Image>
                                                                </div>
                                                                <div className="w-75 d-inline-block">
                                                                    <span className="ml-2">{item.username}</span>
                                                                </div> 
                                                            </div>
                                                        </td>
                                                        <td className="font-14 font-w-400">{item.email}</td>
                                                        <td className="font-14 font-w-400"> 
                                                            <div>
                                                                <span className={`permission-` + item.permission_name.toLowerCase().trim().replace(" ", "")}><span>{item.permission_name}</span></span> 
                                                            </div>
                                                        </td>
                                                        <td className="font-14 font-w-400">{moment(item.created_at).format("DD/MM/YYYY")}</td>
                                                        <td className={item.is_active === "Pending Invitation" ? "font-14 font-w-400 text-dark" : item.is_active === true?"font-14 font-w-400 text-success" : "font-14 font-w-500 text-danger"}>{item.is_active === "Pending Invitation" ? item.is_active : item.is_active === true ?'Active': 'Non-Active'} </td>
                                                        <td className="font-14 font-w-400 text-center">   
                                                        {item.permission_id === 1 || item.user_id === params.id || selectedTeamsSetting.permission_id > 2  || item.is_active === "Pending Invitation"? null :
                                                            <>
                                                            <Image src={EditIcon} className="cursor-pointer" onClick={()=>setEditTeamMember({isShow:true, data: item})}></Image>
                                                            <Image src={DeleteIcon} className="cursor-pointer ml-2" onClick={()=>setDeleteTeamMember({isShow:true, data: item})}></Image>
                                                            </>       
                                                        }
                                                         {item.permission_id === 1 || item.user_id === params.id || selectedTeamsSetting.permission_id > 2  || item.is_active !== "Pending Invitation"? null : 
                                                            <Image src={DeleteIcon} className="cursor-pointer" onClick={()=>setDeleteTeamMember({isShow:true, data: item})}></Image>     
                                                        }
                                                        
                                                        </td>
                                                    </tr>  
                                                
                                                )
                                            })}
                                            
                                        </tbody>
                                    </Table> 
                                </Row>
                            </Col> 
                        </div> 
                    </Col>
                </Row>
            : null}
        </React.Fragment>
    )
}

export default Workspace
