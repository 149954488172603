//=========REACT INITIAL========//
import React, {useEffect, useState, useContext, useRef} from 'react' 
import {DashboardContext} from "../../../context/DashboardContext" 
import {filterDataTableById, stripHtml, stripHtmlAnd } from '../../../helpers/utils/Utils'  

//=========BOOTSTARP INITIAL========//
import {ProgressBar, Image} from 'react-bootstrap' 
import AvatarIcon from "../../../img/avatar.svg" 
import TimeIcon from "../../../img/time.svg" 

//=========3RD LIBRARY========// 
import _ from 'lodash' 
import {Droppable, Draggable} from 'react-virtualized-dnd' 
import AccountCircleIcon from '@material-ui/icons/AccountCircle'  

const Index = (props) =>{
    const {table,setShowDetailKanban} = useContext(DashboardContext)   

    const showDetail = (item) =>{
        let tmpTable = _.cloneDeep(table, true)
        let dataTable = tmpTable[item.keyTable]
        const data = filterDataTableById(dataTable, {id: item.id})   
        setShowDetailKanban({isShow: true, data, parent: item.parentTask})
    }
    return (   
        <div style={{display: 'flex', flexDirection: 'row'}} className="p-3">
            {props.listData.map((elem, index) => ( 
                <div className="p-2" style={{flexGrow: 1}} key={elem.id}> 
                        <div className="kanban-title">{`${elem.name} - ${elem.items.length}`}</div>
                        <div className="kanban">
                        <Droppable dragAndDropGroup={"kanban" + props.index}  droppableId={elem.name} key={elem.index}   containerHeight={145 * props.listData.length} dynamicElemHeight={true}> 
                            {elem.items.map((item, idx) => (    
                            <Draggable dragAndDropGroup={"kanban" + props.index}  style={{width:'100px'}} draggableId={item.id} key={item.idTable * item.columnIndex + item.name}>
                                <div className="kanban-container" onClick={()=>showDetail(item)}> 
                                    <div className="kanban-item">
                                        {stripHtmlAnd(stripHtml(item.name))}
                                    </div> 
                                    <div className="p-2">
                                        <span className="font-12 pl-1 color-grey">{stripHtmlAnd(stripHtml(item.group))}</span>
                                        <div className="mt-4"> 
                                            <div style={{backgroundColor: item.priority.name?item.priority.name.color : '#F0F1F3', borderRadius: '4px' }} className="mt-2 m-1 d-inline-block p-1">
                                                <span className="font-12 text-white p-1" >{item.priority.name? stripHtmlAnd(stripHtml(item.priority.name.name)) : '-'}</span>
                                            </div>
                                            <div style={{backgroundColor: '#F0F1F3', borderRadius: '4px' }} className="mt-2 m-1 d-inline-block p-1">
                                                <Image src={TimeIcon}></Image>
                                                <span className="font-12 color-grey p-1" >{`Due: ${item.due?item.due :'-'}`}</span>
                                            </div>
                                            <div className="mt-2 pt-1 ml-1 mr-1 w-75 d-inline-block">
                                                <ProgressBar now={item.progress? parseInt(item.progress) : 0} style={{height:'8px'}}/>
                                            </div>
                                            <div className="mt-1 pt-1 font-12 font-w-400 d-inline-block ml-2">
                                                 <span>{item.progress? item.progress : 0} %</span>
                                            </div>
                                        </div>
                                    
                                        {item.assignee === "" ? null :
                                            item.assignee.name.length === 0 ? 
                                            <div className="pl-1 mt-2 mb-2" style={{height: "25px"}}>
                                                <Image src={AvatarIcon} width={24}></Image>
                                            </div>:
                                            <div className="p-1 mt-2 mb-2" style={{height: "25px"}}> 
                                                <div>
                                                    <Image src={AvatarIcon} width={24}></Image>
                                                    <span className="font-12 font-w-400 ml-2">{ 
                                                    item.assignee.name.map((val, index) => { 
                                                        return (
                                                            item.assignee.name.length > 1 ?
                                                            index < item.assignee.name.length-1?
                                                            val.label + ",\n" : val.label :  val.label
                                                        )
                                                    })}
                                                </span>
                                                </div>  
                                            </div> 
                                        }   
                                    </div>
                                </div>
                            </Draggable> 
                            ))}
                        </Droppable>  
                    </div> 
                </div> 
            ))}
        </div>    
    )
} 

export default Index