import React, {useEffect, useState, useContext, useRef} from 'react'
import {Modal, Nav, Row, Col, Form, Image } from 'react-bootstrap'  

//=========3RD LIBRARY========//
import _ from 'lodash'  
import Creatable from 'react-select/creatable';

//=========ICON LIST========// 
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'  
import {NotificationManager} from 'react-notifications' 
import SendIcon from '@material-ui/icons/Send';
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import {stylesSelectMulti} from "../../helpers/select/Select"
import {DashboardContext} from "../../context/DashboardContext" 
import AvatarIcon from "../../img/avatar-task.svg"
import RemoveIcon from "../../img/remove.svg" 
import { asyncRequest } from '../../helpers/utils/Utils';

const ModalMember = (props) =>{ 
    const ref = useRef()  
    const {params, member,setNewMember, boardUser, updateBoardUser, workspaceTeams,getWorkspaceTeams,
        boardActive, inviteMember,getBoardUser, sendNotif, setModalSpinner, showModalPricing} = useContext(DashboardContext)  
    const [memberList, setMemberList] = useState([])     
    const [selectedMember, setSelectedMember] = useState([])     
    const [selectedMemberTmp, setSelectedMemberTmp] = useState([])     
    const [loading, setLoading] = useState(false)     
    const [alert, setAlert] = useState({msg: 'Email invitation sent.', isShow: false, variant: "success"})

    useEffect(()=>{ 
        setAlert({...alert, isShow: false})
    },[props])

    useEffect(()=>{ 
        let tmpMember = []
        _.map(memberList,(item) =>{
            let isExist = _.some(selectedMember, function(i) {  
                return i.user_id == item.user_id
            }) 
            if(!isExist)
            tmpMember.push(item) 
        }) 

        setMemberList(tmpMember)
       
        
    },[selectedMember])

    useEffect(()=>{  
       
        let arrTmp = []
        member.map(item =>{ 
            if(item.is_active === true && item.is_active !== "Pending Invitation" ){
               let tmp = {user_id: item.user_id,email: item.email, value: item.user_id, label: item.username, workspace_id: params.workspace_id, board_id: boardActive.id, created_by: params.id}
                arrTmp.push(tmp)
            }
        }) 
        setMemberList(arrTmp)   

        arrTmp = [] 
        boardUser.map(item =>{ 
            if(item!== null){ 
                let permissionWokrspace = _.find(workspaceTeams, (data)=> {return data.user_id === item.id})
                let isFixed = false  
                if(item.id === params.id || item.type === "Pending Invitation" || params.permission_id > 3) isFixed = true
                if(permissionWokrspace && permissionWokrspace.permission_id === 1) isFixed = true
                if(params.permission_id === 3 && permissionWokrspace && permissionWokrspace.permission_id < 4) isFixed = true
                let tmp = {type : item.type, email: item.email, user_id: item.id,value: item.id, label: item.username, workspace_id: params.workspace_id, board_id: boardActive.id, created_by: params.id, is_fixed: isFixed}
                arrTmp.push(tmp)
            }
           
        }) 
        setSelectedMember(arrTmp)  
       

    },[boardUser])

  
    useEffect(()=>{  
        setMemberList([])
        setSelectedMember([])
        let arrTmp = []
        member.map(item =>{ 
            let isFixed = false  
            if(item.user_id === params.id) isFixed = true
            let tmp = {user_id: item.user_id,email: item.email, value: item.user_id, label: item.username, workspace_id: params.workspace_id, board_id: boardActive.id, created_by: params.id, is_fixed: isFixed}
            arrTmp.push(tmp)
        }) 
        setMemberList(arrTmp) 
    },[member])

  
    const handleChangeMember = async(selected) =>{  
        if(selected === null) selected = [] 
        if(selected[0].__isNew__){
            let label = selected[0].label
            let split = label.split(",")
            let invalid = false
            split.some(item =>{
                item = item.replace(",","") 
                item = item.replace(" ","")
                if(!validateEmail(item)){
                    NotificationManager.error(`Invalid email ${item}.`)
                    invalid = true
                    return
               }
               if(_.some(selectedMember, (select) => {return select.email === item})){
                    NotificationManager.error(`${item} already invited.`)
                    invalid = true
                    return
               }
            })  
            if(invalid) return
            setModalSpinner(true)
            const result =  await inviteMember(label)
           
            asyncRequest(1000).then(async()=>{
                setModalSpinner(false)
                if(result.message === "limit") {
                    showModalPricing(true)
                    return
                }
                if(result.status === 200){
                    NotificationManager.success(`Invitation sent.`)
                    await getBoardUser()
                } 
                else NotificationManager.error(result.message) 
                return
                
            })
            return
           
            
        }

         
        setModalSpinner(true) 
        let item = selected[0]
        item.action = "add"
        await updateBoardUser(item)
        await getBoardUser()
        await getWorkspaceTeams(item.workspace_id)
        asyncRequest(200).then(()=>{
            setModalSpinner(false)
        })
        
        selected.map(item => {
            let isExist = _.some(boardUser, function(i) {
                return i.id == item.value
            })
            if(isExist) return
            let wording = boardActive.type === 1 ? "Board Members " : "Dashboard Members "
            let data = {
                board_id : boardActive.id,
                receiver_id : item.value,
                sender_id: params.id, 
                workspace_id : boardActive.workspace_id ,
                team_id: boardActive.team_id,
                message : `${params.username} added you to the  ${wording} "${boardActive.title}" at ${boardActive.team.name} Team.`,
                is_read: false, 
                item_id: boardActive.id,
                created_at: new Date(),
                updated_at: new Date(),
                type: 2
            } 
            sendNotif(data) 
        }) 
      
    }
 
    const handleDelete = async (item) =>{ 
        setModalSpinner(true) 
        item.action = "delete"
        await updateBoardUser(item)
        await getBoardUser()
        asyncRequest(200).then(()=>{
            setModalSpinner(false)
        })
      
    }

    function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
 
    return (
        <Modal
        {...props}
        size=""
        id="modal-add-member"
        centered
        aria-labelledby="contained-modal-title-vcenter"> 
            <Modal.Header>
                <span className="modal-title">
                    {boardActive.type === 1 ? "Board Members" : "Dashboard Members"}
                </span> 
            </Modal.Header>
            <Modal.Body className="mt-1 ml-2 mr-2"> 

            {loading ?
                <Row className="h-100 justify-content-center align-items-center mb-3">
                    <div className="d-flex justify-content-center">
                        <span className="text-pink fa fa-spinner fa-spin fa-3x"></span>
                    </div> 
                </Row> 
            :""}
 
            {params.permission_id < 4 ?
            <>
                <Row hidden>
                    <Col md="10" className="mr-0 pr-0 mt-1">
                        <Form.Group controlId="formBasicEmail"> 
                            <Form.Control ref = {ref} className="font-13" name="email" type="email" placeholder="Invite new member by email" required  /> 
                        </Form.Group>
                    </Col>
                    <Col md="2" className="ml-0 pl-0">
                        <Nav.Link className="nav-menu text-center w-100"> 
                            <SendIcon style={{color: 'black', fontSize: 22 }}  fontSize="large"  className="align-text-top w-100 text-center"/>
                        </Nav.Link> 
                    </Col>
                </Row>
            <Row>
                <Col md="12" className="mb-3"> 
                    <span className="align-text-top font-14 mt-2">Anyone at <span className="font-weight-bold">{params.team? params.team.name  :""} </span>account can find and access this board</span>
                </Col>
                <Col md="12" className="mb-3 mt-1">
                    <Creatable styles={stylesSelectMulti}
                    formatCreateLabel={userInput => `Invite via email ${userInput}`}
                    placeholder ="Search member or enter email address" maxMenuHeight={150} options={memberList}
                    isMulti onChange={(selected)=>{handleChangeMember(selected)}}
                    value={selectedMemberTmp}
                    isClearable={false} 
                    />
                </Col>
                <Col hidden md="2" className="ml-0 pl-0">
                    <Nav.Link className="nav-menu text-center w-100"  onClick={()=>{setNewMember(true)}}> 
                        <AddCircleOutlineIcon style={{color: 'black', fontSize: 22 }}  fontSize="large"  className="align-text-top w-100 text-center"/>
                    </Nav.Link> 
                </Col>
            </Row>

            <Nav.Link className="nav-menu p-2 mt-2" hidden> 
                <MailOutlineIcon style={{color: 'black', fontSize: 20 }} className="align-text-top"/>
                <span className="workspace-item-name-child align-text-top ml-2 font-14">Invite new member by email</span>
            </Nav.Link> 
 
           
            </>:null}
            <div className="pb-3">
                {selectedMember.map((item, key) => { 
                    return(
                        <Nav.Link className={item.type === "Pending Invitation" ?"nav-menu-member-pending p-2 w-100 mt-2" : "nav-menu-member p-2 w-100 mt-2" } key={key}> 
                            <Row>
                                <Col md="12">
                                    <Image src={AvatarIcon} width={32}></Image>
                                    <span className="workspace-item-name-child align-text-top ml-2 font-14">{item.label}</span>
                                    <div hidden={item.type === "Pending Invitation" ? false : true}>
                                        <span className="ml-4 font-12 pl-3 color-grey">{item.type}</span>
                                    </div>  
                                    <Image hidden={item.is_fixed ? true : false} src={RemoveIcon} className="float-right align-middle mt-2 cursor-pointer" onClick={()=>handleDelete(item)}></Image>
                                </Col>  
                            </Row>
                        
                        </Nav.Link> 
                    )
                })}
            </div>
           
            </Modal.Body>
                  
        </Modal>
    )
}

export default ModalMember