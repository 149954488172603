//=========REACT INITIAL========//
import React, {useEffect, useContext, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'
  
//=========BOOTSTARP INITIAL========//
import {Row,Col,Form,Alert, Button} from 'react-bootstrap' 

//=========CONTEXT========//
import {DashboardContext} from "../../context/DashboardContext"  
import { MakeRequest } from "../../api/Api"  

//=========3RD LIBRARY========//
import _ from 'lodash'  
// import '../css/setting.css'  
import { useForm } from "react-hook-form"
import {NotificationManager} from 'react-notifications' 

const Password = () => {  
    const {params, setParams, setModalSpinner} = useContext(DashboardContext)  
    const [isLoading, setLoading] = useState(false) 
    const [alert, setAlert] = useState({msg: 'Change password success.', variant: 'success', hidden: true}) 
    const { register, handleSubmit, errors } = useForm()

   const onSubmit = async (param) => {
        param.id = params.id 
        param.email = params.email 
        if(param.password.length < 8) { 
            NotificationManager.error("Password minimal 8 character") 
            return
        }
        if(param.password !== param.confirm){
            NotificationManager.error("Password and confirm password does not match")
            return  
        }
    
        setLoading(true)
        setModalSpinner(true)
        const result = await MakeRequest('post','user-update-password', {param})  
        let debouncer = setTimeout(() => {
            setModalSpinner(false) 
            setLoading(false)
            if(result.status === 200){
                NotificationManager.success(result.message) 
            }else  NotificationManager.error(result.message) 

            }, 500);
            return () => {
            clearTimeout(debouncer);
        } 
    }

    return (
        <React.Fragment>   
            <Row className="pt-4 pl-2 bg-grey vh-100 ">
                <Col xs="5" className="w-100 p-2 ml-4">  
                    <div className="pl-5 pr-5 pt-3 pb-5 profile-container bg-white"> 
                        <Form onSubmit={handleSubmit(onSubmit)}> 
                            <Form.Group controlId="formBasicEmail" className="pl-3 pr-3 pb-0 pt-4"> 
                                <Form.Row>
                                    <Form.Label className="font-14 mt-2 text-dark font-w-400" style={{width:"150px"}}>Current Password</Form.Label>
                                    <Col>
                                    <Form.Control autoFocus={true} 
                                    className="mb-3 font-14" 
                                    type="password" name="currentPassword" ref={register({ required: true })}></Form.Control>
                                    {errors.title && <span>This field is required</span>}
                                    </Col>
                                </Form.Row>
                                <br />
                                <Form.Row>
                                    <Form.Label className="font-14 mt-2  text-dark font-w-400" style={{width:"150px"}}>New Password</Form.Label>
                                    <Col>
                                    <Form.Control autoFocus={true} 
                                    className="mb-3 font-14" 
                                    type="password" name="password"  ref={register({ required: true })}></Form.Control>
                                    {errors.title && <span>This field is required</span>}
                                    </Col>
                                </Form.Row>
                                <br />
                                <Form.Row>
                                    <Form.Label className="font-14 mt-2 text-dark font-w-400" style={{width:"150px"}}>Confirm Password</Form.Label>
                                    <Col>
                                    <Form.Control autoFocus={true} 
                                    className="mb-3 font-14" 
                                    type="password" name="confirm" ref={register({ required: true })}></Form.Control>
                                    {errors.title && <span>This field is required</span>} 
                                    </Col>
                                </Form.Row>
                                <br />
                            </Form.Group> 

                            <Button
                                variant="primary"
                                disabled={isLoading}
                                className="font-14 text-center float-right"
                                type="submit"
                                >
                                {isLoading ? 'Loading…' : 'Update'}
                            </Button> 

                        </Form>
                    </div> 
                </Col>
               
            </Row>
           
        </React.Fragment>
    )
}

export default Password