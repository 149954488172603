import React, {useEffect, useState, useContext, useRef} from 'react'
import {Modal, Button, Spinner } from 'react-bootstrap'  
import {DashboardContext} from "../../context/DashboardContext" 
import {asyncRequest } from '../../helpers/utils/Utils' 

//=========3RD LIBRARY========//
import _ from 'lodash' 

const ModalAddBoard = (props) =>{ 
    const [isLoading, setLoading] = useState(false)   
    const {showDeleteWorkspaceMember, setDeleteWorkspaceMember, updateWorkspaceTeams, getWorkspaceTeams} = useContext(DashboardContext)  
  
    const actionDelete = async () => {
        let param = _.cloneDeep(showDeleteWorkspaceMember.data)  
        param.is_delete = true  
        await updateWorkspaceTeams(param)
        await getWorkspaceTeams(param.workspace_id) 
        setLoading(true)
        asyncRequest(1000).then(()=>{
            setDeleteWorkspaceMember({isShow: false, actionDelete: false})
            setLoading(false)
        })  

    } 

    return (
        <Modal
        {...props}
        size=""
        centered
        aria-labelledby="contained-modal-title-vcenter"
        > 
            <Modal.Header>  
                    <span className="modal-title">
                        Confirmation
                    </span>
            </Modal.Header>
            <Modal.Body className="m-2"> 
                <span className="font-16">  {showDeleteWorkspaceMember.data ? "Delete workspace member " + showDeleteWorkspaceMember.data.user.username
                : "" } ? </span>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-cancel" onClick={props.onHide}>Cancel</Button>  
                <Button 
                    disabled={isLoading}
                    className="btn-yes text-white"
                    type="submit"
                    onClick={()=> actionDelete()}
                    >
                     {isLoading ?
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                            : "Delete" 
                        }
                </Button> 
            </Modal.Footer>  
        </Modal>
    )
}

export default ModalAddBoard