//=========REACT INITIAL========//
import React, {useEffect, useContext, useRef, useState} from 'react' 
//=========ICON INITIAL========//  
import AvatarIcon from "../../img/avatar.svg"
import PersonIcon from "../../img/person.svg"
import EmailIcon from "../../img/email.svg"
import PhoneIcon from "../../img/phone.svg"
import LocationIcon from "../../img/location.svg"
import BoardIcon from "../../img/board.svg"
import DashboardIcon from "../../img/dashboard.svg"

//=========BOOTSTARP INITIAL========//
import {Row,Col,Image} from 'react-bootstrap' 
import {Style} from "../../helpers/style/Style"
import {initialString } from '../../helpers/utils/Utils' 

//=========CONTEXT========//
import {DashboardContext} from "../../context/DashboardContext"  
import { MakeRequest } from "../../api/Api"  

//=========3RD LIBRARY========//
import _ from 'lodash'  
// import '../css/setting.css' 
import ContentEditable from 'react-contenteditable' 

const Personal = () => {  
    const {params, setParams, teams, getWorkspaceProfile, workspaceProfile, setRating} = useContext(DashboardContext)  
    const [state, setState] = useState({})  
    const [board, setBoard] = useState([])  
    const refTitle = useRef("")
    const refPhone= useRef("")
    const refLocation = useRef("")
    const isFirstRun = useRef(true)
     
    useEffect(()=>{  
        let state = {}
        state.id = params.id
        state.email = params.email 
        state.phone = params.phone 
        state.title = params.title 
        state.location = params.location  
        state.username = params.username  
        setState(state)  

        if(isFirstRun.current &&  params.id ){
            const getData = async()=>{
                await getWorkspaceProfile()
                isFirstRun.current = false
            } 
            getData()
        }
     
    },[params])
  
    useEffect(()=>{
        let boardTMp = [], taskTmp = []
        workspaceProfile.map(item =>{
            item.board.map(boardItem=>{
                let tmp = {}
                tmp.name = boardItem.title
                tmp.id = boardItem.id 
                tmp.type = boardItem.type
                tmp.is_delete =  boardItem.is_delete
                boardTMp.push(tmp)
                boardItem.table.map(table =>{
                    table.rows.map((row, index) => {
                        if(index === 0) return
                        row.board = boardItem.title
                        row.board_id = boardItem.id 
                        row.map(rowItem => {
                            if(rowItem.type === "person"){
                                if(typeof(rowItem.name) === "object"){
                                    rowItem.name.map(user =>{ 
                                       if(user.value === params.id) taskTmp.push(row)
                                    })
                                } 
                            }
                        })
                        
                    })
                })
            })
        }) 

       
        let rating = 0, count = 0, ratingBoard = []
        taskTmp.map(item => { 
            item.map(rowItem => {
                if(rowItem.type === "rating"){ 
                    if(rowItem.name !== "" &&  rowItem.name !== "0"){  
                        let tmp = {}
                        tmp.board_id = item.board_id
                        tmp.board = item.board
                        tmp.count = parseInt(rowItem.name)
                        ratingBoard.push(tmp)
                        rating += parseInt(rowItem.name)
                        count++
                    }
                   
                }
            })
        }) 

        const ratingPerBoard = _.chain(ratingBoard)
            .groupBy("board_id")
            .map(function(value, key) {
                let rating = _.sumBy(value, 'count') / value.length
                return {
                    board_id: value[0].board_id, 
                    board: value[0].board, 
                    rating: rating
                }
            })
            .value() 

        _.map(boardTMp, (item)=>{
            _.map(ratingPerBoard, (filter)=> {  
                if(filter.board_id === item.id) item.rating = filter.rating
            }) 
        })  
        setBoard(boardTMp)  

        let fixedRating = (rating/count).toFixed(1)
        if(taskTmp.length)
        if(fixedRating !== NaN) setRating(fixedRating)

    },[workspaceProfile])
    
    const saveProfile = async()=>{
        if(state.email !== undefined){
            let param = {}
            param.id = params.id
            param.title = refTitle.current.lastHtml
            param.phone = refPhone.current.lastHtml
            param.location = refLocation.current.lastHtml
            console.log("paramupdateuser", param)
            const result = await MakeRequest('post','user-update', {param})   
            if(result.status === 200){
                let newParams = _.cloneDeep(params)
                newParams.title = param.title
                newParams.phone = param.phone 
                newParams.location = param.location 
                setParams(newParams)
                localStorage.setItem('saatkerja', JSON.stringify(newParams))  
            }
        }
    }
 

   
    return (
        <React.Fragment>   
            <Row className="bg-grey vh-100">
                <Col xs="3" className="w-100 bg-white"> 
                    <div className="p-4 text-center mt-4">   
                        <div>
                            <Image src={AvatarIcon}></Image>
                        </div>
                        <div className="mt-2">
                            <span className=" text-dark font-16 font-w-400">{state.username}</span>
                        </div>
                        
                    </div>
                    <div className="pt-4 pl-3"> 
                        <div> 
                            <Image src={PersonIcon}></Image> 
                            <div className="d-inline ml-3">
                                <span className="font-14 font-w-400 color-grey">Title</span>
                                <div>
                                    <ContentEditable 
                                        html= {state.title}
                                        className="font-14 editable-border ml-2 text-dark font-w-400 ml-4"  
                                        onBlur = {(e)=>saveProfile()} 
                                        ref ={(r)=> refTitle.current = r} 
                                    />  
                                </div> 
                            </div> 
                        </div>   
                        <div className="mt-4"> 
                            <Image src={EmailIcon}></Image> 
                            <div className="d-inline ml-3">
                                <span className="font-14 font-w-400 color-grey">Email</span>
                                <div>
                                    <ContentEditable 
                                        html= {state.email}
                                        className="font-14 ml-2 text-dark font-w-400 ml-4"  
                                        disabled ={true}
                                    />  
                                </div> 
                            </div> 
                        </div>   
                        <div className="mt-4"> 
                            <Image src={PhoneIcon}></Image> 
                            <div className="d-inline ml-3">
                                <span className="font-14 font-w-400 color-grey">Phone</span>
                                <div>
                                    <ContentEditable 
                                        html= {state.phone}
                                        className="font-14 editable-border ml-2 text-dark font-w-400 ml-4"  
                                        onBlur = {(e)=>saveProfile()} 
                                        ref ={(r)=> refPhone.current = r}    
                                    />  
                                </div> 
                            </div> 
                        </div>   
                        <div className="mt-4"> 
                            <Image src={LocationIcon}></Image> 
                            <div className="d-inline ml-3">
                                <span className="font-14 font-w-400 color-grey">Location</span>
                                <div>
                                    <ContentEditable 
                                        html= {state.location}
                                        className="font-14 editable-border ml-2 text-dark font-w-400 ml-4"   
                                        onBlur = {(e)=>saveProfile()} 
                                        ref ={(r)=> refLocation.current = r}    
                                    />  
                                </div> 
                            </div> 
                        </div>   
                    </div>  
                </Col>

                <Col xs="4">
                    <div className="profile-container mt-4">
                        <div className="title-profile">
                            <span className="font-16 text-dark">Teams</span>
                        </div>
                         
                        <div className="mt-3 pb-3 ml-3 item-profile"> 
                            {teams.map(item => { 
                                return (
                                    <div className="cursor-pointer p-2" key={item.id}>
                                        <Row>
                                            <Col md="2">
                                                <span style={Style.circleTextTeams}>
                                                    <span style={Style.circleTextTeamsSpan}>{initialString(item.team.name)}</span>
                                                </span>
                                            </Col>
                                            <Col className="col pr-1 pl-1 h-100 m-auto">
                                                <div className="d-inline mt-2">
                                                    <span className="pl-2 font-14 font-w-500 text-dark">{item.team.name}</span>
                                                </div> 
                                            </Col>
                                        </Row> 
                                    </div>
                                )
                            })} 
                        </div> 
                    </div>
                
                    <div className="profile-container mt-4">
                        <div className="title-profile">
                            <span className="font-16 text-dark">Workspace</span>
                        </div>
                         
                        <div className="mt-3 pb-3 ml-3 item-profile"> 
                            {workspaceProfile.map(item => { 
                                return (
                                    <div className="cursor-pointer p-2">
                                        <Row>
                                            <Col md="2">
                                                <span style={Style.circleText}>
                                                    <span style={Style.circleTextSpan}>{initialString(item.name)}</span>
                                                </span>
                                            </Col>
                                            <Col className="col pr-1 pl-1 h-100 m-auto">
                                                <div className="d-inline mt-2">
                                                    <span className="pl-2 font-14 font-w-500 text-dark">{item.name}</span>
                                                </div> 
                                            </Col>
                                        </Row> 
                                    </div>
                                )
                            })} 
                        </div> 
                    </div>

                </Col>

                <Col xs="4">
                    <div className="profile-container mt-4">
                        <div className="title-profile">
                            <span className="font-16 text-dark">Bord</span>
                        </div>
                         
                        <div className="mt-3 pb-3 ml-3 item-profile"> 
                            {board.map(item => {  
                                if(item.type === 2) return
                                return (
                                    <div className="cursor-pointer p-2" key={item.id}>
                                        <Row>
                                            <Col md="1">
                                                <Image src={BoardIcon}></Image>
                                            </Col>
                                            <Col className="col pr-1 pl-1 h-100 m-auto">
                                                <div className="d-inline mt-2">
                                                    <span className="pl-2 font-14 font-w-500 text-dark">{item.name}</span>
                                                </div> 
                                                {item.is_delete? <span className="font-14 ml-2 mr-2 float-right font-w-500  text-danger">Deleted</span> :
                                                <span className="font-14 ml-2 mr-2 font-w-500 float-right text-success">Open</span>}                                    
                                            </Col>
                                        </Row> 
                                    </div>
                                )
                            })} 
                        </div> 
                    </div>
                
                    <div className="profile-container mt-4">
                        <div className="title-profile">
                            <span className="font-16 text-dark">Dashboard</span>
                        </div>
                         
                        <div className="mt-3 pb-3 ml-3 item-profile"> 
                            {board.map(item => {  
                                if(item.type === 1) return
                                return (
                                    <div className="cursor-pointer p-2" key={item.id}>
                                        <Row>
                                            <Col md="1">
                                                <Image src={DashboardIcon}></Image>
                                            </Col>
                                            <Col className="col pr-1 pl-1 h-100 m-auto">
                                                <div className="d-inline mt-2">
                                                    <span className="pl-2 font-14 font-w-500 text-dark">{item.name}</span>
                                                </div>  
                                                {item.is_delete? <span className="font-14 ml-2 mr-2 float-right font-w-500  text-danger">Deleted</span> :
                                                <span className="font-14 ml-2 mr-2 font-w-500 float-right text-success">Open</span>}                                    
                                            </Col>
                                        </Row> 
                                    </div>
                                )
                            })} 
                        </div> 
                    </div>

                </Col>
 
            </Row>
             
        </React.Fragment>
    )
}

export default Personal