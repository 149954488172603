//=========REACT INITIAL========//
import React, {useRef, useEffect, useState, useContext} from 'react'   
import {OverlayTrigger, Tooltip} from 'react-bootstrap'  
import {DashboardContext} from "../../context/DashboardContext" 

const Battery  = (props) => {
    const donePercent = useRef("") 
    const [data, setData] = useState([])
    const sumData = useRef("") 
    const { setFullscreenWidget} = useContext(DashboardContext)  

    useEffect(()=>{
        donePercent.current = ""
        let sum = props.data.reduce((s, f) => s + f.y, 0); 
        sumData.current = sum    
        setData(props.data)
    },[props])

    return (
        <React.Fragment>   
            <div className="w-100 pt-5 text-center"> 
                <div className="battery-container d-inline-block align-middle" style={{height: props.height}}>
                    {data.map((item, key) => {  
                        let width = ((item.y / sumData.current) * 100)  
                        if(item.name.toLowerCase().trim() === "done") donePercent.current = `${width.toFixed(0)}% Done`
                        let style = {width : `${width}%`, backgroundColor: item.color} 
                        return ( 
                            <OverlayTrigger key = {key} trigger={["hover","focus"]} placement="top" overlay={
                                <Tooltip id='tooltip'>
                                    { `${width.toFixed(0)}% ${item.name} `}
                                </Tooltip>
                            } rootClose>
                                <div key={key} 
                                // onClick={()=> setFullscreenWidget({fullscreen: true, dashboard: dashboard[key], key, category: this.category})}
                                 className="d-inline-block h-100 cursor-pointer hover-battery" style={style}></div> 
                            </OverlayTrigger>
                            )
                    })} 
                </div>  
                <div className="d-inline-block h-100">
                    <span className="battery"></span>
                </div>
                <div className="d-inline-block ml-4">
                    <span className="font-16 font-w-400">{donePercent.current} </span>
                </div>

                
                <div className="mt-5">
                {data.map((item, key) => {  
                    return (
                        <div key={key} className={props.className}>
                            <span className="circle align-middle" style={{backgroundColor : item.color}}></span>
                            <span className="font-12 pl-1 align-middle">{item.name}</span>
                        </div> 
                    ) 
                })}
                </div>
            </div> 
        </React.Fragment>
    )
}


export default Battery
