import React, { Component } from 'react'; 
import HeroImg from '../../img/img-header.svg'; 
import {useHistory} from 'react-router-dom' 

export const Hero = () => {
  const history = useHistory()  

  const handleSubmit = async e => {
    e.preventDefault()
    history.push('/register') 
  }
 
    return (
      <div className="container" id="product">
        <div className="hero-container">
          <div className="row mt-5">
            <div className="col-md-5" id="perent-display-text-hero">
              <span className="display-text" id="display-text-hero">
              Flexible
              Project Management
              Software
              </span>
            
              <p className="hero-text" id="ya-display-text-hero">
              Provides convenience in managing scheduling, planning and realization, monitoring of standard reports on work activities
              </p>
              <div className="btn-free cursor-pointer" id="btn-display-text-hero" onClick={handleSubmit} >
                <span ClassName="hero-text2" id="text2-display-text-hero">Get Started for Free</span>
              </div>
            </div>
            <a className='btn-whatsapp' target="_blank" rel="noopener noreferrer" href="https://web.whatsapp.com/send?phone=6281294954952&text=Hallo admin Saat Kerja?!" style={{color: "red"}}><i className="fa fa-whatsapp fa-lg my-float"></i></a>

            <div className="col" >
              <img id="img-display-image-hero" src={HeroImg}></img>
            </div>
          </div>  
        </div>
      </div>
    );
  } 

export default Hero