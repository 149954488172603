import React, {useState, useContext, useEffect} from 'react'
import {Col, Row, Form, Image, Button, Alert, Spinner } from 'react-bootstrap' 
import ImageRegister from "../../img/register-img.svg" 
import LoginIcon from "../../img/login-icon.svg" 
import "../css/login.css"
import { useForm } from "react-hook-form"
import { MakeRequest } from "../../api/Api"  
import {useHistory} from 'react-router-dom'  
import {asyncRequest } from '../../helpers/utils/Utils'  
import LockIcon from "../../img/lock.svg" 

const Register = () =>{ 
    const history = useHistory()
    const [isLoading, setLoading] = useState(false) 
    const [alert, setAlert] = useState({msg: 'Register success.', variant: 'success', hidden: true}) 
    const { register, handleSubmit, watch, errors } = useForm() 
    const [typePassword, setTypePassword] = useState("password") 
    const [promo, setPromo] = useState("") 

    useEffect(()=>{
        if(history.location.state)
        setPromo(history.location.state) 
    })

    const onSubmit = async (params) => {
        params.email = params.email.trim()
        params.promo = promo
        setAlert({msg: 'Register success.', variant: 'success', hidden: true}) 
        setLoading(true)
  
        const result = await MakeRequest('post','user-create', params)   
        if(result.status !== 200) {
            asyncRequest(1000).then(()=>{
                let detailError = result.data.detail
                setAlert({msg: detailError === undefined ? "Server error": detailError, variant: 'danger', hidden: false})
            })  
        }else{
            asyncRequest(1000).then(() => {  
                setAlert({msg: 'Register successfully.', variant: 'success', hidden: false}) 
            })
            
            asyncRequest(1000).then(() => {  
                history.push('/login')
            })
        }
        asyncRequest(1000).then(()=>{
            setLoading(false)
        }) 
        
    } 

    return(
        <Row xs="12" className="no-gutters vh-100 vw-100">
           <Col md={7}> 
            <Image src={LoginIcon} className="mt-4 ml-4 img-fluid cursor-pointer"  onClick={()=>history.push('/')}></Image>
            <Row className="h-75 text-center">
                <Col md={12} className="m-auto pt-5">   
                    <Image src={ImageRegister} className="img-fluid"></Image>
                </Col> 
            </Row>
            </Col> 
            <Col md={5} className="min-vh-100 "> 
                <div className="m-3 bg-login h-95">
                    <Row className="h-100">
                        <Col md={12} className="m-auto p-5">  
                            <div className="p-3">
                                <div className="txt-login">
                                    <span>Create Account</span>
                                </div>
                                <div className="mt-5">
                                    <Form onSubmit={handleSubmit(onSubmit)}>  
                                        <Form.Group controlId="formBasicEmail"> 
                                            <Form.Control className="font-16 input-text" name="email" type="email" placeholder="Email" ref={register({ required: true })} />
                                            <div className="mt-3">
                                                {errors.email && <span>This field is required</span>}
                                            </div>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicEmail" className="pt-2"> 
                                            <Form.Control className="font-16 input-text" name="username" type="text" placeholder="Name" ref={register({ required: true })} />
                                            <div className="mt-3">
                                                {errors.username && <span>This field is required</span>}
                                            </div>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicEmail" className="pt-2"> 
                                            <div className="pass-wrapper">
                                                <Form.Control className="font-16 input-text" name="password" type={typePassword} placeholder="Password" ref={register({ required: true , minLength: 8})} />
                                                {typePassword === "password" ? 
                                                    <Image src={LockIcon} onClick={()=> setTypePassword("text")} className="eye-icon"></Image>
                                                :
                                                    <Image src={LockIcon} onClick={()=> setTypePassword("password")} className="eye-icon"></Image>
                                                }
                                            </div>
                                            <div className="mt-3">
                                                {errors.password && <span>This field is required / minimal 8 character</span>}
                                            </div>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicEmail" className="pt-2"> 
                                            <Form.Control className="font-16 input-text" name="team" type="text" placeholder="Team Name" ref={register({ required: true})} />
                                            <div className="mt-3">
                                                {errors.team && <span>This field is required</span>}
                                            </div>
                                        </Form.Group> 
                                        <Form.Group controlId="formBasicCheckbox" className="checkbox pt-2">
                                            <div className="d-inline "> 
                                                <Form.Check type="checkbox" defaultChecked={true} className="font-16 h-100 d-inline cursor-pointer" />
                                                <span className="font-16 ml-2 color-grey-darker font-w-400">By creating an account, you agree to the Terms of Service and Condition, and Privacy Police</span>
                                            </div>    
                                            
                                        </Form.Group>
                                        <Form.Group className="pt-2" hidden={alert.hidden}>  
                                            <Alert variant={alert.variant} >
                                                <span className="font-15">{alert.msg}</span>
                                            </Alert>
                                        </Form.Group> 
                                        <div className="w-100 pt-2">
                                            <Button
                                                variant="primary"
                                                disabled={isLoading}
                                                className="font-16 text-white  font-weight-bold btn-login"
                                                type="submit" 
                                                >
                                                {isLoading ?
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        />
                                                    : "Create" 
                                                }
                                                </Button> 
                                        </div> 
                                    </Form> 
                                    <div className="mt-5 txt-login-child">
                                    <span className="color-grey-darker">Already have an account? </span> <br></br>
                                    <span className="cursor-pointer text-primary" onClick={()=>{history.push({pathname:'/login', state: promo})}}> Login</span>
                                </div> 
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Col>
       </Row>
    )
}

export default Register