import React, { useContext, useEffect, useState } from 'react'
import { Style } from "../../helpers/style/Style"
//=========BOOTSTARP INITIAL========//
import { Col, Row, FormControl, Button, Image, Card } from 'react-bootstrap'
import InputGroup from 'react-bootstrap/InputGroup'
import SearchIcon from '../../img/search.svg'
import AvatarIcon from "../../img/avatar.svg"
import PersonIcon from "../../img/person.svg"
import CheckIcon from "../../img/check.svg"

//=========CONTEXT========//
import { DashboardContext } from "../../context/DashboardContext"

const Chat = () => {
    const { params, getMember, member, permission,
        flagNewMember, selectedTeams, updateTeams,
        getTeams, setShowMemberTeam, teams, modalSpinner, setModalSpinner, selectedTeamsSetting, setSelectedTeamsSetting,
        setEditTeamMember, setShowDeleteTeams, showDeleteTeams, setDeleteTeamMember } = useContext(DashboardContext)

    useEffect(() => {
        if (!teams.length) return
        setSelectedTeamsSetting(teams[0].team)
    }, [teams])

    useEffect(() => {
        if (selectedTeams && selectedTeams.value) getDataMember();



    }, [flagNewMember])

    const getDataMember = async () => {
        await getMember(selectedTeams.value)
    }
    return (
        <React.Fragment>
            <Row className="vh-100">
                <Col className="d-flex">
                    {/* side */}
                    <div
                        className="ml-4  bg-white vh-100"
                        style={{ width: '30%', borderRight: '1px solid rgb(230, 233, 239)' }}
                    >
                        {/* header */}
                        <div className='d-flex p-3  flex-column'>
                            <span className="font-20 ml-3 mb-2 font-w-600 text-dark">Message</span>
                            <InputGroup className="mt-4  pr-4 ml-3">
                                <FormControl
                                    className='font-14 border-right-0'
                                    placeholder="Search Message or User ..."
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                />
                                <InputGroup.Append>
                                    <Button
                                        className='bg-white font-14 border-left-0 '
                                        style={{ borderColor: '#ced4da' }}
                                    >
                                        <Image src={SearchIcon} ></Image>
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </div>
                        {/* end header */}

                        {/* contact */}
                        <div className='mt-1 pt-2'
                            style={{ overflow: 'auto', height: '100vh' }}>

                            {/* body person */}
                            <div className='pl-4 pt-3 bg-white'
                                style={{ height: '100px', cursor: 'pointer' }}>
                                {/* header cntact */}
                                <div className='d-flex justify-content-between '>
                                    <div className='d-flex align-items-center '>
                                        <Image src={AvatarIcon} width={25}></Image>
                                        <span className='font-14 font-w-600 ml-2'>Alwi Gunawan</span>
                                    </div>
                                    <div className='d-flex align-items-center '>
                                        <span className='font-12 mr-3'>Just Now</span>
                                    </div>
                                </div>
                                {/* end header contact */}

                                {/* body contact */}
                                <div className='d-flex mt-2 justify-content-between '>
                                    <div className='d-flex align-items-center'
                                        style={{ width: '80%' }}>
                                        <p className='font-14  ml-2 d-inline-block text-truncate'>
                                            Lorem ipsum dolor sit amet, consectetur adip lorem ips lorem et
                                            lorem ipsum dolor sit amet, consectetur adip
                                            Lorem ipsum dolor sit amet, consectetur adip lorem ips lorem et
                                            lorem ipsum dolor sit amet, consectetur adip
                                            Lorem ipsum dolor sit amet, consectetur adip lorem ips lorem et
                                            lorem ipsum dolor sit amet, consectetur adip
                                        </p>
                                    </div>
                                    <div className='d-flex justify-content-end mr-3'
                                        style={{ width: '20%' }}>
                                        <span style={Style.circleTextWorkspaceSmall}>
                                            <span style={Style.circleTextWorkspaceSpanSmall}>{"1"}</span>
                                        </span>
                                    </div>
                                </div>
                                {/* end body contact */}
                            </div>
                            {/* end body person */}

                        </div>
                        {/* end contact */}

                    </div>
                    {/* end side */}

                    {/* ------------- */}
                    {/* content */}
                    <div
                        className="pt-3 bg-grey "
                        style={{ width: '70%', position: 'relative', overflowY: 'auto', overflowX: 'hidden', height: '100vh' }}>

                        {/* date */}
                        <div
                            className="w-100 mt-4 d-flex justify-content-center">
                            <div className="w-20 pt-2 pb-2 pl-3 pr-3"
                                style={{ borderRadius: '50px', backgroundColor: '#E5E5E5' }}
                            >
                                <span>February 23</span>
                            </div>
                        </div>
                        {/* end date */}

                        {/* meessage left */}
                        <div
                            className="w-100 ml-3 mt-4 d-flex flex-column justify-content-start">
                            <div className='d-flex justify-content-between '>
                                <div className='d-flex align-items-center '>
                                    <Image src={AvatarIcon} width={25}></Image>
                                    <span className='font-14 font-w-600 ml-2'>Alwi Gunawan</span>
                                </div>
                            </div>
                            <div
                                className="border pt-2 ml-4 mt-2  pb-2 pl-3 pr-3"
                                style={{ borderRadius: '0 15px 15px 15px', maxWidth: '50%', backgroundColor: 'white' }}
                            >
                                <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                                    In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                                    In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                                    In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.

                                </p>
                            </div>
                        </div>
                        {/* end meessage leeft */}

                        {/* meessage right */}
                        <div
                            className="w-100 mt-4 p-2 d-flex mr-2 justify-content-end">
                            <div
                                className="pt-3 mr-1 mt-2 bg-primary  pl-3 pr-3"
                                style={{ borderRadius: '10px 10px 0 10px', maxWidth: '50%', backgroundColor: 'white' }}>
                                <p className="text-white">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                                </p>
                            </div>
                            <div
                                className="d-flex pt-3 mr-1 mt-2 ml-1 align-items-end">
                                <Image className="mb-3" src={CheckIcon}></Image>
                            </div>
                        </div>
                        <div
                            className="w-100 mt-4 p-2 d-flex mr-2 justify-content-end">
                            <div
                                className="pt-3 mr-1 mt-2 bg-primary  pl-3 pr-3"
                                style={{ borderRadius: '10px 10px 0 10px', maxWidth: '50%', backgroundColor: 'white' }}>
                                <p className="text-white">In publishing and graphic design.
                                </p>
                            </div>
                            <div
                                className="d-flex pt-3 mr-1 mt-2 ml-1 align-items-end">
                                <Image className="mb-3" src={CheckIcon}></Image>
                            </div>
                        </div>
                        <div
                            className="w-100 mt-4 p-2 d-flex mr-2 justify-content-end">
                            <div
                                className="pt-3 mr-1 mt-2 bg-primary  pl-3 pr-3"
                                style={{ borderRadius: '10px 10px 0 10px', maxWidth: '50%', backgroundColor: 'white' }}>
                                <p className="text-white">In publishing and graphic design.
                                </p>
                            </div>
                            <div
                                className="d-flex pt-3 mr-1 mt-2 ml-1 align-items-end">
                                <Image className="mb-3" src={CheckIcon}></Image>
                            </div>
                        </div>
                        <div
                            className="w-100 mt-4 p-2 d-flex mr-2 justify-content-end">
                            <div
                                className="pt-3 mr-1 mt-2 bg-primary  pl-3 pr-3"
                                style={{ borderRadius: '10px 10px 0 10px', maxWidth: '50%', backgroundColor: 'white' }}>
                                <p className="text-white">In publishing and graphic design.
                                </p>
                            </div>
                            <div
                                className="d-flex pt-3 mr-1 mt-2 ml-1 align-items-end">
                                <Image className="mb-3" src={CheckIcon}></Image>
                            </div>
                        </div>
                        {/* end meessage right */}

                        {/* type text */}
                        <div
                            className="w-100  bg-white p-3"
                            style={{ position: 'sticky', bottom: '0px', right: '5px' }}
                        >
                            <InputGroup className="">
                                <FormControl
                                    className='font-14 border-0'
                                    placeholder="Write Your Message"
                                />

                            </InputGroup>
                        </div>
                        {/* end type text */}

                    </div>
                    {/* endcontent */}

                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Chat
