import React, {useEffect, useContext, useState} from 'react'
import {Modal, Row, Col, Image,ProgressBar} from 'react-bootstrap'  
import _ from 'lodash'  
import {DashboardContext} from "../../context/DashboardContext" 
 
import StatusIc from "../../img/status_ic.svg"   
import DateIc from "../../img/date_ic.svg"  
import TimelineIc from "../../img/timeline_ic.svg"   
import TextIc from "../../img/text_ic.svg"  
import ParagraphIc from "../../img/paragraph_ic.svg"  
import ProgressIc from "../../img/progress_ic.svg"  
import WeekIc from "../../img/week_ic.svg"  
import RattingIc from "../../img/ratting_ic.svg"  
import PriorityIc from "../../img/priority_ic.svg"  
import LastIc from "../../img/last_ic.svg"  
import CreationIc from "../../img/creation_ic.svg"   
import Rating from "react-rating"
import NumberIc from "../../img/number.svg"    
import RightIc from "../../img/right-ic.svg"  
import AvatarIcon from "../../img/avatar.svg"   
import { stripHtmlAnd } from '../../helpers/utils/Utils'

const ModalDetailKanban = (props) =>{  
    const [header, setHeader] = useState([])
    const [data, setData] = useState([])
    const [title, setTitle] = useState("")
    const [parent, setParent] = useState("")
    const [group, setGroup] = useState("")
    const [person, setPerson] = useState("")
    const {showDetailKanban, getTable} = useContext(DashboardContext)  
        
    const onExitModal = ()=>{
        getTable()
    }

    useEffect(()=>{  
        let rows =  showDetailKanban.data.rows 
       
        if(rows){ 
            setParent(showDetailKanban.parent)
            rows.map((item, key)=>{
                if(key === 0){
                    let tmp = []
                    item.map((data, index) => {
                        data.name = stripHtmlAnd(data.name)
                        if(data.type === "status") data.img = StatusIc
                        if(data.type === "priority") data.img = PriorityIc
                        if(data.type === "date") data.img = DateIc
                        if(data.type === "duedate") data.img = DateIc
                        if(data.type === "timeline_duedate") data.img = TimelineIc
                        if(data.type === "timeline") data.img = TimelineIc
                        if(data.type === "progress") data.img = ProgressIc
                        if(data.type === "rating") data.img = RattingIc
                        if(data.type === "creation_log") data.img = CreationIc
                        if(data.type === "last_update") data.img = LastIc
                        if(data.type === "paragraph") data.img = ParagraphIc
                        if(data.type === "text") data.img = TextIc
                        if(data.type === "number") data.img = NumberIc
                        if(data.type === "week") data.img = WeekIc
                        if(index === 1) setGroup(data.name)
                        if(index > 1 && data.type !== "person" && data.type !== "subitemscontroll" && data.type !== "add")
                        tmp.push(data)
                    })
                    setHeader(tmp)
                }
                if(key === 1){
                    let tmp = []
                    item.map((data, index) => {
                        if(index === 1) {
                            console.log(data)
                            setTitle(data.name) 
                        }
                        if(data.type === "person") setPerson(data.name.length ? data.name[0].label : "")
                        if(index > 1 && data.type !== "person" && data.type !== "subitemscontroll" && data.type !== "add")
                        tmp.push(data)
                    })
                    setData(tmp)
                }
            })
         
        }
    },[props])

    return (
        <Modal
        {...props} 
        id="modal-detail-kanban" 
        autoFocus={false}
        enforceFocus= {false}
        onExit= {onExitModal}
        aria-labelledby="contained-modal-title-vcenter"
        > 
            <Modal.Header>
                <div className="ml-2">
                    <Image src={AvatarIcon} width={35}></Image>
                    <span className="font-14 font-w-600 ml-2">{person}</span>
                </div>
            </Modal.Header>
            <Modal.Body>   
                {/* <Content item= {showDetailKanban.data} fromKanban = {true}/>  */}
                <div className="p-3">
                    <span className="font-13 font-w-400">{group}</span>
                  
                    {parent ? 
                    <React.Fragment>
                        <Image className="ml-2 mr-2" src={RightIc}></Image>
                        <span className="font-13 font-w-400">{parent}</span>
                        <Image className="ml-2 mr-2" src={RightIc}></Image>
                        <span className="font-13 font-w-400">{title}</span>
                    </React.Fragment>
                    
                    : <React.Fragment>
                            <Image className="ml-2 mr-2" src={RightIc}></Image>
                            <span className="font-13 font-w-400">{title}</span>
                    </React.Fragment>}
                </div>
                <Row>
                    <Col md="4">
                        {header.map((item, key) => {
                            return (
                                <div className={item.type === "paragraph" ? "d-flex p-3 mb-5 pb-5" : "d-flex p-3"} key={key}>
                                    <Image src={item.img}></Image>
                                    <span className="font-13 font-w-400 ml-2">{item.name}</span>
                                </div>
                            )
                        })}
                       
                    </Col>
                    <Col md="8">
                        {data.map((item, key) => { 
                                return (
                                    <div key={key}>
                                        {item.type === "status"?
                                        <div className="d-flex pt-1">
                                            <div className="name-cell-text-color w-100" style={{backgroundColor: item.name.color? item.name.color : "rgb(196, 196, 196)"}}>  
                                                <span className="p-2 span-label">{item.name.name}</span>
                                            </div> 
                                        </div>:<></>}

                                        {item.type === "priority"?
                                        <div className="d-flex pt-1">
                                            <div className="name-cell-text-color w-100" style={{backgroundColor: item.name.color? item.name.color : "rgb(196, 196, 196)"}}>  
                                                <span className="p-2 span-label">{item.name.name}</span>
                                            </div> 
                                        </div>:<></>}

                                        {item.type === "date" || item.type === "week" || item.type === "number" || item.type === "creation_log" || item.type === "last_update"  || item.type === "text"?
                                        <div className="d-flex pt-2">
                                            <div className="name-cell-text-color text-left justify-content-start  w-100" >  
                                                <span className="text-dark span-label">{item.name}</span>
                                            </div>
                                        </div>:<></>}

                                        {item.type === "duedate"?
                                        <div className="d-flex pt-2">
                                            <div className="name-cell-text-color text-left justify-content-start w-100" >  
                                                <span className="text-dark span-label">{item.name}</span>
                                            </div>
                                        </div>:<></>}

                                        {item.type === "timeline_duedate" || item.type === "timeline"?
                                        <div className="d-flex pt-2">
                                            <div className="name-cell-time-line text-center w-100 " >  
                                                <span className="p-2 span-label">{item.name}</span>
                                            </div>
                                        </div>:<></>}

                                        {item.type === "progress"?
                                        <div className="d-flex pt-2">
                                            <div className="name-cell-text-color text-center w-100" >  
                                                <div className="w-75 text-center d-inline-block">  
                                                        <ProgressBar now={item.name} style={{height: '12px'}}/>  
                                                    </div>  
                                                <span className="w-25 font-12 text-right float-right text-dark">{item.name} %</span>
                                            </div>  
                                        </div>:<></>}

                                        {item.type === "rating"?
                                        <div className="d-flex pt-1">
                                            <div className="name-cell-text-color justify-content-start w-100" >  
                                                <Rating
                                                    emptySymbol="fa fa-star-o fa-2x font-18"
                                                    fullSymbol="fa fa-star fa-2x color-rating font-18"  
                                                    readonly = {true}
                                                    initialRating={item.name}
                                                    fractions={1}
                                                />
                                            </div>  
                                        </div>:<></>}

                                        {item.type === "paragraph"?
                                        <div className="d-flex pt-2">
                                            <div className="name-cell-text-color text-dark text-left justify-content-start  w-100" style={{height: '100px', overflow: 'scroll'}}>    
                                                <div className="h-100 pt-1" dangerouslySetInnerHTML= {{__html : item.name}}></div> 
                                            </div>
                                        </div>:<></>}


                                    </div>
                                )
                            })
                        }

                        
                     
                       
                        {/* <div className="d-flex p-3">
                            <Image src={ParagraphIc}></Image>
                            <span className="font-14 font-w-400 ml-2">Paragraph</span>
                        </div> */}
                        {/* <div className="d-flex p-3">
                            <Image src={TextIc}></Image>
                            <span className="font-14 font-w-400 ml-2">Number</span>
                        </div> */}
                        {/* <div className="d-flex p-3">
                            <Image src={WeekIc}></Image>
                            <span className="font-14 font-w-400 ml-2">Week</span>
                        </div> */}
                        {/* <div className="d-flex pt-4"> 
                            <div className="w-100 pl-2 pr-2 mt-2">  
                                <Rating
                                    emptySymbol="fa fa-star-o fa-2x font-18"
                                    fullSymbol="fa fa-star fa-2x color-rating font-18"  
                                    readonly = {true}
                                    initialRating={4}
                                    fractions={1}
                                />
                            </div> 
                        </div>
                        <div className="d-flex pt-4"> 
                            <div className="mt-2">
                                <span className="font-14 font-w-400 ml-2">Last Update</span>
                            </div> 
                        </div>
                        <div className="d-flex pt-4"> 
                            <div className="mt-2">
                                <span className="font-14 font-w-400 ml-2">Creation Log</span>
                            </div> 
                        </div> */}
                    </Col>
                </Row>
            </Modal.Body>
                 
        </Modal>
    )
}

export default ModalDetailKanban