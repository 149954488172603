import React, { useContext } from 'react'

//=========BOOTSTARP INITIAL========//
import { Col, Row } from 'react-bootstrap'
import { DashboardContext } from "../../../context/DashboardContext"

//=========COMPONENT========//  
import HeaderAndroid from './HeaderAndroid'
import ContainerAndroid from './ContainerAndroid'
import BgIcon from "../../../img/bg-dashboard.svg"
import ContentFullScreenAndroid from './ContentFullScreenAndroid'


const Workspace = () => {
    const { fullscreenWidget, width, setWidth } = useContext(DashboardContext)

    return (
        <Row >
            <Col md={12}>
                {/* <div className="content-board bg-grey" style={{ marginLeft: width.board }}> */}
                <div className="content-board">
                {/* <div className="content-board bg-grey"> */}
                    {fullscreenWidget.fullscreen ? <ContentFullScreenAndroid /> :
                        <React.Fragment>
                            <div className="devider p-3 bg-white">
                                <HeaderAndroid />
                            </div>
                            <Col className="min-vh-100" id="main-dashboard">
                            {/* <Col className="min-vh-100" id="main-dashboard" style={{ backgroundImage: "url(" + BgIcon + ")" }}> */}
                                <ContainerAndroid />
                            </Col>
                        </React.Fragment>}
                </div>
            </Col>
        </Row>
    )
}

export default Workspace
