import React, { useState, useEffect, useRef } from 'react'
import Highcharts from "highcharts/highcharts.js"
import highchartsMore from "highcharts/highcharts-more.js"
import solidGauge from "highcharts/modules/solid-gauge.js"
import HighchartsReact from "highcharts-react-official"

highchartsMore(Highcharts)
solidGauge(Highcharts)

export const BasicChart = (props) => { 
    const chartComponent = useRef({});
    
    let charts = props.charts  
    // console.log("props_charts____", charts)
     
    const options = {
        chart: {
            type: charts.type,
            animation: true,
            backgroundColor: charts.backgroundColor, 
        }, 
        title: {
            text: ''
        },
        xAxis: charts.xAxis,
        legend:  charts.legend,
        yAxis:  charts.yAxis,
        plotOptions: charts.plotOptions,
        credits: {
            enabled: false
        },
        series: charts.series
    }

    // useEffect(() => {
    //     const chart = chartComponent.current?.chart;
    //     console.log("chart___", chart)
    
    //     if (chart) chart.redraw();
    // }, []);
 
    return (
        <HighchartsReact
            ref={chartComponent}
            containerProps={{ style: { height: "100%", width: "100%", minWidth:'100%', margin:"0 auto"} }}
            highcharts={Highcharts}
            options={options}
        /> 
    )
} 

export const SplineChart = (props) => { 
    let charts = props.charts  
     
    const options = {
        chart: {
            type: charts.type,
            animation: true,
            backgroundColor: '#1A1A1A', 
        },
        title: {
            text: ''
        },
        xAxis: { 
            categories: charts.categories, 
            labels: {
                formatter () {
                  return `<span style="color: white;font-size: 10px">${this.value}</span>`
                }
              }
        },
        legend:  charts.legend,
        yAxis: {
            type: 'logarithmic',
            minorTickInterval: 1,
            title: {
                text: '', 
            },
            labels: {
                enabled: true,
                style: {
                    color: 'white'
                }
            }
        }, 
        plotOptions: charts.plotOptions,
        credits: {
            enabled: false
        },
        series: charts.data
    }
 
    return (
        <HighchartsReact
            containerProps={{ style: { height: "100%", width: "100%", margin:"0 auto"} }}
            highcharts={Highcharts}
            options={options}
        /> 
    )
} 

export const GaugeChart = (props) => {  
    let charts = props.charts   
    const options = {         
        chart: {
            type: charts.type,
            animation: true,  
            backgroundColor: 'transparent', 
        },    
        title: null,     
        pane: {
            center: ['50%', '50%'], 
            startAngle: 0,
            endAngle: 360,
            background: {
                backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
                innerRadius: '70%',
                outerRadius: '100%',
                shape: 'arc'
            }
        },    
        exporting: {
            enabled: false
        },
    
        tooltip: {
            enabled: true
        }, 
        yAxis: { 
            min: 0,
            max: 100,
            lineWidth: 0,
            minorTickInterval: null,
            tickPixelInterval: 100,
            tickWidth: 0,
            title: {
                y: -70
            },
            labels: {
                enabled:false
            }
        },             
        plotOptions: charts.plotOptions,
        credits: {
            enabled: false
        },
    
        series: charts.data    
    }

    return (
        <HighchartsReact
            containerProps={{className: 'gauge-container'}}
            highcharts={Highcharts}
            options={options}
        /> 
    )
}