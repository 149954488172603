import React, { useEffect, useState, useContext, useRef } from "react";
import { Modal, Row, Col, Button, Spinner } from "react-bootstrap";

//=========3RD LIBRARY========//
import _ from "lodash";

//=========ICON LIST========//
import { DashboardContext } from "../../context/DashboardContext";
import { StyleSelect } from "../../helpers/select/Select";
import Select from "react-select";

const ModalCutOff = (props) => {
  const ref = useRef();
  const { workspaceWeeks, processWorkspaceWeeks } =
    useContext(DashboardContext);
  const [isLoading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedWeeks, setSelected] = useState({});

  useEffect(() => {
    if (!workspaceWeeks.weeks.length) setSelected({});

    let options = [];
    workspaceWeeks.weeks.map((week, key) => {
      let obj = { value: week, label: "Week " + week };
      if (key === 0) setSelected(obj);
      if (week === workspaceWeeks.currentWeek) setSelected(obj);

      options.push(obj);
    });
    setOptions(options);
  }, [workspaceWeeks]);

  const handleProcess = async () => {
    setLoading(true);
    const process = await processWorkspaceWeeks(selectedWeeks.value);
    setLoading(false);
  };

  return (
    <Modal
      {...props}
      size=""
      id="modal-add-member"
      centered
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header>
        <span className="modal-title">Cut Off Weekly</span>
      </Modal.Header>
      <Modal.Body className="mt-1 ml-2 mr-2">
        {isLoading ? (
          <Row className="h-100 justify-content-center align-items-center mb-3">
            <div className="d-flex justify-content-center">
              <span className="text-pink fa fa-spinner fa-spin fa-3x"></span>
            </div>
          </Row>
        ) : (
          <React.Fragment>
            <div className="mb-2">
              <span className="font-14">
                Current Week {workspaceWeeks.currentWeek}
              </span>
            </div>
            <Row>
              <Col>
                <Select
                  styles={StyleSelect({
                    menuListHeight: 150,
                    fontSize: "14px",
                  })}
                  placeholder="Select Week"
                  maxMenuHeight={150}
                  options={options}
                  value={selectedWeeks}
                  onChange={(selected) => setSelected(selected)}
                ></Select>
              </Col>
              <Col md="4">
                <Button
                  disabled={isLoading}
                  className="btn-yes text-white"
                  type="submit"
                  onClick={() => handleProcess()}
                >
                  {isLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Process"
                  )}
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        )}

        <div className="pb-3"></div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalCutOff;
