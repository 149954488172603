//=========REACT INITIAL========//
import React, {useEffect, useState, useContext, useRef} from 'react' 
import {DashboardContext} from "../../context/DashboardContext" 

//=========BOOTSTARP INITIAL========//
import {Col, Row, Nav,OverlayTrigger, Popover, Tooltip, Card, Image} from 'react-bootstrap' 

//========CHART==========//
import {BasicChart} from '../../helpers/highcharts/Charts'

//=========3RD LIBRARY========// 
import _ from 'lodash' 
import "react-datepicker/dist/react-datepicker.css"  
import '../css/dashboard.css'
import '../css/grid/styles.css'
import Content from '../board/Table' 

// import '../css/resizable/styles.css' 
import ContentEditable from 'react-contenteditable'  
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { filterDataTableColumn } from '../../helpers/utils/Utils'

//=========ICON LIST========//   
import Settings from "monday-ui-react-core/dist/icons/Settings"
import Menu from "monday-ui-react-core/dist/icons/Menu"
import Duplicate from "monday-ui-react-core/dist/icons/Duplicate"
import Delete from "monday-ui-react-core/dist/icons/Delete"
import Edit from "monday-ui-react-core/dist/icons/Edit" 
import FullscreenCloseIcon from "../../img/fullscreenClose.svg" 
import BoardIcon from "../../img/board.svg" 
import MoreIcon from "../../img/more.svg" 
import Battery from '../dashboard/Battery'

const ContentDashboardFullScreen = (props) => {  
    const {modeDashboard, setModeDashboard, boardActive, updateDashboard, 
        deleteDashboard, createDashboard, setShowWidget, color, dispatchChart,
        fullscreenWidget, setFullscreenWidget, setValueSearch,params} = useContext(DashboardContext)  
    const [ chart, setChart ] = useState(false)   
    const [ dashboardLocal, setDashboard ] = useState(false)   
    const isFirstRun = useRef(true)
    let ref = React.useRef(null) 
    let refEditable = useRef("")
    let refContentEditable = useRef([])  
    const isFirstRunWidget = useRef(true) 
    let refParagraph = useRef("")  
    const [contentComponent, setContentComponent] = useState([])
    
    const modules = { 
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image'],
         
        ],
      }

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'align',
        'list', 'bullet', 'indent',
        'link', 'image'
    
    ] 
    
    useEffect(()=>{  
        let chart = fullscreenWidget.dashboard.column.chart
        let type = fullscreenWidget.dashboard.column.type
        let dashboard = fullscreenWidget.dashboard 
        let selectedBoard = fullscreenWidget.dashboard.widget.selectedBoard  
        let category = fullscreenWidget.category
        let group = fullscreenWidget.dashboard.widget.group
        
        if(category){ 
             //=====CATEGORY VALUE COME FROM CLICK POINT CHART=====//
            let categories = _.filter(chart.xAxis.categories,(item) =>{ if(item === category) return item} )
            let dataChart = _.filter(chart.series[0].data,(item) =>{ if(item.name === category) return item} )
            chart.series[0].data = dataChart
            chart.xAxis.categories = categories
            chart.yAxis.title.text = ''
        }
        setChart(chart) 
        setDashboard(dashboard) 
        let board = fullscreenWidget.dashboard.widget.board  
        let value = category
        if(type === "Chart" || type === "Battery"){
            //==== SETUP CONTENT GRID ======///
            let typeColumn = dashboard.widget.xAxis.toLowerCase()
            setContentComponent([])
            let componentTmp = []   
            
            let idBoard = ""
            selectedBoard.map(item =>{  
                //===ONLY SELECTED BOARD====//
                if(!board.includes(item.id)) return
                item.table.map(val => {  
                    if(!group.includes(val.id)) return
                    if(val.rows.length < 2) return
                    let data = _.cloneDeep(val) 
                    //=====CATEGORY VALUE COME FROM CLICK POINT CHART=====//  
                    if(value) data = filterDataTableColumn(data, {value: value, type: typeColumn})
                    //=====SHOW BOARD NAME ONLY FIRST TABLE WITH SAME BOARD =====//
                    if(data.rows.length > 1)
                    componentTmp.push(<Content item={data} board_id= {idBoard} board ={item.id === idBoard ? null : item} />)
                    idBoard = item.id
                })  
            })
            setContentComponent(componentTmp)
        } 
        
    },[]) 

    const deleteWidget = async() =>{ 
        ref.click()
        await deleteDashboard({id:dashboardLocal.id})
        setFullscreenWidget({fullscreen: false, dashboard: "", refresh: true})
    }

    const duplicateWidget = async() =>{ 
        ref.click()
        let dashboardTmp = dashboardLocal 
        let info = {title:dashboardTmp.title, created_by: dashboardTmp.created_by, 
            team_id : dashboardTmp.team_id, board_id: dashboardTmp.board_id, 
            workspace_id: dashboardTmp.workspace_id, permission_id: dashboardTmp.permission_id}
        
        let mod = dashboardLocal.length % 2  
        let column = {   
              "x": mod === 0 ? 0 : 4,
              "y": mod === 0 ? 0 : 4,
              "w": 3,
              "h": 2,
              minW: 1,
              minH: 1,
              "i": new Date()+"1",
              "static": false, 
              "type" : dashboardTmp.column.type,
              chart : dashboardTmp.column.chart
            }   
        let data = Object.assign(info,{column:column}, {widget: dashboardTmp.widget}, {chart: [] })  
        await createDashboard(data, true) 
        setFullscreenWidget({fullscreen: false, dashboard: "", refresh: true})
    }

    const clickSetting =() => {
        ref.click()
        let dashboardTmp = dashboardLocal   
        setModeDashboard("update")
        dispatchChart({type: 'SET_UPDATE_CHART', data : dashboardTmp})
        setShowWidget(true)
    }

    const handelChangeParagraph = (value) => {
        refParagraph.current = value.replace(/\&nbsp;/g, ' ')    
    }
 
    const handleEditItemParagraph =  async (index) =>{  
        if(refParagraph.current){
            let tmpDashboard = dashboardLocal
            tmpDashboard.column.value = refParagraph.current.trim() 
            await updateDashboard(tmpDashboard,false)  
        }
    }

    const handleClickBoard = (id) =>{ 
        const win = window.open(`/app/dashboard?id=${id}`, "_blank");
        win.focus() 
    }

    const popoverMore =()=> (
        <Popover id="popover-basic"> 
            <Popover.Content>  
                <Nav.Link className={ params.permission_id < 4 ? "nav-menu p-2 pr-5" : "nav-menu p-2 pr-5 disabled"} onClick={()=>handleRename()}> 
                    <Edit style={{color: 'black',width: 18 }} className="align-text-top"/>
                    <span className="workspace-item-name-child align-text-top ml-2 font-13">Rename</span>
                </Nav.Link> 
                 <Nav.Link className={ params.permission_id < 4 ? "nav-menu p-2 pr-5" : "nav-menu p-2 pr-5 disabled"} onClick={()=>duplicateWidget()}> 
                    <Duplicate style={{color: 'black', width: 18 }} className="align-text-top"/>
                    <span className="workspace-item-name-child align-text-top ml-2 font-13">Duplicate</span>
                </Nav.Link> 
                <Nav.Link className={ params.permission_id < 4 ? "nav-menu p-2 pr-5" : "nav-menu p-2 pr-5 disabled"} onClick={()=>clickSetting()}> 
                    <Settings style={{color: 'black', width: 18 }} className="align-text-top"/>
                    <span className="workspace-item-name-child align-text-top ml-2 font-13">Settings</span>
                </Nav.Link>  
                <div className="spacer pt-2"></div>  
           
                <Nav.Link className={ params.permission_id < 4 ? "nav-menu p-2 pr-5" : "nav-menu p-2 pr-5 disabled"} onClick={()=>deleteWidget()}> 
                    <Delete style={{color: 'black',width: 18 }} className="align-text-top"/>
                    <span className="workspace-item-name-child align-text-top ml-2 font-13">Delete</span>
        </Nav.Link> 
            </Popover.Content>
        </Popover>
    )

    const popoverBoard =()=> (
        <Popover id="popover-basic"> 
            <Popover.Content>  
                <span className="workspace-item-name-child align-text-top ml-2 font-13">Connected Boards</span> 
           
            <div className="spacer pt-2"></div>  
            
                {dashboardLocal && dashboardLocal.widget.selectedBoard && dashboardLocal.widget.selectedBoard.map((item, key)=>{ 
                        return (
                            dashboardLocal.widget.board.includes(item.id) ?
                                <Nav.Link key ={key} onClick={()=>handleClickBoard(item.id, item.workspace_id)} className={"nav-menu p-2 pr-5"}> 
                                     <Image src={BoardIcon}></Image> 
                                    <span className="workspace-item-name-child align-text-top ml-2 font-13">{item.title}</span>
                                </Nav.Link> 
                            : null
                        ) 
                })}
                
            </Popover.Content>
        </Popover>
    )

    const handleChangeTitle = (value) => {
        refEditable.current = value.replace(/\&nbsp;/g, ' ')   
    }

    const handleSaveTitle = async (index) => { 
        if(refEditable.current){
            let tmpDashboard = dashboardLocal 
            tmpDashboard.widget.title = refEditable.current.trim()
            tmpDashboard.title = refEditable.current.trim() 
            await updateDashboard(tmpDashboard,false)  
        }
    }

    const handleRename = async () => {  
        ref.click()
        refContentEditable.current.el.current.focus() 
    }

    const setFullScreen = () =>{ 
        setFullscreenWidget({fullscreen: false, dashboard: "", refresh: true})
    }
 
    return (
        <React.Fragment>  
            <div className="bg-grey">
                <Row className="pb-5 h-100 w-100 ml-0 pl-0 bg-white" ref={(r) => ref = r}> 
                    <Col md="12 p-0 mt-2" > 
                        <Card id ="title-dashborad"  style={{border: 'none'}}>
                            <div className="m-2 w-100 d-inline title-dashborad" >
                                <ContentEditable 
                                    html={fullscreenWidget.dashboard.title}  
                                    ref ={(r)=> refContentEditable.current = r}
                                    onBlur = {()=>handleSaveTitle()}
                                    style={{color : color.color}}
                                    onChange={(e)=> {handleChangeTitle(e.target.value.replace(/<\/?[^>]+(>|$)/g, "")+ " ")}} 
                                    disabled={modeDashboard === "view"? true : false}
                                    className={modeDashboard === "view"? "ml-4 font-16  font-w-400 p-1 d-inline align-text-top" : "ml-4 d-inline align-text-top font-16 p-1 font-w-400 p-1"}  
                                />  
                                <OverlayTrigger trigger={['hover','focus']} placement="bottom" overlay={ 
                                    <Tooltip id='tooltip'>
                                        Exit Fullscreen
                                    </Tooltip>
                                } rootClose>
                                    <span className="align-text-top font-20 float-right no-print">
                                    <Image src={FullscreenCloseIcon}  onClick={()=>setFullScreen()}  className="float-right ml-2 mr-3 cursor-pointer no-print"></Image>
                                    </span> 
                                </OverlayTrigger> 
                                
                                
                                {modeDashboard === "edit" ?
                                    <React.Fragment>  
                                        <OverlayTrigger trigger={['hover','focus']} placement="bottom" overlay={ 
                                            <Tooltip id='tooltip'>
                                                More Options
                                            </Tooltip>
                                        } rootClose> 
                                            <OverlayTrigger trigger="click" placement="bottom" overlay={popoverMore()} rootClose>
                                            <span className="align-text-top font-20 float-right no-print cursor-pointer" style={{height:'20px'}}>
                                                <Image width="16" src={MoreIcon} className="no-print float-right mr-2 ml-2 mt-1 cursor-pointer"></Image>  
                                                </span>
                                            </OverlayTrigger> 
                                        </OverlayTrigger> 
                                    
                                        
                                    </React.Fragment>
                                    :""} 
                                        {dashboardLocal.column &&  dashboardLocal.column.type === "Text" ?
                                        null
                                        :
                                        <OverlayTrigger trigger={['hover','focus']} placement="bottom" overlay={ 
                                            <Tooltip id='tooltip'>
                                                Connected Boards
                                            </Tooltip>
                                        } rootClose>
                                            <span className="align-text-top font-20 float-right no-print">
                                            <OverlayTrigger trigger="click" placement="bottom" overlay={popoverBoard()} rootClose>
                                                <Image src={BoardIcon} className="float-right mr-2 cursor-pointer "></Image> 
                                            </OverlayTrigger>
                                            </span> 
                                        </OverlayTrigger> 
                                    }                                     
                            </div>
                            
                            <Card.Body > 
                                <div key={true}  className = "text-center h-100 w-100 m-0 p-0">
                                    {dashboardLocal.column && dashboardLocal.column.type === "Text" ?
                                        <div className="text-left pl-3" dangerouslySetInnerHTML= {{__html : dashboardLocal.column.value}}></div> 
                                    :fullscreenWidget.dashboard.column.type === "Battery" ? 
                                        <Battery data={fullscreenWidget.dashboard.column.data} height={200} className="d-inline-block p-3"/>
                                    : chart? <BasicChart  charts = {chart} /> : null
                                    } 
                                </div>
                            </Card.Body> 
                        </Card>   

                    </Col>  
                </Row>
                <div className="bg-white m-4" hidden = {dashboardLocal.column && (dashboardLocal.column.type === "Text" || dashboardLocal.column.type === "Battery") || false} style={{borderRadius: '8px'}}>
                    <Col className="p-4">  
                        {contentComponent.map((content,index)=>{
                        return (
                            content === undefined ? "" :
                                <React.Fragment key={index}> 
                                    {React.cloneElement(content, {contentIndex : index})} 
                                </React.Fragment>
                            )
                        })}   
                    </Col>  
                </div>
            </div>
        </React.Fragment>
    )
}


export default ContentDashboardFullScreen
