import React, {useEffect, useState, useContext} from 'react'

import {Modal, Button, Spinner, Form } from 'react-bootstrap' 
import _ from 'lodash' 
import 'react-quill/dist/quill.snow.css' 
import {DashboardContext} from "../../context/DashboardContext"  
import {asyncRequest } from '../../helpers/utils/Utils' 
import { useForm } from "react-hook-form"
import {NotificationManager} from 'react-notifications'  
import {MakeRequest} from "../../api/Api"   

const ModalDesc = (props) =>{  
    const {boardActive, params, updateBoard} = useContext(DashboardContext)  
    const [valueDesc, setValueDesc] = useState("") 
    const [isLoading, setLoading] = useState(false)
    const { register, handleSubmit, watch, errors } = useForm()  
 

    useEffect(()=>{ 
        setValueDesc(boardActive.description)
    },[props]) 

    const onSubmit = async (param) => { 
        param.email = params.email
        param.subjectType = 'HELPDESK' 
        param.name = params.username 
        param.company = ''
        param.companySize = ''
        setLoading(true)
        const result = await MakeRequest('post','contact-us', param)   
        if(result.status !== 200) {
            asyncRequest(1000).then(()=>{
              setLoading(false)
                let detailError = result.data ? result.data.detail : 'Server error'
                NotificationManager.error(detailError) 
            })
        }else{
            asyncRequest(1000).then(()=>{
              NotificationManager.success(result.data.message) 
                setLoading(false)
                props.onHide()
            })
          }
       
      } 

    return (
        <Modal
        {...props}
        size="md"  
        centered
        aria-labelledby="contained-modal-title-vcenter" > 
            <Modal.Header>
                <span className="modal-title">
                 Contact Sales
                </span>
            </Modal.Header> 
            <Form onSubmit={handleSubmit(onSubmit)}>  
            <Modal.Body className="m-2">  
               
                    <Form.Group controlId="formBasicEmail" className="pt-2"> 
                        <Form.Control className="font-16 input-text" name="subject" type="text" placeholder="Subject" ref={register({ required: true })} />
                        <div className="mt-3">
                            {errors.subject && <span>This field is required</span>}
                        </div>
                    </Form.Group>
                    
                    <Form.Group controlId="formBasicEmail" className="pt-2"> 
                        <Form.Control as="textarea" rows={3} className="font-16 input-text" name="message" type="text" placeholder="Message" ref={register({ required: true })} />
                        <div className="mt-3">
                            {errors.message && <span>This field is required</span>}
                        </div>
                    </Form.Group>  
                 
                    
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-cancel" onClick={props.onHide}>Cancel</Button>  
                    <Button 
                        disabled={isLoading} 
                        className="btn-yes text-white"
                        type="submit"
                        >
                            {isLoading ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    />
                                : "Send" 
                            }
                    </Button> 
            </Modal.Footer> 
            </Form>
        </Modal>
    )
}

export default ModalDesc