import React, {useEffect, useState, useRef, useContext} from 'react'
import {Col, Row, Modal, Button, Form, Alert, Spinner, Nav, Image} from 'react-bootstrap' 
import {initialString, stripHtml } from '../../helpers/utils/Utils' 
import {Style} from "../../helpers/style/Style"
import { useForm } from "react-hook-form"  
import {DashboardContext} from '../../context/DashboardContext'
import AddIcon from "../../img/add.svg"

const ModalSeeAllWorkspace = (props) =>{ 
    let ref = useRef(null) 
    const [isLoading, setLoading] = useState(false) 
    const { register, handleSubmit, watch, errors } = useForm()
    const [alert, setAlert] = useState({msg: '', variant: 'success', hidden: true}) 
    const {dispatchChart, getWorkspaceSeeAll, workspaceSeeAll, setParams, params, setSelectedWorkspace, setLocalStorage, setModalSeeAllWorkspace, setAddNewWorkspace} = useContext(DashboardContext)  

    useEffect(()=>{
        // await getWorkspaceSeeAll()
        setAlert({...alert, hidden: true})
    },[props])

    useEffect(()=>{  
        
        const getData = async()=>{
            await getWorkspaceSeeAll()
        } 
        getData()
     
    },[params])

    const handleSelectedWorkspace = (selected) =>{  
        // ref.click()
        dispatchChart({type : 'CLEAR_SELECTED_BOARD'})
        setSelectedWorkspaceLocal(selected)
    }

    const setSelectedWorkspaceLocal =(selected)=>{
        let paramTmp = {...params, workspace_id: selected.id, indexBoardActive: 0, team_id: selected.team_id, permission_id : selected.permission_id}
        selected = {
            value: selected.id,
            label: selected.name,
            team_id: selected.team_id,
            team_name: selected.team[0].name,
            created_by: selected.created_by
        }
        paramTmp.selectedWorkspace = selected
        setParams(paramTmp)
        setSelectedWorkspace(selected)  
        setLocalStorage(paramTmp)
    }


    return (
        <Modal
        {...props}
        size=""
        aria-labelledby="contained-modal-title-vcenter"
        centered>
            <Modal.Header>
                <span className="modal-title">
                All Workspace
                </span>
                <div className="d-inline"> 
                    <Image src={AddIcon} onClick={()=>{setAddNewWorkspace(true); setModalSeeAllWorkspace(false)}} className="cursor-pointer float-right menu-icon-down" style={{position: "relative", top: "11px", width: "15px"}}></Image>
                </div>
            </Modal.Header>
            <Modal.Body className="m-2"> 
                {workspaceSeeAll.map(item => { 
                    return (
                        <div className="cursor-pointer p-2">
                            <Nav.Link className={ "nav-menu pl-2 nav-menu-teams"} onClick={()=>{handleSelectedWorkspace(item); setModalSeeAllWorkspace(false)}}> 

                            <Row>
                                <Col md="2">
                                    <span style={Style.circleText}>
                                        <span style={Style.circleTextSpan}>{initialString(item.name)}</span>
                                    </span>
                                </Col>
                                <Col className="col pr-1 pl-1" style={{height: "10px !important"}}>
                                    {/* <div className="d-inline"> */}
                                        <span className="pl-2 font-14 font-w-600 text-dark lable-no-wrap" style={{position: "relative", bottom: "10px"}}>
                                            {item.name}
                                            <pre className="font-10 lable-no-wrap" style={{position: "relative", bottom: "9px", right: "2px", color: "grey", paddingBottom: "0px !important"}}>{item.team[0].name}</pre>
                                        </span>
                                    {/* </div> */}
                                </Col>
                            </Row>
                            
                            {/* <Row>
                                <Col md="2">
                                    <span style={Style.circleText}>
                                        <span style={Style.circleTextSpan}>{initialString(item.name)}</span>
                                    </span>
                                </Col>
                                <Col className="col pr-1 pl-1 h-100 m-auto">
                                    <div className="d-inline mt-2">
                                        <span className="pl-2 font-14 font-w-500 text-dark">
                                            {item.name}
                                            <pre className="font-10 lable-no-wrap" style={{position: "relative", bottom: "9px", right: "2px", color: "grey", paddingBottom: "0px !important"}}>{item.team[0].name}</pre>
                                        </span>
                                    </div> 
                                </Col> */}
                                {/* <Col md="2">
                                    <span style={Style.circleTextTeams}>
                                        <span style={Style.circleTextTeamsSpan}>{item.team && item.team[0].name? initialString(item.team[0].name): "NA"}</span>
                                    </span>
                                </Col>
                                <Col md="4" className="pr-1 pl-1">
                                    <div className="d-inline">
                                        <span className="pl-2 font-14 font-w-600 text-dark lable-no-wrap">{item.team[0].name ? item.team[0].name: "No Available"}</span>
                                    </div> 
                                </Col> */}
                            {/* </Row>  */}
                            </Nav.Link>  
                        </div>
                    )
                })}
            </Modal.Body>
            <Modal.Footer>
                {/* <Button className="btn-cancel" onClick={props.onHide}>Cancel</Button>  
                <Button 
                    disabled={isLoading}
                    className="btn-yes text-white"
                    type="submit"
                    >
                        {isLoading ?
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                            : "Create" 
                        }
                </Button>  */}
            </Modal.Footer>
        </Modal>
    )
}

export default ModalSeeAllWorkspace