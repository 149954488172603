import React, {useEffect, useState, useContext, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import {MakeRequest} from '../../api/Api'
import {asyncRequest } from '../../helpers/utils/Utils' 
import ModalSpinner from '../../helpers/loading/ModalSpinner'
import {Container} from 'react-bootstrap' 

//=========BOOTSTARP INITIAL========//
import {Col, Row, Image} from 'react-bootstrap'  
 
const Workspace = () => {   
    const history = useHistory() 
    const [modalSpinner, setModalSpinner] = useState(false)

    // const urlParams = new URLSearchParams(window.location.search);
    // const myParam = urlParams.get('usr');

    useEffect(()=>{ 
        lgn()
    },[])

    const lgn = async () => {
        setModalSpinner(true)
        const urlParams = new URLSearchParams(window.location.search);
        let email = urlParams.get('usr');
        let pwd = urlParams.get('pwd');
        let brdId = urlParams.get('board');
        let typeBoard = urlParams.get('type');
        let workspaceId = urlParams.get('workspace_id');
        // console.log("workspaceId", workspaceId)
        // return
        // console.log("usrPWD", email, pwd)

        const result = await MakeRequest('post','user-login', {email: email, password: pwd})  
        if(result.status !== 200) {
            asyncRequest(1000).then(()=>{
                // console.log("urlParams", email)
                // console.log("error nih")
            })
        }else{
            asyncRequest(1000).then(()=>{
                // console.log("masuk nihh")
            })
           
            asyncRequest(1000).then(() => {
                // console.log("resultt", result.data)
                result.data.expired = new Date().getTime() + 3600 * 1000
                result.data.team_id = result.data.teamUser.team ? result.data.teamUser.team.id : result.data.teamUser.team_id
                result.data.team = result.data.teamUser.team?result.data.teamUser.team : {permission_id : ""}
                result.data.permission_id = result.data.teamUser.permission_id
                result.data.team.permission_id = result.data.teamUser.permission_id 
                result.data.selectedBoard = {id: parseInt(brdId), workspace_id: parseInt(workspaceId)} 
                result.data.from = "android" 
                // console.log("result__", result)
                // return
                localStorage.setItem('saatkerja', JSON.stringify(result.data))   
                // history.push('/app/board')
                if(parseInt(typeBoard) === 1){
                    window.location.href = '/app/board-android-detail'
                }
                if(parseInt(typeBoard) === 2){
                    window.location.href = '/app/dashboard-android-detail'
                }
            })
        }
    }
    return(
        <Container fluid className="no-gutters overflow-hidden pr-0" >
            <ModalSpinner show={modalSpinner} onHide={() => setModalSpinner(false)}/>
        </Container> 
    )

}

export default Workspace
