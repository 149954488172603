import React, {useState, useEffect} from 'react'
import {Col, Row, Form, Image, Button, Alert, Spinner } from 'react-bootstrap' 
import ImageLogin from "../../img/login-img.svg" 
import LoginIcon from "../../img/login-icon.svg" 
import LockIcon from "../../img/lock.svg" 
import "../css/login.css"
import { useForm } from "react-hook-form"
import { MakeRequest } from "../../api/Api"  
import {useHistory} from 'react-router-dom'  
import {asyncRequest } from '../../helpers/utils/Utils' 

const Login = () =>{ 
    const history = useHistory()
    const [isLoading, setLoading] = useState(false) 
    const [typePassword, setTypePassword] = useState("password") 
    const [alert, setAlert] = useState({msg: 'Register success.', variant: 'success', hidden: true}) 
    const { register, handleSubmit, watch, errors } = useForm()
    const [promo, setPromo] = useState("") 

    useEffect(()=>{
        if(history.location.state)
        setPromo(history.location.state) 
    })

    const onSubmit = async (params) => {
        setAlert({msg: 'Register success.', variant: 'success', hidden: true}) 
        setLoading(true)
        params.promo = promo
        const result = await MakeRequest('post','user-login', params)   
        if(result.status !== 200) {
            asyncRequest(1000).then(()=>{
                let detailError = result.data.detail
                setAlert({msg: detailError === undefined ? "Server error": detailError, variant: 'danger', hidden: false})
            })
        }else{
            asyncRequest(1000).then(()=>{
                setAlert({msg: 'Login successfully.', variant: 'success', hidden: false})
                localStorage.removeItem('saatkerja')  
            })
           
            asyncRequest(1000).then(() => {
                result.data.expired = new Date().getTime() + 3600 * 1000
                result.data.team_id = result.data.teamUser.team ? result.data.teamUser.team.id : result.data.teamUser.team_id
                result.data.team = result.data.teamUser.team?result.data.teamUser.team : {permission_id : ""}
                result.data.permission_id = result.data.teamUser.permission_id
                result.data.team.permission_id = result.data.teamUser.permission_id 
                localStorage.setItem('saatkerja', JSON.stringify(result.data))   
                // history.push('/app/board')
                window.location.href = 'app/board'
            })
        }
        asyncRequest(1000).then(()=>{
            setLoading(false)
        }) 
    } 

    useEffect(()=>{
        let data = JSON.parse(localStorage.getItem('saatkerja'))  
        if(data !== null && data.expired  > new Date().getTime()) history.push('/app/dashboard')  
    },[])
 

    return(
       <Row xs="12" className="no-gutters vh-100 vw-100">
            <Col md={7}> 
            <Image src={LoginIcon} className="mt-4 ml-4 img-fluid cursor-pointer" onClick={()=>history.push('/')}></Image>
            <Row className="h-75 text-center">
                <Col md={12} className="m-auto pt-5">   
                    <Image src={ImageLogin} className="img-fluid"></Image>
                            
                </Col> 
            </Row>
            </Col>
            <Col md={5} className="min-vh-100 "> 
                <div className="m-3 bg-login h-95">
                <Row className="h-100">
                    <Col md={12} className="m-auto p-5">  
                        <div className="p-3">
                            <div className="txt-login">
                                <span>Login</span>
                            </div>
                            <div className="mt-3 txt-login-child">
                                <span className="color-grey-darker">Login with your data that you entered during your registration</span> 
                            </div> 
                            <div className="mt-5">
                                <Form onSubmit={handleSubmit(onSubmit)}> 
                                    <Form.Group controlId="formBasicEmail"> 
                                        <Form.Control className="font-16 input-text" name="email" type="email" placeholder="Email" ref={register({ required: true })} />
                                        <div className="mt-3">
                                            {errors.email && <span>This field is required</span>}
                                        </div> 
                                    </Form.Group>
                                    <Form.Group controlId="formBasicEmail" className="pt-2">  
                                        <div className="pass-wrapper">
                                            <Form.Control className="font-16 input-text" name="password" type={typePassword} placeholder="Password" ref={register({ required: true , minLength: 8})} />
                                            {typePassword === "password" ? 
                                                <Image src={LockIcon} onClick={()=> setTypePassword("text")} className="eye-icon"></Image>
                                            :
                                                <Image src={LockIcon} onClick={()=> setTypePassword("password")} className="eye-icon"></Image>
                                            }
                                        </div>
                                        {errors.password && <span>This field is required / minimal 8 character</span>}
                                    </Form.Group>
                                    <Form.Group className="pt-2" hidden={alert.hidden}>  
                                        <Alert variant={alert.variant} >
                                            <span className="font-15">{alert.msg}</span>
                                        </Alert>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicCheckbox" className="checkbox pt-2">
                                        <div className="d-inline"> 
                                            <Form.Check type="checkbox" className="font-16 d-inline cursor-pointer" label="Remember me" />
                                        </div>    
                                        <div className="d-inline"> 
                                            <span className="cursor-pointer text-primary font-16 d-inline float-right" onClick={()=>{history.push('/forgotpassword')}}> Forgot Password ?</span>
                                        </div>
                                    </Form.Group>
                                    <div className="w-100 pt-2">
                                        <Button
                                            id="btn-login-display"
                                            variant="primary"
                                            disabled={isLoading}
                                            className="font-16 text-white font-weight-bold btn-login"
                                            type="submit" 
                                            >
                                            {isLoading ?
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    />
                                                : "Login" 
                                            }
                                            </Button> 
                                    </div> 
                                </Form>
                                <div className="mt-5 txt-login-child">
                                    <span className="color-grey-darker">Dont have an account ? </span> <br></br>
                                    <span className="cursor-pointer text-primary" onClick={()=>{history.push('/register')}}> Sign up here</span>
                                </div> 
                            </div>
                        </div>
                    </Col>
                </Row> 
                </div>
            </Col>
       </Row>
    )
}

export default Login