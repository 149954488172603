import React, { useContext, useRef, useEffect, useState } from "react";
import {
  Row,
  Col,
  Nav,
  Popover,
  OverlayTrigger,
  Tooltip,
  Image,
} from "react-bootstrap";
import { DashboardContext } from "../../context/DashboardContext";
import { useHistory, useLocation } from "react-router-dom";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import Workspace from "monday-ui-react-core/dist/icons/Workspace";
import _ from "lodash";
import { stripHtml } from "../../helpers/utils/Utils";
import Counter from "monday-ui-react-core/dist/Counter";
import WorkspaceIcon from "../../img/workspace.svg";
import UserIcon from "../../img/user.svg";
import NotifIcon from "../../img/notif.svg";
import ChatIcon from "../../img/chat.svg";

import PersonIcon from "../../img/person-profile.svg";
import AvatarIcon from "../../img/avatar-profile.svg";
import LogoutIcon from "../../img/logout.svg";
import WhatIcon from "../../img/what-new.svg";
import ContactIcon from "../../img/contact.svg";
import TeamsSidebarIcon from "../../img/team-sidebar.svg";
import SettingIcon from "../../img/setting.svg";

const SideBar = () => {
  const history = useHistory();
  const [path, setPath] = useState("");
  const {
    setAddNewWorkspace,
    params,
    selectedTeams,
    setSelectedTeams,
    showModalPricing,
    teams,
    setParams,
    getWorkspace,
    setShowNotif,
    countNotif,
    setShowModalContact,
  } = useContext(DashboardContext);
  let ref = useRef(null);
  const location = useLocation();
  const [teamsData, setTeamsData] = useState();
  const [defaultIndex, setDefaultIndex] = useState(0);
  const isFirstRun = useRef(true);

  const handleAddWorkspace = () => {
    setAddNewWorkspace(true);
    //=========CLOSE POPOVER========//
    ref.click();
  };

  const handleLogout = () => {
    localStorage.removeItem("saatkerja");
    history.push("/");
  };

  useEffect(() => {
    isFirstRun.current = true;
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    setPath(currentPath);
  }, [location]);

  const handleSelectedTeams = async (selected) => {
    let newParams = _.cloneDeep(params);
    newParams.team = selected;
    newParams.permission_id = selected.permission_id;
    newParams.team_id = selected.value;
    newParams.indexBoardActive = 0;
    newParams.indexWorkspaceActive = 0;
    localStorage.setItem("saatkerja", JSON.stringify(newParams));
    setParams(newParams);
    setSelectedTeams(selected);
    await getWorkspace(newParams);
  };

  useEffect(() => {
    //=========Asign value teams========//
    let arrTmp = [];
    teams.map((item, index) => {
      let objTmp = {};
      objTmp.created_by = item.team.user_id;
      objTmp.value = item.team.id;
      objTmp.label = stripHtml(item.team.name);
      objTmp.name = stripHtml(item.team.name);
      objTmp.is_active = item.team.is_active;
      objTmp.is_default = item.team.is_default;
      objTmp.user_id = item.team.user_id;
      objTmp.id = item.team.id;
      objTmp.permission_id = item.permission_id;
      if (item.team.is_active === true) {
        arrTmp.push(objTmp);
        if (selectedTeams && selectedTeams.value === item.team.id)
          setSelectedTeams(objTmp);
      }
    });
    if (
      selectedTeams &&
      !selectedTeams.name &&
      arrTmp.length &&
      isFirstRun.current
    ) {
      setSelectedTeams(arrTmp[0]);
      handleSelectedTeams(arrTmp[0]);
      isFirstRun.current = false;
    }
    setDefaultIndex(0);
    setTeamsData(arrTmp);
  }, [teams]);

  const popupWorkspace = (
    <Popover id="popover-basic">
      <Popover.Content>
        <Nav.Link
          className="nav-menu p-2"
          onClick={() => handleAddWorkspace(true)}
        >
          <AddCircleOutlineIcon
            style={{ color: "black", fontSize: 18 }}
            className="align-text-top"
          />
          <span className="workspace-item-name-child align-text-top ml-2 font-13">
            Add new workspace
          </span>
        </Nav.Link>
        <Nav.Link
          className="nav-menu p-2"
          onClick={() => {
            history.push("/app/workspace");
            ref.click();
          }}
        >
          <Workspace
            style={{ color: "black", fontSize: 18 }}
            className="align-text-top"
          />
          <span className="workspace-item-name-child align-text-top ml-2 font-13">
            Browse all workspace
          </span>
        </Nav.Link>
      </Popover.Content>
      <div className="spacer pt-1 pl-0 ml-0 pr-0 mr-0"></div>
      <Popover.Content>
        <Nav.Link className="nav-menu p-2">
          <SwapVertIcon
            style={{ color: "black", fontSize: 18 }}
            className="align-text-top align-text-top float-left mr-1 cursor-pointer"
          />
          <span className="workspace-item-name-child align-text-top ml-2 font-13">
            Short by alphabetical order
          </span>
        </Nav.Link>
      </Popover.Content>
    </Popover>
  );

  const popoverProfile = (
    <Popover id="popover-basic" style={{ width: "380px", maxWidth: "500px" }}>
      <Popover.Content>
        <Row>
          <Col md="2">
            <div className="pt-2">
              <Image src={AvatarIcon}></Image>
            </div>
          </Col>
          <Col className="p-0">
            <div className="pt-2">
              <span className="font-16 font-w-600">{params.username}</span>
            </div>
            <div>
              <span className="font-14 font-w-400">{params.title}</span>
            </div>
          </Col>
        </Row>

        <div className="spacer mt-3"></div>
        <Row className="mt-3 mb-4">
          <Col>
            <span className="font-14 ml-2 pl-1 mb-1 color-grey font-w-400">
              Account
            </span>
            <Nav.Link
              className="nav-menu p-2 mt-2 d-flex"
              onClick={() => {
                history.push("/app/setting/profile");
                ref.click();
              }}
            >
              <Image src={PersonIcon} width="14"></Image>
              <span className="workspace-item-name-child ml-3 font-14 font-w-400 mt-1">
                My Profile
              </span>
            </Nav.Link>

            <Nav.Link className="nav-menu p-2 d-flex" onClick={handleLogout}>
              <Image src={LogoutIcon} width="15.6"></Image>
              <span className="workspace-item-name-child ml-3 font-14 font-w-400 mt-1">
                Logout
              </span>
            </Nav.Link>
          </Col>
          <Col>
            <span className="font-14 ml-2 pl-1 mb-1">Explore</span>
            <Nav.Link className="nav-menu p-2 mt-2 d-flex">
              <Image src={WhatIcon} width="16"></Image>
              <span className="workspace-item-name-child ml-3 font-14 font-w-400 mt-1">
                Whats New
              </span>
            </Nav.Link>
            <Nav.Link
              className="nav-menu p-2 d-flex"
              onClick={() => {
                setShowModalContact(true);
                ref.click();
              }}
            >
              <Image src={ContactIcon} width="16"></Image>
              <span className="workspace-item-name-child ml-3 font-14 font-w-400 mt-1">
                Contact Sales
              </span>
            </Nav.Link>

            {params.permission_id < 4 ? (
              <>
                <div className="spacer mt-3"></div>
                <div
                  className="upgrade"
                  onClick={() => {
                    showModalPricing(true);
                    ref.click();
                  }}
                >
                  <span className="font-14 font-w-400 mt-1">
                    {params.team &&
                    params.team.subscriber &&
                    params.team.subscriber.plan
                      ? params.team.subscriber.plan.toUpperCase()
                      : "Upgrade"}
                  </span>
                </div>
              </>
            ) : null}
          </Col>
        </Row>
      </Popover.Content>
    </Popover>
  );

  return (
    <div
      className="position-absolute sidebar text-center"
      ref={(r) => (ref = r)}
    >
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="right"
        overlay={<Tooltip id="tooltip">Workspace</Tooltip>}
        rootClose
      >
        <Nav.Link
          onClick={() => (window.location.href = "/app/board")}
          className="m-0 p-0 mt-4"
        >
          <Image src={WorkspaceIcon}></Image>
        </Nav.Link>
      </OverlayTrigger>
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="right"
        overlay={<Tooltip id="tooltip">Notification</Tooltip>}
        rootClose
      >
        <Nav.Link
          className="m-0 p-0 mt-5"
          onClick={() => setShowNotif(true)}
          id={countNotif}
        >
          {parseInt(countNotif) === 0 ? null : (
            <div className="">
              <Counter
                id="Knobs"
                count={parseInt(countNotif)}
                size={Counter.sizes.SMALL}
                kind={Counter.kinds.FILL}
                color={Counter.colors.PRIMARY}
                wrapperClassName="counter-tag no-print ml-4 font-12"
              />
            </div>
          )}
          <Image src={NotifIcon}></Image>
        </Nav.Link>
      </OverlayTrigger>

      {/* <OverlayTrigger trigger={["hover", "focus"]} placement="right" overlay={
                <Tooltip id='tooltip'>
                    Chat
                </Tooltip>
            } rootClose>
                <Nav.Link className="m-0 p-0 mt-5" onClick={() => history.push('/app/chat')} id={'mytaskSidebar'}>
                    <Image src={ChatIcon} ></Image>
                </Nav.Link>
            </OverlayTrigger>

            <OverlayTrigger trigger={["hover", "focus"]} placement="right" overlay={
                <Tooltip id='tooltip'>
                    My Task
                </Tooltip>
            } rootClose>
                <Nav.Link className="m-0 p-0 mt-5" onClick={() => { history.push('/app/mytask'); }} id={'mytaskSidebar'}>
                    <Image src={NotifIcon} ></Image>
                </Nav.Link>
            </OverlayTrigger> */}

      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="top"
        overlay={<Tooltip id="tooltip">Teams</Tooltip>}
        rootClose
      >
        <Nav.Link
          className="position-fixed ml-2"
          style={{ bottom: 85 }}
          onClick={() => history.push("/app/setting/teams")}
        >
          <Image src={TeamsSidebarIcon} width="22"></Image>
        </Nav.Link>
      </OverlayTrigger>

      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="top"
        overlay={<Tooltip id="tooltip">Profile</Tooltip>}
        rootClose
      >
        <Nav.Link className="position-fixed ml-2" style={{ bottom: 30 }}>
          <OverlayTrigger
            trigger="click"
            placement="right"
            overlay={popoverProfile}
            rootClose={true}
          >
            <Image src={UserIcon} width="16.3"></Image>
          </OverlayTrigger>
        </Nav.Link>
      </OverlayTrigger>
    </div>
  );
};

export default SideBar;
