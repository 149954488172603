//=========REACT INITIAL========//
import React, {useEffect, useState, useContext, useRef} from 'react' 
import {DashboardContext} from "../../../context/DashboardContext" 
import {kanbanData, reorderDataById } from '../../../helpers/utils/Utils' 
 import Kanban from './Kanban'
 
//=========3RD LIBRARY========// 
import _ from 'lodash' 
import {DragDropContext} from 'react-virtualized-dnd'  

const Index = (props) =>{
    const {table,status, setColumnUpdate,params, updateTableKanban} = useContext(DashboardContext)  
    const [listData, setData] = useState([])  

    useEffect(()=>{ 
        setData(props.listData) 
    },[props.listData])
    
    const onDragEnd = (source, destinationId, placeholderId) =>{   
        const listToRemoveFrom = listData.find(list => list.name.includes(source.droppableId));
        const listToAddTo = listData.find(list => list.name.includes(destinationId));
        const elemToAdd = listToRemoveFrom.items.find(entry => entry.id === source.draggableId);
        let indexToRemove = listToRemoveFrom.items.findIndex(item => item.id === source.draggableId);
        let indexToInsert = placeholderId === 'END_OF_LIST' ? listToAddTo.items.length : placeholderId.includes('header') ? 0 : listToAddTo.items.findIndex(item => item.id === placeholderId);
        // Re-arrange within the same list
        if (listToRemoveFrom.name === listToAddTo.name) {
            if (indexToRemove === indexToInsert) {
                return;
            }
            // If we're moving an element below the insertion point, indexes will change.
            const direction = indexToRemove < indexToInsert ? 1 : 0;
            listToRemoveFrom.items.splice(indexToRemove, 1);
            listToAddTo.items.splice(indexToInsert - direction, 0, elemToAdd);
            let index = indexToInsert - direction
            // handleReorder(listToAddTo,index) 
        } else {
            listToRemoveFrom.items.splice(indexToRemove, 1);
            listToAddTo.items.splice(indexToInsert, 0, elemToAdd);
            handleUpdate(listToAddTo,indexToInsert,indexToRemove) 
        }
        
        // listData[listToRemoveFrom.index] = listToRemoveFrom
        // listData[listToAddTo.index] = listToAddTo    
        const newData = _.cloneDeep(listData, true)
        newData[listToRemoveFrom.index] = listToRemoveFrom
        newData[listToAddTo.index] = listToAddTo    
        setData(newData)  
    }

    const handleUpdate =(item, indexToInsert,indexToRemove) =>{
        let data = item.items[indexToInsert] 
        if(data === undefined) return
        let status = _.cloneDeep(item, true) 
        delete status.items  

        setColumnUpdate({id: data.idTable, colIndex: data.columnIndex, rowIndex:data.rowIndex, name: status, itemId : data.columnId, user_id: params.id, refresh: false}) 
    }

    const handleReorder = (item, index) =>{  
        const data = reorderDataById(table, item, index) 
    }

    // useEffect(()=>{
    //     let ids = []
    //     listData.map(data => {
    //         data.items.map(item => { 
    //             let tmp ={idTable: item.idTable, id : item.id}
    //             ids.push(tmp)
    //         })
    //     })
        
    //     _.map(table, (tab) => {
    //         let listKanban = _.filter(ids, (id) => {return id.idTable === tab.id})
    //         let newListKanban = _.map(listKanban, "id"); 
    //         tab.kanban = newListKanban
    //         if(newListKanban.length)
    //         updateTableKanban(tab.id, newListKanban)
    //     })
        
    // },[listData])

    
    return (      
         <DragDropContext 
            scrollContainerHeight={window.innerHeight - 10}
            dragAndDropGroup= {"kanban" + props.index} 
            onDragEnd= {onDragEnd} 
            outerScrollBar={true}>  
                <Kanban listData = {listData} index = {props.index} />
        </DragDropContext>
        
    )
} 

export default Index