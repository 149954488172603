import React, { useState } from 'react'; 
import {useHistory} from 'react-router-dom' 
import { useForm } from "react-hook-form"
import {Form, Row, Col, Button, Spinner} from 'react-bootstrap' 
import {MakeRequest} from "../../api/Api"   
import {asyncRequest } from '../../helpers/utils/Utils' 
import {NotificationManager, NotificationContainer} from 'react-notifications'  
import HeroImg from '../../img/img-contact.svg';

export const Hero = () => {
  const history = useHistory()  
  const { register, handleSubmit, watch, errors, reset } = useForm() 
  const [isLoading, setLoading] = useState(false) 
  const [filenameBukti, setFilenameBukti] = useState("")
  const [modalSpinner, setModalSpinner] = useState(false)

  const onSubmit = async (params) => {

    if(filenameBukti == ""){
        NotificationManager.error("Sertakan bukti pembayaran.") 
        return
    }
    
    setLoading(true)
    params.filename_bukti = filenameBukti
    const result = await MakeRequest('post','already-paid', params)   
    if(result.status !== 200) {
        asyncRequest(1000).then(()=>{
          setLoading(false)
            let detailError = result.data ? result.data.detail : 'Server error'
            NotificationManager.error(detailError) 
        })
    }else{
        asyncRequest(1000).then(()=>{
          NotificationManager.success(result.data.message) 
            setLoading(false)
            setFilenameBukti()
            reset();
        })
      }
   
  }

  const handleFileSelected = async(event) =>{  
    setLoading(true)
    const formData = new FormData() 
    formData.append('user_id', 226)
    formData.append('file', event.target.files[0])  
    
    const result = await MakeRequest('post', 'file-upload', formData)
    
    if(result.status === 200) {
        setFilenameBukti(result.data.filename_system)
        setLoading(false)
        console.log("berhasil upload")
    }else{
        console.log("gagal upload")
    }
}
 
    return (
      <div className="container-fluid Col-xs-"  id="contact">
         <NotificationContainer/>
        <div className="hero-container-already-paid">
          <Row className="w-100">
            <Col className="pl-5 pb-5 pr-5 pt-3">
          <span className="display-text-already-paid">
          Have you already paid and the package is not active?
          </span>
          <p className="hero-p-already-paid">
          For more info fill out the form
          </p> 
            <Form onSubmit={handleSubmit(onSubmit)} className="w-100">  
              
                <Form.Group controlId="formBasicEmail"> 
                      <Form.Control className="font-16 input-text" name="username" type="text" placeholder="Username" ref={register({ required: true })} />
                      <div className="mt-3">
                          {errors.name && <span>This field is required</span>}
                      </div>
                  </Form.Group>
                 
                <Form.Group controlId="formBasicEmail" className="pt-2"> 
                      <Form.Control className="font-16 input-text" name="email" type="email" placeholder="Email" ref={register({ required: true })} />
                      <div className="mt-3">
                          {errors.email && <span>This field is required</span>}
                      </div>
                  </Form.Group>
                
              
             
                <Form.Group controlId="formBasicEmail" className="pt-2"> 
                      <Form.Control className="font-16 input-text" name="transaction_id" type="text" placeholder="Transaction ID" ref={register({ required: true })} />
                      <div className="mt-3">
                          {errors.name && <span>This field is required</span>}
                      </div>
                  </Form.Group>

              
                <Form.Group controlId="formBasicEmail" className="pt-2"> 
                      <Form.Control as="textarea" rows={3} className="font-16 input-text" name="problem" type="text" placeholder="Problem" ref={register({ required: true })} />
                      <div className="mt-3">
                          {errors.message && <span>This field is required</span>}
                      </div>
                  </Form.Group>
                  
                <Form.Group controlId="formBasicEmail" className="pt-2"> 
                    <Form.Control className="font-16 input-text" name="bukti_transfer" type="file" placeholder="Bukti Transfer" ref={register({ required: false })} onChange={(e)=>handleFileSelected(e)}/>
                    <div className="mt-3">
                        {errors.name && <span>This field is required</span>}
                    </div>
                </Form.Group>
                
              <div className="w-100 pt-2">
                      <Button 
                          disabled={isLoading}
                          className="btn-submit"
                          type="submit" 
                          >
                          {isLoading ?
                              <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  />
                              : "Submit" 
                          }
                          </Button> 
                  </div> 
              </Form>  
              </Col>
              <Col className="m-auto">
                  <img id="heroimg-display-img-contact"src={HeroImg}></img>
              </Col>
              </Row>
        </div>

         
      </div>
    );
  } 

export default Hero