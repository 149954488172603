import XLSX from "xlsx";
import * as FileSaver from "file-saver";
import _ from "lodash";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";

export const stripHtml = (text) => {
  if (text === "" || text === undefined || text === null) return "";
  return text
    .replace(/(<([^>]+)>)/gi, "")
    .replace(/&nbsp;/g, "")
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&nbsp;/g, "");
};

export const stripHtmlAnd = (text) => {
  if (text === "" || text === undefined || text === null) return "";
  return text
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&nbsp;/g, "");
};

export const exportExcel = (param) => {
  let ws = XLSX.utils.json_to_sheet(param.data, { skipHeader: true });
  ws["!cols"] = param.wscols;
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const type =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const data = new Blob([excelBuffer], { type });
  FileSaver.saveAs(data, param.name + ".xlsx");
};

export const exportTemplate = (param) => {
  let ws = XLSX.utils.json_to_sheet(param.data, { skipHeader: true });
  ws["!cols"] = param.wscols;
  let wsStatus = XLSX.utils.json_to_sheet(param.statusData, {
    skipHeader: true,
  });
  wsStatus["!cols"] = param.wscols;
  let wsPriority = XLSX.utils.json_to_sheet(param.priorityData, {
    skipHeader: true,
  });
  wsPriority["!cols"] = param.wscols;
  let wsMember = XLSX.utils.json_to_sheet(param.memberData, {
    skipHeader: true,
  });
  wsMember["!cols"] = param.wscols;

  const wb = {
    Sheets: {
      data: ws,
      status: wsStatus,
      priority: wsPriority,
      member: wsMember,
    },
    SheetNames: ["data", "status", "priority", "member"],
  };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const type =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const data = new Blob([excelBuffer], { type });
  FileSaver.saveAs(data, param.name + ".xlsx");
};

export const getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const filterDataTableForFilter = (data, search) => {
  let values = [];
  let filter = _.filter(data, (items, key) => {
    let column = search.column;
    return _.some(items.rows, (item, index) => {
      let tmp = {};
      let columnIndex = _.findIndex(items.rows[0], { id: column });
      if (index > 0) {
        item.map((value, key) => {
          let tmpValue = value.name;
          if (columnIndex === key) {
            if (Array.isArray(value.name)) {
              value.name.map((val) => {
                tmpValue = val.value;
                tmp = { value: tmpValue, label: val.label };
                if (tmp.value) {
                  let isExist = _.some(values, function (i) {
                    return i.value == tmpValue;
                  });
                  if (!isExist) values.push(tmp);
                }
              });
            } else if (typeof value.name === "object")
              tmpValue = value.name.name;
            else if (typeof value.name === "number")
              tmpValue = value.name.name + "";

            tmp = { value: tmpValue, label: tmpValue };
            if (tmp.value) {
              let isExist = _.some(values, function (i) {
                return i.value == tmpValue;
              });
              if (!isExist) values.push(tmp);
            }
          }
        });
      }
      if (column === "Group" && index === 0) {
        let value = item[1].name;
        tmp = { value: value, label: stripHtmlAnd(value) };
        if (tmp.value) {
          let isExist = _.some(values, function (i) {
            return i.value == value;
          });
          if (!isExist) values.push(tmp);
        }
      }
      if (column === "Name" && index > 0) {
        if (item[1].type === "addsubitems" || item[1].type === "subitems")
          return;
        let value = item[1].name;
        tmp = { value: value, label: stripHtmlAnd(value) };

        if (tmp.value) {
          let isExist = _.some(values, function (i) {
            return i.value == value;
          });
          if (!isExist) values.push(tmp);
        }
      }
    });
  });
  values = _.filter(values, (item) => {
    return item.label.length;
  });
  return values;
};

export const filterColumnDataTable = (data, search) => {
  let column = search.column;
  let value = search.value;
  let typeColumn = search.type;
  let condition = search.condition;
  let filter = [];
  filter = _.filter(data, (items, key) => {
    return _.some(items.rows, (item, index) => {
      if (column === "Group" && index === 0) {
        if (condition === "is any of") {
          if (item[1].name.includes(value)) return item;
        }
        if (condition === "is not any of") {
          if (!item[1].name.includes(value)) return item;
        }
        if (condition === "is") {
          if (item[1].name === value) return item;
        }
        if (condition === "is not") {
          if (item[1].name !== value) return item;
        }
        if (condition === "is empty") {
          if (item[1].name === "") return item;
        }
        if (condition === "is not empty") {
          if (item[1].name !== "") return item;
        }
      }

      let columnIndex = _.findIndex(items.rows[0], { id: column });
      if (index > 0) {
        let key = columnIndex;

        if (column === "Name") key = 1;
        let tmpName = item[key] ? item[key].name : "";
        let name = item[key] ? item[key].name : "";
        if (Array.isArray(tmpName)) {
          tmpName.map((val) => {
            name += val.value;
          });
        } else if (typeof tmpName === "object") name = tmpName.name;
        else if (typeof tmpName === "number") name = tmpName;

        value = value + "";
        name = name + "";
        name = name.trim();
        value = value.trim();
        if (condition === "is any of") {
          if (name.includes(value)) {
            return item;
          }
        }
        if (condition === "is not any of") {
          if (!name.includes(value)) {
            return item;
          }
        }
        if (condition === "is") {
          if (name === value) return item;
        }
        if (condition === "is not") {
          if (name !== value) return item;
        }
        if (condition === "is empty") {
          if (name === "") return item;
        }
        if (condition === "is not empty") {
          if (name !== "") return item;
        }
      }
    });
  });
  if (column !== "Group") {
    let type = "text";
    if (typeColumn === "person") type = "person";
    if (typeColumn === "Name") column = "name";
    let search = {
      value: value,
      type: type,
      column: column,
      condition: condition,
    };
    filter.map((item) => {
      let newData = filterDataTableSpesificColumn(item.rows, search);
      if (newData.length) item.rows = newData;
    });
    return filter;
  }

  return filter;
};

export const filterDataTableByRows = (data, search) => {
  let filter = _.filter(data, (items, key) => {
    let value = search.value;
    if (value === "") return items;
    if (value === "undefined") value = "";
    return _.some(items, (item) => {
      let valueName = item.name;
      if (search.type === "person" && item.type === "person") {
        valueName = "";
        if (Array.isArray(item.name)) {
          item.name.map((val) => {
            valueName += val.value;
          });
        }
        if (valueName.includes(value) || key === 0) return item;
      }

      if (search.type === "text") {
        if (Array.isArray(item.name)) {
          valueName = "";
          item.name.map((val) => {
            valueName += val.label;
          });
        } else if (typeof item.name === "object") valueName = item.name.name;
        else if (typeof item.name === "number") valueName = item.name.name + "";
        if (
          valueName.toLowerCase().trim().includes(value.trim().toLowerCase()) ||
          key === 0
        )
          return item;
      }
    });
  });

  return filter;
};

export const filterDataTable = (tableParam, search) => {
  let table = _.cloneDeep(tableParam, true);
  let arrayTable = [];
  _.map(table, (data) => {
    let filter = _.filter(data.rows, (items, key) => {
      let value = search.value;
      if (value === "") return items;
      if (value === "undefined") value = "";
      return _.some(items, (item) => {
        let valueName = item.name;
        let type = item.type;
        if (search.type === "person" && item.type === "person") {
          valueName = "";
          if (Array.isArray(item.name)) {
            item.name.map((val) => {
              valueName += val.value;
            });
          }

          if (valueName.includes(value) || key === 0) return item;
        }

        if (search.type === "text") {
          if (Array.isArray(item.name)) {
            valueName = "";
            item.name.map((val) => {
              valueName += val.label;
            });
          } else if (typeof item.name === "object") valueName = item.name.name;
          else if (typeof item.name === "number")
            valueName = item.name.name + "";
          if (
            valueName
              .toLowerCase()
              .trim()
              .includes(value.trim().toLowerCase()) ||
            key === 0
          )
            return item;
        }
      });
    });
    if (filter.length > 1) {
      let tmpTable = data;
      tmpTable.rows = filter;
      arrayTable.push(tmpTable);
    }
  });

  return arrayTable;
};

export const filterDataTableSpesificColumn = (data, search) => {
  let filter = _.filter(data, (items, key) => {
    let value = search.value;
    let condition = search.condition;
    if (value === "") return items;
    if (value === "undefined") value = "";
    return _.some(items, (item, index) => {
      let valueName = item.name;
      let type = item.type;
      let columnIndex = _.findIndex(data[0], { id: search.column });
      if (search.column === "name") columnIndex = 1;
      if (index === columnIndex) {
        if (search.type === "person" && item.type === "person") {
          valueName = "";
          if (Array.isArray(item.name)) {
            item.name.map((val) => {
              valueName += val.value;
            });
          }
          let name = valueName.toLowerCase().trim();
          value = value.trim().toLowerCase();
          if (condition === "is any of") {
            if (name.includes(value) || key === 0) {
              return item;
            }
          }

          if (condition === "is not any of") {
            if (!name.includes(value) || key === 0) {
              return item;
            }
          }

          if (condition === "is") {
            if (name === value || key === 0) return item;
          }

          if (condition === "is not" || key === 0) {
            if (name !== value) return item;
          }

          if (condition === "is empty" || key === 0) {
            if (name === "") return item;
          }

          if (condition === "is not empty" || key === 0) {
            if (name !== "") return item;
          }
        }

        if (search.type === "text") {
          if (Array.isArray(item.name)) {
            valueName = "";
            item.name.map((val) => {
              valueName += val.label;
            });
          } else if (typeof item.name === "object") valueName = item.name.name;
          else if (typeof item.name === "number")
            valueName = item.name.name + "";

          let name = valueName.toLowerCase().trim();
          value = value.trim().toLowerCase();
          if (condition === "is any of") {
            if (name.includes(value) || key === 0) {
              return item;
            }
          }

          if (condition === "is not any of") {
            if (!name.includes(value) || key === 0) {
              return item;
            }
          }

          if (condition === "is") {
            if (name === value || key === 0) return item;
          }

          if (condition === "is not" || key === 0) {
            if (name !== value) return item;
          }

          if (condition === "is empty" || key === 0) {
            if (name === "") return item;
          }

          if (condition === "is not empty" || key === 0) {
            if (name !== "") return item;
          }
        }
      }
    });
  });

  return filter;
};

export const filterDataTableColumn = (data, search) => {
  let filter = _.filter(data.rows, (items, key) => {
    let value = search.value;
    if (value === "") return items;
    if (value === "undefined") value = "";
    if (search.type === "name") search.type = "text";
    return _.some(items, (item) => {
      let valueName = item.name;
      if (search.type !== item.type) return;
      if (search.type === "person" && item.type === "person") {
        valueName = "";
        if (Array.isArray(item.name)) {
          item.name.map((val) => {
            valueName += val.value;
          });
        }
        if (value === "") {
          if (valueName === value || key === 0) return item;
        } else {
          if (valueName.includes(value) || key === 0) return item;
        }
      }
      if (Array.isArray(item.name)) {
        valueName = "";
        item.name.map((val) => {
          valueName += val.label;
        });
      } else if (typeof item.name === "object") valueName = item.name.name;

      if (
        valueName.toLowerCase().trim() === value.trim().toLowerCase() ||
        key === 0
      )
        return item;
    });
  });

  data.rows = filter;
  return data;
};

export const filterDataTableById = (data, search) => {
  let filter = _.filter(data.rows, (items, key) => {
    let id = search.id;
    return _.some(items, (item) => {
      if (id === item.id || id === item.parent || key === 0) return item;
    });
  });

  data.rows = filter;
  return data;
};

export const filterDataTableByItem = (data, search) => {
  let filter = _.filter(data.rows, (items, key) => {
    let value = search.value;
    if (value === "") return items;
    if (value === "undefined") value = "";
    if (search.type === "name") search.type = "text";
    return _.some(items, (item) => {
      let valueName = item.name;
      if (search.type !== item.type) return;
      if (search.type === "person" && item.type === "person") {
        valueName = "";
        if (Array.isArray(item.name)) {
          item.name.map((val) => {
            valueName += val.value;
          });
        }
        if (value === "") {
          if (valueName === value || key === 0) return item;
        } else {
          if (valueName.includes(value) || key === 0) return item;
        }
      }
      if (Array.isArray(item.name)) {
        valueName = "";
        item.name.map((val) => {
          valueName += val.label;
        });
      } else if (typeof item.name === "object") valueName = item.name.name;

      if (
        valueName.toLowerCase().trim() === value.trim().toLowerCase() ||
        key === 0
      )
        return item;
    });
  });

  data.rows = filter;
  return data;
};

export const sortDataTableByRows = (paramData, search) => {
  let data = _.cloneDeep(paramData);
  let value = search.value;
  let column = search.column;
  let activeIndex = 0;
  if (value === "undefined") value = "";

  let arrTmp = [],
    arrHeader = [];
  paramData.map((item, index) => {
    item.map((val, idx) => {
      if (val.type === column) {
        activeIndex = idx;
        if (Array.isArray(val.name)) {
          val.name.map((val) => {
            val.name += val.label + " ";
          });
          return;
        } else if (typeof val.name === "object") val.name = val.name.name;
      }
    });
    if (column === "Name") activeIndex = 1;
    if (index == 0) {
      arrHeader.push(item[activeIndex]);
      return;
    }
    arrTmp.push(item[activeIndex]);
  });
  arrTmp.sort((a, b) => {
    if (value === "asc") {
      if (a.parent) return 0;
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    }

    if (value === "desc") {
      if (a.parent) return 0;
      if (b.name < a.name) return -1;
      if (b.name > a.name) return 1;
      return 0;
    }
  });
  let newArray = arrHeader.concat(arrTmp);
  let fixedArray = [];
  newArray.map((item) => {
    if (!item) return;
    let id = item.id;
    data.map((itemData) => {
      if (!itemData[activeIndex]) return;
      if (
        itemData[activeIndex].id === id &&
        itemData[activeIndex].type !== "subitems" &&
        itemData[activeIndex].type !== "addsubitems"
      ) {
        fixedArray.push(itemData);
      }
    });
  });

  return fixedArray;
};

export const sortDataTable = (paramData, search) => {
  let value = search.value;
  let column = search.column;
  let activeIndex = 0;
  if (value === "undefined") value = "";

  let tmpTable = [];
  _.map(paramData, (data) => {
    let tmpRows = _.cloneDeep(data.rows);
    data.rowsOri = tmpRows;
    let arrTmp = [],
      arrHeader = [];
    data.rows.map((item, index) => {
      let columnIndex = _.findIndex(tmpRows[0], { id: search.column });
      item.map((val, idx) => {
        if (columnIndex === idx) {
          activeIndex = idx;
          if (Array.isArray(val.name)) {
            val.name.map((val) => {
              val.name += val.label + " ";
            });
            return;
          } else if (typeof val.name === "object") val.name = val.name.name;
        }
      });
      if (column === "Name") activeIndex = 1;
      if (index == 0) {
        arrHeader.push(item[activeIndex]);
        return;
      }
      arrTmp.push(item[activeIndex]);
    });
    data.rowsSort = arrTmp;
    data.header = arrHeader;
    tmpTable.push(data);
  });

  _.map(tmpTable, (item) => {
    item.rowsSort.sort((a, b) => {
      if (value === "asc") {
        if (a.parent) return 0;
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      if (value === "desc") {
        if (a.parent) return 0;
        if (b.name < a.name) return -1;
        if (b.name > a.name) return 1;
        return 0;
      }
    });

    item.rowsSort = item.header.concat(item.rowsSort);
  });

  let fixData = [];
  _.map(tmpTable, (table) => {
    let fixedArray = [],
      tmpObj = {};
    _.map(table.rowsSort, (item) => {
      if (!item) return;
      let id = item.id;
      _.map(table.rowsOri, (itemData) => {
        if (!itemData[activeIndex]) return;
        if (
          itemData[activeIndex].id === id &&
          itemData[activeIndex].type !== "subitems" &&
          itemData[activeIndex].type !== "addsubitems"
        ) {
          fixedArray.push(itemData);
        }
      });
    });
    tmpObj = table;
    tmpObj.rows = fixedArray;
    fixData.push(tmpObj);
  });
  fixData = _.map(fixData, (o) => _.omit(o, ["rowsSort", "header"]));
  return fixData;
};

export const kanbanData = (dataParam, dataHeaderParam, columnParam) => {
  let data = _.cloneDeep(dataParam, true);
  let assignee = _.cloneDeep(columnParam.assignee, true);
  if (!assignee) assignee = { value: "" };
  let priority = _.cloneDeep(columnParam.priority, true);
  if (!priority) priority = { value: "" };
  let due = _.cloneDeep(columnParam.due, true);
  if (!due) due = { value: "" };
  let progress = _.cloneDeep(columnParam.progress, true);
  if (!progress) progress = { value: "" };
  let dataHeader = _.cloneDeep(dataHeaderParam, true);
  let column = _.cloneDeep(columnParam, true);
  let headers = [];
  dataHeader.map((item) => {
    item.name = stripHtmlAnd(item.name);
    if (item.name.trim() === "") {
      item.name = "Empty";
    }
    headers.push(item);
  });

  //===REORDER ITEM IF KANBAN ALREDY EXIST ====///
  // _.map(data, (item)=>{
  //     if(item.kanban) {
  //         let newRows = []
  //         item.kanban.map(id=> {
  //             _.map(item.rows, (row)=>{
  //                 _.map(row, (rw) => {if(rw.id === id) newRows.push(row)})
  //             })

  //         })
  //         item.rows = newRows
  //     }
  // })

  //====GET ITEM BY COLUMN HEADER====///
  let arrItems = [],
    header = [];
  _.map(data, (items, keyTable) => {
    _.map(items.rows, (item, key) => {
      if (key === 0) return;
      let assigneeValue = "",
        priorityValue = "",
        dueVallue = "-",
        progressValue = 0;
      _.map(item, (val, idx) => {
        let columnIndex = _.findIndex(items.rows[0], { id: column.value });
        let columnIndexAssignee = _.findIndex(items.rows[0], {
          id: assignee.value,
        });
        let columnIndexPriority = _.findIndex(items.rows[0], {
          id: priority.value,
        });
        let columnIndexDue = _.findIndex(items.rows[0], { id: due.value });
        let columnIndexProgress = _.findIndex(items.rows[0], {
          id: progress.value,
        });
        let parentTask = "";
        if (item[1].parent) {
          _.map(items.rows, (itemTmp, key) => {
            let parrent = _.filter(itemTmp, (data) => {
              return data.id === item[1].parent;
            });
            if (parrent.length) parentTask = parrent[0].name;
          });
        }
        let tmpItems = {
          item: {
            name: stripHtmlAnd(item[1].name),
            id: item[1].id,
            idTable: items.id,
            columnIndex,
            columnId: val.id,
            keyTable,
            rowIndex: columnIndex,
            parentTask,
          },
        };

        if (item[columnIndexAssignee]) {
          let value = item[columnIndexAssignee];
          if (typeof value.name !== "object") value.name = [];
          assigneeValue = value;
        }

        if (item[columnIndexPriority]) {
          let value = item[columnIndexPriority];
          priorityValue = value;
        }

        if (item[columnIndexDue]) dueVallue = item[columnIndexDue].name;

        if (item[columnIndexProgress])
          progressValue = item[columnIndexProgress].name;

        if (columnIndex !== idx) return;
        if (val.name === "") {
          let tmp = { name: "Empty" };
          val.name = tmp;
        }

        if (typeof val.name === "object" && val.name.name.trim() === "")
          val.name.name = "Empty";
        let tmp = { id: uuid(), name: stripHtmlAnd(val.name.name), items: [] };
        tmpItems.status = tmp;
        tmpItems.item.assignee = assigneeValue;
        tmpItems.item.due = dueVallue;
        tmpItems.item.priority = priorityValue;
        tmpItems.item.progress = progressValue;
        tmpItems.item.group = items.rows[0][1].name;
        tmpItems.item.groupId = items.rows[0][1].id;
        arrItems.push(tmpItems);
      });
    });
  });
  //====REORDER EMPTY HEADER TO LAST POSITION====///
  let emptyHeader = _.filter(headers, (item) => {
    return item.name === "Empty";
  });
  if (emptyHeader.length === 0)
    emptyHeader.push({ id: uuid(), name: "Empty", items: [] });
  header = _.filter(headers, (item) => {
    return item.name !== "Empty";
  });
  header = header.concat(emptyHeader);
  headers = header;

  //====ASSIGN ITEM TO DATA====///
  _.map(headers, (item, index) => {
    item.index = index;
    let valueItem = _.filter(arrItems, (data) => {
      return data.status.name === item.name;
    });
    valueItem = _.map(valueItem, "item");
    item.items = valueItem;
  });

  return headers;
};

export const reorderDataById = (table, item, index) => {
  // let dataMove = item.items[index]
  // let dataUp = item.items[index-1]
  // //====FIND ROWS BY DATA MOVE ====///
  // let arrRows = []
  // _.map(table, (tab)=> {
  //     _.map(tab.rows,(item)=>{
  //         _.map(item, (val) => {if (val.id === dataMove.id) arrRows.push(tab)})
  //     })
  // })
  // _.map(arrRows[0].rows, (item) => {
  //     console.log(item)
  // })
  // console.log(table, item, dataMove,dataUp)
};

export const taskByGroup = (table, parent) => {
  let groupedOptions = [];
  _.map(table, (tab, idx) => {
    _.map(tab.rows, (row, index) => {
      if (row[1].type === "subitems" || row[1].type === "addsubitems") return;

      if (index === 0) {
        let tmp = { label: row[1].name, options: [] };
        groupedOptions.push(tmp);
      }
      if (index > 0) {
        if (parent && !row[1].parent) return;
        if (!parent && row[1].parent) return;
        let tmp = { value: row[1].id, label: row[1].name };
        groupedOptions[idx].options.push(tmp);
      }
    });
  });
  return groupedOptions;
};

export const getSpesificColumn = (table, column) => {
  let arrStatus = [];
  _.map(table, (tab) => {
    _.map(tab.rows, (item, index) => {
      _.map(item, (val) => {
        if (column.includes(val.type) && index === 0) {
          if (!_.some(arrStatus, (item) => item.value === val.id))
            arrStatus.push({ value: val.id, label: val.name, type: val.type });
        }
      });
    });
  });
  return arrStatus;
};

export const getStatusColumn = (table, id) => {
  let data = _.cloneDeep(table, true);
  let arrStatus = [];
  _.map(data, (tab) => {
    _.map(tab.rows, (item, index) => {
      _.map(item, (val) => {
        if (id === val.id && index === 0) {
          arrStatus = val.list;
        }
      });
    });
  });
  return arrStatus;
};

export const divToImg = (div, name) => {
  html2canvas(div).then((canvas) => {
    let img = canvas.toDataURL();
    FileSaver.saveAs(img, name);
  });
};

export const divToPDF = (div, name) => {
  html2canvas(div).then((canvas) => {
    var doc = new jsPDF("p", "mm", "a4");
    let img = canvas.toDataURL();
    doc.addImage(
      img,
      "PNG",
      0,
      0,
      canvas.width * 0.2,
      canvas.height * 0.2,
      "a",
      "FAST"
    );
    doc.save(name + ".pdf");
  });
};

export const ExcelDateToJSDate = (serial) => {
  var utc_days = Math.floor(serial - 25569);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);

  var fractional_day = serial - Math.floor(serial) + 0.0000001;

  var total_seconds = Math.floor(86400 * fractional_day);

  var seconds = total_seconds % 60;

  total_seconds -= seconds;

  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;

  return new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate(),
    hours,
    minutes,
    seconds
  );
};

export const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const titleCase = (str) => {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};

export const asyncRequest = (timeout) => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};

export const initialString = (text) => {
  if (text === "" || text === undefined || text === null) return "";
  return text
    .match(/(^\S\S?|\b\S)?/g)
    .join("")
    .match(/(^\S|\S$)?/g)
    .join("")
    .toUpperCase();
};

export const getDataTimeLine = (data) => {
  if (!data.length) return [];
  let array = [];
  let keyStartDate = 0;
  let keyDueDate = 0;
  let keyName = 1;
  let keyProgress = 0;
  let depedency = 0
  let displayOrder = 1;

  data.map((item, key) => {
    let dates = [],
      arraytmp = [];
    item.rows.map((row, keyRow) => {
      if (keyRow === 0) {
        keyStartDate = _.findKey(row, function (value) {
          return value.name === "Start Date";
        });
        keyDueDate = _.findKey(row, function (value) {
          return value.name === "Due Date";
        });
        keyProgress = _.findKey(row, function (value) {
          return value.name === "Progress";
        });
        depedency = _.findKey(row, function (value) {
          return value.name === "Depedency";
        });
      }

      if (!row[keyStartDate] || !row[keyDueDate]) return;
      let rowStart = row[keyStartDate].name;
      let rowEnd = row[keyDueDate].name;
      if (rowStart === "" || rowEnd === "") return;

      if (keyRow === 0) {
        const currentDate = new Date();
        let obj = {
          start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
          end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
          name: row[keyName].name,
          id: row[keyName].id,
          progress: 0,
          type: "project",
          hideChildren: false,
          displayOrder,
        };
        arraytmp.push(obj);
      }

      if (keyRow > 0) {
        let start = moment(row[keyStartDate].name, "DD MMM YYYY")
          .add(7, "hours")
          .toDate();

        let end = moment(row[keyDueDate].name, "DD MMM YYYY")
          .add(7, "hours")
          .toDate();
        
        let obj = {
          start,
          end,
          name: row[keyName].name,
          id: row[keyName].id,
          type: "task",
          displayOrder,
          progress: row[keyProgress].name,
          dependencies: [],
        };
        dates.push(start);
        dates.push(end);
        arraytmp.push(obj);
        displayOrder++;
      }
    });

    const sortedDates = _.orderBy(
      dates,
      (o) => {
        return moment(o);
      },
      ["asc"]
    );

    if (arraytmp.length > 0 && sortedDates.length > 0) {
      arraytmp[0].start = sortedDates[0];
      arraytmp[0].end = sortedDates[sortedDates.length - 1];
    }
    array.push(...arraytmp);
  });

  return array;
};

export const getDataTimeLineReport = (data, selectedTeams) => {
  if (!data.length) return [];
  let array = [];
 
  let datesAll = []
   
  let obj = {
    start: new Date(),
    end: new Date(),
    name: selectedTeams.label,
    id: selectedTeams.label,
    progress: 0,
    type: "project",
    hideChildren: false,
    displayOrder: 1,
  };

  array.push(obj) 
  data.map((item, key) => {
    if (!item.dates.length) return;
    let dates = item.dates 
    let obj = {
      start: moment(dates[0], "YYYY-MM-DD").toDate(),
      end: moment(dates[1], "YYYY-MM-DD").toDate(),
      name: item.workspace,
      id: item.workspace,
      progress: 0,
      type: "task",
      displayOrder: key,
    };
    datesAll.push(...item.dates )
    array.push(obj);
  });
 
  const sortedDates = _.orderBy(
    datesAll,
    (o) => {
      return moment(o,"YYYY-MM-DD").toDate();
    },
    ["asc"]
  );

  if (array.length > 0 && sortedDates.length > 0) {
    array[0].start = moment(sortedDates[0],"YYYY-MM-DD").toDate(); 
    array[0].end =  moment(sortedDates[sortedDates.length - 1],"YYYY-MM-DD").toDate();
  }
  console.log(array)
  return array;
};


export const getDataTimeLineReportWorkspace = (data, selectedWorkspace) => {
    if (!data.length) return [];
    let array = [];
   
    let datesAll = []
     
    let obj = {
      start: new Date(),
      end: new Date(),
      name: selectedWorkspace.label,
      id: selectedWorkspace.label,
      progress: 0,
      type: "project",
      hideChildren: false,
      displayOrder: 1,
    };
  
    array.push(obj) 
    data.map((item, key) => {
      if (!item.dates.length) return;
      let dates = item.dates 
      let obj = {
        start: moment(dates[0], "YYYY-MM-DD").toDate(),
        end: moment(dates[1], "YYYY-MM-DD").toDate(),
        name: item.board,
        id: item.board,
        progress: 0,
        type: "task",
        displayOrder: key,
      };
      datesAll.push(...item.dates )
      array.push(obj);
    });
   
    const sortedDates = _.orderBy(
      datesAll,
      (o) => {
        return moment(o,"YYYY-MM-DD").toDate();
      },
      ["asc"]
    );
  
    if (array.length > 0 && sortedDates.length > 0) {
      array[0].start = moment(sortedDates[0],"YYYY-MM-DD").toDate(); 
      array[0].end =  moment(sortedDates[sortedDates.length - 1],"YYYY-MM-DD").toDate();
    }
    console.log(array)
    return array;
  };
