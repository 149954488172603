import React, { Component } from 'react';
import Logo from '../../img/icon-footer.svg'
import { HashLink as Link } from 'react-router-hash-link';
import {Col,Row } from 'react-bootstrap'   

export default class Footer extends Component {
  render() {
    return (
      <div>
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mt-5 text-center">
                 <img src={Logo}></img>
                  <div>
                  <nav className="navbar navbar-expand-sm transparent">
                    <div className="container">
                      <Row className="w-100">
                        <Col md="4"> 
                        </Col>
                        <Col md="5">
                        <ul className="navbar-nav">
                          <li className="nav-item mt-2">
                            <Link smooth to="#product" className="nav-text product">
                              Product
                            </Link>
                          </li>
                          <li className="nav-item mt-2">
                            <Link smooth to="#uses-page" className="nav-text use-cases" style={{width:'70px'}}>
                                Why Us
                              </Link>
                          </li>
                          
                          <li className="nav-item mt-2">
                            <Link smooth to="#contact" className="nav-text use-cases">
                                Contact
                              </Link>
                          </li>

                          <li className="nav-item mt-2">
                            <Link smooth to="/support" className="nav-text use-cases">
                                Support
                              </Link>
                          </li> 

                        </ul>
                        </Col>
                        <Col md="3">
                          
                        </Col>
                      </Row> 
                    </div>
                  </nav>
                  </div> 
                  <hr></hr>
              </div>
            </div>
            <div className="mb-5">
                <span>© 2021 Saat Kerja. All rights reserved</span>
              </div>
          </div>
        </footer>
      </div>
    );
  }
}
