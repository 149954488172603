import React, { useState } from 'react'; 
import './css/home.css';
import { HashLink as Link } from 'react-router-hash-link';
import {Col, Row } from 'react-bootstrap'   


// COMPONENTS
import Hero from './Hero';
import Product from './Product';
import Footer from './Footer'; 
import Contact from './Contact'; 
import {useHistory} from 'react-router-dom' 
import Logo from '../../img/logo.png'
import Icon from '../../img/icon.svg'
import Promo from './ModalPromo'; 
import {asyncRequest } from '../../helpers/utils/Utils' 



const Home = () => {
  const history = useHistory()  
  const [isShowModal, setShowModal ] = useState(false)

  useState(()=>{
    asyncRequest(1000).then(()=>{
      setShowModal(true)
    })
  })

  const login = async e => { 
    localStorage.removeItem('saatkerja')
    history.push('/login') 
  }

  const register = async e => { 
    localStorage.removeItem('saatkerja')
    history.push('/register') 
  }

    return (
      <div className='overflow'>
        <Promo show = {isShowModal} onHide={()=>setShowModal(false)}/>
        <nav class="navbar nav-scroll fixed-top navbar-expand-lg transparent w-100">
          <div className='container'>
            <div className="navbar-brand">
              <Link smoot to="/">
                <img src={Icon} alt="logo" />
              </Link>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon">
                =
              </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="istprod" id="oke">
              <ul className="navbar-nav">
                  <li className="nav-item mt-2" id="li-display1">
                    <Link smooth to="#product" className="nav-text product">
                      Product
                    </Link>
                  </li>
                  <li className="nav-item mt-2" id="why">
                    <Link smooth to="#uses-page" className="nav-text use-cases" style={{width:'70px'}}>
                        Why Us
                      </Link>
                  </li>
                  <li className="nav-item mt-2" id="pri">
                    <Link smooth to="/pricing" className="nav-text use-cases">
                        Pricing
                      </Link>
                  </li>
                  <li className="nav-item mt-2" id="con">
                    <Link smooth to="#contact" className="nav-text use-cases">
                        Contact
                      </Link>
                  </li>
                  <li className="nav-item mt-2" id="sup">
                    <Link smooth to="/support" className="nav-text use-cases">
                        Support
                      </Link>
                  </li> 
                
                  <Col md="3" className='w-100' id="col-menu-btn">
                    <ul className="navbar-nav" id="btnup"> 
                    <div className='log'>
                      <span className="signup-btn cursor-pointer mr-3" id="signup-btn" onClick={register}>
                        <span>Sign Up</span>
                      </span>
                      <span className="login-btn cursor-pointer" id="login-btn" onClick={login}>
                        <span >Login</span>
                      </span>
                      </div>
                    </ul> 
                  </Col>
                </ul>
              </div>
            </div>
          </div>
        </nav>
        {/* <nav className="navbar nav-scroll fixed-top navbar-expand-sm transparent w-100">
           
          <div className="container">
            <Row className="w-100">
              <Col md="4">
                <div className="navbar-brand">
                  <Link smoot to="/">
                    <img src={Icon} alt="logo" />
                  </Link>
                </div>
              </Col>
              <Col md="5">
              <ul className="navbar-nav">
                <li className="nav-item mt-2">
                  <Link smooth to="#product" className="nav-text product">
                    Product
                  </Link>
                </li>
                <li className="nav-item mt-2">
                  <Link smooth to="#uses-page" className="nav-text use-cases" style={{width:'70px'}}>
                      Why Us
                    </Link>
                </li>

                <li className="nav-item mt-2">
                  <Link smooth to="/pricing" className="nav-text use-cases">
                      Pricing
                    </Link>
                </li>
 
                 
                <li className="nav-item mt-2">
                  <Link smooth to="#contact" className="nav-text use-cases">
                      Contact
                    </Link>
                </li>

                <li className="nav-item mt-2">
                  <Link smooth to="/support" className="nav-text use-cases">
                      Support
                    </Link>
                </li> 

              </ul>
              </Col>
              <Col md="3">
                <ul className="navbar-nav"> 
                  <span className="signup-btn cursor-pointer mr-3" onClick={register}>
                    <span>Sign Up</span>
                  </span>
                  <span className="login-btn cursor-pointer" onClick={login}>
                    <span >Login</span>
                  </span>
                </ul> 
              </Col>
            </Row> 
          </div>
        </nav> */}
        <Hero />
        <Product /> 
        <Contact /> 
        <Footer/>
      </div>
    );
  } 

export default Home