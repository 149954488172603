import React, {useEffect, useState, useContext, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import {MakeRequest} from '../../api/Api'
import {asyncRequest } from '../../helpers/utils/Utils' 

//=========BOOTSTARP INITIAL========//
import {Col, Row, Image} from 'react-bootstrap'  
import HeaderAndroid from './HeaderAndroid'

//=========CONTEXT========//
import {DashboardContext} from "../../context/DashboardContext"  
 
//=========COMPONENT========//
import TableAndroid from './TableAndroid' 
import ContainerKanban from './kanban/Container'  
 
const Workspace = () => {  
    const {table, viewType, width} = useContext(DashboardContext)  
    const [tableComponent, setTableComponent] = useState([]) 
    // const [table, setTable] = useState([])
    // const [viewType, setViewType] = useState('Default')
    // const [width, setWidth] = useState({board: '290px'})
    const isFirstRun = useRef(true) 
    const history = useHistory() 

    // console.log("viewType", viewType)
    // console.log("width", width)

    useEffect(()=>{
        // getTable();
        
        setTableComponent([])
        if(!table.length) return
        let componentTmp = []   
        table.map((item, key) =>{  
            componentTmp.push(<TableAndroid item={item} index = {key} />)
        })
        setTableComponent(componentTmp) 

        if(isFirstRun.current){ 
            isFirstRun.current = false
            return
        }  
        
    },[table])

    // const getTable = async() => {
    //     const urlParams = new URLSearchParams(window.location.search);
    //     let brdId = urlParams.get('board');

    //     // console.log("urllljeeng", brdId)
    //     let params = { board_id: parseInt(brdId) }
    //     const getTbl = await MakeRequest('post', 'table-read', params)
    //     // console.log("getTbl", getTbl)
    //     // return
    //     if (getTbl.status === 200) {
    //         setTableComponent([])
    //         if(!getTbl.data.length) return
    //         let componentTmp = []   
    //         getTbl.data.map((item, key) =>{  
    //             componentTmp.push(<Table item={item} index = {key} />)
    //         })
    //         setTableComponent(componentTmp) 

    //         if(isFirstRun.current){ 
    //             isFirstRun.current = false
    //             return
    //         }
            
    //         // setTable(getTbl.data)
    //     }
    // }

    return (
        <Row className="vh-100">   
             <Col md={12} className="pr-0">
                {/* <div className="content-board vh-100 overflow-scroll" style={{marginLeft: width.board}}> */}
                <div className="content-board vh-100 overflow-scroll pr-0">
                    <div className="devider pl-2 pt-2">
                        <HeaderAndroid/>
                    </div>
                    <Col className={viewType !== "Default" ? "bg-grey overflow-scroll p-0" : "overflow-scroll section-to-print-board-android p-0" } >  
                        {viewType !== "Default" ? <ContainerKanban/> :
                            tableComponent.map((content,index)=>{
                                return (
                                    content === undefined ? "" :
                                        <React.Fragment key={index}>
                                            {React.cloneElement(content)} 
                                        </React.Fragment>
                                    )
                                })
                        }  
                    </Col> 
                </div> 
            </Col>
        </Row>
    )
}

export default Workspace
