import React, {useEffect, useState, useContext, useRef} from 'react'
import {Modal, Button, Spinner, Form, Col, Row } from 'react-bootstrap'  
import {DashboardContext} from "../../context/DashboardContext" 
import {StyleSelect} from "../../helpers/select/Select"
import Select from 'react-select' 
import {asyncRequest } from '../../helpers/utils/Utils' 

//=========3RD LIBRARY========//
import _ from 'lodash' 
import {NotificationManager} from 'react-notifications' 

const ModalEditWorkspaceMember = (props) =>{ 
    const [isLoading, setLoading] = useState(false) 
    const [isActive, setIsActive] = useState(false) 
    const [listType, setListType] = useState([])
    const [data, setData] = useState({user : {username:""}})
    const {permission, editWorkspaceMember, updateWorkspaceTeams, getWorkspaceTeams, 
            sendNotif, selectedTeamsSetting, params, setEditWorkspaceMember} = useContext(DashboardContext)  
    const [selected, setSelected] = useState({})


    useEffect(()=>{ 
        if(editWorkspaceMember.isShow){
            setIsActive(editWorkspaceMember.data.is_active)
            setData(editWorkspaceMember.data) 
            console.log(editWorkspaceMember.data)
        }
       
    },[editWorkspaceMember])

    useEffect(()=>{ 
        if(data.permission_id)
        getType()
         
    },[data])

    
    const getType = () => {
        let type = []
        permission.map(item =>{ 
            if(item.id !== 1) {
                let tmp =  {value:item.id, label: item.name,  id: item.id}   
                if(item.id === data.permission_id) setSelected(tmp)
                type.push(tmp)
            }
        }) 

        setListType(type)
    }

    
    const handleChange = (selected) =>{
        setSelected(selected)
    }

    const handleUpdate = async() => {
        setLoading(true)   
        let param = _.cloneDeep(data) 
        param.permission_id = selected.value
        param.permission = selected
        param.is_active = isActive 
        let result =  await updateWorkspaceTeams(param)
        await getWorkspaceTeams(data.workspace_id) 
        asyncRequest(1000).then(()=>{ 
                let dataNotif = {
                receiver_id : param.user_id,
                sender_id: params.id,  
                team_id: selectedTeamsSetting.id,
                message : `${params.username} has been Updated your team status to ${param.permission_name} at ${selectedTeamsSetting.label} Team.`,
                is_read: false, 
                item_id: param.permission_id,
                created_at: new Date(),
                updated_at: new Date(),
                type: 5 
            }  
            asyncRequest(500).then(()=>{ 
                setEditWorkspaceMember({isShow: false})
            })
          
            sendNotif(dataNotif)
            NotificationManager.success(result.message) 
            setLoading(false)
           }) 
    }
 

    return (
        <Modal
        {...props}
        size=""
        aria-labelledby="contained-modal-title-vcenter"
        centered>
                <Modal.Header>
                    <span className="modal-title">Edit Member </span>
                </Modal.Header>
                <Modal.Body> 
                    
                    <Form.Group controlId="formBasicEmail" className="p-3 pt-4"> 
                        <Form.Row>
                            <Form.Label className="font-14 w-50 text-dark font-w-400">Name</Form.Label>
                            <Col className="w-50 h-100">
                                <span className="font-14 font-w-400 text-dark float-right m-auto">{data.user.username}</span>
                            </Col>
                        </Form.Row> 
                        <br />
                        <Form.Row>
                            <Form.Label className="font-14 w-50 text-dark font-w-400">Active Status</Form.Label>
                            <Col className="w-50 h-100">
                                <Form.Check type="switch" onChange={(e)=> {setIsActive(e.target.checked)}} checked={isActive} className="float-right m-auto" id="custom-switch" label=""/> 
                            </Col>
                        </Form.Row> 
                        <br />
                        <Form.Row>
                            <Form.Label className="font-14 w-50 text-dark font-w-400">User Type</Form.Label>
                            <Col className="w-50 h-100">
                            <Select focus styles={StyleSelect({menuListHeight: 250})}   
                                placeholder=""  
                                options={listType}  
                                isClearable={false} 
                                value={selected}
                                onChange={(selected) => handleChange(selected)}
                                className='m-0 p-0' 
                                ></Select>
                            </Col>
                        </Form.Row> 
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-cancel" onClick={props.onHide}>Cancel</Button>  
                    <Button 
                        disabled={isLoading}
                        className="btn-yes text-white"
                        onClick={()=>handleUpdate()}
                        type="submit"
                        >
                            {isLoading ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    />
                                : "Update" 
                            }
                    </Button> 
                </Modal.Footer>
             
        </Modal>
    )
}

export default ModalEditWorkspaceMember