import React, {useEffect, useState, useContext, useRef} from 'react'
import {Modal, Tabs, Tab, Image} from 'react-bootstrap'  

//=========3RD LIBRARY========//
import _ from 'lodash' 
import moment from "moment" 
import 'react-quill/dist/quill.snow.css'

//=========ICON LIST========//    
import AvatarIcon from "../../img/avatar-logs.svg"
import FolderActiveIcon from "../../img/folder.svg" 

//========CONTEXT==========//
import {DashboardContext} from "../../context/DashboardContext" 

//========CSS==========//
import  "../css/modal-custome.css" 



const ModalNotif = (props) =>{ 
    let ref = useRef()  
    const {params,getNotif,setModalSpinner,showLogs,boardActive} = useContext(DashboardContext)  
    const [notif, setNotif] = useState([]) 

    useEffect(()=>{
        const getData = async() =>{
            setModalSpinner(true) 
            let result = await getNotif(params.id, boardActive.id)
            let debouncer = setTimeout(() => {
                setModalSpinner(false)  
                if(result.status === 200) setNotif(result.data)
                else setNotif([])
              }, 500);
              return () => {
                clearTimeout(debouncer);
            } 
        } 
        if(showLogs.isShow){  
            setNotif([])
            getData()
        } 
        
    },[showLogs])

    
    return (
        <Modal
        {...props}
        size="lg"
        id="modal-activities-mobile"
        className="modal right fade mdl-activity-mobile"
        aria-labelledby="contained-modal-title-vcenter"> 
            <Modal.Header  ref={r => (ref = r)}>
                <span className="modal-title-notif">{boardActive.title}</span>
            </Modal.Header>
            <Modal.Body className="mt-1 ml-2 mr-2 overflow-scroll">  
            <Tabs defaultActiveKey="update" transition={false} id="tab-comment" id={notif.length}>
                <Tab eventKey="update" title="Activity"> 
                
                    <ul className="p-0">
                        {notif.map(item => {
                            return (
                                <div key={item.id}  className="devider parent-notif mt-2">
                                    <li className="notif-container">
                                        <a href="#" className="notif-href">
                                            <div className="notif-creator center-img"> 
                                                <Image src={AvatarIcon}/>
                                            </div>
                                            <div className="notif-content">
                                                <div> 
                                                    <span className="font-14 color-grey">{moment(item.created_at).fromNow()}</span>
                                                </div>
                                                <div className="mt-2">
                                                    <span className="font-14 font-w-400" dangerouslySetInnerHTML= {{__html:item.message}}></span> 
                                                </div>
                                                <div className="mt-2">
                                                    <Image src={FolderActiveIcon} className="mr-2"/>
                                                    <div className="d-inline mt-2">
                                                        <span className="font-14 text-primary">{item.board? item.board.title: ""}</span> 
                                                    </div> 
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </div>
                            )
                        })}
                        
                    </ul>  
                     
                </Tab> 
            </Tabs>
            </Modal.Body>
                  
        </Modal>
    )
}

export default ModalNotif