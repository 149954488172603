const axios = require('axios').default 
 
export const MakeRequest = async (method, url, data) => {  
  // console.log("window.location.hostname", window.location.hostname)
  let pathUrl = window.location.origin 
  if(window.location.hostname === 'localhost')
  // let pathUrl = "http://192.168.124.185:3333" 
  pathUrl = "http://localhost:3333"
  pathUrl = `${pathUrl}/api/v1/`
  const path = `${pathUrl}${url}` 
  let result = await axios({
    method : method,
    url: path,
    data: data, 
  }).catch(error => {
    return error.response
  }) 

  return result? result.data: {data:[], status: 500}
    
}
 

export const MakeRequestDownload = async (method, url, data) => {  
  let pathUrl = window.location.origin 
  if(window.location.hostname === 'localhost')
  pathUrl = "http://localhost:3333" 
  pathUrl = `${pathUrl}/api/v1/`
  const path = `${pathUrl}${url}` 
  let result = await axios({
    method : method,
    url: path,
    data: data,
    responseType: 'arraybuffer' 
  }).catch(error => {
    return error.response
  }) 

  return result? result.data: {data:[], status: 500}
    
}
 