export const StyleSelect = (params = {}) =>{
    return  {
        option: (provided, state) => ({
        ...provided,
        color: 'black',
        fontSize:  params.fontSize? params.fontSize: '14px', 
        backgroundColor: state.isSelected?'#F5F7FF ':'white',
        '&:hover': {
            background: '#F5F7FF'
            },  
        }),
        menu: (provided, state) => ({
            ...provided, 
            color: 'black', 
            background: "white", 
            fontSize:  params.fontSize? params.fontSize: '13px',
            boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.12)',
            borderRadius: '0px 0px 8px 8px',               
        }),
        menuList:  (provided, state) => ({
            ...provided,  
            boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.12)',
            borderRadius: '0px 0px 8px 8px',               
        }),
        // menuPortal: (provided, state) => ({
        //     ...provided, 
        //     color: 'white', 
        //     background: "white", 
        //     fontSize:  params.fontSize? params.fontSize: '14px'         
            
        // }),
        control: base => ({
            ...base, 
            minHeight: params.height,
            height: params.height, 
            width: params.width,
            border: '1px solid #C2C9D1',
            boxSizing: 'border-box',
            borderRadius: '4px' 
        }),
        placeholder: (provided, state) => ({
            ...provided, 
            fontSize: params.fontSize? params.fontSize:'14px'
        }),
        input: (provided, state) => ({
            ...provided,
            color : 'black',
            fontSize:  params.fontSize? params.fontSize: '14px'
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color : 'black',
            fontSize:  params.fontSize? params.fontSize: '14px'
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color : 'black',
            fontSize:  params.fontSize? params.fontSize: '14px'
        }),
        multiValue: (provided, state) => ({
            ...provided,
            color : '#4FBCB6' , 
            backgroundColor: '#F5F7FF' ,
            fontSize:  params.fontSize? params.fontSize: '14px'
        }),
        multiValueLabel: (provided, state) => ({
            ...provided,
            color : 'black',
            fontSize:  params.fontSize? params.fontSize: '14px'
        })
    }

}

export const stylesSelectMulti = (params = {}) => {
    return {
        multiValue: (base, state) => {
        return state.data.is_fixed ? { ...base, backgroundColor: '#F5F7FF', fontSize: '14px' } : { ...base, backgroundColor: '#F5F7FF', fontSize: '14px' };
        },
        singleValue: (provided, state) => ({
            ...provided,
            color : 'white',
            backgroundColor: '#F5F7FF',
            fontSize:  '14px'
        }),
        multiValueLabel: (base, state) => {
        return state.data.is_fixed
            ? { ...base, color: '#4FBCB6', fontSize: '14px', paddingRight: 6 }
            : { ...base, color: '#4FBCB6', fontSize: '14px', paddingRight: 6 };
        },
        multiValueRemove: (base, state) => {
        return state.data.is_fixed ? { ...base, display: 'none' } : base;
        },
        menu:  (provided, state) => ({
            ...provided,  
            fontSize: '14px',
            color: 'black',
            background: 'white',
            boxShadow: 'none'
                
        }),
        menuList:  (provided, state) => ({
            ...provided,  
            fontSize: '14px',
            background: 'white',
            border: 'none',
            boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.12)',
            borderRadius: '0px 0px 8px 8px',             
        }),
        menuPortal: (provided, state) => ({
            ...provided, 
            color: 'white', 
            background: "white", 
            fontSize:  '14px',
            
        }),
        option: (provided, state) => ({ 
            ...provided,
            color: 'black',
            fontSize:  '14px',
            backgroundColor: 'white',
            '&:hover': {
                background: '#F5F7FF'
                },  
        }),
        input: (provided, state) => ({
            ...provided,
            color : 'black',
            fontSize:  '14px'
        }),
        control: base => ({
            ...base,   
            background: 'white',
            border: '1px solid #C2C9D1',
            boxSizing: 'border-box',
            borderRadius: '4px'
        }),
        placeholder: (provided, state) => ({
            ...provided, 
            fontSize: '14px'
        }),
    }
}