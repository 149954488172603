import React, {useEffect, useState, useContext, useRef, memo} from 'react'
import {Modal, Nav, Row, Col, Form, Alert, Button, Image} from 'react-bootstrap'  

//=========3RD LIBRARY========//
import _ from 'lodash' 
import Select from 'react-select'  
import {asyncRequest } from '../../helpers/utils/Utils' 
import {DashboardContext} from "../../context/DashboardContext" 
import {stylesSelectMulti} from "../../helpers/select/Select"
import AvatarIcon from "../../img/avatar-task.svg"
import RemoveIcon from "../../img/remove.svg" 

const ModalMember = (props) =>{  
    const {params, member,selectedWorkspace, selectedTeams, deleteWorkspaceTeams, 
        getWorkspaceTeams,
        setModalSpinner,workspaceTeams,getMember,createWorkspaceTeams,sendNotif} = useContext(DashboardContext)  
    const [memberList, setMemberList] = useState([])         
    const [loading, setLoading] = useState(false)      
    const [selectedMember, setSelectedMember] = useState([])     
   
    // useEffect(()=>{
    //     if(selectedTeams)
    //     getDataMember()

    // },[selectedTeams])

    useEffect(()=>{ 
         
        let arrTmp = []
       
        member.map(item =>{  
            if(item.permission_id === 3 && item.is_active){
                let isExist = _.some(workspaceTeams,(data)=> {return data.user.id === item.user_id})
                let tmp = {value: item.user_id, label: item.username, permission_id : item.permission_id}
                if(!isExist)arrTmp.push(tmp)
            }  
        }) 
        setMemberList(arrTmp)  

    },[member])

    useEffect(()=>{ 
        setSelectedMember([])
        let arrTmp = []
        workspaceTeams.map(item =>{ 
            if(item.permission.id === 3){
                let isFixed = false
                if(item.user_id === params.id || params.permission_id > 2) isFixed = true 
                let tmp = {id: item.id, value: item.user.id, label: item.user.username, is_fixed: isFixed}
                arrTmp.push(tmp)
            }
        })
        // setSelectedMember(arrTmp) 
        
    },[workspaceTeams])

    const getDataMember = async() =>{
        await getWorkspaceTeams(selectedWorkspace.value)
    }
 

    const handleChangeMember = async(selected) =>{  
        let currentSelected = _.cloneDeep(selectedMember, true)
        currentSelected.push(selected)  
        setSelectedMember(currentSelected)

        let newList = _.filter(memberList,(list)=> {return list.value !== selected.value})
        setMemberList(newList)    
    }
 
   

    const save = async() => {
        setLoading(true)
        setModalSpinner(true)  
        let selected = _.filter(selectedMember, (item) => {return !item.is_fixed})
        let data = [] 
        selected.map(item => {
            let param = {}
            param.created_by = params.id
            param.user_id = item.value
            param.workspace_id = selectedWorkspace.value
            param.is_active = true
            param.permission_id = item.permission_id
            data.push(param)

            let isExist = _.some(workspaceTeams, function(i) {
                return i.user_id == item.value
            })
            if(isExist) return  
            let dataNotif = {
                receiver_id : item.value,
                sender_id: params.id, 
                workspace_id : selectedWorkspace.value ,
                team_id: selectedWorkspace.team_id,
                message : `${params.username} added you to the Workspace  "${selectedWorkspace.label}" as Project Admin.`,
                is_read: false, 
                item_id: selectedWorkspace.value,
                created_at: new Date(),
                updated_at: new Date(),
                type: 3
            }  
            sendNotif(dataNotif)

        })  
        await createWorkspaceTeams({data: data})
        await getDataMember()
        asyncRequest(1000).then(()=>{
            props.onHide(true)
            setLoading(false)
            setModalSpinner(false)  
        })
      
    } 

    const handleDelete = async (item) =>{  
        // setModalSpinner(true)  
        // await deleteWorkspaceTeams(item) 
        // await getDataMember()
        // asyncRequest(200).then(()=>{
        //     setModalSpinner(false)
        // })
      
        let currentList = _.cloneDeep(memberList, true)
        currentList.push(item)  
        setMemberList(currentList)

        let newSelected = _.filter(selectedMember,(selected)=> {return selected.value !== item.value})
        setSelectedMember(newSelected)  

    }
    
    return (
        <Modal
        {...props}
        size=""
        id="modal-add-member"
        centered
        aria-labelledby="contained-modal-title-vcenter"> 
            <Modal.Header>
               <span className="modal-title">
                    Invite new project admin
               </span>  
            </Modal.Header>
            <Modal.Body className="mt-1 ml-2 mr-2"> 
 
                <Col className="mr-0 pr-0 mb-3">
                    <Select styles={stylesSelectMulti}
                    placeholder ="Select People" maxMenuHeight={150} options={memberList}
                     onChange={(selected)=>{handleChangeMember(selected)}}
                    value={[]}
                    isClearable={false} 
                    />
                </Col>

                <Col>
                <div className="pb-3">
                {selectedMember.map((item, key) => { 
                    return(
                        <Nav.Link className={item.type === "Pending Invitation" ?"nav-menu-member-pending p-2 w-100 mt-2" : "nav-menu-member p-2 w-100 mt-2" } key={key}> 
                            <Row>
                                <Col md="12">
                                    <Image src={AvatarIcon} width={32}></Image>
                                    <span className="workspace-item-name-child align-text-top ml-2 font-14">{item.label}</span>
                                    <div hidden={item.type === "Pending Invitation" ? false : true}>
                                        <span className="ml-4 font-12 pl-3 color-grey">{item.type}</span>
                                    </div>  
                                    <Image hidden={item.is_fixed ? true : false} src={RemoveIcon} className="float-right align-middle mt-2 cursor-pointer" onClick={()=>handleDelete(item)}></Image>
                                </Col>  
                            </Row>
                        
                        </Nav.Link> 
                    )
                })}
            </div>
            </Col>
             
            </Modal.Body>
            <Modal.Footer>
                    <Button className="btn-cancel" onClick={props.onHide}>Cancel</Button> 
                    <Button 
                        disabled={loading}
                        className="btn-yes text-white"
                        type="submit"
                        onClick={()=>save()}
                        >
                        {loading ? 'Loading…' : 'Save'}
                    </Button> 
                </Modal.Footer>
                  
        </Modal>
    )
}

export default ModalMember