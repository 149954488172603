import React from 'react'
import {Route, Switch, BrowserRouter as Router} from "react-router-dom"
import App from "../pages/Index"  
import Login from "../pages/auth/Login"
import LoginInvitation from "../pages/auth/LoginInvitation"
import LoginTeamInvitation from "../pages/auth/LoginTeamInvitation"
import RegisterInvitation from "../pages/auth/RegisterInvitation"
import RegisterTeamInvitation from "../pages/auth/RegisterTeamInvitation"
import Register from "../pages/auth/Register"
import HomePage from '../pages/home/Home'
import PricingPage from '../pages/home/Pricing' 
import SupportPage from '../pages/home/Support'
import ForgotPassword from '../pages/auth/ForgotPassword' 
import ResetPassword from '../pages/auth/ResetPassword' 
import Verification from '../pages/auth/Verification' 
import PageSudahBayar from '../pages/home/PageSudahBayar' 
import BoardAndroid from '../pages/board/BoardAndroid'

export const MainRoutes = () =>{
    return ( 
        <Router>
            <Switch>  
                <Route path="/app/board-android" exact >
                    <BoardAndroid />
                </Route>
                <Route path="/register" exact>
                    <Register/>
                </Route> 
                <Route path="/login" exact>
                    <Login/>
                </Route> 
                <Route path="/forgotpassword" exact>
                    <ForgotPassword/>
                </Route> 
                <Route path="/forgotpassword/:token" exact>
                    <ResetPassword/>
                </Route> 
                <Route path="/login/:token" exact>
                    <LoginInvitation/>
                </Route> 
                <Route path="/loginteam/:token" exact>
                    <LoginTeamInvitation/>
                </Route> 
                <Route path="/invitation/:token" exact>
                    <RegisterInvitation/>
                </Route> 
                <Route path="/teaminvitation/:token" exact>
                    <RegisterTeamInvitation/>
                </Route> 
                <Route path="/verification/:token" exact>
                    <Verification/>
                </Route> 
                <Route path="/" exact>
                    <HomePage/>
                </Route> 
                
                <Route path="/support" exact>
                    <SupportPage/>
                </Route> 

                <Route path="/pricing" exact>
                    <PricingPage/>
                </Route>

                <Route path="/already-paid" exact>
                    <PageSudahBayar/>
                </Route> 

                <Route path="/support/:id" exact>
                    <SupportPage/>
                </Route> 
                <Route path="/app/team/dashboard" exact >
                    <App />
                </Route>

                <Route path="/app">
                    <App />
                </Route>   
            </Switch>
        </Router>  
    )
}