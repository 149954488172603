import React, { useContext } from "react";
import AppRoute from "../router/App";
import "monday-ui-react-core/dist/main.css";
import { DashboardContext } from "../context/DashboardContext";

//=========COMPONENT========//
import ModalAddWorkspace from "./modal/ModalAddWorkspace";
import ModalAddBoard from "./modal/ModalAddBoard";
import ModalAddTeams from "./modal/ModalAddTeams";
import ModalAddMember from "./modal/ModalAddMember";
import ModalMember from "./modal/ModalMember";
import ModalPriority from "./modal/ModalPriority";
import ModalStatus from "./modal/ModalStatus";
import ModalComment from "./modal/ModalComment";
import ModalNotifications from "./modal/ModalNotifications";
import ModalPermissionBoard from "./modal/ModalPermissionBoard";
import ModalDesc from "./modal/ModalDesc";
import ModalAddDashboard from "./modal/ModalAddDashboard";
import ModalAddDashboardPMU from "./modal/ModalAddDashboardPMU";
import ModalChooseBoard from "./modal/ModalChooseBoard";
import ModalAddWidget from "./modal/ModalAddWidget";
import ModalAddWidgetBattery from "./modal/ModalAddWidgetBattery";
import ModalChooseWidget from "./modal/ModalChooseWidget";
import ModalPermissionDashboard from "./modal/ModalPermissionDashboard";
import ModalLogs from "./modal/ModalLogs";
import ModalSpinner from "../helpers/loading/ModalSpinner";
import ModalDelete from "./modal/ModalDelete";
import ModalDeleteTeams from "./modal/ModalDeleteTeams";
import ModalDeleteWorkspace from "./modal/ModalDeleteWorkspace";
import ModalEditTeamMember from "./modal/ModalEditTeamMember";
import ModalEditWorkspaceMember from "./modal/ModalEditWorkspaceMember";
import ModalInviteMember from "./modal/ModalInviteMember";
import { NotificationContainer } from "react-notifications";
import ModalDetailKanban from "./modal/ModalDetailKanban";
import ModalText from "./modal/ModalText";
import ModalContact from "./modal/ModalContact";
import ModalAddFolder from "./modal/ModalAddFolder";
import ModalPricing from "./modal/ModalPricing";
import ModalPayment from "./modal/ModalPayment";
import ModalArticle from "./modal/ModalArticle";
import ModalMenuArticle from "./modal/ModalAddMenu";
import ModalDeleteTeamMember from "./modal/ModalDeleteTeamMember";
import ModalDeleteWorkspaceMember from "./modal/ModalDeleteWorkspaceMember";
import ModalSeeAllWorkspace from "./modal/ModalSeeAllWorkspace"; // see all workspace
import ModalDetailBoard from "./modal/ModalDetailBoard"; // see all workspace
import "./css/notifications/notifications.css";

//=========BOOTSTARP INITIAL========//
import { Container } from "react-bootstrap";
import ModalCutOff from "./modal/ModalCutoff";

const Index = () => {
  const {
    addNewWorkspace,
    setAddNewWorkspace,
    addNewBoard,
    setNewBoard,
    addNewMember,
    setNewMember,
    showMember,
    setShowMember,
    showCuttoff,
    setShowCuttoff,
    showPriority,
    setShowPriority,
    showStatus,
    setShowStatus,
    showComment,
    setShowComment,
    showPermissionBoard,
    setShowPermissionBoard,
    editDesc,
    setEditDesc,
    addNewDashboard,
    setNewDashboard,
    addNewDashboardPMU,
    setNewDashboardPMU,
    showChooseBoard,
    setShowChooseBoard,
    showWidget,
    setShowWidget,
    showChooseWidget,
    setShowChooseWidget,
    setModeDashboard,
    permissionDashboard,
    setPermissionDashboard,
    modalSpinner,
    setModalSpinner,
    showNotif,
    setShowNotif,
    showLogs,
    setShowLogs,
    showDelete,
    setShowDelete,
    showMemberTeam,
    setShowMemberTeam,
    showDetailKanban,
    setShowDetailKanban,
    setEditTeamMember,
    editTeamMember,
    setEditWorkspaceMember,
    editWorkspaceMember,
    showModalText,
    setShowModalText,
    showModalContact,
    setShowModalContact,
    showWidgetBattery,
    setShowWidgetBattery,
    addNewFolder,
    setAddNewFolder,
    showDeleteTeams,
    setShowDeleteTeams,
    showDeleteWorkspace,
    setShowDeleteWorkspace,
    addNewTeams,
    setNewTeams,
    modalPricing,
    showModalPricing,
    modalPayment,
    showModalPayment,
    modalArticle,
    showModalArticle,
    modalMenuArticle,
    showModalMenuArticle,
    showDeleteTeamMember,
    setDeleteTeamMember,
    showDeleteWorkspaceMember,
    setDeleteWorkspaceMember,
    modalSeeAllWorkspace,
    setModalSeeAllWorkspace,
    setShowDetailBoard,
    showDetailBoard,
  } = useContext(DashboardContext);

  return (
    <Container fluid className="no-gutters overflow-hidden pr-0">
      <NotificationContainer />
      <ModalSpinner show={modalSpinner} onHide={() => setModalSpinner(false)} />
      <ModalAddWorkspace
        show={addNewWorkspace}
        onHide={() => setAddNewWorkspace(false)}
      />
      <ModalAddBoard show={addNewBoard} onHide={() => setNewBoard(false)} />
      <ModalAddTeams show={addNewTeams} onHide={() => setNewTeams(false)} />
      <ModalAddFolder
        show={addNewFolder}
        onHide={() => setAddNewFolder(false)}
      />
      <ModalAddMember show={addNewMember} onHide={() => setNewMember(false)} />
      <ModalMember show={showMember} onHide={() => setShowMember(false)} />
      <ModalCutOff show={showCuttoff} onHide={() => setShowCuttoff(false)} />
      <ModalPriority
        show={showPriority.isShow}
        onHide={() => setShowPriority({ isShow: false })}
      />
      <ModalStatus
        show={showStatus.isShow}
        onHide={() => setShowStatus({ isShow: false })}
      />
      <ModalComment show={showComment} onHide={() => setShowComment(false)} />
      <ModalPermissionBoard
        show={showPermissionBoard}
        onHide={() => setShowPermissionBoard(false)}
      />
      <ModalDesc show={editDesc} onHide={() => setEditDesc(false)} />
      <ModalAddDashboard
        show={addNewDashboard}
        onHide={() => setNewDashboard(false)}
      />
      <ModalAddDashboardPMU
        show={addNewDashboardPMU}
        onHide={() => setNewDashboardPMU(false)}
      />
      <ModalChooseBoard
        show={showChooseBoard}
        onHide={() => setShowChooseBoard(false)}
      />
      <ModalAddWidget
        show={showWidget}
        onHide={() => {
          setShowWidget(false);
          setModeDashboard("edit");
        }}
      />
      <ModalChooseWidget
        show={showChooseWidget}
        onHide={() => setShowChooseWidget(false)}
      />
      <ModalPermissionDashboard
        show={permissionDashboard}
        onHide={() => setPermissionDashboard(false)}
      />
      <ModalSpinner show={modalSpinner} onHide={() => setModalSpinner(false)} />
      <ModalNotifications show={showNotif} onHide={() => setShowNotif(false)} />
      <ModalLogs
        show={showLogs.isShow}
        onHide={() => setShowLogs({ isShow: false })}
      />
      <ModalDelete
        show={showDelete.isShow}
        onHide={() => setShowDelete({ isShow: false })}
      />
      <ModalDeleteTeams
        show={showDeleteTeams.isShow}
        onHide={() => setShowDeleteTeams({ isShow: false })}
      />
      <ModalDeleteWorkspace
        show={showDeleteWorkspace.isShow}
        onHide={() => setShowDeleteWorkspace({ isShow: false })}
      />
      <ModalInviteMember
        show={showMemberTeam}
        onHide={() => setShowMemberTeam(false)}
      />
      <ModalEditTeamMember
        show={editTeamMember.isShow}
        onHide={() => setEditTeamMember({ isShow: false })}
      />
      <ModalEditWorkspaceMember
        show={editWorkspaceMember.isShow}
        onHide={() => setEditWorkspaceMember({ isShow: false })}
      />
      <ModalDetailKanban
        show={showDetailKanban.isShow}
        onHide={() => setShowDetailKanban({ isShow: false, data: [] })}
      />
      <ModalText
        show={showModalText.isShow}
        onHide={() => setShowModalText({ isShow: false, data: [] })}
      />
      <ModalContact
        show={showModalContact}
        onHide={() => setShowModalContact(false)}
      />
      <ModalAddWidgetBattery
        show={showWidgetBattery}
        onHide={() => setShowWidgetBattery(false)}
      />
      <ModalPricing
        show={modalPricing}
        onHide={() => showModalPricing(false)}
      />
      <ModalPayment
        show={modalPayment.isShow}
        onHide={() => showModalPayment({ isShow: false })}
      />
      <ModalArticle
        show={modalArticle.isShow}
        onHide={() => showModalArticle({ isShow: false })}
      />
      <ModalMenuArticle
        show={modalMenuArticle.isShow}
        onHide={() => showModalMenuArticle({ isShow: false })}
      />
      <ModalDeleteTeamMember
        show={showDeleteTeamMember.isShow}
        onHide={() => setDeleteTeamMember({ isShow: false })}
      />
      <ModalDeleteWorkspaceMember
        show={showDeleteWorkspaceMember.isShow}
        onHide={() => setDeleteWorkspaceMember({ isShow: false })}
      />
      <ModalSeeAllWorkspace
        show={modalSeeAllWorkspace}
        onHide={() => setModalSeeAllWorkspace(false)}
      />
      <ModalDetailBoard
        show={showDetailBoard}
        onHide={() => setShowDetailBoard(false)}
      />
      <AppRoute />
    </Container>
  );
};

export default Index;
