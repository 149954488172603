import React from 'react'
import {Modal,Image} from 'react-bootstrap'  
import LoadingIcon from '../../img/loading.gif'

const ModalAddBoard = (props) =>{ 
    return (
        <Modal
        {...props}
        size="sm"
        dialogClassName="modal-spinner"
        aria-labelledby="contained-modal-title-vcenter" 
        centered> 
            <Image src={LoadingIcon} width={100} className="pl-2 pr-4"></Image> 
        </Modal>
    )
}

export default ModalAddBoard