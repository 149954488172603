import React, {useEffect, useState, useContext, useRef} from 'react'
import {Modal, Row, Col, Image } from 'react-bootstrap'  
import {DashboardContext} from "../../context/DashboardContext"  
import _ from 'lodash'
import permissionsEveryone from "../../img/permission1.svg"
import permissionsColaborator from "../../img/permission1.svg"
import permissionsAssigned from "../../img/permission1.svg"
import permissionOwner from "../../img/permission1.svg"
import CheckBlue from "../../img/check-blue.svg"
import CheckGrey from "../../img/check-grey.svg"

const ModalPermissionBoard = (props) =>{ 
    const ref = useRef() 
    const {params, updateBoard, boardActive} = useContext(DashboardContext)  
    const [selectedId, setSelectedId] = useState()
    const [permission, setPermission] = useState([{
        id :1 ,
        permission : 'Edit everything',
        selected : false,
        detail: 'Add/Move groups, columns and rows. \n Edit content in all rows. \n Write updates',
        img : permissionsEveryone
    },{
        id :2 ,
        permission : 'Edit content',
        selected : false,
        detail : 'Add/Move rows. \n Edit content in all rows. \n Write updates.',
        img: permissionsColaborator
    },{
        id :3 ,
        permission : 'Edit rows assigned to them In the Person Column',
        selected : false,
        detail : 'Team members can only edit rows assigned to them in a selected person column.',
        img: permissionsAssigned
    },{
        id :4 ,
        permission : 'View Only',
        selected : false,
        detail : 'View board content. \n Write updates.',
        img: permissionOwner
    }])

    useEffect(()=>{ 
        setSelectedId(boardActive.permission_id)
    },[boardActive]) 

    const handlePermissionClick = async (itemParam) => {
        let newPermission = _.map(permission,((item, key)=>{ 
            item.selected = false
            if(item.id === itemParam.id) item.selected = true
            return item
        })) 
        let param = {}
        param.id = boardActive.id
        param.title = boardActive.title
        param.description = boardActive.desc 
        param.permission_id = itemParam.id 
        param.is_favorites = boardActive.is_favorites 
        param.updated_by = params.id
        setPermission(newPermission)
        setSelectedId(itemParam.id)
        await updateBoard(param) 

    }



    return (
        <Modal
        {...props}
        size="lg" 
        centered
        id="modal-add-member"
        aria-labelledby="contained-modal-title-vcenter"
        > 
            <Modal.Header> 
                <span className="modal-title">
                    Board Permissions
                </span>
            </Modal.Header>
            <Modal.Body className="m-2"> 
                <div><span className="font-14 font-w-400">Choose what everyone can do on this board:</span></div>
                <Row>

                <Col md="12 mt-1"> 
                    {permission.map(item => {
                        let className = "permission-container mt-3 cursor-pointer"
                        if(selectedId === item.id) item.selected = true
                        if(item.selected) className = "permission-container-active mt-3 cursor-pointer"
                        return ( 
                        <div className= {className} key={item.id} onClick={()=> handlePermissionClick(item)}>
                            <div className="div-permission">
                                <Row className="w-100">
                                    <Col md="10">
                                        <Row>
                                            <Col md="4">
                                                <Image src={permissionsEveryone}></Image>
                                            </Col>
                                            <Col className="pt-3">
                                                <div className="d-inline mt-3">
                                                    <div>
                                                        <span className="font-14 font-w-500">{item.permission}</span>
                                                    </div>
                                                    <div>
                                                        <span className="font-14 font-w-400 color-grey">{item.detail}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row> 
                                    </Col>
                                    <Col md="2 h-100 pt-4">
                                        {item.selected ?
                                            <Image src={CheckBlue} className="align-middle float-right mt-3"></Image>
                                        :
                                            <Image src={CheckGrey} className="align-middle float-right mt-3"></Image>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        )
                    })}
                </Col> 
                </Row>
                     
            </Modal.Body>
                 
        </Modal>
    )
}

export default ModalPermissionBoard