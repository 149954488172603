//=========REACT INITIAL========//
import React, {useEffect, useContext, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'
  
//=========BOOTSTARP INITIAL========//
import {Row,Col,Table,Image} from 'react-bootstrap'  

//=========CONTEXT========//
import {DashboardContext} from "../../context/DashboardContext"  
import { MakeRequest } from "../../api/Api"  

//=========3RD LIBRARY========//
import _ from 'lodash'  
// import '../css/setting.css'  
import { useForm } from "react-hook-form"
import {NotificationManager} from 'react-notifications' 
import AddIcon from "../../img/add-pa.svg"    
import Show from "monday-ui-react-core/dist/icons/Show";
import EditIcon from "../../img/edit.svg"    
import DeleteIcon from "../../img/delete.svg" 

const Article = () => {  
    const {params, showModalArticle, setModalSpinner,getArticle, 
        isNewArticle, deleteArticle, postMenuArticle, showModalMenuArticle,
        deleteMenuArticle} = useContext(DashboardContext)  
    const [isLoading, setLoading, modalArticle] = useState(false) 
    const [data, setData] = useState([]) 
    const [selectedMenu, setSelectedMenu] = useState(false) 
    const [dataMenu, setDataMenu] = useState([]) 
    const [menu, setMenu] = useState([]) 
    const [subMenu, setSubMenu] = useState([]) 

    useEffect(()=>{ 
        getData()
        getMenu()
    },[modalArticle])

    useEffect(()=>{ 
        if(isNewArticle){
            getData()
            getMenu()
        }
     
    },[isNewArticle])

    const getMenu = async ()=>{
        let data = await postMenuArticle()
        if(data.status === 200){
            let menu = []
            setDataMenu(data.data)
            data.data.map(item => {
                item.is_active = false
                if(item.parent === null) menu.push(item)
                if(item.id === selectedMenu.id){
                    item.is_active = true
                    let dataSubmenu = _.filter(data.data, (item)=> {return item.parent === selectedMenu.id})
                    setSubMenu(dataSubmenu)
                }
            })
            setMenu(menu) 
        }
    }

    const getData = async () => {
        let data = await getArticle()
        if(data.status === 200){
            setData(data.data)
        }
    }

    const handleDelete = async (param) => { 
        setModalSpinner(true)
        const result = await deleteArticle({id: param.id})
        let debouncer = setTimeout(async() => {
            setModalSpinner(false)
            getData() 
            }, 500);
            return () => {
            clearTimeout(debouncer);
        } 
   
    }

    const handleDeleteMenu = async (param) => { 
        if(!param.parent) setSelectedMenu(false)
        setModalSpinner(true)
        const result = await deleteMenuArticle({id: param.id})
        let debouncer = setTimeout(async() => {
            setModalSpinner(false)
            getMenu() 
            }, 500);
            return () => {
            clearTimeout(debouncer);
        } 
   
    }
    

    const handleClickTr = (param) => { 
        let menuTmp = _.cloneDeep(menu)
        menuTmp.map(item => {
            item.is_active = false
            if(item.id === param.id){
                item.is_active = true
                setSelectedMenu(item)
            }
        })
        setMenu(menuTmp)

        let dataSubmenu = _.filter(dataMenu, (item)=> {return item.parent === param.id})
        setSubMenu(dataSubmenu)

    }


    return (
        <React.Fragment>   
            <Row className="pt-4 vh-100 bg-white">
                <Col xs="4" className="w-100">  
                   <Col>
                    <div className="p-2 profile-container bg-white"> 
                    <span className="invite-workspace-pa mr-0" 
                        onClick={()=>showModalMenuArticle({isShow: true})}>
                        <Image src={AddIcon}></Image>
                        <span className="invite-workspace-pa-span ">Add</span>
                    </span>
                    <Table bordered hover responsive className="mt-2">
                    <thead>
                        <tr>  
                        <th className="font-14 font-w-500">Menu</th> 
                        <th className="font-14 font-w-500" >Action</th> 
                        </tr>
                    </thead>
                    <tbody>
                        {menu.map((item, key)=>{ 
                        return (
                            <tr key={key} className={item.is_active ? "bg-alice" : ""}> 
                                <td className="font-14" onClick={()=> handleClickTr(item)}>{item.name}</td> 
                                <td className="font-14 text-center cursor-pointer">
                                    <Image src={EditIcon}  onClick={()=>showModalMenuArticle({isShow: true, data: item})}></Image>
                                    <Image src={DeleteIcon} onClick={()=>handleDeleteMenu(item)}></Image>
                                </td>
                            </tr>
                        )
                            
                        })}
                    </tbody>
                    </Table>
                    </div>
                    </Col>
                    <Col>
                    <div className="p-2 profile-container bg-white"> 
                    <span hidden={selectedMenu.id ? false: true} className="invite-workspace-pa mr-0" 
                        onClick={()=>showModalMenuArticle({isShow: true, subMenu: selectedMenu})}>
                        <Image src={AddIcon}></Image>
                        <span className="invite-workspace-pa-span ">Add</span>
                        </span>
                    <Table bordered hover responsive className="mt-2">
                    <thead>
                        <tr>  
                        <th className="font-14 font-w-500">Sub Menu</th> 
                        <th className="font-14 font-w-500" >Action</th> 
                        </tr>
                    </thead>
                    <tbody>
                        {subMenu.map((item, key)=>{ 
                        return (
                            <tr key={key}> 
                                <td className="font-14">{item.name}</td> 
                                <td className="font-14 text-center cursor-pointer">
                                    <Image src={EditIcon}  onClick={()=>showModalMenuArticle({isShow: true, data: item})}></Image>
                                    <Image src={DeleteIcon} onClick={()=>handleDeleteMenu(item)}></Image>
                                </td>
                            </tr>
                        )
                            
                        })}
                    </tbody>
                    </Table>
                    </div>
                    </Col>
                </Col>
                <Col xs="8" className="w-100 pl-0 ">  
                    <div className="p-2 pr-4 pb-5 profile-container bg-white"> 
                          <span className="invite-workspace-pa mr-0" 
                          onClick={()=>showModalArticle({isShow: true})}>
                            <Image src={AddIcon}></Image>
                            <span className="invite-workspace-pa-span ">Add</span>
                          </span>
                          <Table bordered hover responsive className="mt-2">
                                <thead>
                                    <tr> 
                                    <th className="font-14 font-w-500" >Title</th>
                                    <th className="font-14 font-w-500">Menu</th>
                                    <th className="font-14 font-w-500" >Sub Menu</th>
                                    <th className="font-14 font-w-500" >Content</th> 
                                    <th className="font-14 font-w-500" >Action</th> 
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, key)=>{
                                    
                                    return (
                                        <tr key={key}> 
                                            <td className="font-14">{item.title}</td>
                                            <td className="font-14">{item.menu?item.menu.name : ""}</td>
                                            <td className="font-14">{item.subMenu? item.subMenu.name: ""}</td>
                                            <td className="font-14 text-center cursor-pointer"><Show></Show></td>
                                            <td className="font-14 text-center cursor-pointer">
                                                
                                                <Image src={EditIcon}    onClick={()=>showModalArticle({isShow: true, data: item})}></Image>
                                                <Image src={DeleteIcon} onClick={()=>handleDelete(item)}></Image>
                                            </td>
                                        </tr>
                                    )
                                        
                                    })}

                                </tbody>

                         </Table>
                    </div> 
                </Col>
               
            </Row>
           
        </React.Fragment>
    )
}

export default Article