import React, {useEffect, useState, useContext, useRef} from 'react'
import {Modal, Button, Accordion, Card, Row, Col, Form, Spinner } from 'react-bootstrap' 
import {DashboardContext} from "../../context/DashboardContext" 

//========3D LIBRARY==========//
import ContentEditable from 'react-contenteditable'  
import {StyleSelect, stylesSelectMulti} from "../../helpers/select/Select"
import Select from 'react-select' 
import _ from 'lodash'  
import {NotificationManager} from 'react-notifications' 
 
//========ICON==========//
import ExpandMoreIcon from '@material-ui/icons/ExpandMore' 
import { asyncRequest } from '../../helpers/utils/Utils'

import Battery from '../dashboard/Battery'

const ModalAddWidget = (props) =>{   
    const [title, setTitle] = useState("Status Overview")  
    const [isLoading, setLoading] = useState(false)  
    const [dataBattery, setDataBatteryTmp] = useState([])  
    const [updateDataTime, setUpdateDataTime] = useState(false)  
    const isFirstRun = useRef(true)  
    const sumData = useRef("") 

    const {params, boardActive, selectedBoard, stateChart, 
        dispatchChart, createDashboard, updateDashboard, dataDashboard, setDataBattery} = useContext(DashboardContext)  

    const titleCase = (str) => {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) { 
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        } 
        return splitStr.join(' '); 
     }

    useEffect(()=>{  
        if(isFirstRun.current){
            isFirstRun.current = false
            return
        } 
        if(selectedBoard.length === 0) return   

        //SET BORD 
        let board = [], arrTmp = [], group = [], selectedIdGroup = []
        selectedBoard.map(items =>{  
            let tmp = {value: items.id, label: items.title}
            if(items.type === 1) { 
                if(stateChart.isUpdate){
                    if(stateChart.board.includes(items.id)){
                        board.push(items.id)
                        arrTmp.push(tmp)
                    }
                }else{
                    board.push(items.id) 
                    arrTmp.push(tmp) 
                }  
            } 

            tmp = {}
            tmp.board_id = items.id
            let tmpGroup = []
            items.table.map(item =>{  
                item.rows.map((row, key) =>{
                    if(key === 0) { 
                        //==== GET GROUP NAME BY ID TABLES======// 
                        let objGroup = {}
                        objGroup.id = item.id
                        objGroup.name = row[1].name 
                        objGroup.board_id = items.id
                        tmpGroup.push(objGroup) 
                        selectedIdGroup.push(item.id)
                    }
                }) 
            }) 
            tmp.group = tmpGroup
            group.push(tmp)
        })  

 
        dispatchChart({type: 'SET_SELECTED_BOARD', selectedBoard: selectedBoard,selectedIdBoard: _.map(selectedBoard,"id"), 
        board: board, group : selectedIdGroup, selectedIdGroup : selectedIdGroup, selectedGroup: group }) 

    },[selectedBoard])

    useEffect(()=>{ 
        if(!updateDataTime) return  
        let undefinedData = _.filter(dataDashboard, (item) => {return item.name === "Undefined"})
        let data = _.filter(dataDashboard, (item) => {return item.name !== "Undefined"})
        
        data = data.concat(undefinedData) 
        setDataBatteryTmp(data)
        let sum = dataDashboard.reduce((s, f) => s + f.y, 0); 
        sumData.current = sum   
    },[updateDataTime]) 

    useEffect(()=>{
        if(stateChart.selectedBoard.length === 0) return  
        let dataTmp = [], color = []   
        let stateChartTmp = _.cloneDeep(stateChart, true) 
        if(stateChartTmp.idUpdate) setTitle(stateChartTmp.title)
        stateChartTmp.selectedBoard.map(items => {   
            if(stateChartTmp.board.includes(items.id)){
                items.table.map((item, key) => { 
                    if(stateChartTmp.group.includes(item.id)) {
                        let group = "", keyColumn = ""
                        item.rows.map((row, rowKey) => { 
                            if(rowKey === 0) {
                                group = row[1] 
                                _.map(row, (item, key) =>{
                                    if(stateChartTmp.xAxisColumnId.includes(item.id)) keyColumn = key
                                    if(stateChartTmp.xAxisColumnId.length === 0) {
                                        let type =  item.type.replace("_"," ").toUpperCase()
                                        if(stateChartTmp.xAxis.toUpperCase() === type && keyColumn === "") keyColumn = key 
                                    }
                                }) 
                            }   
                                //===== SKIP HEADER ======//
                            if(rowKey > 0){  
                                row.some((val, valIdx)=> {  
                                    let type =  val.type.replace("_"," ")
                                    type  = titleCase(type).trim() 
                                    if(val.name === null) val.name = "" 
                                    if(stateChart.xAxis === "Board"){ 
                                        if(valIdx === 1){   
                                            let tmp = {}
                                            tmp.name = items.title
                                            color[tmp.name] = 'rgb(87, 155, 252)'
                                            dataTmp.push(tmp)
                                        } 
                                    }

                                    if(stateChartTmp.xAxis === "Group"){ 
                                        if(valIdx === 1){   
                                            color[group.name] = 'rgb(87, 155, 252)'
                                            dataTmp.push(group)
                                        } 
                                    }

                                    if(stateChartTmp.xAxis === "Name"){ 
                                        if(valIdx === 1 && val.type !== "addsubitems" && val.type !== "subitems"){  
                                            let tmp = {}
                                            tmp.name = val.name.trim()
                                            color[tmp.name] = 'rgb(87, 155, 252)'
                                            dataTmp.push(tmp)
                                        } 
                                    }  

                                    if(!stateChart.showUndefined && (val.name === undefined || val.name === "" || val.name.name === "" || val.name.name === "Undefined")) return
                                    if((type === stateChartTmp.xAxis)){ 
                                        if(type === "Person" && (keyColumn !== "" && valIdx === keyColumn)){
                                            if(val.name.length === 0) val.name = ""
                                            if(val.name ===  "") {
                                                dataTmp.push("")
                                                return
                                            }
                                            val.name.map((n,idx) => { 
                                                let tmp = {}
                                                tmp.name = n.label
                                                color[tmp.name] = 'rgb(87, 155, 252)'
                                                dataTmp.push(tmp)
                                            })
                                        }
                                        if(type === "Priority" || type === "Status" && valIdx === keyColumn){  
                                            let tmp = {name : "Undefined", color: "rgb(196, 196, 196)"}
                                            if(val.name.name) val.name.name = val.name.name.trim()
                                            if(val.name === "" || val.name.name === "") val.name = tmp 
                                            color[val.name.name] =  val.name.color
                                            dataTmp.push(val.name)
                                        }

                                        if(type === "Progress" && valIdx === keyColumn){ 
                                            let tmp = {}
                                            tmp.name = val.name + '%'
                                            color[tmp.name] = 'rgb(87, 155, 252)'
                                            dataTmp.push(tmp) 
                                        }

                                        if(type === "Text" && valIdx === keyColumn){  
                                            let tmp = {}
                                            tmp.name = val.name 
                                            color[tmp.name] = 'rgb(87, 155, 252)'
                                            dataTmp.push(tmp)  
                                        }

                                        if(type === "Paragraph" && valIdx === keyColumn){  
                                            let tmp = {}
                                            tmp.name = val.name 
                                            color[tmp.name] = 'rgb(87, 155, 252)'
                                            dataTmp.push(tmp) 
                                             
                                        }

                                        if(type === "Rating" || type === "Duedate" || type === "Timeline Duedate"
                                        || type === "Timeline" || type === "Date" || type === "Number" || type === "Week" && valIdx === keyColumn){ 
                                            let tmp = {}
                                            tmp.name = val.name
                                            color[tmp.name] = 'rgb(87, 155, 252)'
                                            dataTmp.push(tmp) 
                                        }

                                    }
                                })  
                            }  
                            
                        }) 
                    }
                }) 
            }
        })  

        
        var count = _.countBy(dataTmp, 'name')  
        let data = []
        Object.keys(count).map(item=>{
            let obj = {}
            obj.name = item
            obj.y = count[item] 
            obj.color = color[item] 
            data.push(obj) 
        })   
 
        if(stateChart.sortBy.value === 1){
            data.sort(function(a, b) {
                return a.y - b.y
            }) 
        }

        if(stateChart.sortBy.value === 2){
            data.sort(function(a, b) {
                return b.y - a.y
            }) 
        }

        if(stateChart.sortBy.value === 3){
            data.sort(function(a, b) {
                if (a.name < b.name)
                    return -1
                if (a.name > b.name)
                    return 1
                return 0
            }) 
        }

        if(stateChart.sortBy.value === 4){
            data.sort(function(a, b) {
                if (b.name < a.name)
                    return -1
                if (b.name > a.name)
                    return 1
                return 0
            }) 
        }

        setDataBattery(data)
        asyncRequest(100).then(()=>{
            setUpdateDataTime(new Date()) 
        })
     
    },[stateChart])
    
    const handleChangeTitle = (value) => {
        setTitle(value)
    }

    const handleApply = async () =>{
        setLoading(true)
        let info = {id: stateChart.idUpdate, title:title, created_by: params.id, team_id : params.team.id, board_id: boardActive.id, workspace_id: boardActive.workspace_id, permission_id: boardActive.permission_id}
        let mod = boardActive.dashboard.length % 2  
       
        if(stateChart.isUpdate){
            let column = stateChart.column 
            column.chart = ''
            let data = Object.assign(info,{column:column}, {widget: stateChart}, {chart: [] })  
            await updateDashboard(data, true)
        }else{
            let column =  {   
                "x": mod === 0 ? 0 : 4,
                "y": mod === 0 ? 0 : 4,
                "w": 5,
                "h": 2,
                minW: 3,
                minH: 2,
                "i":  new Date().getTime()+"1",
                "static": false, 
                type : 'Battery',
                value : ''
            }   
            let data = Object.assign(info,{column:column}, {widget: stateChart}, {chart: [] })  
            await createDashboard(data)
            
        }
        setLoading(false) 
        props.onHide()
    }

    return (
        <Modal
        {...props}
        size=""
        className="modal full fade" 
        id="modal-add-widget"
        // id={updateDataTime}
        aria-labelledby="contained-modal-title-vcenter"
        centered> 
            <Modal.Header >
                <Row className="w-100 modal-title">
                    <Col md="10">
                        <span className="font-18 font-w-600">Add New Status Overview</span>
                        <p className="font-16 font-w-400 color-grey">Show your total progress from all tasks</p> 
                    </Col>
                    <Col md="2" className="mt-2">
                    
                    <Button 
                        disabled={isLoading}
                        className="btn-yes text-white float-right"
                        type="submit"
                        onClick={handleApply}
                        >
                            {isLoading ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    />
                                : "Done" 
                            }
                    </Button>  
                    <Button className="btn-cancel float-right" onClick={props.onHide}>Cancel</Button>   
                    </Col>
                </Row> 
                    
            </Modal.Header>
            <Modal.Body className="vh-100 pt-0"> 
                
                <Row className="h-100">
                    <Col md="8" className="border-widget vh-75">
                        <div className="text-center mt-2">
                            <ContentEditable 
                                html={title}  
                                onChange={(e)=> {handleChangeTitle(e.target.value.replace(/<\/?[^>]+(>|$)/g, "")+ " ")}} 
                                className="board-title p-1 font-18"
                            />  
                            <Battery data = {dataBattery} height={200} className="d-inline-block p-3"/>
                        </div> 
                    </Col>
                    <Col md="4" className="p-0">
                        <div>
                            <OptionWidget/>
                        </div>
                    </Col>
                </Row>
            </Modal.Body> 
        </Modal>
    )
}

const OptionWidget =() =>{
    return ( 
        <Accordion> 
            <Card className="border-widget-option">
                <Accordion.Toggle as={Card.Header} eventKey="1" className="bg-white font-14 p-3">
                <ExpandMoreIcon style={{ color: 'black'}} className="align-text-top float-right cursor-pointer"/>
                    Column Type
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                <Card.Body style={{height: '480px', overflow:'auto'}}><OptionXAxis/></Card.Body>
                </Accordion.Collapse>
            </Card> 
            <Card className="border-widget-option">
                <Accordion.Toggle as={Card.Header} eventKey="4" className="bg-white font-14 p-3">
                <ExpandMoreIcon style={{ color: 'black'}} className="align-text-top float-right cursor-pointer"/>
                More settings
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="4">
                <Card.Body style={{height: '250px', overflow:'auto'}}><MoreSetting/></Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card className="border-widget-option">
                <Accordion.Toggle as={Card.Header} eventKey="5" className="bg-white font-14 p-3">
                <ExpandMoreIcon style={{ color: 'black'}} className="align-text-top float-right cursor-pointer"/>
                Boards
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="5">
                <Card.Body style={{height: '480px', overflow:'auto'}} ><Boards/></Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card className="border-widget-option">
                <Accordion.Toggle as={Card.Header} eventKey="6" className="bg-white font-14 p-3">
                <ExpandMoreIcon style={{ color: 'black'}} className="align-text-top float-right cursor-pointer"/>
                Choose groups
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="6">
                <Card.Body style={{height: '480px', overflow:'auto'}}><GroupList/></Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>  
    )
}
 
const OptionXAxis =() =>{
    const {stateChart, dispatchChart} = useContext(DashboardContext)  
    const [typeList, setTypeList] = useState([]) 
    const [selectedType, setSelectedType] = useState([]) 
    const [selectElement, setSelecteEment] = useState([]) 

    const titleCase = (str) => {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) { 
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        } 
        return splitStr.join(' '); 
     }

    useEffect(()=>{  
        let typeList = []
        let selectElement = [] , group = []
        stateChart.selectedBoard.map(items => { 
            //===== INSERT TO SELECT ELEMENT ======//
            selectElement.push(<OptionXAxisSelect item={items} />)
            items.table.map(item => {
                //===== GET HEADER EACH TABLE ======//
                item.rows[0].map((row, key) =>{
                    if(key === 1) group.push(row.name.trim())
                    if(key > 1){
                        let type =  row.type.replace("_"," ")
                        type  = titleCase(type)  
                        if(row.type.trim() !== "status" && row.type.trim() !== "priority") return
                        let isExist = _.some(typeList, (data) => {return data === type})
                        if(!isExist)
                        typeList.push(type.trim())  
                    }
                      
                })
            }) 
        })  

        setSelecteEment(selectElement)  
        let arrTmp = []
        typeList.map(item =>{  
            let tmp = {value: item, label: item} 
            arrTmp.push(tmp)
             //===== SET DEFAULT SELETED TYPE ======//
            
            if(item === stateChart.xAxis) setSelectedType(tmp)   
        }) 
        setTypeList(arrTmp) 
    },[])
    
    const handleSelectedType = async(selected) =>{  
        setSelectedType(selected)  
        let ids = []  
        stateChart.selectedBoard.map(items => { 
            items.table.map((item, index) => { 
                let groupName = "" 
                //===== GET FISRT COLUMN EACH TABLE ======// 
                item.rows[0].map((row, key) =>{  
                    let type =  row.type.replace("_"," ")
                    type  = titleCase(type).trim()
                    let id =  row.id  
                    let name =  row.name  
                   
                    if(key === 1) {
                        groupName = name  
                    } 
                    if(type === selected.value && key !== 1) { 
                        let tmp = {board_id : item.board_id, id, groupName, name}
                        let isReady = ids.some(el => el.groupName === groupName)
                        if(!isReady)
                        ids.push(tmp)
                    }     
                   
                })
            })  
        })  
        dispatchChart({type : 'SET_XAXIS_CHART', value : selected.value, column : ids, id: _.map(ids,"id")})
    }

    return (
        <div>
            Column Type
            <div className="mt-2">
            <Select styles={StyleSelect({menuListHeight: 100})} 
                options ={typeList}
                value ={selectedType}
                onChange={(selected)=>{handleSelectedType(selected)}} 
                maxMenuHeight={110}  
               />
            </div>

            <div className="mt-3">
            Choose X Axis by a specific column
                <div className="mt-2">
                {selectElement.map((element,index)=>{
                    return (
                        element === undefined ? "" :
                        <React.Fragment key={index}>
                            {React.cloneElement(element, {elementIndex : index, selectedType: selectedType})} 
                        </React.Fragment>
                    )
                })}     
                </div>
            </div>
        </div>
    )
}

const OptionXAxisSelect = (props) =>{ 
    const [list, setList] = useState([]) 
    const [selected, setSelected] = useState([]) 
    const {stateChart, dispatchChart} = useContext(DashboardContext)  

    const titleCase = (str) => {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) { 
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        } 
        return splitStr.join(' '); 
     }

    useEffect(()=>{  
        setSelected([])   
        let typeList = []   
        props.item.table.map(item => { 
            //===== GET HEADER EACH TABLE ======//
            item.rows[0].map((row, key) =>{
                let type =  row.type.replace("_"," ")
                type  = titleCase(type).trim()
                let name =  row.name  
                let id =  row.id  
                if(key === 1) {
                    name = "Name"
                    type = "Name"
                }
                let isReady = typeList.some(el => el.name === name)
                let tmp = {name: name, type: type, id, board_id : item.board_id}
                if((!isReady) && type === props.selectedType.value && row.type !== 'option' && row.type !== 'add' && row.type !== 'file' && row.type !== 'subitemscontroll' ){
                    typeList.push(tmp) 
                }
                      
            })
        }) 

        let arrTmp = []
        let selected = {}
        typeList.map(item =>{  
            let tmp = {value: item.id, label: item.name, board_id : item.board_id} 
            arrTmp.push(tmp)
             //===== SET DEFAULT SELETED TYPE ======//
            
            if(stateChart.xAxisColumnId.includes(item.id)){
                selected = tmp
            }  
        })     
        if(!selected.value) selected = arrTmp[0]
        setSelected(selected)  
        setList(arrTmp)  
          

    },[props])
 
    
    const handleSelected = async(selected) =>{  
        setSelected(selected)   
        let tmpColumn = _.filter(stateChart.xAxisColumn,(item) => {return item.board_id !== selected.board_id})
        // console.log(tmpColumn)
        stateChart.selectedBoard.map(items => { 
            items.table.map((item, index) => { 
                let groupName = "" 
                //===== GET FISRT COLUMN EACH TABLE ======// 
                item.rows[0].map((row, key) =>{  
                    let type =  row.type.replace("_"," ")
                    type  = titleCase(type).trim()
                    let id =  row.id  
                    let name =  row.name  
                   
                    if(key === 1) {
                        groupName = name  
                    } 
                    if(id === selected.value && key !== 1) { 
                        let tmp = {board_id : item.board_id, id, groupName, name}
                        tmpColumn.push(tmp)
                    }     
                   
                })
            })  
        })   
        dispatchChart({type : 'SET_XAXIS_COLUMN_CHART', column : tmpColumn, id: _.map(tmpColumn,"id")})
         
    }

    return (
        <div className="mt-3">
            {props.item.title}
            <div className="mt-2">
            <Select styles={StyleSelect({menuListHeight: 100})} 
                options ={list}
                value ={selected}
                onChange={(selected)=>{handleSelected(selected)}} 
                maxMenuHeight={110}  
               />
            </div>

          
        </div>
    )
}

  
const MoreSetting =() =>{ 
    const [sortList, setSortList] = useState([]) 
    const [itemList, setItemList] = useState([{value: 1, label: 'Show Top'},{value: 2, label: 'Show Bottom'}]) 
    const [selectedSort, setSelectedSort] = useState([])  
    const [selectedItem, setSelectedItem] = useState([])  
    const [onlyTop, setOnlyTop] = useState(false)   
    const {stateChart, dispatchChart} = useContext(DashboardContext)  

    useEffect(()=>{   
        let typeSort = [{value: 1, label: 'Y Axis Ascending'},{value: 2, label: 'Y Axis Descending'},
                        {value: 3, label: 'X Axis Ascending'},{value: 4, label: 'X Axis Descending'}] 
 
        typeSort.map(item =>{  
            let tmp = {value: item.value, label: item.label}  
             //===== SET DEFAULT SELETED TYPE ======//
            
        }) 
        setSortList(typeSort) 
        setSelectedSort(stateChart.sortBy)
       
    },[stateChart.sortBy])
    
    const handleSelected = async(selected) =>{  
        setSelectedSort(selected)   
        dispatchChart({type: 'SET_SORT_CHART', sort: selected})
    }

    const handleSelectedItem = async(selected) =>{  
        setSelectedItem(selected)   
    }

    const handleUndefined = (e) =>{  
        dispatchChart({type: 'SET_UNDEFINED_CHART', value: e.target.checked})
    }

    return (
        <div>
            Sort by
            <div className="mt-2">
                <Select styles={StyleSelect({menuListHeight: 100})} 
                    options ={sortList}
                    value ={selectedSort}
                    placeholder={"Select sort by..."}
                    onChange={(selected)=>{handleSelected(selected)}} 
                    maxMenuHeight={110}  
               />
            </div>

            <div className="mt-3" hidden>
                <Form.Group controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" onClick={()=>setOnlyTop(!onlyTop)} label="Show only top/bottom items" />
                </Form.Group>
                {onlyTop ?
                <div className="mt-2 d-inline">
                    <Row>
                        <Col>
                            <Select styles={StyleSelect({menuListHeight: 100})} 
                            options ={itemList} 
                            value ={selectedItem}
                            onChange={(selected)=>{handleSelectedItem(selected)}} 
                            maxMenuHeight={110}  
                        />
                        </Col>
                        <Col md="3" className="ml-0 pl-0">
                            <input type="number" className="form-control" defaultValue={1} min="1"></input>
                        </Col>
                        <Col md="2" className="m-auto ml-0 pl-0">
                            Items
                        </Col>
                    </Row> 
                </div>
                :""}
            </div>

            <div className="mt-3" hidden>
                <Form.Group controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Show cumulative data" />
                </Form.Group> 
            </div>

            <div className="mt-3">
                <Form.Group controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Show undefined values" defaultChecked={stateChart.showUndefined} onChange={(e)=>handleUndefined(e)}/>
                </Form.Group> 
            </div>

        </div>
    )
}


const Boards = (props) =>{ 
    const [boardList, setBoardList] = useState([]) 
    const [selectedBoardTmp, setSelectedBoard] = useState([]) 
    const {selectedBoard, setShowChooseBoard, stateChart, dispatchChart, boardDashboardSelected} = useContext(DashboardContext)  

    useEffect(()=>{  
       
    },[props])
  
    useEffect(()=>{   
        getList() 
    },[])
    
    useEffect(()=>{   
        getList() 
    },[stateChart.selectedIdBoard])
    
    
    const handleSelected = async(selected) =>{  
        if(selected === null) selected = []
        setSelectedBoard(selected)  
        let board = []
        selected.map(item =>{
            board.push(item.value)
        }) 
        dispatchChart({type : 'SET_BOARD_CHART', board: board})  
    }

    const getList = () => {
        let arrTmp = []  
        stateChart.selectedBoard.map(item =>{  
            let tmp = {value: item.id, label: item.title}
            if(item.type === 1)
                arrTmp.push(tmp)
        }) 
        setBoardList(arrTmp)

        arrTmp = []
        let board = []
        stateChart.selectedBoard.map(item =>{  
            let tmp = {value: item.id, label: item.title}
            if(item.type === 1) { 
                if(stateChart.isUpdate){
                    if(stateChart.board.includes(item.id)){
                        board.push(item.id)
                        arrTmp.push(tmp)
                    }
                }else{
                    board.push(item.id) 
                    arrTmp.push(tmp) 
                }  
            } 
        }) 
        
        setSelectedBoard(arrTmp) 
    }

    return (
        <div>
            <div className="font-16">Choose boards</div>
            <div className="mt-3">Choose which boards to show on your widget</div>
            <div className="mt-3">
                <Select styles={stylesSelectMulti} 
                    options ={boardList}
                    isMulti
                    value ={selectedBoardTmp}
                    onChange={(selected)=>{handleSelected(selected)}}
                    maxMenuHeight={110}  
               />
            </div>
            <div className="mt-3 cursor-pointer text-primary" onClick={()=>setShowChooseBoard(true)}>
                <span>Add boards for this widget</span>
            </div>
        </div>
    )
}


const GroupList =() =>{
    const [selectElement, setSelectElement] = useState([])  
    const {stateChart} = useContext(DashboardContext)  
    const isFirstRun = useRef(true) 

    useEffect(()=>{    
        let tmpELement = []    
        stateChart.selectedBoard.map(items => {
            //===== INSERT TO SELECT ELEMENT ======//  
            let isExist = _.some(stateChart.board, function(i) {
                return i == items.id
            })  
            if(isExist) tmpELement.push(<GroupListSelect item={items} />)
            
        })   
        setSelectElement(tmpELement) 

    },[stateChart.board]) 
     

    return (
        <div> 
            <div className="mt-2">
                {selectElement.map((element,index)=>{  
                    return (
                        element === undefined ? "" :
                        <React.Fragment key={index}>
                            {React.cloneElement(element, {elementIndex : index})} 
                        </React.Fragment>
                    )
                })}     
            </div> 
        </div>
    )
}

const GroupListSelect = (props) =>{ 
    const [list, setList] = useState([]) 
    const [selected, setSelected] = useState([]) 
    const {stateChart, dispatchChart} = useContext(DashboardContext)  

    useEffect(()=>{   
        setSelected([])    
        let arrTmp = [], selectedTmp = []
        props.item.table.map(item =>{  
            item.rows.map((row, key) =>{
                if(key === 0) { 
                    //==== GET GROUP NAME ======// 
                    let tmp = {value: item.id, label: row[1].name, board_id : props.item.id} 
                    let isExist = _.some(stateChart.group, function(i) {
                        return i == item.id
                    })  
                    if(isExist) 
                        selectedTmp.push(tmp) 
                    arrTmp.push(tmp) 
                    
                }
            })  
        }) 
        setList(arrTmp) 
         //===== SET DEFAULT SELETED TYPE ======// 
        setSelected(selectedTmp)   
    },[props])
 
    const handleSelected = async(selected) =>{   
        if(selected === null) {
            NotificationManager.error("Minimal 1 group must be selected.")
            return
        }
        setSelected(selected)   
        let groupSelected = []  
        selected.map(item=>{  
            let board = _.filter(stateChart.selectedGroup, (group) => {return group.board_id === item.board_id})
            if(board.length) {
                let group = _.filter(board[0].group, (gr) => {return gr.id === item.value}) 
                group[0].board_id = item.board_id 
                groupSelected.push(group[0])
            } 
        }) 

        let tmpGroup = _.cloneDeep(stateChart.selectedGroup) 
        let idGroup = []
        _.map(tmpGroup, (item) => {  
            if(item.board_id === groupSelected[0].board_id) {
                item.group = groupSelected 
            }
            _.map(item.group, (gr)=>{
                idGroup.push(gr.id)
            })
           
        })   
        dispatchChart({type : 'SET_GROUP_CHART', group: idGroup})  
    }

    return (
        <div className="mt-3">
            {props.item.title}
            <div className="mt-2">
                <Select styles={stylesSelectMulti} 
                    options ={list} 
                    value ={selected}
                    isMulti
                    onChange={(selected)=>{handleSelected(selected)}} 
                    maxMenuHeight={110}  
               />
            </div>

          
        </div>
    )
}

export default ModalAddWidget