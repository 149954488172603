import React, {useContext} from 'react'
import {Modal, Row, Col, Card } from 'react-bootstrap'  
import {DashboardContext} from "../../context/DashboardContext"  
import _ from 'lodash' 

//=========IMG========// 
// import Status from '../../img/status-overview.png' 
// import Calendar from '../../img/calendar.png' 
// import Charts from '../../img/charts.png' 
// import Resource from '../../img/resource-management.png' 
// import Week from '../../img/my-week.png'  
// import Text from '../../img/widget-text.png'  

const ModalPermissionBoard = (props) =>{ 
    const {setShowWidget, createWidget, stateChart, setShowChooseBoard} = useContext(DashboardContext)  
    // const widget = [
    //     {title: 'Status Overview',img: Status},  
    //     {title: 'Calendar',img: Calendar},  
    //     {title: 'Chart',img: Charts, ready: true, fn : 'setShowWidget(true)'},  
    //     {title: 'Text',img: Text, ready: true, fn: 'createWidget({type: "Text"})'},  
    //     {title: 'Resource Management',img: Resource},  
    //     {title: 'Task Across Boards',img: Week},   
    // ]
      
    return (
        <Modal
        {...props}
        size="lg"
        dialogClassName="modal-90-w"
        id="modal-add-member"
        aria-labelledby="contained-modal-title-vcenter"
        > 
            <Modal.Header>
                 <span className="modal-title">
                    Dashboards Center
                </span>
            </Modal.Header>
            <Modal.Body className="m-2"> 
                 <Row> 
                {/* {widget.map(item =>{
                    return (
                        <Col md="4" className="pt-2 mt-2 cursor-pointer" key={item.title} >
                           <Card onClick={stateChart.selectedIdBoard.length ? ()=>{eval(item.fn);props.onHide()} : ()=>setShowChooseBoard(true)} style={item.ready ? { width: '18rem',cursor : 'pointer' }: {width: '18rem',cursor : 'not-allowed'}} className="shadow-sm">
                            <Card.Img style={{width :'100%', height:'175px', objectFit: 'cover'}} variant="top" src={item.img} />
                            <Card.Body className="text-center p-3">
                                <Card.Title className="p-0 m-0">{item.title}</Card.Title> 
                            </Card.Body>
                            </Card>
                        </Col>
                        
                    )
                })}  */}
                </Row>
                     
            </Modal.Body>
                 
        </Modal>
    )
}

export default ModalPermissionBoard