import React, {useEffect, useState, useContext, useRef} from 'react'

//=========3RD LIBRARY  ========//
import _ from 'lodash'  

//=========ICON LIST========//
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import StarOutlineIcon from '@material-ui/icons/StarBorder' 
import ContentEditable from 'react-contenteditable'  
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows'  
import SubjectIcon from '@material-ui/icons/Subject'  
import Edit from "monday-ui-react-core/dist/icons/Edit"  
import Moon from "monday-ui-react-core/dist/icons/Moon"   
import Invite from "monday-ui-react-core/dist/icons/Invite" 
import Print from "monday-ui-react-core/dist/icons/Print"  

import DeleteIcon from "../../img/delete.svg"   
import DuplicateIcon from "../../img/duplicate.svg"   
import TvIcon from "../../img/tv.svg"    
import NightIcon from "../../img/night.svg"   
import Locked from "monday-ui-react-core/dist/icons/Locked" 

//=========BOOTSTARP INITIAL========//
import {Col, Row, Nav,OverlayTrigger, Popover, Image} from 'react-bootstrap' 

//=========CONTEXT========//
import {DashboardContext} from "../../context/DashboardContext"  

const Header = () => {
    const {boardActive, updateBoard, params, deleteBoard,
            setPermissionDashboard, modeDashboard, setModeDashboard,
            duplicateDashboard, setColor, color, setShowMember} = useContext(DashboardContext)  
    const [state, setState] = useState({title:"", desc: ""})
    const [permission, setPermission] = useState("")
    const [colorFav, setColorFav] = useState("black")  
    const [rename, setRename] = useState(false)  
    const isFirstRun = useRef(true) 
    let ref = React.useRef(null)
    let refContentEditable = useRef([])

    const handleChangeTitle = (value) => {
        setState({...state, title: value})
    } 

    const handleEditItem = async() =>{
       let param = {}
       param.id = boardActive.id
       param.title = state.title
       param.description = state.desc 
       param.permission_id = boardActive.permissionId 
       param.is_favorites = boardActive.is_favorites 
       param.updated_by = params.id
       await updateBoard(param)
       setRename(false)
    }

    const handleEnterItem  = (event) => {
        var keyCode = event.which || event.keyCode;
        if(keyCode === 13) {  
            handleEditItem()
        }
    }

    const handleFav = async () => {
        let param = {}
       param.id = boardActive.id
       param.title = state.title
       param.description = state.desc 
       param.permission_id = boardActive.permissionId 
       param.is_favorites = !boardActive.is_favorites 
       param.updated_by = params.id
       setColorFav('black')
       if(param.is_favorites) setColorFav('orange')
       await updateBoard(param)
    }

    useEffect(()=>{  
        if(!boardActive.dashboard) return 
        setColorFav('black')
        if(boardActive.is_favorites) setColorFav('orange')
        let desc = boardActive.description
        desc = desc.replace(/<p>/g, ' <p class="m-0">')  
        setState({title: boardActive.title})
        
        setModeDashboard("edit")
        if(boardActive.dashboard.length > 0) setModeDashboard("view")

        setPermission("Main")
        if(boardActive.permission_id === 1)
            setPermission("Private") 
    },[boardActive])
 
    const printDashboard = () => { 
        window.print()
        
    }

    const handleRename = () => {
        ref.click() 
        setRename(true) 
    }

    useEffect(()=>{
        if(isFirstRun.current){
            isFirstRun.current = false
            return
        } 
        refContentEditable.el.current.focus() 
    },[rename])

    useEffect(()=>{
        setColorFav(color.color)
        if(colorFav.color === 'white') setColorFav('black')
        if(boardActive.is_favorites) setColorFav('orange')
    },[color])

    const duplicate = async () => {
        ref.click()
         await duplicateDashboard()
    }

    const setTvMode = () => {
        var elem = document.getElementById("main-dashboard")
        if (elem.requestFullscreen) {
            elem.requestFullscreen()
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen()
        } else if (elem.msRequestFullscreen) { 
            elem.msRequestFullscreen()
        }
    }
 

    const popoverBoard = (
        <Popover id="popover-basic"> 
            <Popover.Content> 
                <Nav.Link className={"nav-menu p-2 pr-5"} onClick={()=>{setTvMode(); ref.click()}}> 
                    <Image src={TvIcon}></Image>
                    <span className="workspace-item-name-child align-text-top ml-2 font-14">TV Mode</span>
                </Nav.Link> 
                 <Nav.Link className={ params.permission_id < 4 ? "nav-menu p-2 pr-5" : "nav-menu p-2 pr-5 disabled"} onClick={()=>{setPermissionDashboard(true); ref.click()}}> 
                    {boardActive.permission_id === 1 ?
                        <Locked  className="align-text-top icon"/>
                        :
                        <SubjectIcon style={{ fontSize: 20 }} className="align-text-top icon" />
                    }
                    <span className="workspace-item-name-child align-text-top ml-2 font-14">Change type to {permission}</span>
                </Nav.Link> 
                <Nav.Link hidden className={ "nav-menu p-2 pr-5"} 
                    onClick={()=>{setColor(color.content === "#20253b" ? 
                    {header:'white', content:"rgb(245, 246, 248)", color: 'black', chart: 'white'} : 
                    {header:'#292f4c',content:"#20253b", color: 'white', chart: '#31385b'}); ref.click()}}> 
                    {color.content === "#20253b" ?
                         <>
                            <Image src={NightIcon}></Image>
                            <span className="workspace-item-name-child align-text-top ml-2 font-14">Switch to Light Mode</span>
                         </>
                        :
                        <>
                            <Moon className="align-text-top icon"/>
                            <span className="workspace-item-name-child align-text-top ml-2 font-14">Switch to Dark Mode</span>
                        </>
                    }
                   
                </Nav.Link>  
            <div className="spacer pt-2"></div>  
            
                <Nav.Link className={ params.permission_id < 4 ? "nav-menu p-2 pr-5 mt-2" : "nav-menu p-2 pr-5 mt-2 disabled"} onClick={()=>handleRename()}> 
                    <Edit style={{color: 'black' , width: 18}} className="align-text-top"/>
                    <span className="workspace-item-name-child align-text-top ml-2 font-14">Rename</span>
                </Nav.Link> 
                <Nav.Link className={ params.permission_id < 4 ? "nav-menu p-2 pr-5" : "nav-menu p-2 pr-5 disabled"} onClick={()=>duplicate()}> 
                    <Image src={DuplicateIcon}></Image>
                    <span className="workspace-item-name-child align-text-top ml-2 font-13">Duplicate</span>
                </Nav.Link> 
                <Nav.Link className={ params.permission_id < 4 ? "nav-menu p-2 pr-5" : "nav-menu p-2 pr-5 disabled"} onClick={()=>deleteBoard(boardActive)}> 
                    <Image src={DeleteIcon}></Image>
                    <span className="workspace-item-name-child align-text-top ml-2 font-14">Delete</span>
                </Nav.Link> 
            </Popover.Content>
        </Popover>
    )

    const popoverShare = (
        <Popover id="popover-basic"> 
            <Popover.Content> 
                 <Nav.Link className={ params.permission_id < 4 ? "nav-menu p-2 pr-5" : "nav-menu p-2 pr-5 disabled"} onClick={()=>{setShowMember(true);ref.click()}}> 
                    <Invite  className="align-text-top icon"/>
                    <span className="workspace-item-name-child align-text-top ml-2 font-14">Subscribers & Guests / 1</span>
                </Nav.Link> 
                <Nav.Link className={"nav-menu p-2 pr-5"} onClick={()=>{printDashboard();ref.click()}}> 
                    <Print className="align-text-top icon"/>
                    <span className="workspace-item-name-child align-text-top ml-2 font-14">Print Dashboard</span>
                </Nav.Link> 
                
            </Popover.Content>
            
        </Popover>
    )
    
    return (
        <React.Fragment>
            {state.title?
                <Col className="p-3 ml-2" ref={r => (ref = r)}>  
                    <Row>
                        <ContentEditable 
                            html={state.title}   
                            className="board-title-view board-title"
                            disabled={modeDashboard === "edit"  ? false : rename ? false : true }   
                            onBlur={()=>{handleEditItem()}}
                            ref ={(r)=> refContentEditable = r}
                            style={{color: color.color}}
                            onChange={(e)=> {handleChangeTitle(e.target.value.replace(/<\/?[^>]+(>|$)/g, "")+ " ")}} 
                            onKeyDown={(e)=>handleEnterItem(e)} 
                        />  
                        <StarOutlineIcon style={{ color: colorFav, fontSize: 20}} onClick = {handleFav} className="align-text-top mt-2 align-text-top ml-2"/>
                        {params.permission_id < 4?
                        <Col className="col-item"> 
                                <span onClick={()=>setModeDashboard("edit")} className={modeDashboard !== "edit" ? "float-right color-grey text-controll-dashboard text-edit-active-noactive p-1 cursor-pointer" : "float-right  p-1 bg-edit text-edit-active cursor-pointer"}>
                                    <Edit style={ modeDashboard === "edit" ? { color: '#4FBCB6'} :{ color: color.color}} className="align-text-top float-left cursor-pointer ml-2"/>
                                    <div className="d-inline-block align-text-top ml-2 mr-2" style={{marginTop: '1px'}}>
                                        <span>Edit</span>
                                    </div> 
                                </span>
                            
                                <span onClick={()=>setModeDashboard("view")} className={modeDashboard !== "view" ? "float-right color-grey text-controll-dashboard text-edit-active-noactive cursor-pointer ml-2" : "ml-2 float-right p-1  bg-edit text-edit-active cursor-pointer"}>
                                    <DesktopWindowsIcon style={ modeDashboard === "view" ? { color: '#4FBCB6', fontSize:17} :{ color: color.color, fontSize:17}} className=" mt-1 float-left cursor-pointer ml-2"/>
                                    <div className="d-inline-block align-text-top ml-2 mr-2">
                                        <span>View</span>
                                    </div> 
                                </span>
                            
                        </Col>
                         
                        : null }
                        <Col>
                            <OverlayTrigger trigger="click" placement="bottom" overlay={popoverBoard} rootClose>
                                <MoreHorizIcon style={{ color: color.color}} className="align-text-top m-2 float-right cursor-pointer"/>
                            </OverlayTrigger> 
                            <OverlayTrigger trigger="click" placement="bottom" overlay={popoverShare} rootClose>
                                <span className="member float-right text-white bg-primary rounded">
                                    <Invite style={{ color: 'white'}} className="align-text-top float-left cursor-pointer"/>
                                    <div className="d-inline-block align-text-top ml-2">
                                        <span>Share</span>
                                    </div> 
                                </span>
                            </OverlayTrigger>
                         
                        </Col>
                    </Row> 
                </Col>  
            :null}
       </React.Fragment>
    )
}

export default Header