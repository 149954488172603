import React, { useEffect, useContext, useRef, useState } from 'react'
//=========BOOTSTARP INITIAL========//
import { Row, Col, Tabs, Tab } from 'react-bootstrap'

import BootstrapSwitchButton from 'bootstrap-switch-button-react'

import { SettingContext } from "../../context/SettingContext"
import _ from "lodash"

const Setting = () => {
    const { updateSetting, setting } = useContext(SettingContext)
    const [data, setData] = useState([])
    let provideData = [ 
        {
            title: 'Item Task',
            key: 'task',
            checked : true
        },
        {
            title: 'Replays Comment',
            key: 'coment',
            checked : true
        },
        {
            title: 'Teams',
            key: 'teams',
            checked : true
        },
        {
            title: 'Workspace',
            key: 'workspace',
            checked : true
        },
        {
            title: 'Board',
            key: 'board',
            checked : true
        },
        {
            title: 'Dashboard',
            key: 'dashboard',
            checked : true
        },
    ]

    useEffect(()=>{  
        if(!setting) setData(provideData)
        else {
            
            let tmpData = []
            Object.keys(setting).map(item=>{
                if(item === "id" || item === "user_id") return
                let data = _.filter(provideData, (val) => { 
                    return val.key === item
                })
                if(data.length) data[0].checked = setting[item]
                tmpData.push(data[0])
            })
            setData(tmpData)
        }
    },[setting])

    return (
        <React.Fragment>
            <Row className="bg-grey pb-5 pt-3 justify-content-md-center">
                <Col xs="11" className="w-100 pt-3 pb-5 bg-white mt-4" style={{height:'70vh', overflow:'scroll'}}>
                    {data.map((e, key) => {
                        return (
                            <div key={key} className="bg-white ml-4 mr-4 d-flex justify-content-center">
                                <div className="w-100 border-bottom ml-5 mr-5 p-2 d-flex justify-content-start">
                                    <div className="flex-fill">
                                        <p className="font-16 text-capitalize font-weight-bold">Notifications {e.title}</p>
                                        <p className="font-14 text-break font-weight-light">Receive push notification on mentions and comments</p>
                                    </div>
                                    <div>
                                        <BootstrapSwitchButton
                                            checked={e.checked}
                                            onlabel=' '
                                            offlabel=' '
                                            size="sm"
                                            offstyle="secondary"
                                            style={{ borderRadius: '50px !important' }}
                                            onChange={(checked, key = e.key) => {
                                                var params = {
                                                    key: key,
                                                    user_id: 1,
                                                    value: checked,
                                                    updated_at: new Date()
                                                }
                                                updateSetting(params)
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>
                        )
                    })}

                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Setting
