import React, {useEffect, useState, useContext, useRef} from 'react'
import {Modal, Button, Form, Alert, Spinner } from 'react-bootstrap' 
import { useForm } from "react-hook-form" 
import {useHistory} from 'react-router-dom' 
import {DashboardContext} from "../../context/DashboardContext" 

const ModalAddBoard = (props) =>{
    const history = useHistory()
    const ref = useRef()
    const [isLoading, setLoading] = useState(false) 
    const { register, handleSubmit, watch, errors } = useForm()
    const [alert, setAlert] = useState({msg: '', variant: 'success', hidden: true}) 
    const {params, setFlagNewBoard,setParams, board, getTeams, createTeams} = useContext(DashboardContext)  

    useEffect(()=>{
        setAlert({...alert, hidden: true})
    },[props])
  
    const onSubmit = async (paramTmp) => {
        setLoading(true)

        paramTmp.id = params.id 
        let result =  await createTeams(paramTmp)   
        await getTeams(params.id)
        if(result.status !== 200) {
           setAlert({msg: result.data.detail, variant: 'danger', hidden: false})
           setLoading(false)
        }else{
            setAlert({msg: 'Create teams successfully', variant: 'success', hidden: false})
              //=========SET indexWorkspaceActive FOR DEAFAULT SELECTED WORKSAPCE=========//
            setParams({...params, indexBoardActive: board.length})
            simulateNetworkRequest().then(() => {
                setAlert({ hidden: false})
                
                props.onHide()
                setLoading(false)
            })
            
        }
     
    }
    
    function simulateNetworkRequest() {
        return new Promise((resolve) => setTimeout(resolve, 1000));
    }

    return (
        <Modal
        {...props}
        size=""
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered>
            <Form onSubmit={handleSubmit(onSubmit)}> 
                <Modal.Header> 
                    <span className="modal-title">
                        Create Teams
                    </span>
                </Modal.Header>
                <Modal.Body className="m-2"> 
                    <Form.Control autoFocus={true} 
                    className="mb-3 font-14"
                    ref={ref} 
                    type="text" name="team" placeholder="New Teams" ref={register({ required: true })}></Form.Control>
                     {errors.title && <span>This field is required</span>}
                    
                    <Alert variant={alert.variant} hidden={alert.hidden} className="mt-3">
                        {alert.msg}
                    </Alert>
                </Modal.Body>
                <Modal.Footer>
                     <Button 
                        disabled={isLoading}
                        className="btn-yes text-white"
                        type="submit"
                        >
                            {isLoading ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    />
                                : "Add teams" 
                            }
                    </Button> 
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default ModalAddBoard