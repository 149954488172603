import React, { useEffect, useState, useContext, useRef } from "react";

//=========BOOTSTARP INITIAL========//
import { Col, Row, Image, Nav } from "react-bootstrap";
import { Style } from "../../helpers/style/Style";
import {
  initialString,
  stripHtmlAnd,
  asyncRequest,
} from "../../helpers/utils/Utils";
import DeleteIcon from "../../img/delete.svg";
import AddIcon from "../../img/add-pa.svg";
import ActiveIcon from "../../img/active.svg";
import NonActiveIcon from "../../img/non_active.svg";
import AvatarIcon from "../../img/avatar.svg";
import Cover from "../../img/cover.jpg";
import Cover1 from "../../img/cover1.jpg";
import Cover2 from "../../img/cover2.jpg";
import Cover3 from "../../img/cover3.jpg";
import Cover4 from "../../img/cover4.jpg";
import Cover5 from "../../img/cover5.jpg";
import Cover6 from "../../img/cover6.jpg";
import { useHistory, useLocation } from "react-router-dom";

import _ from "lodash";
import ContentEditable from "react-contenteditable";

//=========CONTEXT========//
import { DashboardContext } from "../../context/DashboardContext";

//=========COMPONENT INITIAL========//
import ModalInviteAdmin from "../modal/ModalInviteAdmin";

const Workspace = () => {
  const {
    selectedWorkspace,
    params,
    workspaceTeams,
    showPA,
    setDeleteWorkspaceMember,
    setShowPA,
    setModalSpinner,
    updateWorkspace,
    updateWorkspaceShowDashboard,
    getWorkspace,
    width,
    setParams,
    setEditWorkspaceMember,
    modalSpinner,
    showDeleteWorkspace,
    setShowDeleteWorkspace,
    setSelectedTeamsSetting,
  } = useContext(DashboardContext);
  const titleRef = useRef();
  const refShowIndashboard = useRef(); 
  const history = useHistory();
  const [coverImg, setCoverImg] = useState();

  const onHide = async (isDone) => {
    setShowPA(false);
    // if(isDone) await getDataWorkspace()
  };

  useEffect(() => {
    const coverList = [
      Cover,
      Cover1,
      Cover2,
      Cover2,
      Cover3,
      Cover4,
      Cover5,
      Cover6,
    ];
    var randomInt = Math.floor(Math.random() * coverList.length);
    var coverImg = coverList[randomInt];
    setCoverImg(coverImg);
  }, []);

  const updateDataWorkspace = async (isActive) => {
    if (titleRef.current.el.current.outerText === "") return;
    let param = {};
    param.id = selectedWorkspace.value;
    param.name = titleRef.current.el.current.outerText;
    param.is_active = isActive;

    setModalSpinner(true);

    let result = await updateWorkspace(param);
    // if(!isActive) setWorkspace([])
    if (result.status === 200) {
      setParams({
        ...params,
        indexBoardActive: 0,
        workspace_id: result.data.id,
        indexWorkspaceActive: 0,
      });
      await getWorkspace(params);
    }

    asyncRequest(500).then(() => {
      setModalSpinner(false);
    });
  };

  const handleselectedTeamsSetting = (item) => {
    // alert("anjay")
    item = {
      created_by: item.created_by,
      id: item.team_id,
      id_default: true,
      name: item.team_name,
      see_all_member: true,
    };
    setSelectedTeamsSetting(item);
    history.push("/app/workspace/team/members");
  };

  useEffect(() => {
    if (showDeleteWorkspace.actionDelete) {
      updateDataWorkspace(false);
      setShowDeleteWorkspace({ actionDelete: false });
    }
  }, [showDeleteWorkspace.actionDelete]);

  const handleChange = async (e) => {
    let isChecked = e.target.checked;

    let param = {};
    param.id = selectedWorkspace.value;
    param.is_show_dashboard = isChecked
    let result = await updateWorkspaceShowDashboard(param);
   
    if (result.status === 200) {
     
    }
  }

  return (
    <React.Fragment>
      {selectedWorkspace && selectedWorkspace.value && !modalSpinner ? (
        <Row className="vw-100 ">
          <ModalInviteAdmin show={showPA} onHide={(isDone) => onHide(isDone)} />
          <Col> 
            <div
              className="bg-grey h-100 min-vh-100 overflow-scroll overflow-x-hidden"
              style={{ marginLeft: width.board }}
            >
              {/* <div className="worspace-cover">
                                <Image src={coverImg} className="worspace-cover"></Image>
                            </div> */}
              <Col className="m-4">
                <div className="p-3 cursor-pointer">
                  <span style={Style.circleTextWorkspace}>
                    <span style={Style.circleTextWorkspaceSpan}>
                      {selectedWorkspace.label
                        ? initialString(selectedWorkspace.label)
                        : "NA"}
                    </span>
                  </span>
                  <ContentEditable
                    html={
                      selectedWorkspace.label
                        ? selectedWorkspace.label
                        : "No Available"
                    }
                    className="font-24 font-weight-bold ml-3 d-inline content-editable single-line"
                    disabled={params.permission_id < 3 ? false : true}
                    onBlur={() => {
                      updateDataWorkspace(true);
                    }}
                    ref={(r) => (titleRef.current = r)}
                  />
                </div>
                <span
                  className="workspace-text"
                  style={{
                    position: "relative",
                    bottom: "30px",
                    left: "100px",
                  }}
                >
                  {selectedWorkspace.team_name}
                  <span
                    className="workspace-text cursor-pointer"
                    style={{ position: "relative", left: "50px" }}
                    onClick={() => {
                      handleselectedTeamsSetting(selectedWorkspace);
                    }}
                  >
                    See All Members
                  </span>
                  <span
                    className="workspace-text cursor-pointer"
                    style={{ position: "relative", left: "100px" }}
                  >
                    <input
                      type="checkbox"
                      label="Show in Dashboard"
                      className="mr-2"
                      ref={(r) => (refShowIndashboard.current = r)}
                      onChange={(e)=> handleChange(e)}
                      defaultChecked = {selectedWorkspace.is_show_dashboard}
                    ></input> 
                    Show In Dashboard Teams
                  </span>
                </span>
              </Col>
              <Col className="m-4">
                <div className="mt-3 p-3">
                  {!selectedWorkspace.label ? null : (
                    <>
                      <span className="font-14 font-w-600">
                        {workspaceTeams.length}
                      </span>
                      <span className="font-14"> member on this workspace</span>
                    </>
                  )}

                  {params.permission_id < 3 ||
                  selectedWorkspace.permission_id < 3 ? (
                    <span
                      className="invite-workspace-pa"
                      onClick={() => setShowPA(true)}
                    >
                      <Image src={AddIcon}></Image>
                      <span className="invite-workspace-pa-span">
                        Invite Project Admin
                      </span>
                    </span>
                  ) : null}
                  {params.permission_id < 3 ? (
                    <span
                      className="delete-workspace"
                      onClick={() =>
                        setShowDeleteWorkspace({
                          isShow: true,
                          name: selectedWorkspace.label,
                        })
                      }
                    >
                      <Image src={DeleteIcon}></Image>
                      <span className="delete-workspace-span">
                        Delete Workspace
                      </span>
                    </span>
                  ) : null}
                </div>
              </Col>
              <Col className="m-4">
                <Row className="no-gutters pt-3">
                  {workspaceTeams.map((item) => {
                    return (
                      <div
                        key={item.user.id}
                        className="member-list text-center"
                      >
                        <div className="avatar pt-4 w-100">
                          <Image src={AvatarIcon}></Image>
                          {item.is_active ? (
                            <Image
                              className="float-right mr-2"
                              src={ActiveIcon}
                            ></Image>
                          ) : (
                            <Image
                              className="float-right mr-4"
                              src={NonActiveIcon}
                            ></Image>
                          )}
                        </div>
                        <div className="member-name pt-3">
                          <span>{item.user.username}</span>
                        </div>
                        <div className="member-job-desc pt-1">
                          <span>
                            {stripHtmlAnd(
                              item.user.title ? item.user.title : "-"
                            )}
                          </span>
                        </div>
                        <div className="pt-3">
                          <span
                            className={
                              `pt-1 pb-1 permission-` +
                              item.permission.name
                                .toLowerCase()
                                .trim()
                                .replace(" ", "")
                            }
                          >
                            <span>{item.permission.name}</span>
                          </span>
                        </div>
                        {item.permission.id === 1 ||
                        item.user_id === params.id ||
                        selectedWorkspace.permission_id > 2 ? null : (
                          <div className="d-inline-block w-auto">
                            <div
                              className="pt-4 mt-2 d-inline-block"
                              onClick={() =>
                                setEditWorkspaceMember({
                                  isShow: true,
                                  data: item,
                                })
                              }
                            >
                              <span className="edit-member">Edit Member</span>
                            </div>
                            <div
                              className="pt-4 mt-2 d-inline-block ml-2"
                              onClick={() =>
                                setDeleteWorkspaceMember({
                                  isShow: true,
                                  data: item,
                                })
                              }
                            >
                              <span className="delete-member">Delete </span>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </Row>
              </Col>
              {/* <div className="w-50 mt-2"> */}
              {/* <Row> */}
              {/* <Col md="4">
                                            <div className="d-inline"> 
                                                <span className="workspace-text d-inline pl-3">Teams</span>
                                            </div>
                                        </Col> */}
              {/* <Col>
                                            <div className="d-inline"> 
                                                <span className="d-inline" style={{color: "grey"}} onClick={() => history.push("/app/setting/teams")}>
                                                    See All Teams
                                                </span>
                                            </div>
                                        </Col> */}
              {/* </Row>
                                </div> */}
              {/* <div className="w-50 pl-3 pt-2">
                                    <Row>
                                        <Col md="1">
                                            <span style={Style.circleTextTeams}>
                                                <span style={Style.circleTextTeamsSpan}>{selectedWorkspace && selectedWorkspace.team_name? initialString(selectedWorkspace.team_name): "NA"}</span>
                                            </span>
                                        </Col>
                                        <Col md="6">
                                            <div className="d-inline">
                                                <span className="font-18 font-w-600 text-dark ">{selectedWorkspace.team_name ? selectedWorkspace.team_name: "No Available"}</span>
                                            </div> 
                                        </Col>
                                        <Col>
                                            <div className='pt-1 cursor-pointer' style={{color: "grey"}} onClick={()=>{handleselectedTeamsSetting(selectedWorkspace)}}>
                                                See All Members
                                            </div>
                                        </Col>

                                    </Row> 
                                </div> */}
              {/* <div className="mt-3 p-3">
                                    
                                    {!selectedWorkspace.label? null : <>
                                    <span className="font-14 font-w-600">{workspaceTeams.length}</span> 
                                    <span className="font-14"> member on this workspace</span></>}

                                    {params.permission_id < 3 || selectedWorkspace.permission_id < 3 ? 
                                        <span className="invite-workspace-pa" onClick={()=>setShowPA(true)}>
                                            <Image src={AddIcon}></Image>
                                            <span className="invite-workspace-pa-span">Invite Project Admin</span>
                                        </span>
                                        : null
                                    }
                                    {params.permission_id < 3  ?
                                        <span className="delete-workspace" onClick={()=>setShowDeleteWorkspace({isShow: true, name: selectedWorkspace.label})}>
                                            <Image src={DeleteIcon}></Image>
                                            <span className="delete-workspace-span">Delete Workspace</span>
                                        </span>
                                    :null 
                                    }
                                </div> */}

              {/* <Row className="no-gutters pt-3">
                                    {workspaceTeams.map (item => {  
                                        return (
                                            <div key = {item.user.id} className="member-list text-center">
                                                <div className="avatar pt-4 w-100">
                                                    <Image src={AvatarIcon}></Image>
                                                    {item.is_active ? <Image  className="float-right mr-2" src={ActiveIcon}></Image>
                                                    :<Image  className="float-right mr-4" src={NonActiveIcon}></Image>}
                                                </div>
                                                <div className="member-name pt-3">
                                                    <span>{item.user.username}</span>
                                                </div>
                                                <div className="member-job-desc pt-1">
                                                    <span>{stripHtmlAnd(item.user.title ? item.user.title : '-')}</span>
                                                </div>
                                                <div className="pt-3">
                                                    <span className={`pt-1 pb-1 permission-` + item.permission.name.toLowerCase().trim().replace(" ", "")}><span>{item.permission.name}</span></span>
                                                </div>
                                                {item.permission.id ===1 || item.user_id === params.id || selectedWorkspace.permission_id > 2? null :
                                                <div className="d-inline-block w-auto" >
                                                    <div className="pt-4 mt-2 d-inline-block" onClick={()=>setEditWorkspaceMember({isShow: true, data: item})} >
                                                        <span className="edit-member">Edit Member</span>
                                                    </div>
                                                    <div className="pt-4 mt-2 d-inline-block ml-2" onClick={()=>setDeleteWorkspaceMember({isShow: true, data: item})} >
                                                        <span className="delete-member">Delete </span>
                                                    </div>
                                                   
                                                </div>
                                                }

                                                
                                            </div>
                                        )
                                    })}
                                
                                </Row> */}
              {/* </Col>  */}
            </div>
          </Col>
        </Row>
      ) : null}
    </React.Fragment>
  );
};

export default Workspace;
