
export const ChartReducer = (state, action) =>{
    switch (action.type){
        case 'INITIAL':
            return {
                ...state,
                chart: action.chart
            }   
  
        case 'SET_SELECTED_BOARD':
            return {
                ...state,
                selectedBoard : action.selectedBoard,
                selectedIdBoard : action.selectedIdBoard,
                group : action.group,
                board : action.board,
                selectedIdGroup : action.selectedIdGroup,
                selectedGroup : action.selectedGroup
            }   
            
        case 'SET_IS_UPDATE':
            return {
                ...state,
                isUpdate : action.value,
                title : action.title,
                sortBy : {value: 0, label : 'Select sort by...'},
                showUndefined: false
            }   

        case 'SET_UPDATE_DONE':
            return {
                ...state,
                isUpdate : false,
                
            }   

        case 'SET_SELECTED_ID_BOARD':
            return {
                ...state,
                selectedIdBoard : action.value
            }   

        case 'CLEAR_SELECTED_BOARD':
            return {
                ...state,
                selectedBoard : [],
                isUpdate : false
            }   

        case 'SET_DEFAULT_CHART':
            return {
                ...state, 
                type: 'column', 
                xAxis: 'Status', 
                title: 'Chart',
                sortBy : {value: 0, label : 'Select sort by...'},
                showUndefined: false,
                xAxisColumn : [],
                xAxisColumnId : [],
            }   
        
        case 'SET_UPDATE_CHART':
            return {
                ...state,
                type: action.data.widget.type, 
                xAxis: action.data.widget.xAxis, 
                yAxis: action.data.widget.yAxis,
                board : action.data.widget.board, 
                group : action.data.widget.group, 
                selectedBoard: action.data.widget.selectedBoard, 
                title : action.data.title, 
                column: action.data.column, 
                sortBy: action.data.widget.sortBy, 
                isUpdate: true, 
                showUndefined : action.data.widget.showUndefined, 
                xAxisColumnId : action.data.widget.xAxisColumnId, 
                 xAxisColumn : action.data.widget.xAxisColumn,  
                idUpdate : action.data.id
            }   

        case 'SET_TYPE_CHART':
            return {
                ...state,
                type: action.value,  
            }   

        case 'SET_XAXIS_CHART':
            return {
                ...state,
                xAxis: action.value,  
                xAxisColumn : action.column,
                xAxisColumnId : action.id
            }
            
        case 'SET_XAXIS_CHART_2':
            return {
                ...state,
                xAxis2: action.value,  
            }

        case 'SET_XAXIS_COLUMN_CHART':
            return {
                ...state, 
                xAxisColumn : action.column,
                xAxisColumnId : action.id,
            }   
            
        case 'SET_XAXIS_COLUMN':
            return {
                ...state,
                xAxisColumn: action.value,  
            }   

        case 'SET_BOARD_GROUP_CHART':
            return {
                ...state,
                board: action.board,  
                group: action.group,  
            }   

        case 'SET_BOARD_CHART':
            return {
                ...state,
                board: action.board 
            }   

        case 'SET_GROUP_CHART':
            return {
                ...state,
                group: action.group, 
            }    

        case 'SET_SORT_CHART':
            return {
                ...state,
                sortBy: action.sort, 
            }
            
        case 'SET_GROUPING_STATUS':
            return {
                ...state,
                groupBy: action.group, 
            }

        case 'SET_UNDEFINED_CHART':
            return {
                ...state,
                showUndefined: action.value, 
            }    

        case 'SET_ADJUST_COLOR':
            return {
                ...state,
                adjustColor: action.value, 
            }    
            
        default:
            return state
    }
}
