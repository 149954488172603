//=========REACT INITIAL========//
import React, {useEffect, useState, useContext, useRef} from 'react' 
import {DashboardContext} from "../../context/DashboardContext" 

//=========BOOTSTARP INITIAL========//
import {Col, Row, Nav,OverlayTrigger, Popover, ProgressBar, Form, Tooltip, Image } from 'react-bootstrap' 

//=========3RD LIBRARY========// 
import _ from 'lodash'
import {StyleSelect} from "../../helpers/select/Select"
import Select from 'react-select' 
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import Rating from "react-rating"
import '../css/dashboard.css'
import {NotificationManager} from 'react-notifications' 
import {MultiGrid, ColumnSizer, AutoSizer} from 'react-virtualized'
import ContentEditable from 'react-contenteditable' 
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' 
//=========ICON LIST========//  
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline' 
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'    
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered' 
import AttachmentIcon from '@material-ui/icons/Attachment' 
import { exportExcel, stripHtml,stripHtmlAnd, taskByGroup, exportTemplate, ExcelDateToJSDate, uuid,asyncRequest } from '../../helpers/utils/Utils' 
import Item from 'monday-ui-react-core/dist/icons/Item'  
import XLSX from 'xlsx'
import LinkIcon from '@material-ui/icons/Link'
import ArrowDown from "../../img/arrow-down.svg" 
import ChatIcon from "../../img/chat.svg" 
import SubItemIcon from "../../img/sub-control.svg" 
import AddIconSvg from "../../img/add.svg" 
import ExpandIcon from "../../img/expand.svg" 
import ParagraphIcon from "../../img/paragraph.svg" 
import AvatarIcon from "../../img/avatar-task.svg"  
import FileIcon from "../../img/file.svg"  
import AddIcon from "../../img/add_icon.svg"  
import DuplicateIcon from "../../img/duplicate.svg"  
import MoveGroupIcon from "../../img/move-group.svg"  
import MoveUpIcon from "../../img/move-up.svg"  
import MoveDownIcon from "../../img/move-down.svg"  
import DeleteIcon from "../../img/delete.svg" 
import DownloadFileIcon from "../../img/download-icon.svg" 
import ExcelIcon from "../../img/excel.svg" 
import DownloadIcon from "../../img/download.svg" 
import UploadIcon from "../../img/upload.svg" 
import RemoveIcon from "../../img/remove.svg" 
import Delete from "../../img/delete.svg" 
import AddSmallIcon from "../../img/add-small.svg"  
import StatusIc from "../../img/status_ic.svg"  
import DoneIc from "../../img/done_ic.svg"  
import PersonIc from "../../img/person_ic.svg"  
import DateIc from "../../img/date_ic.svg"  
import TimelineIc from "../../img/timeline_ic.svg"  
import DueTimelineIc from "../../img/due_timeline_ic.svg"  
import TextIc from "../../img/text_ic.svg"  
import ParagraphIc from "../../img/paragraph_ic.svg"  
import ProgressIc from "../../img/progress_ic.svg"  
import WeekIc from "../../img/week_ic.svg"  
import RattingIc from "../../img/ratting_ic.svg"  
import PriorityIc from "../../img/priority_ic.svg"  
import FileIc from "../../img/file_ic.svg"  
import DepedencyIc from "../../img/depedency_ic.svg"  
import LastIc from "../../img/last_ic.svg"  
import CreationIc from "../../img/creation_ic.svg"  
import LeftIc from "../../img/left.svg"  
import RightIc from "../../img/right.svg"  
import TrashIc from "../../img/trash.svg"  
import IncreaseIc from "../../img/increase.svg"  
import DecreaseIc from "../../img/decrease.svg"  
import SettingIc from "../../img/setting.svg"   
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import AssessmentIcon from '@material-ui/icons/AssessmentOutlined';

const Content = (props) => { 
    const [textAdd, setTextAdd] = useState("+ Add")   
    const [selectedWeek, setSelectedWeek] = useState("") 
    const [arrWeeks, setWeekArr] = useState([])   
    const [memberList, setMemberList] = useState([])   
    const [selectedMember, setSelectedMember] = useState([])  
    const [startDateTimeline, setStartDateTimeline] = useState(new Date())
    const [startDateTimelineDueDate, setStartDateTimelineDueDate] = useState(new Date())
    const [endDateTimeline, setEndDateTimeline] = useState(new Date()) 
    const [endDateTimelineDueDate, setEndDateTimelineDueDate] = useState(new Date()) 
    const [selectedIRowndex, setSelectedRowIndex] = useState() 
    const [selected, setSelected] = useState() 
    const [selectedColumnIndex, setSelectedColumnIndex] = useState()  
    const [columnMapping, setColumnMapping] = useState()  
    const [isCollapse, setCollapse] = useState(true)    
    let refTimeLine = React.useRef(true)
    let refTimeLineDueDate = React.useRef(true)
    let ref = React.useRef(null)
    let refPic = React.useRef([])
    let refGrid= React.useRef([])
    let refEditable = useRef("")
    let refNumber = useRef("")
    let refProgress = useRef("")
    let refParagraph = useRef("")
    let isColumnSettings = useRef(false)
    let fileUploader = useRef([])
    let fileImport = useRef([]) 
    let currentListGrid = useRef([]) 
    let currentListId = useRef("") 
    const isFirstRunSearch = useRef(null)
    const isFirstRun = useRef(true) 
    const isFirstRunProps = useRef(true) 
    const targetOverlay = useRef(null)
    const [listGrid, setListGrid] = useState([])
    const [listGridForFilter, setListGridForFilter] = useState([])
    const {setItemUpdate, setShowMember, boardUser, duplicateGroup, 
            params, uploadFile, deleteFile, downloadFile, priority, 
            setShowPriority, status, setShowStatus, setShowComment,
            boardActive, setItemActive ,valueSearch,valueSort, table, 
            setTable, member, setColumnUpdate,setColumnAdd, setItemAdd,setSubItemAdd, 
            setItemDelete, setColumnDelete,workspaceTeams,setGroupDelete, showModalPricing,
            setModalSpinner,sendNotif, selectedTeams, selectedWorkspace, viewType,setPriority,
            setStatus, setItemExpand, setColumnUpdateStatus, setMoveGroupTable, setMoveItemTable,
            getTable} = useContext(DashboardContext)  

    const modules = {
        toolbar: [ 
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            
        ],
        }

    const formats = [ 
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 
    ]

    useEffect(()=>{
        setListGrid([])
        getWeekly() 
        if(boardUser.length) getMemberList() 
    },[])

    useEffect(()=>{  
        if(isFirstRunProps.current){
            isFirstRunProps.current = false
            return
        } 
       if(boardUser.length) getMemberList()
    },[boardUser])

    const getMemberList = () => {
        let arrTmp = [] 
        boardUser.map(item =>{ 
            if(item !== null){
                let tmp = {value: item.id,type: item.type, label: item.username, email: item.email, notify: false}
                arrTmp.push(tmp)
            }
          
        }) 
        setMemberList(arrTmp) 
    }


    useEffect(()=>{  
        refNumber.current = ""
        refEditable.current = ""
        refProgress.current = ""
        refParagraph.current = "" 
        let grid = _.filter(props.item.rows,(item)=>{
            let tmp =_.filter(item,(val)=>{
                  if(val.isShow !== false ) return val  
            })
            if(tmp.length)
                return tmp
        }) 
        
        currentListGrid.current = grid
        currentListId.current = props.item.id
        setListGrid(grid) 
        setListGridForFilter(props.item.rows)   
    },[props.item])

    useEffect(()=>{ 
        if(isFirstRun.current){
            isFirstRun.current = false
            return
        }  
        //=======MAPPING COLUMN ALREADY EXIST IN LIST========/// 
        if(listGrid.length > 0 ){
            let tmpObj = []
            listGrid[0].map((item,index)  => { 
                if(index === 1) return
                tmpObj[item.type] = item.name 
            })  
            setColumnMapping(tmpObj)
        }     
    },[listGrid])
  
    const getWeekly = () =>{
        let weeks = moment().isoWeeksInYear()
        let arrWeeks = []
        for(let week =1 ; week <= weeks; week++){
            let obj = {}
            if(week.length === 1) week = `0${week}`
            obj.value = week
            obj.selected = false
            arrWeeks.push(obj)
        }
        setWeekArr(arrWeeks)
    }

    const handleDeleteRow = (colIndex) => {  
        let itemId = "" 
        let newList =_.filter(listGrid, (item, index) =>{
            if(index === colIndex){
                item.map((val,idx)=>{
                    itemId = item[1].id
                })
            } 

            return index !== colIndex
        })  
        currentListGrid.current = newList 
        ref.click()     
        setItemDelete({id: props.item.id, itemId, colIndex, user_id: params.id, refresh: true})  
    }

    const handleDeleteGroup = () =>{
        ref.click()   
        setListGrid([]) 
        setGroupDelete({id: props.item.id, refresh: true}) 
    }

    const handleExport = (rowIndex) => {  
        let dataExcel = [[boardActive.title]]
        let wscols = []
        listGrid.map((item,key)=>{
            let tmp = [] 
            item.map((value, valueKey) => {
                let valueName = ""
                if(typeof(value.name) === 'string') valueName = value.name
                if((valueKey > 0 ) && (valueKey < (item.length-1))){
                    let objTmp = {} 
                    if(Array.isArray(value.name)){
                        let separator = ""
                        value.name.map(val=>{ 
                            valueName += separator + val.label
                            separator = ","
                        })
                        
                    }else if(typeof(value.name) === 'object') valueName =  value.name.name
                    tmp.push(stripHtml(valueName.replace(/\&nbsp;/g, ' ')))  
                    objTmp.wch = valueName.length + 10 
                    if(key === 1)
                        wscols.push(objTmp)
                } 
            })
        dataExcel.push(tmp)
        })   
        exportExcel({data: dataExcel, name: boardActive.title.replace(/ /g, '_'), wscols: wscols})
        ref.click() 
    }

    const handleExportTemplate = () =>{
        let dataExcel = []
        let wscols = []
        listGrid.map((item,key)=>{ 
            if(key !== 0) return
            let tmp = [] 
            item.map((value, valueKey) => {
                let  valueName = value.name
                if(valueKey === 1) valueName = "Item Task"
                if((valueKey > 0) && (valueKey < (item.length-1))){
                    let objTmp = {}   
                    tmp.push(stripHtml(valueName.replace(/\&nbsp;/g, ' ')))  
                    objTmp.wch = valueName.length + 10 
                    if(key === 1)
                        wscols.push(objTmp)
                } 
            })
            dataExcel.push(tmp)
        })   
        let statusData = [["name"]]
        status.map(item => {
             let tmp = [] 
             tmp.push(item.name)
             statusData.push(tmp)
        }) 

        let priorityData = [["name"]]
        priority.map(item => {
             let tmp = [] 
             tmp.push(item.name)
             priorityData.push(tmp)
        }) 

        let memberData = [["name","email"]]
        memberList.map(item => {
             let tmp = [] 
             tmp.push(item.label)
             tmp.push(item.email)
             memberData.push(tmp)
        })  

        exportTemplate({data: dataExcel,statusData,priorityData,memberData, name: boardActive.title.replace(/ /g, '_'), wscols: wscols})
        ref.click() 
    }

    const handleImportItem = () => {
        ref.click() 
        fileImport.click()
    }

    const handleFileSelectedImport = async(event) =>{  
        let file = event.target.files[0]
        const reader = new FileReader()
        setModalSpinner(true)
        reader.onload = (evt) =>  {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, {type:'binary'});
            /* Get first worksheet */ 
            const wsname = wb.SheetNames[0]; 
            if(wsname !== "data") {
                setModalSpinner(false)
                NotificationManager.error("File import invalid.") 
                return
            }
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */  
            let oJS=  XLSX.utils.sheet_to_json(ws, {defval:""})
 
            handleImport(oJS)
        }
        reader.readAsBinaryString(file) 
        fileImport.value = ""
        
    }

    const handleImport = (data) => { 

        let key = []  
        let dataImport = []
        data.map((item, key) =>{ 
            item[0] = "option"
            let obj =  Object.entries(item).map(([key, value]) =>{
                return value
            }) 
            dataImport.push(obj)  
        })    
        if(params.team && params.team.subscriber === null){
            showModalPricing(true)
            setModalSpinner(false)
            return
        }
        let listGridTmp = listGrid 
        //==========Clonse first object========== 
        if(!dataImport.length || (dataImport[0].length >= listGrid[0].length)){
            NotificationManager.error("Column length invalid.") 
            setModalSpinner(false)
            return
        }
        let tmpList = [], isError = false   
        let isFirstSubItem = true , indexParent = "", lastSubItems = []  
        let parentId = ""
        dataImport.some((data, index) =>{
            let newList = _.cloneDeep(listGrid[0], true)  
            data.some((item, key)=> {   
                if(item === "") {
                    newList[key].name = item
                    newList[key].id = uuid()
                    return
                } 
                 
                if(newList[key].type === "person"){ 
                    let person = _.filter(memberList, (val) => {   
                        return val.email === item
                    }) 
                    if(person.length === 0) {
                        isError = errorImport(`Email ${item} not found in the member boards.`)
                        return true
                    }
                   
                    item = person.length ? person : ""
                }
                if(newList[key].type === "status"){ 
                    let statusData = _.filter(status, (val) => {  
                        return val.name === item
                    })
                    if(statusData.length === 0) {
                        isError = errorImport(`${newList[key].name} ${item} invalid.`)
                        return true
                    }
                    item = statusData.length ? statusData[0] : ""
                }
                if(newList[key].type === "priority"){ 
                    let priorityData = _.filter(priority, (val) => {  
                        return val.name === item
                    })
                    if(priorityData.length === 0) {
                        isError = errorImport(`${newList[key].name} ${item} invalid.`)
                        return true
                    }
                    item = priorityData.length ? priorityData[0] : "" 
                }
                if(newList[key].type === "duedate" || newList[key].type === "date") {
                    let value = moment(ExcelDateToJSDate(item)).format('DD MMM YYYY')
                    if(value.toString().includes("Invalid")){
                        isError = errorImport(`${newList[key].name} ${item} invalid.`)
                        return true
                    }
                    item = value

                }
                if(newList[key].type === "progress" || newList[key].type === "rating" || newList[key].type === "number"  || newList[key].type === "week") {
                    if(typeof(item) !== "number"){
                        isError = errorImport(`${newList[key].name} ${item} invalid.`)
                        return true
                    }
                    
                }

                if(newList[key].type === "timeline_duedate" || newList[key].type === "timeline"){
                    // item = moment(ExcelDateToJSDate(43840)).format('DD MMM YYYY')
                    let splitDate = item.split("-") 
                    if(splitDate.length === 2) {
                        let start = splitDate[0]
                        let end = splitDate[1]
                       
                        let timelineStart = moment(start, 'DD/MM/YYYY').toDate() 
                        let timelineEnd = moment(end, 'DD/MM/YYYY').toDate()  
                        if(timelineStart.toString().includes("Invalid") || timelineEnd.toString().includes("Invalid")) {
                            isError = errorImport(`${newList[key].name} ${item} invalid.`)
                            return true
                        }
                        else item = moment(timelineStart).format('DD MMM YYYY') + " - " +  moment(timelineEnd).format('DD MMM YYYY') 
                    }else {
                        isError = errorImport(`${newList[key].name} ${item} invalid.`)
                        return true
                    }
                } 
                if(newList[key].type === "week")  item = "Week " + item
                

                if(newList[key].type === "subitemscontroll"){  
                    if(item === "") return  
                    if(newList[key-1].name !== ""){
                        isError = errorImport(`${newList[key].name} ${item} invalid.`)
                        return true
                    }
                    let arr = []   
                    if(isFirstSubItem) {    
                        let tmp = {name:"",  type: "subitems"} 
                        arr.push(tmp)
                        tmp = {name:"Sub Items",  type: "subitems"} 
                        arr.push(tmp)
                        tmpList.push(arr)  
                        isFirstSubItem = false 
                    }       
                      
                    newList[key-1].name = item 
                     
                    
                    if(!dataImport[index+1] || dataImport[index+1][key] === "" ){ 
                        let tmp = {name:"",  type: "subitems"} 
                        lastSubItems.push(tmp)
                        tmp = {name:"Add",  type: "addsubitems"} 
                        lastSubItems.push(tmp)   
                        isFirstSubItem = true  
                    } 
                    return 
                }   
                newList[key].name = item
                newList[key].id = uuid()
                
               
            })   
            if(isError) return true
            tmpList.push(newList) 
            if(lastSubItems.length){ 
                tmpList.push(lastSubItems)
                lastSubItems = []
            }
        })    
        if(!isError){
            let list = listGridTmp.concat(tmpList) 
            let idParent = "", countChild = 0, indexParent = 0
            tmpList.map((item, index) => { 
                item.map((task, idx)=> { 
                    if(task.type === "subitems" && idx > 0){  
                        idParent = tmpList[index-1][1].id 
                        task.parent = idParent 
                        indexParent = index-1 
                        item[0].parent = idParent
                    }
                     
                    if(idParent !== ""){
                        task.parent = idParent 
                    }
                    if(task.type === "person"){
                        if(task.name === "") return
                        task.name.map(person => {
                            let param = {value: person.value,itemName: item[1].name,itemId:task.id, groupName: list[0][1].name, rowId: task.id}
                            sendNotificationItem(param)
                        })
                    }
                    if(task.type === "creation_log"){
                        task.name = params.username
                    }
                    if(task.type === "last_update"){
                        task.name = ""
                    }
                    if(task.type === "add"){
                        task.created_by = params.username
                        task.updated_by = ""
                        task.last_update = ""
                    }
                    
                    if(task.type === "addsubitems"){
                        tmpList[indexParent][2].hashchild = true 
                        tmpList[indexParent][2].countchild = (index - indexParent) -2 
                        idParent = ""
                        countChild = 0
                        indexParent = 0
                    } 
                }) 
            })  
            currentListGrid.current = list
            let debouncer = setTimeout(() => { 
                setItemUpdate({id: props.item.id, rows: list, refresh: true}) 
                 
              }, 1000);
              return () => {
                clearTimeout(debouncer);
              } 
        }
       
    }

    const errorImport = (msg) =>{
        NotificationManager.error(msg) 
        setModalSpinner(false)
        return true
    }

    const handleDeleteCol = (name, columnIndex) => { 
        ref.click()    
        setColumnDelete({id: props.item.id, colIndex: columnIndex, user_id: params.id, refresh : true, board_id : boardActive.id}) 
      }

    const handleAddColumn = (type) => { 
        ref.click()
        setColumnAdd({board_id: boardActive.id, type: type, user_id : params.id})
        
    }
 
    const handelChangeEditable = (value) => {
        refEditable.current = value.replace(/\&nbsp;/g, ' ')   
    }

    const handelChangeNumber = (value) => {   
        if(value !== " "){
            value = value.replace(/\&nbsp;/g, '')
            value = value.trim()  
            refNumber.current = parseFloat(value) 
        }
    }

    const handelChangeProgress = (value) => {
        refProgress.current = value.replace(/\&nbsp;/g, ' ')   
    }

    const handelChangeParagraph = (value) => { 
        refParagraph.current = value.replace(/\&nbsp;/g, ' ')   
    }

    const handleEditItem = (colIndex, rowIndex, itemId) =>{    
        if(rowIndex === 0 && colIndex !== 1){
            table.map((tab, idx) => { 
                asyncRequest(100).then(()=>{
                    doHandleEditItem(colIndex, rowIndex, itemId, tab.id, idx)
                    if(idx === table.length - 1) refEditable.current = ""
                })
            }) 
        }else {
            doHandleEditItem(colIndex, rowIndex, itemId, currentListId.current, table.length)
            refEditable.current = ""
        }
        
    }

    const doHandleEditItem = (colIndex, rowIndex, itemId, id, idx) =>{   
        let name = refEditable.current.trim()  
        if(name !== ""){    
           let groupName = "", columnName = "", itemName = "", oldStatus = "", rowId = ""
            let list =  currentListGrid.current 
            let listTmp = _.filter(list, (item, index)=>{   
               if(index === 0) { 
                   groupName = item[1].name  
                   columnName =  item[colIndex].name  
               }
               if(!item[colIndex]) return item
               if(item[parseInt(colIndex)].id === itemId && (rowIndex > 0 ? index > 0 : index  === 0)){ 
                   
                
                    oldStatus = item[colIndex].name
                    item[colIndex].name = name 
                    itemName = item[1].name  
                    rowId = item[1].id  
                    item.map(child => {
                        if(child.type === "person"){ 
                            if(child.name === "" || rowIndex === 0 || typeof(child.name) !== 'object') return
                            child.name.map(person => {
                                let param = {id:itemId, groupName,value: person.value,columnName,itemName,oldStatus, name: name, rowId}
                                sendNotification(param) 
                            })
                        }
                    })

                    // Notif to created task
                    let createdBy = _.filter(item, (dataItem)=> {return dataItem.type === "add" })
                    if(createdBy.length && createdBy[0].created_id){
                        let param = {id:itemId, groupName,value: createdBy[0].created_id,columnName,itemName,oldStatus, name: name, rowId}
                        sendNotification(param) 
                    }
               } 
               return item
            })          
           currentListGrid.current = listTmp
           setListGrid(listTmp)
           setColumnUpdate({id: id, colIndex, rowIndex, name, user_id: params.id, itemId, username: params.username, refresh : idx === table.length - 1 ? true : false})  
        } 
    }

    const handleEditItemNumber = (colIndex, rowIndex, itemId) =>{   
        let name = refNumber.current  
        if(name !== ""){ 
            let  groupName = "", columnName = "", itemName = "", oldStatus = "", rowId = ""
            let listTmp = _.filter(listGrid, (item, index)=>{ 
            
                if(index === 0) { 
                    groupName = item[1].name  
                    columnName =     item[colIndex].name  
                }
                if(!item[colIndex]) return item
                if(item[parseInt(colIndex)].id === itemId && (rowIndex > 0 ? index > 0 : index  === 0)){ 
                // if(index === rowIndex){ 
                    oldStatus = item[colIndex].name
                    item[colIndex].name = name
                    // itemId = item[colIndex].id
                    itemName = item[1].name  
                    rowId = item[1].id  
                    item.map(child => {
                        if(child.type === "person"){
                            if(child.name === "" || rowIndex === 0 || typeof(child.name) !== 'object') return
                            child.name.map(person => {
                                let param = {id:itemId, groupName,value: person.value,columnName,itemName,oldStatus, name: name, rowId}
                                sendNotification(param) 
                            })
                        }
                    })

                    let createdBy = _.filter(item, (dataItem)=> {return dataItem.type === "add" })
                    if(createdBy.length && createdBy[0].created_id){
                        let param = {id:itemId, groupName,value: createdBy[0].created_id,columnName,itemName,oldStatus, name: name, rowId}
                        sendNotification(param) 
                    }
                } 
                return item
             })     
           currentListGrid.current = listTmp
           setListGrid(listTmp) 
           setColumnUpdate({id: props.item.id, colIndex, rowIndex, name, user_id: params.id, itemId})  
        }
    }
 

    const handleEditItemProgress = (colIndex, rowIndex, itemId) =>{  
        if(refProgress.current !== ""){ 
            if(refProgress.current > 100){ 
                NotificationManager.error("Max value for progress is 100","Failed") 
                return
            }
            let name = refProgress.current.trim()
           
           let  groupName = "", columnName = "", itemName = "", oldStatus = "", rowId =""
           
           let listTmp = _.filter(listGrid, (item, index)=>{ 
              
               if(index === 0) { 
                   groupName = item[1].name  
                   columnName =  item[colIndex].name  
               }
               if(!item[colIndex]) return item
               if(item[parseInt(colIndex)].id === itemId && (rowIndex > 0 ? index > 0 : index  === 0)){ 
            //    if(index === rowIndex){ 
                   oldStatus = item[colIndex].name + "%"
                   item[colIndex].name = name
                //    itemId = item[colIndex].id
                   itemName = item[1].name  
                   rowId = item[1].id  
                   item.map(child => {
                       if(child.type === "person"){
                            if(child.name === "" || rowIndex === 0 || typeof(child.name) !== 'object') return
                           child.name.map(person => {
                               let param = {id:itemId, groupName,value: person.value,columnName,itemName,oldStatus, name: name+ "%", rowId }
                               sendNotification(param) 
                           })
                       }
                   })

                   let createdBy = _.filter(item, (dataItem)=> {return dataItem.type === "add" })
                   if(createdBy.length && createdBy[0].created_id){
                       let param = {id:itemId, groupName,value: createdBy[0].created_id,columnName,itemName,oldStatus, name: name+ "%", rowId}
                       sendNotification(param) 
                   }
               } 
               return item
            })     
           currentListGrid.current = listTmp
           setListGrid(listTmp) 
           setColumnUpdate({id: props.item.id, colIndex, rowIndex, name, user_id: params.id, itemId})  
        }
    }

    const handleEditItemParagraph = (colIndex, rowIndex, itemId) =>{  
        if(refParagraph.current !== ""){ 
            let name =  refParagraph.current.trim()
            let groupName = "", columnName = "", itemName = "", oldStatus = "", rowId
            let listTmp = _.filter(listGrid, (item, index)=>{ 
            
                if(index === 0) { 
                    groupName = item[1].name  
                    columnName =  item[colIndex].name  
                }
                if(!item[colIndex]) return item
                if(item[parseInt(colIndex)].id === itemId && (rowIndex > 0 ? index > 0 : index  === 0)){ 
                // if(index === rowIndex){ 
                    oldStatus = item[colIndex].name
                    item[colIndex].name = name
                    // itemId = item[colIndex].id
                    itemName = item[1].name  
                    rowId = item[1].id  
                    item.map(child => {
                        if(child.type === "person"){
                            if(child.name === "" || rowIndex === 0 || typeof(child.name) !== 'object') return
                            child.name.map(person => {
                                let param = {id:itemId, groupName,value: person.value,columnName,itemName,oldStatus, name: name, rowId}
                                sendNotification(param) 
                            })
                        }
                    })

                    let createdBy = _.filter(item, (dataItem)=> {return dataItem.type === "add" })
                    if(createdBy.length && createdBy[0].created_id){
                        let param = {id:itemId, groupName,value: createdBy[0].created_id,columnName,itemName,oldStatus, name: name, rowId}
                        sendNotification(param) 
                    }
                } 
                return item
             })      
           currentListGrid.current = listTmp
           setListGrid(listTmp) 
           setColumnUpdate({id: props.item.id, colIndex, rowIndex, name, user_id: params.id, itemId})  
        }
    }


    const handleSelectedOption = (itemId, rowIndex, colIndex , value, fromTimeLine = false) =>{  
        let groupName = "", columnName = "", itemName = "", oldStatus = "", rowId 
        let listTmp = _.filter(listGrid, (item, index)=>{ 
            
            if(index === 0) { 
                groupName = item[1].name  
                columnName =  item[colIndex].name  
            }
            if(!item[colIndex]) return item
            if(item[parseInt(colIndex)].id === itemId && (rowIndex > 0 ? index > 0 : index  === 0)){ 
            // if(index === rowIndex){ 
                oldStatus = item[colIndex].name 
                item[colIndex].name = value
                itemId = item[colIndex].id
                itemName = item[1].name  
                rowId = item[1].id  
                item.map(child => {
                    if(child.type === "person"){
                        if(child.name === "" || rowIndex === 0 || typeof(child.name) !== 'object') return
                        child.name.map(person => { 
                            if(fromTimeLine && !value.includes("-")) return
                            let param = {id:value, fromTimeLine : fromTimeLine,  groupName,value: person.value,columnName,itemName,oldStatus, name: value, rowId }
                            sendNotification(param) 
                        })
                    }
                })
                let createdBy = _.filter(item, (dataItem)=> {return dataItem.type === "add" })
                if(createdBy.length && createdBy[0].created_id){
                    let param = {id:itemId, groupName,value: createdBy[0].created_id,columnName,itemName,oldStatus, name: value, rowId}
                    sendNotification(param) 
                }
            } 
            return item
        })      

        if(!fromTimeLine){ 
            if(ref.current !== null){
                ref.click()
            }
        }
        let name = value.trim()
        currentListGrid.current = listTmp
        setListGrid(listTmp)  
        setColumnUpdate({id: props.item.id, colIndex, rowIndex, name,itemId, user_id: params.id})
    }

    const handleSelectedPriority = (rowIndex,colIndex, value, itemId) =>{  
        let groupName = "", columnName = "", itemName = "", oldStatus = "", rowId
        
        let listTmp = _.filter(listGrid, (item, index)=>{  
           
            if(index === 0) { 
                groupName = item[1].name  
                columnName =  item[colIndex].name  
            } 
            if(!item[colIndex]) return item
            if(item[parseInt(colIndex)].id === itemId && (rowIndex > 0 ? index > 0 : index  === 0)){ 
            // if(index === rowIndex){ 
                oldStatus = item[colIndex].name.name
                item[colIndex].name = value
                itemId = item[colIndex].id
                itemName = item[1].name  
                rowId = item[1].id  
                item.map(child => {
                    if(child.type === "person"){
                        if(child.name === "" || rowIndex === 0 || typeof(child.name) !== 'object')  return
                        child.name.map(person => {
                            let param = {id:value.id, groupName,value: person.value,columnName,itemName,oldStatus, name: value.name, rowId }
                            sendNotification(param) 
                        })
                    }
                })
                let createdBy = _.filter(item, (dataItem)=> {return dataItem.type === "add" })
                if(createdBy.length && createdBy[0].created_id){
                    let param = {id:itemId, groupName,value: createdBy[0].created_id,columnName,itemName,oldStatus, name: value.name, rowId}
                    sendNotification(param) 
                }
            } 
            return item
        })      
        ref.click()
        let name = value
        setListGrid(listTmp)   
        currentListId.current = listTmp
        setColumnUpdate({id: props.item.id, colIndex, rowIndex, name,itemId, user_id: params.id, refresh:true, itemId})
         
 
    }

    const sendNotification = (param) => {

        let message  =  `${params.username} has been Updated ${param.columnName} "${param.itemName.trim()}" from "${param.oldStatus ? param.oldStatus : ""}" to "${param.name}"`
        if(param.fromTimeLine)  message = `${params.username} has been Updated ${param.columnName} "${param.itemName.trim()}"  to "${param.name}"`
        if(param.value !== params.id){
            let data = {
                receiver_id : param.value,
                sender_id: params.id,
                board_id : boardActive.id,
                workspace_id : boardActive.workspace_id ,
                team_id: boardActive.team_id,
                message : message,
                task_name: param.itemName.trim(),
                is_read: false,
                group_id: props.item.id,
                item_id: param.id,
                group_name: param.groupName,
                created_at: new Date(),
                updated_at: new Date(),
                row_id: param.rowId,
                type: 4}
             sendNotif(data)
         }else {
            workspaceTeams.map(item =>{ 
                if(item.permission.id < 4){
                    let data = {
                        receiver_id : item.user_id,
                        sender_id: params.id,
                        board_id : boardActive.id,
                        workspace_id : boardActive.workspace_id ,
                        team_id: boardActive.team_id,
                        message :message,
                        task_name: param.itemName.trim(),
                        is_read: false,
                        group_id: props.item.id,
                        item_id: param.id,
                        group_name: param.groupName,
                        created_at: new Date(),
                        updated_at: new Date(),
                        row_id: param.rowId,
                        type: 4}
                     sendNotif(data)
                }
            })
         }
    }

    const handleEnterItem  = (event, colIndex, rowIndex, id) => {
        var keyCode = event.which || event.keyCode;
        if(keyCode === 13) {  
            handleEditItem(colIndex, rowIndex, id)
        }
    }

    const handleEnterItemNumber  = (event, colIndex, rowIndex, id) => {
        var keyCode = event.which || event.keyCode;
        if(keyCode === 13) {  
            handleEditItemNumber(colIndex, rowIndex, id)
        }
    }

    const handleRemoveMember = (selected, rowIndex, columnIndex, itemId) =>{ 
        let name = [],nameInsert = ""
        let itemName = "", groupName = "", rowId = ""
        let listTmp = _.filter(listGrid, (item, index)=>{ 
            name = selected === null ? [] : selected
            if(index === 0) { 
                groupName = item[1].name   
            }
            if(!item[columnIndex]) return item
            if(item[parseInt(columnIndex)].id === itemId && (rowIndex > 0 ? index > 0 : index  === 0)){
            // if(index === rowIndex){ 
                itemName = item[1].name 
                rowId = item[1].id    
                let newFilter = _.filter(item[columnIndex].name, (data) => {
                    return data.value !== selected.value
                })
                item[columnIndex].name = newFilter 
                nameInsert = newFilter
            } 
            return item
        })      
        setListGrid(listTmp)   
        currentListGrid.current = listTmp   
        setColumnUpdate({id: props.item.id, colIndex : columnIndex, rowIndex, name: nameInsert, user_id: params.id, itemId, refresh: true}) 
        nameInsert && nameInsert.map(item=> {
            let param = {value: item.value,itemName,itemId: rowId,groupName, rowId: itemId}
            sendNotificationItem(param)
        })
        
    }

    const handleChangeMember = (selected, rowIndex, columnIndex, itemId) =>{ 
        let name = [], nameInsert = ""
        let itemName = "", groupName = "", rowId = ""
        let listTmp = _.filter(listGrid, (item, index)=>{ 
            name = selected === null ? [] : selected
            if(index === 0) { 
                groupName = item[1].name   
            }
            if(!item[columnIndex]) return item 
            if(item[parseInt(columnIndex)].id === itemId && (rowIndex > 0 ? index > 0 : index  === 0)){
            // if(index === rowIndex){ 
                itemName = item[1].name 
                rowId = item[1].id  
                if(item[columnIndex].name === "") item[columnIndex].name = [] 
                item[columnIndex].name.push(name[0])  
                nameInsert = item[columnIndex].name
            } 
            return item
        })      
        setListGrid(listTmp)  
        currentListGrid.current = listTmp    
        setColumnUpdate({id: props.item.id, colIndex : columnIndex, rowIndex, name:nameInsert, user_id: params.id, itemId, refresh: true}) 
        selected && selected.map(item=> {
            let param = {value: item.value,itemName,itemId: rowId,groupName, rowId: itemId}
            sendNotificationItem(param)
        })
        
        
    }

    const handleChangeDepedency = (selected,rowIndex, columnIndex, itemId) => {
        let name = []  
        let listTmp = _.filter(listGrid, (item, index)=>{ 
            name = selected === null ? [] : selected 
            if(!item[columnIndex]) return item
            if(item[parseInt(columnIndex)].id === itemId && (rowIndex > 0 ? index > 0 : index  === 0)){   
                item[columnIndex].name = name
            } 
            return item
        })       
        setListGrid(listTmp)    
        currentListGrid.current = listTmp  
        setColumnUpdate({id: props.item.id, colIndex : columnIndex, rowIndex, name, user_id: params.id, itemId, refresh: true}) 
        // selected && selected.map(item=> {
        //     let param = {value: item.value,itemName,itemId: rowId,groupName, rowId: itemId}
        //     sendNotificationItem(param)
        // })
    }



    const sendNotificationItem = (param) =>{
        let data = {
            receiver_id : param.value,
            sender_id: params.id,
            board_id : boardActive.id,
            workspace_id : boardActive.workspace_id ,
            team_id: boardActive.team_id,
            task_name: param.itemName,
            message : `${params.username} has been assigned you to the item  "${param.itemName}" at ${boardActive.team.name} Team.`,
            is_read: false,
            group_id: props.item.id,
            item_id: param.itemId,
            group_name: param.groupName,
            created_at: new Date(),
            updated_at: new Date(),
            row_id: param.rowId,
            type: 1}  
        sendNotif(data) 
    }

    const handleClickWeekly = (items,rowIndex, columnIndex, id) =>{
        let weeksTmp = arrWeeks
        _.map(weeksTmp,(item)=> {
            item.selected = false
            if(item.value === items.value) item.selected = true 
        })
        handleSelectedOption(id, rowIndex, columnIndex,`Week ${items.value}`)
        setSelectedWeek(items.value)
        setWeekArr(weeksTmp) 
    }
     
    const onChangeTimeline = (rowIndex, columnIndex, dates, timelineStart, timelineEnd, itemId) => {
        refTimeLine.current = false
        const [start, end] = dates
        timelineStart = start
        timelineEnd = end
        setStartDateTimeline(start)
        setEndDateTimeline(end)
        let date = `${moment(start).format('DD MMM YYYY')} - ${moment(end).format('DD MMM YYYY')}`
        if(date.includes("Invalid date") > 0) date = moment(start).format('DD MMM YYYY')
        handleSelectedOption(itemId, rowIndex, columnIndex, date, true)
    }

    const onChangeTimelineDueDate = (rowIndex, columnIndex, dates, timelineStart, timelineEnd, itemId) => {
        refTimeLineDueDate.current = false
        const [start, end] = dates
        timelineStart = start
        timelineEnd = end
        setStartDateTimelineDueDate(start)
        setEndDateTimelineDueDate(end)
        let date = `${moment(start).format('DD MMM YYYY')} - ${moment(end).format('DD MMM YYYY')}`
        if(date.includes("Invalid date") > 0) date = moment(start).format('DD MMM YYYY')
        handleSelectedOption(itemId, rowIndex, columnIndex, date, true) 
    }
    
    const handleUploadFile = (rowIndex) =>{ 
        fileUploader.current[rowIndex].click()
    }

    const handleFileSelected = async(event, columnIndex, rowIndex, itemId) =>{  
        const formData = new FormData() 
        formData.append('user_id', params.id) 
        formData.append('file', event.target.files[0])  
        let result = await uploadFile(formData) 
        let name = []
        if(result.status === 200) {
            let listTmp = _.filter(listGrid, (item, index)=>{
                if(!item[columnIndex]) return item
                if(item[parseInt(columnIndex)].id === itemId && (rowIndex > 0 ? index > 0 : index  === 0)){
                // if(index === rowIndex){
                    name = [...item[columnIndex].name,{id: result.data.id, name: result.data.filename}]
                    item[columnIndex].name = name 
                } 
                return item
           })  
           currentListGrid.current = listTmp   
           setListGrid(listTmp) 
           setColumnUpdate({id: props.item.id, colIndex : columnIndex, rowIndex, name, user_id: params.id, itemId})  
        }
    }

    const handleDeleteFile = async(id, columnIndex, rowIndex,itemId) =>{  
        let result = await deleteFile({id: id}) 
        let name = []
        if(result.status === 200) {
            let listTmp = _.filter(listGrid, (item, index)=>{
                if(!item[columnIndex]) return item
                if(item[parseInt(columnIndex)].id === itemId && (rowIndex > 0 ? index > 0 : index  === 0)){
                // if(index === rowIndex){
                    let listFile = _.filter(item[columnIndex].name, (file)=>{
                        return file.id !== id
                    })   
                    name = listFile
                    item[columnIndex].name = name 
                }  
                return item
           })     
           currentListGrid.current = listTmp
           setListGrid(listTmp)  
           setColumnUpdate({id: props.item.id, colIndex : columnIndex, rowIndex, name, user_id: params.id, itemId})
        }
    }

    const downloadFileAction = async(item) =>{  
        let result = await downloadFile(item.id) 
        const url = window.URL.createObjectURL(new Blob([result],{ type: "application/pdf", }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', item.name)
        document.body.appendChild(link)
        link.click()
    } 

    const popoverPeople = (rowIndex, columnIndex, name, id) => {
        if(name === "") name = []
        const newMember = _.cloneDeep(memberList)
        let tmpMember = []
        _.map(newMember,(item) =>{ 
            let isExist = _.some(name, function(i) {  
                return i.value == item.value
            })
            if(!isExist && item.type !== "Pending Invitation")
            tmpMember.push(item)
        }) 

        return (
            <Popover id="popover-basic">  
                <Popover.Content>
                    <div className="pt-3 pb-2">
                        <Select styles={StyleSelect({menuListHeight: 150, width: 220})} 
                        placeholder ="Select People" maxMenuHeight={150} options={tmpMember}
                        isMulti onChange={(selected)=>{handleChangeMember(selected,rowIndex, columnIndex, id)}}
                        value={[]}
                        /> 
                    </div>

                    {name && name.map((item, key) => { 
                            return(
                                <Nav.Link className="nav-menu p-2 w-100 mt-2" key={key}> 
                                    <Row>
                                        <Col md="12">
                                            <Image src={AvatarIcon}></Image>
                                            <span className="workspace-item-name-child align-text-top ml-2 font-14">{item.label}</span>
                                            <Image src={RemoveIcon} className="float-right align-middle mt-1 cursor-pointer" onClick={()=> handleRemoveMember(item, rowIndex, columnIndex, id)}></Image>
                                        </Col>  
                                    </Row> 
                                </Nav.Link> 
                            )
                        })}
                    <div className="spacer pt-2"></div>
                    <Nav.Link className="nav-menu p-2 mt-3 mb-2" onClick={()=>{setShowMember(true); ref.click()}}> 
                        <Image src={AddSmallIcon}></Image>
                        <span className="workspace-item-name-child align-text-top ml-2 font-14 text-primary">Add new member</span>
                    </Nav.Link> 
                    
                </Popover.Content> 
            </Popover>
        ) 
    }

    const popoverDepedency = (id,rowIndex, columnIndex, name, dataDepedency) => { 
        return (
                <Popover id="popover-basic">  
                    <Popover.Content className="mt-2">
                        <Select styles={StyleSelect({menuListHeight: 250, width: 300})} 
                        placeholder ="Select Task" maxMenuHeight={150} options={dataDepedency}
                        isMulti onChange={(selected)=>{handleChangeDepedency(selected,rowIndex, columnIndex, id)}} 
                        value = {name}
                        /> 
                       
                    </Popover.Content> 
                </Popover>
            ) 
        }

    const popoverPriority = (rowIndex, columnIndex, id, listPriority, idHeader) => {
        return (
            <Popover id="popover-basic"> 
                <Popover.Content className="mt-2">
                    {listPriority && listPriority.map((item,key)=>{
                        return(
                            <div key={key} className="status-picker" style={{background: item.color}} onClick={()=>handleSelectedPriority(rowIndex, columnIndex, item, id)}>
                                <span className="font-14">{stripHtmlAnd(item.name)}</span>
                            </div>
                        )
                    })} 
                <div className="spacer pt-2"></div>  
                 
                    <Nav.Link className="nav-menu p-2 mt-2" onClick={()=>{setShowPriority({isShow: true, data: listPriority, id, idHeader}); ref.click()}}> 
                        <Image src={AddSmallIcon}></Image>
                        <span className="workspace-item-name-child align-text-top ml-2 font-13 text-primary">Add/Edit Labels</span>
                    </Nav.Link> 
                </Popover.Content>
            </Popover>
        )
    }

    const popoverStatus = (rowIndex, columnIndex, id, listStatus, idHeader) => {
       if(!listStatus) return
        return (
            <Popover id="popover-basic"> 
                <Popover.Content className="mt-2"> 
                <Row style={{width: listStatus.length > 5 ? 500 : 165}}>
                    {listStatus && listStatus.map((item,key)=>{
                        return(
                            <Col key={key} md={listStatus.length > 5 ? 4 : 12}>
                                <div key={key} className="status-picker" style={{background: item.color}} onClick={()=>handleSelectedPriority(rowIndex, columnIndex, item, id)}>
                                    <span className="font-14 span-label-popover ">{stripHtmlAnd(item.name)}</span>
                                </div>
                            </Col>
                        )
                    })} 
                </Row>

                <div className="spacer pt-2"></div>   
                    <Nav.Link className="nav-menu p-2 mt-2" onClick={()=>{setShowStatus({isShow: true, data: listStatus, id, idHeader}); ref.click()}}> 
                        <Image src={AddSmallIcon}></Image>
                        <span className="workspace-item-name-child align-text-top ml-2 font-13 text-primary">Add/Edit Labels</span>
                    </Nav.Link> 
                </Popover.Content>
            </Popover>
        )
    }

    const popoverDate = (rowIndex, columnIndex, id) => {
        return (
            <Popover id="popover-basic"> 
                <Popover.Content className="mt-2">
                    <DatePicker selected={strDate} onChange={(date)=>handleSelectedOption(id, rowIndex, columnIndex, moment(date).format('DD MMM YYYY'))} inline/>
                </Popover.Content>
                
            </Popover>
        )
    }

    const popoverDueDate = (rowIndex, columnIndex, id) => {
        return (
            <Popover id="popover-basic"> 
                <Popover.Content className="mt-2"> 
                    <DatePicker 
                    selected={strDueDate}
                    onChange={(date)=>handleSelectedOption(id, rowIndex, columnIndex, moment(date).format('DD MMM YYYY'))} inline/>
                </Popover.Content>
                
            </Popover>
        )
    }

    const popoverCreationLog = (name) => {
        return (
            <Popover id="popover-basic"> 
                <Popover.Content className="mt-2">
                    Created by {name}
                </Popover.Content>
                
            </Popover>
        )
    }
    
    const popoverLastUpdate = (rowIndex, columnIndex) => {
        return (
            <Popover id="popover-basic"> 
                <Popover.Content className="mt-2">
                    Update by
                </Popover.Content>
                
            </Popover>
        )
    }

    const popoverProgress = (rowIndex, columnIndex, name, id) => {
        return (
            <Popover id="popover-basic"> 
                <Popover.Content className="mt-2">
                    <Form.Control type="number" name="progress"
                    className="font-14"
                    html= {refProgress.current}  
                    defaultValue={name}
                    onBlur={()=>handleEditItemProgress(columnIndex, rowIndex, id)}
                    onChange={(e)=> {handelChangeProgress(e.target.value)}} 
                    /> 
                </Popover.Content> 
            </Popover>
        )
    }

    const popoverWeek = (rowIndex, columnIndex, name, type, id) => { 
        return (
            <Popover id="popover-basic-week" className="test" style={{width: '275px !important'}}> 
                <Popover.Content className="mt-2"> 
                    <div className="react-datepicker">
                    <button type="button" className="react-datepicker__navigation react-datepicker__navigation--previous" aria-label="Previous Month">Previous Month</button>
                    <button type="button" className="react-datepicker__navigation react-datepicker__navigation--next" aria-label="Next Month">Next Month</button>
                        <div className="react-datepicker__month-container">
                            <div className="react-datepicker__header "> 
                            <div className="react-datepicker__current-month pb-2">
                                2020
                            </div>
                            </div>
                            <div className="react-datepicker__month"> 
                                <Row className="ml-1">
                                    {arrWeeks.map((item, index) => {  
                                        //=========SET DEFAULT SELECTED===========# 
                                        item.selected = false
                                        if((type === "week")  && (item.value === parseInt(name.replace("Week ","")))) item.selected = true
                                        return(
                                            <>   
                                                <div key={item.value} onClick={()=>handleClickWeekly(item, rowIndex, columnIndex, id)} className= {item.selected ? 
                                                    "react-datepicker__day--selected react-datepicker__day react-datepicker__day--001 react-datepicker__day--weekend" : 
                                                    "react-datepicker__day react-datepicker__day--001 react-datepicker__day--weekend"} 
                                                    tabIndex="-1" role="button" aria-disabled="false">
                                                    {item.value}
                                                </div> 
                                            </>
                                        )
                                    })}  
                                </Row>
                            </div>
                            </div>
                        </div>
                </Popover.Content>
                
            </Popover>
        )
    }
    
    const popoverTimeline = (rowIndex, columnIndex,timelineStart, timelineEnd, id) => {
        return (
            <Popover id={startDateTimeline}> 
                <Popover.Content className="mt-2">
                    <DatePicker 
                    selected={refTimeLine.current ? timelineStart : startDateTimeline}
                    startDate={refTimeLine.current ? timelineStart : startDateTimeline}
                    endDate={refTimeLine.current ? timelineEnd : endDateTimeline}
                    onChange={(dates) =>onChangeTimeline(rowIndex, columnIndex, dates, timelineStart, timelineEnd, id)}
                    selectsRange   
                    maxDate={strDate}
                    inline/>

                </Popover.Content>
            </Popover>
        )
    }

    const popoverTimelineDueDate = (rowIndex, columnIndex, timelineStart, timelineEnd, itemId) => {
        return (
            <Popover id={startDateTimelineDueDate}>  
                <Popover.Content className="mt-2">
                    <DatePicker
                    selected={refTimeLineDueDate.current ? timelineStart : startDateTimelineDueDate}
                    startDate={refTimeLineDueDate.current ? timelineStart : startDateTimelineDueDate}
                    endDate={refTimeLineDueDate.current ? timelineEnd : endDateTimelineDueDate}
                    onChange={(dates) =>onChangeTimelineDueDate(rowIndex, columnIndex, dates, timelineStart, timelineEnd, itemId)}
                    selectsRange   
                    minDate={strDate}
                    maxDate={strDueDate}
                    inline/>
                </Popover.Content>
            </Popover>
        ) 
    }

    const moveToGroup = async (keyIndex,rowIndex) =>{
        let tmpTable = _.cloneDeep(table)
        let sourceKey = 0
        //====== GET DATA TO MOVE =====////
        let rowData = ""
        _.map(tmpTable, (item, tableKey)=>{
           if(item.id === props.item.id){
                sourceKey = tableKey
                rowData =  _.filter(item.rows, (row, key) =>{
                    return rowIndex === key
                })  
           } 
        })

        //====== MOVE DATA =====//// 
        tmpTable[keyIndex].rows.push(rowData[0]) 
        setItemUpdate({id: tmpTable[keyIndex].id, rows: tmpTable[keyIndex].rows, refresh: false})  
        //====== DELETE OLD DATA =====//// 
        let tmpRows = _.filter(tmpTable[sourceKey].rows, (item, key)=>{
            return key !== rowIndex
        })  
 
        let debouncer = setTimeout(() => {   
            setItemUpdate({id: tmpTable[sourceKey].id, rows: tmpRows, refresh: true}) 
            currentListGrid.current = tmpRows
            tmpTable[sourceKey].rows = tmpRows
            // setTable([])
            // NotificationManager.success("Successfully moved 1 item.") 
          }, 100);
          return () => {
            clearTimeout(debouncer);
          } 

       
    }

    const popoverMoveGroup= (rowIndex) => {
        return (
            <Popover id="popover-basic">    
                <Popover.Content className="mt-2 mb-2">
                    {table.map((item, key) => {   
                        if(item.id !== props.item.id){
                            return (
                                <Nav.Link key = {key} className={"nav-menu p-2"} onClick={()=>moveToGroup(key,rowIndex)}>  
                                    <span className="workspace-item-name-child align-text-top ml-2 font-13 mr-2">{item.rows[0][1].name}</span>
                                </Nav.Link> 
                            )
                        } 
                    })}
                    
                    
                </Popover.Content>
            </Popover>
        ) 
    }

    const array_move = (data, index, oldIndex)=> {
        if (index >= data.length) {
            var k = index - data.length + 1
            while (k--) {
                data.push(undefined);
            }
        }
        data.splice(index, 0, data.splice(oldIndex, 1)[0])
        return data
    };

    const moveToDown =(index) => {
        ref.click()
        if(index === (listGrid.length -1)) return
        setMoveItemTable({id: props.item.id, type: "down", refresh: true, index}) 
    }

    const moveGroup =  async (type) => {
        ref.click()     
        setMoveGroupTable({id: props.item.id, type, refresh: true}) 
    }

    


    const moveToUp =(index) => { 
        ref.click()
        if(index === 1) return   
        setMoveItemTable({id: props.item.id, type: "up", refresh: true, index}) 
        
    }

    const moveToRight = (colIndex) => {
        ref.click() 
        table.map(tab => {
            tab.rows.map(item => {  
                if(item.length > 2)  
                array_move(item, colIndex+1, colIndex) 
            }) 
            asyncRequest(100).then(()=>{
                setItemUpdate({id: tab.id, rows: tab.rows, refresh: false}) 
            })
          
        })
        setTable([])
        let debouncer = setTimeout(() => {
            setTable(table)
          }, 5);
          return () => {
            clearTimeout(debouncer);
        }  
 
    }

    const moveToLeft = (colIndex) => {
        ref.click()
        table.map(tab => {
            tab.rows.map(item => {  
                if(item.length > 2 && item[colIndex-1] && item[colIndex-1].type !== "subitemscontroll")  
                    array_move(item, colIndex-1, colIndex)  
            }) 
            asyncRequest(100).then(()=>{
                setItemUpdate({id: tab.id, rows: tab.rows, refresh: false}) 
            })
          
        })
        setTable([])
        let debouncer = setTimeout(() => {
            setTable(table)
          }, 5);
          return () => {
            clearTimeout(debouncer);
        }  

         
    }

    const increaseWidth = (type, colIndex) => {
        ref.click()
        let increase = colIndex === 1 ? 50 : 20
        let width = colIndex === 1 ? 370 : 220

        if(type === "subitemscontroll") width = 170
        if(type === "person") width = 200
        if(type === "status") width = 150
        if(type === "priority") width = 150
        if(type === "progress") width = 200
        if(type === "rating") width = 150
        if(type === "paragraph") width = 130
        if(type === "timeline") width = 220
        if(type === "timeline_duedate") width = 220
        if(type === "last_update") width = 200 
        if(type === "creation_log") width = 200  
        if(type === "date") width = 130  
        if(type === "number") width = 130  
        if(type === "week") width = 130   

        table.map(tab => {
            tab.rows.map(item => { 
                item.map((col, index)=> {
                    if(index === colIndex){
                        if(!col.width) col.width = width + increase
                        else col.width = col.width + increase
                    }
                })
            }) 
            asyncRequest(100).then(()=>{
                setItemUpdate({id: tab.id, rows: tab.rows, refresh: false}) 
            })
          
        })
        setTable([])
        let debouncer = setTimeout(() => {
            setTable(table)
          }, 5);
          return () => {
            clearTimeout(debouncer);
        }  
        
    }

    const decreaseWidth = (type, colIndex) => {
        ref.click()
        let decrease = colIndex === 1 ? 50 : 20
        let width = colIndex === 1 ? 370 : 220 

        if(type === "subitemscontroll") width = 170
        if(type === "person") width = 200
        if(type === "status") width = 150
        if(type === "priority") width = 150
        if(type === "progress") width = 200
        if(type === "rating") width = 150
        if(type === "paragraph") width = 130
        if(type === "timeline") width = 220
        if(type === "timeline_duedate") width = 220
        if(type === "last_update") width = 200 
        if(type === "creation_log") width = 200  
        if(type === "date") width = 130  
        if(type === "number") width = 130  
        if(type === "week") width = 130 

        table.map(tab => {
            tab.rows.map(item => { 
                item.map((col, index)=> {
                    if(index === colIndex){
                        if(!col.width) col.width = width - decrease
                        else col.width = col.width - decrease
                    }
                })
            })
            asyncRequest(100).then(()=>{
                setItemUpdate({id: tab.id, rows: tab.rows, refresh: false}) 
            })
        })
        setTable([])
        let debouncer = setTimeout(() => {
            setTable(table)
          }, 5);
          return () => {
            clearTimeout(debouncer);
        }  
      
    }
    
    const popoverEditGroup =  (rowIndex, parent, id) => {
        return (
            <Popover id="popover-basic-option"> 
            {/* For Header Only */}
            {rowIndex < 1?
                <>
                    <Popover.Content className="mt-2">
                        <Nav.Link className={ params.permission_id < 4 || boardActive.permission_id == 1 ? "nav-menu p-2" : "nav-menu p-2 disabled"} onClick={()=>{duplicateGroup(currentListGrid.current); ref.click()}}> 
                            <Image src={DuplicateIcon} ></Image>
                            <span className="workspace-item-name-child align-text-top ml-2 font-13">Duplicate Group</span>
                        </Nav.Link> 
                        <Nav.Link className="nav-menu p-2" onClick={()=>handleExport(rowIndex)}> 
                            <Image src={ExcelIcon} ></Image>
                            <span className="workspace-item-name-child align-text-top ml-2 font-13">Export Excel</span>
                        </Nav.Link> 
                        <Nav.Link className={ params.permission_id < 4 || boardActive.permission_id == 1 ? "nav-menu p-2" : "nav-menu p-2 disabled"} onClick={()=>handleExportTemplate(rowIndex)}> 
                            <Image src={DownloadIcon} ></Image>
                            <span className="workspace-item-name-child align-text-top ml-2 font-13">Download Template</span>
                        </Nav.Link> 
                        <Nav.Link className={ params.permission_id < 4 || boardActive.permission_id == 1 ? "nav-menu p-2" : "nav-menu p-2 disabled"} onClick={()=>handleImportItem(rowIndex)}> 
                            <Image src={UploadIcon} ></Image>
                            <span className="workspace-item-name-child align-text-top ml-2 font-13">Upload Template</span>
                        </Nav.Link> 
                        <Nav.Link className="nav-menu p-2" onClick={()=>moveGroup("up")}> 
                            <Image src={MoveUpIcon} ></Image>
                            <span className="workspace-item-name-child align-text-top ml-2 font-13">Move to Up</span>
                        </Nav.Link>  
                        
                        <Nav.Link className="nav-menu p-2" onClick={()=>moveGroup("down")}> 
                            <Image src={MoveDownIcon} ></Image>
                            <span className="workspace-item-name-child align-text-top ml-2 font-13">Move to Down</span>
                        </Nav.Link>  
                        <div className="spacer pt-1 pl-0 ml-0 pr-0 mr-0"></div> 
                        <Nav.Link className={ params.permission_id < 4 || boardActive.permission_id == 1 ? "nav-menu p-2" : "nav-menu p-2 disabled"} onClick={()=>handleDeleteGroup()}> 
                            <Image src={DeleteIcon} ></Image>
                            <span className="workspace-item-name-child align-text-top ml-2 font-13">Delete Group</span>
                        </Nav.Link>  
                    </Popover.Content>  
                </>
                : 
                <Popover.Content>
                    {!parent ?
                    <>
                    <Nav.Link className={ params.permission_id < 4 || boardActive.permission_id == 1 ? "nav-menu p-2" : "nav-menu p-2 disabled"} onClick={()=>{addSubItem(rowIndex, id); ref.click()}}> 
                        <Image src={SubItemIcon} width={20}></Image>
                        <span className="workspace-item-name-child  align-text-top ml-2 pl-1 font-13">Add Subitem</span>
                    </Nav.Link>  
                     <div className="spacer pt-1 pl-0 ml-0 pr-0 mr-0"></div>  
                    <Nav.Link className={ params.permission_id < 4 || boardActive.permission_id == 1 ? "nav-menu p-2" : "nav-menu p-2 disabled"} onClick={()=>{duplicateItem(rowIndex); ref.click()}}> 
                        <Image src={DuplicateIcon} ></Image>
                            <span className="workspace-item-name-child align-text-top ml-2 font-13">Duplicate Item</span>
                        </Nav.Link> 
                    <OverlayTrigger trigger="click" placement="right" overlay={popoverMoveGroup(rowIndex)} rootClose>
                        <Nav.Link className="nav-menu p-2"> 
                            <Image src={MoveGroupIcon} ></Image>
                            <span className="workspace-item-name-child align-text-top ml-2 font-13">Move to Group</span>
                        </Nav.Link>  
                    </OverlayTrigger>
                    
                    <Nav.Link className="nav-menu p-2" onClick={()=>moveToUp(rowIndex)}> 
                        <Image src={MoveUpIcon} ></Image>
                        <span className="workspace-item-name-child align-text-top ml-2 font-13">Move to Up</span>
                    </Nav.Link>  
                    
                    <Nav.Link className="nav-menu p-2" onClick={()=>moveToDown(rowIndex)}> 
                        <Image src={MoveDownIcon} ></Image>
                        <span className="workspace-item-name-child align-text-top ml-2 font-13">Move to Down</span>
                    </Nav.Link>  
                    <div className="spacer pt-1 pl-0 ml-0 pr-0 mr-0"></div>  
                    </>
                    :null}
                   
                    <Nav.Link className={ params.permission_id < 4 || boardActive.permission_id == 1 ? "nav-menu p-2" : "nav-menu p-2 disabled"} onClick={()=>handleDeleteRow(rowIndex)}> 
                        <Image src={DeleteIcon} ></Image>
                        <span className="workspace-item-name-child align-text-top ml-2 font-13">Delete Item</span>
                    </Nav.Link> 
                    
                </Popover.Content>
                }
            </Popover>
        )
    }

    useEffect(()=>{
        const hanldeClick = (e) => {           
        }

        window.addEventListener('click', hanldeClick)
        return () => window.removeEventListener('click', hanldeClick)
     
    },[])

    const handleDefaultDone = (item, index, id) => {
        let statusList = _.cloneDeep(status)
        statusList.map((stat, key) => {
            stat.isDone = false
            if(key === index) stat.isDone = true
        })
        setColumnUpdateStatus({board_id: boardActive.id, id_header : id, 
            status_list : statusList, refresh: true, user_id:"" }) 

        setStatus(statusList)

    }

    const popoverColumnSetting = (colIndex, name, type, id) => {
        return (
            <Popover id="popover-basic-option"> 
                <Col md="12" className="m-0 p-0">
                    <Popover.Content>
                        <Row className="mt-3 mb-3">
                            <Col><span className="font-14">Status Settings</span></Col>
                        </Row> 
                        <Row className="mt-3 mb-3">
                            <Col><span className="font-14  mb-3">Which label means assignments are done?</span></Col>
                        </Row>
                        <Row className="mt-3 mb-3">
                            <Col>
                                {status.map((item, key) => {
                                    return (
                                        <div key = {key} className={item.isDone ? "colors-button selected" : "colors-button"} onClick= {()=> handleDefaultDone(item, key, id)}style={{background: item.color}}></div>
                                    )
                                })} 
                            </Col> 
                        </Row>
                        <div className="spacer pl-0 ml-0 pr-0 mr-0"></div>  
                        <Row className="mt-2">  
                            <Col>
                            <div>
                                <span className="font-14">Selected</span>
                            </div>
                            <div className="mt-2">
                                {status.map((item, key) => {
                                    if(!item.isDone) return
                                    return (
                                        <div key = {key} className={"colors-button"}style={{background: item.color}}></div>
                                    )
                                })}
                            </div>
                            </Col>
                        </Row>
                    </Popover.Content>
                </Col>
            </Popover>
        )
    }

    const popoverEditColumn = (name, colIndex, type, id,uid) => {  
        return (
            <Popover id="popover-filter"> 
            {colIndex > 1 ? 
                <Popover.Content>
                    {type !== "subitemscontroll"?
                    <> 
                    {type !== "status"? <></>:
                        <>
                         <OverlayTrigger trigger="click" placement="left" overlay={popoverColumnSetting(colIndex, name, type, id,uid)}>
                            <Nav.Link id="span-column-settings" className={ params.permission_id < 4 ? "nav-menu p-2" : "nav-menu p-2 disabled"} onClick={()=> isColumnSettings.current = uid}> 
                                <Image src={SettingIc}></Image>
                                <span className="workspace-item-name-child align-text-top ml-2 font-13">Column Settings</span>
                            </Nav.Link>  
                        </OverlayTrigger>
                          <div className="spacer pt-1 pl-0 ml-0 pr-0 mr-0"></div>  
                        </>
                    }
                  
                    <Nav.Link  className={ params.permission_id < 4 ? "nav-menu p-2" : "nav-menu p-2 disabled"} onClick={()=> increaseWidth(type, colIndex)}> 
                        <Image src={IncreaseIc}></Image>
                        <span className="workspace-item-name-child align-text-top ml-2 font-13">Increase Width</span>
                    </Nav.Link>  
                    <Nav.Link   className={ params.permission_id < 4 ? "nav-menu p-2" : "nav-menu p-2 disabled"} onClick={()=> decreaseWidth(type, colIndex)}> 
                        <Image src={DecreaseIc} ></Image>
                        <span className="workspace-item-name-child align-text-top ml-2 font-13">Decrease Width</span>
                    </Nav.Link>  
                    <Nav.Link hidden={colIndex ===  (listGrid && listGrid[0].length-2) ? true : false} className={ params.permission_id < 4 ? "nav-menu p-2" : "nav-menu p-2 disabled"} onClick={()=> moveToRight(colIndex)}> 
                        <Image src={RightIc}></Image>
                        <span className="workspace-item-name-child align-text-top ml-2 font-13">Move To Right</span>
                    </Nav.Link>  
                    <Nav.Link hidden={colIndex === 2 || (listGrid && listGrid[0][colIndex-1].type === "subitemscontroll") ? true : false} className={ params.permission_id < 4 ? "nav-menu p-2" : "nav-menu p-2 disabled"} onClick={()=> moveToLeft(colIndex)}> 
                        <Image src={LeftIc} ></Image>
                        <span className="workspace-item-name-child align-text-top ml-2 font-13">Move To Left</span>
                    </Nav.Link> 
                    <div className="spacer pt-1 pl-0 ml-0 pr-0 mr-0"></div>  
                    </>:null}

                    <Nav.Link className={ params.permission_id < 4 ? "nav-menu p-2" : "nav-menu p-2 disabled"} onClick={()=> handleDeleteCol(name, colIndex)}> 
                        <Image src={TrashIc}></Image>
                        <span className="workspace-item-name-child align-text-top ml-2 font-13">Delete Column</span>
                    </Nav.Link>  
                </Popover.Content>
            :
            <Popover.Content>
                <Nav.Link className={ params.permission_id < 4 ? "nav-menu p-2" : "nav-menu p-2 disabled"} onClick={()=> increaseWidth(colIndex, colIndex)}> 
                    <Image src={IncreaseIc}></Image>
                    <span className="workspace-item-name-child align-text-top ml-2 font-13">Increase Width</span>
                </Nav.Link>  
                <Nav.Link  className={ params.permission_id < 4 ? "nav-menu p-2" : "nav-menu p-2 disabled"} onClick={()=> decreaseWidth(colIndex, colIndex)}> 
                    <Image src={DecreaseIc} ></Image>
                    <span className="workspace-item-name-child align-text-top ml-2 font-13">Decrease Width</span>
                </Nav.Link> 
            </Popover.Content>
            }
            </Popover>
        )
    }

    const popoverFile = (rowIndex, columnIndex) => {
        return (
            <Popover id="popover-basic-option"> 
                <Popover.Content>
                    <Nav.Link className="nav-menu p-2" onClick={()=> handleUploadFile(rowIndex)}> 
                        <AttachmentIcon style={{color: 'black', fontSize: 18 }} className="align-text-top"/>
                        <span className="workspace-item-name-child align-text-top ml-2 font-13">From Computer</span>
                    </Nav.Link> 
                    
                </Popover.Content>
            </Popover>
        )
    }

    const popoverParagraph = (rowIndex, columnIndex, name, id) => {
        return (
            <Popover id="popover-basic-option"> 
                <Popover.Content>
                    <ReactQuill className="mt-1" theme="snow"
                        value={name}   
                        onBlur={()=>handleEditItemParagraph(columnIndex, rowIndex, id)}
                        onChange={(e)=> {handelChangeParagraph(e)}} 
                        modules={modules} 
                        formats={formats} 
                    />  
                </Popover.Content>
            </Popover>
        )
    }

    const popoverFileDownload =(item, rowIndex, columnIndex, id) => {
        return (
            <Popover id="popover-basic"> 
                <Popover.Content className="mt-2">
                    <span className="font-13 p-3">{item.name}</span>
                    <div className="spacer pt-2"></div>  
                    <Nav.Link className="nav-menu p-2" style={{minWidth : '200px'}}  onClick={()=> downloadFileAction(item)}> 
                        <Image src={DownloadFileIcon} className="ml-2"></Image>
                        <span className="workspace-item-name-child align-text-top ml-2 font-13">Dowload</span>
                    </Nav.Link> 
                    
                    <Nav.Link className="nav-menu p-2"  style={{minWidth : '200px'}} onClick={()=> handleDeleteFile(item.id, columnIndex, rowIndex, id)}>  
                        <Image src={DeleteIcon} className="ml-1"></Image>
                        <span className="workspace-item-name-child align-text-top ml-2 font-13">Delete</span>
                    </Nav.Link> 
                
                </Popover.Content>
                
            </Popover>
        )
    }


    const popoverAddcolumn = () => {
        return (
            <Popover id="popover-basic"> 
                <Popover.Content className="mt-2">
                    <Row>
                       <Col className="md-6" style={{minWidth : '17vw'}}> 
                            <Nav.Link className="nav-menu p-2" onClick={()=> handleAddColumn('Status')}>  
                                <div style={{width: '16px'}} className="d-inline-block">
                                    <Image src={StatusIc}></Image>
                                </div> 
                                <span className="ml-3 font-14 font-w-400 text-dark">Status</span>  
                                {columnMapping["status"] ? <Image src={DoneIc} className="float-right mt-1 ml-2"></Image>:""}
                            </Nav.Link> 
                            <Nav.Link className="nav-menu p-2" onClick={()=> handleAddColumn('Person')}>  
                                <div style={{width: '16px'}} className="d-inline-block">
                                    <Image src={PersonIc}></Image>
                                </div> 
                                <span className="ml-3 font-14 font-w-400 text-dark">Person</span>  
                                {columnMapping["person"] ? <Image src={DoneIc} className="float-right mt-1 ml-2"></Image>:""}
                            </Nav.Link> 
                            <Nav.Link className="nav-menu p-2" onClick={()=> handleAddColumn('Date')}>  
                                <div style={{width: '16px'}} className="d-inline-block">
                                    <Image src={DateIc}></Image>
                                </div> 
                                <span className="ml-3 font-14 font-w-400 text-dark">Date</span>  
                                {columnMapping["date"] ? <Image src={DoneIc} className="float-right mt-1 ml-2"></Image>:""}
                            </Nav.Link> 
                            <Nav.Link hidden className="nav-menu p-2" onClick={()=> handleAddColumn('Timeline')}>  
                                <div style={{width: '16px'}} className="d-inline-block">
                                    <Image src={TimelineIc}></Image>
                                </div> 
                                <span className="ml-3 font-14 font-w-400 text-dark">Timeline</span>  
                                {columnMapping["timeline"] ? <Image src={DoneIc} className="float-right mt-1 ml-2"></Image>:""}
                            </Nav.Link> 
                            <Nav.Link className="nav-menu p-2" onClick={()=> handleAddColumn('Due Date')}>  
                                <div style={{width: '16px'}} className="d-inline-block">
                                    <Image src={DueTimelineIc}></Image>
                                </div> 
                                <span className="ml-3 font-14 font-w-400 text-dark">Due Date</span>  
                                {columnMapping["duedate"] ? <Image src={DoneIc} className="float-right mt-1 ml-2"></Image>:""}
                            </Nav.Link> 
                            <Nav.Link className="nav-menu p-2" onClick={()=> handleAddColumn('Timeline')}>  
                                <div style={{width: '16px'}} className="d-inline-block">
                                    <Image src={TimelineIc}></Image>
                                </div> 
                                <span className="ml-3 font-14 font-w-400 text-dark">Timeline</span>  
                                {columnMapping["timeline_duedate"] ? <Image src={DoneIc} className="float-right mt-1 ml-2"></Image>:""}
                            </Nav.Link> 
                            <Nav.Link hidden className="nav-menu p-2" onClick={()=> handleAddColumn('Due Date + Timeline')}>  
                                <div style={{width: '16px'}} className="d-inline-block">
                                    <Image src={DueTimelineIc}></Image>
                                </div> 
                                <span className="ml-3 font-14 font-w-400 text-dark">Due Date + Timeline</span>  
                                {columnMapping["duedate"] ? <Image src={DoneIc} className="float-right mt-1 ml-2"></Image>:""}
                            </Nav.Link> 
                           
                            <Nav.Link className="nav-menu p-2" onClick={()=> handleAddColumn('Text')}>  
                                <div style={{width: '16px'}} className="d-inline-block">
                                    <Image src={TextIc}></Image>
                                </div> 
                                <span className="ml-3 font-14 font-w-400 text-dark">Text</span>  
                                {columnMapping["text"] ? <Image src={DoneIc} className="float-right mt-1 ml-2"></Image>:""}
                            </Nav.Link> 
                            <Nav.Link className="nav-menu p-2" onClick={()=> handleAddColumn('Paragraph')}>  
                                <div style={{width: '16px'}} className="d-inline-block">
                                    <Image src={ParagraphIc}></Image>
                                </div> 
                                <span className="ml-3 font-14 font-w-400 text-dark">Paragraph</span>  
                                {columnMapping["paragraph"] ? <Image src={DoneIc} className="float-right mt-1 ml-2"></Image>:""}
                            </Nav.Link> 
                            <Nav.Link className="nav-menu p-2" onClick={()=> handleAddColumn('Number')}>   
                                <div style={{width: '16px'}} className="d-inline-block">
                                    <FormatListNumberedIcon style={{color: 'black', fontSize: 18 }}/>
                                </div> 
                                <span className="ml-3 font-14 font-w-400 text-dark">Number</span>  
                                {columnMapping["number"] ? <Image src={DoneIc} className="float-right mt-1 ml-2"></Image>:""}
                            </Nav.Link> 
                            <Nav.Link className="nav-menu p-2" onClick={()=> handleAddColumn('Plan Weight')}>   
                                <div style={{width: '16px'}} className="d-inline-block">
                                    <AssignmentIcon style={{color: 'black', fontSize: 18 }}/>
                                </div> 
                                <span className="ml-3 font-14 font-w-400 text-dark">Plan Weight</span>  
                                {columnMapping["plan_weight"] ? <Image src={DoneIc} className="float-right mt-1 ml-2"></Image>:""}
                            </Nav.Link> 
                       </Col>
                       <Col className="md-6" style={{minWidth : '17vw'}}>
                            <Nav.Link className="nav-menu p-2" onClick={()=> handleAddColumn('Progress')}>  
                                <div style={{width: '16px'}} className="d-inline-block">
                                    <Image src={ProgressIc}></Image>
                                </div> 
                                <span className="ml-3 font-14 font-w-400 text-dark">Progress</span>  
                                {columnMapping["progress"] ? <Image src={DoneIc} className="float-right mt-1 ml-2"></Image>:""}
                            </Nav.Link> 
                            <Nav.Link className="nav-menu p-2" onClick={()=> handleAddColumn('Week')}>  
                                <div style={{width: '16px'}} className="d-inline-block">
                                    <Image src={WeekIc}></Image>
                                </div> 
                                <span className="ml-3 font-14 font-w-400 text-dark">Week</span>  
                                {columnMapping["week"] ? <Image src={DoneIc} className="float-right mt-1 ml-2"></Image>:""}
                            </Nav.Link> 
                            <Nav.Link className="nav-menu p-2" onClick={()=> handleAddColumn('Rating')}>  
                                <div style={{width: '16px'}} className="d-inline-block">
                                    <Image src={RattingIc}></Image>
                                </div> 
                                <span className="ml-3 font-14 font-w-400 text-dark">Rating</span>  
                                {columnMapping["rating"] ? <Image src={DoneIc} className="float-right mt-1 ml-2"></Image>:""}
                            </Nav.Link> 
                            <Nav.Link className="nav-menu p-2" onClick={()=> handleAddColumn('Priority')}>  
                                <div style={{width: '16px'}} className="d-inline-block">
                                    <Image src={PriorityIc}></Image>
                                </div> 
                                <span className="ml-3 font-14 font-w-400 text-dark">Priority</span>  
                                {columnMapping["priority"] ? <Image src={DoneIc} className="float-right mt-1 ml-2"></Image>:""}
                            </Nav.Link> 
                            <Nav.Link className="nav-menu p-2" onClick={()=> handleAddColumn('File')}>  
                                <div style={{width: '16px'}} className="d-inline-block text-center">
                                    <Image src={FileIc}></Image>
                                </div> 
                                <span className="ml-4 font-14 font-w-400 text-dark">File</span>  
                                {columnMapping["file"] ? <Image src={DoneIc} className="float-right mt-1 ml-2"></Image>:""}
                            </Nav.Link> 
                            <Nav.Link className="nav-menu p-2" onClick={()=> handleAddColumn('Depedency')}>  
                                <div style={{width: '16px'}} className="d-inline-block">
                                    <Image src={DepedencyIc}></Image>
                                </div> 
                                <span className="ml-3 font-14 font-w-400 text-dark">Depedency</span>  
                                {columnMapping["depedency"] ? <Image src={DoneIc} className="float-right mt-1 ml-2"></Image>:""}
                            </Nav.Link> 
                            <Nav.Link className="nav-menu p-2" onClick={()=> handleAddColumn('Last Update')}>  
                                <div style={{width: '16px'}} className="d-inline-block">
                                    <Image src={LastIc}></Image>
                                </div> 
                                <span className="ml-3 font-14 font-w-400 text-dark">Last Update</span>  
                                {columnMapping["last_update"] ? <Image src={DoneIc} className="float-right mt-1 ml-2"></Image>:""}
                            </Nav.Link> 
                            <Nav.Link className="nav-menu p-2" onClick={()=> handleAddColumn('Creation Log')}>  
                                <div style={{width: '16px'}} className="d-inline-block">
                                    <Image src={CreationIc} ></Image>
                                </div> 
                                <span className="ml-3 font-14 font-w-400 text-dark">Creation Log</span>  
                                {columnMapping["creation_log"] ? <Image src={DoneIc} className="float-right mt-1 ml-2"></Image>:""}
                            </Nav.Link> 
                            <Nav.Link className="nav-menu p-2" onClick={()=> handleAddColumn('Actual Weight')}>   
                                <div style={{width: '16px'}} className="d-inline-block">
                                    <AssessmentIcon style={{color: 'black', fontSize: 18 }}/> 
                                </div> 
                                <span className="ml-3 font-14 font-w-400 text-dark">Actual Weight</span>  
                                {columnMapping["actual_weight"] ? <Image src={DoneIc} className="float-right mt-1 ml-2"></Image>:""}
                            </Nav.Link> 
                       </Col>
                    </Row>
                </Popover.Content> 
                
            </Popover>
        )
    }

    let isMyTask = true 
    let strDueDate = "", strDate = ""
    const cellRenderer = ({columnIndex, key, rowIndex, style}) =>{ 
        if(!listGrid[rowIndex][columnIndex]) return 
        let classNameInput = "row-index w-100 single-line"
        let comments = listGrid[rowIndex][columnIndex].comments 
        comments = _.filter(comments,(item) => { 
            let alreadyRead = false
            let seen = _.filter(item.seen, (val) =>{
                // if(val.seen_id === params.id) alreadyRead = true 
            }) 
            if(!alreadyRead) return seen
        }) 
        if(!comments.length) comments = undefined
        let uid =  uuid()
        let name = listGrid[rowIndex][columnIndex].name  
        let type = listGrid[rowIndex][columnIndex].type   
        let parent = listGrid[rowIndex][columnIndex].parent   
        let id = listGrid[rowIndex][columnIndex].id   
        let hashchild = listGrid[rowIndex][columnIndex].hashchild   
        let countchild = listGrid[rowIndex][columnIndex].countchild  
        let visibility = listGrid[rowIndex][columnIndex].visibility 
        if(visibility === "hidden") return    
        if(columnIndex === (listGrid[0].length-1) & rowIndex > 0) return 
        
        if(columnIndex > 1) classNameInput = "row-index w-100 single-line text-center"

        //==========Column Only==========
        if(rowIndex === 0)  classNameInput = "column-index single-line text-center col"

        //==========Title Group==========
        if(rowIndex === 0 & columnIndex === 1) classNameInput = "column-index name-column-header"

        let classNameDiv = "", classLeftIndicator = "left-indicator"
        if(columnIndex === 1 & rowIndex > 0) {
            classNameDiv = "name-cell-component"
            if(parent) 
                classLeftIndicator = "left-indicator-child" 
                
            if(type === "subitems") classLeftIndicator = ""
            if(type === "addsubitems") classLeftIndicator = ""
        } 

        //==========Only Row==========
        let classNameRow = ""
        if(rowIndex > 0) {
            classNameRow = "name-cell-text" 
            if(type === "subitems" || type === "addsubitems") classNameRow+= " bg-white" 
        }   
       
        let classNameCell = "cell-component"
        if(columnIndex > 0 && rowIndex > 0) {
            classNameCell = "cell-component cell-border"
            if(parent && !listGrid[rowIndex-1][columnIndex] || listGrid[rowIndex-1][columnIndex] && listGrid[rowIndex-1][columnIndex].type === "subitems") 
            classNameCell ="cell-component cell-border-top"

            if(parent && listGrid[rowIndex-1][columnIndex] && listGrid[rowIndex-1][columnIndex].type === "subitems") 
            classNameCell ="cell-component cell-border-first-child"

            if(rowIndex > 1 && !listGrid[rowIndex-1][columnIndex] || listGrid[rowIndex-1][columnIndex] && listGrid[rowIndex-1][columnIndex].type === "subitems") 
            classNameCell ="cell-component cell-border-top"

            if(listGrid[rowIndex-1][columnIndex] && listGrid[rowIndex-1][columnIndex].type === "addsubitems") 
            classNameCell ="cell-component cell-border-top"

            //==========Only for sub items========== 
            if(type === "subitems") classNameCell = " ml-5 " 
            if(type === "addsubitems") classNameCell = " ml-5 " 
            if(parent && columnIndex === 1) classNameCell += " ml-5 " 
            if(type === "subitems" && name.trim() === "Sub Items") return
             
        }   

        if(columnIndex > 0 && (columnIndex < (listGrid[0].length-1) ) && rowIndex === 0) 
            classNameCell = "cell-border-bottom"

        if(columnIndex === 0 && rowIndex > 0) { 
            //==========Only for sub items========== 
            if(type === "subitems" || parent) 
            classNameCell = " cell-component ml-5 pl-1 "  

            if(parent && listGrid[rowIndex][1].name !=="" && listGrid[rowIndex][1].type !=="addsubitems") 
            classNameCell = " cell-component ml-5 pl-1 cell-border-dashed"

            // if(type === "subitems" && name === "") return  

        }    

        //==========Only > 1 Column========== 
        if(columnIndex > 0 && rowIndex === 0) {
            classNameRow = "row m-0 p-0"
        }   

        //==========Edit Row Icon==========
        if(columnIndex === 0) {
            classNameRow = "cell-edit mt-2"
        }   
        //=========Edit Col Icon===========
        if(columnIndex === 0  & rowIndex === 0) {
            classNameRow = "cell-edit-first mt-2 no-print text-center"
        }   
         
        
        // refTimeLine.current = true 
        // refEditable.current = name
        // refNumber.current = name

        let classMinWH  = "w-100 text-center min-wh-column" 

        //==========Set backgorund color and text color to white only for type col priority========
        let styleDiv = {}, listPriority = [], idHeader = ""
        if(type === "priority" & rowIndex > 0) {
            classNameRow = " name-cell-text-color"
            classNameInput += classNameRow
            styleDiv = {backgroundColor: name.color}  
            if(name === "" || name === undefined){
                classMinWH = "w-100 text-center min-wh-column"
                name = {name:"", color:""}  
            } 
            name = name.name    
            listPriority = listGrid[0][columnIndex].list 
            idHeader = listGrid[0][columnIndex].id 
            if(!priority.length)
            setPriority(listPriority)
        }

        let listStatus = []
        //==========Set backgorund color and text color to white only for type col status========//
        if(type === "status" & rowIndex > 0) {
            classNameRow = " name-cell-text-color"
            classNameInput += classNameRow

            classNameRow = " name-cell-text-color"
            classNameInput += classNameRow
            if(name === "" || name === undefined){
                classMinWH = "w-100 text-center min-wh-column"
                name = {name:"", color:""}  
            } 
            styleDiv = {backgroundColor: name.color}  
            name = name.name     
            listStatus = listGrid[0][columnIndex].list 
            idHeader = listGrid[0][columnIndex].id 
            if(!status.length)
            setStatus(listStatus)
        } 
 
        let dataDepedency = []
        if(type === "depedency" & rowIndex > 0) { 
            dataDepedency = taskByGroup(table, parent) 
            if(name === "" || name === undefined) {
                classMinWH = "w-100 text-center min-wh-column"
                name = []
            }
            
        } 

        //==========CONVERT VALUE DATE STRING TO DATE FOR DATE COLUMN ONLY======== 
        if(type === "date" & rowIndex > 0 ){ 
           if(name !=="") 
           strDate =  moment(name, 'DD MMM YYYY').toDate() 
           else strDate =  moment(moment(), 'DD MMM YYYY').toDate()
        }

        let isOverDue = false
        if(type === "duedate" & rowIndex > 0 ){
            if(name !==""){ 
                strDueDate =  moment(name, 'DD MMM YYYY').toDate() 
                let currDate = new Date()
                if(strDueDate <= currDate) isOverDue = true  
            }
          
            else strDueDate =  moment(moment(), 'DD MMM YYYY').toDate()  

        }

         //==========EXTRACT TIMELINE DUE DATE STRING TO DATE ========
        let timelineStart = "", timelineEnd = ""
        if(((type === "timeline_duedate") || (type === "timeline")) & rowIndex > 0 ){
            if(name !== ""){
                let splitDate = name.split("-")
                if(splitDate.length === 2) {
                    let start = splitDate[0]
                    let end = splitDate[1]
                    timelineStart = moment(start, 'DD MMM YYYY').toDate() 
                    timelineEnd = moment(end, 'DD MMM YYYY').toDate() 
                    
                }
                classNameRow = " name-cell-time-line"
            }
           
        }
            
        //======== CHECK IF PERMISION ID 3 AND CHECK HIS OWN TASK ======///  
        isMyTask = false    
        if(boardActive.permission_id !== 3)  isMyTask = true    
       
        listGrid[rowIndex].map(item => {
            if(item.type === 'person' & rowIndex > 0){
                if(item.name.length > 0) {
                    item.name.map(it => {
                        if(it.value === params.id) isMyTask = true 
                    }) 
                }
            }
           
        })    


        classNameCell = classNameCell + " " + id

        return ( 
            <div  key={key} style={style} className={classNameCell}>  
                <div className={classNameDiv}>
                    <div className={classLeftIndicator}></div>
                    <div className={classNameRow} style={styleDiv} ref={targetOverlay} >
                        {type === "person" & rowIndex > 0?
                        //======== Only boards owner and permision id 1 s/d 3 can edit item ===========//
                        params.permission_id < 4 || boardActive.permission_id < 4 ?
                            params.permission_id < 4 || isMyTask ?
                                <OverlayTrigger trigger="click" placement="bottom" overlay={popoverPeople( rowIndex, columnIndex, name, id)} rootClose>
                                    <div className="text-center w-100">
                                        {name.length === 1 ? 
                                            <span className="text-center w-100">{name[0].label}</span>
                                        : 
                                        name.length > 1 ?
                                            <div>
                                                <Image src={AvatarIcon}></Image> 
                                                <span className="ml-1"> + {name.length}</span>
                                            </div>
                                        :
                                            <Image src={AddIconSvg} className="addPeople"></Image>
                                        
                                    }
                                    </div>
                                </OverlayTrigger>
                                :
                                <OverlayTrigger trigger="click" placement="bottom" overlay={
                                    <Tooltip id='tooltip'>
                                        You can only view this board
                                    </Tooltip>
                                } rootClose>
                                    <div className="text-center w-100">
                                        {name.length === 1 ? 
                                                <span className="text-center w-100">{name[0].label}</span>
                                            : 
                                            name.length > 1 ?
                                                <div>
                                                    <Image src={AvatarIcon}></Image> 
                                                    <span className="ml-1"> + {name.length}</span>
                                                </div>
                                            :
                                                <Image src={AddIconSvg} className="addPeople"></Image>
                                        }
                                    </div>
                                </OverlayTrigger> 
                            :  
                            <OverlayTrigger trigger="click" placement="bottom" overlay={
                                <Tooltip id='tooltip'>
                                    You can only view this board
                                </Tooltip>
                            } rootClose>
                                <div className="text-center w-100">
                                    {name.length === 1 ? 
                                                <span className="text-center w-100">{name[0].label}</span>
                                            : 
                                            name.length > 1 ?
                                                <div>
                                                    <Image src={AvatarIcon}></Image> 
                                                    <span className="ml-1"> + {name.length}</span>
                                                </div>
                                            :
                                                <Image src={AddIconSvg} className="addPeople"></Image>
                                        }
                                </div>
                            </OverlayTrigger> 

                        :
                        type === "subitemscontroll" & rowIndex > 0 &  parent === undefined?
                        //======== Only boards owner and permision id 1 s/d 3 can edit item ===========//
                            params.permission_id < 4 || boardActive.permission_id == 1 ? 
                            <div className="text-center w-100">
                                {countchild ? 
                                    <>
                                        <div>
                                            <Image src={SubItemIcon} className="mr-2"></Image>
                                            <span>{countchild}</span>
                                            <Image src={ExpandIcon} onClick={()=>{handleSubClick(rowIndex, id)}} className="float-right"></Image>
                                        </div> 
                                        
                                    </>
                                    : 
                                    <Image src={AddIconSvg} onClick={()=>{addSubItem(rowIndex, id)}} className="mr-2"></Image>
                                }
                            </div> 
                        :
                            <OverlayTrigger trigger={['hover','focus']} placement="bottom" overlay={ 
                                <Tooltip id='tooltip'>
                                    This action is available only for board owners
                                </Tooltip>
                            } rootClose>
                                <>
                                <Item fontSize="small" onClick={()=>{handleSubClick(rowIndex, id)}} className={countchild ? "align-text-top align-text-top cursor-pointer text-center w-50 icon" : "align-text-top align-text-top cursor-pointer icon text-center w-50"} />
                                <span>{countchild}</span>
                                </>
                            </OverlayTrigger>

                        :
                        type === "subitemscontroll" & rowIndex > 0 &  parent !== undefined?
                            <></>
                        :
                        
                        ((type === "priority")) & rowIndex > 0?
                        //======== Only boards owner and permision id 1 s/d 3 can edit item ===========//
                        params.permission_id < 4 || boardActive.permission_id < 4 ?
                            params.permission_id < 4 || isMyTask ?
                                <OverlayTrigger trigger="click" placement="bottom" overlay={popoverPriority(rowIndex, columnIndex, id, listPriority, idHeader)} rootClose>
                                    <div className={classMinWH}>  
                                        <span className="p-2 span-label">{stripHtmlAnd(name)}</span>
                                    </div> 
                                </OverlayTrigger>
                                :
                                <OverlayTrigger trigger="click" placement="bottom" overlay={
                                    <Tooltip id='tooltip'>
                                        You can only view this board
                                    </Tooltip>
                                } rootClose>
                                    <div className={classMinWH}>  
                                        <span className="p-2 span-label">{stripHtmlAnd(name)}</span>
                                    </div> 
                                </OverlayTrigger>
                            :
                            <OverlayTrigger trigger="click" placement="bottom" overlay={
                                <Tooltip id='tooltip'>
                                    You can only view this board
                                </Tooltip>
                            } rootClose>
                                <div className={classMinWH}>  
                                    <span className="p-2 span-label">{stripHtmlAnd(name)}</span>
                                </div> 
                            </OverlayTrigger>
                        :
                        ((type === "status")) & rowIndex > 0?
                        //======== Only boards owner and permision id 1 s/d 3 can edit item ===========//
                        params.permission_id < 4 || boardActive.permission_id < 4 ?
                            params.permission_id < 4 || isMyTask ?
                                <OverlayTrigger trigger="click" placement="bottom" overlay={popoverStatus(rowIndex, columnIndex, id, listStatus, idHeader)} rootClose>
                                    <div className= {classMinWH}>  
                                        <span className="p-2 span-label">{stripHtmlAnd(name)}</span>
                                    </div> 
                                </OverlayTrigger>
                                :
                                <OverlayTrigger trigger="click" placement="bottom" overlay={
                                    <Tooltip id='tooltip'>
                                        You can only view this board
                                    </Tooltip>
                                } rootClose>
                                    <div className= {classMinWH}>  
                                        <span className="p-2 span-label">{stripHtmlAnd(name)}</span>
                                    </div> 
                                </OverlayTrigger>
                            :
                            <OverlayTrigger trigger="click" placement="bottom" overlay={
                                <Tooltip id='tooltip'>
                                    You can only view this board
                                </Tooltip>
                            } rootClose>
                                <div className= {classMinWH}>  
                                    <span className="p-2 span-label"> {stripHtmlAnd(name)}</span>
                                </div> 
                            </OverlayTrigger>
                        :
                        ((type === "date")) & rowIndex > 0?
                         //======== Only boards owner and permision id 1 s/d 3 can edit item ===========//
                         params.permission_id < 4 || boardActive.permission_id < 4 ?
                            params.permission_id < 4 || isMyTask ?
                                <OverlayTrigger trigger="click" placement="bottom"  overlay={popoverDate(rowIndex, columnIndex, id)} rootClose>
                                    <div className={classMinWH}>  
                                        <span>{name}</span>
                                    </div>
                                </OverlayTrigger>
                                :
                                <OverlayTrigger trigger="click" placement="bottom"  overlay={
                                    <Tooltip id='tooltip'>
                                        You can only view this board
                                    </Tooltip>
                                } rootClose>
                                    <div className={classMinWH}>  
                                        <span>{name}</span>
                                    </div>
                                </OverlayTrigger>
                            :
                            <OverlayTrigger trigger="click" placement="bottom"  overlay={
                                <Tooltip id='tooltip'>
                                    You can only view this board
                                </Tooltip>
                            } rootClose>
                                <div className={classMinWH}>  
                                    <span>{name}</span>
                                </div>
                            </OverlayTrigger>
                        :
                        ((type === "duedate")) & rowIndex > 0?
                         //======== Only boards owner and permision id 1 s/d 3 can edit item ===========//
                         params.permission_id < 4 || boardActive.permission_id < 4 ?
                            params.permission_id < 4 || isMyTask ?
                                <OverlayTrigger trigger="click" placement="bottom"  overlay={popoverDueDate(rowIndex, columnIndex, id)} rootClose>
                                    <div className={classMinWH}>  
                                        <span>{name}</span>
                                        <span hidden={!isOverDue} className="text-red ml-2"><i className="fa fa-exclamation-triangle"></i></span>
                                    </div>
                                </OverlayTrigger>
                                :
                                <OverlayTrigger trigger="click" placement="bottom"  overlay={
                                    <Tooltip id='tooltip'>
                                        You can only view this board
                                    </Tooltip>
                                } rootClose>
                                    <div className={classMinWH}>  
                                        <span>{name}</span>
                                        <span>2</span>
                                    </div>
                                </OverlayTrigger>
                            :
                            <OverlayTrigger trigger="click" placement="bottom"  overlay={
                                <Tooltip id='tooltip'>
                                    You can only view this board
                                </Tooltip>
                            } rootClose>
                                <div className={classMinWH}>  
                                    <span>{name}</span>
                                    <span>1</span>
                                </div>
                            </OverlayTrigger>
                        :
                        ((type === "timeline")) & rowIndex > 0?
                          //======== Only boards owner and permision id 1 s/d 3 can edit item =
                          params.permission_id < 4 || boardActive.permission_id < 4 ?
                            params.permission_id < 4 || isMyTask ?
                                <OverlayTrigger trigger="click" placement="bottom" overlay={popoverTimeline(rowIndex, columnIndex,timelineStart, timelineEnd, id)} rootClose>
                                    <div className={classMinWH}>  
                                        <span>{name}</span>
                                    </div>
                                </OverlayTrigger>
                                :
                                <OverlayTrigger trigger="click" placement="bottom" overlay={
                                    <Tooltip id='tooltip'>
                                        You can only view this board
                                    </Tooltip>
                                } rootClose>
                                    <div className={classMinWH}>  
                                        <span>{name}</span>
                                    </div>
                                </OverlayTrigger>
                            :
                            <OverlayTrigger trigger="click" placement="bottom" overlay={
                                <Tooltip id='tooltip'>
                                    You can only view this board
                                </Tooltip>
                            } rootClose>
                                <div className={classMinWH}>  
                                    <span>{name}</span>
                                </div>
                            </OverlayTrigger>
                        :
                        ((type === "timeline_duedate")) & rowIndex > 0?
                         //======== Only boards owner and permision id 1 s/d 3 can edit item =
                         params.permission_id < 4 || boardActive.permission_id < 4 ?
                            params.permission_id < 4 || isMyTask ?
                                <OverlayTrigger trigger="click" placement="bottom" overlay={popoverTimelineDueDate(rowIndex, columnIndex, timelineStart, timelineEnd, id)} rootClose>
                                    <div className={classMinWH}>  
                                        <span>{name}</span>
                                    </div>
                                </OverlayTrigger>
                                :
                                <OverlayTrigger trigger="click" placement="bottom" overlay={
                                    <Tooltip id='tooltip'>
                                        You can only view this board
                                    </Tooltip>
                                } rootClose>
                                    <div className={classMinWH}>  
                                        <span>{name}</span>
                                    </div>
                                </OverlayTrigger>
                            :
                            <OverlayTrigger trigger="click" placement="bottom" overlay={
                                <Tooltip id='tooltip'>
                                    You can only view this board
                                </Tooltip>
                            } rootClose>
                                <div className={classMinWH}>  
                                    <span>{name}</span>
                                </div>
                            </OverlayTrigger>
                        :
                        ((type === "progress")) & rowIndex > 0?
                          //======== Only boards owner and permision id 1 s/d 3 can edit item =
                          params.permission_id < 4 || boardActive.permission_id < 4 ?
                            params.permission_id < 4 || isMyTask ?
                                <OverlayTrigger trigger="click" placement="bottom" overlay={popoverProgress(rowIndex, columnIndex, name, id)} rootClose>
                                    <div className="w-100">
                                        <div className="w-75 text-center d-inline-block">  
                                                <ProgressBar now={name} style={{height: '12px'}}/>  
                                            </div>  
                                        <span className="w-25 font-12  text-right float-right">{name?name: 0} %</span>
                                    </div>
                                </OverlayTrigger>
                                :
                                <OverlayTrigger trigger="click" placement="bottom" overlay={
                                    <Tooltip id='tooltip'>
                                        You can only view this board
                                    </Tooltip>
                                } rootClose>
                                    <div className="w-100">
                                    <div className="w-75 text-center d-inline-block">  
                                            <ProgressBar now={name} style={{height: '12px'}}/>  
                                        </div>  
                                    <span className="w-25 font-12 float-right">{name?name: 0} %</span>
                                </div>
                                </OverlayTrigger>
                            :
                            <OverlayTrigger trigger="click" placement="bottom" overlay={
                                <Tooltip id='tooltip'>
                                    You can only view this board
                                </Tooltip>
                            } rootClose>
                                <div className="w-100">
                                    <div className="w-75 text-center d-inline-block">  
                                            <ProgressBar now={name} style={{height: '12px'}} />  
                                        </div>  
                                    <span className="font-12 w-25 loat-right">{name?name: 0} %</span>
                                </div>
                            </OverlayTrigger>
                        :
                        ((type === "week")) & rowIndex > 0?
                         //======== Only boards owner and permision id 1 s/d 3 can edit item =
                         params.permission_id < 4 || boardActive.permission_id < 4 ?
                            params.permission_id < 4 || isMyTask ?
                                <OverlayTrigger trigger="click" placement="bottom" overlay={popoverWeek(rowIndex, columnIndex, name, type, id)} rootClose>
                                    <div className={classMinWH}>  
                                        <span>{name}</span>
                                    </div>
                                </OverlayTrigger>
                                :
                                <OverlayTrigger trigger="click" placement="bottom" overlay={
                                    <Tooltip id='tooltip'>
                                        You can only view this board
                                    </Tooltip>
                                } rootClose>
                                    <div className={classMinWH}>  
                                        <span>{name}</span>
                                    </div>
                                </OverlayTrigger>
                            :
                            <OverlayTrigger trigger="click" placement="bottom" overlay={
                                <Tooltip id='tooltip'>
                                    You can only view this board
                                </Tooltip>
                            } rootClose>
                                <div className={classMinWH}>  
                                    <span>{name}</span>
                                </div>
                            </OverlayTrigger>
                        :
                        ((type === "check")) & rowIndex > 0? 
                            <Form.Group controlId="formBasicCheckbox" className="w-100">
                                <Form.Check type="checkbox" className="text-center mt-3" />
                            </Form.Group>
                        :
                        ((type === "number" || type === "actual_weight" || type === "plan_weight")) & rowIndex > 0? 
                         //======== Only boards owner and permision id 1 s/d 3 can edit item =
                         params.permission_id < 4 || boardActive.permission_id < 4 ?
                            params.permission_id < 4 || isMyTask ?
                                    <input type="number" pattern="[0-9]*" className="form-control input-number font-14 single-line"  
                                    defaultValue= {name}
                                    onBlur={()=>{handleEditItemNumber(columnIndex, rowIndex, id);}}
                                    onChange={(e)=> {handelChangeNumber(e.target.value)}} 
                                    onKeyDown={(e)=>handleEnterItemNumber(e, columnIndex, rowIndex, id)} 
                                ></input> 
                                :
                                <OverlayTrigger trigger="click" placement="bottom" overlay={
                                    <Tooltip id='tooltip'>
                                        You can only view this board
                                    </Tooltip>
                                } rootClose>
                                    <input type="number" pattern="[0-9]*" className="form-control input-number font-14 single-line"  
                                        defaultValue= {name}
                                        onBlur={()=>{handleEditItemNumber(columnIndex, rowIndex, id);}}
                                        onChange={(e)=> {handelChangeNumber(e.target.value)}} 
                                        onKeyDown={(e)=>handleEnterItemNumber(e, columnIndex, rowIndex, id)} 
                                    ></input>
                                </OverlayTrigger>
                            :
                            <OverlayTrigger trigger="click" placement="bottom" overlay={
                                <Tooltip id='tooltip'>
                                    You can only view this board
                                </Tooltip>
                            } rootClose>
                                <input type="number" pattern="[0-9]*" className="form-control input-number font-14 single-line"  
                                    defaultValue= {name}
                                    onBlur={()=>{handleEditItemNumber(columnIndex, rowIndex, id);}}
                                    onChange={(e)=> {handelChangeNumber(e.target.value)}} 
                                    onKeyDown={(e)=>handleEnterItemNumber(e, columnIndex, rowIndex, id)} 
                                ></input>
                            </OverlayTrigger>
                           
                        :
                        ((type === "paragraph")) & rowIndex > 0? 
                         //======== Only boards owner and permision id 1 s/d 3 can edit item =
                         params.permission_id < 4 || boardActive.permission_id < 4 ?
                            params.permission_id < 4 || isMyTask ?
                                    <>
                                       <OverlayTrigger trigger="click" placement="bottom" overlay={popoverParagraph(rowIndex, columnIndex, name, id)} rootClose>
                                            <div className="board-desc mt-0 p-1 w-100 text-center">
                                                {name === "" ?
                                                    <Image src={AddIconSvg} className="addParagraph"></Image>
                                                    :
                                                    <Image src={ParagraphIcon}></Image>
                                                } 
                                            </div>   
                                        </OverlayTrigger>
                                    </> 
                                :
                                <OverlayTrigger trigger="click" placement="bottom" overlay={
                                    <Tooltip id='tooltip'>
                                        You can only view this board
                                    </Tooltip>
                                } rootClose>
                                        <>
                                        <OverlayTrigger trigger="click" placement="bottom" overlay={popoverParagraph(rowIndex, columnIndex, name, id)} rootClose>
                                            <div className="board-desc mt-0 p-1 w-100 text-center">
                                                {name === "" ?
                                                    <Image src={AddIconSvg} className="addParagraph"></Image>
                                                    :
                                                    <Image src={ParagraphIcon}></Image>
                                                }
                                            </div>   
                                        </OverlayTrigger>
                                        </> 
                                </OverlayTrigger>
                            :
                            <OverlayTrigger trigger="click" placement="bottom" overlay={
                                <Tooltip id='tooltip'>
                                    You can only view this board
                                </Tooltip>
                            } rootClose> 
                                    <>
                                    <OverlayTrigger trigger="click" placement="bottom" overlay={popoverParagraph(rowIndex, columnIndex, name, id)} rootClose>
                                            <div className="board-desc mt-0 p-1 w-100 text-center"> 
                                                {name === "" ?
                                                    <Image src={AddIconSvg} className="addParagraph"></Image>
                                                    :
                                                    <Image src={ParagraphIcon}></Image>
                                                }
                                            </div>   
                                        </OverlayTrigger>
                                    </> 
                            </OverlayTrigger>
                           
                        :
                        ((type === "rating")) & rowIndex > 0?
                         //======== Only boards owner and permision id 1 s/d 3 can edit item =
                         params.permission_id < 4 || boardActive.permission_id < 4 ?
                            params.permission_id < 4 || isMyTask ?
                                <div className="w-100 text-center">
                                    <Rating
                                        emptySymbol="fa fa-star-o fa-2x font-18"
                                        fullSymbol="fa fa-star fa-2x color-rating font-18" 
                                        onChange= {(rating) => handleSelectedOption(id, rowIndex, columnIndex,rating+"")}
                                        initialRating={name}
                                        fractions={1}
                                    />
                                </div>
                                :
                                <div className="w-100 text-center">
                                    <Rating
                                        emptySymbol="fa fa-star-o fa-2x font-18"
                                        fullSymbol="fa fa-star fa-2x color-rating font-18"  
                                        readonly = {true}
                                        initialRating={name}
                                        fractions={1}
                                    />
                                </div>
                            :
                            <div className="w-100 text-center">
                                <Rating
                                    emptySymbol="fa fa-star-o fa-2x font-18"
                                    fullSymbol="fa fa-star fa-2x color-rating font-18"  
                                    readonly = {true}
                                    initialRating={name}
                                    fractions={1}
                                />
                            </div>
                        :
                        type === "depedency" & rowIndex > 0?
                            <OverlayTrigger trigger="click" placement="bottom" overlay={popoverDepedency(id, rowIndex, columnIndex, name, dataDepedency)} rootClose>
                                <div className= {classMinWH}> 
                                {name.length ? name.length === 1 ? name[0].label :  
                                  <div>
                                     <LinkIcon fontSize="small" className="text-center cursor-pointer"/> 
                                    <span className="ml-1"> + {name.length}</span>
                                </div>
                                  : ""}
                                </div>
                            </OverlayTrigger>   
                        :
                        type === "creation_log" & rowIndex > 0?
                            <OverlayTrigger trigger="click" placement="bottom" overlay={popoverCreationLog(name)} rootClose>
                                <div className="w-100 text-center"> 
                                {name}
                                </div>
                            </OverlayTrigger>
                        :
                        type === "last_update" & rowIndex > 0?
                            // <OverlayTrigger trigger="click" placement="bottom" overlay={popoverLastUpdate()} rootClose>
                                <div className="w-100 text-center"> 
                                 {name}
                                </div>
                            // </OverlayTrigger>
                        :
                        type === "file" & rowIndex > 0?  
                            <div className="w-100 text-center add-file">
                                  {/* //======== Only boards owner and permision id 1 s/d 3 can edit item = */}
                                {params.permission_id < 4 || boardActive.permission_id < 4 ? 
                                    params.permission_id < 4 || isMyTask ?
                                        <OverlayTrigger trigger="click" placement="bottom" overlay={popoverFile(rowIndex, columnIndex, id)} rootClose>
                                             <Image src={AddIconSvg} className="addFile mr-2"></Image>
                                        </OverlayTrigger> 
                                        :
                                        <OverlayTrigger trigger="click" placement="bottom" overlay={
                                            <Tooltip id='tooltip'>
                                                You can only view this board
                                            </Tooltip>
                                        } rootClose>
                                            <Image src={AddIconSvg} className="addFile mr-2"></Image>
                                        </OverlayTrigger> 
                                    :
                                    <OverlayTrigger trigger="click" placement="bottom" overlay={
                                        <Tooltip id='tooltip'>
                                            You can only view this board
                                        </Tooltip>
                                    } rootClose>
                                         <Image src={AddIconSvg} className="addFile mr-2"></Image>
                                    </OverlayTrigger> 
                                }
                                <input type="file" id={`file${columnIndex}${rowIndex}`} ref={r => (fileUploader.current[rowIndex] = r)} hidden onChange={(e)=>handleFileSelected(e,columnIndex, rowIndex, id)}/>
                                {name === ""? null:
                                    name.map(item => {
                                        return(
                                            <>
                                               {/* //======== Only boards owner and permision id 1 s/d 3 can edit item = */}
                                            {params.permission_id < 4 || boardActive.permission_id < 4 ? 
                                                params.permission_id < 4 || isMyTask ?
                                                    <OverlayTrigger
                                                    key={item.id}
                                                    placement="bottom" 
                                                    trigger="click"
                                                    rootClose
                                                    overlay={popoverFileDownload(item, rowIndex, columnIndex, id)}>
                                                        <Image src={FileIcon} className="mr-2"></Image>
                                                    </OverlayTrigger>
                                                    :
                                                    <OverlayTrigger
                                                    key={item.id}
                                                    placement="bottom" 
                                                    trigger="click"
                                                    rootClose
                                                    overlay={  
                                                    <Tooltip id='tooltip'>
                                                        You can only view this board
                                                    </Tooltip>}>
                                                        <Image src={FileIcon}></Image>
                                                    </OverlayTrigger>

                                                :
                                                <OverlayTrigger
                                                key={item.id}
                                                placement="bottom" 
                                                trigger="click"
                                                rootClose
                                                overlay={  
                                                <Tooltip id='tooltip'>
                                                    You can only view this board
                                                </Tooltip>}>
                                                    <Image src={FileIcon}></Image>
                                                </OverlayTrigger>
                                                }
                                            </>
                                        )
                                    })
                                }
                            </div> 
                        :
                        type === "add" & rowIndex === 0? 
                            // ====== Only Owner boards, Permission Id 1 can add item  ===== 
                            params.permission_id < 4 || boardActive.permission_id == 1 ?
                                <OverlayTrigger trigger="click" placement="bottom" overlay={popoverAddcolumn()} rootClose>
                                    <Image src={AddIcon}  hidden ={viewType === "Default"? false : true } className="ml-3 pt-2 mt-1"></Image>
                                 </OverlayTrigger>
                            :
                                <OverlayTrigger trigger={['hover','focus']} placement="bottom" overlay={ 
                                    <Tooltip id='tooltip'>
                                        This action is available only for board owners
                                    </Tooltip>
                                } rootClose>
                                     <Image src={AddIcon}  hidden ={viewType === "Default"? false : true } className="ml-3 pt-2 mt-1"></Image> 
                                </OverlayTrigger>
                        :  
                       
                        type === "subitems"?
                        <span className="font-14">{name}</span>
                        :
                        type === "addsubitems"  ?  
                                <ContentEditable 
                                    html= {"+ Add"}  
                                    hidden= {props.fromKanban ? true : false}
                                    onClick = {()=>{addSubItemClick(parent, id)}} 
                                    className="cursor-pointer"
                                    style={{width : '95%'}} 
                                    disabled={true}   
                                />  
                        :
                        type === "option" ?
                            <OverlayTrigger  trigger="click" placement="left" overlay={popoverEditGroup(rowIndex, parent,id)} rootClose>
                                 <Image src={ArrowDown} hidden ={viewType === "Default"? false : true}></Image>
                            </OverlayTrigger>
                        :  
                            <>  
                                 {/* <OverlayTrigger trigger={["hover","focus"]} placement="top" overlay={
                                    <Tooltip id='tooltip'>
                                        {name === "" || typeof name !== "string" ? "Empty" : name}
                                    </Tooltip>
                                } rootClose>  */}
                                     <ContentEditable   
                                        html= {typeof name !== "string"? "" : name} 
                                        key={key} 
                                        disabled = { params.permission_id < 4 || boardActive.permission_id < 4 ? 
                                            params.permission_id < 4 || isMyTask  ? false : true : true}
                                        className= {classNameInput}  
                                        style={{width : '95% !important'}} 
                                        onBlur={()=> {handleEditItem(columnIndex, rowIndex, id)}}
                                        onChange={(e)=> {handelChangeEditable(e.target.value.replace(/<\/?[^>]+(>|$)/g, "")+ " ")}} 
                                        onKeyDown={(e)=>{handleEnterItem(e, columnIndex, rowIndex, id)}} 
                                    /> 
                                {/* </OverlayTrigger>  */}
                            </> 
                        }
                        
                         {/*==========Only first collumn==========*/}
                        {columnIndex === 1 & rowIndex > 0 && type !== "subitems" && type !== "addsubitems"?  
                            <div  onClick={()=>{setShowComment(true); setItemActive({id: props.item.id,rowIndex, itemId: id, name:name})}}>
                                <div className={parent?"ml-3 mr-5 d-inline float-right" :comments === undefined?"mr-2 ml-3 d-inline float-right": "ml-3 d-inline float-right"}>
                                    <Image src={ChatIcon} className={"cursor-pointer no-print mr-3"}></Image>
                                    <span>{comments !== undefined ? comments.length : ""}</span> 
                                </div>  
                            </div>  
                        : null}
                        {((columnIndex > 0) & (columnIndex < (listGrid[0].length-1))) & rowIndex === 0 ?
                            <Col className="m-0 p-0 div-edit-col text-center" md="2">
                                <OverlayTrigger trigger="click" placement="bottom" overlay={popoverEditColumn(name, columnIndex, type, id,uid)} rootClose>
                                    <div id={uid} >
                                        <ArrowDropDownIcon onClick={()=>setSelectedColumnIndex(columnIndex)} style={{ color: 'black'}} fontSize="small" className="cursor-pointer mt-2 text-center"/>
                                    </div> 
                                </OverlayTrigger>
                            </Col>
                        :null}
                         
                    </div>
                </div>     
            </div>
        )
    }

    const getColumnWidthHelper = (getColumnWidth) => ({ index}) =>{ 
        let width = getColumnWidth
        let listTmp = listGrid[0]   
        if(listTmp[index].width) return listTmp[index].width
        //==========Title Group col==========
        if(index === 1) width = 370
        if(index === 0) width = 50 

        // //==========Set width date========== 
        if(listTmp[index].type === "subitemscontroll") width = 150
        if(listTmp[index].type === "person") width = 200
        if(listTmp[index].type === "status") width = 150
        if(listTmp[index].type === "priority") width = 150
        if(listTmp[index].type === "progress") width = 200
        if(listTmp[index].type === "rating") width = 150
        if(listTmp[index].type === "paragraph") width = 130
        if(listTmp[index].type === "timeline") width = 220
        if(listTmp[index].type === "timeline_duedate") width = 220
        if(listTmp[index].type === "last_update") width = 200 
        if(listTmp[index].type === "creation_log") width = 200  
        if(listTmp[index].type === "date") width = 130  
        if(listTmp[index].type === "number") width = 130  
        if(listTmp[index].type === "week") width = 130  
        return width
    }
  
    const handleAddItem = () =>{ 
        if(textAdd === "") setTextAdd("+ Add") 
        if((textAdd.trim() !== "")  & (textAdd.trim() !== "+ Add")){ 

            //==========Clonse first object==========
            let newList = _.cloneDeep(listGrid[0], true)   
            //==========Update value to default add text 
            newList = _.map(newList, o => _.omit(o, ['list'])); 
            _.map(newList, (value, index)=>{    
                value.name = "" 
                value.id = uuid()
                if(index === 1) value.name = textAdd 
                return value
            })  
            listGrid.push(newList) 
            currentListGrid.current = listGrid 
            setListGridForFilter(listGrid)
            setItemAdd({id: props.item.id, rows: newList, user_id : params.id, refresh: true, username: params.username})
            setTextAdd("+ Add") 
        }
    } 

    const addSubItemClick = (parent, itemId) =>{
        // setCollapse(true)
        let rowIndex = 0
        _.filter(listGrid, (item, index) =>{
            item.map((val,idx)=>{
                if(val.id === parent) rowIndex = index
            })
        }) 
        let name = "Sub Item"  
        setSubItemAdd({id: props.item.id, rowIndex, itemId:parent, parent, name, user_id : params.id, refresh: true, username: params.username, board_id: boardActive.id, itemOnly: true})
    }

    const addSubItem = (rowIndex) =>{ 
        // setCollapse(true)
        let itemId = ""
        _.filter(listGrid, (item, index) =>{
            if(index === rowIndex){
                item.map((val,idx)=>{
                    itemId = item[1].id
                })
            }
          
        })
      
        let name = "Sub Item"  
        setSubItemAdd({id: props.item.id,rowIndex, itemId, name, user_id : params.id, refresh: true, username: params.username, board_id: boardActive.id, itemOnly: false})
      
    }

    const handleSubClick = (rowIndex) =>{
        let idparent = 0
        let newList = _.cloneDeep(listGrid, true)
        _.filter(listGrid, (item, index)=>{  
            if(index === rowIndex){ 
                idparent = item[1].id
            }  
        })   
        let alreadyExpand = _.filter(listGrid, (item, index)=>{  
            let tmp = _.filter(item, (val, idx)=>{   
                 if(val.parent === idparent) return val
             })   
             if(tmp.length) return tmp
         })   
       
        if(alreadyExpand.length){
            collapseSubItem(rowIndex)
        }else{
            let listTmp = _.filter(listGridForFilter, (item, index)=>{  
                let tmp = _.filter(item, (val, idx)=>{   
                     if(val.parent === idparent) return val 
                 })   
                 if(tmp.length) return tmp
             })   
             newList.splice(parseInt(rowIndex)+1, 0, ...listTmp)  
             currentListGrid.current = newList
             setListGrid(newList) 
             let debouncer = setTimeout(() => { 
                setItemExpand({id: props.item.id, itemId : idparent, refresh: false, type: 'expand'}) 
                 
              }, 500);
              return () => {
                clearTimeout(debouncer);
              } 
            
        }
    }

    const expandSubItem = (rowIndex) => {
        let idparent = 0
        let newList = _.cloneDeep(listGrid, true)
        _.filter(listGrid, (item, index)=>{  
            if(index === rowIndex){ 
                idparent = item[1].id
            }  
        })   
        let alreadyExpand = _.filter(listGrid, (item, index)=>{  
            let tmp = _.filter(item, (val, idx)=>{   
                 if(val.parent === idparent) return val
             })   
             if(tmp.length) return tmp
         })   

        if(alreadyExpand.length) return 

        let listTmp = _.filter(listGridForFilter, (item, index)=>{  
           let tmp = _.filter(item, (val, idx)=>{   
                if(val.parent === idparent) return val
            })   
            if(tmp.length) return tmp
        })   
        newList.splice(parseInt(rowIndex)+1, 0, ...listTmp)   
        currentListGrid.current = newList
        setListGrid(newList) 
          
    }

    const collapseSubItem = (rowIndex) => {
        let idparent = 0
        _.filter(listGrid, (item, index)=>{  
            if(index === rowIndex){ 
                idparent = item[1].id
            }  
        })     
        let listTmpShow = _.cloneDeep(listGrid)
        let listTmp = _.filter(listTmpShow, (item, index)=>{   
           let tmp = _.filter(item, (val, idx)=>{   
                if(val.parent === idparent) val.isShow = false
                if(val.parent !== idparent) return val
            })   
            if(tmp.length) return tmp
        })    

        currentListGrid.current = listTmp
        setListGrid(listTmp)
        let debouncer = setTimeout(() => { 
            setItemExpand({id: props.item.id, itemId : idparent, refresh: false, type: 'collapse'}) 
             
          }, 500);
          return () => {
            clearTimeout(debouncer);
          } 
       
    }

    const duplicateItem = (rowIndex) => {  
        let list = currentListGrid.current

        let newList = []
        _.filter(list, (item, index)=>{  
            if(index === rowIndex){   
                let name = item[1].name
                _.map(item, (value, idx)=>{     
                    value.id = uuid() 
                    value.name = ""
                    if(idx === 1) value.name = name 
                    newList.push(value)
                })  
            }  
        })     
        setItemAdd({id: props.item.id, rows: newList, user_id : params.id, refresh: true, username: params.username})
 
    }
    const handleEnter  = (event) => {
        var keyCode = event.which || event.keyCode;
        if(keyCode === 13 & textAdd.trim() !== "+ Add") { 
            if(textAdd.trim() === "") setTextAdd("+ Add") 
            handleAddItem()
        }
    }
   

    return (
        
        <React.Fragment>     
            {listGrid.length >0 ?
            <React.Fragment>  
                {/* THIS COMING FROM FULLSCREEN WIDGET CHART */}
                {props.board ?
                    <div className="name-column-header text-dark ml-3">
                        {props.board.title}
                    </div>
                : null } 
                <Col className={listGrid.length > 1? "mt-4 mb-5 pb-3 p-0": "mt-4 mb-5 pb-3 p-0 no-print"} id={selectedWeek}>  
                    <input type="file" accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ref={r => (fileImport = r)} hidden onChange={(e)=>handleFileSelectedImport(e)}/>
                    {/* <AutoSizer disableHeight>
                        {({width}) => (
                            <ColumnSizer 
                                columnMaxWidth={100}
                                columnMinWidth={50}
                                columnCount={listGrid[0].length} 
                                width={width}>
                                 {({ adjustedWidth, getColumnWidth, registerChild }) => ( */}
                                 
                               <AutoSizer disableHeight>
                                    {({width}) => (  
                                            <MultiGrid  
                                                ref = {refGrid}
                                                cellRenderer={cellRenderer}
                                                columnWidth={getColumnWidthHelper(200)}
                                                columnCount={listGrid[0].length}
                                                fixedColumnCount={2}
                                                fixedRowCount={1} 
                                                enableFixedColumnScroll={true}
                                                height={(listGrid.length * 46)+20}
                                                rowHeight={46}   
                                                rowCount={listGrid.length}  
                                                width={width}  
                                            />   
                                      )}
                                    </AutoSizer> 
                                    {/* )}
                            </ColumnSizer>   
                        )}
                       </AutoSizer> */}
                       

                    {/* ====== Only Owner boards, Permission Id 1 and 2 can add item ===== */}
                    <div className="cell-component ml-5 no-print" ref={r => (ref = r)}> 
                        {params.permission_id < 4 || boardActive.permission_id < 3 ? 
                                <div className="name-cell-component" hidden={props.fromKanban ? true : false}>
                                    <div className="left-indicator-footer "></div>
                                    <div className="name-cell-text-footer">
                                        <ContentEditable 
                                            html= {textAdd}  
                                            onClick = {()=>{setTextAdd("")}} 
                                            style={{width : '95%'}}
                                            onBlur={handleAddItem}
                                            onKeyDown={handleEnter}
                                            onChange={(e)=> {setTextAdd(e.target.value.replace(/<\/?[^>]+(>|$)/g, "")+ " ")}} 
                                            disabled={false}   
                                        /> 
                                    </div>
                                </div> 
                        :null} 
                    </div>

                </Col>
            </React.Fragment>
            :  
            null
         }
        </React.Fragment>
    )
}


export default Content
