import React, { useEffect, useState, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";

//=========BOOTSTARP INITIAL========//
import { Col, Row, Image } from "react-bootstrap";
import Header from "./Header";

//=========CONTEXT========//
import { DashboardContext } from "../../context/DashboardContext";

//=========COMPONENT========//
import Table from "./Table";
import ContainerKanban from "./kanban/Container";
import Index from "./timeline/Index";

const Workspace = () => {
  const { table, viewType, width, valueFilter } = useContext(DashboardContext);
  const [tableComponent, setTableComponent] = useState([]);
  const isFirstRun = useRef(true);

  useEffect(() => {
    setTableComponent([]);
    if (!table.length) return;
    let componentTmp = [];
    table.map((item, key) => {
      componentTmp.push(<Table item={item} index={key} />);
    });
    setTableComponent(componentTmp);

    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
  }, [table]);

  return (
    <Row className="vh-100">
      <Col md={12}>
        <div
          className="content-board vh-100 overflow-scroll"
          style={{ marginLeft: width.board }}
        >
          <div className="devider p-3">
            <Header />
          </div>
          <Col
            className={
              viewType !== "Default" && viewType !== "Timeline"
                ? "bg-grey overflow-scroll p-0"
                : "overflow-scroll"
            }
            id="section-to-print-board"
          >
            {viewType === "Kanban" ? (
              <ContainerKanban />
            ) : viewType === "Timeline" ? (
              <Index />
            ) : (
              tableComponent.map((content, index) => {
                return content === undefined ? (
                  ""
                ) : (
                  <React.Fragment key={index}>
                    {React.cloneElement(content)}
                  </React.Fragment>
                );
              })
            )}
          </Col>
        </div>
      </Col>
    </Row>
  );
};

export default Workspace;
