import React, {useEffect, useState, useContext, useRef} from 'react'
import {Modal, Button, Form, Alert, Popover, Nav, Image, OverlayTrigger, Col, Row, ProgressBar, Tooltip } from 'react-bootstrap' 
import { useForm } from "react-hook-form"
import { MakeRequest } from "../../api/Api"   
import {DashboardContext} from "../../context/DashboardContext" 
import _ from 'lodash'
import {StyleSelect} from "../../helpers/select/Select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Select from 'react-select' 
import moment from "moment"
import Rating from "react-rating"
import ReactQuill from 'react-quill'
import {NotificationManager} from 'react-notifications'

import { exportExcel, stripHtml,stripHtmlAnd, taskByGroup, exportTemplate, ExcelDateToJSDate, uuid,asyncRequest } from '../../helpers/utils/Utils' 

import ContentEditable from 'react-contenteditable'

import AddSmallIcon from "../../img/add-small.svg"
import AddIconSvg from "../../img/add.svg" 
import AvatarIcon from "../../img/avatar-task.svg"
import RemoveIcon from "../../img/remove.svg"
import ParagraphIcon from "../../img/paragraph.svg" 

const ModalDetailBoard = (props) =>{ 
    let ref = useRef("")
    const [isLoading, setLoading] = useState(false) 
    const { register, handleSubmit, watch, errors } = useForm()
    const [alert, setAlert] = useState({msg: '', variant: 'success', hidden: true}) 

    const refTimeLineDueDate = React.useRef(true)
    const targetOverlay = useRef(null)
    let refProgress = useRef("")
    let currentListGrid = useRef([])
    let currentListId = useRef("")
    let refEditable = useRef("")
    let refNumber = useRef("")
    
    const [titleText, setTitleText] = useState("")
    const [listPriority, setListPriority] = useState([]) 
    const [listStatus, setListStatus] = useState([]) 
    const [memberList, setMemberList] = useState([])
    const [listName, setListName] = useState([])
    const [optionMember, setOptionMember] = useState([])
    const [strDate, setStrDate] = useState(moment(moment(), 'DD MMM YYYY').toDate())
    const [strDueDate, setStrDueDate] = useState(moment(moment(), 'DD MMM YYYY').toDate())
    const [timelineStart, setTimelineStart] = useState(moment(moment(), 'DD MMM YYYY').toDate())
    const [timelineEnd, setTimelineEnd] = useState(moment(moment(), 'DD MMM YYYY').toDate())
    const [valProgress, setValProgress] = useState("0")
    const [valRating, setValRating] = useState("0")
    const [arrWeeks, setWeekArr] = useState([])
    const [valWeek, setValWeek] = useState("")
    const [valTypeWeek, setValTypeWeek] = useState("")
    const [dataDepedency, setDataDepedency] = useState([])
    const [valDepedency, setValDepedency] = useState([])
    const [valParagraph, setValParagraph] = useState([])
    const [listGrid, setListGrid] = useState([])
    const [textVal, setTextVal] = useState("")
    const [selectedWeek, setSelectedWeek] = useState("") 
    
    const modules = {
        toolbar: [ 
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            
        ],
        }

    const formats = [ 
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 
    ]

    const [startDateTimelineDueDate, setStartDateTimelineDueDate] = useState(new Date())
    const [endDateTimelineDueDate, setEndDateTimelineDueDate] = useState(new Date())
    
    const {params, setFlagNewMember, boardActive, selectedTaskBoard, getTaskDetail, detailTask, setDetailTask, setPriority,
        detailTaskBoard, boardUser, table, sendNotif, setColumnUpdate, workspaceTeams} = useContext(DashboardContext)  

    useEffect(()=>{
        // console.log("selectedTaskBoard", selectedTaskBoard)
        setAlert({...alert, hidden: true})

        // set arr weeks
        let weeks = moment().isoWeeksInYear()
        let arrWeeks = []
        for(let week =1 ; week <= weeks; week++){
            let obj = {}
            if(week.length === 1) week = `0${week}`
            obj.value = week
            obj.selected = false
            arrWeeks.push(obj)
        }
        setWeekArr(arrWeeks)

        // sett memberlist
        let arrTmpMember = [] 
        boardUser.map(item =>{ 
            if(item !== null){
                let tmp = {value: item.id,type: item.type, label: item.username, email: item.email, notify: false}
                arrTmpMember.push(tmp)
            }
          
        }) 
        setMemberList(arrTmpMember) 
        
        if(detailTaskBoard) {
            // console.log("detailTaskBoard", detailTaskBoard)
            // console.log("taskDetail", detailTask)
            detailTaskBoard.map((item, index) => {
                if(index > 0){
                    // mapping assign to
                    if(item.type === "person"){
                        let name = item.name
                        if(name === "") name = []
                        setListName(name)
                        const newMember = _.cloneDeep(memberList)
                        let tmpMember = []
                        _.map(newMember,(val) =>{ 
                            let isExist = _.some(name, function(i) {  
                                return i.value == val.value
                            })
                            if(!isExist && val.type !== "Pending Invitation")
                            tmpMember.push(val)
                        })
                        setOptionMember(tmpMember)
                    }
                    // mapping priority
                    if(detailTask[0][index].type === "priority"){
                        setListPriority(detailTask[0][index].list)
                    }
                    // mapping status
                    if(detailTask[0][index].type === "status"){
                        setListStatus(detailTask[0][index].list)
                    }
                    // mapping start date
                    if(item.type === "date"){
                        if(item.name !=="")
                        setStrDate(moment(item.name, 'DD MMM YYYY').toDate())
                        else setStrDate(moment(moment(), 'DD MMM YYYY').toDate())
                    }
                    // mapping start date
                    if(item.type === "duedate"){
                        if(item.name !=="")
                        setStrDueDate(moment(item.name, 'DD MMM YYYY').toDate())
                        else setStrDueDate(moment(moment(), 'DD MMM YYYY').toDate())
                    }
                    // mapping timeline / timeline_duedate
                    if(item.type === "timeline_duedate" || item.type === "timeline"){
                        if(item.name !== ""){
                            let splitDate = item.name.split("-")
                            if(splitDate.length === 2) {
                                let start = splitDate[0]
                                let end = splitDate[1]
                                setTimelineStart(moment(start, 'DD MMM YYYY').toDate()) 
                                setTimelineEnd(moment(end, 'DD MMM YYYY').toDate()) 
                                
                            }
                        }
                    }
                    // mapping progress
                    if(item.type === "progress"){
                        setValProgress(item.name)
                    }
                    // mapping rating
                    if(item.type === "rating"){
                        setValRating(item.name)
                    }
                    // mapping week
                    if(item.type === "week"){
                        setValWeek(item.name)
                        setValTypeWeek(item.type)
                    }
                    if(item.type === "depedency"){
                        let parent = detailTask[selectedTaskBoard.index_value][index].parent
                        setDataDepedency(taskByGroup(table, parent)) 
                        setValDepedency(item.name)
                        if(item.name === "" || item.name === undefined) {
                            // classMinWH = "w-100 text-center min-wh-column"
                            item.name = []
                        }
                    }
                    if(item.type === "paragraph"){
                        // console.log("nameParagraph", item)
                        setValParagraph(item.name)
                    }
                }
            })
        }
    },[props])

    const handleEditItemProgress = (colIndex, rowIndex, itemId) =>{  
        // console.log("collIndex__", colIndex)
        // console.log("rowIndex__", rowIndex)
        // console.log("itemId", itemId)
        if(refProgress.current !== ""){ 
            if(refProgress.current > 100){ 
                NotificationManager.error("Max value for progress is 100","Failed") 
                return
            }
            let name = refProgress.current.trim()
           
           let  groupName = "", columnName = "", itemName = "", oldStatus = "", rowId =""
           
           let listTmp = _.filter(detailTask, (item, index)=>{ 
              
               if(index === 0) { 
                   groupName = item[1].name  
                   columnName =  item[colIndex].name  
               }
               if(!item[colIndex]) return item
               if(item[parseInt(colIndex)].id === itemId && (rowIndex > 0 ? index > 0 : index  === 0)){ 
            //    if(index === rowIndex){ 
                   oldStatus = item[colIndex].name + "%"
                   item[colIndex].name = name
                //    itemId = item[colIndex].id
                   itemName = item[1].name  
                   rowId = item[1].id  
                   item.map(child => {
                       if(child.type === "person"){
                            if(child.name === "" || rowIndex === 0 || typeof(child.name) !== 'object') return
                           child.name.map(person => {
                               let param = {id:itemId, groupName,value: person.value,columnName,itemName,oldStatus, name: name+ "%", rowId }
                               sendNotification(param) 
                           })
                       }
                   })

                   let createdBy = _.filter(item, (dataItem)=> {return dataItem.type === "add" })
                   if(createdBy.length && createdBy[0].created_id){
                       let param = {id:itemId, groupName,value: createdBy[0].created_id,columnName,itemName,oldStatus, name: name+ "%", rowId}
                       sendNotification(param) 
                   }
               } 
               return item
            })     
           currentListGrid.current = listTmp
           setListGrid(listTmp) 
           setColumnUpdate({id: selectedTaskBoard.table_id, colIndex, rowIndex, name, user_id: params.id, itemId, refresh: true})  
        }
    }

    const handelChangeProgress = (value) => {
        refProgress.current = value.replace(/\&nbsp;/g, ' ')   
    }

    const handleEditItem = (colIndex, rowIndex, itemId) =>{    
        // console.log("colIndex__", colIndex)
        // console.log("rowIndex__", rowIndex)
        // console.log("itemId__", itemId)
        if(rowIndex === 0 && colIndex !== 1){
            table.map((tab, idx) => { 
                asyncRequest(100).then(()=>{
                    doHandleEditItem(colIndex, rowIndex, itemId, tab.id, idx)
                    if(idx === table.length - 1) refEditable.current = ""
                })
            }) 
        }else {
            doHandleEditItem(colIndex, rowIndex, itemId, currentListId.current, table.length)
            refEditable.current = ""
        }
        
    }

    const handleSelectedPriority = (rowIndex, colIndex, value, itemId) =>{ 
        // console.log("rowIndex__", rowIndex) 
        // console.log("colIndex___", colIndex)
        // console.log("value___", value)
        // console.log("itemId__", itemId)
        // return
        let groupName = "", columnName = "", itemName = "", oldStatus = "", rowId
        
        let listTmp = _.filter(detailTask, (item, index)=>{  
           
            if(index === 0) { 
                groupName = item[1].name  
                columnName =  item[colIndex].name  
            } 
            if(!item[colIndex]) return item
            if(item[parseInt(colIndex)].id === itemId && (rowIndex > 0 ? index > 0 : index  === 0)){ 
            // if(index === rowIndex){ 
                oldStatus = item[colIndex].name.name
                item[colIndex].name = value
                itemId = item[colIndex].id
                itemName = item[1].name  
                rowId = item[1].id  
                item.map(child => {
                    if(child.type === "person"){
                        if(child.name === "" || rowIndex === 0 || typeof(child.name) !== 'object')  return
                        child.name.map(person => {
                            let param = {id:value.id, groupName,value: person.value,columnName,itemName,oldStatus, name: value.name, rowId }
                            sendNotification(param) 
                        })
                    }
                })
                let createdBy = _.filter(item, (dataItem)=> {return dataItem.type === "add" })
                if(createdBy.length && createdBy[0].created_id){
                    let param = {id:itemId, groupName,value: createdBy[0].created_id,columnName,itemName,oldStatus, name: value.name, rowId}
                    sendNotification(param) 
                }
            } 
            return item
        })      
        ref.click()
        let name = value
        setListGrid(listTmp)   
        currentListId.current = listTmp
        setColumnUpdate({id: selectedTaskBoard.table_id, colIndex, rowIndex, name,itemId, user_id: params.id, refresh:true, itemId})
         
 
    }

    const handleNewDataUpdated = async () => {
        // console.log("running__")
        let getData = await getTaskDetail({table_id: selectedTaskBoard.table_id, board_id: selectedTaskBoard.board_id, index_value: selectedTaskBoard.index_value})
        // console.log("getData__", getData)
        // console.log("newTaskDEtailBoard=======", detailTaskBoard)
    }

    const doHandleEditItem = (colIndex, rowIndex, itemId, id, idx) =>{   
        let name = refEditable.current.trim()  
        if(name !== ""){    
           let groupName = "", columnName = "", itemName = "", oldStatus = "", rowId = ""
            let list =  currentListGrid.current 
            let listTmp = _.filter(list, (item, index)=>{   
               if(index === 0) { 
                   groupName = item[1].name  
                   columnName =  item[colIndex].name  
               }
               if(!item[colIndex]) return item
               if(item[parseInt(colIndex)].id === itemId && (rowIndex > 0 ? index > 0 : index  === 0)){ 
                   
                
                    oldStatus = item[colIndex].name
                    item[colIndex].name = name 
                    itemName = item[1].name  
                    rowId = item[1].id  
                    item.map(child => {
                        if(child.type === "person"){ 
                            if(child.name === "" || rowIndex === 0 || typeof(child.name) !== 'object') return
                            child.name.map(person => {
                                let param = {id:itemId, groupName,value: person.value,columnName,itemName,oldStatus, name: name, rowId}
                                sendNotification(param) 
                            })
                        }
                    })

                    // Notif to created task
                    let createdBy = _.filter(item, (dataItem)=> {return dataItem.type === "add" })
                    if(createdBy.length && createdBy[0].created_id){
                        let param = {id:itemId, groupName,value: createdBy[0].created_id,columnName,itemName,oldStatus, name: name, rowId}
                        sendNotification(param) 
                    }
               } 
               return item
            })          
           currentListGrid.current = listTmp
           setListGrid(listTmp)
           setColumnUpdate({id: selectedTaskBoard.table_id, colIndex, rowIndex, name, user_id: params.id, itemId, username: params.username, refresh : true})  
        //    handleNewDataUpdated()
            setTextVal(name)
        } 
    }

    const sendNotification = (param) => {

        let message  =  `${params.username} has been Updated ${param.columnName} "${param.itemName.trim()}" from "${param.oldStatus ? param.oldStatus : ""}" to "${param.name}"`
        if(param.fromTimeLine)  message = `${params.username} has been Updated ${param.columnName} "${param.itemName.trim()}"  to "${param.name}"`
        if(param.value !== params.id){
            let data = {
                receiver_id : param.value,
                sender_id: params.id,
                board_id : boardActive.id,
                workspace_id : boardActive.workspace_id ,
                team_id: boardActive.team_id,
                message : message,
                task_name: param.itemName.trim(),
                is_read: false,
                group_id: props.item.id,
                item_id: param.id,
                group_name: param.groupName,
                created_at: new Date(),
                updated_at: new Date(),
                row_id: param.rowId,
                type: 4}
             sendNotif(data)
         }else {
            workspaceTeams.map(item =>{ 
                if(item.permission.id < 4){
                    let data = {
                        receiver_id : item.user_id,
                        sender_id: params.id,
                        board_id : boardActive.id,
                        workspace_id : boardActive.workspace_id ,
                        team_id: boardActive.team_id,
                        message :message,
                        task_name: param.itemName.trim(),
                        is_read: false,
                        group_id: props.item.id,
                        item_id: param.id,
                        group_name: param.groupName,
                        created_at: new Date(),
                        updated_at: new Date(),
                        row_id: param.rowId,
                        type: 4}
                     sendNotif(data)
                }
            })
         }
    }

    const handelChangeEditable = (value) => {
        refEditable.current = value.replace(/\&nbsp;/g, ' ')   
    }

    const handleEnterItem  = (event, colIndex, rowIndex, id) => {
        var keyCode = event.which || event.keyCode;
        if(keyCode === 13) {  
            handleEditItem(colIndex, rowIndex, id)
        }
    }

    const onChangeTimelineDueDate = (rowIndex, columnIndex, dates, timelineStart, timelineEnd, itemId) => {
        refTimeLineDueDate.current = false
        const [start, end] = dates
        // timelineStart = start
        // timelineEnd = end
        setStartDateTimelineDueDate(start)
        setEndDateTimelineDueDate(end)
        let date = `${moment(start).format('DD MMM YYYY')} - ${moment(end).format('DD MMM YYYY')}`
        if(date.includes("Invalid date") > 0) date = moment(start).format('DD MMM YYYY')
        handleSelectedOption(itemId, rowIndex, columnIndex, date, true) 
    }

    const handleSelectedOption = (itemId, rowIndex, colIndex , value, fromTimeLine = false) =>{  
        // console.log("colIndex__", colIndex)
        // console.log("rowIndex__", rowIndex)
        // console.log("itemId__", itemId)
        // console.log("value__", value)
        
        let groupName = "", columnName = "", itemName = "", oldStatus = "", rowId =""
        let listTmp = _.filter(detailTask, (item, index)=>{ 
            
            if(index === 0) { 
                groupName = item[1].name  
                columnName =  item[colIndex].name  
            }
            if(!item[colIndex]) return item
            if(item[parseInt(colIndex)].id === itemId && (rowIndex > 0 ? index > 0 : index  === 0)){ 
            // if(index === rowIndex){ 
                oldStatus = item[colIndex].name 
                item[colIndex].name = value
                itemId = item[colIndex].id
                itemName = item[1].name  
                rowId = item[1].id  
                item.map(child => {
                    if(child.type === "person"){
                        if(child.name === "" || rowIndex === 0 || typeof(child.name) !== 'object') return
                        child.name.map(person => { 
                            if(fromTimeLine && !value.includes("-")) return
                            let param = {id:value, fromTimeLine : fromTimeLine,  groupName,value: person.value,columnName,itemName,oldStatus, name: value, rowId }
                            sendNotification(param) 
                        })
                    }
                })
                let createdBy = _.filter(item, (dataItem)=> {return dataItem.type === "add" })
                if(createdBy.length && createdBy[0].created_id){
                    let param = {id:itemId, groupName,value: createdBy[0].created_id,columnName,itemName,oldStatus, name: value, rowId}
                    sendNotification(param) 
                }
            } 
            return item
        })      

        if(!fromTimeLine){ 
            if(ref.current !== null){
                ref.click()
            }
        }
        let name = value.trim()
        currentListGrid.current = listTmp
        // console.log("listTmp__", listTmp)
        setListGrid(listTmp)
        setColumnUpdate({id: selectedTaskBoard.table_id, colIndex, rowIndex, name,itemId, user_id: params.id, refresh: true})
        // handleNewDataUpdated()
    }

    const handleClickWeekly = (items,rowIndex, columnIndex, id) =>{
        // console.log("items__", items)
        // console.log("rowIndex__", rowIndex)
        // console.log("columnIndex__", columnIndex)
        // console.log("id__", id)
        let weeksTmp = arrWeeks
        _.map(weeksTmp,(item)=> {
            item.selected = false
            if(item.value === items.value) item.selected = true 
        })
        handleSelectedOption(id, rowIndex, columnIndex,`Week ${items.value}`)
        setSelectedWeek(items.value)
        setWeekArr(weeksTmp) 
    }

    const handleEditItemNumber = (colIndex, rowIndex, itemId) =>{   
        let name = refNumber.current 
        if(name !== ""){ 
            let  groupName = "", columnName = "", itemName = "", oldStatus = "", rowId = ""
            let listTmp = _.filter(listGrid, (item, index)=>{ 
            
                if(index === 0) { 
                    groupName = item[1].name  
                    columnName =     item[colIndex].name  
                }
                if(!item[colIndex]) return item
                if(item[parseInt(colIndex)].id === itemId && (rowIndex > 0 ? index > 0 : index  === 0)){ 
                // if(index === rowIndex){ 
                    oldStatus = item[colIndex].name
                    item[colIndex].name = name
                    // itemId = item[colIndex].id
                    itemName = item[1].name  
                    rowId = item[1].id  
                    item.map(child => {
                        if(child.type === "person"){
                            if(child.name === "" || rowIndex === 0 || typeof(child.name) !== 'object') return
                            child.name.map(person => {
                                let param = {id:itemId, groupName,value: person.value,columnName,itemName,oldStatus, name: name, rowId}
                                sendNotification(param) 
                            })
                        }
                    })

                    let createdBy = _.filter(item, (dataItem)=> {return dataItem.type === "add" })
                    if(createdBy.length && createdBy[0].created_id){
                        let param = {id:itemId, groupName,value: createdBy[0].created_id,columnName,itemName,oldStatus, name: name, rowId}
                        sendNotification(param) 
                    }
                } 
                return item
             })     
           currentListGrid.current = listTmp
           setListGrid(listTmp) 
           setColumnUpdate({id: selectedTaskBoard.table_id, colIndex, rowIndex, name, user_id: params.id, itemId, refresh: true})  
        }
    }

    const handelChangeNumber = (value) => {   
        if(value !== " "){
            value = value.replace(/\&nbsp;/g, '')
            value = value.trim()  
            refNumber.current = parseInt(value) 
        }
    }

    const handleEnterItemNumber  = (event, colIndex, rowIndex, id) => {
        var keyCode = event.which || event.keyCode;
        if(keyCode === 13) {  
            handleEditItemNumber(colIndex, rowIndex, id)
        }
    }
    
    const onSubmit = async (paramTmp) => {
        setLoading(true) 
        let param = _.cloneDeep(params) 
        param.email = paramTmp.email.toLowerCase()
        param.username = paramTmp.username
        param.board_id = boardActive.id
        param.password = paramTmp.password 
        const result = await MakeRequest('post','user-create-member', param)   
        if(result.status !== 200) {
           setAlert({msg: result.data.detail, variant: 'danger', hidden: false})
           setLoading(false)
        }else{
            setAlert({msg: 'Create member successfully', variant: 'success', hidden: false})
            simulateNetworkRequest().then(() => {
                setAlert({ hidden: false})
                setFlagNewMember(new Date().getUTCMilliseconds())
                props.onHide()
                setLoading(false)
            })
            
        }
        
    }
    
    function simulateNetworkRequest() {
        return new Promise((resolve) => setTimeout(resolve, 1000));
    }

    const popoverPeople = (rowIndex, columnIndex, name, id) => {
        // if(name === "") name = []
        // const newMember = _.cloneDeep(memberList)
        // let tmpMember = []
        // _.map(newMember,(item) =>{ 
        //     let isExist = _.some(name, function(i) {  
        //         return i.value == item.value
        //     })
        //     if(!isExist && item.type !== "Pending Invitation")
        //     tmpMember.push(item)
        // }) 

        return (
            <Popover id="popover-basic">  
                <Popover.Content>
                    <div className="pt-3 pb-2">
                        <Select styles={StyleSelect({menuListHeight: 150, width: 220})} 
                            focus
                            isClearable={false}
                            placeholder ="Select People" maxMenuHeight={150} 
                            options={optionMember}
                            isMulti onChange={(selected)=>{}}
                            value={[]}
                        /> 
                    </div>

                    {listName && listName.map((item, key) => { 
                            return(
                                <Nav.Link className="nav-menu p-2 w-100 mt-2" key={key}> 
                                    <Row>
                                        <Col md="12">
                                            <Image src={AvatarIcon}></Image>
                                            <span className="workspace-item-name-child align-text-top ml-2 font-14">{item.label}</span>
                                            <Image src={RemoveIcon} className="float-right align-middle mt-1 cursor-pointer"></Image>
                                        </Col>  
                                    </Row> 
                                </Nav.Link> 
                            )
                        })}
                    <div className="spacer pt-2"></div>
                    <Nav.Link className="nav-menu p-2 mt-3 mb-2"> 
                        <Image src={AddSmallIcon}></Image>
                        <span className="workspace-item-name-child align-text-top ml-2 font-14 text-primary">Add new member</span>
                    </Nav.Link> 
                    
                </Popover.Content> 
            </Popover>
        ) 
    }

    const popoverPriority = (rowIndex, columnIndex, items, id) => {
        // if (detailTask[0][columnIndex].type === "priority"){
        //     setListPriority(detailTask[0][columnIndex].list)
        //     console.log("listPriority", listPriority)
        // }
        // console.log("aaaaaaaaaa", columnIndex)
        // console.log(detailTask[0][columnIndex])
        // if(detailTask) {
        //     // mapping list priority
        //     // console.log("detailTask", detailTask[0][columnIndex].list)
        //     setListPriority(detailTask[0][columnIndex].list)
        // }
        
        return (
            <Popover id="popover-basic"> 
                <Popover.Content className="mt-2">
                    {listPriority && listPriority.map((item,key)=>{
                        return(
                            <div key={key} className="status-picker" style={{background: item.color}} onClick={()=>handleSelectedPriority(selectedTaskBoard.index_value, columnIndex, item, id)}>
                            {/* <div key={key} className="status-picker" style={{background: item.color}}> */}
                                <span className="font-14">{stripHtmlAnd(item.name)}</span>
                            </div>
                        )
                    })} 
                <div className="spacer pt-2"></div>
                    {/* <Nav.Link className="nav-menu p-2 mt-2" onClick={()=>{setShowPriority({isShow: true, data: listPriority, id, idHeader}); ref.click()}}> 
                        <Image src={AddSmallIcon}></Image>
                        <span className="workspace-item-name-child align-text-top ml-2 font-13 text-primary">Add/Edit Labels</span>
                    </Nav.Link>  */}
                    <Nav.Link className="nav-menu p-2 mt-2"> 
                        <Image src={AddSmallIcon}></Image>
                        <span className="workspace-item-name-child align-text-top ml-2 font-13 text-primary">Add/Edit Labels</span>
                    </Nav.Link>
                </Popover.Content>
            </Popover>
        )
    }

    const popoverStatus = (rowIndex, columnIndex, items, id) => {
       if(!listStatus) return
        return (
            <Popover id="popover-basic"> 
                <Popover.Content className="mt-2"> 
                <Row style={{width: listStatus.length > 5 ? 500 : 165}}>
                    {listStatus && listStatus.map((item,key)=>{
                        return(
                            <Col key={key} md={listStatus.length > 5 ? 4 : 12}>
                                <div key={key} className="status-picker" style={{background: item.color}} onClick={()=>handleSelectedPriority(selectedTaskBoard.index_value, columnIndex, item, id)}>
                                {/* <div key={key} className="status-picker" style={{background: item.color}}> */}
                                    <span className="font-14 span-label-popover ">{stripHtmlAnd(item.name)}</span>
                                </div>
                            </Col>
                        )
                    })} 
                </Row>

                <div className="spacer pt-2"></div>   
                    <Nav.Link className="nav-menu p-2 mt-2"> 
                        <Image src={AddSmallIcon}></Image>
                        <span className="workspace-item-name-child align-text-top ml-2 font-13 text-primary">Add/Edit Labels</span>
                    </Nav.Link> 
                </Popover.Content>
            </Popover>
        )
    }

    const popoverDate = (rowIndex, columnIndex, id) => {
        return (
            <Popover id="popover-basic"> 
                <Popover.Content className="mt-2">
                    <DatePicker selected={strDate} onChange={(date)=>handleSelectedOption(id, rowIndex, columnIndex, moment(date).format('DD MMM YYYY'))} inline/>
                    {/* <DatePicker selected={strDate} onChange={(date)=>alert(date)} inline/> */}
                </Popover.Content>
                
            </Popover>
        )
    }

    const popoverDueDate = (rowIndex, columnIndex, id) => {
        return (
            <Popover id="popover-basic"> 
                <Popover.Content className="mt-2"> 
                    <DatePicker selected={strDueDate} onChange={(date)=>handleSelectedOption(id, rowIndex, columnIndex, moment(date).format('DD MMM YYYY'))} inline/>
                    {/* <DatePicker 
                    selected={strDueDate}
                    onChange={(date)=>alert(date)} inline/> */}
                </Popover.Content>
                
            </Popover>
        )
    }

    const popoverTimelineDueDate = (rowIndex, columnIndex, start, end, itemId) => {
        return (
            <Popover id={startDateTimelineDueDate}>  
                <Popover.Content className="mt-2">
                    <DatePicker
                    selected={refTimeLineDueDate.current ? timelineStart : startDateTimelineDueDate}
                    startDate={refTimeLineDueDate.current ? timelineStart : startDateTimelineDueDate}
                    endDate={refTimeLineDueDate.current ? timelineEnd : endDateTimelineDueDate}
                    onChange={(dates) =>onChangeTimelineDueDate(rowIndex, columnIndex, dates, timelineStart, timelineEnd, itemId)}
                    selectsRange   
                    minDate={strDate}
                    maxDate={strDueDate}
                    inline/>
                </Popover.Content>
            </Popover>
        ) 
    }

    const popoverProgress = (rowIndex, columnIndex, name, id) => {
        return (
            <Popover id="popover-basic"> 
                <Popover.Content className="mt-2">
                    <Form.Control type="number" name="progress"
                    className="font-14"
                    html= {refProgress.current}  
                    defaultValue={name}
                    onBlur={()=>handleEditItemProgress(columnIndex, rowIndex, id)}
                    onChange={(e)=> {handelChangeProgress(e.target.value)}} 
                    /> 
                </Popover.Content> 
            </Popover>
        )
    }

    const popoverWeek = (rowIndex, columnIndex, name, type, id) => { 
        return (
            <Popover id="popover-basic-week" className="test" style={{width: '275px !important'}}> 
                <Popover.Content className="mt-2"> 
                    <div className="react-datepicker">
                    <button type="button" className="react-datepicker__navigation react-datepicker__navigation--previous" aria-label="Previous Month">Previous Month</button>
                    <button type="button" className="react-datepicker__navigation react-datepicker__navigation--next" aria-label="Next Month">Next Month</button>
                        <div className="react-datepicker__month-container">
                            <div className="react-datepicker__header "> 
                            <div className="react-datepicker__current-month pb-2">
                                2020
                            </div>
                            </div>
                            <div className="react-datepicker__month"> 
                                <Row className="ml-1">
                                    {arrWeeks.map((item, index) => {  
                                        //=========SET DEFAULT SELECTED===========# 
                                        item.selected = false
                                        if((valTypeWeek === "week")  && (item.value === parseInt(valWeek.replace("Week ","")))) item.selected = true
                                        return(
                                            <>   
                                                <div key={item.value} onClick={()=>handleClickWeekly(item, rowIndex, columnIndex, id)}
                                                className= {item.selected ? 
                                                    "react-datepicker__day--selected react-datepicker__day react-datepicker__day--001 react-datepicker__day--weekend" : 
                                                    "react-datepicker__day react-datepicker__day--001 react-datepicker__day--weekend"} 
                                                    tabIndex="-1" role="button" aria-disabled="false">
                                                    {item.value}
                                                </div> 
                                            </>
                                        )
                                    })}  
                                </Row>
                            </div>
                            </div>
                        </div>
                </Popover.Content>
                
            </Popover>
        )
    }

    const popoverDepedency = (id,rowIndex, columnIndex, name, dataDepedencys) => { 
        return (
            <Popover id="popover-basic">  
                <Popover.Content className="mt-2">
                    <Select styles={StyleSelect({menuListHeight: 250, width: 300})} 
                    placeholder ="Select Task" maxMenuHeight={150} options={dataDepedency}
                    isMulti onChange={(selected)=>{alert("halo")}} 
                    value = {valDepedency}
                    /> 
                    
                </Popover.Content> 
            </Popover>
        ) 
    }

    const popoverParagraph = (rowIndex, columnIndex, name, id) => {
        return (
            <Popover id="popover-basic-option"> 
                <Popover.Content>
                    <ReactQuill className="mt-1" theme="snow"
                        value={valParagraph}   
                        // onBlur={()=>handleEditItemParagraph(columnIndex, rowIndex, id)}
                        // onChange={(e)=> {handelChangeParagraph(e)}} 
                        modules={modules} 
                        formats={formats} 
                    />  
                </Popover.Content>
            </Popover>
        )
    }

    return (
        <Modal
        {...props}
        size="lg"
        id="modal-add-member"
        aria-labelledby="contained-modal-title-vcenter"
        >
            {/* <Form onSubmit={handleSubmit(onSubmit)}>  */}
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="p-2" style={{fontSize: "16px"}}>
                    Detail Task
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="m-2" ref={r => (ref = r)} style={{maxHeight: "450px", overflowY: "scroll"}}> 

                {detailTaskBoard.map((item, index) => {
                    if(index > 0){
                        if(index === 1 && item.type === "text"){
                            return (
                                <div className="row mt-2 mr-1">
                                    <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Title Task</div>
                                    <div className="col" style={{border: "1px solid", borderColor: "#91979D", borderRadius: "5px", paddingLeft: "5px", height: "38px", paddingTop: "5px"}}>
                                        <ContentEditable   
                                            // html= {(typeof item.name !== "string") ? "" : (textVal !== "" textVal : item.name)} 
                                            html= {textVal !== "" ? textVal : item.name} 
                                            key={index} 
                                            disabled = { params.permission_id < 4 ? false : true}
                                            // style={{width : '95% !important'}} 
                                            // onClick={() => {handleOpenModalDetail(params.selectedBoard.id, columnIndex, rowIndex, id)}}
                                            // onClick={() => {modalDetailTask(columnIndex, rowIndex, id)}}
                                            onBlur={()=> {handleEditItem(index, selectedTaskBoard.index_value, item.id)}}
                                            onChange={(e)=> {handelChangeEditable(e.target.value.replace(/<\/?[^>]+(>|$)/g, "")+ " ")}} 
                                            onKeyDown={(e)=>{handleEnterItem(e, index, selectedTaskBoard.index_value, item.id)}} 
                                        />
                                    </div>
                                </div> 
                            )
                        }else if (item.type === "person"){
                            if(params.permission_id < 4){
                                return (
                                    <OverlayTrigger trigger="click" placement="bottom" overlay={popoverPeople( "rowIndex", "columnIndex", "name", "id")} rootClose>
                                        <div className="row mt-2 mr-1">
                                            <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Assign To</div>
                                            <div className="col" style={{paddingLeft: "20px !important", border: "1px solid", borderColor: "#91979D", borderRadius: "5px", padding: "10px", height: "38px"}}>
                                                {item.name === "" ? null
                                                    : item.name.length > 1 ? "+ 2"
                                                        : item.name.length === 1 ? item.name[0].label
                                                            : null
                                                }
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                )
                            }else{
                                return (
                                    <OverlayTrigger trigger="click" placement="bottom" overlay={
                                        <Tooltip id="tooltip">
                                            You can only view this board.
                                        </Tooltip>
                                    } rootClose>
                                        <div className="row mt-2 mr-1">
                                            <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Assign To</div>
                                            <div className="col" style={{paddingLeft: "20px !important", border: "1px solid", borderColor: "#91979D", borderRadius: "5px", padding: "10px", height: "38px"}}>
                                                {item.name === "" ? null
                                                    : item.name.length > 1 ? "+ 2"
                                                        : item.name.length === 1 ? item.name[0].label
                                                            : null
                                                }
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                )
                            }
                        }else if (item.type === "priority"){
                            if(params.permission_id < 4){
                                return (
                                    <OverlayTrigger trigger="click" placement="bottom" overlay={popoverPriority("rowIndex", index, "item", item.id)} rootClose>
                                        <div className="row mt-2 mr-1">
                                            <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Priority</div>
                                            <div className="col" style={{paddingLeft: "20px !important", border: item.name === "" ? "1px solid" : "0px solid", borderRadius: "5px", borderColor: item.name !== "" ? item.name.color : "#91979D", padding: "10px", backgroundColor: item.name !== "" ? item.name.color : "white", height: "38px", textAlign: "center", color: item.name !== "" ? "white" : "black"}}>
                                                {item.name === "" ? null
                                                    : item.name.name
                                                }
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                )
                            }else{
                                return (
                                    <OverlayTrigger trigger="click" placement="bottom" overlay={
                                        <Tooltip id='tooltip'>
                                            You can only view this board
                                        </Tooltip>
                                    } rootClose>
                                        <div className="row mt-2 mr-1">
                                            <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Priority</div>
                                            <div className="col" style={{paddingLeft: "20px !important", border: item.name === "" ? "1px solid" : "0px solid", borderRadius: "5px", borderColor: item.name !== "" ? item.name.color : "#91979D", padding: "10px", backgroundColor: item.name !== "" ? item.name.color : "white", height: "38px", textAlign: "center", color: item.name !== "" ? "white" : "black"}}>
                                                {item.name === "" ? null
                                                    : item.name.name
                                                }
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                )
                            }
                        }
                        else if (item.type === "status"){
                            if(params.permission_id < 4){
                                return (
                                    <OverlayTrigger trigger="click" placement="bottom" overlay={popoverStatus("rowIndex", index, "item", item.id)} rootClose>
                                        <div className="row mt-2 mr-1">
                                            <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Status</div>
                                            <div className="col" style={{paddingLeft: "20px !important", border: item.name === "" ? "1px solid" : "0px solid", borderRadius: "5px", borderColor: item.name !== "" ? item.name.color : "#91979D", padding: "10px", backgroundColor: item.name !== "" ? item.name.color : "white", height: "38px", textAlign: "center", color: item.name !== "" ? "white" : "black"}}>
                                                {item.name === "" ? null
                                                    : item.name.name
                                                }
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                )
                            }else{
                                return (
                                    <OverlayTrigger trigger="click" placement="bottom" overlay={
                                        <Tooltip id="tooltip">
                                            You can only view this board.
                                        </Tooltip>
                                    } rootClose>
                                        <div className="row mt-2 mr-1">
                                            <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Status</div>
                                            <div className="col" style={{paddingLeft: "20px !important", border: item.name === "" ? "1px solid" : "0px solid", borderRadius: "5px", borderColor: item.name !== "" ? item.name.color : "#91979D", padding: "10px", backgroundColor: item.name !== "" ? item.name.color : "white", height: "38px", textAlign: "center", color: item.name !== "" ? "white" : "black"}}>
                                                {item.name === "" ? null
                                                    : item.name.name
                                                }
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                )
                            }
                        }else if (item.type === "date"){
                            if(params.permission_id < 4){
                                return (
                                    <OverlayTrigger trigger="click" placement="top" overlay={popoverDate(selectedTaskBoard.index_value, index, item.id)} rootClose>
                                        <div className="row mt-2 mr-1">
                                            <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Date</div>
                                            <div className="col" style={{paddingLeft: "20px !important", padding: "10px", border: "1px solid", borderColor: "#91979D", borderRadius: "5px", height: "38px"}}>
                                                {item.name}
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                )
                            }else{
                                return (
                                    <OverlayTrigger trigger="click" placement="top" overlay={
                                        <Tooltip>
                                            You can only view this board.
                                        </Tooltip>
                                    } rootClose>
                                        <div className="row mt-2 mr-1">
                                            <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Date</div>
                                            <div className="col" style={{paddingLeft: "20px !important", padding: "10px", border: "1px solid", borderColor: "#91979D", borderRadius: "5px", height: "38px"}}>
                                                {item.name}
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                )
                            }
                        }else if (item.type === "duedate"){
                            if(params.permission_id < 4){
                                return (
                                    <OverlayTrigger trigger="click" placement="top" overlay={popoverDueDate(selectedTaskBoard.index_value, index, item.id)} rootClose>
                                        <div className="row mt-2 mr-1">
                                            <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Due Date</div>
                                            <div className="col" style={{paddingLeft: "20px !important", padding: "10px", border: "1px solid", borderColor: "#91979D", borderRadius: "5px", height: "38px"}}>
                                                {item.name}
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                )
                            }else{
                                return (
                                    <OverlayTrigger trigger="click" placement="top" overlay={
                                        <Tooltip id="tooltip">
                                            You can only view this board.
                                        </Tooltip>
                                    } rootClose>
                                        <div className="row mt-2 mr-1">
                                            <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Due Date</div>
                                            <div className="col" style={{paddingLeft: "20px !important", padding: "10px", border: "1px solid", borderColor: "#91979D", borderRadius: "5px", height: "38px"}}>
                                                {item.name}
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                )
                            }
                        }else if (item.type === "timeline_duedate"){
                            if(params.permission_id < 4){
                                return (
                                    <OverlayTrigger trigger="click" placement="top" overlay={popoverTimelineDueDate(selectedTaskBoard.index_value, index, "timelineStart", "timelineEnd", item.id)} rootClose>
                                        <div className="row mt-2 mr-1">
                                            <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Timeline</div>
                                            <div className="col" style={{paddingLeft: "20px !important", border: "1px solid", borderColor: "#91979D", borderRadius: "5px", padding: "10px", height: "38px", backgroundColor: item.name !== "" ? "#91979D" : "white", color: item.name !== "" ? "white" : "black"}}>
                                                {item.name}
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                )
                            }else{
                                return (
                                    <OverlayTrigger trigger="click" placement="top" overlay={
                                        <Tooltip id="tooltip">
                                            You can only view this board.
                                        </Tooltip>
                                    } rootClose>
                                        <div className="row mt-2 mr-1">
                                            <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Timeline</div>
                                            <div className="col" style={{paddingLeft: "20px !important", border: "1px solid", borderColor: "#91979D", borderRadius: "5px", padding: "10px", height: "38px", backgroundColor: item.name !== "" ? "#91979D" : "white", color: item.name !== "" ? "white" : "black"}}>
                                                {item.name}
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                )
                            }
                        }else if (item.type === "progress"){
                            if(params.permission_id < 4){
                                return (
                                    <OverlayTrigger trigger="click" placement="top" overlay={popoverProgress(selectedTaskBoard.index_value, index, item.name, item.id)} rootClose>
                                        <div className="row mt-2 mr-1">
                                            <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Progress</div>
                                            <div className="col" style={{paddingLeft: "20px !important", padding: "10px", height: "38px"}}>
                                                <div className="w-75 text-center d-inline-block">  
                                                    <ProgressBar now={item.name} style={{height: '12px'}}/>  
                                                </div>  
                                                <span className="w-25 font-12  text-right float-right">{item.name ? item.name: 0} %</span>
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                )
                            }else{
                                return (
                                    <OverlayTrigger trigger="click" placement="top" overlay={
                                        <Tooltip id="tooltip">
                                            You can only view this board.
                                        </Tooltip>
                                    } rootClose>
                                        <div className="row mt-2 mr-1">
                                            <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Progress</div>
                                            <div className="col" style={{paddingLeft: "20px !important", padding: "10px", height: "38px"}}>
                                                <div className="w-75 text-center d-inline-block">  
                                                    <ProgressBar now={item.name} style={{height: '12px'}}/>  
                                                </div>  
                                                <span className="w-25 font-12  text-right float-right">{item.name ? item.name: 0} %</span>
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                )
                            }
                        }else if (item.type === "rating"){
                            return (
                                // <OverlayTrigger trigger="click" placement="bottom" overlay={popoverStatus("rowIndex", "columnIndex", "id", listStatus, "idHeader")} rootClose>
                                    <div className="row mt-2 mr-1">
                                        <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Rating</div>
                                        <div className="col" style={{paddingLeft: "5px", padding: "10px", height: "38px"}}>
                                            <Rating
                                                emptySymbol="fa fa-star-o fa-2x font-18"
                                                fullSymbol="fa fa-star fa-2x color-rating font-18" 
                                                onChange= {(rating) => handleSelectedOption(item.id, selectedTaskBoard.index_value, index,rating+"")}
                                                initialRating={item.name}
                                                fractions={1}
                                            />
                                        </div>
                                    </div>
                                // </OverlayTrigger>
                            )
                        }else if (index !== 1 && item.type === "text") {
                            return (
                                <div className="row mt-2 mr-1">
                                    <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Text</div>
                                    <div className="col" style={{border: "1px solid", borderColor: "#91979D", borderRadius: "5px", paddingLeft: "5px", height: "38px", paddingTop: "5px"}}>
                                        <ContentEditable   
                                            html= {typeof item.name !== "string"? "" : item.name} 
                                            key={index} 
                                            disabled = { params.permission_id < 4 ? false : true }
                                            // style={{width : '95% !important'}} 
                                            // onClick={() => {handleOpenModalDetail(params.selectedBoard.id, columnIndex, rowIndex, id)}}
                                            onBlur={()=> {handleEditItem(index, selectedTaskBoard.index_value, item.id)}}
                                            onChange={(e)=> {handelChangeEditable(e.target.value.replace(/<\/?[^>]+(>|$)/g, "")+ " ")}} 
                                            onKeyDown={(e)=>{handleEnterItem(e, index, selectedTaskBoard.index_value, item.id)}}
                                        />
                                    </div>
                                </div>
                            )
                        }else if (item.type === "file") {
                            return (
                                <div className="row mt-2 mr-1">
                                    <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>File</div>
                                    <div className="col" style={{border: "1px solid", borderColor: "#91979D", borderRadius: "5px", paddingLeft: "5px", height: "38px", paddingTop: "5px"}}>
                                        <ContentEditable   
                                            html= {typeof item.name !== "string"? "" : item.name} 
                                            key={index} 
                                            // disabled = { params.permission_id < 4 ? 
                                            //     params.permission_id < 4 || isMyTask  ? false : true : true}
                                            // style={{width : '95% !important'}} 
                                            // onClick={() => {handleOpenModalDetail(params.selectedBoard.id, columnIndex, rowIndex, id)}}
                                            // onClick={() => {modalDetailTask(columnIndex, rowIndex, id)}}
                                            // onBlur={()=> {handleEditItem(columnIndex, rowIndex, id)}}
                                            // onChange={(e)=> {handelChangeEditable(e.target.value.replace(/<\/?[^>]+(>|$)/g, "")+ " ")}} 
                                            // onKeyDown={(e)=>{handleEnterItem(e, columnIndex, rowIndex, id)}} 
                                        />
                                    </div>
                                </div>
                            )
                        }else if (item.type === "week") {
                            if(params.permission_id < 4){
                                return (
                                    <OverlayTrigger trigger="click" placement="bottom" overlay={popoverWeek(selectedTaskBoard.index_value, index, "type", "name", item.id)} rootClose>
                                        <div className="row mt-2 mr-1">
                                            <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Week</div>
                                            <div className="col" style={{paddingLeft: "20px !important", border: "1px solid", borderColor: "#91979D", borderRadius: "5px", padding: "10px", height: "38px"}}>
                                                {item.name}
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                )
                            }else{
                                return (
                                    <OverlayTrigger trigger="click" placement="bottom" overlay={
                                        <Tooltip id="tooltip">
                                            You can only view this board.
                                        </Tooltip>
                                    } rootClose>
                                        <div className="row mt-2 mr-1">
                                            <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Week</div>
                                            <div className="col" style={{paddingLeft: "20px !important", border: "1px solid", borderColor: "#91979D", borderRadius: "5px", padding: "10px", height: "38px"}}>
                                                {item.name}
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                )
                            }
                        }
                        else if (item.type === "depedency") {
                            if(params.permission_id < 4){
                                return (
                                    <OverlayTrigger trigger="click" placement="bottom" overlay={popoverDepedency("rowIndex", "columnIndex", "type", "name", "id")} rootClose>
                                        <div className="row mt-2 mr-1">
                                            <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Depedency</div>
                                            <div className="col" style={{paddingLeft: "20px !important", padding: "10px", border: "1px solid", borderColor: "#91979D", borderRadius: "5px", height: "38px"}}>
                                                {item.name.length ? item.name.length === 1 ? item.name[0].label : "+ 2" : ""}
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                )
                            }else{
                                return (
                                    <OverlayTrigger trigger="click" placement="bottom" overlay={
                                        <Tooltip id="tooltip">
                                            You can only view this board.
                                        </Tooltip>
                                    } rootClose>
                                        <div className="row mt-2 mr-1">
                                            <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Depedency</div>
                                            <div className="col" style={{paddingLeft: "20px !important", padding: "10px", border: "1px solid", borderColor: "#91979D", borderRadius: "5px", height: "38px"}}>
                                                {item.name.length ? item.name.length === 1 ? item.name[0].label : "+ 2" : ""}
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                )
                            }
                        }else if (item.type === "last_update") {
                            return (
                                <div className="row mt-2 mr-1">
                                    <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Last Update</div>
                                    <div className="col" style={{paddingLeft: "20px !important", padding: "10px", border: "1px solid", borderColor: "#91979D", borderRadius: "5px", height: "38px"}}>
                                        {item.name}
                                    </div>
                                </div>
                            )
                        }else if (item.type === "creation_log") {
                            return (
                                <div className="row mt-2 mr-1">
                                    <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Creation Log</div>
                                    <div className="col" style={{paddingLeft: "20px !important", padding: "10px", border: "1px solid", borderColor: "#91979D", borderRadius: "5px", height: "38px"}}>
                                        {item.name}
                                    </div>
                                </div>
                            )
                        }else if (item.type === "paragraph") {
                            if(params.permission_id < 4){
                                return (
                                    <OverlayTrigger trigger="click" placement="bottom" overlay={popoverParagraph("rowIndex", "columnIndex", "name", "id")} rootClose>
                                        <div className="row mt-2 mr-1">
                                            <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Paragraph</div>
                                            <div className="col" style={{paddingLeft: "20px !important", padding: "10px", height: "38px"}}>
                                                {item.name === "" ?
                                                    <Image src={AddIconSvg} className="addParagraphs"></Image>
                                                    :
                                                    <Image src={ParagraphIcon}></Image>
                                                }
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                )
                            }else{
                                return (
                                    <OverlayTrigger trigger="click" placement="bottom" overlay={
                                        <Tooltip id="tooltip">
                                            You can only view this board.
                                        </Tooltip>
                                    } rootClose>
                                        <div className="row mt-2 mr-1">
                                            <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Paragraph</div>
                                            <div className="col" style={{paddingLeft: "20px !important", padding: "10px", height: "38px"}}>
                                                {item.name === "" ?
                                                    <Image src={AddIconSvg} className="addParagraphs"></Image>
                                                    :
                                                    <Image src={ParagraphIcon}></Image>
                                                }
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                )
                            }
                        }else if (item.type === "number") {
                            return (
                                <div className="row mt-2 mr-1">
                                    <div className="col-4" style={{fontSize: "14px", paddingTop: "10px", fontWeight: "500"}}>Number</div>
                                    <div className="col" style={{border: "1px solid", borderColor: "#91979D", borderRadius: "5px", paddingLeft: "5px", height: "38px", paddingTop: "5px"}}>
                                        <ContentEditable   
                                            html= {typeof item.name !== "string"? item.name.toString() : item.name} 
                                            key={index} 
                                            disabled = { params.permission_id < 4 ? false : true }
                                            // style={{width : '95% !important'}} 
                                            onBlur={()=>{handleEditItemNumber(index, selectedTaskBoard.index_value, item.id);}}
                                            onChange={(e)=> {handelChangeNumber(e.target.value)}} 
                                            onKeyDown={(e)=>handleEnterItemNumber(e, index, selectedTaskBoard.index_value, item.id)}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    }
                })}
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="light" onClick={props.onHide}>Cancel</Button> 
                    <Button
                        variant="primary"
                        disabled={isLoading}
                        className="font-14 text-center"
                        type="submit"
                        >
                        {isLoading ? 'Loading…' : 'Create Member'}
                    </Button> 
                </Modal.Footer> */}
            {/* </Form> */}
        </Modal>
    )
}

export default ModalDetailBoard