import React, {useEffect, useState, useContext, useRef} from 'react'
import {Modal, Button, Accordion, Card, Row, Col, Tooltip,OverlayTrigger, Nav, Form, Spinner } from 'react-bootstrap' 
import {DashboardContext} from "../../context/DashboardContext" 

//========3D LIBRARY==========//
import ContentEditable from 'react-contenteditable'  
import {StyleSelect, stylesSelectMulti} from "../../helpers/select/Select"
import Select from 'react-select' 
import _ from 'lodash' 
import { CirclePicker } from 'react-color'
import {NotificationManager} from 'react-notifications' 

//========CHART==========//
import {BasicChart} from '../../helpers/highcharts/Charts'

//========ICON==========//
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Pie from '../../img/pie.svg'
import Bar from '../../img/bar.svg'
import Line from '../../img/line.svg'
import Stacked from '../../img/stacked.svg'
import Donut from '../../img/donut.svg'
import SmoothLine from '../../img/smooth-line.svg'
import StackedLine from '../../img/stacked-line.svg'
import Stackedline100 from '../../img/stacked-line-100.svg'
import StackedBar100 from '../../img/stacked-bar-100.svg'
import Column from '../../img/column.svg'
import StackedColumn from '../../img/stacked-column.svg'
import StackedColumn100 from '../../img/stacked-column-100.svg'
import Area from '../../img/area.svg'
import StackedArea from '../../img/stacked-area.svg'
import StackedArea100 from '../../img/stacked-area-100.svg'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

//========CSS==========//
import  "../css/modal-custome-full.css" 
import { chart } from 'highcharts'

const ModalAddWidget = (props) =>{ 
    const [charts, setCharts] = useState([])  
    const [data, setData] = useState([])  
    const [title, setTitle] = useState("Chart")  
    const [isLoading, setLoading] = useState(false)  
    const isFirstRun = useRef(true) 

    const {params, boardActive, selectedBoard, stateChart, 
        dispatchChart, createDashboard, updateDashboard, setDataBattery} = useContext(DashboardContext)  

    const titleCase = (str) => {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) { 
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        } 
        return splitStr.join(' '); 
     }

    useEffect(()=>{  
        if(isFirstRun.current){
            isFirstRun.current = false
            return
        } 
        if(selectedBoard.length === 0) return   

        //SET BORD 
        let board = [], arrTmp = [], group = [], selectedIdGroup = []
        selectedBoard.map(items =>{  
            let tmp = {value: items.id, label: items.title}
            if(items.type === 1) { 
                if(stateChart.isUpdate){
                    if(stateChart.board.includes(items.id)){
                        board.push(items.id)
                        arrTmp.push(tmp)
                    }
                }else{
                    board.push(items.id) 
                    arrTmp.push(tmp) 
                }  
            } 

            tmp = {}
            tmp.board_id = items.id
            let tmpGroup = []
            items.table.map(item =>{  
                item.rows.map((row, key) =>{
                    if(key === 0) { 
                        //==== GET GROUP NAME BY ID TABLES======// 
                        let objGroup = {}
                        objGroup.id = item.id
                        objGroup.name = row[1].name 
                        objGroup.board_id = items.id
                        tmpGroup.push(objGroup) 
                        selectedIdGroup.push(item.id)
                    }
                }) 
            }) 
            tmp.group = tmpGroup
            group.push(tmp)
        })  

 
        dispatchChart({type: 'SET_SELECTED_BOARD', selectedBoard: selectedBoard,selectedIdBoard: _.map(selectedBoard,"id"), 
        board: board, group : selectedIdGroup, selectedIdGroup : selectedIdGroup, selectedGroup: group }) 

    },[selectedBoard])

    useEffect(()=>{
        if(charts[0] !== undefined) setCharts([{}])
        if(stateChart.selectedBoard.length === 0) return  
        console.log("stateChart___", stateChart)
        let dataTmp = [], color = []  
        setTitle(stateChart.title)    
        stateChart.selectedBoard.map(items => {   
            if(stateChart.board.includes(items.id)){
                items.table.map((item, key) => { 
                    if(stateChart.group.includes(item.id)) {
                        let group = "", keyColumn = ""
                        item.rows.map((row, rowKey) => { 
                            if(rowKey === 0) {
                                group = row[1] 
                                _.map(row, (item, key) =>{
                                    if(stateChart.xAxisColumnId.includes(item.id)) keyColumn = key
                                    if(stateChart.xAxisColumnId.length === 0) {
                                        let type =  item.type.replace("_"," ").toUpperCase()
                                        if(stateChart.xAxis.toUpperCase() === type && keyColumn === "") keyColumn = key 
                                    }
                                }) 
                            }   
                                //===== SKIP HEADER ======//
                            if(rowKey > 0){  
                                row.some((val, valIdx)=> { 
                                    let type =  val.type.replace("_"," ")
                                    type  = titleCase(type).trim() 
                                    if(val.name === null) val.name = "" 
                                    if(stateChart.xAxis === "Board" && stateChart.groupBy === ""){ 
                                        if(valIdx === 1){   
                                            let tmp = {}
                                            tmp.name = items.title
                                            color[tmp.name] = 'rgb(87, 155, 252)'
                                            dataTmp.push(tmp)
                                        } 
                                    }else if(stateChart.xAxis === "Board" && stateChart.groupBy === "Status"){
                                        let status = _.find(row, {type: "status"});
                                        let statusName = status !== undefined ? status.name.name : undefined
                                        statusName = statusName !== undefined ? statusName.replace(" ", "_").toLowerCase() : statusName
                                        let statusColor = status !== undefined ? status.name.color : 'rgb(87, 155, 252)'
                                        color[statusName] =  statusName !== undefined ? statusColor : 'rgb(87, 155, 252)'
                                        // console.log("status____", status)
                                        
                                        if(valIdx === 1){   
                                            let tmp = {}
                                            tmp.name = items.title
                                            let labelStatus =  status !== undefined ? status.name.name : undefined
                                            labelStatus = labelStatus !== undefined ? labelStatus.replace(" ", "_").toLowerCase() : labelStatus
                                            tmp.status = labelStatus
                                            // color[tmp.name] = 'rgb(87, 155, 252)'
                                            dataTmp.push(tmp)
                                        }
                                    }

                                    if(stateChart.xAxis === "Group" && stateChart.groupBy === ""){ 
                                        if(valIdx === 1){   
                                                let tmp = {}
                                                tmp.id = group.id
                                                tmp.name = group.name
                                                tmp.type = group.type
                                                color[group.name] = 'rgb(87, 155, 252)'
                                                dataTmp.push(tmp)
                                            
                                            // color[group.name] = 'rgb(87, 155, 252)'
                                            // console.log("group____", group)
                                            // dataTmp.push(group)
                                        } 
                                    }else if(stateChart.xAxis === "Group" && stateChart.groupBy === "Status"){
                                        let status = _.find(row, {type: "status"});
                                        let statusName = status !== undefined ? status.name.name : undefined
                                        statusName = statusName !== undefined ? statusName.replace(" ", "_").toLowerCase() : statusName
                                        let statusColor = status !== undefined ? status.name.color : 'rgb(87, 155, 252)'
                                        color[statusName] =  statusName !== undefined ? statusColor : 'rgb(87, 155, 252)'

                                        if(valIdx === 1){   
                                            let tmp = {}
                                            tmp.id = group.id
                                            tmp.name = group.name
                                            tmp.type = group.type
                                            // color[group.name] = 'rgb(87, 155, 252)'
                                            let labelStatus =  status !== undefined ? status.name.name : undefined
                                            labelStatus = labelStatus !== undefined ? labelStatus.replace(" ", "_").toLowerCase() : labelStatus
                                            tmp.status = labelStatus
                                            dataTmp.push(tmp)
                                        } 
                                    }

                                    if(stateChart.xAxis === "Name" && stateChart.groupBy === ""){ 
                                        if(valIdx === 1 && val.type !== "addsubitems" && val.type !== "subitems"){  
                                            let tmp = {}
                                            tmp.name = val.name.trim()
                                            color[tmp.name] = 'rgb(87, 155, 252)'
                                            dataTmp.push(tmp)
                                        } 
                                    }else if(stateChart.xAxis === "Name" && stateChart.groupBy === "Status"){
                                        let status = _.find(row, {type: "status"})
                                        let statusName = status !== undefined ? status.name.name : undefined
                                        statusName = statusName !== undefined ? statusName.replace(" ", "_").toLowerCase() : statusName
                                        let statusColor = status !== undefined ? status.name.color : 'rgb(87, 155, 252)'
                                        color[statusName] =  statusName !== undefined ? statusColor : 'rgb(87, 155, 252)'
                                        
                                        if(valIdx === 1 && val.type !== "addsubitems" && val.type !== "subitems"){  
                                            let tmp = {}
                                            tmp.name = val.name.trim()
                                            // color[tmp.name] = 'rgb(87, 155, 252)'
                                            
                                            let labelStatus =  status !== undefined ? status.name.name : undefined
                                            labelStatus = labelStatus !== undefined ? labelStatus.replace(" ", "_").toLowerCase() : labelStatus
                                            tmp.status = labelStatus
                                            dataTmp.push(tmp)
                                        }
                                    }
                                    if(!stateChart.showUndefined && (val.name === undefined || val.name === "" || val.name.name === "" || val.name.name === "Undefined")) return
                                    if((type === stateChart.xAxis)){ 
                                        if(type === "Person" && (keyColumn !== "" && valIdx === keyColumn) && stateChart.groupBy === ""){
                                            if(val.name.length === 0) val.name = ""
                                            if(val.name ===  "") {
                                                dataTmp.push("")
                                                return
                                            }
                                            val.name.map((n,idx) => { 
                                                let tmp = {}
                                                tmp.name = n.label
                                                color[tmp.name] = 'rgb(87, 155, 252)'
                                                dataTmp.push(tmp)
                                            })
                                        }else if(type === "Person" && (keyColumn !== "" && valIdx === keyColumn) && stateChart.groupBy === "Status"){
                                            // console.log("vallllllll___", val)
                                            // if(val.name.length === 0) val.name = ""
                                            // if(val.name ===  "") {
                                            //     dataTmp.push("")
                                            //     return
                                            // }
                                            
                                            let status = _.find(row, {type: "status"});
                                            // console.log("status___", status)
                                            let statusName = status !== undefined ? status.name.name : undefined
                                            statusName = statusName !== undefined ? statusName.replace(" ", "_").toLowerCase() : statusName
                                            let statusColor = status !== undefined ? status.name.color : 'rgb(87, 155, 252)'
                                            color[statusName] =  statusName !== undefined ? statusColor : 'rgb(87, 155, 252)'
                                            // _.forEach(Object.keys(countStatus), function(value) {
                                            //     return [value]
                                            // });
                                            
                                            if(val.name !== ""){
                                                val.name.map((n,idx) => { 
                                                    // console.log("nnn_data_____", n)
                                                    let tmp = {}
                                                    tmp.name = n.label
                                                    let labelStatus =  status !== undefined ? status.name.name : undefined
                                                    labelStatus = labelStatus !== undefined ? labelStatus.replace(" ", "_").toLowerCase() : labelStatus
                                                    tmp.status = labelStatus
                                                    // color[tmp.name] = 'rgb(87, 155, 252)'
                                                    // color[tmp.name] = status !== undefined ? status.name.color : 'rgb(87, 155, 252)'
                                                    dataTmp.push(tmp)
                                                })
                                            }
                                            // val.name.map((n,idx) => { 
                                            //     // console.log("nnn_data_____", n)
                                            //     let tmp = {}
                                            //     tmp.name = n.label
                                            //     let labelStatus =  status !== undefined ? status.name.name : undefined
                                            //     labelStatus = labelStatus !== undefined ? labelStatus.replace(" ", "_").toLowerCase() : labelStatus
                                            //     tmp.status = labelStatus
                                            //     // color[tmp.name] = 'rgb(87, 155, 252)'
                                            //     // color[tmp.name] = status !== undefined ? status.name.color : 'rgb(87, 155, 252)'
                                            //     dataTmp.push(tmp)
                                            // })
                                        }

                                        if(stateChart.groupBy === "" && type === "Priority" || type === "Status" && valIdx === keyColumn){  
                                            let tmp = {}
                                            tmp.id = val.name.id
                                            tmp.board_id = val.name.board_id
                                            if(val.name.name) val.name.name = val.name.name.trim()
                                            if(val.name === "" || val.name.name === ""){
                                                tmp.name = "Undefined"
                                                tmp.color = "rgb(196, 196, 196)"
                                            }else{
                                                tmp.name = val.name.name
                                                color[val.name.name] =  val.name.color
                                            }
                                            dataTmp.push(tmp)
                                            
                                            // let tmp = {name : "Undefined", color: "rgb(196, 196, 196)"}
                                            // if(val.name.name) val.name.name = val.name.name.trim()
                                            // if(val.name === "" || val.name.name === "") val.name = tmp 
                                            // color[val.name.name] =  val.name.color
                                            // dataTmp.push(val.name)
                                        }else if(stateChart.groupBy === "Status" && type === "Priority" && valIdx === keyColumn){
                                            let status = _.find(row, {type: "status"});
                                            let statusName = status !== undefined ? status.name.name : undefined
                                            statusName = statusName !== undefined ? statusName.replace(" ", "_").toLowerCase() : statusName
                                            let statusColor = status !== undefined ? status.name.color : 'rgb(87, 155, 252)'
                                            color[statusName] =  statusName !== undefined ? statusColor : 'rgb(87, 155, 252)'

                                            let tmp = {}
                                            tmp.id = val.name.id
                                            tmp.board_id = val.name.board_id
                                            if(val.name.name) val.name.name = val.name.name.trim()
                                            if(val.name === "" || val.name.name === ""){
                                                tmp.name = "Undefined"
                                                // tmp.color = "rgb(196, 196, 196)"
                                            }else{
                                                tmp.name = val.name.name
                                                // color[val.name.name] =  val.name.color
                                            }
                                            let labelStatus =  status !== undefined ? status.name.name : undefined
                                            labelStatus = labelStatus !== undefined ? labelStatus.replace(" ", "_").toLowerCase() : labelStatus
                                            tmp.status = labelStatus
                                            dataTmp.push(tmp)
                                        }

                                        if(type === "Progress" && valIdx === keyColumn && stateChart.groupBy === ""){ 
                                            let tmp = {}
                                            tmp.name = val.name + '%'
                                            color[tmp.name] = 'rgb(87, 155, 252)'
                                            dataTmp.push(tmp) 
                                        }else if(type === "Progress" && valIdx === keyColumn && stateChart.groupBy === "Status"){
                                            let status = _.find(row, {type: "status"});
                                            let statusName = status !== undefined ? status.name.name : undefined
                                            statusName = statusName !== undefined ? statusName.replace(" ", "_").toLowerCase() : statusName
                                            let statusColor = status !== undefined ? status.name.color : 'rgb(87, 155, 252)'
                                            color[statusName] =  statusName !== undefined ? statusColor : 'rgb(87, 155, 252)'
                                            
                                            let tmp = {}
                                            tmp.name = val.name + '%'
                                            // color[tmp.name] = 'rgb(87, 155, 252)'
                                            let labelStatus =  status !== undefined ? status.name.name : undefined
                                            labelStatus = labelStatus !== undefined ? labelStatus.replace(" ", "_").toLowerCase() : labelStatus
                                            tmp.status = labelStatus
                                            dataTmp.push(tmp) 
                                        }

                                        if(type === "Text" && valIdx === keyColumn){  
                                            let tmp = {}
                                            tmp.name = val.name 
                                            color[tmp.name] = 'rgb(87, 155, 252)'
                                            dataTmp.push(tmp)  
                                        }

                                        if(type === "Paragraph" && valIdx === keyColumn){  
                                            let tmp = {}
                                            tmp.name = val.name 
                                            color[tmp.name] = 'rgb(87, 155, 252)'
                                            dataTmp.push(tmp) 
                                             
                                        }

                                        if(stateChart.groupBy === "" && (type === "Rating" || type === "Duedate" || type === "Timeline Duedate"
                                        || type === "Timeline" || type === "Date" || type === "Number" || type === "Week" && valIdx === keyColumn)){ 
                                            // console.log("masuk atas")
                                            let tmp = {}
                                            tmp.name = val.name
                                            color[tmp.name] = 'rgb(87, 155, 252)'
                                            dataTmp.push(tmp) 
                                        }else if(stateChart.groupBy === "Status" && (type === "Rating" || type === "Duedate" || type === "Timeline Duedate"
                                        || type === "Timeline" || type === "Date" || type === "Number" || type === "Week" && valIdx === keyColumn)){
                                            // console.log("masuk bawah")
                                            let status = _.find(row, {type: "status"});
                                            let statusName = status !== undefined ? status.name.name : undefined
                                            statusName = statusName !== undefined ? statusName.replace(" ", "_").toLowerCase() : statusName
                                            let statusColor = status !== undefined ? status.name.color : 'rgb(87, 155, 252)'
                                            color[statusName] =  statusName !== undefined ? statusColor : 'rgb(87, 155, 252)'
                                            
                                            if(val.name !== ""){
                                                let tmp = {}
                                                tmp.name = val.name
                                                // color[tmp.name] = 'rgb(87, 155, 252)'
                                                let labelStatus =  status !== undefined ? status.name.name : undefined
                                                labelStatus = labelStatus !== undefined ? labelStatus.replace(" ", "_").toLowerCase() : labelStatus
                                                tmp.status = labelStatus
                                                dataTmp.push(tmp) 
                                            }
                                        }

                                    }
                                })  
                            }  
                            
                        }) 
                    }
                }) 
            }
        })  
        // dataTmp.categories = categories 
        //====== GET COUNT yAxis OF DATA ======  
        // console.log("dataTMP___", dataTmp)
        var count = _.countBy(dataTmp, 'name')  
        var countStatus = _.countBy(dataTmp, 'status')  
        // console.log("count__", count)
        // console.log("countStatus__", countStatus)
        // var countStatus = _.countBy(dataTmp, 'status')  

        // console.log("count_status_", countStatus)
        
        let data = [], categories = []

        // Object.keys(count).map(item=>{
        //     let obj = {}
        //     obj.name = item
        //     obj.y = count[item] 
        //     obj.color = color[item] 
        //     data.push(obj) 
        // })

        let stats = []
        
        Object.keys(count).map(item=>{
            // console.log("color___", color)
            // console.log("item___", item)
            let obj = {}
            obj.name = item
            obj.y = count[item] 
            obj.color = color[item] !== undefined ? color[item] : color['open']
            obj.status = _.forEach(Object.keys(countStatus), function(value) {
                return [value]
            });
            Object.keys(countStatus).map(ite => {
                obj[ite] = 0
            })
            
            dataTmp.map(i => {
                if(stateChart.xAxis === "Number"){
                    if(i.name.toString() === item){
                        obj[i.status] ++
                    }
                }else {
                    if(i.name === item){
                        obj[i.status] ++
                    }
                }
            })
            data.push(obj) 
        })

        console.log("data___", data)

        let open = [], done = [], completed = [], in_progress = [], undefined_val = []
        let dataCoba = []
        
        data.map(it => {
            // console.log("ITITITITITI", it)

            open.push(it.open ? it.open : 0)
            done.push(it.done ? it.done : 0)
            completed.push(it.completed ? it.completed : 0)
            in_progress.push(it.in_progress ? it.in_progress : 0)
            if(stateChart.showUndefined === true) undefined_val.push(it.undefined ? it.undefined : 0)
        })
        // console.log(dataCoba)

        if(stateChart.sortBy.value === 1){
            data.sort(function(a, b) {
                return a.y - b.y
            }) 
        }

        if(stateChart.sortBy.value === 2){
            data.sort(function(a, b) {
                return b.y - a.y
            }) 
        }

        if(stateChart.sortBy.value === 3){
            data.sort(function(a, b) {
                if (a.name < b.name)
                    return -1
                if (a.name > b.name)
                    return 1
                return 0
            }) 
        }

        if(stateChart.sortBy.value === 4){
            data.sort(function(a, b) {
                if (b.name < a.name)
                    return -1
                if (b.name > a.name)
                    return 1
                return 0
            }) 
        }
        
        categories = _.map(data,'name')
        
        let type = stateChart.type.toLowerCase()

        let dataSeries
        
        if((stateChart.groupBy === "Status" && stateChart.xAxis === "Person") || (stateChart.groupBy === "Status" && stateChart.xAxis === "Board") || (stateChart.groupBy === "Status" && stateChart.xAxis === "Group") || (stateChart.groupBy === "Status" && stateChart.xAxis === "Name") || (stateChart.groupBy === "Status" && stateChart.xAxis === "Priority") || (stateChart.groupBy === "Status" && stateChart.xAxis === "Progress") || (stateChart.groupBy === "Status" && stateChart.xAxis === "Date") || (stateChart.groupBy === "Status" && stateChart.xAxis === "Duedate") || (stateChart.groupBy === "Status" && stateChart.xAxis === "Timeline Duedate") || (stateChart.groupBy === "Status" && stateChart.xAxis === "Rating") || (stateChart.groupBy === "Status" && stateChart.xAxis === "Number")){
            if(stateChart.showUndefined === true){
                if(stateChart.adjustColor === true){
                    dataSeries = [
                        {name: 'Open', data: open[0] === undefined ? [0] : open, color: 'black'},
                        {name: 'Done', data: done[0] === undefined ? [0] : done, color: 'black'},
                        {name: 'Completed', data: completed[0] === undefined ? [0] : completed, color: 'black'},
                        {name: 'In Progress', data: in_progress[0] === undefined ? [0] : in_progress, color: 'black'},
                        {name: 'Undefined', data: undefined_val[0] === undefined ? [0] : undefined_val, color: 'black'}
                    ]
                }else{
                    dataSeries = [
                        {name: 'Open', data: open[0] === undefined ? [0] : open, color: color['open']},
                        {name: 'Done', data: done[0] === undefined ? [0] : done, color: color['done']},
                        {name: 'Completed', data: completed[0] === undefined ? [0] : completed, color: color['completed']},
                        {name: 'In Progress', data: in_progress[0] === undefined ? [0] : in_progress, color: color['in_progress']},
                        {name: 'Undefined', data: undefined_val[0] === undefined ? [0] : undefined_val, color: color['undefined']}
                    ]
                }
            }else{
                if(stateChart.adjustColor === true){
                    dataSeries = [
                        {name: 'Open', data: open[0] === undefined ? [0] : open, color: 'black'},
                        {name: 'Done', data: done[0] === undefined ? [0] : done, color: 'black'},
                        {name: 'Completed', data: completed[0] === undefined ? [0] : completed, color: 'black'},
                        {name: 'In Progress', data: in_progress[0] === undefined ? [0] : in_progress, color: 'black'}
                    ]
                }else{
                    dataSeries = [
                        {name: 'Open', data: open[0] === undefined ? [0] : open, color: color['open']},
                        {name: 'Done', data: done[0] === undefined ? [0] : done, color: color['done']},
                        {name: 'Completed', data: completed[0] === undefined ? [0] : completed, color: color['completed']},
                        {name: 'In Progress', data: in_progress[0] === undefined ? [0] : in_progress, color: color['in_progress']}
                    ]
                }
            }
        }else{
            dataSeries = [{ 
                size: '50%',
                innerSize: type === "donut" ? '50%' : '0%',
                name: stateChart.yAxis,
                data : data,
                borderSizeWidth : 1,
                borderColor : '#F0F1F3'
            }]
        }

        console.log("dataSeries____", dataSeries[0])
        
        let chart = {
            type: type === "donut"? "pie": type === "smooth line" ? "spline" : type,
            borderColor: '#F0F1F3',
            borderWidth: 1,
            legend: {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal',
                enabled: ((stateChart.groupBy === "Status" && stateChart.xAxis === "Person") || (stateChart.groupBy === "Status" && stateChart.xAxis === "Board") || (stateChart.groupBy === "Status" && stateChart.xAxis === "Group") || (stateChart.groupBy === "Status" && stateChart.xAxis === "Name") || (stateChart.groupBy === "Status" && stateChart.xAxis === "Priority") || (stateChart.groupBy === "Status" && stateChart.xAxis === "Progress") || (stateChart.groupBy === "Status" && stateChart.xAxis === "Date") || (stateChart.groupBy === "Status" && stateChart.xAxis === "Duedate") || (stateChart.groupBy === "Status" && stateChart.xAxis === "Timeline Duedate") || (stateChart.groupBy === "Status" && stateChart.xAxis === "Rating") || (stateChart.groupBy === "Status" && stateChart.xAxis === "Number")) ? true : false
            },
            xAxis: {
                categories: categories,   
                lineColor: '#F0F1F3',
                lineWidth: 1,
                labels: {
                    enabled: true,
                    formatter () {
                      return `<span style="color: black;font-size: 12px; font-weight: 400">${this.value}</span>`
                    }
                  }
            }, 
            yAxis: {  
                title: {
                    // text: stateChart.yAxis, 
                    text: '', 
                }, 
                labels: {
                    enabled: true,
                    style: {
                        color: 'black'
                    }
                },
                gridLineColor: '#F0F1F3'
            }, 
            plotOptions: {      
                [type === 'donut'? 'pie': type]: {  
                    dataLabels: {
                        enabled: true,
                        crop: false,
                        overflow: 'none', 
                        format: type === 'pie' || type === 'donut' ? '<b>{point.name}</b>: {point.percentage:.1f} %' :'{point.y}'
                    },
                },   
                
                series: {
                    // animation: SVGComponentTransferFunctionElement,
                    animation: false,
                    dataLabels: {
                        format: '{y}',
                        enabled: true, 
                    }
                }        
            },
            series: dataSeries
                // [{ 
                //     size: '50%',
                //     innerSize: type === "donut" ? '50%' : '0%',
                //     name: stateChart.yAxis,
                //     data : data,
                //     borderSizeWidth : 1,
                //     borderColor : '#F0F1F3'
                // }]
        }  
        let a = setCharts(chart)  
        console.log("chart___setttt", chart)
        console.log('aaaaaaaaaaaaaaa', a)
    },[stateChart]) 
    
    const handleChangeTitle = (value) => {
        setTitle(value)
    }
    const handleApply = async () =>{ 
        setLoading(true)
        let info = {id: stateChart.idUpdate, title:title, created_by: params.id, team_id : params.team.id, board_id: boardActive.id, workspace_id: boardActive.workspace_id, permission_id: boardActive.permission_id}
        let mod = boardActive.dashboard.length % 2  
       
        if(stateChart.isUpdate){
            let column = stateChart.column 
            column.chart = charts
            let data = Object.assign(info,{column:column}, {widget: stateChart}, {chart: [] })  
            await updateDashboard(data, true)
            dispatchChart({type : 'SET_UPDATE_DONE'})
        }else{
            let column =  {   
                "x": mod === 0 ? 0 : 4,
                "y": mod === 0 ? 0 : 4,
                "w": 5,
                "h": 2,
                minW: 3,
                minH: 2,
                "i":  new Date().getTime()+"1",
                "static": false, 
                type : 'Chart',
                chart : charts
            }   
            let data = Object.assign(info,{column:column}, {widget: stateChart}, {chart: [] })   
            await createDashboard(data)
            
        }
        setLoading(false) 
        props.onHide()
    }

    return (
        <Modal
        {...props}
        size=""
        className="modal full fade" 
        id="modal-add-widget"
        aria-labelledby="contained-modal-title-vcenter"
        centered> 
            <Modal.Header >
                <Row className="w-100 modal-title">
                    <Col md="10">
                        <span className="font-18 font-w-600">Add New Chart</span>
                        <p className="font-16 font-w-400 color-grey">Create chart views to visually show data in your board</p> 
                    </Col>
                    <Col md="2" className="mt-2">
                    
                    <Button 
                        disabled={isLoading}
                        className="btn-yes text-white float-right"
                        type="submit"
                        onClick={handleApply}
                        >
                            {isLoading ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    />
                                : "Done" 
                            }
                    </Button>  
                    <Button className="btn-cancel float-right" onClick={()=>{props.onHide(); dispatchChart({type : 'SET_UPDATE_DONE'})}}>Cancel</Button>   
                    </Col>
                </Row> 
                    
            </Modal.Header>
            <Modal.Body className="vh-100 pt-0"> 
                
                <Row className="h-100">
                    <Col md="8" className="border-widget vh-75">
                        <div className="text-center mt-2">
                            <ContentEditable 
                                html={title}  
                                onChange={(e)=> {handleChangeTitle(e.target.value.replace(/<\/?[^>]+(>|$)/g, "")+ " ")}} 
                                className="board-title p-1 font-18"
                            />  
                        </div>
                        <div>
                            {stateChart.selectedBoard.length && charts.type && <BasicChart charts = {charts} />}
                        </div>
                    </Col>

                    <Col md="4" className="p-0">
                        <div>
                            <OptionWidget/>
                        </div>
                    </Col>
                </Row>
            </Modal.Body> 
        </Modal>
    )
}

const OptionWidget =() =>{
    return ( 
        <Accordion>
            <Card className="border-widget-option">
                <Accordion.Toggle as={Card.Header} eventKey="0" className="bg-white font-14 p-3">
                <ExpandMoreIcon style={{ color: 'black'}} className="align-text-top float-right cursor-pointer"/>
                Chart type
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                <Card.Body style={{height: '480px', overflow:'auto'}}><OptionChartType/></Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card className="border-widget-option">
                <Accordion.Toggle as={Card.Header} eventKey="1" className="bg-white font-14 p-3">
                <ExpandMoreIcon style={{ color: 'black'}} className="align-text-top float-right cursor-pointer"/>
                X Axis
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                <Card.Body style={{height: '480px', overflow:'auto'}}><OptionXAxis/></Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card className="border-widget-option">
                <Accordion.Toggle as={Card.Header} eventKey="2" className="bg-white font-14 p-3">
                <ExpandMoreIcon style={{ color: 'black'}} className="align-text-top float-right cursor-pointer"/>
                Y Axis
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                <Card.Body style={{height: '280px', overflow:'auto'}}><OptionYAxis/></Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card className="border-widget-option" hidden>
                <Accordion.Toggle as={Card.Header} eventKey="3" className="bg-white font-14 p-3">
                <ExpandMoreIcon style={{ color: 'black'}} className="align-text-top float-right cursor-pointer"/>
                Benchmark Lines
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                <Card.Body style={{height: '480px', overflow:'auto'}}><Benchmark/></Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card className="border-widget-option">
                <Accordion.Toggle as={Card.Header} eventKey="4" className="bg-white font-14 p-3">
                <ExpandMoreIcon style={{ color: 'black'}} className="align-text-top float-right cursor-pointer"/>
                More settings
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="4">
                <Card.Body style={{height: '250px', overflow:'auto'}}><MoreSetting/></Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card className="border-widget-option">
                <Accordion.Toggle as={Card.Header} eventKey="5" className="bg-white font-14 p-3">
                <ExpandMoreIcon style={{ color: 'black'}} className="align-text-top float-right cursor-pointer"/>
                Boards
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="5">
                <Card.Body style={{height: '480px', overflow:'auto'}} ><Boards/></Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card className="border-widget-option">
                <Accordion.Toggle as={Card.Header} eventKey="6" className="bg-white font-14 p-3">
                <ExpandMoreIcon style={{ color: 'black'}} className="align-text-top float-right cursor-pointer"/>
                Choose groups
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="6">
                <Card.Body style={{height: '480px', overflow:'auto'}}><GroupList/></Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>  
    )
}

const OptionChartType =() =>{
    const {stateChart, dispatchChart} = useContext(DashboardContext)  
    const [updated, setUpdated] = useState(new Date)
    const [data, setData] = useState([
        {parent: 'Most popular', detail: [ 
          {name: 'Pie', img: Pie, selected: false},
          {name: 'Column', img: Bar, selected: true},
          {name: 'Line', img: Line, selected: false},
          {name: 'Stacked Bar', img: Stacked, selected: false},
        ]},
        {parent: 'Pie', detail: [ 
            {name: 'Pie', img: Pie, selected: false},
            {name: 'Donut', img: Donut, selected: false}, 
        ]},
        {parent: 'Line', detail: [ 
            {name: 'Line', img: Line, selected: false},
            {name: 'Smooth Line', img: SmoothLine, selected: false},
            {name: 'Stacked Line', img: StackedLine, selected: false},
            {name: '100% Stacked Line', img: Stackedline100, elected: false},
        ]},
        {parent: 'Column', detail: [ 
            {name: 'Column', img: Bar, selected: false},
            {name: 'Stacked Column', img: Stacked, selected: false},
            {name: '100% Stacked Column', img: StackedBar100, selected: false}, 
        ]},
        {parent: 'Bar', detail: [ 
            {name: 'Bar', img: Column, selected: false},
            {name: 'Stacked Bar', img: StackedColumn, selected: false},
            {name: '100% Stacked Column', img: StackedColumn100, selected: false}, 
        ]},
        {parent: 'Area', detail: [ 
            {name: 'Area', img: Area, selected: false},
            {name: 'Stacked Area', img: StackedArea, selected: false},
            {name: '100% Stacked Area', img: StackedArea100, selected: false}, 
        ]},
    ]) 

    const handleSelected = (itemIndex, itemKey) => {
        let tmp = data
        tmp.map((item, index) => { 
            item.detail.map((val, key) => {
                val.selected = false
                if((itemIndex === index) && (key === itemKey)){
                    val.selected = true 
                    dispatchChart({type :'SET_TYPE_CHART', value : val.name})
                }
            }) 
        }) 
        setData(tmp)
        // setUpdated(new Date())
        
    }

    return ( 
        <div id={updated}>
            {data.map( (item, itemKey)=> {
                return (
                    <React.Fragment key={1+itemKey}>
                        <div className="mt-2">
                            <span>{item.parent}</span>
                        </div>
                        
                         <Row>
                            <div className="mt-2">
                                {item.detail.map((value, index) => {
                                    return(
                                        <div key={(index+itemKey)} style={value.name.includes('Stacked') ? {cursor: 'not-allowed', opacity: '0.2'} : {cursor :' pointer'}} 
                                        className={value.selected ? "d-inline-block ml-2 selected" :"d-inline-block ml-2 cursor-pointer"} 
                                        onClick={value.name.includes('Stacked') ? null : ()=>handleSelected(itemKey,index)}>
                                            <OverlayTrigger trigger={['hover','focus']} placement="bottom" 
                                                overlay={<Tooltip id='tooltip'>{value.name} </Tooltip>}rootClose>
                                                <div className="galery-item" >
                                                    <span className="gallery-item-text">
                                                        <img className="gallery-item-image" src={value.img} style={{height :'36px'}}/>
                                                    </span> 
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    )
                             })}
                            </div>
                        </Row>
                    </React.Fragment>
                )
            })}
            

        </div>
    )
}

// siniopt
const OptionXAxis =() =>{
    const {stateChart, dispatchChart} = useContext(DashboardContext)  
    const [typeList, setTypeList] = useState([]) 
    const [typeList2, setTypeList2] = useState([]) 
    const [selectedType, setSelectedType] = useState([]) 
    const [selectedType2, setSelectedType2] = useState([]) 
    const [selectElement, setSelecteEment] = useState([]) 

    const titleCase = (str) => {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) { 
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        } 
        return splitStr.join(' '); 
     }

    useEffect(()=>{  
        let typeList = ["Board","Group","Name"]
        let typeList2 = ["Board","Group","Name"]
        let selectElement = [] , group = []
        stateChart.selectedBoard.map(items => { 
            //===== INSERT TO SELECT ELEMENT ======//
            selectElement.push(<OptionXAxisSelect item={items} />)
            items.table.map(item => {
                //===== GET HEADER EACH TABLE ======//
                item.rows[0].map((row, key) =>{
                    if(key === 1) group.push(row.name.trim())
                    if(key > 1){
                        let type =  row.type.replace("_"," ")
                        type  = titleCase(type) 
                    
                        if((typeList.indexOf(type) === -1) && row.type !== 'option' && row.type !== 'add' && row.type !== 'file' 
                        && type.trim() !== 'Last Update' && type.trim() !== 'Creation Log'&& type.trim() !== 'Subitemscontroll' && type.trim()){  
                            typeList.push(type.trim()) 
                        }
                        if((typeList2.indexOf(type) === -1) && row.type !== 'option' && row.type !== 'add' && row.type !== 'file' 
                        && type.trim() !== 'Last Update' && type.trim() !== 'Creation Log'&& type.trim() !== 'Subitemscontroll' && type.trim()){  
                            typeList2.push(type.trim()) 
                        }
                    }
                      
                })
            }) 
        })  

        setSelecteEment(selectElement) 

        let arrTmp = []
        typeList.map(item =>{  
            let tmp = {value: item, label: item} 
            arrTmp.push(tmp)
             //===== SET DEFAULT SELETED TYPE ======//
            
            if(item === stateChart.xAxis) setSelectedType(tmp)   
        }) 
        setTypeList(arrTmp) 

        // typelist2
        let arrTmp2 = []
        typeList2.map(item =>{  
            let tmp = {value: item, label: item} 
            arrTmp2.push(tmp)
             //===== SET DEFAULT SELETED TYPE ======//
            
            if(item === stateChart.xAxis2) setSelectedType2(tmp)   
        }) 
        setTypeList2(arrTmp) 
    },[])
    
    const handleSelectedType = async(selected) =>{  
        setSelectedType(selected)  
        let ids = []  
        stateChart.selectedBoard.map(items => { 
            items.table.map((item, index) => { 
                let groupName = "" 
                //===== GET FISRT COLUMN EACH TABLE ======// 
                item.rows[0].map((row, key) =>{  
                    let type =  row.type.replace("_"," ")
                    type  = titleCase(type).trim()
                    let id =  row.id  
                    let name =  row.name  
                   
                    if(key === 1) {
                        groupName = name  
                    } 
                    if(type === selected.value && key !== 1) { 
                        let tmp = {board_id : item.board_id, id, groupName, name}
                        let isReady = ids.some(el => el.groupName === groupName)
                        if(!isReady)
                        ids.push(tmp)
                    }     
                   
                })
            })  
        })  
        dispatchChart({type : 'SET_XAXIS_CHART', value : selected.value, column : ids, id: _.map(ids,"id")})
    }

    const handleSelectedType2 = async(selected) =>{  
        setSelectedType2(selected)  
        let ids = []  
        stateChart.selectedBoard.map(items => { 
            items.table.map((item, index) => { 
                let groupName = "" 
                //===== GET FISRT COLUMN EACH TABLE ======// 
                item.rows[0].map((row, key) =>{  
                    let type =  row.type.replace("_"," ")
                    type  = titleCase(type).trim()
                    let id =  row.id  
                    let name =  row.name  
                   
                    if(key === 1) {
                        groupName = name  
                    } 
                    if(type === selected.value && key !== 1) { 
                        let tmp = {board_id : item.board_id, id, groupName, name}
                        let isReady = ids.some(el => el.groupName === groupName)
                        if(!isReady)
                        ids.push(tmp)
                    }     
                   
                })
            })  
        })  
        dispatchChart({type : 'SET_XAXIS_CHART_2', value : selected.value})
    }

    return (
        <div>
            Column Type 1
            <div className="mt-2">
            <Select styles={StyleSelect({menuListHeight: 100})} 
                options ={typeList}
                value ={selectedType}
                onChange={(selected)=>{handleSelectedType(selected)}} 
                maxMenuHeight={110}  
               />
            </div>

            {/* Column Type 2
            <div className="mt-2">
            <Select styles={StyleSelect({menuListHeight: 100})} 
                options ={typeList2}
                value ={selectedType2}
                onChange={(selected)=>{handleSelectedType2(selected)}} 
                maxMenuHeight={110}  
               />
            </div> */}

            <div className="mt-3">
            Choose X Axis by a specific column
                <div className="mt-2">
                {selectElement.map((element,index)=>{
                    return (
                        element === undefined ? "" :
                        <React.Fragment key={index}>
                            {React.cloneElement(element, {elementIndex : index, selectedType: selectedType})} 
                        </React.Fragment>
                    )
                })}     
                </div>
            </div>
        </div>
    )
}

const OptionXAxisSelect = (props) =>{ 
    const [list, setList] = useState([]) 
    const [selected, setSelected] = useState([]) 
    const {stateChart, dispatchChart} = useContext(DashboardContext)  

    const titleCase = (str) => {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) { 
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        } 
        return splitStr.join(' '); 
     }

    useEffect(()=>{  
        setSelected([])   
        let typeList = []   
        props.item.table.map(item => { 
            //===== GET HEADER EACH TABLE ======//
            item.rows[0].map((row, key) =>{
                let type =  row.type.replace("_"," ")
                type  = titleCase(type).trim()
                let name =  row.name  
                let id =  row.id  
                if(key === 1) {
                    name = "Name"
                    type = "Name"
                }
                let isReady = typeList.some(el => el.name === name)
                let tmp = {name: name, type: type, id, board_id : item.board_id}
                if((!isReady) && type === props.selectedType.value && row.type !== 'option' && row.type !== 'add' && row.type !== 'file' && row.type !== 'subitemscontroll' ){
                    typeList.push(tmp) 
                }
                      
            })
        }) 

        let arrTmp = []
        let selected = {}
        typeList.map(item =>{  
            let tmp = {value: item.id, label: item.name, board_id : item.board_id} 
            arrTmp.push(tmp)
             //===== SET DEFAULT SELETED TYPE ======//
            
            if(stateChart.xAxisColumnId.includes(item.id)){
                selected = tmp
            }  
        })     
        if(!selected.value) selected = arrTmp[0]
        setSelected(selected)  
        setList(arrTmp)  
          

    },[props])
 
    
    const handleSelected = async(selected) =>{  
        setSelected(selected)   
        let tmpColumn = _.filter(stateChart.xAxisColumn,(item) => {return item.board_id !== selected.board_id})
        // console.log(tmpColumn)
        stateChart.selectedBoard.map(items => { 
            items.table.map((item, index) => { 
                let groupName = "" 
                //===== GET FISRT COLUMN EACH TABLE ======// 
                item.rows[0].map((row, key) =>{  
                    let type =  row.type.replace("_"," ")
                    type  = titleCase(type).trim()
                    let id =  row.id  
                    let name =  row.name  
                   
                    if(key === 1) {
                        groupName = name  
                    } 
                    if(id === selected.value && key !== 1) { 
                        let tmp = {board_id : item.board_id, id, groupName, name}
                        tmpColumn.push(tmp)
                    }     
                   
                })
            })  
        })   
        dispatchChart({type : 'SET_XAXIS_COLUMN_CHART', column : tmpColumn, id: _.map(tmpColumn,"id")})
         
    }

    return (
        <div className="mt-3">
            {props.item.title}
            <div className="mt-2">
            <Select styles={StyleSelect({menuListHeight: 100})} 
                options ={list}
                value ={selected}
                onChange={(selected)=>{handleSelected(selected)}} 
                maxMenuHeight={110}  
               />
            </div>

          
        </div>
    )
}

const OptionYAxis =() =>{
    const {stateChart, dispatchChart} = useContext(DashboardContext)  
    const [typeList, setTypeList] = useState([]) 
    const [selectedType, setSelectedType] = useState([]) 
    const [selectElement, setSelecteEment] = useState([]) 

    const titleCase = (str) => {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) { 
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        } 
        return splitStr.join(' '); 
     }

    useEffect(()=>{  
        let typeList = ["Count Items"]
        let selectElement = [] 
        stateChart.selectedBoard.map(items => {
            //===== INSERT TO SELECT ELEMENT ======//
            selectElement.push(<OptionYAxisSelect item={items} />)
            
        })  
        setSelecteEment(selectElement) 

        let arrTmp = []
        typeList.map(item =>{  
            let tmp = {value: item, label: item} 
            arrTmp.push(tmp)
             //===== SET DEFAULT SELETED TYPE ======//
            setSelectedType(tmp)   
        }) 
        setTypeList(arrTmp)
       
    },[])
    
    const handleSelectedType = async(selected) =>{  
        setSelectedType(selected)   
    }

    return (
        <div>
            Column Type
            <div className="mt-2">
            <Select styles={StyleSelect({menuListHeight: 100})} 
                options ={typeList}
                value ={selectedType}
                onChange={(selected)=>{handleSelectedType(selected)}} 
                maxMenuHeight={110}  
               />
            </div>

            <div className="mt-3">
            Choose Y Axis by a specific column
                <div className="mt-2">
                {selectElement.map((element,index)=>{
                    return (
                        element === undefined ? "" :
                        <React.Fragment key={index}>
                            {React.cloneElement(element, {elementIndex : index, selectedType: selectedType})} 
                        </React.Fragment>
                    )
                })}     
                </div>
            </div>
        </div>
    )
}

const OptionYAxisSelect = (props) =>{ 
    const [list, setList] = useState([]) 
    const [selected, setSelected] = useState([]) 
 
    useEffect(()=>{  
        setSelected([])   
        let typeList = ["Count Item"]  
        
        let arrTmp = []
        typeList.map(item =>{  
            let tmp = {value: item, label: item} 
            arrTmp.push(tmp)
             //===== SET DEFAULT SELETED TYPE ======// 
             setSelected(tmp)   
        }) 
        setList(arrTmp) 
 
    },[props])
    
    const handleSelected = async(selected) =>{  
        setSelected(selected)   
    }

    return (
        <div className="mt-3">
            {props.item.title}
            <div className="mt-2">
                <Select styles={StyleSelect({menuListHeight: 100})} 
                    options ={list} 
                    value ={selected}
                    onChange={(selected)=>{handleSelected(selected)}} 
                    maxMenuHeight={110}  
               />
            </div>

          
        </div>
    )
}

const Benchmark = (props) =>{ 
    const [count, setCount] = useState(1)  
    const [elements, setElements] = useState([])  
    
    useEffect(()=>{  
       
    },[props])

    useEffect(()=>{  
        createElement()  
    },[count])
     
    const createElement = () => {
        let elements = []
        for(let i = 0; i < count; i++){
            elements.push(<BenchmarkList />)
        }
        setElements(elements)
    }

    const addBenchmark = () => {
        let cnt = count
        cnt += 1
        setCount(cnt)
    }

    const deleteBenchmark = (index) =>{  
      let elementsTmp =   _.filter(elements, (item, key) => {return key !== index})
      setElements(elementsTmp)
      let cnt = count
      cnt -= 1
      setCount(cnt)
    }

    return (
        <div>
            <div>
            {elements.map((element,index)=>{
                return (
                    element === undefined ? "" :
                    <React.Fragment key={index}>
                        {React.cloneElement(element, {elementIndex : index, count: count, index: index, deleteBenchmark: deleteBenchmark})} 
                    </React.Fragment>
                )
            })}     
            </div>
            <div className="w-100 mt-3">
                <Nav.Link className="nav-menu p-2 float-right" onClick={addBenchmark}> 
                    <AddCircleOutlineIcon style={{color: 'black', fontSize: 20 }} className="align-text-top"/>
                    <span className="workspace-item-name-child align-text-top ml-2 font-12">Add benchmark line</span>
                </Nav.Link> 
            </div>
              
        </div>
    )
}

const BenchmarkList = (props) =>{ 
    const [color, setColor] = useState("rgb(196, 196, 196)")  
    const colors = ["rgb(3, 127, 76)", "rgb(253, 171, 61)", "rgb(87, 155, 252)", "rgb(226, 68, 92)", "rgb(0, 202, 114)", "rgb(196, 196, 196)", "rgb(0, 134, 192)", "rgb(162, 93, 220)", "rgb(255, 203, 0)","rgb(51, 51, 51)"]
    useEffect(()=>{  
        
    
    },[props])
        
    const onChangeComplete =(color) =>{
        setColor(color.hex)  
    }

    return (
        <div className={props.index === 0 ? "mt-2" : "mt-4"}> 
            Benchmark Line 
            <Row>
                <Col>
                    <div className="mt-2">
                        <input type ="text" placeholder="Number value" className="form-control font-12"></input>
                    </div>
                    <div className="mt-3">
                        <input type ="text" placeholder="Benchmark Line Label" className="form-control font-12"></input>
                    </div>
                    <div className="mt-3">
                        <CirclePicker color={color} colors ={colors} width={'100%'} circleSpacing= {5} circleSize = {22} onChangeComplete = {onChangeComplete}></CirclePicker>
                    </div>
                </Col>
                {props.count > 1 ?
                    <Col md="1" className="m-auto ml-0 pl-0">
                        <HighlightOffIcon style={{color: 'black', fontSize: 20 }} className="align-text-top" onClick={()=>props.deleteBenchmark(props.index)}/>
                    
                    </Col>
                    :"" 
                }
            </Row>
            
        </div>
    )
}

//sanamoreset
const MoreSetting =() =>{ 
    const [sortList, setSortList] = useState([]) 
    const [itemList, setItemList] = useState([{value: 1, label: 'Show Top'},{value: 2, label: 'Show Bottom'}]) 
    const [selectedSort, setSelectedSort] = useState([])  
    const [selectedItem, setSelectedItem] = useState([])  
    const [onlyTop, setOnlyTop] = useState(false)   
    const {stateChart, dispatchChart} = useContext(DashboardContext)  
    const [selectedType, setSelectedType] = useState([]) 
    const [typeList, setTypeList] = useState([])

    const titleCase = (str) => {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) { 
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        } 
        return splitStr.join(' '); 
    }
    
    useEffect(()=>{  
        let typeList = ["Board","Group","Name"] 
        
        let group = []
        stateChart.selectedBoard.map(items => { 
            items.table.map(item => {
                //===== GET HEADER EACH TABLE ======//
                item.rows[0].map((row, key) =>{
                    if(key === 1) group.push(row.name.trim())
                    if(key > 1){
                        let type =  row.type.replace("_"," ")
                        type  = titleCase(type) 
                    
                        if((typeList.indexOf(type) === -1) && row.type !== 'option' && row.type !== 'add' && row.type !== 'file' 
                        && type.trim() !== 'Last Update' && type.trim() !== 'Creation Log'&& type.trim() !== 'Subitemscontroll' && type.trim()){  
                            typeList.push(type.trim()) 
                        }
                    }
                      
                })
            }) 
        })

        let arrTmp = [{value: "", label: "Select group by..."}]
        typeList.map(item =>{  
            let tmp = {value: item, label: item} 
            if(item === "Status"){
                arrTmp.push(tmp)
            }
             //===== SET DEFAULT SELETED TYPE ======//
            if(item === stateChart.groupBy) {
                setSelectedType(tmp)
            }
        }) 
        setTypeList(arrTmp)

        let typeSort = [{value: 1, label: 'Y Axis Ascending'},{value: 2, label: 'Y Axis Descending'},
                        {value: 3, label: 'X Axis Ascending'},{value: 4, label: 'X Axis Descending'}] 
 
        typeSort.map(item =>{  
            let tmp = {value: item.value, label: item.label}  
             //===== SET DEFAULT SELETED TYPE ======//
            
        }) 
        setSortList(typeSort) 
        setSelectedSort(stateChart.sortBy)
       
    },[stateChart.sortBy])

    const handleSelectedType = async(selected) =>{  
        setSelectedType(selected)  
        let ids = []  
        stateChart.selectedBoard.map(items => { 
            items.table.map((item, index) => { 
                let groupName = "" 
                //===== GET FISRT COLUMN EACH TABLE ======// 
                item.rows[0].map((row, key) =>{  
                    let type =  row.type.replace("_"," ")
                    type  = titleCase(type).trim()
                    let id =  row.id  
                    let name =  row.name  
                   
                    if(key === 1) {
                        groupName = name  
                    } 
                    if(type === selected.value && key !== 1) { 
                        let tmp = {board_id : item.board_id, id, groupName, name}
                        let isReady = ids.some(el => el.groupName === groupName)
                        if(!isReady)
                        ids.push(tmp)
                    }     
                   
                })
            })  
        })  
        dispatchChart({type : 'SET_GROUPING_STATUS', group: selected.value})
    }
    
    const handleSelected = async(selected) =>{  
        setSelectedSort(selected)   
        dispatchChart({type: 'SET_SORT_CHART', sort: selected})
    }

    const handleSelectedItem = async(selected) =>{  
        setSelectedItem(selected)   
    }

    const handleUndefined = (e) =>{  
        dispatchChart({type: 'SET_UNDEFINED_CHART', value: e.target.checked})
    }

    const handleAdjustColor = (e) =>{  
        dispatchChart({type: 'SET_ADJUST_COLOR', value: true})
    }

    return (
        <div>
            Sort by
            <div className="mt-2 mb-2">
                <Select styles={StyleSelect({menuListHeight: 100})} 
                    options ={sortList}
                    value ={selectedSort}
                    placeholder={"Select sort by..."}
                    onChange={(selected)=>{handleSelected(selected)}} 
                    maxMenuHeight={110}  
               />
            </div>
            {stateChart.xAxis !== "Status" ? "Group by" : null}
            {stateChart.xAxis !== "Status" ? 
                <div className="mt-2">
                    <Select styles={StyleSelect({menuListHeight: 100})} 
                        options ={typeList}
                        value ={selectedType}
                        placeholder={"Select group by..."}
                        onChange={(selected)=>{handleSelectedType(selected)}} 
                        maxMenuHeight={110}  
                />
                </div>
                : null
            }
         
            <div className="mt-2 mb-2" hidden>
                <button onClick={()=> handleAdjustColor()}>Adjust Color</button>
            </div>

            <div className="mt-3" hidden>
                <Form.Group controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" onClick={()=>setOnlyTop(!onlyTop)} label="Show only top/bottom items" />
                </Form.Group>
                {onlyTop ?
                <div className="mt-2 d-inline">
                    <Row>
                        <Col>
                            <Select styles={StyleSelect({menuListHeight: 100})} 
                            options ={itemList} 
                            value ={selectedItem}
                            onChange={(selected)=>{handleSelectedItem(selected)}} 
                            maxMenuHeight={110}  
                        />
                        </Col>
                        <Col md="3" className="ml-0 pl-0">
                            <input type="number" className="form-control" defaultValue={1} min="1"></input>
                        </Col>
                        <Col md="2" className="m-auto ml-0 pl-0">
                            Items
                        </Col>
                    </Row> 
                </div>
                :""}
            </div>

            <div className="mt-3" hidden>
                <Form.Group controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Show cumulative data" />
                </Form.Group> 
            </div>

            <div className="mt-3">
                <Form.Group controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Show undefined values" defaultChecked={stateChart.showUndefined} onChange={(e)=>handleUndefined(e)}/>
                </Form.Group> 
            </div>

        </div>
    )
}


const Boards = (props) =>{ 
    const [boardList, setBoardList] = useState([]) 
    const [selectedBoardTmp, setSelectedBoard] = useState([]) 
    const {selectedBoard, setShowChooseBoard, stateChart, dispatchChart, boardDashboardSelected} = useContext(DashboardContext)  

    useEffect(()=>{  
       
    },[props])
  
    useEffect(()=>{   
        getList() 
    },[])
    
    useEffect(()=>{   
        getList() 
    },[stateChart.selectedIdBoard])
    
    
    const handleSelected = async(selected) =>{  
        if(selected === null) selected = []
        setSelectedBoard(selected)  
        let board = []
        selected.map(item =>{
            board.push(item.value)
        }) 
        dispatchChart({type : 'SET_BOARD_CHART', board: board})  
    }

    const getList = () => {
        let arrTmp = []  
        stateChart.selectedBoard.map(item =>{  
            let tmp = {value: item.id, label: item.title}
            if(item.type === 1)
                arrTmp.push(tmp)
        }) 
        setBoardList(arrTmp)

        arrTmp = []
        let board = []
        stateChart.selectedBoard.map(item =>{  
            let tmp = {value: item.id, label: item.title}
            if(item.type === 1) { 
                if(stateChart.isUpdate){
                    if(stateChart.board.includes(item.id)){
                        board.push(item.id)
                        arrTmp.push(tmp)
                    }
                }else{
                    board.push(item.id) 
                    arrTmp.push(tmp) 
                }  
            } 
        }) 
        
        setSelectedBoard(arrTmp) 
    }

    return (
        <div>
            <div className="font-16">Choose boards</div>
            <div className="mt-3">Choose which boards to show on your widget</div>
            <div className="mt-3">
                <Select styles={stylesSelectMulti} 
                    options ={boardList}
                    isMulti
                    value ={selectedBoardTmp}
                    onChange={(selected)=>{handleSelected(selected)}}
                    maxMenuHeight={110}  
               />
            </div>
            <div className="mt-3 cursor-pointer text-primary" onClick={()=>setShowChooseBoard(true)}>
                <span>Add boards for this widget</span>
            </div>
        </div>
    )
}


const GroupList =() =>{
    const [selectElement, setSelectElement] = useState([])  
    const {stateChart} = useContext(DashboardContext)  
    const isFirstRun = useRef(true) 

    useEffect(()=>{    
        let tmpELement = []    
        stateChart.selectedBoard.map(items => {
            //===== INSERT TO SELECT ELEMENT ======//  
            let isExist = _.some(stateChart.board, function(i) {
                return i == items.id
            })  
            if(isExist) tmpELement.push(<GroupListSelect item={items} />)
            
        })   
        setSelectElement(tmpELement) 

    },[stateChart.board]) 
     

    return (
        <div> 
            <div className="mt-2">
                {selectElement.map((element,index)=>{  
                    return (
                        element === undefined ? "" :
                        <React.Fragment key={index}>
                            {React.cloneElement(element, {elementIndex : index})} 
                        </React.Fragment>
                    )
                })}     
            </div> 
        </div>
    )
}

const GroupListSelect = (props) =>{ 
    const [list, setList] = useState([]) 
    const [selected, setSelected] = useState([]) 
    const {stateChart, dispatchChart} = useContext(DashboardContext)  

    useEffect(()=>{   
        setSelected([])    
        let arrTmp = [], selectedTmp = []
        props.item.table.map(item =>{  
            item.rows.map((row, key) =>{
                if(key === 0) { 
                    //==== GET GROUP NAME ======// 
                    let tmp = {value: item.id, label: row[1].name, board_id : props.item.id} 
                    let isExist = _.some(stateChart.group, function(i) {
                        return i == item.id
                    })  
                    if(isExist) 
                        selectedTmp.push(tmp) 
                    arrTmp.push(tmp) 
                    
                }
            })  
        }) 
        setList(arrTmp) 
         //===== SET DEFAULT SELETED TYPE ======// 
        setSelected(selectedTmp)   
    },[props])
 
    const handleSelected = async(selected) =>{   
        if(selected === null) {
            NotificationManager.error("Minimal 1 group must be selected.")
            return
        }
        setSelected(selected)   
        let groupSelected = []  
        selected.map(item=>{  
            let board = _.filter(stateChart.selectedGroup, (group) => {return group.board_id === item.board_id})
            if(board.length) {
                let group = _.filter(board[0].group, (gr) => {return gr.id === item.value}) 
                group[0].board_id = item.board_id 
                groupSelected.push(group[0])
            } 
        }) 

        let tmpGroup = _.cloneDeep(stateChart.selectedGroup) 
        let idGroup = []
        _.map(tmpGroup, (item) => {  
            if(item.board_id === groupSelected[0].board_id) {
                item.group = groupSelected 
            }
            _.map(item.group, (gr)=>{
                idGroup.push(gr.id)
            })
           
        })   
        dispatchChart({type : 'SET_GROUP_CHART', group: idGroup})  
    }

    return (
        <div className="mt-3">
            {props.item.title}
            <div className="mt-2">
                <Select styles={stylesSelectMulti} 
                    options ={list} 
                    value ={selected}
                    isMulti
                    onChange={(selected)=>{handleSelected(selected)}} 
                    maxMenuHeight={110}  
               />
            </div>

          
        </div>
    )
}

export default ModalAddWidget