import React, { useState } from 'react'; 
import './css/home.css';
import { HashLink as Link } from 'react-router-hash-link';
import {Col,Row } from 'react-bootstrap'   
import Check from '../../img/check-green.svg';
import CheckWhite from '../../img/check-white.svg';
import XRed from '../../img/x-red.svg';
import Unlimited from '../../img/unlimited.svg';
import UnlimitedWhite from '../../img/unlimited-white.svg'; 
// COMPONENTS 
import {useHistory} from 'react-router-dom'   

const Home = () => {
  const history = useHistory()  
    return (
      <div style={{marginTop: '150px'}}>
        <div  className="m-5">
        <div className="pricing m-2">
          <span>Pricing</span>
        </div>
        <div className="pricing-desc m-2">
          <span>Choose the right plan for your team</span>
        </div>
        <Row className="mt-3 row-md-4">
            <Col> 
            <div className={"cursor-pointer"}>
              <div className="spacer-primary mt-3 ml-3 mr-3"></div>  
                <div className="m-3">
                  <div className="avatar pt-2 w-100">
                      <span className="font-24 font-weight-bold" style={{color:'#616569'}}>FREE</span>
                  </div> 
                  <div className="pt-3">
                      <span className="font-22 font-w-600 " style={{color: "#616569"}}>Rp 0</span> 
                      <span className="font-14 font-w-600" style={{color: "#616569"}}> / Month</span>
                  </div> 
                  <div className="mt-2">
                      <span className="font-14 font-w-400" style={{color: "#616569"}}>For 5 User</span>
                  </div> 
                </div> 
                <div className="spacer m-3"></div>  
                <div className="m-3">
                    <div className="mt-2"><span className="font-14 font-w-400 color-grey ">5 Board</span></div>
                    <div className="mt-2"><span className="font-14 font-w-400 color-grey">1 Teams</span></div>
                    <div className="mt-2"><span className="font-14 font-w-400 color-grey">1 Workspace</span></div>
                    <div className="mt-2"><span className="font-14 font-w-400 color-grey">10 Column Type</span></div>
                    <div className="mt-2"><span className="font-14 font-w-400 color-grey">1 Board / Dashboard</span></div>
                    <div className="mt-2">
                      <img src={Unlimited}></img>
                      <span className="font-14 font-w-400 color-grey ml-1">Viewer</span>
                    </div>
                    <div className="mt-2">
                      <img src={XRed}></img>
                      <span className="font-14 font-w-400 color-grey ml-2 ">Import Task</span>
                    </div>
                    <div className="mt-2">
                      <img src={Check}></img>
                      <span className="font-14 font-w-400 color-grey ml-2 ">Kanban View</span>
                      </div>
                      <div className="mt-2">
                      <img src={Check}></img>
                      <span className="font-14 font-w-400 color-grey ml-2 ">Timeline View</span>
                      </div>
                    <div className="mt-2">
                      <img src={Check}></img>
                      <span className="font-14 font-w-400 color-grey ml-2 ">Private Boards</span>
                    </div>
                    <div className="mt-2"><span className="font-14 font-w-400 color-grey">5GB File Storage</span></div>
                    <div className="mt-2"><span className="font-14 font-w-400 color-grey">1 Week Log Activity</span></div>
                    <div className="mt-2">
                      <img src={Check}></img>
                      <span className="font-14 font-w-400 color-grey ml-2 ">Access Knowladge Base</span>
                    </div>
                    <div className="mt-2">
                      <img src={Check}></img>
                      <span className="font-14 font-w-400 color-grey ml-2 ">Customer Support</span>
                    </div>
                    <div className="mt-2 text-center btn-pricing mt-4" onClick={()=>history.push("/register")}> 
                      <span className="font-14 font-w-400 color-grey ml-2 ">Start For Free</span>
                    </div>
                </div>
              
            </div>
            </Col>
            <Col>
            {/* <div className="price-list cursor-pointer" onClick={()=>{showModalPayment({isShow:true, plan: 'Basic'})}}> */}
            <div className={"cursor-pointer pricing-center"}>
            <div className="spacer-primary mt-3 ml-3 mr-3"></div>  
                <div className="m-3">
                  <div className="avatar pt-2 w-100">
                      <span className="font-24 font-weight-bold text-white">BASIC</span>
                  </div>
                  <div className="pt-3"> 
                      <span className="font-22 font-w-600 text-white">Rp 20.000</span>
                      <span className="font-14 font-w-600 text-white"> / Users / Month</span>
                  </div>
                  <div className="mt-2">
                      <span className="font-14 font-w-400 text-white">For 30 User</span>
                  </div> 
                </div>
                <div className="spacer m-3"></div>  
                <div className="m-3 pb-3">
                    <div className="mt-2"><span className="font-14 font-w-400 text-white">50 Board</span></div> 
                    <div className="mt-2">
                      <img src={CheckWhite}></img>
                      <span className="font-14 font-w-400 text-white ml-1">30 Teams</span>
                    </div>
                    <div className="mt-2"><span className="font-14 font-w-400 text-white">3 Workspace / Team</span></div>
                    <div className="mt-2"><span className="font-14 font-w-400 text-white">10 Column Type</span></div>
                    <div className="mt-2"><span className="font-14 font-w-400 text-white">50 Dashboard</span></div> 
                    <div className="mt-2">
                      <img src={UnlimitedWhite}></img>
                      <span className="font-14 font-w-400 text-white ml-1">Viewer</span>
                    </div>
                    <div className="mt-2">
                      <img src={CheckWhite}></img>
                      <span className="font-14 font-w-400 text-white ml-2 ">Import Task</span>
                    </div> 
                    <div className="mt-2">
                      <img src={CheckWhite}></img>
                      <span className="font-14 font-w-400 text-white ml-2 ">Kanban View</span>
                    </div>
                      <div className="mt-2">
                      <img src={CheckWhite}></img>
                      <span className="font-14 font-w-400 text-white ml-2 ">Timeline View</span>
                      </div>
                     <div className="mt-2">
                      <img src={CheckWhite}></img>
                      <span className="font-14 font-w-400 text-white ml-2 ">Private Boards</span>
                    </div>
                    <div className="mt-2"><span className="font-14 font-w-400 text-white">25GB File Storage</span></div>
                    <div className="mt-2"><span className="font-14 font-w-400 text-white">6 Months Log Activity</span></div>
                    <div className="mt-2">
                      <img src={CheckWhite}></img>
                      <span className="font-14 font-w-400 text-white ml-2 ">Access Knowladge Base</span>
                    </div>
                    <div className="mt-2">
                      <img src={CheckWhite}></img>
                      <span className="font-14 font-w-400 text-white ml-2 ">Customer Support</span>
                    </div>
                    <div className="mt-2 text-center btn-pricing-white mt-4" onClick={()=>history.push({pathname:"/register", state :"basic"})}> 
                      <span className="font-14 font-w-400 color-grey ml-2 ">Free 30-Days Trial</span>
                    </div>
                </div>
            </div>
            </Col>
            <Col>
            {/* <div className="price-list cursor-pointer" onClick={()=>{showModalPayment({isShow:true, plan: 'Basic'})}}> */}
            <div className={"cursor-pointer"}>
              <div className="spacer-primary mt-3 ml-3 mr-3"></div>  
                <div className="m-3">
                  <div className="avatar pt-2 w-100">
                      <span className="font-24 font-weight-bold"  style={{color: '#4FBCB6'}}>BASIC ++</span>
                  </div> 
                  <div className="pt-3">
                      <span className="font-22 font-w-600 " style={{color: "#616569"}}>Contact Sales</span> 
                  </div> 
                  <div className="mt-2">
                      <span className="font-14 font-w-400 text-white">{"For > 30 User" }</span>
                  </div> 
                </div> 
                <div className="spacer m-3"></div>  
                <div className="m-3">
                    <div className="mt-2">
                      <img src={Unlimited}></img>
                      <span className="font-14 font-w-400 color-grey ml-2 ">Board</span>
                    </div>
                    <div className="mt-2">
                      <img src={Unlimited}></img>
                      <span className="font-14 font-w-400 color-grey ml-2 ">Teams</span>
                    </div>
                     <div className="mt-2"><span className="font-14 font-w-400 color-grey">3 Workspace / Team</span></div>
                    <div className="mt-2"><span className="font-14 font-w-400 color-grey">10 Column Type</span></div>
                    <div className="mt-2">
                      <img src={Unlimited}></img>
                      <span className="font-14 font-w-400 color-grey ml-2 ">Dashboard</span>
                    </div>
                    <div className="mt-2">
                      <img src={Unlimited}></img>
                      <span className="font-14 font-w-400 color-grey ml-2 ">Viewer</span>
                    </div> 
                    <div className="mt-2">
                      <img src={Check}></img>
                      <span className="font-14 font-w-400 color-grey ml-2 ">Import Task</span>
                    </div>
                     <div className="mt-2">
                      <img src={Check}></img>
                      <span className="font-14 font-w-400 color-grey ml-2 ">Kanban View</span>
                      </div>
                      <div className="mt-2">
                      <img src={Check}></img>
                      <span className="font-14 font-w-400 color-grey ml-2 ">Timeline View</span>
                      </div>
                     <div className="mt-2">
                      <img src={Check}></img>
                      <span className="font-14 font-w-400 color-grey ml-2 ">Private Boards</span>
                    </div>
                    <div className="mt-2"><span className="font-14 font-w-400 color-grey">{"> 25GB File Storage"}</span></div>
                    <div className="mt-2"><span className="font-14 font-w-400 color-grey">6 MonthsLog Activity</span></div>
                    <div className="mt-2">
                      <img src={Check}></img>
                      <span className="font-14 font-w-400 color-grey ml-2 ">Access Knowladge Base</span>
                    </div>
                    <div className="mt-2">
                      <img src={Check}></img>
                      <span className="font-14 font-w-400 color-grey ml-2 ">Customer Support</span>
                    </div>
                    <div className="mt-2 text-center btn-pricing mt-4" onClick={()=>history.push({pathname:"/register", state :"basic ++"})}> 
                      <span className="font-14 font-w-400 color-grey ml-2 ">Free 30-Days Trial</span>
                    </div>
                </div>
            </div>
            </Col>
        </Row>
        </div>
      </div>
   
    );
  } 

export default Home