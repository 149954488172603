import React, { createContext, useState, useEffect, useRef, useReducer } from "react"
import { MakeRequest, MakeRequestDownload } from "../api/Api"
import _ from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import { getParameterByName } from "../helpers/utils/Utils"
import { ChartReducer } from '../reducers/DashboardReducer'
import socketIOClient from "socket.io-client"
import { tr } from "date-fns/locale"
import { param } from "jquery"
import { NotificationManager } from "react-notifications"

const MytaskContext = createContext()
const MytaskContextProvider = (props) => {
    const [mytask, setMytask] = useState([])
    const [selectedMytask, setSelectedMytask] = useState(false)
    return (
        <MytaskContext.Provider value={{
            mytask,
            setMytask,
            selectedMytask,
            setSelectedMytask
        }}>
            {props.children}
        </MytaskContext.Provider>
    )
}

export { MytaskContext, MytaskContextProvider }