import React, {useContext, useEffect, useRef, useState} from 'react'
import {Col, Nav, Popover, Form, OverlayTrigger, Image, Row } from 'react-bootstrap'   
import './Style.css'
import {StyleSelect} from "../../helpers/select/Select"
import {Style} from "../../helpers/style/Style"
import Select from 'react-select'  
import {DashboardContext} from "../../context/DashboardContext" 
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'   
import BoardIcon from "../../img/board.svg" 
import BoardActiveIcon from "../../img/board-active.svg" 
import DashboardIcon from "../../img/dashboard.svg" 
import DashboarActivedIcon from "../../img/dashboard-active.svg" 
import AddIcon from "../../img/add.svg" 
import FilterIcon from "../../img/filter.svg" 
import DownIcon from "../../img/down.svg"  
import VertIcon from "../../img/vert.svg"  
import CheckIcon from "../../img/check.svg"   
import PlusIcon from "../../img/plus.svg"   
import DeleteIcon from "../../img/delete.svg"   
import DuplicateIcon from "../../img/duplicate.svg"   
import FolderIcon from "../../img/folder-no-active.svg"   
import FolderActiveIcon from "../../img/folder.svg"   
import {initialString, stripHtml } from '../../helpers/utils/Utils' 
import _ from 'lodash' 
import {useHistory, useLocation} from 'react-router-dom'  
import DropdownChevronLeft from "monday-ui-react-core/dist/icons/DropdownChevronLeft"
import DropdownChevronRight from "monday-ui-react-core/dist/icons/DropdownChevronRight" 
import { param } from 'jquery'


const Menu = () =>{ 
    const {workspace, params, setParams, setNewBoard, setFlagNewBoard, board, setBoardActive, 
        boardActive, setNewDashboard, dispatchChart, selectedTeams, duplicateBoard, 
        duplicateDashboard, setShowDelete,setViewType, setSelectedTeams,setWorkspaceTeams,
        setSelectedWorkspace, selectedWorkspace, teams, getWorkspace, setAddNewWorkspace,getMember,
        getNotifCount, setAddNewFolder, setTable, width, setWidth, setNewTeams, setLocalStorage,getWorkspaceTeams,
        setValueSearch, setValueSort, setValueFilter, setFilterElement, setSortElement, getWorkspaceSeeAll, workspaceSeeAll, setModalSeeAllWorkspace} = useContext(DashboardContext)  
    const isFirstRunWorkspace = useRef(true) 
    const isFirstRun = useRef(true) 
    let ref = useRef(null) 
    let refCurrentSelected = useRef(false)
    const [stateWorkspace, setStateWorkspace] = useState([])
    const [selectedItem, setSelectedItem] = useState()
    const [teamsData, setTeamsData] = useState([])
    const history = useHistory() 
    const location = useLocation()

    useEffect(()=>{
        setStateWorkspace([])
        if(!workspace.length) return  
        //=========Asign value workspace========//
        let arrTmp = []
        let alreadyDefault =  false
        workspace.map((item, index) => {    
            let objTmp = {}
            objTmp.value = item.id
            objTmp.label = item.name
            objTmp.team_id = item.team_id
            objTmp.team_name = item.team[0].name
            objTmp.created_by = item.created_by
            objTmp.permission_id = item.permission_id
            arrTmp.push(objTmp) 
            
            if(params.selectedWorkspace && params.selectedWorkspace.value === item.id ){ 
                alreadyDefault = true
                setSelectedWorkspace(objTmp) 
                setParams({...params, workspace_id: (params.from && params.from === "android") ? params.selectedBoard.workspace_id : item.id, team_id: item.team_id, workspace_url : null, permission_id : item.permission_id})
                setSelectedWorkspaceLocal(objTmp)
            } 

            if(params.selectedWorkspace && !params.selectedWorkspace.value && index === params.indexWorkspaceActive){
                alreadyDefault = true
                setSelectedWorkspace(objTmp) 
                setParams({...params, workspace_id: (params.from && params.from === "android") ? params.selectedBoard.workspace_id : item.id, team_id: item.team_id, workspace_url : null, permission_id : item.permission_id})
                setSelectedWorkspaceLocal(objTmp)
            }
        }) 
        if(workspace.length === 0 ) setSelectedWorkspace([])  
        if(!alreadyDefault){
            let item = arrTmp[0] 
            setParams({...params, workspace_id: (params.from && params.from === "android") ? params.selectedBoard.workspace_id : item.id, team_id: item.team_id, workspace_url : null, permission_id : item.permission_id})
            // setParams({...params, workspace_id: item.value, team_id: item.team_id, workspace_url : null, permission_id : item.permission_id})
            setSelectedWorkspace(item)  
        } 
        setStateWorkspace(arrTmp) 
    },[workspace])

    useEffect(()=>{ 
        //=========Asign value teams========//
        let arrTmp = [] 
        let alreadyDefault = false
        if(isFirstRun.current){
            isFirstRun.current = false
            return
        } 
        if(!teams.length){
            setNewTeams(true)
            return
        }
        teams.map((item) => {   
            let objTmp = {}
            objTmp.created_by = item.team.user_id
            objTmp.value = item.team.id
            objTmp.label = stripHtml(item.team.name) 
            objTmp.is_default = item.team.is_default 
            objTmp.permission_id = item.permission_id 
            objTmp.permission_id = item.permission_id 
            objTmp.subscriber = item.subscriber 
            if(item.team.is_active === true){
                arrTmp.push(objTmp)   
                if(selectedTeams && selectedTeams.value === item.team.id) {
                    setSelectedTeams(objTmp) 
                    alreadyDefault = true
                } 
            } 
        })
        if(!alreadyDefault)  setSelectedTeams(arrTmp[0]) 
        setTeamsData(arrTmp)
         
    },[teams])

    useEffect(()=>{ 
        if(selectedTeams && refCurrentSelected.current !== selectedTeams.value){ 
            getDataWorkspace(selectedTeams)
            refCurrentSelected.current = selectedTeams.value
        }
       
    },[selectedTeams]) 

    useEffect(()=>{  
        if(!selectedWorkspace.value) isFirstRunWorkspace.current = {}
        if(isFirstRunWorkspace.current === selectedWorkspace.value) return
        //=========Get board on selected workspace========//
        if(selectedWorkspace.value !== undefined && isFirstRunWorkspace.current !== selectedWorkspace.value){
            getWorkspaceTeams(selectedWorkspace.value)
            getMember(selectedWorkspace.team_id)  
            setFlagNewBoard(new Date().getUTCMilliseconds()) 
            isFirstRunWorkspace.current = selectedWorkspace.value 
        } 
    },[selectedWorkspace])

    // useEffect(()=>{
    //     workspaceSeeAll.map(item =>{
            
    //     }) 

    // },[workspaceSeeAll])

    const handleSelectedWorkspace = (selected) =>{  
        ref.click()
        dispatchChart({type : 'CLEAR_SELECTED_BOARD'})
        setSelectedWorkspaceLocal(selected)
    } 

    const setSelectedWorkspaceLocal =(selected)=>{
        let paramTmp = {...params, workspace_id: selected.value, indexBoardActive: 0, team_id: selected.team_id, permission_id : selected.permission_id}
        paramTmp.selectedWorkspace = selected
        setParams(paramTmp)
        setSelectedWorkspace(selected)  
        setLocalStorage(paramTmp)
    }

    const handleAddBoard = () => {
        setNewBoard(true)
        //=========CLOSE POPOVER========//
        ref.click()
    }

    const handleClickBoard = (item, index) => { 
        setValueSearch({value:"", type: ""}) 
        setValueSort({value:"", type: ""}) 
        setValueFilter({value:"", type: ""}) 
        setFilterElement([])
        setSortElement([])
        if(item.type === 1) history.push("/app/board")
        setBoardActive(item)
        dispatchChart({type : 'CLEAR_SELECTED_BOARD'})
        let paramTmp = {...params, indexBoardActive: index, id_url : -1} 
        paramTmp.selectedBoard = item 
        setParams(paramTmp) 
        setViewType("Default")
        setLocalStorage(paramTmp)
        
    }

    const duplicate = async (type) => {
        ref.click()
        if(type === 1) await duplicateBoard()
        else await duplicateDashboard()
    } 

    const handleSelectedTeams = async (selected) =>{
        ref.click()
        // history.push("/app/workspace") 
        dispatchChart({type : 'CLEAR_SELECTED_BOARD'})
        let paramTmp = {...params, indexBoardActive: 0, indexWorkspaceActive: 0, id_url : -1}
        paramTmp.selectedTeams = selected
        setParams(paramTmp)
        setWorkspaceTeams([]) 
        setSelectedTeams(selected)
        setStateWorkspace([])
        setTable([])
        setLocalStorage(paramTmp)
        setValueSearch({value:"", type: ""}) 
        setValueSort({value:"", type: ""}) 
        setValueFilter({value:"", type: ""}) 
    }

    const getDataWorkspace = async (selected) =>{ 
        selected.name = selected.label
        let newParams = _.cloneDeep(params)
        selected.id = selected.value
        newParams.team = selected 
        newParams.permission_id = selected.permission_id
        newParams.team_id = selected.value  
        newParams.indexBoardActive = 0  
        newParams.indexWorkspaceActive = 0
        localStorage.setItem('saatkerja', JSON.stringify(newParams))  
        setParams(newParams)  
        await getWorkspace(newParams)   
        // getNotifCount(params.id, selected.value)
    }


    const popover = (
        <Popover id="popover-basic"> 
            <Popover.Content>
                <Nav.Link className="nav-menu p-2 pr-5 mt-1" onClick={handleAddBoard}>  
                    <Image src={BoardIcon}></Image>
                    <span className="workspace-item-name-child ml-2 font-14 align-text-top">New Blank Board</span>
                </Nav.Link> 
                <Nav.Link className="nav-menu p-2 pr-5 mt-1 mb-1" onClick ={()=>{setNewDashboard(true);ref.click()}}> 
                    <Image src={DashboardIcon}></Image>
                    <span className="workspace-item-name-child align-text-top ml-2 font-14">New Blank Dashboard</span>
                </Nav.Link> 
                <Nav.Link hidden className="nav-menu p-2 pr-5 mt-1 mb-1" onClick ={()=>{setAddNewFolder(true);ref.click()}}> 
                    <Image src={FolderIcon}></Image>
                    <span className="workspace-item-name-child align-text-top ml-2 font-14">New Folder</span>
                </Nav.Link> 
            </Popover.Content>
        </Popover>
    )

    const popoverFilter = (
        <Popover id="popover-basic"> 
            <Popover.Content>
                <Col>
                    <span className="color-grey">Board Type</span>
                </Col>
                <Col className="mt-2">
                    <Form.Check type="checkbox" label="Main"/>
                </Col>
                <Col className="mt-1">
                    <Form.Check type="checkbox" label="Private"/>
                </Col>
                <Col className="mt-1">
                    <Form.Check type="checkbox" label="Shareable"/>
                </Col>
                <Col className="mt-3">
                    <span className="color-grey">Filters</span>
                </Col> 
                <Col className="mt-2">
                    <Form.Check type="checkbox" label="Subscribed"/>
                </Col>
                <Col className="mt-1">
                    <Form.Check type="checkbox" label="Dashboard Only"/>
                </Col>
            </Popover.Content>
        </Popover>
    )

    const popoverBoard = (
        <Popover id="popover-basic"> 
            <Popover.Content>
                <Nav.Link className={ params.permission_id < 4 ? "nav-menu p-2 pr-5" : "nav-menu p-2 pr-5 disabled"} onClick={()=>duplicate(boardActive.type)}> 
                     <Image src={DuplicateIcon}></Image>
                    <span className="workspace-item-name-child align-text-top ml-2 font-14">Duplicate</span>
                </Nav.Link> 
                <Nav.Link className={ params.permission_id < 4 ? "nav-menu p-2" : "nav-menu p-2 disabled"} onClick={()=>{setShowDelete({isShow: true, item : selectedItem}); ref.click()}}> 
                    <Image src={DeleteIcon}></Image>
                    <span className="workspace-item-name-child align-text-top ml-2 font-13">{boardActive.type === 1 ? "Delete Board" : "Delete Dashboard"}</span>
                </Nav.Link> 
            </Popover.Content>
        </Popover>
    )

    const popoverTeams = (
        <Popover id="popover-teams"> 
            <Popover.Content>
                <div className="header-teams">
                    <span className="font-14">Switch Team</span>
                </div>
                {teamsData.map(item => { 
                    return (
                        <div className="pl-2 pr-2 pt-1 pb-1" key={item.value}> 
                            <Nav.Link className={ "nav-menu pl-2 nav-menu-teams"} onClick={()=>{handleSelectedTeams(item)}}> 
                                <span style={Style.circleTextTeams}>
                                    <span style={Style.circleTextTeamsSpan}>{initialString(item.label)}</span>
                                </span>
                                <span className="workspace-item-name-child align-text-top ml-2 font-14">{item.label}</span>
                                {item.value === selectedTeams.value ? 
                                    <div className="float-right ml-5">
                                        <Image className="float-right mt-2" src={CheckIcon} ></Image>
                                    </div>
                                    :null
                                } 
                            </Nav.Link>  
                        </div>  
                    )
                    })
                } 
            </Popover.Content>
        </Popover>
    )

    const popoverWorkspace = (
        <Popover id="popover-teams"> 
            <Popover.Content style={{maxHeight: "335px", overflowY: "scroll"}}> 
                {stateWorkspace.map(item => { 
                    return (
                        <div className="pl-2 pr-2 pt-1 pb-1" key={item.value}> 
                            <Nav.Link className={ "nav-menu p-2 nav-menu-teams"} onClick={()=>{handleSelectedWorkspace(item)}}> 
                            {/* <Row>
                                <Col md="2">
                                    <span style={Style.circleText}>
                                        <span style={Style.circleTextSpan}>{initialString(item.label)}</span>
                                    </span>
                                </Col>
                                
                            </Row> */}
                            {/* <Row>
                                <Col md="2">
                                </Col>
                                <Col className="col pr-1 pl-1">
                                    <div className="d-inline">
                                        <span className="pl-2 font-10 text-dark lable-no-wrap">{item.team_name}</span>
                                    </div>
                                </Col>
                            </Row> */}
                                <span style={Style.circleText}>
                                    <span style={Style.circleTextSpan}>{initialString(item.label)}</span>
                                </span>
                                <span className="workspace-item-name-child align-text-top ml-2 font-14">{item.label}</span>
                                {item.value === selectedWorkspace.value ? 
                                    <div className="float-right ml-5">
                                        <Image className="float-right mt-2" src={CheckIcon} ></Image>
                                    </div>
                                    :null
                                } 
                            </Nav.Link>  
                        </div>  
                    )
                    })
                } 
                {/* <div className="spacer mt-3 ml-3 mr-3"></div>
                <div className='text-center pt-4 pb-4 cursor-pointer font-10 font-w-600 text-dark' onClick={()=>{setModalSeeAllWorkspace(true);ref.click()}}>
                    See All Workspace
                </div> */}
            </Popover.Content>
        </Popover>
    )

   

    return ( 
        <React.Fragment> 
            <div hidden={teams.length ? false: false} className="menu-controll cursor-pointer" style={{left: width.controll, display: 'none'}} 
                onClick={()=>setWidth(width.menu === '255px' ? {menu: '0px', controll: '51px', board: '35px'} : 
                {menu: '255px', controll: '307px', board: '290px'})}>
                {width.menu === '255px' ? <DropdownChevronLeft style={{marginLeft: '2px', marginTop: '3px'}}/>
                : <DropdownChevronRight style={{marginLeft: '2px', marginTop: '3px'}}/>}
            </div>
            {selectedTeams ? 
                <Col className="position-absolute bg-white menu m-0 p-0 h-100" ref={r => (ref = r)} style={{width: width.menu, display: 'none'}}>  
                    <div className="w-100 mt-3">
                        <div className="d-inline"> 
                            <span className="workspace-text d-inline pl-3">Teams</span>
                        </div>    
                    
                        <div className="d-inline"> 
                            <OverlayTrigger trigger="click" placement="right" overlay={popoverTeams} rootClose>
                                <Image src={VertIcon} className="cursor-pointer float-right menu-icon-down"></Image>
                            </OverlayTrigger>
                        </div> 
                    </div>
                    <div className="p-3 cursor-pointer mb-2">
                        <Row>
                            <Col md="2">
                                <span style={Style.circleTextTeams}>
                                    <span style={Style.circleTextTeamsSpan}>{selectedTeams && selectedTeams.label? initialString(selectedTeams.label): "NA"}</span>
                                </span>
                            </Col>
                            <Col className="col pr-1 pl-1">
                                <div className="d-inline">
                                    <span className="pl-2 font-14 font-w-600 text-dark lable-no-wrap">{selectedTeams.label ? selectedTeams.label: "No Available"}</span>
                                </div> 
                            </Col>
                        </Row> 
                    </div>
                    {/* <div className="ml-3 mr-3 mb-3"></div>   */}

                    <div className="spacer ml-3 mr-3 mb-3"></div>
                    

                    <div className="w-100">
                        <div className="d-inline"> 
                            <span className="workspace-text d-inline pl-3">Workspace</span>
                        </div>    
                        <div className="d-inline"> 
                            <OverlayTrigger trigger="click" placement="right" overlay={popoverWorkspace} rootClose>
                                <Image src={DownIcon} className="cursor-pointer float-right menu-icon-down"></Image>
                            </OverlayTrigger>
                            {/* <OverlayTrigger trigger="click" placement="right" overlay={popover} rootClose> */}
                            {params.permission_id > 2 || selectedWorkspace.permission_id > 2 ? null :
                                <Image src={AddIcon} className="cursor-pointer float-right menu-icon-down" style={{position: "relative", top: "3px", width: "15px", right: "30px"}} onClick={()=>setAddNewWorkspace(true)}></Image>
                            }
                            {/* </OverlayTrigger> */}
                        </div>
                    </div>
                    
                    <div className="pt-3 pl-3 pr-3">
                        <Row>
                            <Col md="2" className='cursor-pointer' onClick={()=>{history.push('/app/workspace'); setBoardActive([])}}>
                                <span style={Style.circleText}>
                                    <span style={Style.circleTextSpan}>{selectedWorkspace && selectedWorkspace.label? initialString(selectedWorkspace.label): "NA"}</span>
                                </span>
                            </Col>
                            <Col className="col pr-1 pl-1 cursor-pointer" onClick={()=>{history.push('/app/workspace'); setBoardActive([])}}>
                                <div className="d-inline">
                                    <span className="pl-2 font-14 font-w-600 text-dark lable-no-wrap">{selectedWorkspace.label ? selectedWorkspace.label: "No Available"}
                                    {/* <pre className="font-10 lable-no-wrap" style={{position: "relative", bottom: "9px", right: "2px", color: "grey", paddingBottom: "0px !important"}}> */}
                                        {/* {selectedWorkspace.team_name} */}
                                        {/* </pre> */}
                                    </span>
                                    {/* <span style={{position: "relative", bottom: "10px", left: "8px", color: "grey", fontWeight: "600"}}>{selectedWorkspace.team_name}</span> */}
                                </div>
                            </Col>
                            {/* {params.permission_id > 2 || selectedWorkspace.permission_id > 2 ? null :
                                <div className="d-inline"> 
                                    <Image src={AddIcon} onClick={()=>setAddNewWorkspace(true)} className="cursor-pointer float-right menu-icon-down" style={{position: "relative", top: "11px", width: "15px"}}></Image>
                                </div>
                            } */}
                        </Row> 
                    </div>
                    {/* <div className='text-center pt-1 cursor-pointer' style={{color: "grey"}} onClick={()=>setAddNewWorkspace(true)}>
                        See All Workspace
                    </div> */}
                    
                    {/* <OverlayTrigger trigger="click" placement="right" overlay={popover} rootClose>
                        <Nav.Link  className={params.permission_id < 4 &&  selectedWorkspace.label ? "nav-menu pl-4" : "nav-menu pl-4 disabled"}> 
                            <Image src={AddIcon}></Image>
                            <span className="workspace-item-name-child align-text-top ml-2">Add</span> 
                        </Nav.Link> 
                    </OverlayTrigger>
                    <OverlayTrigger trigger="click" placement="right" overlay={popoverFilter} rootClose>
                        <Nav.Link className="nav-menu pl-4"> 
                            <Image src={FilterIcon}></Image>
                            <span className="workspace-item-name-child align-text-top ml-2">Filter</span>
                        </Nav.Link> 
                    </OverlayTrigger> */}
                    <Nav.Link className="pl-3" hidden> 
                        <Select styles={StyleSelect({menuListHeight: 135})} placeholder ="Search boards" maxMenuHeight={160}/>
                    </Nav.Link> 

                    <div className="spacer ml-3 mr-3 mb-3 mt-2"></div>   

                    <div className="w-100 mt-1 mb-2">
                        <div className="d-inline"> 
                            <span className="workspace-text d-inline pl-3">Board & Dashboard</span>
                        </div>    
                        <div className="d-inline"> 
                            {/* <OverlayTrigger trigger="click" placement="right" overlay={popoverWorkspace} rootClose>
                                <Image src={DownIcon} className="cursor-pointer float-right menu-icon-down"></Image>
                            </OverlayTrigger> */}
                            {/* <OverlayTrigger trigger="click" placement="right" overlay={popoverFilter} rootClose>
                                <Image src={FilterIcon} className="cursor-pointer float-right menu-icon-down" style={{position: "relative", top: "4px", width: "13px", marginLeft: "7px"}}></Image>
                            </OverlayTrigger> */}
                            <OverlayTrigger trigger="click" placement="right" overlay={popover} rootClose>
                                <Image src={AddIcon} className="cursor-pointer float-right menu-icon-down" style={{position: "relative", top: "3px", width: "15px"}}></Image>
                            </OverlayTrigger>
                            
                        </div>
                    </div>
                
                    
                    <div style={{overflow:'auto', maxHeight: '45vh'}}>
                        {board.map((item, index)=> {  
                            return(
                                params.permission_id > 3 && item.type === 2 && item.permission_id === 2?
                                <></>:
                                <Nav.Link className={item.id === boardActive.id && !location.pathname.includes("workspace")? "nav-menu pl-4 menu-active": "nav-menu pl-4"} 
                                key={item.id} onClick={item.type === 3 ? ()=> ref.click() : ()=>{handleClickBoard(item, index)}}> 
                                    {item.type === 1 ?
                                        item.id === boardActive.id && !location.pathname.includes("workspace")?
                                            <Image src={BoardActiveIcon} className="float-left mt-1"></Image>
                                            :
                                            <Image src={BoardIcon} className="float-left mt-1"></Image>
                                        :
                                        item.type === 3 ?
                                            item.id === boardActive.id && !location.pathname.includes("workspace")?
                                            <Image src={FolderIcon} className="float-left mt-1"></Image>
                                            :
                                            <Image src={FolderIcon} className="float-left mt-1"></Image>
                                        :
                                        item.id === boardActive.id && !location.pathname.includes("workspace")?
                                            <Image src={DashboarActivedIcon} className="float-left mt-1"></Image>
                                            :
                                            <Image src={DashboardIcon} className="float-left mt-1"></Image>
                                    }
                                    <span className="workspace-item-name-child align-text-top font-14 ml-2 lable-no-wrap-board">{item.title}</span>
                                    <OverlayTrigger trigger="click" placement="right" overlay={popoverBoard} rootClose>
                                        <MoreHorizIcon style={{ fontSize: 20, color: 'black' }} onClick={()=>{setSelectedItem(item)}} fontSize="small" id ="more-board-option"className="float-right more-board-option"/> 
                                    </OverlayTrigger>
                                </Nav.Link>  
                            )
                        })}
                    </div> 
                    {/* {params.permission_id > 2 || selectedWorkspace.permission_id > 2 ? null :
                        <div hidden ={width.menu === '255px' ? false : true} className="create-workspace cursor-pointer" onClick={()=>setAddNewWorkspace(true)}>
                            <Image src={PlusIcon} className="mr-2 mb-2"></Image>
                            <span className="font-16 pt-1 font-w-400">Create New Workspace</span>
                        </div>
                    } */}

                </Col> 
            : null }

           
        </React.Fragment>
    )
}

export default Menu