import React, {useState, useContext, useEffect} from 'react'
import {Col, Row, Form, Image, Button, Alert, Spinner } from 'react-bootstrap'  
import "../css/login.css"
import { useForm } from "react-hook-form"
import { MakeRequest } from "../../api/Api"  
import {useHistory} from 'react-router-dom'  
import { useParams } from "react-router-dom" 
import {asyncRequest } from '../../helpers/utils/Utils' 
import ImageLogin from "../../img/login.png" 
import LockIcon from "../../img/lock.svg" 

const ResetPassword = () =>{ 
    const history = useHistory()
    const [isLoading, setLoading] = useState(false) 
    const [dataReset, setDataReset] = useState(false) 
    const [alert, setAlert] = useState({msg: 'Change password successfully.', variant: 'success', hidden: true}) 
    const { register, handleSubmit, watch, errors } = useForm() 
    const {token} = useParams()  
    const [typePassword, setTypePassword] = useState("password") 

    useEffect(()=>{
        const getResetPassword = async ()=> {   
            const result = await MakeRequest('post','reset-password-token', {token: token})   
            if(result.status === 200) { 
                setDataReset(result.data)
            }
        }
        getResetPassword()
    },[])

    const onSubmit = async (params) => {  
        setAlert({msg: 'Change password successfully.', variant: 'success', hidden: true}) 
        setLoading(true)
  
        const result = await MakeRequest('post','reset-password', {email:  dataReset.email, password: params.password})   
        if(result.status !== 200) {
            asyncRequest(1000).then(()=>{
                let detailError = result.data.detail
                setAlert({msg: detailError === undefined ? "Server error": detailError, variant: 'danger', hidden: false})
            })
        }else{
            asyncRequest(1000).then(()=>{
                setAlert({msg: 'Change password successfully.', variant: 'success', hidden: false})
                localStorage.removeItem('saatkerja')  
            })
            asyncRequest(1000).then(()=>{ 
                history.push('/login')
            })
        }
        asyncRequest(1000).then(()=>{
            setLoading(false)
        }) 
    }


    return(
        <Row xs="12" className="no-gutters vh-100 vw-100">
            {dataReset ?
            <React.Fragment>
                <Col className="m-auto" md={6}>
                    <Col md={10}>
                        <div className="m-5">
                            <div className="txt-login">
                                <span>Setup new password</span>
                            </div>
                            <div className="mt-2 txt-login-child">
                                <span className="color-grey-darker">Setup new password or</span>  <span className="cursor-pointer text-primary" onClick={()=>{history.push('/login')}}> Sign in here</span>
                            </div>
                            <div className="mt-5">
                                <Form onSubmit={handleSubmit(onSubmit)}> 
                                    <Form.Group controlId="formBasicEmail">  
                                    <div className="pass-wrapper">
                                        <Form.Control className="font-16 input-text" name="password" type={typePassword} placeholder="Minimal 8 characte" ref={register({ required: true , minLength: 8})} />
                                        {typePassword === "password" ? 
                                            <Image src={LockIcon} onClick={()=> setTypePassword("text")} className="eye-icon"></Image>
                                        :
                                            <Image src={LockIcon} onClick={()=> setTypePassword("password")} className="eye-icon"></Image>
                                        }
                                    </div>
                                        <div className="mt-3">
                                            {errors.password && <span>This field is required / minimal 8 character</span>}
                                        </div> 
                                    </Form.Group> 
                                    <Form.Group className="pt-2" hidden={alert.hidden}>  
                                        <Alert variant={alert.variant} >
                                            <span className="font-15">{alert.msg}</span>
                                        </Alert>
                                    </Form.Group>
                                    
                                    <div className="w-100 text-center pt-2">
                                        <Button
                                            variant="primary"
                                            disabled={isLoading}
                                            className="font-16 text-white text-center font-weight-bold btn-forgot"
                                            type="submit" 
                                            >
                                            {isLoading ?
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    />
                                                : "Change Password" 
                                            }
                                            </Button> 
                                    </div> 
                                </Form> 
                            </div>
                        </div>
                    </Col> 
                </Col>
                <Col className="bg-login" md={5}>
                    <Image className="img-login" src={ImageLogin} width="710" height="518"></Image>
                </Col>
            </React.Fragment>
            : null }
       </Row>
    )
}

export default ResetPassword