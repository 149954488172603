//=========REACT INITIAL========//
import React, {useEffect, useState, useContext, useRef} from 'react' 
import {DashboardContext} from "../../../context/DashboardContext" 
import {kanbanData, getStatusColumn } from '../../../helpers/utils/Utils' 
 import Index from './Index'
 
//=========3RD LIBRARY========// 
import _ from 'lodash'   

const Container = (props) =>{
    const {table,status, setColumnUpdate,params, priority, selectedColumnKanban, setShowDetailKanban} = useContext(DashboardContext)  
    const [listData, setData] = useState([]) 
    const [components, setComponents] = useState([]) 

    useEffect(()=>{  
        if(table.length && status.length){
            let data = []
            if(!selectedColumnKanban) return
            let dataStatus = getStatusColumn(table,selectedColumnKanban.value)  
            let tmpTable = _.cloneDeep(table) 
            if(selectedColumnKanban.divideByGroup) {
                 tmpTable.map(item => {  
                    let tmpData = kanbanData([item], dataStatus, selectedColumnKanban)  
                    data.push(tmpData)
                 })
            }else {
                let tmpData = kanbanData(tmpTable, dataStatus, selectedColumnKanban)  
                data.push(tmpData) 
            }   
            setData(data)   
        }
     },[status, priority, selectedColumnKanban]) 

     useEffect(()=>{ 
        if(!listData.length) return
        setComponents([])
        let componentTmp = []    
        listData.map((item, key) =>{   
            componentTmp.push(<Index listData={item} index = {key} />)
        }) 
        setComponents(componentTmp)
     },[listData])
    
    return (      
          <>
           {components.map((content,index)=>{
                    return ( 
                            <div key={index} className={index > 0 ? "mt-5" : ""}>
                                <React.Fragment>
                                    {React.cloneElement(content)} 
                                </React.Fragment>
                            </div>
                        )
                })
            }
          </>
    )
} 

export default Container