import React, {useEffect, useState, useContext, useRef} from 'react'
import {Button, Form, Spinner, Col } from 'react-bootstrap' 
import { useForm } from "react-hook-form"
import { MakeRequest } from "../../api/Api"   
import {DashboardContext} from "../../context/DashboardContext" 
import _ from 'lodash'
import {StyleSelect} from "../../helpers/select/Select"
import Select from 'react-select' 
import {asyncRequest } from '../../helpers/utils/Utils' 
import {NotificationManager} from 'react-notifications' 

const ModalAddMember = (props) =>{ 
    const ref = useRef()
    const [isLoading, setLoading] = useState(false) 
    const { register, handleSubmit, watch, errors } = useForm()
    const [alert, setAlert] = useState({msg: '', variant: 'success', hidden: true}) 
    const {params, getMember, selectedTeamsSetting, permission, showModalPricing} = useContext(DashboardContext)  
    const [listType, setListType] = useState([])
    const [selected, setSelected] = useState({})
    const isFirstRunPermission = useRef(true)

    useEffect(()=>{
        setAlert({...alert, hidden: true}) 
        getListType()
    },[props])

    const getListType = () => {
        let type = []
        permission.map(item =>{
            if(item.id !== 1) {
                    let tmp =    {value:item.id, label: item.name} 
                    type.push(tmp)
                    if(item.id === 4) setSelected(tmp)
            }
        }) 

        setListType(type)
    }

    const onSubmit = async (paramTmp) => {
        setLoading(true) 
        let param = _.cloneDeep(params) 
        param.email = paramTmp.email.toLowerCase()
        param.username = paramTmp.username 
        param.password = paramTmp.password 
        param.team_id = selectedTeamsSetting.id
        param.permission_id = selected.value  
        const result = await MakeRequest('post','user-create-team-member', param)   
        if(result.status !== 200) {
            asyncRequest(1000).then(() => {
                setLoading(false)  
                if(result.message === "limit") showModalPricing(true)
                else NotificationManager.error(result.data? result.data.detail : "Opps... Something went wrong")
            }) 
            
        }else{
            await getMember(selectedTeamsSetting.id) 
            asyncRequest(1000).then(() => {
                NotificationManager.success('Create member successfully')
                setAlert({ hidden: false})
                // setFlagNewMember(new Date().getUTCMilliseconds())
                props.onHide()
                setLoading(false)
            }) 
        } 
    }
    
    const handleChange = (selected) =>{
        setSelected(selected)
    }

    return ( 
         <Form onSubmit={handleSubmit(onSubmit)}> 
            <Form.Group controlId="formBasicEmail" className="p-3 pt-4"> 
                <Form.Row>
                    <Form.Label className="font-14 mt-2 w-25 text-dark font-w-400">Email</Form.Label>
                    <Col>
                        <Form.Control className="font-14" name="email" type="email" placeholder="name@example.com" ref={register({ required: true })} />
                        {errors.email && <span>This field is required</span>}
                    </Col>
                </Form.Row>
                <br />
                <Form.Row>
                    <Form.Label className="font-14 mt-2 w-25 text-dark font-w-400">Name</Form.Label>
                    <Col>
                        <Form.Control className="font-14" name="username" type="text" placeholder="Full Name" ref={register({ required: true })} />
                        {errors.username && <span>This field is required</span>}
                    </Col>
                </Form.Row>
                <br />
                <Form.Row>
                    <Form.Label className="font-14 mt-2 w-25 text-dark font-w-400">Permission</Form.Label>
                    <Col>
                        <Select focus styles={StyleSelect({menuListHeight: 250})}   
                        placeholder=""  
                        options={listType}  
                        isClearable={false} 
                        value={selected}
                        onChange={(selected) => handleChange(selected)}
                        className='m-0 p-0' 
                        ></Select>
                    </Col>
                </Form.Row>
                <br />
                <Form.Row>
                    <Form.Label className="font-14 mt-2 w-25 text-dark font-w-400">Password</Form.Label>   
                    <Col>
                    <Form.Control className="font-14" name="password" type="password" placeholder="Minimal 8 Character" ref={register({ required: true , minLength: 8})} />
                    {errors.password && <span>This field is required / minimal 8 character</span>}
                    </Col>
                </Form.Row>
            </Form.Group>
           
            <div className="spacer mb-4"></div>
            <div className="float-right">
                <Button className="btn-cancel" onClick={props.onHide}>Cancel</Button>  
                <Button 
                    disabled={isLoading}
                    className="btn-yes text-white"
                    type="submit"
                    >
                        {isLoading ?
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                            : "Create" 
                        }
                </Button> 
            </div>
           
        </Form>
                
    )
}

export default ModalAddMember