import React, {useEffect, useState, useContext, useRef} from 'react'

//=========3RD LIBRARY  ========//
import _ from 'lodash'  
import {StyleSelect} from "../../../helpers/select/Select"
import Select from 'react-select' 

//=========ICON LIST========// 
import HighlightOffIcon from '@material-ui/icons/HighlightOff' 

//=========BOOTSTARP INITIAL========//
import {Col, Row} from 'react-bootstrap' 

//=========CONTEXT========//
import {DashboardContext} from "../../../context/DashboardContext"  
import {filterDataTableForFilter } from '../../../helpers/utils/Utils'
 
const Sort = (props) =>{
    const {tableSearch,setValueSort,valueSort} = useContext(DashboardContext)  
    const [sortList, setSortList] = useState([])     
    const [selectedSort, setSelectedSort] = useState([])  
    const [condition, setCondition] = useState([
        {value: 'asc', label: 'Ascending'},
        {value: 'desc', label: 'Desc'}, 
    ])  
    const [selectedCondition, setSelectedCondition] = useState({value: 'asc', label:'Ascending'})  
   
    const clearSelected = (index) =>{ 
        props.deleteSort(index) 
        setValueSort({...valueSort, isReset: true}) 
    }

    useEffect(()=>{  
        let typeList = [{value:"Name", label:"Name"}] 
        tableSearch.map(tab =>{
            //===== GET HEADER EACH TABLE ======//
            tab.rows[0].map((row, key) =>{  
                if(key > 1){
                    let id =  row.id
                    let type =  row.type
                    let name =  row.name

                    if((typeList.indexOf(type) === -1) && row.type !== 'option' && row.type !== 'add' 
                    && row.type !== 'subitemscontroll'  && row.type !== 'file' 
                    && type.trim() !== 'Last Update' && type.trim() !== 'Creation Log'){  
                        let tmp = {value:id, label: name}
                        let isExist = _.some(typeList, function(i) {
                            return i.value == tmp.value
                        })
                        if(!isExist)
                        typeList.push(tmp) 
                    }
                }
                  
            })
        })

        setSelectedSort(typeList[0])
        setSortList(typeList)  
        setValueSort({column:typeList[0].value, value : condition[0].value, isReset: false})
        
    },[tableSearch])
  

    const handleSelectedColumn = (selected) =>{
        setSelectedSort(selected);  
        setValueSort({column: selected.value, value : selectedCondition.value, isReset: false})
    }
     

    const handleChangeOption = (selected) =>{ 
        setSelectedCondition(selected) 
        setValueSort({column: selectedSort.value, value : selected.value, isReset: false})
    }

    return (
        <> 
            <Row className="mt-3 mb-3"> 
                <Col>
                  <Select focus styles={StyleSelect({menuListHeight: 200, width: 200})} 
                        maxMenuHeight={150}
                        placeholder=""     
                        className='m-0 p-0' 
                        options={sortList}
                        value={selectedSort}
                        onChange={(selected)=>{handleSelectedColumn(selected)}} 
                         ></Select>
                </Col>
                <Col>
                  <Select focus styles={StyleSelect({menuListHeight: 200, width: 200})} 
                        maxMenuHeight={150}
                        placeholder=""     
                        value={selectedCondition}
                        options={selectedSort.value === "Column" ? []: condition}
                        onChange={(selected)=>{handleChangeOption(selected)}}
                        className='m-0 p-0'  
                         ></Select>
                </Col>
                 
                <Col className="d-flex align-items-center m-0 p-0 pr-3">
                    
                    <HighlightOffIcon style={{color: 'black', fontSize: 20 }} className="align-text-top cursor-pointer" onClick={()=>clearSelected(props.elementIndex)}/>
                       
                </Col> 
            </Row> 
        </>
    )
}

export default Sort