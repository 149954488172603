//=========REACT INITIAL========//
import React, {useEffect, useState, useContext, useRef} from 'react' 
import {DashboardContext} from "../../../context/DashboardContext" 

//=========BOOTSTARP INITIAL========//
import {Col, Row, Nav,OverlayTrigger, Popover, Image, Tooltip, Card} from 'react-bootstrap' 

//========CHART==========//
import {BasicChart} from '../../../helpers/highcharts/Charts'

//=========3RD LIBRARY========//
import { Responsive, WidthProvider } from 'react-grid-layout' 
import _ from 'lodash' 
import "react-datepicker/dist/react-datepicker.css" 
import '../../css/dashboard.css'
import '../../css/grid/styles.css'
// import '../css/resizable/styles.css' 
import ContentEditable from 'react-contenteditable'  
import 'react-quill/dist/quill.snow.css'
import { divToImg, divToPDF } from '../../../helpers/utils/Utils' 

//=========ICON LIST========//   
import ImportExportIcon from '@material-ui/icons/ImportExport'  
import Duplicate from "monday-ui-react-core/dist/icons/Duplicate"
import Delete from "monday-ui-react-core/dist/icons/Delete"
import Edit from "monday-ui-react-core/dist/icons/Edit"
import BoardIcon from "../../../img/board.svg" 
import SettingIcon from "../../../img/setting.svg" 
import FullscreenIcon from "../../../img/fullscreen.svg" 
import MoreIcon from "../../../img/more.svg"  
import Battery from '../../dashboard/Battery'

//=========IMG========//  

const ResponsiveGridLayout = WidthProvider(Responsive)

const Content = (props) => {  
    const {dashboard,selectedBoard,modeDashboard, setModeDashboard, boardActive, updateDashboard, 
        deleteDashboard, createDashboard, setShowWidget, dispatchChart, stateChart, color,
        fullscreenWidget, setFullscreenWidget, table, params, setShowModalText, setShowWidgetBattery} = useContext(DashboardContext)  
    const [ layouts, setLayouts ] = useState({lg: []})   
    const isFirstRun = useRef(true)
    let ref = React.useRef(null) 
    let refEditable = useRef("")
    let refContentEditable = useRef([])  
    let refParagraph = useRef("")  
    const isFirstRunWidget = useRef(true) 
    

    const onLayoutChange = async(lays) =>{  
        lays.map(async(item,index)=>{    
            if(dashboard.length > 0){
                let tmpDashboard = dashboard[index]   
                tmpDashboard.column.x = item.x
                tmpDashboard.column.y = item.y
                tmpDashboard.column.h = item.h
                tmpDashboard.column.w = item.w  
                if(modeDashboard === "edit")
                await updateDashboard(tmpDashboard,false)
            }
        })    
        
        setTimeout(async () => {
            window.dispatchEvent(new Event('resize'));
        }, 200)


    }  
 
    const titleCase = (str) => {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) { 
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        } 
        return splitStr.join(' '); 
     }

    useEffect(()=>{  
        if(dashboard.length > 0 && fullscreenWidget.refresh)
        getChart()
    }, [])
 
    useEffect(()=>{ 
        if(isFirstRunWidget.current){
            isFirstRunWidget.current = false
            return
        }  
        if(fullscreenWidget.refresh) return
        getChart()
           
    },[dashboard,color]) 

    const getChart =()=>{
        let column = {lg : []} 
        dashboard.map((item, key)=> { 
            let type = item.column.type 
            if(type === 'Text'){
                item.column.title = item.title
                column.lg.push(item.column)
                setLayouts(column) 
                return
            } 
            let chart = item.column.chart
            let widget = item.widget
            console.log("widget____", widget)
            widget.selectedBoard = selectedBoard 
            let dataTmp = [], color = []  
            if(!widget.xAxisColumnId) return
            widget.selectedBoard.map(items => {   
                if(widget.board.includes(items.id)){
                    items.table.map((item, key) => { 
                        if(widget.group.includes(item.id)){
                            let group = "", keyColumn = ""
                            item.rows.map((row, rowKey) => { 
                                if(rowKey === 0) {
                                    group = row[1]  
                                    _.map(row, (item, key) =>{ 
                                        if(widget.xAxisColumnId.includes(item.id)) keyColumn = key 
                                        if(widget.xAxisColumnId.length === 0) {
                                            let type =  item.type.replace("_"," ").toUpperCase()
                                            if(widget.xAxis.toUpperCase() === type && keyColumn === "") keyColumn = key 
                                        } 
                                    }) 

                                }
                                    //===== SKIP HEADER ======//
                                if(rowKey > 0){
                                    row.map((val, valIdx)=> {
                                        let type =  val.type.replace("_"," ")
                                        type  = titleCase(type).trim() 
                                        if(val.name === null) val.name = "" 
                                        if(widget.xAxis === "Board" && widget.groupBy === ""){ 
                                            if(valIdx === 1){   
                                                let tmp = {}
                                                tmp.name = items.title
                                                color[tmp.name] = 'rgb(87, 155, 252)'
                                                dataTmp.push(tmp)
                                            } 
                                        }else if(widget.xAxis === "Board" && widget.groupBy === "Status"){
                                            let status = _.find(row, {type: "status"});
                                            let statusName = status !== undefined ? status.name.name : undefined
                                            statusName = statusName !== undefined ? statusName.replace(" ", "_").toLowerCase() : statusName
                                            let statusColor = status !== undefined ? status.name.color : 'rgb(87, 155, 252)'
                                            color[statusName] =  statusName !== undefined ? statusColor : 'rgb(87, 155, 252)'

                                            if(valIdx === 1){   
                                                let tmp = {}
                                                tmp.name = items.title
                                                let labelStatus =  status !== undefined ? status.name.name : undefined
                                                labelStatus = labelStatus !== undefined ? labelStatus.replace(" ", "_").toLowerCase() : labelStatus
                                                tmp.status = labelStatus
                                                // color[tmp.name] = 'rgb(87, 155, 252)'
                                                dataTmp.push(tmp)
                                            }
                                        }

                                        if(widget.xAxis === "Group" && widget.groupBy === ""){ 
                                            if(valIdx === 1){   
                                                color[group.name] = 'rgb(87, 155, 252)'
                                                dataTmp.push(group)
                                            } 
                                        }else if(widget.xAxis === "Group" && widget.groupBy === "Status"){
                                            let status = _.find(row, {type: "status"});
                                            let statusName = status !== undefined ? status.name.name : undefined
                                            statusName = statusName !== undefined ? statusName.replace(" ", "_").toLowerCase() : statusName
                                            let statusColor = status !== undefined ? status.name.color : 'rgb(87, 155, 252)'
                                            color[statusName] =  statusName !== undefined ? statusColor : 'rgb(87, 155, 252)'

                                            if(valIdx === 1){   
                                                let tmp = {}
                                                tmp.id = group.id
                                                tmp.name = group.name
                                                tmp.type = group.type
                                                // color[group.name] = 'rgb(87, 155, 252)'
                                                let labelStatus =  status !== undefined ? status.name.name : undefined
                                                labelStatus = labelStatus !== undefined ? labelStatus.replace(" ", "_").toLowerCase() : labelStatus
                                                tmp.status = labelStatus
                                                dataTmp.push(tmp)
                                            }
                                        }

                                        if(widget.xAxis === "Name" && widget.groupBy === ""){ 
                                            if(valIdx === 1){ 
                                                let tmp = {}
                                                tmp.name = val.name.trim()
                                                color[tmp.name] = 'rgb(87, 155, 252)'
                                                dataTmp.push(tmp)
                                            } 
                                        }else if(widget.xAxis === "Name" && widget.groupBy === "Status"){
                                            let status = _.find(row, {type: "status"})
                                            let statusName = status !== undefined ? status.name.name : undefined
                                            statusName = statusName !== undefined ? statusName.replace(" ", "_").toLowerCase() : statusName
                                            let statusColor = status !== undefined ? status.name.color : 'rgb(87, 155, 252)'
                                            color[statusName] =  statusName !== undefined ? statusColor : 'rgb(87, 155, 252)'
                                            
                                            if(valIdx === 1){ 
                                                let tmp = {}
                                                tmp.name = val.name.trim()
                                                // color[tmp.name] = 'rgb(87, 155, 252)'
                                                let labelStatus =  status !== undefined ? status.name.name : undefined
                                                labelStatus = labelStatus !== undefined ? labelStatus.replace(" ", "_").toLowerCase() : labelStatus
                                                tmp.status = labelStatus
                                                dataTmp.push(tmp)
                                            } 
                                        }
 
                                        if(!widget.showUndefined && (val.name === undefined || val.name === "" || val.name.name === "" || val.name.name === "Undefined")) return
                                        if((type === widget.xAxis)){ 
                                            if(type === "Person" && valIdx === keyColumn && widget.groupBy === ""){
                                                // console.log("masuk atas om")
                                                if(val.name.length === 0) val.name = ""
                                                if(val.name ===  "") {
                                                    dataTmp.push("")
                                                    return
                                                } 
                                                val.name.map((n,idx) => { 
                                                    let tmp = {}
                                                    tmp.name = n.label
                                                    color[tmp.name] = 'rgb(87, 155, 252)'
                                                    dataTmp.push(tmp)
                                                })
                                            }else if(type === "Person" && valIdx === keyColumn && widget.groupBy === "Status"){
                                                // console.log("masuk bawha om")
                                                // console.log("row___", row)

                                                let status = _.find(row, {type: "status"});
                                                let statusName = status !== undefined ? status.name.name : undefined
                                                statusName = statusName !== undefined ? statusName.replace(" ", "_").toLowerCase() : statusName
                                                let statusColor = status !== undefined ? status.name.color : 'rgb(87, 155, 252)'
                                                color[statusName] =  statusName !== undefined ? statusColor : 'rgb(87, 155, 252)'
                                                // console.log("status___", status)
                                                
                                                // if(val.name.length === 0) val.name = ""
                                                // if(val.name ===  "") {
                                                //     dataTmp.push("")
                                                //     return
                                                // } 
                                                if(val.name !== ""){
                                                    val.name.map((n,idx) => { 
                                                        let tmp = {}
                                                        tmp.name = n.label
                                                        let labelStatus =  status !== undefined ? status.name.name : undefined
                                                        labelStatus = labelStatus !== undefined ? labelStatus.replace(" ", "_").toLowerCase() : labelStatus
                                                        tmp.status = labelStatus
                                                        // color[tmp.name] = 'rgb(87, 155, 252)'
                                                        dataTmp.push(tmp)
                                                    })
                                                }
                                                // val.name.map((n,idx) => { 
                                                //     let tmp = {}
                                                //     tmp.name = n.label
                                                //     let labelStatus =  status !== undefined ? status.name.name : undefined
                                                //     labelStatus = labelStatus !== undefined ? labelStatus.replace(" ", "_").toLowerCase() : labelStatus
                                                //     tmp.status = labelStatus
                                                //     // color[tmp.name] = 'rgb(87, 155, 252)'
                                                //     dataTmp.push(tmp)
                                                // })
                                            }

                                            if(widget.groupBy === "" && type === "Priority" || type === "Status" && valIdx === keyColumn){ 
                                                let tmp = {name : "Undefined", color: "rgb(196, 196, 196)"}
                                                if(val.name.name) val.name.name = val.name.name.trim()
                                                if(val.name === "" || val.name.name === "") val.name = tmp 
                                                color[val.name.name] =  val.name.color
                                                dataTmp.push(val.name)
                                            }else if(widget.groupBy === "Status" && type === "Priority" && valIdx === keyColumn){
                                                let status = _.find(row, {type: "status"});
                                                let statusName = status !== undefined ? status.name.name : undefined
                                                statusName = statusName !== undefined ? statusName.replace(" ", "_").toLowerCase() : statusName
                                                let statusColor = status !== undefined ? status.name.color : 'rgb(87, 155, 252)'
                                                color[statusName] =  statusName !== undefined ? statusColor : 'rgb(87, 155, 252)'

                                                let tmp = {}
                                                tmp.id = val.name.id
                                                tmp.board_id = val.name.board_id
                                                if(val.name.name) val.name.name = val.name.name.trim()
                                                if(val.name === "" || val.name.name === ""){
                                                    tmp.name = "Undefined"
                                                    // tmp.color = "rgb(196, 196, 196)"
                                                }else{
                                                    tmp.name = val.name.name
                                                    // color[val.name.name] =  val.name.color
                                                }
                                                let labelStatus =  status !== undefined ? status.name.name : undefined
                                                labelStatus = labelStatus !== undefined ? labelStatus.replace(" ", "_").toLowerCase() : labelStatus
                                                tmp.status = labelStatus
                                                dataTmp.push(tmp)
                                            }

                                            if(type === "Progress" && valIdx === keyColumn && widget.groupBy === ""){ 
                                                let tmp = {}
                                                tmp.name = val.name + '%'
                                                color[tmp.name] = 'rgb(87, 155, 252)'
                                                dataTmp.push(tmp) 
                                            }else if(type === "Progress" && valIdx === keyColumn && widget.groupBy === "Status"){
                                                let status = _.find(row, {type: "status"});
                                                let statusName = status !== undefined ? status.name.name : undefined
                                                statusName = statusName !== undefined ? statusName.replace(" ", "_").toLowerCase() : statusName
                                                let statusColor = status !== undefined ? status.name.color : 'rgb(87, 155, 252)'
                                                color[statusName] =  statusName !== undefined ? statusColor : 'rgb(87, 155, 252)'
                                            
                                                let tmp = {}
                                                tmp.name = val.name + '%'
                                                // color[tmp.name] = 'rgb(87, 155, 252)'
                                                let labelStatus =  status !== undefined ? status.name.name : undefined
                                                labelStatus = labelStatus !== undefined ? labelStatus.replace(" ", "_").toLowerCase() : labelStatus
                                                tmp.status = labelStatus
                                                dataTmp.push(tmp)
                                            }

                                            if(type === "Text" && valIdx === keyColumn){  
                                                let tmp = {}
                                                tmp.name = val.name 
                                                color[tmp.name] = 'rgb(87, 155, 252)'
                                                dataTmp.push(tmp)  
                                            }
    
                                            if(type === "Paragraph" && valIdx === keyColumn){  
                                                let tmp = {}
                                                tmp.name = val.name 
                                                color[tmp.name] = 'rgb(87, 155, 252)'
                                                dataTmp.push(tmp) 
                                                 
                                            }
    

                                            if(widget.groupBy === "" && (type === "Rating" || type === "Duedate" || type === "Timeline Duedate"
                                            || type === "Timeline" || type === "Date" || type === "Number" || type === "Week" && valIdx === keyColumn)){ 
                                                // console.log("masuk atas om")
                                                let tmp = {}
                                                tmp.name = val.name
                                                color[tmp.name] = 'rgb(87, 155, 252)'
                                                dataTmp.push(tmp) 
                                            }else if(widget.groupBy === "Status" && (type === "Rating" || type === "Duedate" || type === "Timeline Duedate"
                                            || type === "Timeline" || type === "Date" || type === "Number" || type === "Week" && valIdx === keyColumn)){
                                                // console.log("masuk bawah om")
                                                let status = _.find(row, {type: "status"});
                                                let statusName = status !== undefined ? status.name.name : undefined
                                                statusName = statusName !== undefined ? statusName.replace(" ", "_").toLowerCase() : statusName
                                                let statusColor = status !== undefined ? status.name.color : 'rgb(87, 155, 252)'
                                                color[statusName] =  statusName !== undefined ? statusColor : 'rgb(87, 155, 252)'
                                            
                                                if(val.name !== ""){
                                                    let tmp = {}
                                                    tmp.name = val.name
                                                    // color[tmp.name] = 'rgb(87, 155, 252)'
                                                    let labelStatus =  status !== undefined ? status.name.name : undefined
                                                    labelStatus = labelStatus !== undefined ? labelStatus.replace(" ", "_").toLowerCase() : labelStatus
                                                    tmp.status = labelStatus
                                                    dataTmp.push(tmp)
                                                }
                                                
                                                // let tmp = {}
                                                // tmp.name = val.name
                                                // // color[tmp.name] = 'rgb(87, 155, 252)'
                                                // let labelStatus =  status !== undefined ? status.name.name : undefined
                                                // labelStatus = labelStatus !== undefined ? labelStatus.replace(" ", "_").toLowerCase() : labelStatus
                                                // tmp.status = labelStatus
                                                // dataTmp.push(tmp)
                                            }

                                        }
                                    })  
                                }  
                                
                            }) 
                        }
                    }) 
                }
            })  
             
            //====== GET COUNT yAxis OF DATA ======
            // console.log("dataTmp___", dataTmp)
            var count = _.countBy(dataTmp, 'name')  
            // console.log("count___", count)
            var countStatus = _.countBy(dataTmp, 'status')

            // console.log("countStatus__", countStatus)
            
            let data = [], categories = []
            console.log("colornya___", color)
            Object.keys(count).map(item=>{
                let obj = {}
                obj.name = item
                obj.y = count[item]
                obj.color = color[item] 
                obj.status = _.forEach(Object.keys(countStatus), function(value) {
                    return [value]
                });
                Object.keys(countStatus).map(ite => {
                    obj[ite] = 0
                })
                
                dataTmp.map(i => {
                    if(widget.xAxis === "Number"){
                        if(i.name.toString() === item){
                            obj[i.status] ++
                        }
                    }else {
                        if(i.name === item){
                            obj[i.status] ++
                        }
                    }
                })
                data.push(obj)  
            })   
            
            let open = [], done = [], completed = [], in_progress = [], undefined_val = []
            data.map(it => {
                open.push(it.open ? it.open : 0)
                done.push(it.done ? it.done : 0)
                completed.push(it.completed ? it.completed : 0)
                in_progress.push(it.in_progress ? it.in_progress : 0)
                if(widget.showUndefined === true) undefined_val.push(it.undefined ? it.undefined : 0)
            })
            
            if(widget.sortBy.value === 1){
                data.sort(function(a, b) {
                    return a.y - b.y
                }) 
            }
    
            if(widget.sortBy.value === 2){
                data.sort(function(a, b) {
                    return b.y - a.y
                }) 
            }
    
            if(widget.sortBy.value === 3){
                data.sort(function(a, b) {
                    if (a.name < b.name)
                        return -1
                    if (a.name > b.name)
                        return 1
                    return 0
                }) 
            }
    
            if(widget.sortBy.value === 4){
                data.sort(function(a, b) {
                    if (b.name < a.name)
                        return -1
                    if (b.name > a.name)
                        return 1
                    return 0
                }) 
            }
            
            categories = _.map(data,'name')
            // console.log("datanya___", data)

            let dataSeries
        
            if((widget.groupBy === "Status" && widget.xAxis === "Person") || (widget.groupBy === "Status" && widget.xAxis === "Board") || (widget.groupBy === "Status" && widget.xAxis === "Group") || (widget.groupBy === "Status" && widget.xAxis === "Name") || (widget.groupBy === "Status" && widget.xAxis === "Priority") || (widget.groupBy === "Status" && widget.xAxis === "Progress") || (widget.groupBy === "Status" && widget.xAxis === "Date") || (widget.groupBy === "Status" && widget.xAxis === "Duedate") || (widget.groupBy === "Status" && widget.xAxis === "Timeline Duedate") || (widget.groupBy === "Status" && widget.xAxis === "Rating") || (widget.groupBy === "Status" && widget.xAxis === "Number")){
                if(widget.showUndefined === true){
                    dataSeries = [
                        {name: 'Open', data: open[0] === undefined ? [0] : open, color: color['open']},
                        {name: 'Done', data: done[0] === undefined ? [0] : done, color: color['done']},
                        {name: 'Completed', data: completed[0] === undefined ? [0] : completed, color: color['completed']},
                        {name: 'In Progress', data: in_progress[0] === undefined ? [0] : in_progress, color: color['in_progress']},
                        {name: 'Undefined', data: undefined_val[0] === undefined ? [0] : undefined_val, color: color['undefined']}
                    ]
                }else{
                    dataSeries = [
                        {name: 'Open', data: open[0] === undefined ? [0] : open, color: color['open']},
                        {name: 'Done', data: done[0] === undefined ? [0] : done, color: color['done']},
                        {name: 'Completed', data: completed[0] === undefined ? [0] : completed, color: color['completed']},
                        {name: 'In Progress', data: in_progress[0] === undefined ? [0] : in_progress, color: color['in_progress']}
                    ]
                }
            }else{
                dataSeries = [{ 
                    size: '50%',
                    innerSize: type === "donut" ? '50%' : '0%',
                    name: widget.yAxis,
                    data : data,
                    borderSizeWidth : 1,
                    borderColor : '#F0F1F3'
                }]
            }

            // console.log("dataSeries___", dataSeries)

            if(chart){
                // console.log("chart____", chart)
                let event = {events : {
                        click: function () { 
                            setFullscreenWidget({fullscreen: true, dashboard: dashboard[key], key, category: this.category})
                        }
                    }
                }
                chart.borderColor ='#F0F1F3'
                chart.borderWidth = 1   
                chart.backgroundColor = color.chart
                chart.xAxis.categories = categories
                chart.yAxis.labels.style.color = color.chart 
                chart.xAxis.labels = {
                    enabled: true,
                    formatter () {
                      return `<span style="color: black;font-size: 11px">${this.value}</span>`
                    }
                }
                chart.yAxis.title.text = '' 
                chart.yAxis.gridLineColor = '#F0F1F3'
                if((widget.groupBy === "Status" && widget.xAxis === "Person") || (widget.groupBy === "Status" && widget.xAxis === "Board") || (widget.groupBy === "Status" && widget.xAxis === "Group") || (widget.groupBy === "Status" && widget.xAxis === "Name") || (widget.groupBy === "Status" && widget.xAxis === "Priority") || (widget.groupBy === "Status" && widget.xAxis === "Progress") || (widget.groupBy === "Status" && widget.xAxis === "Date") || (widget.groupBy === "Status" && widget.xAxis === "Duedate") || (widget.groupBy === "Status" && widget.xAxis === "Timeline Duedate") || (widget.groupBy === "Status" && widget.xAxis === "Rating") || (widget.groupBy === "Status" && widget.xAxis === "Number")){
                    chart.series = dataSeries 
                }else{
                    chart.series[0].data = data 
                }

                chart.plotOptions.series.cursor = "pointer"
                chart.plotOptions.series.point = event 
                item.column.chart = chart
                item.column.title = item.title 
                column.lg.push(item.column)
            } 
            if(item.column.type === "Battery"){
                item.column.data = data
                item.column.title = item.title
                column.lg.push(item.column)
            }

        })   
        dispatchChart({type : 'SET_DEFAULT_CHART'})
        // console.log("column___", column)
        setLayouts(column) 
        setFullscreenWidget({refresh: false})
    }

    const deleteWidget = async(key) =>{ 
        ref.click()
        await deleteDashboard({id:dashboard[key].id})
    }

    const duplicateWidget = async(key) =>{ 
        ref.click()
        let dashboardTmp = dashboard[key] 
        let info = {title:dashboardTmp.title, created_by: dashboardTmp.created_by, 
            team_id : dashboardTmp.team_id, board_id: dashboardTmp.board_id, 
            workspace_id: dashboardTmp.workspace_id, permission_id: dashboardTmp.permission_id}
        
        let mod = dashboard.length % 2  
        let column = {   
              "x": mod === 0 ? 0 : 4,
              "y": mod === 0 ? 0 : 4,
              "w": 4,
              "h": 2,
              minW: 1,
              minH: 1,
              "i": new Date()+"1",
              "static": false, 
              "type" : dashboardTmp.column.type,
              chart : dashboardTmp.column.chart
            }   
        let data = Object.assign(info,{column:column}, {widget: dashboardTmp.widget}, {chart: [] })  
        await createDashboard(data, true) 
    }

    const clickSetting =(key) => {
        ref.click()
        let dashboardTmp = dashboard[key]  
        // setModeDashboard("update") 
        dispatchChart({type : "SET_UPDATE_CHART", data : dashboardTmp})
        if(dashboardTmp.column.type === "Battery")  setShowWidgetBattery(true)
        else setShowWidget(true)
    }

    const handleClickBoard = (id, workspace) =>{ 
        const win = window.open(`/app/dashboard?id=${id}&workspace=${workspace}`, "_blank");
        win.focus() 
    }

    const popoverMore =(key)=> (
        <Popover id="popover-basic"> 
            <Popover.Content>  
                <Nav.Link className={ params.permission_id < 4 ? "nav-menu p-2 pr-5" : "nav-menu p-2 pr-5 disabled"} onClick={()=>handleRename(key)}> 
                    <Edit style={{color: 'black' , width: 18}} className="align-text-top"/>
                    <span className="workspace-item-name-child align-text-top ml-2 font-13">Rename</span>
                </Nav.Link> 
                 <Nav.Link className={ params.permission_id < 4 ? "nav-menu p-2 pr-5" : "nav-menu p-2 pr-5 disabled"} onClick={()=>duplicateWidget(key)}> 
                    <Duplicate style={{color: 'black', width: 18 }} className="align-text-top"/>
                    <span className="workspace-item-name-child align-text-top ml-2 font-13">Duplicate</span>
                </Nav.Link> 
                <OverlayTrigger trigger="click" placement="right" overlay={popoverExport(key)} rootClose>
                    <Nav.Link className={"nav-menu p-2 pr-5" }> 
                        <ImportExportIcon style={{color: 'black' , width: 18}} className="align-text-top"/>
                        <span className="workspace-item-name-child align-text-top ml-2 font-13">Export</span>
                    </Nav.Link> 
                </OverlayTrigger>

                {dashboard[key] && dashboard[key].column.type !== 'Text' ?
                <Nav.Link className={ params.permission_id < 4 ? "nav-menu p-2 pr-5" : "nav-menu p-2 pr-5 disabled"} onClick={()=>clickSetting(key)}> 
                    <Image src={SettingIcon}></Image>
                    <span className="workspace-item-name-child align-text-top ml-2 font-13">Settings</span>
                </Nav.Link> 
                : null} 
            <div className="spacer pt-2"></div>  
             
                <Nav.Link className={ params.permission_id < 4 ? "nav-menu p-2 pr-5 mt-2" : "nav-menu p-2 pr-5  mt-2 disabled"} onClick={()=>deleteWidget(key)}> 
                    <Delete style={{color: 'black', width: 18 }} className="align-text-top"/>
                    <span className="workspace-item-name-child align-text-top ml-2 font-13">Delete</span>
                </Nav.Link> 
            </Popover.Content>
        </Popover>
    )

    const popoverBoard =(key)=> (
        <Popover id="popover-basic"> 
            <Popover.Content>  
                <span className="workspace-item-name-child align-text-top ml-2 pt-2 font-14">Connected Boards</span> 
                <div className="spacer pt-2"></div>   
                {dashboard[key] && dashboard[key].widget.selectedBoard && dashboard[key].widget.selectedBoard.map((item, index)=>{ 
                        return (
                            dashboard[key].widget.board.includes(item.id) ?
                                <Nav.Link key ={key} onClick={()=>handleClickBoard(item.id, item.workspace_id)} className={"nav-menu p-2 pr-5 mt-2"}> 
                                    <Image src={BoardIcon}></Image>
                                    <span className="workspace-item-name-child align-text-top ml-2 font-13">{item.title}</span>
                                </Nav.Link> 
                            : null
                        ) 
                })}
                
            </Popover.Content>
        </Popover>
    )


    const popoverExport = (key)=>(
        <Popover id="popover-basic"> 
            <Popover.Content>  
                <Nav.Link className={ "nav-menu p-2 pr-5" } onClick={()=>handleExport('png',key,boardActive.title)}>  
                    <span className="workspace-item-name-child align-text-top ml-2 font-13">PNG</span>
                </Nav.Link> 
                <Nav.Link className={ "nav-menu p-2 pr-5" } onClick={()=>handleExport('jpeg',key,boardActive.title)}>  
                    <span className="workspace-item-name-child align-text-top ml-2 font-13">JPEG</span>
                </Nav.Link> 
                <Nav.Link className={ "nav-menu p-2 pr-5" } onClick={()=>handleExport('pdf',key,boardActive.title)}>  
                    <span className="workspace-item-name-child align-text-top ml-2 font-13">PDF</span>
                </Nav.Link> 
                <Nav.Link className={  "nav-menu p-2 pr-5" } onClick={()=>handleExport('print',key,boardActive.title)}>  
                    <span className="workspace-item-name-child align-text-top ml-2 font-13">PRINT</span>
                </Nav.Link> 
            </Popover.Content>
            
        </Popover>
    )

    
    const handleChangeTitle = (value, key) => {
        // refEditable.current = value.replace(/\&nbsp;/g, ' ')   
        // refContentEditable.current[key].value =  value.replace(/\&nbsp;/g, ' ')   
    }

    const handleSaveTitle = async (index, recentTitle) => { 
        var newArrayRefContentEditable = refContentEditable.current.filter(function (el){
            return el.props.html == recentTitle;
            }
        );

        var newArrayTmpDashboard = dashboard.filter(function (el){
            return el.title == recentTitle;
            }
        );

        
        // if(refContentEditable.current[index].lastHtml !==""){
        if(newArrayRefContentEditable[0].lastHtml !==""){
            // console.log("lastHTML", refContentEditable.current[index].lastHtml)
            // let tmpDashboard = dashboard[index]
            let tmpDashboard = newArrayTmpDashboard[0]
            tmpDashboard.widget.title = newArrayRefContentEditable[0].lastHtml
            tmpDashboard.title = newArrayRefContentEditable[0].lastHtml

            // console.log("tmpDashboard___", tmpDashboard)
            
            await updateDashboard(tmpDashboard,true)  
            refContentEditable.current[index].lastHtml = ""
        }
    }

    const handleRename = async (index) => {  
        ref.click()
        refContentEditable.current[index].el.current.focus() 
    }

    const setFullScreen = (key) =>{  
        setFullscreenWidget({fullscreen: true, dashboard: dashboard[key], key})
    }
 
 
    const handleEditItemParagraph =  async (index) =>{  
        let tmpDashboard = dashboard[index]   
        setShowModalText({isShow: true, data: tmpDashboard, isEdit : true}) 
    }

    const handleExport = (type, key, title) =>{ 
        
        if(type === "print"){ 
            var divToPrint=document.getElementsByClassName('section-to-print-widget')[key]
            var newWin=window.open('','Print-Window'); 
            newWin.document.open(); 
            newWin.document.write('<html><title>PM Tools</title><body onload="window.print()">'+divToPrint.innerHTML+'</body></html>');
            newWin.document.close(); 
            setTimeout(function(){newWin.close();},5);
        }
        var divToPrint=document.getElementsByClassName('section-to-print-widget-body')[key]
        if(type === "png" || type === "jpeg")  
            divToImg(divToPrint, title+'.' +type)
    
        if(type === "pdf") 
            divToPDF(divToPrint, title+'.' +type) 
    }

    return (
        <React.Fragment>    
            {layouts.lg.length?
                <Row id="section-to-print" className="mb-5 pb-5 h-100 w-100 p-0"  ref={r => (ref = r)}> 
                    <Col md="12 ml-0 pl-0 pb-0 mb-0">
                    <div className="only-print-dashboard ml-3 p-0">
                        {boardActive.title}
                    </div>
                        <ResponsiveGridLayout  
                            id = "content-dashboard-mobile"
                            layouts = {layouts} 
                            breakpoints = {{lg: 1100, md: 996, sm: 768, xs: 480, xxs: 0}}
                            cols= {{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}} 
                            isDraggable = {modeDashboard === "view"? false : true}
                            isResizable ={modeDashboard === "view"? false : true}
                            onLayoutChange = {onLayoutChange}>   
                            {layouts.lg.map((layout, key) =>{ 
                            return (
                                <Card key = {layout.i} className ="section-to-print-widget p-2" style={{background :color.chart}}>
                                    <div className="m-2 pt-0 mt-0 w-100 d-inline title-dashborad " >
                                        <div className="d-inline w-50">
                                            <ContentEditable 
                                                html={layout.title}  
                                                ref ={(r)=> refContentEditable.current[key] = r}
                                                onBlur = {()=>handleSaveTitle(key, layout.title)}
                                                style={{color : color.color}}
                                                // onChange={(e)=> {handleChangeTitle(e.target.value.replace(/<\/?[^>]+(>|$)/g, "")+ " ", key)}} 
                                                disabled={modeDashboard === "view"? true : false}
                                                className={modeDashboard === "view"? "pl-2 div-dashed font-16  font-w-400 p-1 d-inline align-text-top" : "pl-2 div-dashed d-inline align-text-top font-16 p-1 font-w-400 p-1 w-50"}  
                                            />  
                                        </div>
                                       
                                        <div className="d-inline options">
                                            <OverlayTrigger trigger={['hover','focus']} placement="bottom" overlay={ 
                                                <Tooltip id='tooltip'>
                                                    Fullscreen
                                                </Tooltip>
                                            } rootClose>
                                                <span className="align-text-top font-20 ml-2 float-right no-print">
                                                <Image src={FullscreenIcon} onClick={()=>setFullScreen(key)} className="no-print float-right mr-3 cursor-pointer"></Image> 
                                                {/* <Fullscreen onClick={()=>setFullScreen(key)} id="icon-dashboard-widget" style={{ color: color.color}} className="icon-dashboard-widget align-text-top font-20 float-right mr-3 cursor-pointer no-print"/>  */}
                                                    
                                                </span> 
                                            </OverlayTrigger> 
                                        
                                        
                                            {modeDashboard === "edit" ?
                                            <React.Fragment>  
                                                <OverlayTrigger trigger={['hover','focus']} placement="bottom" overlay={ 
                                                    <Tooltip id='tooltip'>
                                                        More Options
                                                    </Tooltip>
                                                } rootClose>
                                                    <OverlayTrigger trigger="click" placement="bottom" overlay={popoverMore(key)} rootClose> 
                                                        <span className="align-text-top font-20 float-right no-print cursor-pointer" style={{height:'20px'}}>
                                                        
                                                        <Image width="16" src={MoreIcon} className="no-print float-right mr-2 ml-2 mt-1 cursor-pointer"></Image>  
                                                        
                                                        </span>
                                                    </OverlayTrigger>
                                                
                                                </OverlayTrigger> 
                                                
                                                
                                            </React.Fragment>
                                            :""}

                                              {layout.type === "Text" && modeDashboard === "edit" ?
                                                <OverlayTrigger trigger={['hover','focus']} placement="bottom" overlay={ 
                                                    <Tooltip id='tooltip'>
                                                        Edit Text
                                                    </Tooltip>
                                                } rootClose>
                                                    <span className="align-text-top font-20 float-right no-print" onClick={()=>handleEditItemParagraph(key)}>
                                                     
                                                        <Image width="16" src={SettingIcon} className="no-print float-right mr-2 cursor-pointer"></Image> 
                                                    
                                                    </span> 
                                                </OverlayTrigger> 
                                                :null}

                                                {/* {layout.type === "Text"  ?
                                                 null 
                                                :
                                                <OverlayTrigger trigger={['hover','focus']} placement="bottom" overlay={ 
                                                    <Tooltip id='tooltip'>
                                                        Connected Boards
                                                    </Tooltip>
                                                } rootClose>
                                                    <span className="align-text-top font-20 float-right no-print">
                                                    <OverlayTrigger trigger="click" placement="bottom" overlay={popoverBoard(key)} rootClose>
                                                        <Image width="16" src={BoardIcon} className="no-print float-right mr-2 cursor-pointer"></Image> 
                                                    </OverlayTrigger>
                                                    </span> 
                                                </OverlayTrigger> 
                                                }      */}
                                        </div>                                
                                    </div>
                                
                                    <Card.Body id = {layout.i} className="section-to-print-widget-body p-0 pt-3">
                                        <div key = {layout.i} className = "h-100 w-100 m-0 p-0 mw-100" id = {layout.i}>
                                        
                                        {layout.type === "Text" ? 
                                            <div className="pl-3" dangerouslySetInnerHTML= {{__html : layout.value}}></div> 
                                        : 
                                        layout.type === "Battery" ? 
                                           <Battery data={layout.data} height={100} className="d-inline-block p-2"/>
                                        : 
                                        <BasicChart  charts = {layout.chart} />
                                        }
                                        </div>
                                    </Card.Body> 
                                </Card>                  
                            )
                            })}   

                        </ResponsiveGridLayout>
                    </Col> 
                </Row>
            :
                <Row className="h-100 justify-content-center">
                   
                </Row> 
            }
           
        </React.Fragment>
    )
}


export default Content
