import React, {useState, useEffect} from 'react'
import {Col, Row, Form, Image, Button, Alert, Spinner } from 'react-bootstrap' 
import ImageRegistration from "../../img/registration.svg" 
import "../css/login.css"
import { useForm } from "react-hook-form"
import { MakeRequest } from "../../api/Api"  
import {useHistory} from 'react-router-dom' 
import {asyncRequest } from '../../helpers/utils/Utils' 
import ImageLogin from "../../img/login.png" 

const Login = () =>{ 
    const history = useHistory()
    const [isLoading, setLoading] = useState(false) 
    const [alert, setAlert] = useState({msg: 'Recovery link sent. Go to your inbox.', variant: 'success', hidden: true}) 
    const { register, handleSubmit, watch, errors } = useForm()
    
    const onSubmit = async (params) => {
        setAlert({msg: 'Recovery link sent. Go to your inbox.', variant: 'success', hidden: true}) 
        setLoading(true) 
        const result = await MakeRequest('post','user-forgot-password', params)   
        if(result.status !== 200) {
            asyncRequest(1000).then(()=>{
            let detailError = result.data.detail
                setAlert({msg: detailError === undefined ? "Server error": detailError, variant: 'danger', hidden: false})
            })
        }else{
            asyncRequest(1000).then(()=>{
                setAlert({msg: 'Recovery link sent. Go to your inbox.', variant: 'success', hidden: false})
                localStorage.removeItem('saatkerja')  
            }) 
        }
        asyncRequest(1000).then(()=>{
            setLoading(false)
        }) 
      
    }
 
    useEffect(()=>{
        let data = JSON.parse(localStorage.getItem('saatkerja'))  
        if(data !== null && data.expired  > new Date().getTime()) history.push('/app/dashboard')  
    },[])

    return(
        <Row xs="12" className="no-gutters vh-100 vw-100">
            <Col className="m-auto" md={6}>
                <Col md={10}>
                    <div className="m-5">
                        <div className="txt-login">
                            <span>Forgot your password?</span>
                        </div>
                        <div className="mt-4 txt-login-child">
                            <span className="color-grey-darker">We'll email you instructions on how to reset your password or</span>  <span className="cursor-pointer text-primary" onClick={()=>{history.push('/login')}}> Sign in here</span>
                        </div>
                        <div className="mt-5">
                            <Form onSubmit={handleSubmit(onSubmit)}> 
                                <Form.Group controlId="formBasicEmail"> 
                                    <Form.Control className="font-16 input-text" name="email" type="email" placeholder="Email" ref={register({ required: true })} />
                                    <div className="mt-3">
                                        {errors.email && <span>This field is required</span>}
                                    </div> 
                                </Form.Group> 
                                <Form.Group className="pt-2" hidden={alert.hidden}>  
                                    <Alert variant={alert.variant} >
                                        <span className="font-15">{alert.msg}</span>
                                    </Alert>
                                </Form.Group>
                                
                                <div className="w-100 text-center pt-2">
                                    <Button
                                        variant="primary"
                                        disabled={isLoading}
                                        className="font-16 text-white text-center font-weight-bold btn-forgot"
                                        type="submit" 
                                        >
                                        {isLoading ?
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                />
                                            : "Reset Password" 
                                        }
                                        </Button> 
                                </div> 
                            </Form> 
                        </div>
                    </div>
                </Col> 
            </Col>
            <Col className="bg-login" md={5}>
                <Image className="img-login" src={ImageLogin} width="710" height="518"></Image>
            </Col>
       </Row>
    )
}

export default Login