export const Style = {
    circleText: {
        display: 'inline-block',
        lineHeight: '0px',
        borderRadius: '50%',
        backgroundColor: '#DE1D64',
        fontSize: '10px',
        width: '32px',
        height: '32px',
        textAlign: 'center'
    },
    circleTextSpan: {
        display: 'inline-block',
        paddingTop: '50%',
        paddingBottom: '50%',
        marginLeft: '8px',
        marginRight: '8px',
        color: 'white',
        fontWeight: 600,
        fontSize: '10px',

    },
    circleTextTeams: {
        display: 'inline-block',
        lineHeight: '0px',
        borderRadius: '50%',
        backgroundColor: '#6F6FFB',
        fontSize: '10px',
        width: '32px',
        height: '32px',
        textAlign: 'center'
    },
    circleTextTeamsSpan: {
        display: 'inline-block',
        paddingTop: '50%',
        paddingBottom: '50%',
        marginLeft: '8px',
        marginRight: '8px',
        color: 'white',
        fontWeight: 600,
        fontSize: '10px'
    },
    circleTextWorkspace: {
        display: 'inline-block',
        lineHeight: '0px',
        borderRadius: '50%',
        backgroundColor: '#DE1D64',
        fontSize: '10px',
        width: '60px',
        height: '60px',
        textAlign: 'center'
    },
    circleTextWorkspaceSpan: {
        display: 'inline-block',
        paddingTop: '50%',
        paddingBottom: '50%',
        marginLeft: '8px',
        marginRight: '8px',
        color: 'white',
        fontWeight: 600,
        fontSize: '22px'
    },

    circleTextWorkspaceSmall: {
        display: 'inline-block',
        lineHeight: '0px',
        borderRadius: '50%',
        backgroundColor: '#0C6323',
        fontSize: '10px',
        width: '25px',
        height: '25px',
        textAlign: 'center'
    },
    circleTextWorkspaceSpanSmall: {
        display: 'inline-block',
        paddingTop: '50%',
        paddingBottom: '50%',
        marginLeft: '8px',
        marginRight: '8px',
        color: 'white',
        fontWeight: 600,
        fontSize: '12px'
    },
}