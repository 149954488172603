import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Modal,
  Nav,
  OverlayTrigger,
  Popover,
  Col,
  Tabs,
  Tab,
  Button,
  Tooltip,
  Row,
  Image,
  Spinner,
} from "react-bootstrap";

//=========3RD LIBRARY========//
import _ from "lodash";
import moment from "moment";
import ReactQuill from "react-quill";
import "quill-mention";
import "quill-mention/dist/quill.mention.css";
import { stripHtml } from "../../helpers/utils/Utils";

//=========ICON LIST========//
import DeleteIcon from "@material-ui/icons/Delete";
import AvatarCommentIcon from "../../img/avatar-comment.svg";
import AvatarIcon from "../../img/avatar-logs.svg";
import SeenIcon from "../../img/seen.svg";
import DotIcon from "../../img/dot.svg";
import FolderActiveIcon from "../../img/folder.svg";

//========CONTEXT==========//
import { DashboardContext } from "../../context/DashboardContext";

//========CSS==========//
import "../css/modal-custome.css";

const ModalComment = (props) => {
  let ref = useRef();
  let refQuill = useRef();
  const {
    params,
    itemActive,
    table,
    updateTable,
    updateComment,
    boardActive,
    getComment,
    setItemActive,
    setModalSpinner,
    boardUser,
    sendNotif,
    getNotif,
  } = useContext(DashboardContext);
  const [isLoading, setLoading] = useState(false);
  const [item, setitem] = useState("");
  const [title, setTitle] = useState("");
  const [index, setIndex] = useState(0);
  const [value, setValue] = useState("");
  const [valueUpdate, setValueUpdate] = useState(new Date());
  const [valueReply, setValueReply] = useState([]);
  const [loadingReply, setLoadingReply] = useState(false);
  const [comments, setComments] = useState([]);
  const [notif, setNotif] = useState([]);
  const [member, setMember] = useState([]);
  const valueComment = useRef([]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
    ],
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@"],
      source: function (searchTerm, renderItem, mentionChar) {
        let values;
        if (mentionChar === "@") {
          values = member;
        }
        if (searchTerm.length === 0) {
          renderItem(values, searchTerm);
        } else {
          const matches = [];
          for (let i = 0; i < values.length; i++)
            if (values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
              matches.push(values[i]);
          renderItem(matches, searchTerm);
        }
      },
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "mention",
  ];

  useEffect(() => {
    if (boardUser.length) {
      let arrTmp = [];
      boardUser.map((item) => {
        if (item !== null) {
          let tmp = { id: item.id, value: item.username };
          arrTmp.push(tmp);
        }
      });
      setMember(arrTmp);
    }
  }, [props]);

  const handleUpdate = async () => {
    setLoading(true);
    let value = refQuill.current.state.value;
    let comment = {
      value: value,
      created_by: params.id,
      created_name: params.username,
      created_at: new Date(),
      seen: [{ seen_by: params.username, seen_id: params.id }],
    };
    let rows = {
      id: itemActive.id,
      itemId: itemActive.itemId,
      comments: comment,
      type: "create",
    };
    await updateComment(rows);
    getComments();
    setLoading(false);
    //GET DATA ID
    let ids = value.match(/data-id="\w+"/g);
    if (ids !== null) {
      ids = ids.map(function (attributeAndValue) {
        return attributeAndValue.split('"')[1];
      });
      ids = _.uniq(ids);
      ids.map((userId) => {
        let message = `${
          params.username
        } has been mentioned you in Comments  at "${itemActive.name.trim()}" at ${
          boardActive.team.name
        } Team.`;
        let data = {
          receiver_id: parseInt(userId),
          sender_id: params.id,
          board_id: boardActive.id,
          workspace_id: boardActive.workspace_id,
          team_id: boardActive.team_id,
          message: message,
          task_name: item.trim(),
          is_read: false,
          group_id: parseInt(itemActive.id),
          item_id: itemActive.itemId,
          group_name: "",
          created_at: new Date(),
          updated_at: new Date(),
          row_id: itemActive.itemId,
          type: 6,
        };
        sendNotif(data);
      });
    }
  };

  useEffect(() => {
    if (itemActive.length === 0) return;
    setTitle("");
    setComments([]);
    getComments();
    getDataLogs();
  }, [itemActive]);

  const getDataLogs = async () => {
    setModalSpinner(true);
    let result = await getNotif("", "", itemActive.itemId);
    let debouncer = setTimeout(() => {
      setModalSpinner(false);
      if (result.status === 200) {
        if (result.data.length) {
          if (result.data[0].task_name !== null)
            setTitle(result.data[0].task_name);
        }
        setNotif(result.data);
      } else setNotif([]);
    }, 500);
    return () => {
      clearTimeout(debouncer);
    };
  };

  useEffect(() => {
    if (boardActive.type === 1) {
      setValue("");
      setComments([]);
      setItemActive([]);
    }
  }, [boardActive]);

  const getComments = async () => {
    setModalSpinner(true);
    let comment = await getComment(itemActive);
    setModalSpinner(false);
    if (comment.status === 200) {
      let comments = comment.data.comments;
      if (!comments || !comments.length) return;
      setTitle(comment.data.name);
      comments.map((item) => {
        item.add_reply = true;
        let isAlreadySeen = false;
        item.seen.map((seen) => {
          if (seen.seen_id === params.id) isAlreadySeen = true;
        });
        if (!isAlreadySeen) {
          let seen = {};
          seen = { seen_by: params.username, seen_id: params.id };
          item.seen.push(seen);
        }
      });
      setComments(comments);
      setLoading(false);
      let rows = { itemId: itemActive.itemId, comments: comments };
    }
  };

  const deleteComment = async (key) => {
    ref.click();
    let rows = {
      id: itemActive.id,
      itemId: itemActive.itemId,
      type: "delete",
      key: key,
    };
    setModalSpinner(true);
    await updateComment(rows);
    getComments();
  };

  const handleUpdateReply = async (event, key, item) => {
    var keyCode = event.which || event.keyCode;
    if (keyCode !== 13) return;
    let value = valueReply[key];
    let replys = {
      value: value,
      created_by: params.id,
      created_name: params.username,
      created_at: new Date(),
      seen: [{ seen_by: params.username, seen_id: params.id }],
    };
    setLoadingReply(true);
    let rows = {
      id: itemActive.id,
      itemId: itemActive.itemId,
      comments: replys,
      type: "replys",
      key: key,
    };
    setModalSpinner(true);
    await updateComment(rows);
    getComments();
    setLoadingReply(false);
    valueComment.current[key].value = "";

    let ids = [item.created_by];
    if (item.replys) {
      item.replys.map((rep) => {
        let exists = _.some(ids, (id) => {
          return id === rep.created_by;
        });
        if (!exists) ids.push(rep.created_by);
      });
    }
    ids.map((userId) => {
      let message = `${
        params.username
      } has been replay Comments  at "${itemActive.name.trim()}" at ${
        boardActive.team.name
      } Team.`;
      let data = {
        receiver_id: parseInt(userId),
        sender_id: params.id,
        board_id: boardActive.id,
        workspace_id: boardActive.workspace_id,
        team_id: boardActive.team_id,
        message: message,
        task_name: itemActive.name.trim(),
        is_read: false,
        group_id: parseInt(itemActive.id),
        item_id: itemActive.itemId,
        group_name: "",
        created_at: new Date(),
        updated_at: new Date(),
        row_id: itemActive.itemId,
        type: 6,
      };
      sendNotif(data);
    });
  };

  const handleValueReply = (key, value) => {
    let reply = valueReply;
    reply[key] = value;
  };

  const popover = (key) => (
    <Popover id="popover-basic">
      <Popover.Content>
        <Nav.Link
          className="nav-menu p-2 pr-5"
          onClick={() => deleteComment(key)}
        >
          <DeleteIcon
            style={{ color: "black", fontSize: 16 }}
            className="align-text-top"
          />
          <span className="workspace-item-name-child align-text-top ml-2 font-12">
            Delete Update
          </span>
        </Nav.Link>
      </Popover.Content>
    </Popover>
  );

  const setRefComment = (ref, key) => {
    valueComment.current[key] = ref;
  };

  return (
    <Modal
      {...props}
      size="lg"
      id="modal-comment"
      className="modal right fade"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header ref={(r) => (ref = r)} closeButton>
        <span className="modal-title-notif">
          {itemActive.name ? itemActive.name : stripHtml(title)}
        </span>
      </Modal.Header>
      <Modal.Body className="mt-1 ml-2 mr-2 overflow-scroll">
        <Tabs
          defaultActiveKey="update"
          transition={false}
          id="tab-comment"
          name={valueUpdate}
        >
          <Tab eventKey="update" title="Update">
            <ReactQuill
              className="mt-4"
              theme="snow"
              theme="snow"
              ref={refQuill}
              modules={modules}
              value={value}
              placeholder="Write an update..."
              formats={formats}
            />
            <div className="mb-5 pb-4 mt-2">
              <Button
                disabled={isLoading}
                className="btn-yes text-white float-right"
                onClick={handleUpdate}
                type="submit"
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Update"
                )}
              </Button>
            </div>
            <div>
              {comments.map((item, key) => {
                if (item.value.includes("img"))
                  item.value = item.value.replace(
                    "img",
                    "img style='width:100%'"
                  );
                handleValueReply(key, "");
                return (
                  <div className="post mt-4" key={key}>
                    <div className="mt-3">
                      <div className="post-header">
                        <Col className="w-100 mt-3">
                          <div className="post-title w-100">
                            <Image src={AvatarCommentIcon} />
                            <div className="title">
                              <span className="font-14 font-w-600">
                                {item.created_name}
                              </span>
                              <span className="coor-grey font-w-400 font-12 mt-1">
                                {moment(item.created_at).fromNow()}
                              </span>
                            </div>
                            <div className="mt-2 w-100 float-right">
                              <OverlayTrigger
                                trigger="click"
                                placement="bottom"
                                overlay={popover(key)}
                                rootClose
                              >
                                <Image
                                  src={DotIcon}
                                  className="float-right cursor-pointer mt-2"
                                ></Image>
                              </OverlayTrigger>
                              <span className="float-right font-12 cursor-pointer mr-2">
                                <Image src={SeenIcon} className="mr-2"></Image>
                                <OverlayTrigger
                                  trigger={["hover", "focus"]}
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip">
                                      {_.map(item.seen, "seen_by")}
                                    </Tooltip>
                                  }
                                  rootClose
                                >
                                  <span className="font-12 mr-2">
                                    {item.seen.length}
                                  </span>
                                </OverlayTrigger>
                              </span>
                            </div>
                          </div>
                        </Col>

                        <Col className="w-100 font-14 mt-4">
                          <div
                            dangerouslySetInnerHTML={{ __html: item.value }}
                          ></div>
                        </Col>

                        {item.replys && (
                          <div className="reply-area p-3">
                            {item.replys.map((rep, keyRep) => {
                              return (
                                <div className="pt-3" key={keyRep}>
                                  <Row>
                                    <Col md="1" className="mt-1">
                                      <Image src={AvatarCommentIcon} />
                                    </Col>
                                    <Col>
                                      <div className="reply-body">
                                        <div className="reply-body-wrapper">
                                          <span className="user-name font-14">
                                            {rep.created_name}
                                          </span>
                                          <span className="font-12 ml-1">
                                            {rep.value}
                                          </span>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            })}
                          </div>
                        )}

                        {item.add_reply ? (
                          <>
                            <div className="reply-area p-3">
                              <Row>
                                <Col md="1" className="mt-2">
                                  <Image src={AvatarCommentIcon} />
                                </Col>
                                <Col>
                                  <input
                                    type="text"
                                    ref={(ref) => setRefComment(ref, key)}
                                    className="form-control font-14 text-comment"
                                    defaultValue=""
                                    onKeyPress={(e) =>
                                      handleUpdateReply(e, key, item)
                                    }
                                    onChange={(e) =>
                                      handleValueReply(key, e.target.value)
                                    }
                                  ></input>
                                </Col>
                              </Row>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Tab>
          <Tab eventKey="activity" title="Activity">
            <ul className="p-0">
              {notif.map((item) => {
                return (
                  <div key={item.id} className="devider parent-notif mt-2">
                    <li className="notif-container">
                      <a href="#" className="notif-href">
                        <div className="notif-creator center-img">
                          <Image src={AvatarIcon} />
                        </div>
                        <div className="notif-content">
                          <div>
                            <span className="font-14 color-grey">
                              {moment(item.created_at).fromNow()}
                            </span>
                          </div>
                          <div className="mt-2">
                            <span
                              className="font-14 font-w-400"
                              dangerouslySetInnerHTML={{ __html: item.message }}
                            ></span>
                          </div>
                          <div className="mt-2">
                            <Image src={FolderActiveIcon} className="mr-2" />
                            <div className="d-inline mt-2">
                              <span className="font-14 text-primary">
                                {item.board ? item.board.title : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </a>
                    </li>
                  </div>
                );
              })}
            </ul>
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default ModalComment;
