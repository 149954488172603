import React, { useState } from 'react'; 
import {useHistory} from 'react-router-dom' 
import { useForm } from "react-hook-form"
import {Form, Row, Col, Button, Spinner} from 'react-bootstrap' 
import {MakeRequest} from "../../api/Api"   
import {asyncRequest } from '../../helpers/utils/Utils' 
import {NotificationManager, NotificationContainer} from 'react-notifications'  
import HeroImg from '../../img/img-contact.svg';

export const Hero = () => {
  const history = useHistory()  
  const { register, handleSubmit, watch, errors } = useForm() 
  const [isLoading, setLoading] = useState(false) 

  const onSubmit = async (params) => {
    params.subjectType = 'SALES'
    setLoading(true)
    const result = await MakeRequest('post','contact-us', params)   
    if(result.status !== 200) {
        asyncRequest(1000).then(()=>{
          setLoading(false)
            let detailError = result.data ? result.data.detail : 'Server error'
            NotificationManager.error(detailError) 
        })
    }else{
        asyncRequest(1000).then(()=>{
          NotificationManager.success(result.data.message) 
            setLoading(false)
        })
      }
   
  }
 
    return (
      <div className="container-fluid Col-xs-"  id="contact">
         <NotificationContainer/>
        <div className="hero-container">
          <Row className="w-100">
            <Col className="pl-5 pb-5 pr-5 pt-3">
          <span className="display-text-contact">
          What Can We Help?
          </span>
          <p className="hero-p-contact">
          For more info fill out the form
          </p> 
            <Form onSubmit={handleSubmit(onSubmit)} className="w-100">  
              
                <Form.Group controlId="formBasicEmail"> 
                      <Form.Control className="font-16 input-text" name="name" type="text" placeholder="Name" ref={register({ required: true })} />
                      <div className="mt-3">
                          {errors.name && <span>This field is required</span>}
                      </div>
                  </Form.Group>
                 
                <Form.Group controlId="formBasicEmail" className="pt-2"> 
                      <Form.Control className="font-16 input-text" name="email" type="email" placeholder="Email" ref={register({ required: true })} />
                      <div className="mt-3">
                          {errors.email && <span>This field is required</span>}
                      </div>
                  </Form.Group>
                
              
             
                <Form.Group controlId="formBasicEmail" className="pt-2"> 
                      <Form.Control className="font-16 input-text" name="company" type="text" placeholder="Company" ref={register({ required: true })} />
                      <div className="mt-3">
                          {errors.name && <span>This field is required</span>}
                      </div>
                  </Form.Group>
                
                <Form.Group controlId="formBasicEmail" className="pt-2"> 
                      <Form.Control className="font-16 input-text" name="companySize" type="number" placeholder="Company Size" ref={register({ required: true })} />
                      <div className="mt-3">
                          {errors.companySize && <span>This field is required</span>}
                      </div>
                  </Form.Group> 

              
                <Form.Group controlId="formBasicEmail" className="pt-2"> 
                      <Form.Control as="textarea" rows={3} className="font-16 input-text" name="message" type="text" placeholder="Message" ref={register({ required: true })} />
                      <div className="mt-3">
                          {errors.message && <span>This field is required</span>}
                      </div>
                  </Form.Group>
                
              <div className="w-100 pt-2">
                      <Button 
                          disabled={isLoading}
                          className="btn-submit"
                          type="submit" 
                          >
                          {isLoading ?
                              <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  />
                              : "Submit" 
                          }
                          </Button> 
                  </div> 
              </Form>  
              </Col>
              <Col className="m-auto">
                  <img id="heroimg-display-img-contact"src={HeroImg}></img>
              </Col>
              </Row>
        </div>

         
      </div>
    );
  } 

export default Hero