import React, {useEffect, useState, useContext, useRef} from 'react'
import {Modal, Row, Col} from 'react-bootstrap'  
import {useHistory} from 'react-router-dom' 

const ModalAddBoard = (props) =>{
    const history = useHistory()  
    
    useEffect(()=>{
     
    },[props])
   

    return (
        <Modal
        {...props}
        size="lg" 
        aria-labelledby="contained-modal-title-vcenter"> 
          
            <Modal.Body className="m-2"> 
                <div className="text-center">
                    <div className="text-promo">
                        <span>Try SaatKerja Today</span>
                    </div>
                    <div className="text-promo-desc">
                        <span>Get started for free, then add your whole team.</span>
                    </div>
                </div>
                <Row  className="bg-promo mt-5 ml-2 mr-2 mb-2">
                    <Col md="4 spacer-right p-5">
                        <div className="text-promo-white ">
                            <span>Free <br></br>30-Days Trial</span>
                        </div> 
                    </Col>
                    <Col md="8 p-5 spacer-right-hidden">
                        <div className="font-14 font-w-400 line-180">
                            <span className="text-white">Complish is free for up to 5 people. After that it’s just try Basic++ for 30-Days Trial. If you prefer a more than plan, it’s just call our contact service</span>
                        </div>
                        <div className="text-center w-100 mt-5 p-2" onClick={()=>{props.onHide(); history.push('/pricing') }}> 
                            <span className="btn-promo p-2 text-primary cursor-pointer pl-2 pr-2"> Try it Now</span> 
                        </div>
                    </Col>
                </Row>
            </Modal.Body> 

        </Modal> 
    )
}

export default ModalAddBoard