import React, {useState, useEffect} from 'react'
import {Col, Row, Form, Image, Button, Alert, Spinner } from 'react-bootstrap' 
import ImageLogin from "../../img/login.png" 
import LockIcon from "../../img/lock.svg" 
import "../css/login.css"
import { useForm } from "react-hook-form"
import { MakeRequest } from "../../api/Api"  
import {useHistory} from 'react-router-dom'  
import {asyncRequest } from '../../helpers/utils/Utils' 


const Login = () =>{ 
    const history = useHistory()
    const [typePassword, setTypePassword] = useState("password") 
    const [isLoading, setLoading] = useState(false) 
    const [alert, setAlert] = useState({msg: 'Register success.', variant: 'success', hidden: true}) 
    const { register, handleSubmit, watch, errors } = useForm()
    const [dataInvitation, setDataInvitation] = useState(false) 

    useEffect(()=>{
        setDataInvitation(history.location.state.data)  
    },[])

    const onSubmit = async (params) => {
        setAlert({msg: 'Register success.', variant: 'success', hidden: true}) 
        setLoading(true) 
        params.email = dataInvitation.email
        dataInvitation.username = params.username
        dataInvitation.password = params.password
        const create = await MakeRequest('post','user-create-member-invitation', {data: dataInvitation})   
        const result = await MakeRequest('post','user-login', params)   
        if(result.status !== 200) {
            asyncRequest(1000).then(()=>{
                let detailError = result.data.detail
                setAlert({msg: detailError === undefined ? "Server error": detailError, variant: 'danger', hidden: false})
            })
        }else{
            asyncRequest(1000).then(()=>{
                setAlert({msg: 'Login successfully.', variant: 'success', hidden: false})
                localStorage.removeItem('saatkerja')  
            })
           
            asyncRequest(1000).then(() => {
                result.data.expired = new Date().getTime() + 3600 * 1000
                result.data.team_id = result.data.teamUser.team ? result.data.teamUser.team.id : result.data.teamUser.team_id
                result.data.team = result.data.teamUser.team?result.data.teamUser.team : {permission_id : ""}
                result.data.permission_id = result.data.teamUser.permission_id
                result.data.team.permission_id = result.data.teamUser.permission_id 
                localStorage.setItem('saatkerja', JSON.stringify(result.data))   
                history.push('/app/board')
            })
        }
        asyncRequest(1000).then(()=>{
            setLoading(false)
        }) 
      
    }
 

    return(
        <Row xs="12" className="no-gutters vh-100 vw-100">
            <Col className="m-auto" md={6}>
                <Col md={10}>
                    <div className="m-5">
                        <div className="txt-login">
                            <span>Login to your account</span>
                        </div>
                        <div className="mt-2 txt-login-child">
                            <span className="color-grey-darker">Dont have an account ?</span> <span className="cursor-pointer text-primary" onClick={()=>{history.push('/register')}}> Sign up here</span>
                        </div>
                        <div className="mt-5">
                            <Form onSubmit={handleSubmit(onSubmit)}> 
                                <Form.Group controlId="formBasicEmail"> 
                                    <Form.Control className="font-16 input-text" value={dataInvitation.email} disabled name="email" type="email" placeholder="Email" ref={register({ required: true })} />
                                    <div className="mt-3">
                                        {errors.email && <span>This field is required</span>}
                                    </div> 
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail" className="pt-2">  
                                    <div className="pass-wrapper">
                                        <Form.Control className="font-16 input-text" name="password" type={typePassword} placeholder="Password" ref={register({ required: true , minLength: 8})} />
                                        {typePassword === "password" ? 
                                            <Image src={LockIcon} onClick={()=> setTypePassword("text")} className="eye-icon"></Image>
                                        :
                                            <Image src={LockIcon} onClick={()=> setTypePassword("password")} className="eye-icon"></Image>
                                        }
                                    </div>
                                    {errors.password && <span>This field is required / minimal 8 character</span>}
                                </Form.Group>
                                <Form.Group className="pt-2" hidden={alert.hidden}>  
                                    <Alert variant={alert.variant} >
                                        <span className="font-15">{alert.msg}</span>
                                    </Alert>
                                </Form.Group>
                                <Form.Group controlId="formBasicCheckbox" className="checkbox pt-2">
                                    <div className="d-inline"> 
                                        <Form.Check type="checkbox" className="font-16 d-inline cursor-pointer" label="Remember me" />
                                    </div>    
                                    <div className="d-inline"> 
                                        <span className="cursor-pointer text-primary font-16 d-inline float-right" onClick={()=>{history.push('/forgotpassword')}}> Forgot Password ?</span>
                                    </div>
                                </Form.Group>
                                <div className="w-100 text-center pt-2">
                                    <Button
                                        variant="primary"
                                        disabled={isLoading}
                                        className="font-16 text-white text-center font-weight-bold btn-login"
                                        type="submit" 
                                        >
                                        {isLoading ?
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                />
                                            : "Login" 
                                        }
                                        </Button> 
                                </div> 
                            </Form> 
                        </div>
                    </div>
                </Col> 
            </Col>
            <Col className="bg-login" md={5}>
                <Image className="img-login" src={ImageLogin} width="710" height="518"></Image>
            </Col>
       </Row>
    )
}

export default Login