import React, {useEffect, useState, useContext} from 'react'

import {Modal, Button, Spinner } from 'react-bootstrap' 
import _ from 'lodash'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' 
import {DashboardContext} from "../../context/DashboardContext"  
import {asyncRequest } from '../../helpers/utils/Utils' 

const ModalDesc = (props) =>{  
    const {createWidget, showModalText, updateDashboard} = useContext(DashboardContext)  
    const [valueDesc, setValueDesc] = useState("") 
    const [isLoading, setLoading] = useState(false) 

    const modules = {
        toolbar: [ 
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}],
          
        ],
      }

    const formats = [ 
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 
    ]

    useEffect(()=>{
        if(showModalText.isShow)
        setValueDesc(showModalText.data.column.value)
    },[showModalText])

    const handleAdd = async()=>{ 
        setLoading(true)
        if(showModalText.isEdit){
            showModalText.data.column.value = valueDesc
            await updateDashboard(showModalText.data,false)  
        } 
        else createWidget({type: "Text", value: valueDesc})
        asyncRequest(1000).then(()=>{
            setLoading(false)
            props.onHide()
        })
      
    }
  
    return (
        <Modal
        {...props}
        size="md"  
        centered
        aria-labelledby="contained-modal-title-vcenter"
        id="modal-text" >  
            <Modal.Body>  
                <ReactQuill theme="snow"
                    id="quill-text"
                    value={valueDesc || ''} onChange={setValueDesc}
                    modules={modules}  
                    formats={formats} 
                />   
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-cancel" onClick={props.onHide}>Cancel</Button>  
                    <Button 
                        disabled={isLoading}
                        onClick={handleAdd}
                        className="btn-yes text-white"
                        type="submit"
                        >
                            {isLoading ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    />
                                : showModalText.isEdit ? 'Update' : 'Add' 
                            }
                    </Button> 
            </Modal.Footer> 
        </Modal>
    )
}

export default ModalDesc