import React from 'react'
import { useLocation } from 'react-router-dom'

import Menu from './Menu'
import MenuTeams from './MenuTeams'
import MenuAndroid from './MenuAndroid'

const Index = () => {

    const location = useLocation()

    return (
        <React.Fragment>
            {location.pathname.includes("teams") ? <MenuTeams />
                : location.pathname.includes("profile") ? null
                    : location.pathname.includes("chat") ? null
                        : location.pathname.includes("board-android-detail") ? <MenuAndroid />
                            : <Menu />}
        </React.Fragment>

    )
}

export default Index