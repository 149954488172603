//=========REACT INITIAL========//
import React, { useEffect, useState, useContext, useRef } from 'react'
import { DashboardContext } from "../../../context/DashboardContext"

//=========BOOTSTARP INITIAL========//
import { Col, Row, Nav, Image, Popover, OverlayTrigger } from 'react-bootstrap'


//=========ICON LIST========//    
import BoardIcon from "../../../img/board.svg"
import PlusIcon from "../../../img/add-small-black.svg"

//=========COMPONENT========// 
import ContentView from './ContentAndroid'

import Status from '../../../img/status.svg'
import Charts from '../../../img/charts.svg'
import Text from '../../../img/text.svg'
import EditIcon from "../../../img/edit.svg"
import Chart from "../../../img/chart.svg"

const Content = (props) => {
    const { setShowChooseBoard, selectedBoard, boardActive,
        setModeDashboard, modeDashboard, stateChart, color,
        setShowWidget, setShowModalText, setShowWidgetBattery } = useContext(DashboardContext)
    const [length, setlength] = useState(0)
    let ref = useRef(null)

    const widget = [
        { title: 'Chart', img: Charts, ready: true, fn: 'setShowWidget(true)', desc: 'Create charts view to visually show data in your board' },
        { title: 'Text', img: Text, ready: true, fn: 'setShowModalText({isShow: true, data : {column: {value: "Add your text here"}}})', desc: 'Add notes for your dashboard' },
        { title: 'Status Overview', img: Status, desc: 'Show your total progress from all tasks', fn: 'setShowWidgetBattery(true)' },
    ]

    useEffect(() => {
        if (!boardActive.dashboard) return
        setModeDashboard("edit")
        setlength(0)
        if (boardActive.dashboard.length > 0) {
            setModeDashboard("view")
            setlength(1)
        }
    }, [boardActive])


    const popoverAdd = (
        <Popover id="popover-filter">
            <Popover.Content>
                {widget.map(item => {
                    return (
                        <div className="div-hover p-2" key={item.title}>
                            <Row className="pl-3" key={item.title} onClick={stateChart.selectedIdBoard.length ? () => { eval(item.fn); ref.click() } : () => { setShowChooseBoard(true); ref.click() }} >
                                <Col md="2" className="pl-2 pt-2 pb-2">
                                    <div className="d-inline" style={{ width: '50px', height: '50px' }}>
                                        <Image src={item.img}></Image>
                                    </div>
                                </Col>
                                <Col md="8">
                                    <div className="d-inline">
                                        <span className="font-14 font-w-600">{item.title}</span>
                                        <div className="color-grey font-13 font-w-400"><span>{item.desc}</span></div>
                                    </div>
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col md="12">
                                    <Image src={Chart} width={32}></Image>
                                    <span className="workspace-item-name-child align-text-top ml-2 font-14">Chart</span>
                                   
                                </Col>  
                            </Row> */}
                        </div>

                    )
                })
                }

            </Popover.Content>

        </Popover>
    )

    return (
        <React.Fragment>
            <Row className="p-0 pt-2 breadcrumb-mobile" style={{ background: color.heder }} ref={r => (ref = r)}>
                {modeDashboard === "edit" ?
                    <Col>
                        <Nav.Link className="nav-menu p-2 d-inline-block" onClick={() => setShowChooseBoard(true)}>
                            <Image src={BoardIcon}></Image>
                            <span className="workspace-item-name-child align-text-top ml-2 font-w-400 font-14" style={{ color: color.color }} >Boards / {selectedBoard.length}</span>
                        </Nav.Link>
                        <OverlayTrigger trigger="click" placement="bottom" overlay={popoverAdd} rootClose>
                            <Nav.Link className="nav-menu p-2 d-inline-block">
                                <Image src={PlusIcon}></Image>
                                <span className="workspace-item-name-child align-text-top ml-1 font-14 font-w-400" style={{ color: color.color }}>Add Widget</span>
                            </Nav.Link>
                        </OverlayTrigger>
                    </Col>
                    : ""}
            </Row>
            <Col className="w-100 p-0">
                <ContentView />
            </Col>
        </React.Fragment>
    )
}


export default Content
