import React, {useEffect, useState, useRef, useContext} from 'react'
import {Modal, Button, Form, Alert, Spinner } from 'react-bootstrap' 
import { useForm } from "react-hook-form"  
import {DashboardContext} from '../../context/DashboardContext'
import {StyleSelect} from "../../helpers/select/Select"
import Select from 'react-select' 

const ModalAddWorkspace = (props) =>{ 
    const ref = useRef()
    const [isLoading, setLoading] = useState(false) 
    const { register, handleSubmit, watch, errors } = useForm()
    const [alert, setAlert] = useState({msg: '', variant: 'success', hidden: true}) 
    const {insertWorkspace, setParams, workspace, getWorkspace, params, showModalPricing, teams, getTeams} = useContext(DashboardContext)  
    const [listTeams, setListTeams] = useState([])
    const [selected, setSelected] = useState({})
    const [dataSelectedTeam, setDataSelected] = useState({})
    const [errorNotSelectTeam, setErrorNotSelectTeam] = useState("")

    useEffect(()=>{
        setAlert({...alert, hidden: true})
        // set list select teams
        let dataTeams = []
        teams.map(item =>{ 
            let tmp =  {value: item.team.id, label: item.team.name}
            dataTeams.push(tmp)
        }) 

        setListTeams(dataTeams)
    },[props])

    const handleChange = (selected) =>{
        setErrorNotSelectTeam("")
        let dataSelected = {
            team_id: selected.value
        }
        setDataSelected(dataSelected)
        setSelected(selected)
    }

    const onSubmit = async (input) => {
        // if(!dataSelectedTeam.team_id){
        //     setErrorNotSelectTeam("This Field is Required.")
        //     return
        // }
        // return
        setLoading(true)
        // let data = JSON.parse(localStorage.getItem('saatkerja'))  
        let data = params
        input.team_id = data.team.id
        // input.team_id = dataSelectedTeam.team_id // additional dhika
        input.user_id = data.id
        input.username = params.username
        const result = await insertWorkspace(input) 
        if(result.status !== 200) {
            setLoading(false)
            if(result.message === "limit") {
                showModalPricing(true)
                return
            }
           setAlert({msg: result.data.detail, variant: 'danger', hidden: false})
        
        }else{ 
            setAlert({msg: 'Create workspace successfully', variant: 'success', hidden: false})
             //=========SET indexWorkspaceActive FOR DEAFAULT SELECTED WORKSAPCE=========//
             setParams({...params, indexBoardActive: 0, workspace_id: result.data.id, indexWorkspaceActive: workspace.length
            , selectedWorkspace: {}})
            
             //=========GET WOKRSPACE AFTER INSERT=========//
            await getWorkspace(data)
            
            simulateNetworkRequest().then(() => {
                setAlert({ hidden: false})
                setLoading(false)
                props.onHide()
            })
            setDataSelected({})
            setSelected({})
        }
       
    }
    
    function simulateNetworkRequest() {
        return new Promise((resolve) => setTimeout(resolve, 1000));
    }

    return (
        <Modal
        {...props}
        size=""
        aria-labelledby="contained-modal-title-vcenter"
        centered>
            <Form onSubmit={handleSubmit(onSubmit)}> 
                <Modal.Header>
                    <span className="modal-title">
                    Create Workspace
                    </span>
                </Modal.Header>
                <Modal.Body className="m-2"> 
                    <Form.Control autoFocus={true} 
                    className="mb-3 font-14"
                    ref={ref} 
                    type="text" name="name"  placeholder="New Workspace" ref={register({ required: true })}></Form.Control>
                     {errors.name && <span>This field is required</span>}
                    {/* <p className="font-14 mt-2">
                    Workspaces are a way to organize your boards by team or project in one place.sssss
                    </p> */}
                    <Alert variant={alert.variant} hidden={alert.hidden} className="mt-3">
                        {alert.msg}
                    </Alert>
                    {/* <Select focus styles={StyleSelect({menuListHeight: 250})}   
                        ref={ref}
                        name="team_select"
                        placeholder="Select Team"  
                        options={listTeams}  
                        isClearable={false} 
                        value={selected}
                        onChange={(selected) => handleChange(selected)}
                        className='m-0 p-0' 
                        ></Select>
                        {errorNotSelectTeam != "" ? 
                            <Alert variant={"danger"} hidden={false} className="mt-3">
                            {errorNotSelectTeam}
                        </Alert> : null
                        } */}
                        <p className="font-14 mt-2">
                        Workspaces are a way to organize your boards by team or project in one place.
                        </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-cancel" onClick={props.onHide}>Cancel</Button>  
                    <Button 
                        disabled={isLoading}
                        className="btn-yes text-white"
                        type="submit"
                        >
                            {isLoading ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    />
                                : "Create" 
                            }
                    </Button> 
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default ModalAddWorkspace