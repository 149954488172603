import React, { useEffect, useState, useContext, useRef } from "react";
import { Modal, Button, Form, Alert, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { DashboardContext } from "../../context/DashboardContext";

const ModalAddDashboardPMU = (props) => {
  const history = useHistory();
  const ref = useRef();
  const [isLoading, setLoading] = useState(false);
  const [permission, setPermission] = useState(1);
  const { register, handleSubmit, watch, errors } = useForm();
  const [alert, setAlert] = useState({
    msg: "",
    variant: "success",
    hidden: true,
  });
  const {
    params,
    setFlagNewBoard,
    setParams,
    board,
    createBoard,
    setShowChooseBoard,
    dispatchChart,
    showModalPricing,
  } = useContext(DashboardContext);

  useEffect(() => {
    setAlert({ ...alert, hidden: true });
  }, [props]);

  const onSubmit = async (paramTmp) => {
    setLoading(true);
    let param = params;
    paramTmp.team_id = param.team_id;
    paramTmp.workspace_id = param.workspace_id;
    paramTmp.user_id = param.id; 
    paramTmp.type = 4; //======2 is Dashboard PMU=====
    const result = await createBoard(paramTmp);
    if (result.status !== 200) {
      setLoading(false);
      if (result.message === "limit") {
        showModalPricing(true);
        return;
      }
      setAlert({ msg: result.data.detail, variant: "danger", hidden: false });
      setLoading(false);
    } else {
      setAlert({
        msg: "Create dashboard successfully",
        variant: "success",
        hidden: false,
      });
      dispatchChart({ type: "CLEAR_SELECTED_BOARD" });
      //=========SET indexWorkspaceActive FOR DEAFAULT SELECTED WORKSAPCE=========//
      setParams({ ...params, indexBoardActive: board.length });
      simulateNetworkRequest().then(() => {
        setAlert({ hidden: false });
        setFlagNewBoard(new Date().getUTCMilliseconds());
        props.onHide();
        setShowChooseBoard(true);
        setLoading(false);
      });
    }
  };

  function simulateNetworkRequest() {
    return new Promise((resolve) => setTimeout(resolve, 1000));
  }

  const handleChanePermission = (e) => {
    setPermission(parseInt(e.target.value));
  };

  return (
    <Modal
      {...props}
      size=""
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header>
          <span className="modal-title">Create dashboard PMU</span>
        </Modal.Header>
        <Modal.Body className="m-2">
          <Form.Control
            autoFocus={true}
            className="mb-3 font-14"
            ref={ref}
            type="text"
            name="title"
            placeholder="New Blank Dashboar PMU"
            ref={register({ required: true })}
          ></Form.Control>
          {errors.title && <span>This field is required</span>}

          <div className="pt-2" hidden>
            <Form.Check
              inline
              className="font-14"
              name="permission"
              defaultChecked={true}
              label="Main"
              value="1"
              onChange={handleChanePermission}
              type="radio"
              id="main"
            />
            <Form.Check
              inline
              className="font-14"
              name="permission"
              label="Private"
              type="radio"
              id="private"
              onChange={handleChanePermission}
              value="2"
            />
            <div className="mt-2">
              {permission === 1 ? (
                <span className="font-14" style={{ color: "#676879" }}>
                  Visible to everyone in your account
                </span>
              ) : (
                <span className="font-14" style={{ color: "#676879" }}>
                  For working privately - alone or with selected team members
                </span>
              )}
            </div>
          </div>

          <Alert variant={alert.variant} hidden={alert.hidden} className="mt-3">
            {alert.msg}
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-cancel" onClick={props.onHide}>
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            className="btn-yes text-white"
            type="submit"
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Add dashboard"
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalAddDashboardPMU;
