import React, { useEffect, useState, useContext, useRef } from "react";
import { Modal, Button, Form, Alert, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { DashboardContext } from "../../context/DashboardContext";

const ModalAddBoard = (props) => {
  const history = useHistory();
  const ref = useRef();
  const [isLoading, setLoading] = useState(false);
  const { register, handleSubmit, watch, errors } = useForm();
  const [alert, setAlert] = useState({
    msg: "",
    variant: "success",
    hidden: true,
  });
  const {
    params,
    setFlagNewBoard,
    setParams,
    board,
    createBoard,
    showModalPricing,
  } = useContext(DashboardContext);

  useEffect(() => {
    setAlert({ ...alert, hidden: true });
  }, [props]);

  const onSubmit = async (paramTmp) => {
    setLoading(true);
    let param = params;
    paramTmp.team_id = param.team_id;
    paramTmp.workspace_id = param.workspace_id;
    paramTmp.user_id = param.id;
    paramTmp.username = params.username;
    paramTmp.type = 1; //======1 is Board=====
    const result = await createBoard(paramTmp);
    if (result.status !== 200) {
      setLoading(false);
      if (result.message === "limit") {
        showModalPricing(true);
        return;
      }
      setAlert({ msg: result.data.detail, variant: "danger", hidden: false });
    } else {
      setAlert({
        msg: "Create board successfully",
        variant: "success",
        hidden: false,
      });
      //=========SET indexWorkspaceActive FOR DEAFAULT SELECTED WORKSAPCE=========//
      setParams({ ...params, indexBoardActive: board.length });
      simulateNetworkRequest().then(() => {
        setAlert({ hidden: false });
        setFlagNewBoard(new Date().getUTCMilliseconds());
        props.onHide();
        setLoading(false);
      });
    }
  };

  function simulateNetworkRequest() {
    return new Promise((resolve) => setTimeout(resolve, 1000));
  }

  return (
    <Modal
      {...props}
      size=""
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header>
          <span className="modal-title">Create Board</span>
        </Modal.Header>
        <Modal.Body className="m-2">
          <Form.Control
            autoFocus={true}
            className="mb-3 font-14"
            ref={ref}
            type="text"
            name="title"
            placeholder="New Blank Board"
            ref={register({ required: true })}
          ></Form.Control>
          {errors.title && <span>This field is required</span>}

          <Alert variant={alert.variant} hidden={alert.hidden} className="mt-3">
            {alert.msg}
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-cancel" onClick={props.onHide}>
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            className="btn-yes text-white"
            type="submit"
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Add board"
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalAddBoard;
