import React, {useEffect, useState, useContext, useRef} from 'react'
import {Modal, Button, Nav, Col, Popover, Row, OverlayTrigger } from 'react-bootstrap' 
import _ from 'lodash'
import {useHistory} from 'react-router-dom' 
import {DashboardContext} from "../../context/DashboardContext" 
import ContentEditable from 'react-contenteditable' 
import { CirclePicker } from 'react-color';

//=========ICON LIST========//  
import HighlightOffIcon from '@material-ui/icons/HighlightOff' 
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import {stripHtml ,stripHtmlAnd, uuid} from '../../helpers/utils/Utils' 

const ModalStatus = (props) =>{
    const history = useHistory()
    const ref = useRef()  
    let refEditable = useRef()  
    const [color, setColor] = useState("rgb(196, 196, 196)")   
    const [statusList, setstatusList] = useState([])    
    const {setColumnUpdateStatus, boardActive, table, showStatus} = useContext(DashboardContext)  

    const colors = ["#f44336", "#e91e63", "#9c27b0", 
                    "#673ab7", "#3f51b5", "#2196f3", 
                    "#03a9f4", "#00bcd4", "#009688", 
                    "#4caf50", "#8bc34a", "#cddc39", 
                    "rgb(255, 213, 0)", "#ffc107", "#ff9800", 
                    "#ff5722", "#795548", "#607d8b"]

    useEffect(()=>{ 
        if(showStatus.data) 
        getStatusdata() 
    },[props])

  
    const getStatusdata = () => {
        let data = _.cloneDeep(showStatus.data) 
        _.map(data, (item)=>{ item.is_used = false})
        table.map(item => {
            item.rows.map((row, index) => {
                if(index === 0) return
                row.map((val, key) => { 
                    if(item.rows[0][key].id === showStatus.idHeader && val.name !== ""){
                        let isExist = _.some(data, function(i) { 
                            return i.id == val.name.id
                        }) 
                        
                        if(isExist){
                            _.map(data, (item)=>{ if(item.id === val.name.id) item.is_used = true})
                        }
                   } 
                   
                })
            })   
        }) 
        setstatusList(data)
    }

    const onChangeComplete =(color) =>{
        setColor(color.hex)  
        let newStatus = _.map(statusList,((item, key)=>{
            if(key === ref.current) item.color = color.hex
            return item
        })) 
 
        setstatusList(newStatus)
       
    }

    const handleApply = async()=>{  
        setColumnUpdateStatus({board_id: boardActive.id, id_header : showStatus.idHeader, 
            status_list : statusList, refresh: true, user_id:"" })
        props.onHide()
    }

    const popoverColor = (
        <Popover id="popover-basic">  
            <Popover.Content className="mt-2">
                <CirclePicker colors={colors} onChangeComplete = {onChangeComplete}
                />
            </Popover.Content>
            
        </Popover>
    ) 

    const handelChangeEditable = (value) => { 
        refEditable.current = value.replace(/\&nbsp;/g, ' ')  
    }

    const handleEditItem = (index) =>{
        if(refEditable.current !== "" && refEditable.current ){
            let newStatus = _.map(statusList,((item, key)=>{ 
                if(key === index) item.name = refEditable.current.toString().trim()
                return item
            })) 
            refEditable.current="" 
            setstatusList(newStatus)
        }
       
    }

    const handleDelete = (index) =>{
        let newStatus = _.filter(statusList,((item, key)=>{ 
            return key !== index 
        }))  
        setstatusList(newStatus)
    }

    const handleAdd = () => {
        let tmpArr = _.cloneDeep(statusList)
        let tmpObj= statusList[0]
        tmpObj.id = uuid()
        tmpObj.name = "New Status"
        tmpObj.color = 'rgb(196, 196, 196)'
        tmpArr[tmpArr.length] =  tmpObj  
        setstatusList(tmpArr) 
    }

    return (
        <Modal
        {...props}
        size="" 
        id={statusList.length}
        aria-labelledby="contained-modal-title-vcenter" > 
                 
            <Modal.Body className="m-2"> 
            {statusList.map((item,key)=>{
                    return( 
                        <Col key={key}>
                            <Row>
                                <Col md="8"  style={{background: item.color}} className="p-2 mt-2">
                                <ContentEditable   
                                    onChange={(e)=> {handelChangeEditable(e.target.value.replace(/<\/?[^>]+(>|$)/g, "")+ " ")}} 
                                    html= {item.name}  
                                    onBlur={()=>{handleEditItem(key)}} 
                                    className="single-line text-white w-75 font-14"
                                    />  
                            </Col>
                            <Col className="d-flex align-items-center" onClick={()=>{ref.current = key}}>
                                <OverlayTrigger trigger="click" placement="right" overlay={popoverColor} rootClose>
                                    <FormatColorFillIcon className="float-right align-text-top mr-2 cursor-pointer" style={{color: 'black', fontSize: 22 }}/>
                                </OverlayTrigger>
                                {key === 0 || item.is_used? ""
                                :<HighlightOffIcon className="float-right align-text-top cursor-pointer" onClick = {()=>handleDelete(key)} style={{color: 'black', fontSize: 22 }}/>
                                }
                            </Col>
                            
                            </Row>
                            
                            
                        </Col>   
                    )
                })}
            <Nav.Link className="nav-menu p-2 mt-2 mb-2" onClick = {()=>handleAdd()}> 
                <AddCircleOutlineIcon style={{color: 'black', fontSize: 22 }} fontSize="large" className="align-text-top"/>
                <span className="workspace-item-name-child align-text-top ml-2 font-13">Add label</span>
           </Nav.Link>
            </Modal.Body>
            <Modal.Footer>
            <Button className="btn-cancel" onClick={props.onHide}>Cancel</Button>  
                <Button 
                        onClick={handleApply}
                    className="btn-yes text-white"
                    type="submit"
                    >
                        Apply
                </Button> 
            </Modal.Footer> 
        </Modal>
    )
}

export default ModalStatus