//=========REACT INITIAL========//
import React, { useEffect, useContext, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'


//=========BOOTSTARP INITIAL========//
import { Row, Col, Tabs, Tab } from 'react-bootstrap'

//=========CONTEXT========//
import { DashboardContext } from "../../context/DashboardContext"

//=========3RD LIBRARY========//
import _ from 'lodash'
// import '../css/setting.css' 
import Personal from './Personal'
import Password from './Password'
import Article from './Article'
import Setting from './Setting'

const Workspace = () => {
    const { params, setModalSpinner, modalSpinner, rating, showNotif, setShowNotif } = useContext(DashboardContext)
    const [key, setKey] = useState('Profile')

    const isFirstRun = useRef(true)
    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false
            return
        }
    }, [])

    return (
        <React.Fragment>
            <Row className="vh-100">
                <Col>
                    <div className="ml-5 pl-3 pt-3 bg-white">
                        <span className="font-20 font-w-600 text-dark">{key}</span>
                    </div>
                    <div className="pl-5 pt-4 min-vh-100">
                        <Tabs defaultActiveKey="info" onSelect={(k) => {
                            if (k == 'setting') { setKey('Setting'); return };
                            setKey('My profile');

                        }} transition={false}>
                            <Tab eventKey="info" title="Personal Info">
                                <Personal />
                            </Tab>

                            <Tab eventKey="password" title="Change Password">
                                <Password />
                            </Tab>
                            {params.is_admin ?
                                <Tab eventKey="article" title="Article">
                                    <Article />
                                </Tab>
                                : ""}
                            <Tab eventKey="setting" title="Setting">
                                <Setting />
                            </Tab>
                        </Tabs>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Workspace