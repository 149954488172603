import { Height } from '@material-ui/icons'
import React, {useEffect, useState, useContext, useRef} from 'react'
import {Modal, Button, Form, Row, Col, Spinner } from 'react-bootstrap'  
import {DashboardContext} from "../../context/DashboardContext" 

const ModalAddBoard = (props) =>{  
    const {selectedTeams, modalPayment} = useContext(DashboardContext)  

    // useEffect(()=>{
    //     if(modalPayment && modalPayment.token){
    //         window.snap.pay(modalPayment.token)
    //     }
    // },[modalPayment])
    
    return (
        <Modal
        {...props}
        size=""
        id="modal-price-list"
        className="modal full fade" 
        centered>
            <Form> 
                <Modal.Header closeButton> 
                    <div className="w-100 text-center">
                        <span className="modal-title text-center">
                            Purchase {modalPayment && modalPayment.plan} Plan {selectedTeams.label}
                        </span>
                    </div>
                  
                </Modal.Header>
                <Modal.Body className="m-2 vh-100">  
                    
                    {/* <iframe style={{width:'100%',height:'100%'}} src={modalPayment && modalPayment.url} frameborder="0" scrolling="no"></iframe> */}
                  
                </Modal.Body>
               
            </Form>
        </Modal>
    )
}

export default ModalAddBoard