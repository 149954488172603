import React, {useEffect, useState, useContext, useRef} from 'react'
import {Modal, Button, Form, Alert, Spinner } from 'react-bootstrap' 
import { useForm } from "react-hook-form" 
import {useHistory} from 'react-router-dom' 
import {DashboardContext} from "../../context/DashboardContext" 

const ModalAddBoard = (props) =>{
    const history = useHistory()
    const ref = useRef()
    const [isLoading, setLoading] = useState(false) 
    const { register, handleSubmit, watch, errors } = useForm()
    const [alert, setAlert] = useState({msg: '', variant: 'success', hidden: true}) 
    const {params,postMenuArticleCreate, setNewArticle, modalMenuArticle, showModalMenuArticle} = useContext(DashboardContext)  
 
    const onSubmit = async (paramTmp) => {
        setLoading(true)  
        paramTmp.created_by = params.id
        paramTmp.parent = modalMenuArticle.data? modalMenuArticle.data.parent : null
        if(modalMenuArticle.data)  paramTmp.id = modalMenuArticle.data.id
        if(modalMenuArticle.subMenu)  paramTmp.parent = modalMenuArticle.subMenu.id
       
        let data = await postMenuArticleCreate(paramTmp)
        if(data.status === 200){
            let debouncer = setTimeout(() => {
                    setLoading(false)
                    setNewArticle(new Date()) 
                    showModalMenuArticle({data:false, isShow: false})
                    return () => {
                    clearTimeout(debouncer);
                } 
            })
        }   
    } 

    return (
        <Modal
        {...props}
        size=""
        aria-labelledby="contained-modal-title-vcenter"
        centered>
            <Form onSubmit={handleSubmit(onSubmit)}> 
                <Modal.Header> 
                    <span className="modal-title">
                        {modalMenuArticle.data? 'Update Menu'  : 'Create Menu'} 
                    </span>
                </Modal.Header>
                <Modal.Body className="m-2"> 
                    <Form.Control autoFocus={true} 
                    className="mb-3 font-14"
                    ref={ref} 
                    type="text" name="name" defaultValue={modalMenuArticle.data ? modalMenuArticle.data.name : ""} placeholder="Menu" ref={register({ required: true })}></Form.Control>
                     {errors.title && <span>This field is required</span>}
                    
                    <Alert variant={alert.variant} hidden={alert.hidden} className="mt-3">
                        {alert.msg}
                    </Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-cancel" onClick={props.onHide}>Cancel</Button>  
                    <Button 
                        disabled={isLoading}
                        className="btn-yes text-white"
                        type="submit"
                        >
                            {isLoading ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    />
                                :  modalMenuArticle.data? 'Update Menu'  : 'Create Menu'
                            }
                    </Button> 
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default ModalAddBoard