import React, { useEffect, useContext, useState} from 'react';  
import {Form, Row, Col, Image} from 'react-bootstrap'   
import { HashLink as Link } from 'react-router-hash-link'; 
import './css/home.css';
import {useLocation, useHistory} from 'react-router-dom'  
import { MakeRequest } from "../../api/Api"  
import _ from 'lodash'  
import ElipsIcon from "../../img/elips.svg"  
import ElipsIconNoActive from "../../img/elips-white.svg"   
import Icon from "../../img/icon-support.svg"  
import 'react-quill/dist/quill.snow.css'

export const Hero = () => {  
    const location = useLocation()   
    const history = useHistory()   
    const [data, setData] = useState([]) 
    const [content, setContent] = useState("") 

    useEffect(()=>{
      getMenu()
    },[])

    useEffect(()=>{
      let id = location.pathname.split("/")[2]
      if(id)
      getArticle(id)
    },[location])

    const getMenu = async ()=>{
      const result = await MakeRequest('post','article-menu', {})   
      if(result.status === 200){  
        let arr = []
        result.data.map((item, key) =>{ 
          if(!item.parent) { 
            item.child = _.filter(result.data, (it) => {return it.parent === item.id})
            item.child.map((child, index)=>{
              if(index === 0 && key === 0) {
                child.is_active = true
                history.push('/support/'+child.id)
              }
            })
            arr.push(item)
          }
        }) 
        setData(arr) 
      }
  }

  const getArticle = async (id) => {
    const result = await MakeRequest('post','article', {id: id})   
    setContent("")
    if(result.status === 200){  
      if(result.data.length)
        setContent(result.data[0].content)
    }
  }

  const handleClick = (param) => {
    let tmpArr = _.cloneDeep(data)
    tmpArr.map(item => {
      item.child.map(child => {
        child.is_active = false
        if(child.id === param.id) {
          child.is_active = true
        }
      })
    }) 
   setData(tmpArr)
  }

    return (
      <div className="container-fluid"> 
        <Row className="">
          <Col md="3" className="m-0 p-0 min-vh-100 side-bar-support">
            <div className="p-4">
              <a href="/">
                <Image src = {Icon}></Image>
              </a>
              <div className="mt-5">
                {data.map((item, key)=> {
                  return (
                      <div key={key} className="mt-3">
                      <span className="font-14 font-w-600">{item.name}</span> 
                        {item.child.map((child, index) => {
                          return (
                            <div className="text-left mt-3" key={index}>
                              <div className="ml-3">
                                <Link to={`/support/${child.id}`} onClick={()=>handleClick(child)}> 
                                {child.is_active ? <Image src={ElipsIcon}></Image> : <Image src={ElipsIconNoActive}></Image>}
                                <span className={"font-14 font-w-400 text-dark ml-1" } 
                                    style={{verticalAlign:'middle'}}>{child.name}</span>  
                                </Link> 
                              </div> 
                            </div>
                          )
                        })}

                      </div>
                  )
                })}
              </div>
             
            </div> 
            </Col>
            <Col md="9"> 
           
            <div className="col-md-8 pl-5 ml-5">
              <div className="mt-4">
              <input type="text" placeholder="Search Doc" className="form-control font-14"></input>
              </div>
              <div className="mt-5" dangerouslySetInnerHTML= {{__html : content}}></div> 
            </div>
           
            </Col>
        </Row> 
          
         
      </div>
    );
  } 

export default Hero