//=========REACT INITIAL========//
import React, { useEffect, useState, useContext, useRef } from "react";
import { Gantt } from "gantt-task-react";
import "gantt-task-react/dist/index.css";

//=========3RD LIBRARY========//
import _ from "lodash";
import { DashboardContext } from "../../../context/DashboardContext";
import { getDataTimeLine } from "../../../helpers/utils/Utils";

const Index = (props) => {
  const { table, viewTimelineSelected } = useContext(DashboardContext);
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    if (!table) return;
    const data = getDataTimeLine(table);
    if (!data.length) return;
    setTasks(data);
  }, [table]);

  function getStartEndDateForProject(tasks, projectId) {
    const projectTasks = tasks.filter((t) => t.project === projectId);
    let start = projectTasks[0].start;
    let end = projectTasks[0].end;

    for (let i = 0; i < projectTasks.length; i++) {
      const task = projectTasks[i];
      if (start.getTime() > task.start.getTime()) {
        start = task.start;
      }
      if (end.getTime() < task.end.getTime()) {
        end = task.end;
      }
    }
    return [start, end];
  }

  const handleTaskChange = (task) => {
    console.log("On date change Id:" + task.id);
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project =
        newTasks[newTasks.findIndex((t) => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map((t) =>
          t.id === task.project ? changedProject : t
        );
      }
    }
    setTasks(newTasks);
  };

  return (
    <div className="p-4">
      {tasks.length ? (
        <Gantt
          locale="id"
          tasks={tasks}
          viewMode={viewTimelineSelected.value}
          // onDateChange={handleTaskChange}
          listCellWidth=""
          //   barBackgroundColor="red"
          barProgressColor="rgb(76, 175, 80)"
        />
      ) : null}
    </div>
  );
};

export default Index;
