import React, { useContext, useEffect, useState, useRef } from 'react'

import { Style } from "../../helpers/style/Style"
import { initialString, stripHtmlAnd, asyncRequest } from '../../helpers/utils/Utils'

//=========BOOTSTARP INITIAL========//
import { Col, Row, Image } from 'react-bootstrap'

import WorkspaceIcon from '../../img/workspace.svg'
import timeIcon from '../../img/time.svg'
//=========CONTEXT========//
import { DashboardContext } from "../../context/DashboardContext"
import { MytaskContext } from '../../context/MytaskContext'

//=========COMPONENT========//
import Table from '../board/Table' 
import ContainerKanban from '../board/kanban/Container'

import ContentEditable from 'react-contenteditable'

const Mytask = () => {
    const { params, modalSpinner, tableMyTask, viewType, width, getTableMyTask, table } = useContext(DashboardContext)
    const { selectedMytask } = useContext(MytaskContext)
    const [tableComponent, setTableComponent] = useState([]) 

    const isFirstRun = useRef(true) 

    useEffect(()=>{  
        
        const getData = async()=>{
            console.log("getting data___")
            await getTableMyTask()
        } 
        getData()
     
    },[])
    
    useEffect(()=>{ 
        setTableComponent([])
        if(!table.length) return
        let componentTmp = []   
        table.map((item, key) =>{  
            componentTmp.push(<Table item={item} index = {key} />)
        })
        setTableComponent(componentTmp) 

        if(isFirstRun.current){ 
            isFirstRun.current = false
            return
        }  
        
    },[table])

    const provideData = [
        {
            workspace: 'workspace 1',
            task: [{
                title: 'buat content youtube',
                board: 'board',
                level: 'medium',
                color: '#CA7E07',
                teams: 'Nama teams',
                duedate: '10 mei 20201'
            },
            {
                title: 'buat content youtube',
                board: 'board',
                teams: 'Nama teams',
                level: 'low',
                color: '#2778FC',
                duedate: '10 mei 20201'
            },]
        }
        ,
        {
            workspace: 'workspace 2',
            task: [{
                title: 'buat content youtube',
                board: 'board',
                teams: 'Nama teams',
                level: 'high',
                color: '#DC3444',
                duedate: '10 mei 20201'
            }]
        }
    ]
    
    return (
        <React.Fragment>
            {/* {selectedMytask && selectedMytask.value && !modalSpinner ? */}
            <Row className="vw-100 ">
                <Col md={12}>
                    <div className="h-100 min-vh-100 overflow-scroll overflow-x-hidden" style={{ marginLeft: width.board }}>
                        <Col className="m-4">
                            <div className="p-3 cursor-pointer d-flex ">
                                <span style={Style.circleTextWorkspace}>
                                    <span style={Style.circleTextWorkspaceSpan}>{selectedMytask.label ? initialString(selectedMytask.label) : "MT"}</span>
                                </span>
                                <div className="d-flex flex-column">
                                    <ContentEditable
                                        html={selectedMytask.label ? selectedMytask.label : "My Task"}
                                        className="font-24 font-weight-bold ml-2 d-inline content-editable single-line"
                                        disabled={params.permission_id < 3 ? false : true}
                                    //onBlur={()=>{updateDataWorkspace(true)}}
                                    //ref={(r) => titleRef.current = r}
                                    />
                                    <span className="font-13 font-weight-light pl-3 single-line">Your Task in All Workspage</span>
                                </div>

                            </div>
                            {/* <Row className="no-gutters pt-3">
                                <Col md={12} >
                                    <div style={{ overflowX: 'auto', width: '100%', display: 'flex' }}>
                                        {provideData.map(item => {
                                            return (

                                                <div className="col-3 " style={{ padding: '0px', margin: '0px' }}>
                                                    <div className="d-flex justify-content-evenly border-bottom pr-3 pb-3 pt-3">
                                                        <Image src={WorkspaceIcon} ></Image>
                                                        <span className="font-15 ml-2">&nbsp;{item.workspace}</span>
                                                    </div>
                                                    <div className="p-2">
                                                        {item.task.map(i => {
                                                            return (
                                                                <div className="w-100 card mt-4">
                                                                    <div className="card-body ">
                                                                        <h6 className="card-title">{i.teams}</h6>
                                                                        <div className="border p-2">
                                                                            <p className="card-text text-muted">nama board</p>
                                                                            <div className="d-flex flex-row">
                                                                                <div className="p-1 text-light text-center"
                                                                                    style={{ backgroundColor: i.color, width: '40%' }}>{i.level}</div>
                                                                                <div className="ml-1 p-1 text-center"
                                                                                    style={{ backgroundColor: '#F2F2F2', width: '60%' }}
                                                                                >
                                                                                    <Image src={timeIcon} ></Image>
                                                                                    <span className="ml-2">{i.duedate}</span>
                                                                                </div>
                                                                            </div>
                                                                            <p className="card-text mt-2 text-muted">Task</p>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            )
                                                        })}

                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </Col>
                            </Row> */}
                        </Col>

                        {/* table */}
                        <Col className={viewType !== "Default" ? "bg-grey overflow-scroll p-0" : "overflow-scroll" } id="section-to-print-board">  
                            {viewType !== "Default" ? <ContainerKanban/> :
                                tableComponent.map((content,index)=>{
                                    return (
                                        content === undefined ? "" :
                                            <React.Fragment key={index}>
                                                {React.cloneElement(content)} 
                                            </React.Fragment>
                                        )
                                    })
                            }  
                        </Col> 
                        
                    </div>
                </Col>
            </Row>
            {/* : null} */}
        </React.Fragment>
    )
}

export default Mytask