import React, {useEffect, useState, useContext, useRef} from 'react'
import {Modal, Row, Col, Form, Spinner,Tabs, Tab} from 'react-bootstrap'  

//=========3RD LIBRARY========//
import _ from 'lodash' 
import {NotificationManager} from 'react-notifications' 
import {asyncRequest } from '../../helpers/utils/Utils' 
import CreateTeamMember from '../teams/CreateTeamMember' 

//=========ICON LIST========//   
import {DashboardContext} from "../../context/DashboardContext" 


const ModalMember = (props) =>{ 
    const ref = useRef()  
    const {params, selectedTeamsSetting, inviteTeamMember, getMember, member, showModalPricing} = useContext(DashboardContext)         
    const [isLoading, setLoading] = useState(false)     
    const [alert, setAlert] = useState({msg: 'Email invitation sent.', isShow: false, variant: "success"})
 
    useEffect(()=>{
        setAlert({...alert, isShow: false})
    },[props])

    const handleInviteMember = async() =>{
        if(ref.current.value !== ""){ 
            let email = ref.current.value
            let split = email.split(",")
            let invalid = false
            split.some(item =>{
                item = item.replace(",","") 
                item = item.replace(" ","")
                if(!validateEmail(item)){
                    NotificationManager.error(`Invalid email ${item}.`)
                    invalid = true
                    return
               }
               if(_.some(member, (select) => {return select.email === item})){
                    NotificationManager.error(`${item} already invited.`)
                    invalid = true
                    return
               }
            })  
            if(invalid) return

            setLoading(true)
            let param = {}
            param.to = ref.current.value
            param.team = selectedTeamsSetting.name
            param.team_id = selectedTeamsSetting.id
            param.user_id = params.id
            const result =  await inviteTeamMember(param)
            asyncRequest(1000).then(async()=>{ 
                setLoading(false)
                if(result.message === "limit"){
                    showModalPricing(true)
                    return
                }
                if(result.status === 200){ 
                    NotificationManager.success(`Invitation sent.`)
                    await getMember(selectedTeamsSetting.id) 
                }
                else NotificationManager.error(result.message)

            })
          
        } 
    } 
 
    function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    return (
        <Modal
        {...props}
        size=""
        id="modal-add-member" 
        aria-labelledby="contained-modal-title-vcenter"> 
             
            <Modal.Body className="mt-1 ml-2 mr-2">  
                <Tabs defaultActiveKey="invite" transition={false} id="tab-member-team">
                    <Tab eventKey="invite" title="Invite Member">  
                        <Row className="pt-3 pb-3">
                            <Col md="8" className="pr-0">  
                                <Form.Group controlId="formBasicEmail" className="mt-4"> 
                                    <span className="font-14 font-w-400">Invite with email</span>
                                    <Form.Control ref = {ref} className="font-14 mt-1" name="email" type="email" placeholder="Enter email address" required  /> 
                                </Form.Group> 
                            </Col>
                            <Col md="4" className="pl-0"> 
                            <span className="invite-members-button mt-5" onClick={()=>handleInviteMember()}> 
                                    <span className="invite-members-span">
                                    {isLoading ?
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            />
                                        : "Send" 
                                    }
                                    </span>
                                </span> 
                            </Col>
                        </Row>
                        
                       
                    </Tab>
                    <Tab eventKey="create" title="Create New"> 
                        <CreateTeamMember onHide= {props.onHide}/>
                    </Tab>
                </Tabs>
             
            </Modal.Body>
            
        </Modal>
    )
}

export default ModalMember