import React, {useEffect, useState, useContext} from 'react'

import {Modal, Button, Spinner } from 'react-bootstrap' 
import _ from 'lodash'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' 
import {DashboardContext} from "../../context/DashboardContext"  
import {asyncRequest } from '../../helpers/utils/Utils' 

const ModalDesc = (props) =>{  
    const {boardActive, params, updateBoard} = useContext(DashboardContext)  
    const [valueDesc, setValueDesc] = useState("") 
    const [isLoading, setLoading] = useState(false) 

    const modules = {
        toolbar: [ 
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}],
          
        ],
      }

    const formats = [ 
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 
    ]

    useEffect(()=>{ 
        setValueDesc(boardActive.description)
    },[props]) 

    

    const handleApply = async()=>{  
        setLoading(true)
        let param = {}
        param.id = boardActive.id
        param.title = boardActive.title
        param.description = valueDesc
        param.permission_id = boardActive.permissionId 
        param.is_favorites = boardActive.is_favorites 
        param.updated_by = params.id
        await updateBoard(param)  
        props.onHide() 
        setLoading(false)
    }
 

    return (
        <Modal
        {...props}
        size="md"  
        centered
        aria-labelledby="contained-modal-title-vcenter" > 
            <Modal.Header>
                <span className="modal-title">
                Add board description 
                </span>
            </Modal.Header> 
            <Modal.Body className="m-2"> 
            
                <ReactQuill className="mt-1 ml-1" theme="snow"
                    value={valueDesc || ''} onChange={setValueDesc}
                    modules={modules} 
                    defaultValue={boardActive.description}
                    formats={formats} 
                />  
                    
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-cancel" onClick={props.onHide}>Cancel</Button>  
                    <Button 
                        disabled={isLoading}
                        onClick={handleApply}
                        className="btn-yes text-white"
                        type="submit"
                        >
                            {isLoading ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    />
                                : "Save" 
                            }
                    </Button> 
            </Modal.Footer> 
        </Modal>
    )
}

export default ModalDesc