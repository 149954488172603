import React from 'react' 
import './App.css'
import './App.scss'
import {MainRoutes} from "./router/Index"
import {BrowserRouter as Router} from "react-router-dom" 
import 'font-awesome/css/font-awesome.min.css'

function App() {
  return ( 
      <Router>
        <MainRoutes/>
      </Router> 
  )
}

export default App
