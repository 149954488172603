import React, {useEffect, useState, useContext, useRef} from 'react'
import {Modal, Button, Spinner } from 'react-bootstrap'  
import {DashboardContext} from "../../context/DashboardContext" 

const ModalAddBoard = (props) =>{ 
    const [isLoading, setLoading] = useState(false)  
    const [alert, setAlert] = useState({msg: '', variant: 'success', hidden: true}) 
    const {deleteBoard, showDelete} = useContext(DashboardContext)  

    useEffect(()=>{
        setAlert({...alert, hidden: true})   
    },[props])
 
    const actionDelete = async () => {
        props.onHide()
        await deleteBoard(showDelete.item)
       
    } 

    return (
        <Modal
        {...props}
        size=""
        centered
        aria-labelledby="contained-modal-title-vcenter"
        > 
            <Modal.Header>  
                    <span className="modal-title">
                        Confirmation
                    </span>
            </Modal.Header>
            <Modal.Body className="m-2"> 
                <span className="font-16">Delete  {showDelete.item ? showDelete.item.type === 2?  `Dashboard ${showDelete.item.title}` :  ` Board ${showDelete.item.title}` : ""} ? </span>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-cancel" onClick={props.onHide}>Cancel</Button>  
                <Button 
                    disabled={isLoading}
                    className="btn-yes text-white"
                    type="submit"
                    onClick={()=> actionDelete()}
                    >
                     {isLoading ?
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                            : "Delete" 
                        }
                </Button> 
            </Modal.Footer>  
        </Modal>
    )
}

export default ModalAddBoard