import React, { createContext, useState, useEffect, useRef, useReducer } from "react"
import { MakeRequest, MakeRequestDownload } from "../api/Api"
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import { getParameterByName } from "../helpers/utils/Utils"
import { ChartReducer } from '../reducers/DashboardReducer'
import socketIOClient from "socket.io-client"

const SettingContext = createContext()
const SettingContextProvider = (props) => {
    const history = useHistory()
    const isFirstRunDashboard = useRef(true)
    const isFirstRun = useRef(true)
    const [addNewWorkspace, setAddNewWorkspace] = useState(false)
    const [addNewBoard, setNewBoard] = useState(false)
    const [addNewMember, setNewMember] = useState(false)
    const [addNewDashboard, setNewDashboard] = useState(false)
    const [params, setParams] = useState({ column_order: "", order: "", workspace_id: "", indexWorkspaceActive: 0, indexBoardActive: 0 })
    const [workspace, setWorkspace] = useState([])
    const [workspaceProfile, setWorkspaceProfile] = useState([])
    const [board, setBoard] = useState([])
    const [dashboard, setDashboard] = useState([])
    const [selectedBoard, setSelectedBoard] = useState([])
    const [boardUser, setBoardUser] = useState([])
    const [permission, setPermission] = useState([])
    const [rating, setRating] = useState(0)
    const [table, setTable] = useState([])
    const [tableSearch, setTableSearch] = useState([])
    const [member, setMember] = useState([])
    const [teams, setTeams] = useState([])
    const [workspaceTeams, setWorkspaceTeams] = useState([])
    const [selectedTeams, setSelectedTeams] = useState(false)
    const [priority, setPriority] = useState([])
    const [status, setStatus] = useState([])
    const [flagNewBoard, setFlagNewBoard] = useState("")
    const [boardActive, setBoardActive] = useState([])
    const [itemActive, setItemActive] = useState([])
    const [itemAdd, setItemAdd] = useState(false)
    const [subItemAdd, setSubItemAdd] = useState(false)
    const [itemUpdate, setItemUpdate] = useState(false)
    const [itemDelete, setItemDelete] = useState(false)
    const [columnUpdate, setColumnUpdate] = useState(false)
    const [columnAdd, setColumnAdd] = useState(false)
    const [columnDelete, setColumnDelete] = useState(false)
    const [groupDelete, setGroupDelete] = useState(false)
    const [boardUpdate, setBoardUpdate] = useState(false)
    const [selectedWorkspace, setSelectedWorkspace] = useState(false)
    const [flagNewMember, setFlagNewMember] = useState(false)
    const [countNotif, setCountNotif] = useState(0)
    const [showMember, setShowMember] = useState(false)
    const [showMemberTeam, setShowMemberTeam] = useState(false)
    const [showPA, setShowPA] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [showPriority, setShowPriority] = useState(false)
    const [showStatus, setShowStatus] = useState(false)
    const [showComment, setShowComment] = useState(false)
    const [showNotif, setShowNotif] = useState(false)
    const [showLogs, setShowLogs] = useState(false)
    const [showDetailKanban, setShowDetailKanban] = useState({ isShow: false, data: [] })
    const [showChooseBoard, setShowChooseBoard] = useState(false)
    const [editDesc, setEditDesc] = useState(false)
    const [setting, setSetting] = useState()
    const [showPermissionBoard, setShowPermissionBoard] = useState(false)
    const [showWidget, setShowWidget] = useState(false)
    const [showChooseWidget, setShowChooseWidget] = useState(false)
    const [modeDashboard, setModeDashboard] = useState("")
    const [modalSpinner, setModalSpinner] = useState(false)
    const [printDashboard, setPrintDashboard] = useState(false)
    const [permissionDashboard, setPermissionDashboard] = useState(false)
    const [boardDashboardSelected, setBoardDashboardSelected] = useState(false)
    const [fullscreenWidget, setFullscreenWidget] = useState({ fullscreen: false, dashboard: "", key: 0 })
    const [color, setColor] = useState({ content: 'rgb(245, 246, 248)', header: 'white', color: 'black', chart: 'white' })
    const [valueSearch, setValueSearch] = useState({ value: "", type: "" })
    const [valueFilter, setValueFilter] = useState({ value: "", column: "" })
    const [valueSort, setValueSort] = useState({ value: "", column: "" })
    const [socket, setSocket] = useState(false)
    const [viewType, setViewType] = useState("Default")
    const [selectedColumnKanban, setSelectedColumnKanban] = useState(false)
    const [selectedTeamsSetting, setSelectedTeamsSetting] = useState(false)
    const initialChart = {
        type: 'column', xAxis: 'Status', yAxis: 'Count Items', board: [], group: [], cidUpdate: 0,
        selectedBoard: [], title: 'Chart', column: {}, isUpdate: false, widget: { selectedBoard: [] },
        selectedIdBoard: [], selectedIdGroup: [], sortBy: false, showUndefined: false, xAxisColumnId: []
    }
    const [stateChart, dispatchChart] = useReducer(ChartReducer, initialChart)


    //===========WORKSPACE============//
    const getWorkspace = async (data) => {
        setValueSearch({ value: "", type: "" })
        setValueSort({ value: "", type: "" })
        setValueFilter({ value: "", type: "" })
        let param = {}
        param.team_id = data.team_id
        param.user_id = data.id
        param.column_order = "id"
        param.permission_id = data.team.permission_id
        param.order = "asc"
        // setModalSpinner(true)
        const result = await MakeRequest('post', 'workspace-read', param)
        if (result.status === 200) {
            //=======GET ONLY WORKSPACE ATTRIBUTE=========//
            let dataWorkspace = []
            _.each(result.data, (item, key) => {
                if (item.is_active) {
                    //=======GET WORKSPACE ATTRIBUTE PUSH TO DATA VARIABLE=========//
                    _.each(item.workspace, (workspace) => {
                        workspace.permission_id = item.permission_id
                        workspace.is_workspace_active = item.is_active
                        let isExist = _.some(dataWorkspace, function (i) {
                            return i.id == workspace.id
                        })
                        if (!isExist) dataWorkspace.push(workspace)

                    })
                }
            })
            setWorkspace(dataWorkspace)
            if (dataWorkspace.length === 0) {
                setSelectedWorkspace({})
                setBoardActive({ title: 'blank' })
                setBoard([])
            }
            // await getMember(data.team_id)
        }
        // setModalSpinner(false)
    }

    const getWorkspaceProfile = async () => {
        let param = {}
        param.user_id = params.id
        setModalSpinner(true)
        const result = await MakeRequest('post', 'workspace-read-profile', param)
        if (result.status === 200) {
            //=======GET ONLY WORKSPACE ATTRIBUTE=========// 
            let dataWorkspace = []
            _.each(result.data, (item, key) => {
                if (item.is_active) {
                    //=======GET WORKSPACE ATTRIBUTE PUSH TO DATA VARIABLE=========//
                    _.each(item.workspace, (workspace) => {
                        workspace.permission_id = item.permission_id
                        workspace.is_workspace_active = item.is_active
                        let isExist = _.some(dataWorkspace, function (i) {
                            return i.id == workspace.id
                        })
                        if (!isExist) dataWorkspace.push(workspace)

                    })
                }
            })
            setWorkspaceProfile(dataWorkspace)

        }

        let debouncer = setTimeout(() => {
            setModalSpinner(false)
        }, 500);
        return () => {
            clearTimeout(debouncer);
        }
    }

    const getSetting = async (param) => {  
        const result = await MakeRequest('post', 'notif', {user_id: param}) 
        if(result.status === 200 && result.data) setSetting(result.data)
        return result
    }

    const updateSetting = async (param) => { 
        param.user_id = params.id
        const result = await MakeRequest('post', 'notif-update', param)
        return result
    }

    const insertWorkspace = async (params) => {
        const result = await MakeRequest('post', 'workspace-create', params)
        return result
    }

    const getWorkspaceTeams = async (id) => {
        let param = { id: id }
        const result = await MakeRequest('post', 'workspace-users-read', param)
        if (result.status === 200) setWorkspaceTeams(result.data)
    }

    const updateWorkspaceTeams = async (param) => {
        const result = await MakeRequest('post', 'workspace-users-update', param)
        return result
    }

    const createWorkspaceTeams = async (param) => {
        const result = await MakeRequest('post', 'workspace-users-create', param)
        return result
    }

    const updateWorkspace = async (param) => {
        const result = await MakeRequest('post', 'workspace-update', param)
        return result
    }

    //===========END WORKSPACE============//

    //===========BOARD============//

    const createBoard = async (params) => {
        setSelectedBoard([])
        const result = await MakeRequest('post', 'board-create', params)
        return result
    }

    const getBoard = async (fromDelete = false, fromDuplicate = false) => {
        const result = await MakeRequest('post', 'board-read', params)
        if (result.status === 200) {
            setBoardActive([])
            //=======GET ONLY BOARD ATTRIBUTE=========//
            let dataBoard = [], idUrl = params.id_url ? parseInt(params.id_url) : -1
            let active = params.indexBoardActive
            _.each(result.data, (item, key) => {
                //=======GET BOARD ATTRIBUTE PUSH TO DATA VARIABLE=========// 
                _.each(item.board, (board) => {
                    board.is_owner = false
                    if (board.created_by === params.id) board.is_owner = true
                    if (idUrl === board.id) active = key

                    let isExist = _.some(dataBoard, function (i) {
                        return i.id == board.id
                    })
                    if (!isExist) dataBoard.push(board)
                })
            })
            setBoard(dataBoard)
            //===========IF FROM DELETE DEFAULT BOARD ACTIVE IS INDEX 0============//
            if (dataBoard.length > 0) setBoardActive(dataBoard[fromDelete ? 0 : fromDuplicate ? active + 1 : active])
            else setBoardActive({ title: 'blank' })

        }
    }

    const getSelectedBoard = async () => {
        setSelectedBoard([])
        const result = await MakeRequest('post', 'selected-board-read', { selected_board: boardActive.selected_board })
        if (result.status === 200) setSelectedBoard(result.data)
    }

    const getBoardUser = async () => {
        const result = await MakeRequest('post', 'board-users', { board_id: boardActive.id })
        if (result.status === 200) {
            //=======GET ONLY BOARD ATTRIBUTE=========//
            let dataBoard = []
            _.each(result.data, (item) => {
                //=======GET BOARD ATTRIBUTE PUSH TO DATA VARIABLE=========//
                dataBoard.push(item.users)
            })
            setBoardUser(dataBoard);

        }
    }

    const duplicateBoard = async () => {
        let paramsInsert = boardActive
        paramsInsert.user_id = params.id
        paramsInsert.title = "Duplicate of " + paramsInsert.title
        paramsInsert.board_id_source = boardActive.id
        paramsInsert.is_duplicate = true
        setModalSpinner(true)
        let result = await createBoard(paramsInsert)
        if (result.status === 200) {
            paramsInsert.board_id = result.data.id
            await createBoardBulk(paramsInsert)
        }
        setModalSpinner(false)
    }


    const deleteBoard = async (selectedItem) => {
        setModalSpinner(true)
        const result = await MakeRequest('post', 'board-delete', selectedItem)
        if (result.status === 200) await getBoard(true)
        setModalSpinner(false)

    }

    const updateBoard = async (boardParam, isRefresh = true) => {
        const result = await MakeRequest('post', 'board-update', boardParam)
        if (result.status === 200 && isRefresh) return getBoard()

    }

    const createBoardBulk = async (param, isDuplicate = false) => {
        setModalSpinner(true)
        const result = await MakeRequest('post', 'board-create-bulk', { data: param })
        if (result.status === 200) {
            await getBoard(false, false)
        }
        setModalSpinner(false)
        return result
    }

    const updateBoardUser = async (boardParam) => {
        let users = _.map(boardParam, o => _.omit(o, ['value', 'label']));
        let users_id = _.map(boardParam, (item) => {
            return item.user_id
        });
        let param = { users: users, users_id: users_id, board_id: boardActive.id }
        const result = await MakeRequest('post', 'board-users-update', param)
        return result

    }

    const uploadFile = async (param) => {
        const result = await MakeRequest('post', 'file-upload', param)
        if (result.status === 200) return result
    }

    const downloadFile = async (param) => {
        const result = await MakeRequestDownload('get', 'file-download?id=' + param)
        return result
    }

    const deleteFile = async (id) => {
        const result = await MakeRequest('post', 'file-delete', id)
        if (result.status === 200) return result
    }

    const getPriority = async () => {
        const result = await MakeRequest('post', 'priority-read', { board_id: boardActive.id })
        if (result.status === 200) setPriority(result.data)
    }

    const updatePriority = async (data) => {
        const result = await MakeRequest('post', 'priority-update', { data, board_id: boardActive.id })
        if (result.status === 200) return result
    }

    const getStatus = async () => {
        const result = await MakeRequest('post', 'status-read', { board_id: boardActive.id })
        if (result.status === 200) setStatus(result.data)
    }

    const updateStatus = async (data) => {
        const result = await MakeRequest('post', 'status-update', { data, board_id: boardActive.id })
        if (result.status === 200) return result
    }

    const inviteMember = async (email) => {
        const result = await MakeRequest('post', 'invite-member', { user_id: params.id, to: email, board_id: boardActive.id })
        return result
    }

    //===========END BOARD============//

    //===========DASHBOARD============//

    const createDashboard = async (params, isDuplicate = false) => {
        setModalSpinner(true)
        const result = await MakeRequest('post', 'dashboard-create', params)
        if (result.status === 200) {
            if (isDuplicate) {
                await getDashboard()
            } else {
                await getBoard()
            }
        }
        setModalSpinner(false)
        return result
    }

    const createDashboardBulk = async (param, isDuplicate = false) => {
        setModalSpinner(true)
        const result = await MakeRequest('post', 'dashboard-create-bulk', { data: param })
        if (result.status === 200) {
            if (isDuplicate) {
                await getDashboard()
            } else {
                await getBoard(false, false)
            }
        }
        setModalSpinner(false)
        return result
    }

    const duplicateDashboard = async () => {
        let paramsInsert = boardActive
        paramsInsert.user_id = params.id
        paramsInsert.title = "Duplicate of " + paramsInsert.title
        paramsInsert.selected_board = _.map(selectedBoard, "id")
        setModalSpinner(true)
        let result = await createBoard(boardActive)
        if (result.status === 200) {
            paramsInsert.dashboard.map(item => {
                item.board_id = result.data.id
                item.created_by = params.id
            })

            let param = _.map(paramsInsert.dashboard, o => _.omit(o, ['id']));
            await createDashboardBulk(param)
        }
        setModalSpinner(false)
    }

    const updateDashboard = async (params, isRefresh = false) => {
        const result = await MakeRequest('post', 'dashboard-update', params)
        if (result.status === 200 && isRefresh) {
            setModalSpinner(true)
            await getDashboard()
            let debouncer = setTimeout(() => {
                setModalSpinner(false)
            }, 500);
            return () => {
                clearTimeout(debouncer);
            }
        }
        return result
    }

    const deleteDashboard = async (params) => {
        setModalSpinner(true)
        const result = await MakeRequest('post', 'dashboard-delete', params)
        if (result.status === 200) await getDashboard()
        setModalSpinner(false)
        return result

    }

    const getDashboard = async () => {
        const result = await MakeRequest('post', 'dashboard-read', { board_id: boardActive.id })
        if (result.status === 200) return setDashboard(result.data)
    }

    const createWidget = async (param) => {
        let info = { id: stateChart.idUpdate, title: param.type, created_by: params.id, team_id: params.team.id, board_id: boardActive.id, workspace_id: boardActive.workspace_id, permission_id: boardActive.permission_id }
        let mod = boardActive.dashboard.length % 2
        let column = {
            "x": mod === 0 ? 0 : 4,
            "y": mod === 0 ? 0 : 4,
            "w": 3,
            "h": 2,
            minW: 1,
            minH: 1,
            "i": new Date() + "1",
            "static": false,
            type: param.type,
            value: ""
        }
        let data = Object.assign(info, { column: column }, { widget: {} }, { chart: [] })
        let result = await createDashboard(data, true)
        return result
    }

    //===========END DASHBOARD============//

    //===========TABLE============//

    const getTable = async () => {
        let params = { board_id: boardActive.id }
        const result = await MakeRequest('post', 'table-read', params)
        if (result.status === 200) {
            setTable(result.data)
            setTableSearch(result.data)
        }
    }

    const updateTable = async (id, rows) => {
        let param = { id: id, rows: rows, user_id: params.id, username: params.username }
        const result = await MakeRequest('post', 'table-update', param)
        if (result.status === 200) return result
    }

    const updateTableKanban = async (id, kanban) => {
        let param = { id: id, kanban: kanban, user_id: params.id, username: params.username }
        const result = await MakeRequest('post', 'table-update-kanban', param)
        if (result.status === 200) return result
    }

    const addColumnTable = async (param) => {
        const result = await MakeRequest('post', 'table-column-add', param)
        if (result.status === 200) return result
    }

    const updateColumnTable = async (param) => {
        const result = await MakeRequest('post', 'table-column-update', param)
        if (result.status === 200) return result
    }

    const deleteColumn = async (param) => {
        const result = await MakeRequest('post', 'table-column-delete', param)
        if (result.status === 200) return result
    }

    const createRowsTable = async (param) => {
        const result = await MakeRequest('post', 'table-create-rows-item', param)
        if (result.status === 200) return result
    }

    const createSubItem = async (param) => {
        const result = await MakeRequest('post', 'table-create-subs-item', param)
        if (result.status === 200) return result
    }

    const deleteTable = async (param) => {
        const result = await MakeRequest('post', 'table-delete', param)
        if (result.status === 200) return result
    }

    const deleteGroup = async (param) => {
        const result = await MakeRequest('post', 'table-delete-group', param)
        if (result.status === 200) return result
    }

    const uuid = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        })
    }

    const addNewGroup = async () => {
        let param = {}
        param.board_id = boardActive.id
        let header = [
            { type: 'option', name: '' },
            { type: 'text', name: 'New Group', id: uuid() },
            { type: 'person', name: 'Assigned To', id: uuid() },
            { type: 'priority', name: 'Priority', id: uuid() },
            { type: 'status', name: 'Status', id: uuid() },
            { type: 'duedate', name: 'Due Date', id: uuid() },
            { type: 'timeline_duedate', name: 'Timeline', id: uuid() },
            { type: 'progress', name: 'Progress' },
            { type: 'rating', name: 'Rating', id: uuid() },
            { type: 'add', name: '', created_by: params.username },
        ]
        if (table.length) header = table[0].rows[0]
        header[1].name = "New Group"
        let rows = [header]

        let items = _.cloneDeep(header)
        _.map(items, (val, idx) => {
            val.name = ""
            val.id = uuid()
            if (idx === 1) val.name = "Item 1"
            if (val.type === "rating") val.name = "0"
            if (val.type === "progress") val.name = "0"
            if (val.type === "creation_log") val.name = params.username
            if (val.type === "add") {
                val.created_by = params.username
                val.type = ""
            }
        })

        rows.push(items)
        param.rows = rows
        param.user_id = params.id
        setModalSpinner(true)
        const result = await MakeRequest('post', 'table-create', param)
        if (result.status === 200) getTable()
        setModalSpinner(false)
    }

    const duplicateGroup = async (rows) => {
        let param = {}
        param.board_id = boardActive.id
        let groupName = rows[0][1].name
        rows[0][1].name = "Copy of " + groupName
        rows.map((item, index) => {
            if (index === 0) return
            item.map((row, key) => {
                if (key === 1) return
                row.name = ""
            })
        })
        param.rows = rows
        param.user_id = params.id

        setModalSpinner(true)
        const result = await MakeRequest('post', 'table-create', param)
        if (result.status === 200) {
            await getTable()
            let debouncer = setTimeout(() => {
                setModalSpinner(false)
            }, 500);
            return () => {
                clearTimeout(debouncer);
            }
        }
    }

    //===========END TABLE============//

    //===========MEMBER============//
    const getMember = async (teamId) => {
        let param = { team_id: teamId }
        const result = await MakeRequest('post', 'teams-user-read', param)
        if (result.status === 200) setMember(result.data)
    }

    const getTeams = async (id) => {
        let param = { id: id }
        const result = await MakeRequest('post', 'teams-read', param)
        if (result.status === 200) setTeams(result.data)
        return result
    }

    const createTeams = async (param) => {
        const result = await MakeRequest('post', 'teams-create', param)
        return result
    }

    const updateTeams = async (param) => {
        const result = await MakeRequest('post', 'teams-update', param)
        return result
    }

    const updateMember = async (param) => {
        const result = await MakeRequest('post', 'teams-user-update', param)
        return result
    }

    const inviteTeamMember = async (param) => {
        const result = await MakeRequest('post', 'teams-invite-member', param)
        return result
    }


    //===========END MEMBER============//

    //===========PERMISSION============//
    const getPermission = async (teamId) => {
        const result = await MakeRequest('post', 'permission-read')
        if (result.status === 200) setPermission(result.data)
    }

    //===========END MEMBER============//


    //===========NOTIF============//
    const getNotif = async (id, board_id = null, row_id = null) => {
        let team_id = params.team ? params.team.id : 0
        const result = await MakeRequest('post', 'notification-read', { id, board_id, row_id, team_id })
        return result
    }

    const getNotifCount = async (id) => {
        let team_id = params.team ? params.team.id : 0
        const result = await MakeRequest('post', 'notification-count', { id, team_id })
        if (result.status === 200) setCountNotif(result.data)
    }

    //===========END NOTIF============//

    const setupSocket = (id) => {
        let pathUrl = window.location.origin
        if (window.location.hostname === 'localhost')
            pathUrl = "http://localhost:4000"

        let socket = socketIOClient(pathUrl, { query: `id=${id}` });

        setSocket(socket)

        socket.on("notification", data => {
            // NotificationManager.info("You have new notifications.") 
            let debouncer = setTimeout(() => {
                // getNotifCount(id)
            }, 1000);
            return () => {
                clearTimeout(debouncer);
            }

        })

    }

    const sendNotif = (data) => {
        socket.emit('send notif', data)
    }

    useEffect(() => {
        let data = JSON.parse(localStorage.getItem('saatkerja'))
        if (data === null) history.push('/')
        else {
            let idUrl = getParameterByName("id")
            let workspaceUrl = getParameterByName("workspace")
            data.user_id = data.id
            data.id_url = idUrl
            data.workspace_url = workspaceUrl
            let param = Object.assign(data, params)
            param.team.value = param.team.id
            setSelectedTeams(param.team)
            setParams(param)
            getWorkspace(param)
            setupSocket(param.id)
            // getNotifCount(param.id)
            getTeams(param.id)
            getSetting(param.id)
        }
    }, [])

    useEffect(() => {
        if (flagNewBoard !== "") {
            setModalSpinner(true)
            const getdataBoard = async () => {
                await getBoard()
                setModalSpinner(false)
            }
            getdataBoard()
        }

        setTable([])
    }, [flagNewBoard])


    useEffect(() => {
        if (boardActive.id !== undefined) {
            setTable([])

            const getDataTable = async () => {
                setModalSpinner(true)
                if (boardActive.type === 1) {
                    await getMember(boardActive.team_id)
                    await getTable()
                    await getPriority()
                    await getStatus()
                    await getWorkspaceTeams(boardActive.workspace_id)

                }
                if (boardActive.type === 2) {
                    setDashboard([])
                    await getSelectedBoard()
                    await getDashboard()
                }
                await getBoardUser()
                setModalSpinner(false)
            }
            getDataTable()
        }

    }, [boardActive])

    useEffect(() => {
        if (itemAdd) {
            setModalSpinner(true)
            const doCreateRowsTable = async () => {
                await createRowsTable(itemAdd)
                if (itemAdd.refresh) await getTable()
                let debouncer = setTimeout(() => {
                    setModalSpinner(false)
                }, 500);
                return () => {
                    clearTimeout(debouncer);
                }
            }
            doCreateRowsTable()
        }

    }, [itemAdd])

    useEffect(() => {
        if (subItemAdd) {
            setModalSpinner(true)
            const doCreateRowsTable = async () => {
                await createSubItem(subItemAdd)
                if (subItemAdd.refresh) await getTable()
                let debouncer = setTimeout(() => {
                    setModalSpinner(false)
                }, 500);
                return () => {
                    clearTimeout(debouncer);
                }
            }
            doCreateRowsTable()
        }

    }, [subItemAdd])

    useEffect(() => {
        if (itemDelete) {
            setModalSpinner(true)
            const doDeleteTable = async () => {
                await deleteTable(itemDelete)
                if (itemDelete.refresh) await getTable()
                let debouncer = setTimeout(() => {
                    setModalSpinner(false)
                    // let msg = "Successfully deleted 1 item."  
                    // NotificationManager.success(msg) 
                }, 500);
                return () => {
                    clearTimeout(debouncer);
                }

            }
            doDeleteTable()
        }

    }, [itemDelete])



    useEffect(() => {
        if (itemUpdate) {

            const doUpdateTable = async () => {
                await updateTable(itemUpdate.id, itemUpdate.rows)
                if (itemUpdate.refresh) {
                    setModalSpinner(true)
                    await getTable()
                    setModalSpinner(false)
                }

            }

            const doRefreshTable = async () => {
                setModalSpinner(true)
                await getTable()
                setModalSpinner(false)
            }
            if (itemUpdate.refreshOnly)
                doRefreshTable()
            else doUpdateTable()


        }

    }, [itemUpdate])

    useEffect(() => {
        if (columnUpdate) {
            const doColumnTable = async () => {
                await updateColumnTable(columnUpdate)
                // if(columnUpdate.refresh || showDetailKanban.isShow) await getTable()
                // if(columnUpdate.refresh) await getTable()
            }
            doColumnTable()
        }

    }, [columnUpdate])

    useEffect(() => {
        if (columnAdd) {
            setModalSpinner(true)
            const doAddColumnTable = async () => {
                let result = await addColumnTable(columnAdd)
                let debouncer = setTimeout(() => {
                    setTable(result.data)
                    setModalSpinner(false)
                }, 500);
                return () => {
                    clearTimeout(debouncer);
                }
            }
            doAddColumnTable()
        }

    }, [columnAdd])

    useEffect(() => {
        if (columnDelete) {
            setModalSpinner(true)
            const doDeleteColumn = async () => {
                let result = await deleteColumn(columnDelete)
                let debouncer = setTimeout(() => {
                    setTable(result.data)
                    setModalSpinner(false)
                    // let msg = "Successfully deleted 1 column."  
                    // NotificationManager.success(msg) 
                }, 500);
                return () => {
                    clearTimeout(debouncer);
                }
            }
            doDeleteColumn()
        }

    }, [columnDelete])

    useEffect(() => {
        if (groupDelete) {
            setModalSpinner(true)
            const doDeleteGroup = async () => {
                await deleteGroup(groupDelete)
                if (groupDelete.refresh) await getTable()
                let debouncer = setTimeout(() => {
                    setModalSpinner(false)
                    // let msg = "Group was successfully deleted."
                    // NotificationManager.success(msg) 
                }, 500);
                return () => {
                    clearTimeout(debouncer);
                }
            }
            doDeleteGroup()
        }

    }, [groupDelete])

    useEffect(() => {
        if (flagNewMember) {
            const getDataMember = async () => {
                // await getMember(params.team_id) 
                await getBoardUser()
            }
            getDataMember()
        }

    }, [flagNewMember])


    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false
            return
        }
        if (viewType !== "Default") return
        setModalSpinner(true)
        const getDataTable = async () => {
            await getTable()
            let debouncer = setTimeout(() => {
                setModalSpinner(false)
            }, 500);
            return () => {
                clearTimeout(debouncer);
            }
        }
        getDataTable()
    }, [viewType])


    return (
        <SettingContext.Provider value={{
            addNewWorkspace,
            setAddNewWorkspace,
            updateSetting,
            params,
            setParams,
            workspace,
            addNewBoard,
            setNewBoard,
            board,
            getBoard,
            boardActive,
            setBoardActive,
            setFlagNewBoard,
            addNewGroup,
            table,
            tableSearch,
            itemUpdate,
            setItemUpdate,
            columnUpdate,
            setColumnUpdate,
            setColumnAdd,
            setColumnDelete,
            setGroupDelete,
            setItemAdd,
            setSubItemAdd,
            setItemDelete,
            deleteBoard,
            getWorkspace,
            insertWorkspace,
            addNewMember,
            member,
            flagNewMember,
            setFlagNewMember,
            setNewMember,
            duplicateGroup,
            setBoardUpdate,
            updateBoard,
            uploadFile,
            deleteFile,
            downloadFile,
            showMember,
            setShowMember,
            showMemberTeam,
            setShowMemberTeam,
            boardUser,
            updateBoardUser,
            priority,
            setPriority,
            showPriority,
            setShowPriority,
            updatePriority,
            inviteMember,
            getMember,
            getBoardUser,
            showStatus,
            status,
            setShowStatus,
            updateStatus,
            setStatus,
            showComment,
            setShowComment,
            showPermissionBoard,
            setShowPermissionBoard,
            itemActive,
            setItemActive,
            updateTable,
            editDesc,
            setEditDesc,
            addNewDashboard,
            setNewDashboard,
            createBoard,
            showChooseBoard,
            setShowChooseBoard,
            selectedBoard,
            showWidget,
            setShowWidget,
            createDashboard,
            dashboard,
            modeDashboard,
            setModeDashboard,
            showChooseWidget,
            setShowChooseWidget,
            updateDashboard,
            deleteDashboard,
            printDashboard,
            setPrintDashboard,
            duplicateDashboard,
            setDashboard,
            permissionDashboard,
            setPermissionDashboard,
            color,
            setColor,
            fullscreenWidget,
            setFullscreenWidget,
            valueSearch,
            setValueSearch,
            valueFilter,
            setValueFilter,
            createWidget,
            setTable,
            getPermission,
            permission,
            updateMember,
            teams,
            getTeams,
            createTeams,
            selectedTeams,
            setSelectedTeams,
            updateTeams,
            inviteTeamMember,
            selectedWorkspace,
            setSelectedWorkspace,
            workspaceTeams,
            setWorkspaceTeams,
            getWorkspaceTeams,
            updateWorkspaceTeams,
            updateWorkspace,
            createWorkspaceTeams,
            modalSpinner,
            setModalSpinner,
            boardDashboardSelected,
            setBoardDashboardSelected,
            stateChart,
            dispatchChart,
            duplicateBoard,
            workspaceProfile,
            getWorkspaceProfile,
            rating,
            setRating,
            showNotif,
            setShowNotif,
            sendNotif,
            getNotif,
            countNotif,
            getNotifCount,
            showLogs,
            setShowLogs,
            showDelete,
            setShowDelete,
            valueSort,
            setValueSort,
            viewType,
            setViewType,
            selectedColumnKanban,
            setSelectedColumnKanban,
            showDetailKanban,
            setShowDetailKanban,
            getTable,
            updateTableKanban,
            showPA,
            setShowPA,
            setWorkspace,
            selectedTeamsSetting,
            setSelectedTeamsSetting,
            getSetting,
            params,
            setting


        }}>
            {props.children}
        </SettingContext.Provider>
    )
}

export { SettingContext, SettingContextProvider }