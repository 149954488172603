import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  useReducer,
} from "react";
import { MakeRequest, MakeRequestDownload } from "../api/Api";
import _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { getParameterByName } from "../helpers/utils/Utils";
import { ChartReducer } from "../reducers/DashboardReducer";
import { MytaskContextProvider } from "./MytaskContext";
import socketIOClient from "socket.io-client";
import { NotificationManager } from "react-notifications";

const DashboardContext = createContext();
const DashboardContextProvider = (props) => {
  const history = useHistory();
  const isFirstRun = useRef(true);
  const [addNewWorkspace, setAddNewWorkspace] = useState(false);
  const [modalSeeAllWorkspace, setModalSeeAllWorkspace] = useState(false);
  const [addNewTeams, setNewTeams] = useState(false);
  const [addNewBoard, setNewBoard] = useState(false);
  const [addNewMember, setNewMember] = useState(false);
  const [addNewDashboard, setNewDashboard] = useState(false);
  const [addNewDashboardPMU, setNewDashboardPMU] = useState(false);
  const [addNewFolder, setAddNewFolder] = useState(false);
  const [params, setParams] = useState({
    column_order: "",
    order: "",
    workspace_id: "",
    indexWorkspaceActive: 0,
    indexBoardActive: 0,
  });
  const [workspace, setWorkspace] = useState([]);
  const [workspaceProfile, setWorkspaceProfile] = useState([]);
  const [workspaceSeeAll, setWorkspaceSeeAll] = useState([]); // see all workspace
  const [board, setBoard] = useState([]);
  const [dashboard, setDashboard] = useState([]);
  const [selectedBoard, setSelectedBoard] = useState([]);
  // selected task board
  const [selectedTaskBoard, setSelectedTaskBoard] = useState({});
  const [detailTask, setDetailTask] = useState([]);
  const [detailTaskBoard, setDetailTaskBoard] = useState([]);
  const [boardUser, setBoardUser] = useState([]);
  const [permission, setPermission] = useState([]);
  const [rating, setRating] = useState(0);
  const [table, setTable] = useState([]);
  const [tableSearch, setTableSearch] = useState([]);
  const [member, setMember] = useState([]);
  const [teams, setTeams] = useState([]);
  const [workspaceTeams, setWorkspaceTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState(false);
  const [priority, setPriority] = useState([]);
  const [status, setStatus] = useState([]);
  const [flagNewBoard, setFlagNewBoard] = useState("");
  const [boardActive, setBoardActive] = useState([]);
  const [itemActive, setItemActive] = useState([]);
  const [width, setWidth] = useState({
    menu: "255px",
    controll: "307px",
    board: "290px",
  });
  const [itemAdd, setItemAdd] = useState(false);
  const [subItemAdd, setSubItemAdd] = useState(false);
  const [itemUpdate, setItemUpdate] = useState(false);
  const [itemDelete, setItemDelete] = useState(false);
  const [columnUpdate, setColumnUpdate] = useState(false);
  const [columnUpdateStatus, setColumnUpdateStatus] = useState(false);
  const [moveGroupTable, setMoveGroupTable] = useState(false);
  const [moveItemTable, setMoveItemTable] = useState(false);
  const [itemExpand, setItemExpand] = useState(false);
  const [columnAdd, setColumnAdd] = useState(false);
  const [columnDelete, setColumnDelete] = useState(false);
  const [groupDelete, setGroupDelete] = useState(false);
  const [boardUpdate, setBoardUpdate] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState(false);
  const [flagNewMember, setFlagNewMember] = useState(false);
  const [countNotif, setCountNotif] = useState(0);
  const [showMember, setShowMember] = useState(false);
  const [showCuttoff, setShowCuttoff] = useState(false);
  // show modal board name clicked
  const [showDetailBoard, setShowDetailBoard] = useState(false);
  const [showMemberTeam, setShowMemberTeam] = useState(false);
  const [showModalContact, setShowModalContact] = useState(false);
  const [showPA, setShowPA] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showDeleteTeams, setShowDeleteTeams] = useState(false);
  const [showDeleteWorkspace, setShowDeleteWorkspace] = useState(false);
  const [showDeleteTeamMember, setDeleteTeamMember] = useState(false);
  const [showDeleteWorkspaceMember, setDeleteWorkspaceMember] = useState(false);
  const [showPriority, setShowPriority] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [showNotif, setShowNotif] = useState(false);
  const [showLogs, setShowLogs] = useState(false);
  const [showDetailKanban, setShowDetailKanban] = useState({
    isShow: false,
    data: [],
  });
  const [showChooseBoard, setShowChooseBoard] = useState(false);
  const [editDesc, setEditDesc] = useState(false);
  const [showPermissionBoard, setShowPermissionBoard] = useState(false);
  const [showWidget, setShowWidget] = useState(false);
  const [showWidgetBattery, setShowWidgetBattery] = useState(false);
  const [showChooseWidget, setShowChooseWidget] = useState(false);
  const [showModalText, setShowModalText] = useState({
    isShow: false,
    value: "Add your text here",
  });
  const [modeDashboard, setModeDashboard] = useState("");
  const [modalSpinner, setModalSpinner] = useState(false);
  const [printDashboard, setPrintDashboard] = useState(false);
  const [modalPricing, showModalPricing] = useState(false);
  const [modalArticle, showModalArticle] = useState({ isShow: false });
  const [modalMenuArticle, showModalMenuArticle] = useState({
    data: false,
    sShow: false,
  });
  const [permissionDashboard, setPermissionDashboard] = useState(false);
  const [editTeamMember, setEditTeamMember] = useState({ isShow: false });
  const [editWorkspaceMember, setEditWorkspaceMember] = useState({
    isShow: false,
  });
  const [modalPayment, showModalPayment] = useState({ isShow: false });
  const [boardDashboardSelected, setBoardDashboardSelected] = useState(false);
  const [fullscreenWidget, setFullscreenWidget] = useState({
    fullscreen: false,
    dashboard: "",
    key: 0,
  });
  const [color, setColor] = useState({
    content: "rgb(245, 246, 248)",
    header: "white",
    color: "black",
    chart: "white",
  });
  const [valueSearch, setValueSearch] = useState({ value: "", type: "" });
  const [valueFilter, setValueFilter] = useState({ value: "", column: "" });
  const [valueSort, setValueSort] = useState({ value: "", column: "" });
  const [socket, setSocket] = useState(false);
  const [viewType, setViewType] = useState("Default");
  const [selectedColumnKanban, setSelectedColumnKanban] = useState(false);
  const [selectedTeamsSetting, setSelectedTeamsSetting] = useState(false);
  const [dataDashboard, setDataBattery] = useState([]);
  const [isNewArticle, setNewArticle] = useState(false);
  const [filterElement, setFilterElement] = useState([]);
  const [sortElement, setSortElement] = useState([]);
  const [tableMyTask, setTableMyTask] = useState([]);
  const [tableSearchMyTask, setTableSearchMyTask] = useState([]);
  const [dashboardTeams, setDashboardTeams] = useState({
    data: [],
    progress: { progress_weekly: [] },
  });
  const [dashboardBoard, setDashboardBoard] = useState({
    data: [],
    progress: { progress_weekly: [] },
  });
  const [dashboardTeamsCurve, setDashboardTeamsCurve] = useState([]);
  const [dashboardWorkspaceCurve, setDashboardWorkspaceCurve] = useState([]);
  const [workspaceWeeks, setWorkspaceWeeks] = useState({ weeks: [] });
  const [curvaS, setCurvaS] = useState([]);

  const [viewTimeline, setViewTimeline] = useState([
    { value: "Day", label: "Day" },
    { value: "Week", label: "Week" },
    { value: "Month", label: "Month" },
    { value: "Year", label: "Year" },
  ]);

  const [viewTimelineSelected, setViewTimelineSelected] = useState({
    value: "Week",
    label: "Week",
  });

  const initialChart = {
    type: "column",
    xAxis: "Status",
    xAxis2: "Status",
    yAxis: "Count Items",
    board: [],
    group: [],
    cidUpdate: 0,
    selectedBoard: [],
    title: "Chart",
    column: {},
    isUpdate: false,
    widget: { selectedBoard: [] },
    selectedIdBoard: [],
    selectedIdGroup: [],
    sortBy: false,
    showUndefined: false,
    xAxisColumnId: [],
    groupBy: "",
    adjustColor: false,
  };
  const [stateChart, dispatchChart] = useReducer(ChartReducer, initialChart);

  //===========WORKSPACE============//
  const getWorkspace = async (data) => {
    setValueSearch({ value: "", type: "" });
    setValueSort({ value: "", type: "" });
    setValueFilter({ value: "", type: "" });
    let param = {};
    // console.log("data_param_workspace__", data)
    // param.team_id = (params.from && params.from === "android") ? data.teamUser.team_id : data.team_id
    param.team_id = data.team_id;
    param.user_id = data.id;
    param.column_order = "id";
    param.permission_id = data.team.permission_id;
    param.order = "asc";
    // setModalSpinner(true)
    const result = await MakeRequest("post", "workspace-read", param);
    if (result.status === 200) {
      //=======GET ONLY WORKSPACE ATTRIBUTE=========//
      let dataWorkspace = [];
      //=======GET WORKSPACE ATTRIBUTE PUSH TO DATA VARIABLE=========//
      _.each(result.data, (workspace) => {
        workspace.permission_id = workspace.workspaceUsers.permission_id;
        workspace.is_workspace_active = workspace.workspaceUsers.is_active;
        let isExist = _.some(dataWorkspace, function (i) {
          return i.id == workspace.id;
        });
        if (!isExist) dataWorkspace.push(workspace);
      });
      // console.log("dataWorkspace__", dataWorkspace)

      setWorkspace(dataWorkspace);
      if (dataWorkspace.length === 0) {
        setSelectedWorkspace({});
        setBoardActive({ title: "blank" });
        setBoard([]);
      }
      // await getMember(data.team_id)
    }
    // setModalSpinner(false)
  };

  const getWorkspaceProfile = async () => {
    let param = {};
    param.user_id = params.id;
    setModalSpinner(true);
    const result = await MakeRequest("post", "workspace-read-profile", param);
    if (result.status === 200) {
      //=======GET ONLY WORKSPACE ATTRIBUTE=========//
      let dataWorkspace = [];
      _.each(result.data, (item, key) => {
        if (item.is_active) {
          //=======GET WORKSPACE ATTRIBUTE PUSH TO DATA VARIABLE=========//
          _.each(item.workspace, (workspace) => {
            workspace.permission_id = item.permission_id;
            workspace.is_workspace_active = item.is_active;
            let isExist = _.some(dataWorkspace, function (i) {
              return i.id == workspace.id;
            });
            if (!isExist) dataWorkspace.push(workspace);
          });
        }
      });
      setWorkspaceProfile(dataWorkspace);
    }

    let debouncer = setTimeout(() => {
      setModalSpinner(false);
    }, 500);
    return () => {
      clearTimeout(debouncer);
    };
  };

  // see all workspave
  const getWorkspaceSeeAll = async () => {
    if(!params.id) return
    let param = {};
    param.user_id = params.id; 
    // setModalSpinner(true)
    const result = await MakeRequest("post", "workspace-read-see-all", param);
    if (result.status === 200) {
      //=======GET ONLY WORKSPACE ATTRIBUTE=========//
      let dataWorkspaceSeeAll = [];
      _.each(result.data, (item, key) => {
        if (item.is_active) {
          //=======GET WORKSPACE ATTRIBUTE PUSH TO DATA VARIABLE=========//
          _.each(item.workspace, (workspace) => {
            workspace.permission_id = item.permission_id;
            workspace.is_workspace_active = item.is_active;
            let isExist = _.some(dataWorkspaceSeeAll, function (i) {
              return i.id == workspace.id;
            });
            if (!isExist) dataWorkspaceSeeAll.push(workspace);
          });
        }
      });
      setWorkspaceSeeAll(dataWorkspaceSeeAll);
    }

    // let debouncer = setTimeout(() => {
    //     setModalSpinner(false)
    // }, 500);
    // return () => {
    //     clearTimeout(debouncer);
    // }
  };

  const insertWorkspace = async (params) => {
    const result = await MakeRequest("post", "workspace-create", params);
    return result;
  };

  const getWorkspaceTeams = async (id) => {
    let param = { id: id, team_id: selectedTeams.value };
    const result = await MakeRequest("post", "workspace-users-read", param);
    if (result.status === 200) setWorkspaceTeams(result.data);
  };

  const updateWorkspaceTeams = async (param) => {
    const result = await MakeRequest("post", "workspace-users-update", param);
    return result;
  };

  const deleteWorkspaceTeams = async (param) => {
    const result = await MakeRequest("post", "workspace-users-delete", param);
    return result;
  };

  const createWorkspaceTeams = async (param) => {
    param.email = param.email;
    const result = await MakeRequest("post", "workspace-users-create", param);
    return result;
  };

  const updateWorkspace = async (param) => {
    const result = await MakeRequest("post", "workspace-update", param);
    return result;
  };

  const updateWorkspaceShowDashboard = async (param) => {
    const result = await MakeRequest(
      "post",
      "workspace-update-show-dashboard",
      param
    );
    return result;
  };

  //===========END WORKSPACE============//

  //===========BOARD============//

  const createBoard = async (params) => {
    setSelectedBoard([]);
    const result = await MakeRequest("post", "board-create", params);
    return result;
  };

  const getBoard = async (fromDelete = false, fromDuplicate = false) => {
    // console.log("param_getBOardID__", params)
    if (params.from && params.from === "android") {
      params.workspace_id = params.selectedBoard.workspace_id;
    }
    // console.log("ini nih...", params)
    const result = await MakeRequest("post", "board-read", params);
    if (result.status === 200) {
      setBoardActive([]);
      //=======GET ONLY BOARD ATTRIBUTE=========//
      let dataBoard = [],
        idUrl = params.id_url ? parseInt(params.id_url) : -1;
      // console.log("id_url___", idUrl)
      let active = params.indexBoardActive;
      //  _.each(result.data, (item, key)=>{
      //=======GET BOARD ATTRIBUTE PUSH TO DATA VARIABLE=========//
      _.each(result.data, (board, index) => {
        board.is_owner = false;
        if (board.created_by === params.id) board.is_owner = true;
        if (idUrl === board.id) active = index;

        if (params.selectedBoard && params.selectedBoard.id === board.id) {
          active = index;
        }

        let isExist = _.some(dataBoard, function (i) {
          return i.id == board.id;
        });
        if (!isExist) dataBoard.push(board);
      });
      //  })
      setBoard(dataBoard);
      // console.log("dataBoard___", dataBoard)
      //===========IF FROM DELETE DEFAULT BOARD ACTIVE IS INDEX 0============//
      if (dataBoard.length > 0) {
        setBoardActive(
          dataBoard[fromDelete ? 0 : fromDuplicate ? active + 1 : active]
        );
      } else {
        setBoardActive({ title: "blank" });
        setTable([]);
      }
    }
  };

  const getTaskDetail = async (params) => {
    let result = [];
    result = await MakeRequest("post", "task-detail", params);
    if (result.status === 200) {
      setDetailTask(result.data.data);
      setDetailTaskBoard(result.data.data_detail);
    } else {
      setDetailTask([]);
      setDetailTaskBoard([]);
    }
  };

  const getSelectedBoard = async () => {
    setSelectedBoard([]);
    const result = await MakeRequest("post", "selected-board-read", {
      selected_board: boardActive.selected_board,
    });
    if (result.status === 200) setSelectedBoard(result.data);
  };

  const getBoardUser = async () => {
    const result = await MakeRequest("post", "board-users", {
      board_id: boardActive.id,
      workspace_id: selectedWorkspace.value,
      team_id: selectedTeams.value,
    });
    if (result.status === 200) {
      //=======GET ONLY BOARD ATTRIBUTE=========//
      let dataBoard = [];
      _.each(result.data, (item) => {
        //=======GET BOARD ATTRIBUTE PUSH TO DATA VARIABLE=========//
        dataBoard.push(item.users);
      });
      setBoardUser(dataBoard);
    }
  };

  const duplicateBoard = async () => {
    let paramsInsert = boardActive;
    paramsInsert.user_id = params.id;
    paramsInsert.title = "Duplicate of " + paramsInsert.title;
    paramsInsert.board_id_source = boardActive.id;
    paramsInsert.is_duplicate = true;
    setModalSpinner(true);
    let result = await createBoard(paramsInsert);
    if (result.status === 200) {
      paramsInsert.board_id = result.data.id;
      await createBoardBulk(paramsInsert);
    }
    if (result.message === "limit") {
      showModalPricing(true);
      await getBoard();
    }

    setModalSpinner(false);
  };

  const deleteBoard = async (selectedItem) => {
    setModalSpinner(true);
    const result = await MakeRequest("post", "board-delete", selectedItem);
    if (result.status === 200) await getBoard(true);
    setModalSpinner(false);
  };

  const updateBoard = async (boardParam, isRefresh = true) => {
    const result = await MakeRequest("post", "board-update", boardParam);
    if (result.status === 200 && isRefresh) return getBoard();
  };

  const createBoardBulk = async (param, isDuplicate = false) => {
    setModalSpinner(true);
    const result = await MakeRequest("post", "board-create-bulk", {
      data: param,
    });
    if (result.status === 200) {
      await getBoard(false, false);
    }
    setModalSpinner(false);
    return result;
  };

  const updateBoardUser = async (data) => {
    // let users =_.map(boardParam, o => _.omit(o, ['value', 'label']));
    // let users_id =_.map(boardParam,(item)=>{
    //     return item.user_id
    // });
    let param = { users: data, user_id: params.id };
    const result = await MakeRequest("post", "board-users-update", param);
    return result;
  };

  const uploadFile = async (param) => {
    const result = await MakeRequest("post", "file-upload", param);
    if (result.status === 200) return result;
  };

  const downloadFile = async (param) => {
    const result = await MakeRequestDownload(
      "get",
      "file-download?id=" + param
    );
    return result;
  };

  const deleteFile = async (id) => {
    const result = await MakeRequest("post", "file-delete", id);
    if (result.status === 200) return result;
  };

  const getPriority = async () => {
    const result = await MakeRequest("post", "priority-read", {
      board_id: boardActive.id,
    });
    if (result.status === 200) setPriority(result.data);
  };

  const updatePriority = async (data) => {
    const result = await MakeRequest("post", "priority-update", {
      data,
      board_id: boardActive.id,
    });
    if (result.status === 200) return result;
  };

  const getStatus = async () => {
    const result = await MakeRequest("post", "status-read", {
      board_id: boardActive.id,
    });
    if (result.status === 200) setStatus(result.data);
  };

  const updateStatus = async (data) => {
    const result = await MakeRequest("post", "status-update", {
      data,
      board_id: boardActive.id,
    });
    if (result.status === 200) return result;
  };

  const inviteMember = async (email) => {
    const result = await MakeRequest("post", "invite-member", {
      user_id: params.id,
      to: email,
      board_id: boardActive.id,
      team_id: selectedTeams.id,
    });
    return result;
  };

  //===========END BOARD============//

  //===========DASHBOARD============//

  const createDashboard = async (params, isDuplicate = false) => {
    setModalSpinner(true);
    const result = await MakeRequest("post", "dashboard-create", params);
    if (result.status === 200) {
      if (isDuplicate) {
        await getDashboard();
      } else {
        await getBoard();
      }
    }
    setModalSpinner(false);
    return result;
  };

  const createDashboardBulk = async (param, isDuplicate = false) => {
    setModalSpinner(true);
    const result = await MakeRequest("post", "dashboard-create-bulk", {
      data: param,
    });
    if (result.status === 200) {
      if (isDuplicate) {
        await getDashboard();
      } else {
        await getBoard(false, false);
      }
    }
    setModalSpinner(false);
    return result;
  };

  const duplicateDashboard = async () => {
    let paramsInsert = boardActive;
    paramsInsert.user_id = params.id;
    paramsInsert.title = "Duplicate of " + paramsInsert.title;
    paramsInsert.selected_board = _.map(selectedBoard, "id");
    setModalSpinner(true);
    let result = await createBoard(boardActive);
    if (result.status === 200) {
      paramsInsert.dashboard.map((item) => {
        item.board_id = result.data.id;
        item.created_by = params.id;
      });

      let param = _.map(paramsInsert.dashboard, (o) => _.omit(o, ["id"]));
      await createDashboardBulk(param);
    }
    setModalSpinner(false);
  };

  const updateDashboard = async (params, isRefresh = false) => {
    // setModalSpinner(true)
    const result = await MakeRequest("post", "dashboard-update", params);
    if (result.status === 200 && isRefresh) {
      await getDashboard();
      // let debouncer = setTimeout(() => {
      //     setModalSpinner(false)
      // }, 500);
      // return () => {
      //     clearTimeout(debouncer);
      // }
    }
    return result;
  };

  const deleteDashboard = async (params) => {
    setModalSpinner(true);
    const result = await MakeRequest("post", "dashboard-delete", params);
    if (result.status === 200) await getDashboard();
    setModalSpinner(false);
    return result;
  };

  const getDashboard = async () => {
    const result = await MakeRequest("post", "dashboard-read", {
      board_id: boardActive.id,
    });
    if (result.status === 200) return setDashboard(result.data);
  };

  const createWidget = async (param) => {
    let info = {
      id: stateChart.idUpdate,
      title: param.type,
      created_by: params.id,
      team_id: params.team.id,
      board_id: boardActive.id,
      workspace_id: boardActive.workspace_id,
      permission_id: boardActive.permission_id,
    };
    let mod = boardActive.dashboard.length % 2;
    let column = {
      x: mod === 0 ? 0 : 4,
      y: mod === 0 ? 0 : 4,
      w: 5,
      h: 2,
      minW: 3,
      minH: 2,
      i: new Date().getTime() + "1",
      static: false,
      type: param.type,
      value: param.value || "",
    };
    let data = Object.assign(
      info,
      { column: column },
      { widget: {} },
      { chart: [] }
    );
    let result = await createDashboard(data, true);
    return result;
  };

  //===========END DASHBOARD============//

  //===========TABLE============//

  const getTable = async (id) => {
    // let params = {board_id: id ? id : boardActive.id}
    // let valParams

    // if(params.from && params.from === "android"){
    //     valParams = { board_id: params.selectedBoard.id }
    // }else{
    //     valParams = { board_id: boardActive.id }
    // }
    // console.log("valParams__", valParams)
    let params = { board_id: boardActive.id };
    // console.log("params__", params)

    const result = await MakeRequest("post", "table-read", params);
    // console.log("result", result)
    if (result.status === 200) {
      setTable(result.data);
      setTableSearch(result.data);
    }
  };

  const updateTable = async (id, rows) => {
    let param = {
      id: id,
      rows: rows,
      user_id: params.id,
      username: params.username,
    };
    const result = await MakeRequest("post", "table-update", param);
    if (result.status === 200) return result;
  };

  const updateTableKanban = async (id, kanban) => {
    let param = {
      id: id,
      kanban: kanban,
      user_id: params.id,
      username: params.username,
    };
    const result = await MakeRequest("post", "table-update-kanban", param);
    if (result.status === 200) return result;
  };

  const addColumnTable = async (param) => {
    const result = await MakeRequest("post", "table-column-add", param);
    if (result.status === 200) return result;
  };

  const updateColumnTable = async (param) => {
    const result = await MakeRequest("post", "table-column-update", param);
    if (result.status === 200) return result;
    else return NotificationManager.error(result.message);
  };

  const updateColumnTableStatus = async (param) => {
    const result = await MakeRequest(
      "post",
      "table-column-update-status",
      param
    );
    if (result.status === 200) return result;
  };

  const deleteColumn = async (param) => {
    const result = await MakeRequest("post", "table-column-delete", param);
    if (result.status === 200) return result;
  };

  const createRowsTable = async (param) => {
    const result = await MakeRequest("post", "table-create-rows-item", param);
    if (result.status === 200) return result;
  };

  const createSubItem = async (param) => {
    const result = await MakeRequest("post", "table-create-subs-item", param);
    return result;
  };

  const deleteTable = async (param) => {
    const result = await MakeRequest("post", "table-delete", param);
    if (result.status === 200) return result;
  };

  const moveGroup = async (param) => {
    const result = await MakeRequest("post", "move-group", param);
    if (result.status === 200) return result;
  };

  const moveItem = async (param) => {
    const result = await MakeRequest("post", "move-item", param);
    if (result.status === 200) return result;
  };

  const deleteGroup = async (param) => {
    const result = await MakeRequest("post", "table-delete-group", param);
    if (result.status === 200) return result;
  };

  const expandCollapse = async (param) => {
    const result = await MakeRequest("post", "table-expand-collapse", param);
    if (result.status === 200) return result;
  };

  const updateComment = async (param) => {
    const result = await MakeRequest("post", "table-comment-create", param);
    if (result.status === 200) return result;
  };

  const getComment = async (param) => {
    const result = await MakeRequest("post", "table-comment", param);
    if (result.status === 200) return result;
  };

  const uuid = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  const addNewGroup = async () => {
    let param = {};
    param.board_id = boardActive.id;
    let header = [
      { type: "option", name: "" },
      { type: "text", name: "New Group", id: uuid() },
      { type: "person", name: "Assigned To", id: uuid() },
      { type: "priority", name: "Priority", id: uuid() },
      { type: "status", name: "Status", id: uuid() },
      { type: "date", name: "Start Date", id: uuid() },
      { type: "duedate", name: "Due Date", id: uuid() },
      { type: "timeline_duedate", name: "Timeline", id: uuid() },
      { type: "progress", name: "Progress", id: uuid() },
      { type: "rating", name: "Rating", id: uuid() },
      { type: "add", name: "", created_by: params.username },
    ];
    if (table.length) header = table[0].rows[0];
    header[1].name = "New Group";

    _.map(header, (val, idx) => {
      if (!val.id) val.id = uuid();
    });

    let rows = [header];
    let items = _.cloneDeep(header);
    _.map(items, (val, idx) => {
      val.name = "";
      val.id = uuid();
      if (idx === 1) val.name = "Item 1";
      if (val.type === "rating") val.name = "0";
      if (val.type === "progress") val.name = "0";
      if (val.type === "creation_log") val.name = params.username;
      if (val.type === "add") {
        val.created_by = params.username;
        val.type = "";
      }
    });

    rows.push(items);
    param.rows = rows;
    param.user_id = params.id;
    setModalSpinner(true);
    const result = await MakeRequest("post", "table-create", param);
    if (result.status === 200) {
      setTable([]);
      await getTable();
    }
    setModalSpinner(false);
  };

  const duplicateGroup = async (rows) => {
    let param = {};
    param.board_id = boardActive.id;
    let groupName = rows[0][1].name;
    rows[0][1].name = "Copy of " + groupName;
    rows.map((item, index) => {
      if (index === 0) return;
      item.map((row, key) => {
        row.id = uuid();
        if (row.hashchild) row.hashchild = false;
        if (row.countchild) row.countchild = 0;
        if (key === 1) return;
        row.name = "";
      });
    });

    let newRows = _.filter(rows, (item) => {
      let newItem = _.filter(item, (row) => {
        return row.parent === undefined;
      });
      if (newItem.length) return item;
    });
    param.rows = newRows;
    param.user_id = params.id;
    setModalSpinner(true);
    const result = await MakeRequest("post", "table-create", param);
    if (result.status === 200) {
      setTable([]);
      await getTable();
      let debouncer = setTimeout(() => {
        setModalSpinner(false);
      }, 500);
      return () => {
        clearTimeout(debouncer);
      };
    }
  };

  //===========END TABLE============//

  //===========MEMBER============//
  const getMember = async (teamId) => {
    let param = { team_id: teamId };
    const result = await MakeRequest("post", "teams-user-read", param);
    if (result.status === 200) setMember(result.data);
  };

  const getTeams = async (id) => {
    let param = { id: id };
    const result = await MakeRequest("post", "teams-read", param);
    if (result.status === 200) {
      let teams = _.filter(result.data, (item) => {
        return item.is_active === true;
      });
      setTeams(teams);
    }
    return result;
  };

  const getDashboardTeams = async (isBoard) => {
    setDashboardTeams({ data: [], progress: { progress_weekly: [] } });
    let param = {
      team_id: selectedTeams.value,
      user_id: params.id,
    };
    if (isBoard) Object.assign(param, { id: params.workspace_id });
    const result = await MakeRequest("post", "teams-dashboard", param);
    if (result.status === 200) setDashboardTeams(result.data);
  };

  const getDashboardBoard = async () => {
    setDashboardBoard({ data: [], progress: { progress_weekly: [] } });
    let param = {
      team_id: selectedTeams.value,
      user_id: params.id,
      id: params.workspace_id,
    };
    const result = await MakeRequest("post", "workspace-dashboard", param);
    if (result.status === 200) setDashboardBoard(result.data);
  };

  const getDashboardTeamsCurve = async () => {
    setDashboardTeamsCurve([]);
    const result = await MakeRequest("post", "teams-dashboard-curve", {
      team_id: selectedTeams.value,
      user_id: params.id,
    });
    if (result.status === 200) setDashboardTeamsCurve(result.data);
  };

  const getDashboardWorkspaceCurve = async () => {
    setDashboardWorkspaceCurve([]);
    const result = await MakeRequest("post", "workspace-dashboard-curve", {
      team_id: selectedTeams.value,
      user_id: params.id,
      id: params.workspace_id,
      type : 1
    });
    if (result.status === 200) setDashboardWorkspaceCurve(result.data);
  };

  const getWorkspaceWeeks = async () => {
    setWorkspaceWeeks({ weeks: [] });
    const result = await MakeRequest("post", "workspace-weeks", {
      team_id: selectedTeams.value,
      user_id: params.id,
      id: params.workspace_id,
    });
    if (result.status === 200) setWorkspaceWeeks(result.data);
  };

  const getCurvaS = async (type) => {
    setCurvaS([]);
    const result = await MakeRequest("post", "workspace-curve", {
      team_id: selectedTeams.value,
      workspace_id: params.workspace_id,
      type,
    });
    if (result.status === 200) setCurvaS(result.data);
  };

  const processWorkspaceWeeks = async (week) => {
    const result = await MakeRequest("post", "workspace-weeks-process", {
      team_id: selectedTeams.value,
      user_id: params.id,
      id: params.workspace_id,
      week,
      board_id: params.selectedBoard.id,
    });
    if (result.status === 200) NotificationManager.success(result.message);
    else NotificationManager.error(result.message);
  };

  const createTeams = async (param) => {
    const result = await MakeRequest("post", "teams-create", param);
    return result;
  };

  const updateTeams = async (param) => {
    const result = await MakeRequest("post", "teams-update", param);
    return result;
  };

  const updateMember = async (param) => {
    const result = await MakeRequest("post", "teams-user-update", param);
    return result;
  };

  const inviteTeamMember = async (param) => {
    const result = await MakeRequest("post", "teams-invite-member", param);
    return result;
  };

  //===========END MEMBER============//

  //===========PERMISSION============//
  const getPermission = async (teamId) => {
    const result = await MakeRequest("post", "permission-read");
    if (result.status === 200) setPermission(result.data);
  };

  //===========END MEMBER============//

  //===========NOTIF============//
  const getNotif = async (id, board_id = null, row_id = null) => {
    let team_id = params.team ? params.team.id : 0;
    let workspace_id = params.workspace_id 
    const result = await MakeRequest("post", "notification-read", {
      id,
      board_id,
      row_id,
      team_id,
      workspace_id
    });
    return result;
  };

  const getNotifCount = async (id, team_id, workspace_id) => {  
    if(!workspace_id || workspace_id === undefined) return 
    const result = await MakeRequest("post", "notification-count", {
      id,
      team_id,
      workspace_id 
    });

    if (result.status === 200) setCountNotif(result.data);
  };

  //===========END NOTIF============//

  const setupSocket = (id) => {
    let pathUrl = window.location.origin;
    if (window.location.hostname === "localhost")
      pathUrl = "http://localhost:4000";

    let socket = socketIOClient(pathUrl, { query: `id=${id}` });

    setSocket(socket);

    socket.on("notification", (data) => {
      // NotificationManager.info("You have new notifications.")
      let debouncer = setTimeout(() => { 
        getNotifCount(id, data.data.team_id, data.data.workspace_id);
      }, 1000);
      return () => {
        clearTimeout(debouncer);
      };
    });
  };

  const postMidtrans = async (plan) => {
    let param = {};
    param.user_id = params.id;
    param.team_id = selectedTeams.value;
    param.team = selectedTeams.label;
    param.plan = plan;
    param.email = params.email;
    param.name = params.username;
    param.hp = params.hp;
    const result = await MakeRequest("post", "midtrans-token", param);
    return result;
  };

  const postFreeTrial = async (param) => {
    // console.log(param)
    const result = await MakeRequest("post", "free-trial", param);
    return result;
  };

  const postMenuArticle = async () => {
    const result = await MakeRequest("post", "article-menu", {});
    return result;
  };

  const postMenuArticleCreate = async (param) => {
    const result = await MakeRequest("post", "article-menu-create", param);
    return result;
  };

  const deleteMenuArticle = async (param) => {
    const result = await MakeRequest("post", "article-menu-delete", param);
    return result;
  };

  const postArticleCrete = async (param) => {
    const result = await MakeRequest("post", "article-create", param);
    return result;
  };

  const getArticle = async () => {
    const result = await MakeRequest("post", "article", {});
    return result;
  };

  const deleteArticle = async (param) => {
    const result = await MakeRequest("post", "article-delete", param);
    return result;
  };

  const sendNotif = (data) => {
    socket.emit("send notif", data);
  };

  //===========TABLE MY TASK============//

  const getTableMyTask = async (id) => {
    setTable([{}]);
    setTableSearch([{}]);
    setModalSpinner(true);
    // let params = {board_id: id ? id : boardActive.id}
    let param = { user_id: params.id };
    const result = await MakeRequest("post", "my-work", param);
    if (result.status === 200) {
      setTable(result.data);
      setTableSearch(result.data);
      setModalSpinner(false);
    }
  };

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("saatkerja"));
    if (data === null) history.push("/");
    else {
      // if(!location.pathname.includes("workspace"))
      // history.push('/app/board')
      let idUrl = getParameterByName("id");
      let workspaceUrl = getParameterByName("workspace");
      data.user_id = data.id;
      data.id_url = idUrl;
      data.workspace_url = workspaceUrl; 
      let param = Object.assign(data, params);
      param.team.value = param.team.id;
      if (param.selectedTeams) setSelectedTeams(param.selectedTeams); 
      setParams(param); 
      setupSocket(param.id); 
      // getNotifCount(param.id, param.team.id);
      getTeams(param.id);
      getPermission();
    }
  }, []);

  const setLocalStorage = (params) => {
    localStorage.removeItem("saatkerja");
    localStorage.setItem("saatkerja", JSON.stringify(params));
  };

  useEffect(() => {
    if (flagNewBoard !== "") {
      setModalSpinner(true);
      const getdataBoard = async () => {
        await getBoard();
        // console.log("hahahahahhaha___", selectedTaskBoard)
        setModalSpinner(false);
      };
      getdataBoard();
    }

    setTable([]);
  }, [flagNewBoard]);

  // useEffect(async () => {
  //     await getTaskDetail();
  // }, [detailTask])

  useEffect(() => {
    // console.log("params___", params)
    if (boardActive.id !== undefined) {
      setTable([]);
      getNotifCount(params.id, params.team_id, params.workspace_id)

      const getDataTable = async () => {
        setModalSpinner(true);
        if (boardActive.type === 1) {
          // console.log("param", params)
          if (params.from && params.from === "android") {
            history.push("/app/board-android-detail");
          } else {
            history.push("/app/board"); // bisa dinyalakan lagi jika ada error
          }
          await getTable();
          setModalSpinner(false);
          // await getMember(boardActive.team_id)
          // await getPriority()
          // await getStatus()
        }
        await getWorkspaceWeeks();
        if (boardActive.type === 2) {
          setFullscreenWidget(false);
          if (params.from && params.from === "android") {
            history.push("/app/dashboard-android-detail");
          } else {
            history.push("/app/dashboard");
          }

          setDashboard([]);
          await getSelectedBoard();
          await getDashboard();
          setModalSpinner(false);
        }
        await getBoardUser();
      };
      getDataTable();
    }
  }, [boardActive]);

  useEffect(() => {
    if (itemAdd) {
      setModalSpinner(true);
      const doCreateRowsTable = async () => {
        await createRowsTable(itemAdd);
        if (itemAdd.refresh) await getTable();
        let debouncer = setTimeout(() => {
          setModalSpinner(false);
        }, 500);
        return () => {
          clearTimeout(debouncer);
        };
      };
      doCreateRowsTable();
    }
  }, [itemAdd]);

  useEffect(() => {
    if (subItemAdd) {
      setModalSpinner(true);
      const doCreateRowsTable = async () => {
        let result = await createSubItem(subItemAdd);
        if (result.status === 400)
          NotificationManager.error(result.data.detail);
        if (subItemAdd.refresh) await getTable();
        let debouncer = setTimeout(() => {
          setModalSpinner(false);
        }, 500);
        return () => {
          clearTimeout(debouncer);
        };
      };
      doCreateRowsTable();
    }
  }, [subItemAdd]);

  useEffect(() => {
    if (itemDelete) {
      setModalSpinner(true);
      const doDeleteTable = async () => {
        await deleteTable(itemDelete);
        if (itemDelete.refresh) await getTable();
        let debouncer = setTimeout(() => {
          setModalSpinner(false);
          // let msg = "Successfully deleted 1 item."
          // NotificationManager.success(msg)
        }, 500);
        return () => {
          clearTimeout(debouncer);
        };
      };
      doDeleteTable();
    }
  }, [itemDelete]);

  useEffect(() => {
    if (itemUpdate) {
      const doUpdateTable = async () => {
        await updateTable(itemUpdate.id, itemUpdate.rows);
        if (itemUpdate.refresh) {
          setModalSpinner(true);
          await getTable();
          setModalSpinner(false);
        }
      };

      const doRefreshTable = async () => {
        setModalSpinner(true);
        await getTable();
        setModalSpinner(false);
      };
      if (itemUpdate.refreshOnly) doRefreshTable();
      else doUpdateTable();
    }
  }, [itemUpdate]);

  useEffect(() => {
    if (columnUpdate) {
      const doColumnTable = async () => {
        await updateColumnTable(columnUpdate);
        // if(columnUpdate.refresh || showDetailKanban.isShow) await getTable()
        if (columnUpdate.refresh) await getTable(columnUpdate.name.board_id);
      };
      doColumnTable();
    }
  }, [columnUpdate]);

  useEffect(() => {
    if (columnUpdateStatus) {
      const doColumnTable = async () => {
        setModalSpinner(true);
        columnUpdateStatus.user_id = params.id;
        await updateColumnTableStatus(columnUpdateStatus);
        // if(columnUpdate.refresh || showDetailKanban.isShow) await getTable()
        if (columnUpdateStatus.refresh)
          await getTable(columnUpdateStatus.board_id);
        setModalSpinner(false);
      };
      doColumnTable();
    }
  }, [columnUpdateStatus]);

  useEffect(() => {
    if (moveGroupTable) {
      const doColumnTable = async () => {
        setModalSpinner(true);
        await moveGroup(moveGroupTable);
        if (moveGroupTable.refresh) await getTable();
        setModalSpinner(false);
      };
      doColumnTable();
    }
  }, [moveGroupTable]);

  useEffect(() => {
    if (moveItemTable) {
      const doColumnTable = async () => {
        setModalSpinner(true);
        await moveItem(moveItemTable);
        if (moveItemTable.refresh) await getTable();
        setModalSpinner(false);
      };
      doColumnTable();
    }
  }, [moveItemTable]);

  useEffect(() => {
    if (itemExpand) {
      const doColumnTable = async () => {
        await expandCollapse(itemExpand);
        if (itemExpand.refresh) await getTable();
      };
      doColumnTable();
    }
  }, [itemExpand]);

  useEffect(() => {
    if (columnAdd) {
      setModalSpinner(true);
      const doAddColumnTable = async () => {
        let result = await addColumnTable(columnAdd);
        let debouncer = setTimeout(() => {
          setTable(result.data);
          setModalSpinner(false);
        }, 500);
        return () => {
          clearTimeout(debouncer);
        };
      };
      doAddColumnTable();
    }
  }, [columnAdd]);

  useEffect(() => {
    if (columnDelete) {
      setModalSpinner(true);
      const doDeleteColumn = async () => {
        let result = await deleteColumn(columnDelete);
        let debouncer = setTimeout(() => {
          setTable(result.data);
          setModalSpinner(false);
          // let msg = "Successfully deleted 1 column."
          // NotificationManager.success(msg)
        }, 500);
        return () => {
          clearTimeout(debouncer);
        };
      };
      doDeleteColumn();
    }
  }, [columnDelete]);

  useEffect(() => {
    if (groupDelete) {
      setModalSpinner(true);
      const doDeleteGroup = async () => {
        await deleteGroup(groupDelete);
        if (groupDelete.refresh) await getTable();
        let debouncer = setTimeout(() => {
          setModalSpinner(false);
          // let msg = "Group was successfully deleted."
          // NotificationManager.success(msg)
        }, 500);
        return () => {
          clearTimeout(debouncer);
        };
      };
      doDeleteGroup();
    }
  }, [groupDelete]);

  useEffect(() => {
    if (flagNewMember) {
      const getDataMember = async () => {
        // await getMember(params.team_id)
        await getBoardUser();
      };
      getDataMember();
    }
  }, [flagNewMember]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (viewType !== "Default") return;
    setModalSpinner(true);
    const getDataTable = async () => {
      await getTable();
      let debouncer = setTimeout(() => {
        setModalSpinner(false);
      }, 500);
      return () => {
        clearTimeout(debouncer);
      };
    };
    getDataTable();
  }, [viewType]);

  return (
    <DashboardContext.Provider
      value={{
        addNewWorkspace,
        setAddNewWorkspace,
        params,
        setParams,
        workspace,
        addNewBoard,
        setNewBoard,
        board,
        getBoard,
        getTaskDetail,
        boardActive,
        setBoardActive,
        setFlagNewBoard,
        addNewGroup,
        table,
        tableSearch,
        itemUpdate,
        setItemUpdate,
        columnUpdate,
        setColumnUpdate,
        setColumnAdd,
        setColumnDelete,
        setGroupDelete,
        setItemAdd,
        setSubItemAdd,
        setItemDelete,
        deleteBoard,
        getWorkspace,
        insertWorkspace,
        addNewMember,
        member,
        flagNewMember,
        setFlagNewMember,
        setNewMember,
        duplicateGroup,
        setBoardUpdate,
        updateBoard,
        uploadFile,
        deleteFile,
        downloadFile,
        showMember,
        setShowMember,
        showCuttoff,
        setShowCuttoff,
        setShowDetailBoard,
        showDetailBoard,
        showMemberTeam,
        setShowMemberTeam,
        boardUser,
        updateBoardUser,
        priority,
        setPriority,
        showPriority,
        setShowPriority,
        updatePriority,
        inviteMember,
        getMember,
        getBoardUser,
        showStatus,
        status,
        setShowStatus,
        updateStatus,
        status,
        setStatus,
        showComment,
        setShowComment,
        showPermissionBoard,
        setShowPermissionBoard,
        itemActive,
        setItemActive,
        updateTable,
        editDesc,
        setEditDesc,
        addNewDashboard,
        setNewDashboard,
        addNewDashboardPMU,
        setNewDashboardPMU,
        addNewFolder,
        setAddNewFolder,
        createBoard,
        showChooseBoard,
        setShowChooseBoard,
        selectedBoard,
        showWidget,
        setShowWidget,
        createDashboard,
        dashboard,
        modeDashboard,
        setModeDashboard,
        showChooseWidget,
        setShowChooseWidget,
        updateDashboard,
        deleteDashboard,
        printDashboard,
        setPrintDashboard,
        duplicateDashboard,
        setDashboard,
        permissionDashboard,
        setPermissionDashboard,
        color,
        setColor,
        fullscreenWidget,
        setFullscreenWidget,
        valueSearch,
        setValueSearch,
        valueFilter,
        setValueFilter,
        createWidget,
        setTable,
        getPermission,
        permission,
        updateMember,
        teams,
        getTeams,
        getDashboardTeams,
        getDashboardTeamsCurve,
        getDashboardWorkspaceCurve,
        createTeams,
        selectedTeams,
        setSelectedTeams,
        updateTeams,
        inviteTeamMember,
        selectedWorkspace,
        setSelectedWorkspace,
        workspaceTeams,
        setWorkspaceTeams,
        getWorkspaceTeams,
        updateWorkspaceTeams,
        updateWorkspace,
        updateWorkspaceShowDashboard,
        createWorkspaceTeams,
        modalSpinner,
        setModalSpinner,
        boardDashboardSelected,
        setBoardDashboardSelected,
        stateChart,
        dispatchChart,
        duplicateBoard,
        workspaceProfile,
        getWorkspaceProfile,
        rating,
        setRating,
        showNotif,
        setShowNotif,
        sendNotif,
        getNotif,
        countNotif,
        getNotifCount,
        showLogs,
        setShowLogs,
        showDelete,
        setShowDelete,
        showDeleteTeams,
        setShowDeleteTeams,
        showDeleteWorkspace,
        setShowDeleteWorkspace,
        valueSort,
        setValueSort,
        viewType,
        setViewType,
        selectedColumnKanban,
        setSelectedColumnKanban,
        showDetailKanban,
        setShowDetailKanban,
        getTable,
        updateTableKanban,
        showPA,
        setShowPA,
        setWorkspace,
        selectedTeamsSetting,
        setSelectedTeamsSetting,
        editTeamMember,
        setEditTeamMember,
        editWorkspaceMember,
        setEditWorkspaceMember,
        showModalText,
        setShowModalText,
        showModalContact,
        setShowModalContact,
        showWidgetBattery,
        setShowWidgetBattery,
        dataDashboard,
        setDataBattery,
        width,
        setWidth,
        addNewTeams,
        setNewTeams,
        modalPricing,
        showModalPricing,
        modalPayment,
        showModalPayment,
        postMidtrans,
        setLocalStorage,
        deleteWorkspaceTeams,
        modalArticle,
        showModalArticle,
        postMenuArticle,
        postArticleCrete,
        getArticle,
        isNewArticle,
        setNewArticle,
        deleteArticle,
        modalMenuArticle,
        showModalMenuArticle,
        postMenuArticleCreate,
        deleteMenuArticle,
        itemExpand,
        setItemExpand,
        postFreeTrial,
        showDeleteTeamMember,
        setDeleteTeamMember,
        showDeleteWorkspaceMember,
        setDeleteWorkspaceMember,
        updateComment,
        getComment,
        filterElement,
        setFilterElement,
        sortElement,
        setSortElement,
        updateColumnTableStatus,
        columnUpdateStatus,
        setColumnUpdateStatus,
        moveGroupTable,
        setMoveGroupTable,
        setMoveItemTable,
        getWorkspaceSeeAll,
        workspaceSeeAll,
        modalSeeAllWorkspace,
        setModalSeeAllWorkspace,
        getTableMyTask,
        setTableMyTask,
        setTableSearchMyTask,
        tableMyTask,
        tableSearchMyTask,
        setSelectedTaskBoard,
        selectedTaskBoard,
        setDetailTask,
        detailTask,
        setDetailTaskBoard,
        detailTaskBoard,
        dashboardTeams,
        dashboardTeamsCurve,
        dashboardWorkspaceCurve,
        viewTimeline,
        setViewTimeline,
        viewTimelineSelected,
        setViewTimelineSelected,
        getDashboardBoard,
        dashboardBoard,
        getWorkspaceWeeks,
        workspaceWeeks,
        processWorkspaceWeeks,
        getCurvaS,
        curvaS
      }}
    >
      <MytaskContextProvider>{props.children}</MytaskContextProvider>
    </DashboardContext.Provider>
  );
};

export { DashboardContext, DashboardContextProvider };
