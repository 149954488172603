import React, { useEffect, useState, useContext, useRef } from "react";

//=========3RD LIBRARY  ========//
import _ from "lodash";
import { StyleSelect } from "../../helpers/select/Select";
import Select from "react-select";

//=========ICON LIST========//
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import StarOutlineIcon from "@material-ui/icons/StarBorder";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import HeightIcon from "@material-ui/icons/Height";
import FormatColorFillIcon from "@material-ui/icons/FormatColorFill";
import CallReceivedIcon from "@material-ui/icons/CallReceived";
import CreateIcon from "@material-ui/icons/Create";
import ContentEditable from "react-contenteditable";
import Print from "monday-ui-react-core/dist/icons/Print";
import Table from "../../img/table.svg";
import AddIcon from "../../img/add-pa.svg";
import SearchIcon from "../../img/search.svg";
import PersonIcon from "../../img/person.svg";
import FilterIcon from "../../img/filters.svg";
import LockIcon from "../../img/lock.svg";
import SortIcon from "../../img/sort.svg";
import SettingIcon from "../../img/setting.svg";
import ActivityIcon from "../../img/activity.svg";
import MemberIcon from "../../img/member.svg";
import PermissionIcon from "../../img/permission.svg";
import DuplicateIcon from "../../img/duplicate.svg";
import ExcelIcon from "../../img/excel.svg";
import DeleteIcon from "../../img/delete.svg";
import RenameIcon from "../../img/edit.svg";
import KanbanIcon from "../../img/kanban.svg";
import TimelineIcon from "../../img/timeline_ic.svg";

//=========BOOTSTARP INITIAL========//
import {
  Col,
  Row,
  Nav,
  OverlayTrigger,
  Popover,
  Form,
  Image,
} from "react-bootstrap";

//=========CONTEXT========//
import { DashboardContext } from "../../context/DashboardContext";
import {
  exportExcel,
  stripHtml,
  getSpesificColumn,
  filterDataTable,
  filterColumnDataTable,
  sortDataTable,
} from "../../helpers/utils/Utils";

//=========COMPONENT  ========//
import Filter from "./filter/Filter";
import Sort from "./sort/Sort";

const Header = () => {
  const {
    boardActive,
    addNewGroup,
    updateBoard,
    params,
    setShowMember,
    setShowCuttoff,
    boardUser,
    deleteBoard,
    setShowPermissionBoard,
    setEditDesc,
    valueSearch,
    setValueSearch,
    table,
    setTable,
    duplicateBoard,
    showLogs,
    setShowLogs,
    setViewType,
    viewType,
    selectedColumnKanban,
    setSelectedColumnKanban,
    tableSearch,
    valueFilter,
    valueSort,
    filterElement,
    setFilterElement,
    sortElement,
    setSortElement,
    setValueFilter,
    viewTimeline, 
    viewTimelineSelected,
    setViewTimelineSelected,
  } = useContext(DashboardContext);
  const [state, setState] = useState({ title: "", desc: "" });
  const [colorFav, setColorFav] = useState("black");
  const [countMember, setCountMember] = useState(0);
  const [hideSearch, setHideSearch] = useState(true);
  const [hidePerson, setHidePerson] = useState(true);
  const [memberList, setMemberList] = useState([]);
  const [columnKanban, setColumnKanban] = useState([]);
  const [assigneeColumn, setAssigneeColumn] = useState([]);
  const [priorityColumn, setPriorityColumn] = useState([]);
  const [dueColumn, setDueColumn] = useState([]);
  const [progressColumn, setProgressColumn] = useState([]);
  const [selectedAssigneeColumn, setSelectedAssigneeColumn] = useState([]);
  const [selectedPriorityColumn, setSelectedPriorityColumn] = useState([]);
  const [selectedDueColumn, setSelectedDueColumn] = useState([]);
  const [selectedProgressColumn, setSelectedProgressColumn] = useState([]);
  const [selectedMember, setSelectedMember] = useState([]);
  const [isHiddenFilter, setHiddenFilter] = useState(true);
  const [isHiddenSort, setHiddenSort] = useState(true);
  const [isHiddenSettings, setHiddenSetting] = useState(true);
  const [tableFilter, setTableFilter] = useState(true);
  const [count, setCount] = useState(1);
  const [countSort, setCountSort] = useState(0);
  const [divideByGroup, setDivideByGroup] = useState(false);
  let ref = React.useRef(null);
  let titleRef = useRef("");
  let refSearch = React.useRef(null);
  let refPerson = React.useRef(null);
  let refFilter = useRef(true);
  let refSort = useRef(true);
  let refSettings = useRef(true);

  const handleChangeTitle = (value) => {
    setState({ ...state, title: value });
  };

  const handleEditItem = async () => {
    let param = {};
    if (titleRef.current.el.current.outerText === "") {
      setState({ ...state, title: titleRef.current.props.html });
      return;
    }
    param.id = boardActive.id;
    param.title = titleRef.current.el.current.outerText;
    param.description = state.desc;
    param.permission_id = boardActive.permissionId;
    param.is_favorites = boardActive.is_favorites;
    param.updated_by = params.id;
    await updateBoard(param);
  };

  const handleEnterItem = (event) => {
    var keyCode = event.which || event.keyCode;
    if (keyCode === 13) {
      handleEditItem();
    }
  };

  const handleFav = async () => {
    let param = {};
    param.id = boardActive.id;
    param.title = state.title;
    param.description = state.desc;
    param.permission_id = boardActive.permissionId;
    param.is_favorites = !boardActive.is_favorites;
    param.updated_by = params.id;
    setColorFav("black");
    if (param.is_favorites) setColorFav("orange");
    await updateBoard(param);
  };

  useEffect(() => {
    setMemberList([]);
    setHideSearch(true);
    setHidePerson(true);
    setColorFav("black");
    if (boardActive.is_favorites) setColorFav("orange");
    let desc = boardActive.description
      ? boardActive.description
      : "Add board description";
    desc = desc.replace(/<p>/g, ' <p class="m-0">');
    setState({ title: boardActive.title, desc });
  }, [boardActive]);

  useEffect(() => {
    if (table.length === 0) return;
    let arrTmp = [];
    table.map((tab) => {
      tab.rows.map((row) => {
        row.map((item) => {
          if (item && item.type === "person") {
            if (item.name === "" || typeof item.name !== "object") return;
            item.name.map((name) => {
              let tmp = { value: name.value, label: name.label };
              let isExist = _.some(arrTmp, function (i) {
                return i.value == name.value;
              });
              if (!isExist) arrTmp.push(tmp);
            });
          }
        });
      });
    });
    setMemberList(arrTmp);

    createElement();
    const columnKanban = getSpesificColumn(table, ["status", "priority"]);
    if (!columnKanban.length) return;
    setColumnKanban(columnKanban);
    let defaultSelected = _.filter(columnKanban, (item) => {
      return item.type === "status";
    });
    if (!defaultSelected.length) return;
    const assigneeColumn = getSpesificColumn(table, ["person"]);
    setAssigneeColumn(assigneeColumn);
    setSelectedAssigneeColumn(assigneeColumn[0]);

    const priorityColumn = getSpesificColumn(table, ["priority"]);
    setPriorityColumn(priorityColumn);
    setSelectedPriorityColumn(priorityColumn[0]);

    const dueColumn = getSpesificColumn(table, ["duedate"]);
    setDueColumn(dueColumn);
    setSelectedDueColumn(dueColumn[0]);

    const progressColumn = getSpesificColumn(table, ["progress"]);
    setProgressColumn(progressColumn);
    setSelectedProgressColumn(progressColumn[0]);

    let selected = defaultSelected[0];
    selected.assignee = assigneeColumn[0];
    selected.divideByGroup = divideByGroup;
    selected.priority = priorityColumn[0];
    selected.due = dueColumn[0];
    selected.progress = progressColumn[0];

    setSelectedColumnKanban(selected);
  }, [table]);

  useEffect(() => {
    createElement();
  }, [count]);

  const createElement = () => {
    let elements = [];
    for (let i = 0; i < count; i++) {
      elements.push(<Filter />);
    }
    setFilterElement(elements);
  };

  const addFilter = () => {
    let cnt = count;
    cnt += 1;
    setCount(cnt);
  };

  const deleteFitlter = (index) => {
    let elementsTmp = _.filter(filterElement, (item, key) => {
      return key !== index;
    });
    setFilterElement(elementsTmp);
    let cnt = count;
    cnt -= 1;
    setCount(cnt);
  };

  useEffect(() => {
    createElementSort();
  }, [countSort]);

  const createElementSort = () => {
    let elements = [];
    for (let i = 0; i < countSort; i++) {
      elements.push(<Sort />);
    }
    setSortElement(elements);
  };

  const addSort = () => {
    let cnt = countSort;
    cnt += 1;
    setCountSort(cnt);
  };

  const deleteSort = (index) => {
    let elementsTmp = _.filter(sortElement, (item, key) => {
      return key !== index;
    });
    setSortElement(elementsTmp);
    let cnt = countSort;
    cnt -= 1;
    setCountSort(cnt);
  };

  useEffect(() => {
    setCountMember(boardUser.length);
  }, [boardUser]);

  useEffect(() => {
    if (!hideSearch) refSearch.current.focus();
  }, [hideSearch]);

  useEffect(() => {
    if (!hidePerson) refPerson.current.select.focus();
  }, [hidePerson]);

  const actionSearch = (e) => {
    setValueSearch({ value: e.target.value, type: "text" });
  };

  const handleChangeMember = async (selected) => {
    setSelectedMember(selected);
    setValueSearch({ value: selected ? selected.value : "", type: "person" });
  };

  const handleExport = () => {
    let dataExcel = [[boardActive.title]];
    let wscols = [];
    table.map((tab) => {
      tab.rows.map((item, key) => {
        let tmp = [];
        item.map((value, valueKey) => {
          let valueName = "";
          if (typeof value.name === "string") valueName = value.name;
          if (valueKey > 0 && valueKey < item.length - 1) {
            let objTmp = {};
            if (Array.isArray(value.name)) {
              let separator = "";
              value.name.map((val) => {
                valueName += separator + val.label;
                separator = ",";
              });
            } else if (typeof value.name === "object")
              valueName = value.name.name;
            tmp.push(stripHtml(valueName.replace(/\&nbsp;/g, " ")));
            objTmp.wch = valueName.length + 10;
            if (key === 1) wscols.push(objTmp);
          }
        });
        dataExcel.push(tmp);
      });
      //===== ADD SPACE BETWEEN GROUP ======//
      dataExcel.push([]);
      dataExcel.push([]);
    });
    exportExcel({
      data: dataExcel,
      name: boardActive.title.replace(/ /g, "_"),
      wscols: wscols,
    });
    ref.click();
  };

  const handlePrint = () => {
    ref.click();
    window.print();
  };

  const duplicate = async () => {
    ref.click();
    await duplicateBoard();
  };

  const showFilter = () => {
    setHiddenFilter(!isHiddenFilter);
    refFilter.current = !isHiddenFilter;
    window.dispatchEvent(new Event("resize"));
  };

  const showSort = () => {
    setHiddenSort(!isHiddenSort);
    refSort.current = !isHiddenSort;
    window.dispatchEvent(new Event("resize"));
  };

  const showSettings = () => {
    setHiddenSetting(!isHiddenSettings);
    refSettings.current = !isHiddenSettings;
    window.dispatchEvent(new Event("resize"));
  };

  const hideSetting = () => {
    setHiddenSetting(true);
    refSettings.current = true;
  };

  const hideFilter = () => {
    setHiddenFilter(true);
    refFilter.current = true;
  };

  const hideSort = () => {
    setHiddenSort(true);
    refSort.current = true;
  };

  const handleChangeColumn = (selected) => {
    if (!selected) selected = [];
    selected.assignee = selectedAssigneeColumn;
    selected.divideByGroup = divideByGroup;
    selected.priority = selectedPriorityColumn;
    selected.due = selectedDueColumn;
    selected.progress = selectedProgressColumn;

    setSelectedColumnKanban(selected);
  };

  const handleChangeAssigneeColumn = (selected) => {
    if (!selected) selected = [];
    setSelectedAssigneeColumn(selected);
    let newSelected = _.cloneDeep(selectedColumnKanban, true);
    newSelected.assignee = selected;
    newSelected.divideByGroup = divideByGroup;
    setSelectedColumnKanban(newSelected);
  };

  const handleChangePriorityColumn = (selected) => {
    if (!selected) selected = [];
    setSelectedPriorityColumn(selected);
    let newSelected = _.cloneDeep(selectedColumnKanban, true);
    newSelected.priority = selected;
    newSelected.assignee = selectedAssigneeColumn;
    newSelected.divideByGroup = divideByGroup;
    setSelectedColumnKanban(newSelected);
  };

  const handleChangeDueColumn = (selected) => {
    if (!selected) selected = [];
    setSelectedDueColumn(selected);
    let newSelected = _.cloneDeep(selectedColumnKanban, true);
    newSelected.due = selected;
    newSelected.priority = selectedPriorityColumn;
    newSelected.assignee = selectedAssigneeColumn;
    newSelected.divideByGroup = divideByGroup;
    setSelectedColumnKanban(newSelected);
  };

  const handleChangeProgressColumn = (selected) => {
    if (!selected) selected = [];
    setSelectedProgressColumn(selected);
    let newSelected = _.cloneDeep(selectedColumnKanban, true);
    newSelected.progress = selected;
    newSelected.due = selectedProgressColumn;
    newSelected.priority = selectedPriorityColumn;
    newSelected.assignee = selectedAssigneeColumn;
    newSelected.divideByGroup = divideByGroup;
    setSelectedColumnKanban(newSelected);
  };

  useEffect(() => {
    if (valueSearch.type === "") return;
    const getFilter = () => {
      let tmpTable = _.cloneDeep(tableSearch, true);
      let filter = filterDataTable(tmpTable, valueSearch);
      setTable(filter);
    };

    let debouncer = setTimeout(() => {
      getFilter();
    }, 500);
    return () => {
      clearTimeout(debouncer);
    };
  }, [valueSearch]);

  useEffect(() => {
    if (valueFilter.value === "" || !valueFilter.value) return;
    const getFilter = () => {
      let data = _.cloneDeep(tableSearch);
      if (valueFilter.index > 0) data = _.cloneDeep(tableFilter);
      let filter = filterColumnDataTable(data, valueFilter);
      if (valueFilter.index === 0) setTableFilter(_.cloneDeep(filter, true));
      setTable(filter);
    };

    let debouncer = setTimeout(() => {
      getFilter();
      if (valueFilter.isReset && valueFilter.index === 0) {
        setTable(tableSearch);
      } else if (valueFilter.isReset && valueFilter.index > 0) {
        setTable(tableFilter);
      } else getFilter();
    }, 500);

    return () => {
      clearTimeout(debouncer);
    };
  }, [valueFilter]);

  useEffect(() => {
    if (valueSort.value === "") return;
    const getSort = () => {
      let data = _.cloneDeep(tableSearch);
      let dataTmp = sortDataTable(data, valueSort);
      setTable(dataTmp);
    };

    let debouncer = setTimeout(() => {
      if (valueSort.isReset) setTable(tableSearch);
      else getSort();
    }, 500);
    return () => {
      clearTimeout(debouncer);
    };
  }, [valueSort]);

  const handleChangeDivide = (e) => {
    setDivideByGroup(e.target.checked);
    setSelectedColumnKanban({
      ...selectedColumnKanban,
      divideByGroup: e.target.checked,
    });
  };

  const clickOutSide = () => {
    if (!isHiddenSort) setHiddenSort(true);
    if (!isHiddenFilter) setHiddenFilter(true);
    if (!hidePerson) setHidePerson(true);
    if (!hideSearch) setHideSearch(true);
    if (!isHiddenSettings) setHiddenSetting(true);
  };

  useEffect(() => {
    const hanldeClick = (e) => {
      if (e.target.id.includes("react-select") || e.target.tagName === "svg")
        return;

      if (
        document.getElementById("popover-filter") &&
        !document.getElementById("popover-filter").contains(e.target) &&
        !document.getElementById("span-filter").contains(e.target)
      )
        if (!refFilter.current) setHiddenFilter(true);

      if (
        document.getElementById("popover-sort") &&
        !document.getElementById("popover-sort").contains(e.target) &&
        !document.getElementById("span-sort").contains(e.target)
      )
        if (!refSort.current) setHiddenSort(true);

      if (
        document.getElementById("popover-settings") &&
        !document.getElementById("popover-settings").contains(e.target) &&
        !document.getElementById("span-settings").contains(e.target)
      )
        if (!refSettings.current) setHiddenSetting(true);
    };

    window.addEventListener("click", hanldeClick);
    return () => window.removeEventListener("click", hanldeClick);
  }, []);

  const clearFilter = () => {
    setValueFilter({ ...valueFilter, value: "" });
    setFilterElement([]);
  };

  const popoverView = (
    <Popover id="popover-basic">
      <Popover.Content>
        <Col className="ml-1 pl-2">
          <span className="color-black">All Views</span>
        </Col>
        <Nav.Link
          className="nav-menu p-2 pr-5 mt-1"
          onClick={() => {
            setViewType("Default");
            ref.click();
            hideSetting();
          }}
        >
          <Image src={Table}></Image>
          <span className="workspace-item-name-child align-text-top ml-2 font-14">
            Default
          </span>
        </Nav.Link>
        <Nav.Link
          className="nav-menu p-2 pr-5 mt-1"
          onClick={() => {
            setViewType("Kanban");
            ref.click();
            hideSetting();
          }}
        >
          <Image src={KanbanIcon}></Image>
          <span className="workspace-item-name-child align-text-top ml-2 font-14">
            Kanban
          </span>
        </Nav.Link>
        <Nav.Link
          className="nav-menu p-2 pr-5 mt-1"
          onClick={() => {
            setViewType("Timeline");
            ref.click();
            hideSetting();
          }}
        >
          <Image src={TimelineIcon}></Image>
          <span className="workspace-item-name-child align-text-top ml-2 font-14">
            Timeline
          </span>
        </Nav.Link>
      </Popover.Content>
    </Popover>
  );

  const popoverBoard = (
    <Popover id="popover-basic">
      <Popover.Content>
        <Nav.Link
          className={
            params.permission_id < 4
              ? "nav-menu p-2 pr-5"
              : "nav-menu p-2 pr-5 disabled"
          }
          onClick={() => {
            setShowPermissionBoard(true);
            ref.click();
          }}
        >
          <Image src={PermissionIcon}></Image>
          <span className="workspace-item-name-child align-text-top ml-2 font-14">
            Board Permission
          </span>
        </Nav.Link>

        <div className="spacer p-0"></div>
        <Nav.Link
          className="nav-menu p-2 pr-5 mt-2"
          onClick={() => handleExport()}
        >
          <Image src={ExcelIcon}></Image>
          <span className="workspace-item-name-child align-text-top ml-2 font-14">
            Export board to Excel
          </span>
        </Nav.Link>
        <Nav.Link
          hidden
          className="nav-menu p-2 pr-5 mb-2"
          onClick={() => handlePrint()}
        >
          <Print style={{ fontSize: 20 }} className="align-text-top icon" />
          <span className="workspace-item-name-child align-text-top ml-2 font-14">
            Print board
          </span>
        </Nav.Link>

        <div className="spacer p-0"></div>
        <Nav.Link
          className={
            params.permission_id < 4
              ? "nav-menu p-2 pr-5 mt-2"
              : "nav-menu p-2 pr-5 mt-2 disabled"
          }
          onClick={() => duplicate()}
        >
          <Image src={DuplicateIcon}></Image>
          <span className="workspace-item-name-child align-text-top ml-2 font-14">
            Duplicate
          </span>
        </Nav.Link>
        <Nav.Link
          className={
            params.permission_id < 4
              ? "nav-menu p-2 pr-5"
              : "nav-menu p-2 pr-5 disabled"
          }
          onClick={() => deleteBoard(boardActive)}
        >
          <Image src={DeleteIcon}></Image>
          <span className="workspace-item-name-child align-text-top ml-2 font-14">
            Delete Board
          </span>
        </Nav.Link>
      </Popover.Content>
    </Popover>
  );

  const popoverGroup = (
    <Popover id="popover-basic">
      <Popover.Content>
        <Nav.Link className="nav-menu p-2 pr-5">
          <CallReceivedIcon
            style={{ color: "black", fontSize: 20 }}
            className="align-text-top"
          />
          <span className="workspace-item-name-child align-text-top ml-2 font-14">
            Pin Columns
          </span>
        </Nav.Link>
        <Nav.Link className="nav-menu p-2 pr-5">
          <VisibilityOffIcon
            style={{ color: "black", fontSize: 20 }}
            className="align-text-top"
          />
          <span className="workspace-item-name-child align-text-top ml-2 font-14">
            Hidden Columns
          </span>
        </Nav.Link>
        <Nav.Link className="nav-menu p-2 pr-5">
          <HeightIcon
            style={{ color: "black", fontSize: 20 }}
            className="align-text-top"
          />
          <span className="workspace-item-name-child align-text-top ml-2 font-14">
            Item Height
          </span>
        </Nav.Link>
        <Nav.Link className="nav-menu p-2 pr-5">
          <FormatColorFillIcon
            style={{ color: "black", fontSize: 20 }}
            className="align-text-top"
          />
          <span className="workspace-item-name-child align-text-top ml-2 font-14">
            Conditional Coloring
          </span>
        </Nav.Link>
        <Nav.Link className="nav-menu p-2 pr-5">
          <CreateIcon
            style={{ color: "black", fontSize: 20 }}
            className="align-text-top"
          />
          <span className="workspace-item-name-child align-text-top ml-2 font-14">
            Item Default Values
          </span>
        </Nav.Link>
      </Popover.Content>
    </Popover>
  );

  const popoverFilter = (
    <Popover hidden={isHiddenFilter} id="popover-filter">
      <Col md="12">
        <Popover.Content>
          <Row className="mt-3 mb-3">
            <Col>
              <span className="font-14">Advanced Filters</span>
            </Col>
            <Col hidden>
              <span
                className="font-14 float-right cursor-pointer"
                onClick={() => clearFilter()}
              >
                Clear All
              </span>
            </Col>
          </Row>

          {filterElement.length &&
            filterElement.map((element, index) => {
              return element === undefined ? (
                ""
              ) : (
                <React.Fragment key={index}>
                  {React.cloneElement(element, {
                    elementIndex: index,
                    deleteFitlter: (index) => deleteFitlter(index),
                  })}
                </React.Fragment>
              );
            })}
          <Row className="mt-3 mb-3">
            <Col>
              <span
                className="font-14 cursor-pointer mb-3"
                onClick={() => addFilter()}
              >
                + Add new filter
              </span>
            </Col>
          </Row>
        </Popover.Content>
      </Col>
    </Popover>
  );

  const popoverSort = (
    <Popover hidden={isHiddenSort} id="popover-sort">
      <Col md="12">
        <Popover.Content>
          <Row className="mt-3 mb-3">
            <Col>
              <span className="font-14">Sort by</span>
            </Col>
          </Row>
          {sortElement.length === 0 ? (
            <Row className="mt-3 mb-3">
              <Col>
                <span className="font-14">
                  Sort your items by priority, status or any column you have on
                  your board.
                </span>
              </Col>
            </Row>
          ) : (
            ""
          )}

          {sortElement.map((element, index) => {
            return element === undefined ? (
              ""
            ) : (
              <React.Fragment key={index}>
                {React.cloneElement(element, {
                  elementIndex: index,
                  deleteSort: (index) => deleteSort(index),
                })}
              </React.Fragment>
            );
          })}
          <Row className="mt-3 mb-3">
            <Col>
              <span
                className="font-14 cursor-pointer mb-3"
                onClick={() => addSort()}
              >
                + Add new sort
              </span>
            </Col>
          </Row>
        </Popover.Content>
      </Col>
    </Popover>
  );

  const popoverSettings = (
    <Popover hidden={isHiddenSettings} id="popover-settings">
      <Col md="12" style={{ width: "300px" }}>
        <Popover.Content>
          <Row className="mt-3 mb-3">
            <Col>
              <span className="font-14 font-w-400">Customize View</span>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <span className="font-14 font-w-400">Kanban Column</span>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Select
                focus
                styles={StyleSelect}
                maxMenuHeight={150}
                placeholder=""
                options={columnKanban}
                value={selectedColumnKanban}
                onChange={(selected) => handleChangeColumn(selected)}
                className="m-0 p-0"
              ></Select>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <span className="font-14 font-w-400">Assignee Column</span>
            </Col>
          </Row>
          <Row className="mt-3 mb-3">
            <Col>
              <Select
                focus
                styles={StyleSelect}
                maxMenuHeight={150}
                placeholder=""
                options={assigneeColumn}
                value={selectedAssigneeColumn}
                onChange={(selected) => handleChangeAssigneeColumn(selected)}
                className="m-0 p-0"
              ></Select>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <span className="font-14 font-w-400">Priority Column</span>
            </Col>
          </Row>
          <Row className="mt-3 mb-3">
            <Col>
              <Select
                focus
                styles={StyleSelect}
                maxMenuHeight={150}
                placeholder=""
                options={priorityColumn}
                value={selectedPriorityColumn}
                onChange={(selected) => handleChangePriorityColumn(selected)}
                className="m-0 p-0"
              ></Select>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <span className="font-14 font-w-400">Due Column</span>
            </Col>
          </Row>
          <Row className="mt-3 mb-3">
            <Col>
              <Select
                focus
                styles={StyleSelect}
                maxMenuHeight={150}
                placeholder=""
                options={dueColumn}
                value={selectedDueColumn}
                onChange={(selected) => handleChangeDueColumn(selected)}
                className="m-0 p-0"
              ></Select>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <span className="font-14 font-w-400">Progress Column</span>
            </Col>
          </Row>
          <Row className="mt-3 mb-3">
            <Col>
              <Select
                focus
                styles={StyleSelect}
                maxMenuHeight={150}
                placeholder=""
                options={progressColumn}
                value={selectedProgressColumn}
                onChange={(selected) => handleChangeProgressColumn(selected)}
                className="m-0 p-0"
              ></Select>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  onChange={handleChangeDivide}
                  defaultChecked={divideByGroup}
                  type="checkbox"
                  label="Divide by groups"
                />
              </Form.Group>
            </Col>
          </Row>
        </Popover.Content>
      </Col>
    </Popover>
  );

  return (
    <React.Fragment>
      {state.title && state.title !== "blank" ? (
        <Col className="p-3 ml-2" ref={(r) => (ref = r)}>
          <iframe
            id="ifmcontentstoprint"
            hidden
            style={{ height: "0px", width: "0px", position: "absolute" }}
          ></iframe>
          <Row>
            <ContentEditable
              html={state.title}
              className="board-title p-1"
              disabled={params.permission_id < 4 ? false : true}
              onBlur={() => {
                handleEditItem();
              }}
              ref={(r) => (titleRef.current = r)}
              onKeyDown={(e) => handleEnterItem(e)}
            />
            <StarOutlineIcon
              style={{ color: colorFav, fontSize: 20 }}
              onClick={handleFav}
              className="align-text-top mt-2 align-text-top ml-2"
            />
            <Col className="col-item">
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={popoverBoard}
                rootClose
              >
                <MoreHorizIcon
                  onClick={() => clickOutSide()}
                  style={{ color: "black" }}
                  className="align-text-top align-text-top m-2 float-right cursor-pointer"
                />
              </OverlayTrigger>

              <span
                className="activities float-right d-flex align-items-center"
                onClick={() => {
                  setShowLogs({ isShow: true });
                  clickOutSide();
                }}
              >
                <Image src={ActivityIcon} className="pl-1 pr-2"></Image>
                <span className="activities">Activities</span>
              </span>

              <span
                className="member float-right d-flex align-items-center"
                onClick={() => {
                  setShowMember(true);
                  clickOutSide();
                }}
              >
                <Image src={MemberIcon} className="pl-1 pr-2"></Image>
                <span className="activities">Member</span>
              </span>

              <span
                className="cuttoff float-right d-flex align-items-center"
                onClick={() => {
                  setShowCuttoff(true);
                  clickOutSide();
                }}
              >
                <Image src={LockIcon}  className="pr-2"></Image>
                <span className="cuttoff">Cut Off</span>
              </span>

              <span className="activities float-right font-14" hidden>
                Automate / 0
              </span>
              <span className="activities float-right font-14" hidden> 
                Integrate / 0
              </span>
            </Col>
          </Row>

          <Row>
            <div
              className="board-desc mt-0 p-1 ml-1 color-grey "
              dangerouslySetInnerHTML={{ __html: state.desc }}
            ></div>
            {params.permission_id < 4 ? (
              <Image
                src={RenameIcon}
                className=" color-grey cursor-pointer edit-desc"
                onClick={() => {
                  setEditDesc(true);
                  clickOutSide();
                }}
              ></Image>
            ) : null}
          </Row>

          <Row className="mt-3">
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={popoverView}
              rootClose
            >
              <span className="action float-right">
                {viewType === "Default" ? (
                  <Image src={Table}></Image>
                ) : (
                  <Image src={KanbanIcon}></Image>
                )}
                <span className="font-14 mr-2 ml-2"> {viewType}</span>
                <ExpandMoreIcon
                  style={{ color: "black" }}
                  fontSize="small"
                  className="float-right"
                />
              </span>
            </OverlayTrigger>
            <Col className="col-item pr-0 mr-0">
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={popoverGroup}
                rootClose
              >
                <MoreHorizIcon
                  hidden
                  style={{ color: "black" }}
                  className="align-text-top align-text-top m-2 float-right cursor-pointer"
                />
              </OverlayTrigger>
              <OverlayTrigger
                trigger="manual"
                defaultShow={true}
                placement="bottom"
                overlay={popoverSettings}
              >
                <span
                  id="span-settings"
                  onClick={() => {
                    showSettings();
                    hideSort();
                    hideFilter();
                    setHidePerson(true);
                    setHideSearch(true);
                  }}
                  hidden={
                    viewType === "Kanban"
                      ? false
                      : params.permission_id < 4 ||
                        boardActive.permission_id == 1
                      ? true
                      : true
                  }
                  className={
                    params.permission_id < 4 || boardActive.permission_id == 1
                      ? "action float-right"
                      : "action float-right"
                  }
                >
                  <Image src={SettingIcon} className="pl-1 pr-2"></Image>
                  <span className="font-14">Settings</span>
                </span>
              </OverlayTrigger>
              <OverlayTrigger
                trigger="manual"
                defaultShow={true}
                placement="bottom"
                overlay={popoverSort}
              >
                <span
                  className="action float-right"
                  id="span-sort"
                  onClick={() => {
                    showSort();
                    hideSetting();
                    hideFilter();
                    setHidePerson(true);
                    setHideSearch(true);
                  }}
                >
                  <Image src={SortIcon} className="pl-1 pr-2"></Image>
                  <span className="font-14">Sort</span>
                </span>
              </OverlayTrigger>

              <OverlayTrigger
                trigger="manual"
                defaultShow={true}
                placement="bottom"
                overlay={popoverFilter}
              >
                <span
                  className={
                    valueFilter.value
                      ? "action active float-right"
                      : "action non-active float-right"
                  }
                  id="span-filter"
                  onClick={() => {
                    hideSort();
                    hideSetting();
                    showFilter();
                    setHidePerson(true);
                    setHideSearch(true);
                  }}
                >
                  <Image src={FilterIcon} className="pl-1 pr-2"></Image>
                  <span className="font-14"> Filter</span>
                </span>
              </OverlayTrigger>

              <span
                className="float-right"
                hidden={hidePerson}
                onBlur={() => clickOutSide()}
              >
                <Select
                  focus
                  styles={StyleSelect({ width: 200 })}
                  maxMenuHeight={150}
                  placeholder=""
                  options={memberList}
                  defaultMenuIsOpen={true}
                  isClearable={true}
                  onBlur={selectedMember ? null : () => setHidePerson(true)}
                  className="m-0 p-0"
                  onChange={(selected) => {
                    handleChangeMember(selected);
                  }}
                  ref={refPerson}
                ></Select>
              </span>

              <span
                className="action float-right"
                onBlur={() => clickOutSide()}
                hidden={hidePerson ? false : true}
                onClick={() => {
                  setHidePerson(false);
                  hideSetting();
                  setHideSearch(true);
                  hideFilter();
                  hideSort(true);
                }}
              >
                <Image src={PersonIcon} className="pl-1 pr-2"></Image>
                <span className="font-14"> Person</span>
              </span>

              <span
                className="float-right"
                onBlur={() => clickOutSide()}
                hidden={hideSearch}
              >
                <input
                  onBlur={
                    valueSearch === ""
                      ? () => {
                          setHidePerson(false);
                        }
                      : null
                  }
                  ref={refSearch}
                  type="text"
                  className="form-control font-14"
                  placeholder="Search"
                  onInput={(e) => actionSearch(e)}
                ></input>
              </span>

              <span
                className="action float-right "
                onBlur={() => clickOutSide()}
                hidden={hideSearch ? false : true}
                onClick={() => {
                  setHideSearch(false);
                  hideSetting();
                  setHidePerson(true);
                  hideFilter();
                  hideSort(true);
                }}
              >
                <Image src={SearchIcon} className="pl-1 pr-2"></Image>
                <span className="font-14"> Search</span>
              </span>
              <span
                hidden={
                  viewType === "Kanban" || viewType === "Timeline"
                    ? true
                    : false
                }
                className={
                  params.permission_id < 4 || boardActive.permission_id == 1
                    ? "float-right new-group"
                    : "float-right disabled new-group"
                }
                onClick={
                  params.permission_id < 4 || boardActive.permission_id == 1
                    ? () => {
                        addNewGroup();
                        clickOutSide();
                      }
                    : null
                }
              >
                <Image src={AddIcon} className="pl-1"></Image>
                <span className="font-14"> New Group</span>
              </span>
              <div
                className="float-right mr-3"
                hidden={viewType === "Timeline" ? false : true}
              >
                <Select
                  styles={StyleSelect({ width: 200 })}
                  placeholder=""
                  options={viewTimeline}
                  value={viewTimelineSelected}
                  className="m-0 p-0"
                  onChange={(selected)=>{setViewTimelineSelected(selected)}}
                ></Select>
              </div>
            </Col>
          </Row>
        </Col>
      ) : null}
    </React.Fragment>
  );
};

export default Header;
