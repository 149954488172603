import React, { useContext } from 'react'

//=========BOOTSTARP INITIAL========//
import { Col, Row } from 'react-bootstrap'
import { DashboardContext } from "../../context/DashboardContext"

//=========COMPONENT========//  
import Header from './Header'
import Container from './Container'
import BgIcon from "../../img/bg-dashboard.svg"
import ContentFullScreen from './ContentFullScreen' 


const Workspace = () => {
    const { fullscreenWidget, width, setWidth } = useContext(DashboardContext)

    return (
        <Row >
            <Col md={12}>
                <div className="content-board bg-grey" style={{ marginLeft: width.board }}>
                    {fullscreenWidget.fullscreen ? <ContentFullScreen /> :
                        <React.Fragment>
                            <div className="devider p-3 bg-white">
                                <Header />
                            </div>
                            <Col className="min-vh-100" id="main-dashboard" style={{ backgroundImage: "url(" + BgIcon + ")" }}>
                                <Container />
                            </Col>
                        </React.Fragment>}
                </div>
            </Col>
        </Row>
    )
}

export default Workspace
