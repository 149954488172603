import React, {useState, useContext, useEffect} from 'react'
import {Col, Row, Form, Image, Button, Alert, Spinner } from 'react-bootstrap' 
import ImageRegistration from "../../img/registration.svg" 
import "../css/login.css"
import { useForm } from "react-hook-form"
import { MakeRequest } from "../../api/Api"  
import {useHistory} from 'react-router-dom'  
import { useParams } from "react-router-dom" 
import LockIcon from "../../img/lock.svg" 
import ImageLogin from "../../img/login.png" 

const Register = () =>{ 
    const history = useHistory()
    const [isLoading, setLoading] = useState(false) 
    const [dataInvitation, setDataInvitation] = useState(false) 
    const [alert, setAlert] = useState({msg: 'Register success.', variant: 'success', hidden: true}) 
    const { register, handleSubmit, watch, errors } = useForm() 
    const {token} = useParams()  
    const [typePassword, setTypePassword] = useState("password") 

    useEffect(()=>{
        const getInvitationAccept = async ()=> {   
            const result = await MakeRequest('post','invitation-accept', {token: token})   
            if(result.status === 200) {
                if(result.data.user !== null) history.push('/login/'+ token, {data: result.data})
                setDataInvitation(result.data)
            }
        }
        getInvitationAccept()
    },[])

    const onSubmit = async (params) => {
        dataInvitation.username = params.username
        dataInvitation.password = params.password
        setAlert({msg: 'Register success.', variant: 'success', hidden: true}) 
        setLoading(true)
  
        const result = await MakeRequest('post','user-create-member-invitation', {data: dataInvitation})   
        if(result.status !== 200) {
            let detailError = result.data.detail
            setAlert({msg: detailError === undefined ? "Server error": detailError, variant: 'danger', hidden: false})
        }else{
            setAlert({msg: 'Register successfully.', variant: 'success', hidden: false})
            localStorage.removeItem('saatkerja')  
            simulateNetworkRequest().then(() => {
                localStorage.setItem('saatkerja', JSON.stringify(params))  
                history.push('/login')
            })
        }
        setLoading(false)
        
    }

    function simulateNetworkRequest() {
        return new Promise((resolve) => setTimeout(resolve, 2000));
      }

    return(
        <>
        {dataInvitation ?
            <Row xs="12" className="no-gutters vh-100 vw-100">
                <Col className="m-auto" md={6}>
                    <Col md={10}>
                        <div className="m-5">
                            <div className="txt-login">
                                <span>Sign up to Saat Kerja</span>
                            </div>
                            <div className="mt-2 txt-login-child">
                                <span className="color-grey-darker">Have an account ?</span> <span className="cursor-pointer text-primary" onClick={()=>{history.push('/login')}}> Sign in here</span>
                            </div>
                            <div className="mt-5">
                                <Form onSubmit={handleSubmit(onSubmit)}>  
                                    <Form.Group controlId="formBasicEmail"> 
                                        <Form.Control className="font-16 input-text" value={dataInvitation.email} disabled  name="email" type="email" placeholder="Email" ref={register({ required: true })} />
                                        <div className="mt-3">
                                            {errors.email && <span>This field is required</span>}
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicEmail" className="pt-2"> 
                                        <Form.Control className="font-16 input-text" name="username" type="text" placeholder="Name" ref={register({ required: true })} />
                                        <div className="mt-3">
                                            {errors.username && <span>This field is required</span>}
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicEmail" className="pt-2"> 
                                        <div className="pass-wrapper">
                                            <Form.Control className="font-16 input-text" name="password" type={typePassword} placeholder="Password" ref={register({ required: true , minLength: 8})} />
                                            {typePassword === "password" ? 
                                                <Image src={LockIcon} onClick={()=> setTypePassword("text")} className="eye-icon"></Image>
                                            :
                                                <Image src={LockIcon} onClick={()=> setTypePassword("password")} className="eye-icon"></Image>
                                            }
                                        </div>
                                        <div className="mt-3">
                                            {errors.password && <span>This field is required / minimal 8 character</span>}
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicEmail" className="pt-2"> 
                                        <Form.Control className="font-16 input-text" disabled value={dataInvitation.board.team? dataInvitation.board.team.name: ""}  name="team" type="text" placeholder="Team Name" ref={register({ required: true})} />
                                        <div className="mt-3">
                                            {errors.team && <span>This field is required</span>}
                                        </div>
                                    </Form.Group> 
                                    <Form.Group className="pt-2" hidden={alert.hidden}>  
                                        <Alert variant={alert.variant} >
                                            <span className="font-15">{alert.msg}</span>
                                        </Alert>
                                    </Form.Group> 
                                    <div className="w-100 text-center pt-2">
                                        <Button
                                            variant="primary"
                                            disabled={isLoading}
                                            className="font-16 text-white text-center font-weight-bold btn-login"
                                            type="submit" 
                                            >
                                            {isLoading ?
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    />
                                                : "Create" 
                                            }
                                            </Button> 
                                    </div> 
                                </Form> 
                            </div>
                        </div>
                    </Col> 
                </Col>
                <Col className="bg-login" md={5}>
                    <Image className="img-login" src={ImageLogin} width="710" height="518"></Image>
                </Col>
        </Row>
           :""}
        </>
    )
}

export default Register