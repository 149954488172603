import React, { Component } from 'react';
import './css/home.css'; 
import HeroImg from '../../img/img-product.svg';
import HeroImg2 from '../../img/img-product-2.svg';
import HeroImg3 from '../../img/img-product-3.svg';

export default class Product extends Component {
  render() {
    return (
      <div id="uses-page">
        <div className="product-section">
          <div className="container product-container">
          <div className="container" id="product">
              <div className="hero-container">
                <div className="row mt-5">
                  <div className="col-md-6" id="img-display-img-product">
                    <img src={HeroImg}></img>
                  </div>
                  <div className="col m-auto">
                    <span className="display-text-black" id="manage-display-text-product">
                    Manage Various Project
                    </span>
                  
                    <p className="hero-text-black" id="plan-display-text-product">
                    Plan project, track progress and share the results with your team in smart and easier way.
                    </p>
                    
                  </div>

                </div>  
              </div>
            </div>

          </div>
        </div>

        <div className="product-section">
          <div className="container product-container">
          <div className="container" id="product">
              <div className="hero-container">
                <div className="row mt-3">
                <div className="col m-auto col-md-6">
                    <span className="display-text-black" id="cos-display-text-product">
                    Customizeable Dashboard & Workflow
                    </span>
                  
                    <p className="hero-text-black" id="get-dissplay-text-product">
                    Get started with user friendly template or create your own dashboard and template to match the way your team work
                    </p>
                    
                  </div>
                  <div className="col" >
                    <img id="hero2-display-img-product" src={HeroImg2}></img>
                  </div>
                  

                </div>  
              </div>
            </div>

          </div>
        </div>

        <div className="product-section">
          <div className="container product-container">
          <div className="container" id="product">
              <div className="hero-container">
                <div className="row mt-3">
                  <div className="col-md-6" >
                    <img id="hero3-display-img-product" src={HeroImg3}></img>
                  </div>
                  <div className="col m-auto">
                    <span className="display-text-black" id="real-display-text-product">
                    Real-time 
Notification
                    </span>
                  
                    <p className="hero-text-black" id="save-display-text-product">
                    Save time to track your team performance by the real-time notification of work progress
                    </p>
                    
                  </div>

                </div>  
              </div>
            </div>

          </div>
        </div>
        
      </div>
    );
  }
}
