import React, {useEffect, useState, useContext, useRef} from 'react'
import {Modal, Button, Form, Alert } from 'react-bootstrap' 
import { useForm } from "react-hook-form"
import { MakeRequest } from "../../api/Api"   
import {DashboardContext} from "../../context/DashboardContext" 
import _ from 'lodash'

const ModalAddMember = (props) =>{ 
    const ref = useRef()
    const [isLoading, setLoading] = useState(false) 
    const { register, handleSubmit, watch, errors } = useForm()
    const [alert, setAlert] = useState({msg: '', variant: 'success', hidden: true}) 
    const {params, setFlagNewMember, boardActive} = useContext(DashboardContext)  

    useEffect(()=>{
        setAlert({...alert, hidden: true})
    },[props])
    
    const onSubmit = async (paramTmp) => {
        setLoading(true) 
        let param = _.cloneDeep(params) 
        param.email = paramTmp.email.toLowerCase()
        param.username = paramTmp.username
        param.board_id = boardActive.id
        param.password = paramTmp.password 
        const result = await MakeRequest('post','user-create-member', param)   
        if(result.status !== 200) {
           setAlert({msg: result.data.detail, variant: 'danger', hidden: false})
           setLoading(false)
        }else{
            setAlert({msg: 'Create member successfully', variant: 'success', hidden: false})
            simulateNetworkRequest().then(() => {
                setAlert({ hidden: false})
                setFlagNewMember(new Date().getUTCMilliseconds())
                props.onHide()
                setLoading(false)
            })
            
        }
        
    }
    
    function simulateNetworkRequest() {
        return new Promise((resolve) => setTimeout(resolve, 1000));
    }

    return (
        <Modal
        {...props}
        size=""
        id="modal-add-member"
        aria-labelledby="contained-modal-title-vcenter"
        >
            <Form onSubmit={handleSubmit(onSubmit)}> 
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter" className="p-2">
                    Create Member
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="m-2"> 
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label className="font-14">Email</Form.Label>
                        <Form.Control className="font-14" name="email" type="email" placeholder="name@email.com" ref={register({ required: true })} />
                        {errors.email && <span>This field is required</span>}
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label className="font-14">Name</Form.Label>
                        <Form.Control className="font-14" name="username" type="text" placeholder="Name" ref={register({ required: true })} />
                        {errors.username && <span>This field is required</span>}
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label className="font-14">Password</Form.Label>
                        <Form.Control className="font-14" name="password" type="password" placeholder="Minimal 8 character" ref={register({ required: true , minLength: 8})} />
                        {errors.password && <span>This field is required / minimal 8 character</span>}
                    </Form.Group>
                    <Alert variant={alert.variant} hidden={alert.hidden}>
                        {alert.msg}
                    </Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={props.onHide}>Cancel</Button> 
                    <Button
                        variant="primary"
                        disabled={isLoading}
                        className="font-14 text-center"
                        type="submit"
                        >
                        {isLoading ? 'Loading…' : 'Create Member'}
                    </Button> 
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default ModalAddMember