import React from "react"
import { Container } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import SideBar from '../components/sidebar/SideBar'
import ContainerPage from './Container'
import { DashboardContextProvider } from '../context/DashboardContext'
import { SettingContextProvider } from '../context/SettingContext'
import './css/custome.css'
import "monday-ui-react-core/dist/main.css"
import IndexMenu from '../components/menu/Index'
const Index = () => {
    const location = useLocation()
    return (
        <DashboardContextProvider>
            <SettingContextProvider>
                {location.pathname.includes("board-android-detail") ? null
                    : <SideBar /> }
                <IndexMenu />
                <Container fluid className="no-gutters overflow-hidden pr-0" >
                    <ContainerPage />
                </Container>
            </SettingContextProvider>
        </DashboardContextProvider>
    )
}

export default Index