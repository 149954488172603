import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import Workspace from '../pages/workspace/Workspace'
import Teams from '../pages/teams/Teams'
import Board from '../pages/board/Board'
import BoardAndroid2 from '../pages/board/BoardAndroid2'
import Profile from '../pages/profile/Profile'
import Dashboard from '../pages/dashboard/Dashboard'
import DashboardAndroid from '../pages/dashboard/android/DashboardAndroid'
import Mytask from '../pages/mytask/Mytask'
import Chat from '../pages/chat/Chat'
import DashboardPMU from "../pages/dashboard/DashboardPMU"
import DashboardPMUWorkspace from "../pages/dashboard/DashboardPMUWorkspace"

const   AppRoutes = (props) => {
    return (
        <Switch>
            <Route path="/app/board" exact >
                <Board />
            </Route>
            <Route path="/app/dashboard" exact >
                <Dashboard />
            </Route>
            <Route path="/app/board-android-detail" exact >
                <BoardAndroid2 />
            </Route>
            <Route path="/app/dashboard-android-detail" exact >
                <DashboardAndroid />
            </Route>
            <Route path="/app/chat" exact >
                <Chat />
            </Route>
            <Route path="/app/mytask" exact >
                <Mytask />
            </Route>
            <Route path="/app/workspace" exact>
                <Workspace />
            </Route>
            <Route path="/app/setting/teams" exact>
                <Teams />
            </Route>
            <Route path="/app/setting/profile" exact>
                <Profile />
            </Route>
            <Route path="/app/workspace/:id" exact>
                <Workspace />
            </Route>
            <Route path="/app/workspace/team/members" exact>
                <Teams />
            </Route>
            <Route path="/app/dashboard-team" >
                <DashboardPMU />
            </Route>
            <Route path="/app/dashboard-workspace" >
                <DashboardPMUWorkspace />
            </Route>
            
        </Switch>
    )
}

export default AppRoutes