import React, {useEffect, useState, useContext, useRef} from 'react'
import {Modal, Button } from 'react-bootstrap' 
import _ from 'lodash' 
import {DashboardContext} from "../../context/DashboardContext"  

//=========ICON LIST========//    
import SubjectIcon from '@material-ui/icons/Subject'
import LockIcon from '@material-ui/icons/Lock' 

const ModalPermissionDashboard = (props) =>{
     
    const {params, boardActive, board, updateBoard} = useContext(DashboardContext)   
    const [isLoading, setLoading] = useState(false) 

    useEffect(()=>{ 
        
    },[props]) 

    const handleApply = async()=>{ 
        setLoading(true) 
        let param = {}
        param.id = boardActive.id
        param.title = boardActive.title
        param.description = boardActive.description
        param.permission_id = boardActive.permission_id === 1 ? 2 : 1 
        param.is_favorites = boardActive.is_favorites 
        param.updated_by = params.id 
        await updateBoard(param)
        setLoading(false) 
        props.onHide()
    }
  
    return (
        <Modal
        {...props}
        size=""   
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter" > 
            <Modal.Header>
                <span className="font-16">
                    Change dashboard from {boardActive && boardActive.permission_id === 1? "Main to Private" : "Private to Main"}
                </span>
                     
            </Modal.Header>
            <Modal.Body> 
                {boardActive && boardActive.permission_id === 2 ?
                    <div className="help-box-red p-3"> 
                        <div className="w-100 d-flex">
                            <div className="d-inline-block mr-3">
                                <LockIcon style={{color: 'e44258', fontSize: 50 }} className="align-text-top"/>
                            </div>
                            <div className="d-inline-block mt-1">
                                <span className="font-14">
                                This dashboard's content will become publicly available to all team members.
                                </span>
                            </div> 
                        </div>
                    </div>
                :
                <div className="help-box p-3"> 
                    <div className="w-100 d-flex">
                        <div className="d-inline-block mr-3">
                            <SubjectIcon style={{color: '#0085ff', fontSize: 50 }} className="align-text-top"/>
                        </div>
                        <div className="d-inline-block mt-1">
                            <span className="font-14">
                            This dashboard's content will become privately not available to all team members.
                            </span>
                        </div> 
                    </div>
                </div>
                } 
            </Modal.Body>
            <Modal.Footer> 
                <Button variant="light" onClick={props.onHide}>Cancel</Button> 
                <Button
                    variant="primary" 
                    className="font-14 text-center"
                    type="button"
                    disabled={isLoading}
                    onClick={handleApply}
                    > {isLoading ? 'Loading…' : 'Continue'} 
                </Button> 
            </Modal.Footer> 
        </Modal>
    )
}

export default ModalPermissionDashboard